import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from "../../components/StyledButton";
import EditIcon from '../../assets/icons/edit-icon.svg';
import InfoIcon from "../../assets/icons/info-i-icon.svg";
import CloseIcon from "../../assets/icons/close-icon.svg";
import Container from "../../components/Container/Container";
import { measureGroupsContainerCreators } from "./measureGroupsReducer";
import { riskAreaContainerCreators } from "../ManageRiskAreasContainer/reducer";
import LoadingIcon from "../../assets/icons/loading-icon";
import { ContentType } from "@onesixeight-nl/classes";
import Modal from "../../components/Modal/Modal";
import videojs from 'video.js';
import VideoJS from "../../components/VideoJS/video";
import { selectDecodedUserInfo } from "../../AppSelector";
import GetIcons from "../../components/GetIcons/GetIcons";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";
import Tooltip from "rc-tooltip";
import { clearMeasureGroupStatusCreators } from "../AddMeasureGroupContainer/addMeasureGroupReducer";

const testId = `measure-grp-container`;

type RelavantTypeArray = {
    id: string,
    name: string,
    status: string,
}

type MeasureGroups = {
    availableMeasureCount: number;
    measureCount: number;
    measuregroupId: string;
    name: string;
    nameWhenOther: string;
    selected: boolean;
    measuresShow: boolean;
    subgroup: string;
    relavantType: RelavantTypeArray[],
    measuregroupListId: string;
}

type MeasureGroupList = {
    measuregroupListId: string;
    name: string;
}

export type MeasureList = {
    measureListId: string;
    name: string;
    nameWhenOther: string;
    selected: number;
    status: number;
    measureId: string;
}

type MeasuresQuestions = {
    measureListId: string;
    name: string;
}

type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
}

type MeasureGroupPopupDetails = {
    Intro: string;
    Header: string;
    Body: string;
    Button: string;
    Button2: string;
    Link: string;
    Link2: string;
    Video: EventPopupVideo;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
}

type EventPopupVideoAttributes = {
    name: string;
    alternativeText: string;
    caption: string;
    width: null;
    height: null;
    formats: null;
    hash: string;
    ext: string;
    mime: string;
    size: string;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: null;
    createdAt: Date;
    updatedAt: Date;
}

type EventPopupVideo = {
    data: {
        id: number;
        attributes: EventPopupVideoAttributes;
    }
}

export type EventPopupDetails = {
    Intro: string;
    Header: string;
    Body: string;
    Button: string;
    Button2: string;
    Link: string;
    Link2: string;
    Video: EventPopupVideo;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
}

type Events = {
    id: string;
    name: string;
    nameWhenOther: string;
    selected: number;
    relavantType: RelavantTypeArray[],
}

type EventsList = {
    eventListId: string;
    name: string;
}

export default function MeasureGroupsContainer() {
    // hooks
    const { pathname, search, state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const isCancel = new URLSearchParams(search).get('isCancel');
    const params = useParams();
    const { id } = params;
    const intl = useIntl();
    const isMeasureGroups = pathname.includes(`/risk-area/${id}`)
    const { mgFromCancel, from }: RootState = state || {};
    //const { from }: RootState = state || {};


    const addMgSelector = useSelector((state: RootState) => state.addMeasureGroupContainer);
    const EventsSelector = useSelector((state: RootState) => state.measureGroupsContainer);
    const measureGroupsSelector = useSelector((state: RootState) => state.measureGroupsContainer);
    const measuresSelector = useSelector((state: RootState) => state.measureGroupsContainer);
    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);

    // states
    const riskCategoryId: string | undefined = id;
    const [measureGroups, setMeasureGroups] = useState<MeasureGroups[]>([]);
    //const [measureGroupList, setMeasureGroupList] = useState<MeasureGroupList[]>([]);
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>();
    const [measuresList, setMeasuresList] = useState<MeasureList[]>([]);
    // const [measuresQuestions, setMeasuresQuestions] = useState<MeasuresQuestions[]>([]);

    const [selectedSingleMeasure, setSelectedSingleMeasure] = useState<MeasureGroups>();
    const [focusedSingleMeasure, setFocusedSingleMeasure] = useState<MeasureGroups>();

    const [isDeleteMgModal, setIsDeleteMgModal] = useState<boolean>(false)
    const [isDeleteMeasuresModal, setIsDeleteMeasuresModal] = useState<boolean>(false)
    const [selectedMeasure, setSelectedMeasure] = useState<MeasureList>()

    const [isMeasureGroupModal, setIsMeasureGroupModal] = useState<boolean>(false);
    const [measureGroupPopupDetails, setMeasureGroupPopupDetails] = useState<MeasureGroupPopupDetails>();

    const [isMeasuresModal, setIsMeasuresModal] = useState<boolean>(false);
    const [measuresPopupDetails, setMeasuresPopupDetails] = useState<MeasureGroupPopupDetails>();
    // eslint-disable-next-line 
    const [events, setEvents] = useState<Events[]>([]);
    const [eventsList, seteventsList] = useState<EventsList[]>([]);

    const [isEVENTModal, setIsEVENTModal] = useState<boolean>(false);
    const [eventPopupDetails, setEventPopupDetails] = useState<EventPopupDetails>();

    const isHttpsLink = (eventPopupDetails?.Video?.data?.attributes?.url.startsWith("https://") || measureGroupPopupDetails?.Video?.data?.attributes?.url.startsWith("https://") || measuresPopupDetails?.Video?.data?.attributes?.url.startsWith("https://"))

    const playerRef = React.useRef(null);
    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: isHttpsLink ? (eventPopupDetails?.Video?.data?.attributes?.url || measureGroupPopupDetails?.Video?.data?.attributes?.url || measuresPopupDetails?.Video?.data?.attributes?.url) : "https://" + (eventPopupDetails?.Video?.data?.attributes?.url || measureGroupPopupDetails?.Video?.data?.attributes?.url || measuresPopupDetails?.Video?.data?.attributes?.url),
            type: 'video/mp4'
        }]
    };
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    useEffect(() => {
        if (measureGroupsSelector && measureGroupsSelector.error !== null) {
            setIsWromgOrgModal(true)
        }
    }, [measureGroupsSelector.error])

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        if ((measuresSelector?.status !== null && measuresSelector?.status !== undefined && measuresSelector?.method !== "delete" && measuresSelector?.method !== null) || (addMgSelector?.status !== null && addMgSelector?.status !== undefined && addMgSelector?.method !== "delete" && addMgSelector?.method !== null)) {
            setIsRemoved(false)
            setIsSaved(true);
            setTimeCounter(1);
        } else if ((measuresSelector?.method !== null && measuresSelector?.method !== undefined && measuresSelector?.method === "delete" && (measuresSelector?.method !== "post" || measuresSelector?.method !== "put") || (addMgSelector?.method !== null && addMgSelector?.method !== undefined && addMgSelector?.method === "delete" && (addMgSelector?.method !== "post" || addMgSelector?.method !== "put")))) {
            setIsSaved(false)
            setIsRemoved(true);
            setTimeCounter(1);
        }
    }, [measuresSelector.status, measuresSelector.method, addMgSelector.status, addMgSelector.method])
/* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    const eventsApiCall = () => {
        // get events
        dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, singleRiskCategory));
    }

    useEffect(() => {
        if (measureGroupsSelector.shouldReloadMeasureGroup) {
            dispatch(measureGroupsContainerCreators.requestGetMeasureGroups(riskCategoryId, '', isCancel, '', [], null, singleRiskCategory));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskCategoryId, measureGroupsSelector.shouldReloadMeasureGroup])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        const init = async () => {
            dispatch(riskAreaContainerCreators.requestGetRiskCategories());
            await eventsApiCall()
        }
        init();
    }, [riskCategoryId])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.riskCategory && riskAreaSelector.riskCategory.length && riskCategoryId) {
            const riskCategoryObj = riskAreaSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === riskCategoryId)
            setSingleRiskCategory(riskCategoryObj);
        }
    }, [riskAreaSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (singleRiskCategory && decodedUserInfo)
            dispatch(measureGroupsContainerCreators.requestGetMeasureGroups(singleRiskCategory.riskCategoryId, navigate, isCancel, dispatch, [], decodedUserInfo, singleRiskCategory));
    }, [singleRiskCategory, decodedUserInfo])/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore next */
    useEffect(() => {
        if (EventsSelector && EventsSelector.events) {
            const filteredEventArray = EventsSelector.events.filter((ele: any) => ele?.eventListId && ele.eventId)
            const statusOfSelectedEvent: any = { 1: 1, 0: 2 };
            const sortedEventArr = filteredEventArray.sort(
                (a: Events, b: Events) => statusOfSelectedEvent[a.selected] - statusOfSelectedEvent[b.selected]
            );
            setEvents(sortedEventArr);
            const eventsFilterList = EventsSelector.events.filter((ele: any) => !ele?.eventId);
            seteventsList(eventsFilterList)
        } else {
            setEvents([]);
        }
        if (measureGroupsSelector && measureGroupsSelector.data && measureGroupsSelector.data.length >= 0) {
            const updatedArray = measureGroupsSelector.data.filter((ele: any) => ele?.measuregroupId)
            const statusOfSelected = { true: 1, false: 2 };
            const sortedArr = updatedArray.sort(
                (a: MeasureGroups, b: MeasureGroups) => statusOfSelected[`${a.selected}`] - statusOfSelected[`${b.selected}`]
            );
            const arrWithMeasureShow = sortedArr.map((ele: object) => {
                return { ...ele, measuresShow: false };
            });
            setMeasureGroups(arrWithMeasureShow);
            //const measureGroupFilterList = measureGroupsSelector.data.filter((ele: any) => !ele?.measuregroupId);
            //setMeasureGroupList(measureGroupFilterList)
        }
        if (measureGroupsSelector && measureGroupsSelector.measures && measureGroupsSelector.measures.length >= 0) {
            const measureFilterList = measureGroupsSelector.measures.filter((ele: any) => ele?.measureId);
            const selected1Measure = measureFilterList.filter((ele: any) => ele.selected === 1)
            const selected2Measure = measureFilterList.filter((ele: any) => ele.selected === 2)
            const sortMeasureStatus: any = { 3: 1, 2: 2, 1: 3 };
            const sortedMeasureStatusList = selected2Measure.sort(
                (a: any, b: any) => sortMeasureStatus[a.status] - sortMeasureStatus[b.status]
            );
            setMeasuresList([...sortedMeasureStatusList, ...selected1Measure])
            // const measureQuestionList = measureGroupsSelector.measures.filter((ele: any) => !ele?.measureId);
            // setMeasuresQuestions(measureQuestionList)
        }
    }, [measureGroupsSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (EventsSelector && EventsSelector.eventContent) {
            if (EventsSelector.eventContent.data && EventsSelector.eventContent.data[0] && isCancel !== "0") {
                setEventPopupDetails(EventsSelector.eventContent.data[0].attributes);
                setIsEVENTModal(true);
                setIsMeasureGroupModal(false);
            } else {
                setIsEVENTModal(false);
            }
        } else {
            setIsEVENTModal(false);
        }
    }, [EventsSelector]);/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore next */
    useEffect(() => {
        if (measureGroupsSelector && measureGroupsSelector.measureGroupContent) {
            if (measureGroupsSelector.measureGroupContent.data && measureGroupsSelector.measureGroupContent.data[0] && isCancel !== "0") {
                setMeasureGroupPopupDetails(measureGroupsSelector.measureGroupContent.data[0].attributes);
                setIsEVENTModal(false);
                setIsMeasureGroupModal(true);
            } else {
                setIsMeasureGroupModal(false);
            }
        } else {
            setIsMeasureGroupModal(false);
        }
    }, [measureGroupsSelector]);/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore next */
    useEffect(() => {
        // const measureQuestionList = measuresSelector?.measures ? measuresSelector?.measures?.filter((ele: any) => !ele?.measureId) : [];
        // setMeasuresQuestions(measureQuestionList)
        if (measuresSelector && measuresSelector.measuresContent) {
            if (measuresSelector.measuresContent.data && measuresSelector.measuresContent.data[0]) {
                setMeasuresPopupDetails(measuresSelector.measuresContent.data[0].attributes);
                setIsEVENTModal(false);
                setIsMeasureGroupModal(false);
                setIsMeasuresModal(true);
            } else {
                setIsMeasuresModal(false);
                const singleMeasureGroup = measureGroups?.find((ele: any) => ele?.availableMeasureCount > 0 && ele.selected === true);
                if ((role === "administrator") || (role === "editor")) {
                    if (measureGroupsSelector.measures && measureGroupsSelector.measures.length && isCancel !== '0' && singleRiskCategory && singleMeasureGroup) {
                        if (selectedSingleMeasure) {
                            navigate(`/measures/${id}/add-measures/${selectedSingleMeasure.measuregroupId}`, { state: { measuresList: measureGroupsSelector.measures, singleRiskCategory: singleRiskCategory, addMeasureGroupObj: selectedSingleMeasure } });
                        } else {
                            if (singleMeasureGroup) {
                                if (singleMeasureGroup.measureCount > 0 && singleMeasureGroup.availableMeasureCount > 0) {
                                    navigate(`/measures/${id}/add-measures/${singleMeasureGroup.measuregroupId}`, { state: { measuresList: measureGroupsSelector.measures, singleRiskCategory: singleRiskCategory, addMeasureGroupObj: singleMeasureGroup } });
                                } else if (singleMeasureGroup.measureCount > 0 && singleMeasureGroup.availableMeasureCount === 0) {
                                    dispatch(measureGroupsContainerCreators.requestGetMeasures(riskCategoryId, singleMeasureGroup.measuregroupId, singleRiskCategory, navigate, isCancel, decodedUserInfo, singleRiskCategory));
                                }
                            }
                        }
                    }
                }
            }
        } else {
            setIsMeasuresModal(false);
        }
    }, [measuresSelector]);/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore next */
    useEffect(() => {
        setMeasuresList([])
        if (selectedSingleMeasure) {
            // get measures
            if (selectedSingleMeasure.measureCount === 0 && selectedSingleMeasure.availableMeasureCount > 0) {
                dispatch(measureGroupsContainerCreators.requestGetProdMeasuresContent(ContentType.MEASURES, 'TODO', selectedSingleMeasure, decodedUserInfo));
                dispatch(measureGroupsContainerCreators.requestGetMeasures(riskCategoryId, selectedSingleMeasure.measuregroupId, singleRiskCategory, '', isCancel, decodedUserInfo, singleRiskCategory));
            } else if (selectedSingleMeasure.measureCount > 0 && selectedSingleMeasure.availableMeasureCount > 0) {
                dispatch(measureGroupsContainerCreators.requestGetProdMeasuresContent(ContentType.MEASURES, 'DOING', selectedSingleMeasure, decodedUserInfo));
                dispatch(measureGroupsContainerCreators.requestGetMeasures(riskCategoryId, selectedSingleMeasure.measuregroupId, singleRiskCategory, navigate, isCancel, decodedUserInfo, singleRiskCategory));
            } else if (selectedSingleMeasure.measureCount > 0 && selectedSingleMeasure.availableMeasureCount === 0) {
                dispatch(measureGroupsContainerCreators.requestGetProdMeasuresContent(ContentType.MEASURES, 'DONE', selectedSingleMeasure, decodedUserInfo));
                dispatch(measureGroupsContainerCreators.requestGetMeasures(riskCategoryId, selectedSingleMeasure.measuregroupId, singleRiskCategory, navigate, isCancel, decodedUserInfo, singleRiskCategory));
            }
            // navigate(`/risk-area/${riskCategoryId}?isCancel=9`)
        }
    }, [singleRiskCategory, selectedSingleMeasure])/* eslint-disable react-hooks/exhaustive-deps */

    const getFocus = async () => {
        const element = await document.querySelector("[data-type='focusedMeasureGroup']");
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }
    useEffect(() => {
        if (mgFromCancel && !selectedSingleMeasure) {
            setTimeout(() => {
                if (selectedSingleMeasure) {
                    setFocusedSingleMeasure(undefined)
                } else {
                    dispatch(measureGroupsContainerCreators.requestGetMeasures(riskCategoryId, mgFromCancel.measuregroupId, singleRiskCategory, '', isCancel, decodedUserInfo, singleRiskCategory));
                    setFocusedSingleMeasure(mgFromCancel);
                    getFocus();
                }
            }, 700)
        }
    }, [mgFromCancel])

    // functions

    function editMeasureGroup(mg: MeasureGroups) {
        navigate(`/risk-area/${riskCategoryId}/edit-measure-group/${mg?.measuregroupId}`, { state: { measureGroupList: measureGroupsSelector.data, singleRiskCategory: singleRiskCategory, editMeasureGroupObj: mg } });
    }

    function gotoAddMeasureGroup() {
        navigate(`/risk-area/${riskCategoryId}/add-measure-group`, { state: { measureGroupList: measureGroupsSelector.data, singleRiskCategory: singleRiskCategory } });
    }

    function gotoAddMeasure(mg: object) {
        const selectedMeasureGroup = (focusedSingleMeasure || selectedSingleMeasure);
        navigate(`/measures/${id}/add-measures/${selectedMeasureGroup && selectedMeasureGroup.measuregroupId}`, { state: { measuresList: measureGroupsSelector.measures, singleRiskCategory: singleRiskCategory, addMeasureGroupObj: mg } });
    }

    function gotoEditMeasure(ml: MeasureList, mg: object) {
        const selectedMeasureGroup = (focusedSingleMeasure || selectedSingleMeasure);
        navigate(`/measures/${id}/edit-measures/${selectedMeasureGroup && selectedMeasureGroup.measuregroupId}/${ml?.measureId}`, { state: { measuresList: measureGroupsSelector.measures, singleRiskCategory: singleRiskCategory, editMeasureObj: ml, editMeasureGroupObj: mg, from: "measureGroups" } });
    }


    function handleMeasuresShow(index: number, measuregroupId: string) {

        if (measuregroupId === selectedSingleMeasure?.measuregroupId) {
            setSelectedSingleMeasure(undefined);
            setFocusedSingleMeasure(undefined);
        } else {
            const measureShowList: MeasureGroups[] = [...measureGroups];
            const singleMeasure = measureShowList.find((ele: MeasureGroups) => ele.measuregroupId === measuregroupId)
            if (singleMeasure) {
                setSelectedSingleMeasure(singleMeasure);
                setFocusedSingleMeasure(undefined);
            }
        }
    }

    function getMeasureStatus(ml: MeasureList) {
        if (ml.selected === 2) {
            if (ml.status === 1) {
                return intl.formatMessage({ id: 'relevant_not_started', defaultMessage: 'relevant_not_started' })
            } else if (ml.status === 2) {
                return intl.formatMessage({ id: 'relevant_in_progress', defaultMessage: 'relevant_in_progress' })
            } else if (ml.status === 3) {
                return intl.formatMessage({ id: "relevant_done", defaultMessage: 'relevant_done' })
            }
        }
    }

    function handleMeasuresModalClose() {
        setIsMeasuresModal(false);
        const singleMeasureGroup = measureGroups?.find((ele: any) => ele?.availableMeasureCount > 0 && ele.selected === true);
        if (selectedSingleMeasure) {
            navigate(`/measures/${id}/add-measures/${selectedSingleMeasure.measuregroupId}`, { state: { measuresList: measureGroupsSelector.measures, singleRiskCategory: singleRiskCategory, addMeasureGroupObj: selectedSingleMeasure } });
        } else {
            if (measureGroupsSelector.measures && measureGroupsSelector.measures.length && singleRiskCategory && singleMeasureGroup) {
                navigate(`/measures/${id}/add-measures/${singleMeasureGroup.measuregroupId}`, { state: { measuresList: measureGroupsSelector.measures, singleRiskCategory: singleRiskCategory, addMeasureGroupObj: singleMeasureGroup } });
            }
        }
    }

    function handleMeasureGroupModalClose() {
        setIsMeasureGroupModal(false);
        if (measureGroupsSelector.data && measureGroupsSelector.data.length && isCancel !== '0' && singleRiskCategory) {
            navigate(`/risk-area/${id}/add-measure-group`, { state: { measureGroupList: measureGroupsSelector.data, singleRiskCategory: singleRiskCategory } });
        }
    }

    function handleEventModalClose() {
        setIsEVENTModal(false);
        if (eventsList && eventsList.length && isCancel !== '0' && singleRiskCategory) {
            navigate(`/events/${id}/addevents`, { state: { eventsList: eventsList, singleRiskCategory: singleRiskCategory } });
        }
    }

    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });
        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    // delete MeasureGrups model function
    const handleDeleteMgModalOpen = (mg: MeasureGroups) => {
        setSelectedSingleMeasure(mg)
        setIsDeleteMgModal(true);
    }

    function handleDeleteMgModalClose() {
        setIsDeleteMgModal(false)
    }

    const deleteMeasureGroup = () => {
        dispatch(measureGroupsContainerCreators.requestDeleteMeasureGroup(riskCategoryId, selectedSingleMeasure?.measuregroupId))
        setIsDeleteMgModal(false)
    }

    // delete Measure List model function
    const handleDeleteMeasuresModalOpen = (ml: MeasureList) => {
        setSelectedMeasure(ml);
        setIsDeleteMeasuresModal(true);
    }

    function handleDeleteMeasuresModalClose() {
        setIsDeleteMeasuresModal(false)
    }

    const deleteMeasuresList = () => {
        const currentMeasureGroup = (selectedSingleMeasure || focusedSingleMeasure);
        dispatch(measureGroupsContainerCreators.requestDeleteMeasures(riskCategoryId, currentMeasureGroup?.measuregroupId, selectedMeasure?.measureId))
        setSelectedSingleMeasure(undefined);
        setIsDeleteMeasuresModal(false)
    }

    const goToPrevious = () => {
        navigate(`/events/${id}?isCancel=8`)
    }

    return (
        <>
            {(measureGroupsSelector.loading) ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div>
                    <Container>
                        <div className="flex-grow-1 flex flex-col justify-between">
                            <div>
                                <div className='m-auto w-full pb-7 align-middle items-center flex justify-between'>
                                    <div className="flex items-center font-semibold text-xl">
                                        <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                        <div data-testid={`${testId}-singlerisk-category-name`} className="ml-3 break-all font-semibold text-xl text-[#535353] sm:w-4/5 sm:break-all">
                                            {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                        </div>
                                    </div>
                                    {isSaved &&
                                        <div className="font-normal text-xs text-[#959595] text-end">
                                            <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                        </div>
                                    }
                                    {isRemoved &&
                                        <div className="font-normal text-xs text-[#959595] text-end">
                                            <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                                        </div>
                                    }
                                </div>

                                {/* Navbar */}
                                <div className="mb-10 w-full block" id="navbar-default">
                                    <ul className="flex sm:flex-wrap flex-row mt-[30px] text-xl font-semibold ">
                                        {singleRiskCategory?.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400' &&
                                            <>
                                                <li onClick={() => navigate(`/locations/${id}?isCancel=4`)} data-testid={`${testId}-navigate-location`}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'locations', defaultMessage: 'locations' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-hardware`} onClick={() => navigate(`/hardware/${id}?isCancel=5`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'hardware', defaultMessage: 'hardware' })}</span>
                                                </li>
                                                <li onClick={() => navigate(`/connectivity/${id}?isCancel=6`)} data-testid={`${testId}-navigate-connectivity`}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'connectivity', defaultMessage: 'connectivity' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-software`} onClick={() => navigate(`/software/${id}?isCancel=7`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'software', defaultMessage: 'software' })}</span>
                                                </li>
                                            </>
                                        }

                                        <li data-testid={`${testId}-navigate-events`} onClick={() => navigate(`/events/${id}?isCancel=8`)}>
                                            <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'events', defaultMessage: 'events' })}</span>
                                        </li>
                                        <li data-testid={`${testId}-navigate-risk-area`} onClick={() => navigate(`/risk-area/${id}?isCancel=9`)}>
                                            <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer ${isMeasureGroups && '!text-[#121212]'}`} aria-current="page">{intl.formatMessage({ id: 'measure_groups', defaultMessage: 'measure_groups' })}</span>
                                        </li>

                                    </ul>
                                </div>

                                <div className="border w-full"></div>

                                <div className="my-10 sm:my-6">
                                    <div className="font-semibold text-[32px] mb-8 sm:text-2xl">{intl.formatMessage({ id: 'measure_groups', defaultMessage: 'measure_groups' })}</div>
                                    {/* <Container> */}
                                    <div className="flex flex-col">
                                        {measureGroups.length > 0 ?
                                            <div>
                                                {measureGroups.map((mg, index) => (
                                                    <div data-type={mgFromCancel && mg.measuregroupId === mgFromCancel?.measuregroupId ? 'focusedMeasureGroup' : ''} data-testid={`${testId}-measure-grp-${index}`} key={`${testId}-measure-grp-${mg.measuregroupId}`} className="mb-4 p-6 bg-white border rounded-lg sm:w-full sm:p-3">
                                                        <div className="flex items-center">
                                                            <div className={`w-[6px] h-[6px] rounded-lg ${mg?.selected ? 'bg-green-500' : 'bg-red-500'} mr-1`}></div>
                                                            <div data-testid={`${testId}=relevent-not-relevent`} className="font-bold text-[10px] font-family-arial text-[#959595] uppercase tracking-[1px] mr-1">{mg?.selected ? intl.formatMessage({ id: 'relevant', defaultMessage: 'relevant' }) : intl.formatMessage({ id: 'not_relevant', defaultMessage: 'not_relevant' })}</div>
                                                            {((role === "administrator") || (role === "editor")) ?
                                                                <div className="flex items-center">
                                                                    <img data-testid={`${testId}-edit-mg`} onClick={() => editMeasureGroup(mg)} className="w-[10px] h-[10px] cursor-pointer" src={EditIcon} alt='' />
                                                                    {mg.measuregroupListId === "00000000-0000-0000-0000-000000000000" &&
                                                                        <div data-testid={`${testId}-delete-modal-open`} className="w-max flex ml-2 items-center justify-between cursor-pointer" onClick={() => handleDeleteMgModalOpen(mg)}>
                                                                            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                                    <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                :
                                                                <></>
                                                            }
                                                        </div>
                                                        <div className={`flex items-center justify-between ${((focusedSingleMeasure && mg.measuregroupId === focusedSingleMeasure.measuregroupId) || (selectedSingleMeasure && mg.measuregroupId === selectedSingleMeasure.measuregroupId)) ? 'mb-6 sm:mb-3' : ''}`}>
                                                            {(mg?.name?.length >= 50 || mg?.nameWhenOther?.length >= 50) ?
                                                                <Tooltip placement="bottom" trigger={['hover']} overlay={<span>{mg?.name || mg?.nameWhenOther}</span>}>
                                                                    <div data-testid={`${testId}-handle-measure-show`} onClick={() => mg?.selected && handleMeasuresShow(index, mg.measuregroupId)} className={`app-elip-mg font-semibold text-xl sm:text-sm ${mg?.selected ? 'cursor-pointer' : ''}`}>{mg?.name || mg?.nameWhenOther}</div>
                                                                </Tooltip>
                                                                :
                                                                <div data-testid={`${testId}-measure-show`} onClick={() => mg?.selected && handleMeasuresShow(index, mg.measuregroupId)} className={`app-elip-mg font-semibold text-xl sm:text-sm ${mg?.selected ? 'cursor-pointer' : ''}`}>{mg?.name || mg?.nameWhenOther}</div>
                                                            }
                                                            {mg?.selected &&
                                                                <div data-testid={`${testId}-selected-measure-show`} onClick={() => handleMeasuresShow(index, mg.measuregroupId)} className={`sm:min-w-[98px] flex items-center border rounded-full cursor-pointer ${((focusedSingleMeasure && mg.measuregroupId === focusedSingleMeasure.measuregroupId) || (selectedSingleMeasure && mg.measuregroupId === selectedSingleMeasure.measuregroupId)) ? 'border-[#535353]' : 'border-[#959595]'} px-4 py-1 sm:px-2 sm:py-1`}>
                                                                    <div className={`font-bold font-family-arial text-sm sm:text-[11px] ${((focusedSingleMeasure && mg.measuregroupId === focusedSingleMeasure.measuregroupId) || (selectedSingleMeasure && mg.measuregroupId === selectedSingleMeasure.measuregroupId)) ? 'text-[#535353]' : 'text-[#959595]'} mr-2`}>{mg.measureCount} {intl.formatMessage({ id: 'measures', defaultMessage: 'measures' })}</div>
                                                                    {((focusedSingleMeasure && mg.measuregroupId === focusedSingleMeasure.measuregroupId) || (selectedSingleMeasure && mg.measuregroupId === selectedSingleMeasure.measuregroupId)) ?
                                                                        <img data-testid={`${testId}-close-icon`} src={CloseIcon} alt='' />
                                                                        :
                                                                        <img data-testid={`${testId}-info-icon`} src={InfoIcon} alt='' />
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        {((focusedSingleMeasure && mg.measuregroupId === focusedSingleMeasure.measuregroupId) || (selectedSingleMeasure && mg.measuregroupId === selectedSingleMeasure.measuregroupId)) &&
                                                            <>
                                                                <div className="mb-2">
                                                                    {measuresList.length > 0 ?
                                                                        measuresList?.map((ml, idx) => (
                                                                            <div data-testid={`${testId}-measure-list-${idx}`} key={`${testId}-measure-list-${ml.measureId}`} className={`${idx === 0 ? 'rounded-t-lg' : ''} ${idx + 1 === measuresList.length ? 'rounded-b-lg' : ''} flex item-center justify-between w-full px-4 py-[14px] bg-[#FAFAFA] border`}>
                                                                                <div className="font-semibold text-sm sm:text-xs">{ml?.name || ml?.nameWhenOther}</div>
                                                                                <div className="flex items-center">
                                                                                    <div className="font-normal text-xs mr-2 tracking-[0.25px] font-family-arial">{`${ml?.selected === 1 ? intl.formatMessage({ id: 'not_relevant' }) : getMeasureStatus(ml)}`}</div>
                                                                                    {((role === "administrator") || (role === "editor")) ? <div className="flex items-center"><img onClick={() => gotoEditMeasure(ml, mg)} className="w-[12px] h-[12px] cursor-pointer" src={EditIcon} alt='' />

                                                                                        {ml.measureListId === "00000000-0000-0000-0000-000000000000" &&
                                                                                            <div className="w-max flex ml-2 items-center justify-between cursor-pointer" onClick={() => handleDeleteMeasuresModalOpen(ml)} >
                                                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                                                        <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </div>
                                                                                        }

                                                                                    </div> : <img onClick={() => gotoEditMeasure(ml, mg)} className="w-[12px] h-[12px] cursor-pointer" src={InfoIcon} alt='' />}
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                        :
                                                                        <>
                                                                            {measureGroupsSelector.measureLoading &&
                                                                                <div className='w-full flex items-center justify-center'>
                                                                                    <LoadingIcon />
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                                {((role === "administrator") || (role === "editor")) ? <StyledButton onClick={() => gotoAddMeasure(mg)} buttonClass={'border-[#535353] px-4'} flexReverse={'flex-row-reverse'} width={'w-max'} height={'h-8'} align={'justify-start'} text={intl.formatMessage({ id: 'add_measure', defaultMessage: 'add_measure' })} color={'text-[#535353] text-sm font-bold'} iconType={'add'} backgroundColor={'bg-white'} /> : <></>}
                                                            </>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className="flex items-center justify-center min-h-[30vh]">
                                            </div>
                                        }
                                        {((role === "administrator") || (role === "editor")) ? <div className="mt-4 sm:mt-2">
                                            <StyledButton testId={`${testId}-goto-add-mg`} onClick={() => gotoAddMeasureGroup()} buttonClass={'border-[#535353] px-4'} flexReverse={'flex-row-reverse'} width={'w-max sm:w-full'} height={'h-12 sm:h-10'} align={'justify-start'} text={intl.formatMessage({ id: 'add_measure_group', defaultMessage: 'add_measure_group' })} color={'text-[#535353] font-bold'} iconType={'add'} backgroundColor={'bg-[#FAFAFA]'} />
                                        </div> : <></>}
                                    </div>
                                    {/* </Container> */}
                                </div>
                            </div>
                            <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                                <StyledButton
                                    testId={`${testId}-previous-btn`}
                                    width={'w-48 sm:w-40'}
                                    align={'justify-start'}
                                    text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                    color={'text-gray-700'}
                                    flexReverse={'flex-row-reverse'}
                                    iconType={'arrowleft'}
                                    backgroundColor={'bg-gray-300'}
                                    onClick={goToPrevious}
                                />
                            </div>
                        </div>
                    </Container>
                </div>
            }

            <Modal show={isEVENTModal} onClose={() => handleEventModalClose()}>
                {(EventsSelector.loading) ?
                    <div className='app-loader'>
                        <LoadingIcon />
                    </div>
                    :
                    <div className={eventPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                        <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer">
                            {eventPopupDetails?.Intro}
                        </div>
                        <div className="font-semibold text-xl text-[#121212] mb-6">
                            {eventPopupDetails?.Header}
                        </div>
                        {eventPopupDetails?.Video?.data &&
                            <div className="mb-10">
                                <VideoJS options={options} onReady={handlePlayerReady} />
                            </div>
                        }
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(eventPopupDetails?.Body)}</ReactMarkdown>
                        <div className="flex items-center justify-between">
                            {eventPopupDetails?.Button2 && <StyledButton onClick={() => eventPopupDetails?.Link2 ? navigate(eventPopupDetails?.Link2) : handleEventModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={eventPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                            {eventPopupDetails?.Button && <StyledButton onClick={() => eventPopupDetails?.Link ? navigate(eventPopupDetails?.Link) : handleEventModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={eventPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        </div>
                    </div>
                }
            </Modal>

            <Modal show={isMeasureGroupModal} onClose={() => handleMeasureGroupModalClose()}>
                {(measureGroupsSelector.loading) ?
                    <div className='app-loader'>
                        <LoadingIcon />
                    </div>
                    :
                    <div className={measureGroupPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                        <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                            {measureGroupPopupDetails?.Intro}
                        </div>
                        <div className="font-semibold text-xl text-[#121212] mb-6">
                            {measureGroupPopupDetails?.Header}
                        </div>
                        {measureGroupPopupDetails?.Video?.data &&
                            <div className="mb-10">
                                <VideoJS options={options} onReady={handlePlayerReady} />
                            </div>
                        }
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(measureGroupPopupDetails?.Body)}</ReactMarkdown>
                        <div className="flex items-center justify-between">
                            {measureGroupPopupDetails?.Button2 && <StyledButton onClick={() => measureGroupPopupDetails?.Link2 ? navigate(measureGroupPopupDetails?.Link2) : handleMeasureGroupModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={measureGroupPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                            {measureGroupPopupDetails?.Button && <StyledButton onClick={() => measureGroupPopupDetails?.Link ? navigate(measureGroupPopupDetails?.Link) : handleMeasureGroupModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={measureGroupPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        </div>
                    </div>
                }
            </Modal>

            <Modal show={isMeasuresModal} onClose={() => handleMeasuresModalClose()}>
                {(measuresSelector.loading) ?
                    <div className='app-loader'>
                        <LoadingIcon />
                    </div>
                    :
                    <div className={measuresPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                        <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                            {measuresPopupDetails?.Intro}
                        </div>
                        <div className="font-semibold text-xl text-[#121212] mb-6">
                            {measuresPopupDetails?.Header}
                        </div>
                        {measuresPopupDetails?.Video?.data &&
                            <div className="mb-10">
                                <VideoJS options={options} onReady={handlePlayerReady} />
                            </div>
                        }
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(measuresPopupDetails?.Body)}</ReactMarkdown>
                        <div className="flex items-center justify-between">
                            {measuresPopupDetails?.Button2 && <StyledButton onClick={() => measuresPopupDetails?.Link2 ? navigate(measuresPopupDetails?.Link2) : handleMeasuresModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={measuresPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                            {measuresPopupDetails?.Button && <StyledButton onClick={() => measuresPopupDetails?.Link ? navigate(measuresPopupDetails?.Link) : handleMeasuresModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={measuresPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        </div>
                    </div>
                }
            </Modal>

            <div>
                <Modal show={isDeleteMgModal} onClose={() => handleDeleteMgModalClose()}>
                    <div>{`${intl.formatMessage({ id: 'are_you_sure', defaultMessage: 'are_you_sure' })}`}</div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-cancel-delete-modal-mg`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => handleDeleteMgModalClose()}
                        />
                        <StyledButton
                            testId={`${testId}-delete-mg`}
                            buttonClass={'PrimoryBtn'}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "delete", defaultMessage: 'delete' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => deleteMeasureGroup()}
                        />
                    </div>
                </Modal>
            </div>

            <div>
                <Modal show={isDeleteMeasuresModal} onClose={() => handleDeleteMeasuresModalClose()}>
                    <div>{`${intl.formatMessage({ id: 'are_you_sure', defaultMessage: 'are_you_sure' })}`}</div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => handleDeleteMeasuresModalClose()}
                        />
                        <StyledButton
                            buttonClass={'PrimoryBtn'}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "delete", defaultMessage: 'delete' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => deleteMeasuresList()}
                        />
                    </div>
                </Modal>
                <Modal show={isWromgOrgModal} onClose={() => ""}>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                    </div>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                    </div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-goto-dashboard-bia`}
                            onClick={() => handleWrongOrgModel()}
                            buttonClass={'PrimoryBtn'}
                            width={"w-[220px] sm:w-40"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                            color={"text-white mr-3 text-base"}
                            iconType={'home'}
                            backgroundColor={"bg-black"}
                        />
                    </div>
                </Modal>
            </div>

        </>
    );
}