import LoadingIcon from '../../assets/icons/loading-icon';
import LoginArrowIcon from '../../assets/icons/login-arrow-icon.svg';
import BlackArrowIcon from '../../assets/icons/arrow-icon.svg';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';
import WhiteArrowLeftIcon from '../../assets/icons/white-arrow-left.svg';
import AddIcon from '../../assets/icons/plus-icon.svg';
import HomeIcon from '../../assets/icons/home.svg';
import OrganisationIcon from '../../assets/icons/org-icon.svg';
import BIAIcon from '../../assets/icons/bia-icon.svg';
import RiskIcon from '../../assets/icons/risk-icon.svg';
import DownloadGreyIcon from '../../assets/icons/download-grey-icon.svg';
import DownloadWhiteIcon from '../../assets/icons/download-white-icon.svg';

type Props = {
  text: string;
  iconType?: string;
  disabled?: boolean;
  width: string;
  height?: string;
  align: string;
  customClass?: string;
  flexReverse?: string;
  buttonClass?: string;
  color: string;
  backgroundColor: string;
  onClick?: () => void;
  loading?: boolean | null;
  testId?: string;
}

export default function StyledButton(props: Props) {

  const {
    text,
    iconType,
    disabled,
    width,
    height = 'h-12',
    align,
    customClass = '',
    flexReverse = '',
    buttonClass = '',
    color,
    backgroundColor,
    onClick,
    loading = false,
    testId = 'style-button'
  } = props;

  const getIcon = () => {
    if (iconType === 'arrow') {
      return LoginArrowIcon;
    }
    if (iconType === 'blackarrow') {
      return BlackArrowIcon;
    }
    if (iconType === 'arrowleft') {
      return ArrowLeftIcon;
    }
    if (iconType === 'whitearrowleft') {
      return WhiteArrowLeftIcon;
    }
    if (iconType === 'add') {
      return AddIcon;
    }
    if (iconType === 'home') {
      return HomeIcon;
    }
    if (iconType === 'organisation') {
      return OrganisationIcon;
    }
    if (iconType === 'bia') {
      return BIAIcon;
    }
    if (iconType === 'risk') {
      return RiskIcon;
    }
    if (iconType === 'downloadgrey') {
      return DownloadGreyIcon;
    }
    if (iconType === 'downloadwhite') {
      return DownloadWhiteIcon;
    }
    return '';
  };

  const iconUrl = getIcon();

  const isButtonDisabled = disabled ? disabled : loading ? loading : false;

  return (
    <div data-testid={`${testId}-btn`} className={`w-full flex h-[52px] items-end ${align} ${customClass}`}>
      <button data-testid={testId} disabled={isButtonDisabled} onClick={() => onClick && onClick()} className={`${backgroundColor} border rounded-3xl ${width} ${height} flex ${flexReverse} items-center justify-center ${buttonClass} ${isButtonDisabled ? 'btn-Disable-removedBorder disabled:border-none disabled:opacity-60 cursor-not-allowed' : 'cursor-pointer'}`}>
        {
          loading && <LoadingIcon />
        }
        <span data-testid={`${testId}-style-btn-text`} className={`${color} mx-2 font-family-arial font-semibold`}>{text}</span>
        {iconUrl ? <img data-testid={`${testId}-style-btn-img`} className='w-4 h-4' src={iconUrl} alt='' /> : null}
      </button>
    </div>
  );
}
