import { produce } from 'immer';
import { createActions } from 'reduxsauce';
import { Address } from './Step2';
import { Product } from './Step3';
import { Process } from './step4';

export const { Types: listOptionsContainerTypes, Creators: listOptionsContainerCreators } = createActions({
    requestGetListOptionsBusiness: [],
    successGetListOptionsBusiness: ['data'],
    failureGetListOptionsBusiness: ['error'],
    requestGetListOptionsEmployees: [],
    successGetListOptionsEmployees: ['data'],
    failureGetListOptionsEmployees: ['error'],
    requestGetListOptionsRevenue: [],
    successGetListOptionsRevenue: ['data'],
    failureGetListOptionsRevenue: ['error'],
    requestGetListOptionsProcesses: [],
    successGetListOptionsProcesses: ['data'],
    failureGetListOptionsProcesses: ['error'],
    requestGetOrganization: [],
    successGetOrganization: ['organization'],
    failureGetOrganization: ['error'],

    requestGetRoles: ['organizationId', 'userId'],
    successGetRoles: ['data'],
    failureGetRoles: ['error'],

    requestDeleteUser: ['organizationId', 'userId'],
    successDeleteUser: ['data', 'method'],
    failureDeleteUser: ['error'],

    requestPutRole: ['organizationId', 'userId', 'data'],
    successPutRole: ['data'],
    failurePutRole: ['error'],

    requestPutOrganization: ['data'],
    successPutOrganization: ['data', 'status', 'method'],
    failurePutOrganization: ['error'],

    requestGetAddresses: ['dispatch', 'decodedUserInfo', 'intl'],
    successGetAddresses: ['data'],
    failureGetAddresses: ['error'],

    requestAddAddress: ['data'],
    successAddAddress: ['data', 'status', 'method'],
    failureAddAddress: ['error'],

    requestDeleteAddress: ['data'],
    successDeleteAddress: ['data', 'method'],
    failureDeleteAddress: ['error'],

    requestPutAddress: ['id', 'data'],
    successPutAddress: ['data', 'status', 'method'],
    failurePutAddress: ['error'],

    requestGetProducts: [''],
    successGetProducts: ['data'],
    failureGetProducts: ['error'],

    requestAddProduct: ['data'],
    successAddProduct: ['data', 'status', 'method'],
    failureAddProduct: ['error'],

    requestDeleteProduct: ['data'],
    successDeleteProduct: ['data', 'method'],
    failureDeleteProduct: ['error'],

    requestPutProduct: ['id', 'data'],
    successPutProduct: ['data', 'status', 'method'],
    failurePutProduct: ['error'],

    requestGetProcesses: ['navigate', 'isCancel'],
    successGetProcesses: ['data'],
    failureGetProcesses: ['error'],

    requestAddProcess: ['data'],
    successAddProcess: ['data', 'status', 'method'],
    failureAddProcess: ['error'],

    requestDeleteProcess: ['data'],
    successDeleteProcess: ['data', 'method'],
    failureDeleteProcess: ['error'],

    requestPutProcess: ['id', 'data', 'navigate'],
    successPutProcess: ['data', 'status', 'method'],
    failurePutProcess: ['error'],

    // get User
    requestGetOrganizationUsers: ["id"],
    successGetOrganizationUsers: ["data"],
    failureGetOrganizationUsers: ["error"],

    // put users
    requestPutUsers: ['userId', 'data', 'dispatch', 'setShowEditEmailToast', 'page'],
    successPutUsers: ['data', 'status', 'method'],
    failurePutUsers: ['error'],

    // get Invite user
    requestGetInviteUser: [],
    successGetInviteUser: ['inviteUsers'],
    failureGetInviteUser: ['error'],

    // post Invite user
    requestPostInviteUser: ["data", "resetData"],
    successPostInviteUser: ['inviteUsers', 'status', 'method'],
    failurePostInviteUser: ['error'],

    // delete Invite user
    requestDeleteInviteUser: ["inviteId"],
    successDeleteInviteUser: ['inviteUsers', 'method'],
    failureDeleteInviteUser: ['error'],

    // post resend Invite user
    requestResendInviteUser: ["inviteId", "setShowToast", "setIsDisable"],
    successResendInviteUser: ['resendInviteUsers', 'status', 'method'],
    failureResendInviteUser: ['error'],

    // get user account info
    requestGetAccountInfo: ["userId"],
    successGetAccountInfo: ["accountInfo"],
    failureGetAccountInfo: ["error"],

    // get Invite By ID user
    requestGetInviteUserById: ["inviteId", "setIsCreateAccount", "navigate", "intl"],
    successGetInviteUserById: ['useInvite'],
    failureGetInviteUserById: ['error'],

    // get Current Organisation Info
    requestGetCurrentOrgInfo: ["organizationId", "dispatch", "intl"],
    successGetCurrentOrgInfo: ["currentOrgInfo"],
    failureGetCurrentOrgInfo: ["error"],

    // put Current Organisation Info
    requestPutCurrentOrgInfo: ["organizationId", "data"],
    successPutCurrentOrgInfo: ["currentOrgInfo", "status", 'method'],
    failurePutCurrentOrgInfo: ["error"],

    // get User Organisation List
    requestGetUserOrgList: ["userId"],
    successGetUserOrgList: ["userOrgList"],
    failureGetUserOrgList: ["error"],

    // delete User Organisation 
    requestDeleteUserOrg: ["organizationId", "setShowDeleteCurrentOrgToast", "navigate"],
    successDeleteUserOrg: ["userOrgList", "method"],
    failureDeleteUserOrg: ["error"],

    // Post Add USer To Org By Invite
    requestAddUserToOrgByInvite: ["userId", "inviteId", "setShowToast"],
    successAddUserToOrgByInvite: ['inviteUsers'],
    failureAddUserToOrgByInvite: ['error'],

    // delete User Organisation List
    requestDeleteOrgFromList: ["payload", "organizationId", "setShowDeleteBtnToast", "dispatch", "navigate"],
    successDeleteOrgFromList: ["userOrgList", "method"],
    failureDeleteOrgFromList: ["error"],

    // post new invite user
    requestSetInviteUser: ['isOrgExist', 'data', 'setShowToast', 'navigate'],
    successSetInviteUser: ['createdUser'],
    failureSetInviteUser: ['createAccountFromInviteError'],

    // get Users linked to org
    requestGetUserLinkToOrg: ["organizationId"],
    successGetUserLinkToOrg: ["linkedUserList"],
    failureGetUserLinkToOrg: ["error"],

    // get Users linked to org
    requestCreateNewClientOrg: ["organizationId", "data", "setShowAddClientToast", "onCancel", "navigate"],
    successCreateNewClientOrg: ["newClientOrg", 'status', 'method'],
    failureCreateNewClientOrg: ["createNewClientOrgError"],

    // get client org list
    requestGetClientOrgList: ["organizationId"],
    successGetClientOrgList: ["clientOrgList"],
    failureGetClientOrgList: ["error"],

    // get rolelimits
    requestGetUsersRoleLimits: ['organizationId'],
    successGetUsersRoleLimits: ['roleLimits'],
    failureGetUsersRoleLimits: ['error'],

    // get software storage
    requestGetSoftwareStorage: ['typeId'],
    successGetSoftwareStorage: ['softwareStorage'],
    failureGetSoftwareStorage: ['error'],

    // get software backup
    requestGetSoftwareBackup: ['typeId'],
    successGetSoftwareBackup: ['softwareBackup'],
    failureGetSoftwareBackup: ['error'],

    // get All Client Organisation
    requestGetAllClientOrg: ['organizationId', 'clientOrganizationId'],
    successGetAllClientOrg: ['allClientOrgList'],
    failureGetAllClientOrg: ['error'],

    // put Client Organisation
    requestPutClientOrg: ['organizationId', 'clientOrganizationId', 'data', 'onCancel', 'navigate'],
    successPutClientOrg: ['allClientOrgList'],
    failurePutClientOrg: ['error'],

    // delete Client Organisation
    requestDeleteClient: ['organizationId', 'clientOrganizationId'],
    successDeleteClient: ['allClientOrgList', 'status', 'method'],
    failureDeleteClient: ['error'],

    //get link Partner Organisation
    requestGetLinkedPartnerOrg: ['organizationId', 'userId'],
    successGetLinkedPartnerOrg: ['linkPartnerOrgList'],
    failureGetLinkedPartnerOrg: ['error'],

    // delete user from Client Organisation
    requestDeleteUserFromClientOrg: ['organizationId', 'userId', 'clientOrganizationId'],
    successDeleteUserFromClientOrg: ['UserFromClientOrgList'],
    failureDeleteUserFromClientOrg: ['error'],

    // post user from Client Organisation
    requestPostLinkedPartnerOrgToUser: ['organizationId', 'userId', 'data', 'setClientsListModal', 'setErrorMessage'],
    successPostLinkedPartnerOrgToUser: ['postUser'],
    failurePostLinkedPartnerOrgToUser: ['error'],

    // putOrg
    requestPutOrgUser: ['organizationId', 'userId', 'data'],
    successPutOrgUser: ['data', 'status', 'method'],
    failurePutOrgUser: ['error'],

    //get Otp
    requestGetOtp: [],
    successGetOtp: ['qrData'],
    failureGetOtp: ['error'],

    //post varify Otp
    requestPostVarifyOtp: ['code', 'navigate'],
    successPostVarifyOtp: ['varifyOtpData'],
    failurePostVarifyOtp: ['error'],

    //post validate Otp
    requestPostValidateOtp: ['code', 'navigate'],
    successPostValidateOtp: ['validateOtpData'],
    failurePostValidateOtp: ['error'],

    //post validate Otp
    requestPostValidateTwofaToken: ['code', 'navigate'],
    successPostValidateTwofaToken: ['validateTwoFaToken'],
    failurePostValidateTwofaToken: ['error'],

    //post notification
    requestPostNotification: ['data'],
    successPostNotification: ['notification'],
    failurePostNotification: ['error'],

    //post notification message
    requestPostNotificationMessage: ['data'],
    successPostNotificationMessage: ['notificationMessage'],
    failurePostNotificationMessage: ['error'],

});

export const { Types: clearListOptionContainerTypes, Creators: clearListOptionContainerCreators } = createActions({
    //clear status
    clearListOptionStatus: []
});

export const { Types: clearErrorTypes, Creators: clearErrorrCreators } = createActions({
    //clear error
    clearError: []
});

export type InitialStateType = {
    listOptionsBusiness: object[] | null;
    listOptionsEmployees: object[] | null;
    listOptionsRevenue: object[] | null;
    listOptionsProcesses: object[] | null;
    error: string | null;
    inviteError: string | null,
    createAccountFromInviteError: string | null;
    loading: boolean | null;
    organization: object | null,
    roles: object | null,
    isReloadUserList: boolean,
    user: object | null,
    currentOrgInfo: object | null,
    addresses: Address[] | null,
    shouldRealoadAddresses?: boolean,
    products: Product[],
    shouldRealoadProducts?: boolean,
    processes: Process[],
    shouldRealoadProcesses?: boolean,
    data: object | null;
    organizationUsers: object[] | null;
    inviteUsers: object[] | null;
    isReloadInviteUserList: boolean;
    resendInviteUsers: object[] | null;
    accountInfo: object | null;
    useInvite: boolean | null;
    userOrgList: object[] | null;
    isReloadOrgInfo: boolean;
    createdUser: object | null;
    linkedUserList: object[] | null;
    newClientOrg: object | null;
    createNewClientOrgError: string | null;
    isReloadOrgList: boolean;
    roleLimits: object[] | null;
    isReloadRoleLimit: boolean;
    softwareStorage: object[] | null;
    softwareBackup: object[] | null;
    clientOrgList: object[] | null;
    allClientOrgList: object | null;
    linkPartnerOrgList: object | null;
    shouldRealoadClientOrg?: boolean,
    shouldRealoadLinkedUser?: boolean,
    UserFromClientOrgList: object | null;
    isReloadClientsList: boolean,
    postUser: object | null;
    isReloadVarifyOtp?: boolean;
    varifyOtpData?: object | null;
    qrData?: object | null;
    validateOtpData?: object | null;
    validateTwoFaToken?: object | null;
    status?: number | null;
    method?: string | null;
}

export const initialState: InitialStateType = {
    listOptionsBusiness: [],
    listOptionsEmployees: [],
    listOptionsRevenue: [],
    listOptionsProcesses: [],
    error: null,
    inviteError: null,
    createAccountFromInviteError: null,
    loading: null,
    organization: null,
    roles: null,
    isReloadUserList: false,
    user: null,
    currentOrgInfo: null,
    addresses: null,
    shouldRealoadAddresses: false,
    products: [],
    shouldRealoadProducts: false,
    processes: [],
    shouldRealoadProcesses: false,
    data: null,
    organizationUsers: null,
    inviteUsers: null,
    isReloadInviteUserList: false,
    resendInviteUsers: null,
    accountInfo: null,
    useInvite: null,
    userOrgList: null,
    isReloadOrgInfo: false,
    createdUser: null,
    linkedUserList: null,
    newClientOrg: null,
    createNewClientOrgError: null,
    isReloadOrgList: false,
    roleLimits: null,
    isReloadRoleLimit: false,
    softwareStorage: null,
    softwareBackup: null,
    clientOrgList: null,
    allClientOrgList: null,
    linkPartnerOrgList: null,
    shouldRealoadClientOrg: false,
    shouldRealoadLinkedUser: false,
    UserFromClientOrgList: null,
    isReloadClientsList: false,
    postUser: null,
    isReloadVarifyOtp: false,
    varifyOtpData: null,
    qrData: null,
    validateOtpData: null,
    validateTwoFaToken: null,
    status: null,
    method: null,
};

export const listOptionsContainerReducer = (state = initialState, action: any) =>
    produce(state, (draft) => {
        switch (action.type) {
            case listOptionsContainerTypes.REQUEST_GET_LIST_OPTIONS_BUSINESS:
            case listOptionsContainerTypes.REQUEST_GET_LIST_OPTIONS_EMPLOYEES:
            case listOptionsContainerTypes.REQUEST_GET_LIST_OPTIONS_REVENUE:
            case listOptionsContainerTypes.REQUEST_GET_LIST_OPTIONS_PROCESSES:
            case listOptionsContainerTypes.REQUEST_GET_ROLES:
            case listOptionsContainerTypes.REQUEST_DELETE_USER:
                draft.method = null;
                draft.status = null;
                draft.loading = true;
                draft.error = null;
                draft.isReloadUserList = false;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.REQUEST_ADD_ADDRESS:
                draft.loading = true;
                draft.error = null;
                draft.isReloadUserList = false;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.REQUEST_GET_ADDRESSES:
                draft.method = null;
                draft.status = null;
                draft.addresses = null;
                draft.loading = true;
                draft.error = null;
                draft.isReloadUserList = false;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.REQUEST_DELETE_ADDRESS:
                draft.status = null;
                draft.loading = true;
                draft.error = null;
                draft.isReloadUserList = false;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.REQUEST_PUT_ADDRESS:
                draft.loading = true;
                draft.error = null;
                draft.isReloadUserList = false;
                draft.shouldRealoadAddresses = false;
                break;

            case listOptionsContainerTypes.REQUEST_GET_PRODUCTS:
                draft.method = null;
                draft.status = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadProducts = false;
                draft.status = null;
                break;

            case listOptionsContainerTypes.REQUEST_ADD_PRODUCT:
            case listOptionsContainerTypes.REQUEST_DELETE_PRODUCT:
            case listOptionsContainerTypes.REQUEST_PUT_PRODUCT:
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadProducts = false;
                break;
            case listOptionsContainerTypes.REQUEST_GET_PROCESSES:
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadProcesses = false;
                draft.processes = [];
                break;
            case listOptionsContainerTypes.REQUEST_ADD_PROCESS:
            case listOptionsContainerTypes.REQUEST_DELETE_PROCESS:
            case listOptionsContainerTypes.REQUEST_PUT_PROCESS:
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadProcesses = false;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_LIST_OPTIONS_BUSINESS:
                draft.listOptionsBusiness = action.data;
                draft.loading = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_LIST_OPTIONS_EMPLOYEES:
                draft.listOptionsEmployees = action.data;
                draft.loading = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_LIST_OPTIONS_REVENUE:
                draft.listOptionsRevenue = action.data;
                draft.loading = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_LIST_OPTIONS_PROCESSES:
                draft.listOptionsProcesses = action.data;
                draft.loading = false;
                draft.error = null;
                break;

            //get Organization
            case listOptionsContainerTypes.REQUEST_GET_ORGANIZATION:
                draft.loading = true;
                draft.data = null;
                draft.organization = null;
                draft.error = null;
                draft.isReloadUserList = false;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_ORGANIZATION:
                draft.data = null;
                draft.organization = action.organization;
                draft.loading = false;
                draft.error = null;
                draft.isReloadUserList = false;
                break;
            case listOptionsContainerTypes.FAILURE_GET_ORGANIZATION:
                draft.loading = false;
                draft.data = null;
                draft.organization = null;
                draft.error = action.error;
                draft.isReloadUserList = false;
                draft.shouldRealoadAddresses = false;
                break;

            case listOptionsContainerTypes.SUCCESS_GET_ROLES:
                draft.roles = action.data;
                draft.loading = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_DELETE_USER:
                draft.method = action.method;
                draft.user = action.data;
                draft.loading = false;
                draft.isReloadUserList = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.REQUEST_PUT_ROLE:
                draft.user = action.data;
                draft.loading = false;
                draft.isReloadUserList = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_PUT_ROLE:
                draft.user = action.data;
                draft.loading = false;
                draft.isReloadUserList = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_PUT_ROLE:
                draft.user = action.data;
                draft.loading = false;
                draft.isReloadUserList = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_ADDRESSES:
                draft.method = null;
                draft.status = null;
                draft.addresses = action.data;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_PRODUCTS:
                draft.method = null;
                draft.status = null;
                draft.products = action.data;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadProducts = false;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_PROCESSES:
                draft.status = null;
                draft.method = null;
                draft.processes = action.data;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadProcesses = false;
                break;
            case listOptionsContainerTypes.SUCCESS_ADD_ADDRESS:
                draft.method = action.method;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = true;
                draft.status = action.status;
                draft.method = action.method;
                break;
            case listOptionsContainerTypes.SUCCESS_DELETE_ADDRESS:
                draft.method = action.method;
                draft.status = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = true;
                draft.status = action.status;
                break;
            case listOptionsContainerTypes.SUCCESS_PUT_ADDRESS:
                draft.method = action.method;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = true;
                draft.status = action.status;
                draft.method = action.method;
                break;

            case listOptionsContainerTypes.SUCCESS_ADD_PRODUCT:
                draft.method = action.method;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadProducts = true;
                draft.status = action.status;
                break;

            case listOptionsContainerTypes.SUCCESS_DELETE_PRODUCT:
                draft.method = action.method;
                draft.status = action.status;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadProducts = true;
                break;
            case listOptionsContainerTypes.SUCCESS_PUT_PRODUCT:
                draft.method = action.method;
                draft.status = action.status;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadProducts = true;
                break;
            case listOptionsContainerTypes.SUCCESS_ADD_PROCESS:
                draft.method = action.method;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadProcesses = true;
                draft.status = action.status;
                break;

            case listOptionsContainerTypes.SUCCESS_DELETE_PROCESS:
                draft.method = action.method;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadProcesses = true;
                break;
            case listOptionsContainerTypes.SUCCESS_PUT_PROCESS:
                draft.method = action.method;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadProcesses = true;
                draft.status = action.status;
                break;
            case listOptionsContainerTypes.FAILURE_GET_LIST_OPTIONS_BUSINESS:
            case listOptionsContainerTypes.FAILURE_GET_LIST_OPTIONS_EMPLOYEES:
            case listOptionsContainerTypes.FAILURE_GET_LIST_OPTIONS_REVENUE:
            case listOptionsContainerTypes.FAILURE_GET_LIST_OPTIONS_PROCESSES:
            case listOptionsContainerTypes.FAILURE_GET_ROLES:
            case listOptionsContainerTypes.FAILURE_DELETE_USER:
                draft.method = null;
                draft.status = null;
                // draft.method=action.method;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadProcesses = true;
                break;
            case listOptionsContainerTypes.FAILURE_ADD_ADDRESS:
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadUserList = true;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.FAILURE_GET_ADDRESSES:
                draft.addresses = null;
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadUserList = true;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.FAILURE_DELETE_ADDRESS:
                draft.status = null;
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadUserList = true;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.FAILURE_PUT_ADDRESS:
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadUserList = true;
                draft.shouldRealoadAddresses = false;
                break;
            case listOptionsContainerTypes.FAILURE_GET_PRODUCTS:
                draft.method = null;
                draft.status = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadProducts = false;
                break;
            case listOptionsContainerTypes.FAILURE_ADD_PRODUCT:
            case listOptionsContainerTypes.FAILURE_DELETE_PRODUCT:
            case listOptionsContainerTypes.FAILURE_PUT_PRODUCT:
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadProducts = false;
                break;
            case listOptionsContainerTypes.FAILURE_GET_PROCESSES:
            case listOptionsContainerTypes.FAILURE_ADD_PROCESS:
            case listOptionsContainerTypes.FAILURE_DELETE_PROCESS:
            case listOptionsContainerTypes.FAILURE_PUT_PROCESS:
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadProcesses = false;
                break;

            //put users 
            case listOptionsContainerTypes.REQUEST_PUT_USERS:
                draft.status = null;
                draft.loading = true;
                draft.data = null;
                draft.isReloadUserList = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_PUT_USERS:
                draft.method = action.method;
                draft.loading = false;
                draft.data = action.data;
                draft.isReloadUserList = true;
                draft.error = null;
                draft.status = action.status;
                break;
            case listOptionsContainerTypes.FAILURE_PUT_USERS:
                draft.status = null;
                draft.loading = false;
                draft.data = null;
                draft.isReloadUserList = false;
                draft.error = action.error;
                break;


            //get OrganizationUser
            case listOptionsContainerTypes.REQUEST_GET_ORGANIZATION_USERS:
                draft.organizationUsers = null;
                draft.isReloadUserList = false;
                draft.loading = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_ORGANIZATION_USERS:
                draft.organizationUsers = action.data;
                draft.isReloadUserList = false;
                draft.loading = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_ORGANIZATION_USERS:
                draft.organizationUsers = null;
                draft.isReloadUserList = false;
                draft.loading = false;
                draft.error = action.error;
                break;

            //Invites get Users
            case listOptionsContainerTypes.REQUEST_GET_INVITE_USER:
                draft.method = null;
                draft.status = null;
                draft.loading = true;
                draft.inviteUsers = null;
                draft.isReloadInviteUserList = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_INVITE_USER:
                draft.method = null;
                draft.status = null;
                draft.loading = false;
                draft.inviteUsers = action.inviteUsers;
                draft.isReloadInviteUserList = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_INVITE_USER:
                draft.status = null;
                draft.loading = false;
                draft.inviteUsers = null;
                draft.isReloadInviteUserList = false;
                draft.error = action.error;
                break;

            //Invites post Users
            case listOptionsContainerTypes.REQUEST_POST_INVITE_USER:
                draft.status = null;
                draft.loading = true;
                draft.isReloadInviteUserList = false;
                draft.isReloadRoleLimit = false;
                draft.inviteUsers = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_POST_INVITE_USER:
                draft.method = action.method;
                draft.status = action.status;
                draft.loading = false;
                draft.isReloadInviteUserList = true;
                draft.isReloadRoleLimit = true;
                draft.inviteUsers = action.inviteUsers;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_POST_INVITE_USER:
                draft.status = null;
                draft.loading = false;
                draft.isReloadInviteUserList = false;
                draft.isReloadRoleLimit = false;
                draft.inviteUsers = null;
                draft.error = action.error;
                break;

            //Invites delete Users
            case listOptionsContainerTypes.REQUEST_DELETE_INVITE_USER:
                draft.status = null;
                draft.method = null;
                draft.loading = true;
                draft.isReloadInviteUserList = false;
                draft.isReloadRoleLimit = false;
                draft.inviteUsers = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_DELETE_INVITE_USER:
                draft.method = action.method;
                draft.loading = false;
                draft.isReloadInviteUserList = true;
                draft.isReloadRoleLimit = true;
                draft.inviteUsers = action.inviteUsers;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_DELETE_INVITE_USER:
                draft.loading = false;
                draft.isReloadInviteUserList = false;
                draft.isReloadRoleLimit = false;
                draft.inviteUsers = null;
                draft.error = action.error;
                break;


            // resend invite user
            case listOptionsContainerTypes.REQUEST_RESEND_INVITE_USER:
                draft.status = null;
                draft.method = null;
                draft.loading = true;
                draft.resendInviteUsers = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_RESEND_INVITE_USER:
                draft.method = action.method;
                draft.status = action.status;
                draft.loading = false;
                draft.resendInviteUsers = action.resendInviteUsers;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_RESEND_INVITE_USER:
                draft.status = null;
                draft.loading = false;
                draft.resendInviteUsers = null;
                draft.error = action.error;
                break;

            //get user account info
            case listOptionsContainerTypes.REQUEST_GET_ACCOUNT_INFO:
                draft.method = null;
                draft.status = null;
                draft.loading = true;
                draft.isReloadUserList = false;
                draft.accountInfo = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_ACCOUNT_INFO:
                draft.method = null;
                draft.status = null;
                draft.loading = false;
                draft.isReloadUserList = false;
                draft.accountInfo = action.accountInfo;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_ACCOUNT_INFO:
                draft.status = null;
                draft.loading = false;
                draft.isReloadUserList = false;
                draft.accountInfo = null;
                draft.error = action.error;
                break;

            //get current organisation info
            case listOptionsContainerTypes.REQUEST_GET_CURRENT_ORG_INFO:
                draft.method = null;
                draft.status = null;
                draft.loading = true;
                draft.currentOrgInfo = null;
                draft.isReloadOrgInfo = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_CURRENT_ORG_INFO:
                draft.method = null;
                draft.status = null;
                draft.loading = false;
                draft.currentOrgInfo = action.currentOrgInfo;
                draft.isReloadOrgInfo = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_CURRENT_ORG_INFO:
                draft.status = null;
                draft.loading = false;
                draft.currentOrgInfo = null;
                draft.isReloadOrgInfo = false;
                draft.error = action.error;
                break;

            //put current organisation info
            case listOptionsContainerTypes.REQUEST_PUT_CURRENT_ORG_INFO:
                draft.status = null;
                draft.loading = true;
                draft.currentOrgInfo = null;
                draft.isReloadOrgInfo = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_PUT_CURRENT_ORG_INFO:
                draft.method = action.method;
                draft.status = action.status;
                draft.loading = false;
                draft.currentOrgInfo = action.currentOrgInfo;
                draft.isReloadOrgInfo = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_PUT_CURRENT_ORG_INFO:
                draft.status = null;
                draft.loading = false;
                draft.currentOrgInfo = null;
                draft.isReloadOrgInfo = false;
                draft.error = action.error;
                break;


            //Get Invites Users By Id
            case listOptionsContainerTypes.REQUEST_GET_INVITE_USER_BY_ID:
                draft.loading = true;
                draft.useInvite = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_INVITE_USER_BY_ID:
                draft.loading = false;
                draft.useInvite = action.useInvite;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_INVITE_USER_BY_ID:
                draft.loading = false;
                draft.useInvite = null;
                draft.error = action.error;
                break;

            //get User Organization list
            case listOptionsContainerTypes.REQUEST_GET_USER_ORG_LIST:
                draft.method = null;
                draft.status = null;
                draft.loading = true;
                draft.userOrgList = null;
                draft.isReloadUserList = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_USER_ORG_LIST:
                draft.method = null;
                draft.status = null;
                draft.loading = false;
                draft.userOrgList = action.userOrgList;
                draft.isReloadUserList = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_USER_ORG_LIST:
                draft.status = null;
                draft.loading = false;
                draft.userOrgList = null;
                draft.isReloadUserList = false;
                draft.error = action.error;
                break;

            //delete User Organization 
            case listOptionsContainerTypes.REQUEST_DELETE_USER_ORG:
                draft.method = null;
                draft.loading = true;
                draft.userOrgList = null;
                draft.isReloadUserList = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_DELETE_USER_ORG:
                draft.method = action.method;
                draft.loading = false;
                draft.userOrgList = action.userOrgList;
                draft.isReloadUserList = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_DELETE_USER_ORG:
                draft.loading = false;
                draft.userOrgList = null;
                draft.isReloadUserList = false;
                draft.error = action.error;
                break;

            // Post Add USer To Org By Invite
            case listOptionsContainerTypes.REQUEST_ADD_USER_TO_ORG_BY_INVITE:
                draft.loading = true;
                draft.isReloadInviteUserList = false;
                draft.inviteUsers = null;
                draft.inviteError = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_ADD_USER_TO_ORG_BY_INVITE:
                draft.loading = false;
                draft.isReloadInviteUserList = true;
                draft.inviteUsers = action.inviteUsers;
                draft.inviteError = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_ADD_USER_TO_ORG_BY_INVITE:
                draft.loading = false;
                draft.isReloadInviteUserList = false;
                draft.inviteUsers = null;
                draft.inviteError = action.error;
                draft.error = action.error;
                break;

            case listOptionsContainerTypes.REQUEST_DELETE_ORG_FROM_LIST:
                draft.loading = true;
                draft.userOrgList = null;
                draft.isReloadUserList = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_DELETE_ORG_FROM_LIST:
                draft.method = action.method;
                draft.loading = false;
                draft.userOrgList = action.userOrgList;
                draft.isReloadUserList = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_DELETE_ORG_FROM_LIST:
                draft.loading = false;
                draft.userOrgList = null;
                draft.isReloadUserList = false;
                draft.error = action.error;
                break;

            //post new user
            case listOptionsContainerTypes.REQUEST_SET_INVITE_USER:
                draft.createdUser = null;
                draft.loading = true;
                draft.error = null;
                draft.createAccountFromInviteError = null;
                break;
            case listOptionsContainerTypes.SUCCESS_SET_INVITE_USER:
                draft.createdUser = action.createdUser;
                draft.loading = false;
                draft.error = null;
                draft.createAccountFromInviteError = null;
                break;
            case listOptionsContainerTypes.FAILURE_SET_INVITE_USER:
                draft.createdUser = null;
                draft.loading = false;
                draft.error = action.error;
                draft.createAccountFromInviteError = action.createAccountFromInviteError;
                break;

            //get User Organization list
            case listOptionsContainerTypes.REQUEST_GET_USER_LINK_TO_ORG:
                draft.method = null;
                draft.status = null;
                draft.loading = true;
                draft.linkedUserList = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_USER_LINK_TO_ORG:
                draft.status = null;
                draft.loading = false;
                draft.linkedUserList = action.linkedUserList;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_USER_LINK_TO_ORG:
                draft.status = null;
                draft.loading = false;
                draft.linkedUserList = null;
                draft.error = action.error;
                break;

            //create New Client Organization
            case listOptionsContainerTypes.REQUEST_CREATE_NEW_CLIENT_ORG:
                draft.method = null;
                draft.status = null;
                draft.loading = true;
                draft.newClientOrg = null;
                draft.error = null;
                draft.createNewClientOrgError = null;
                draft.isReloadOrgList = false;
                break;
            case listOptionsContainerTypes.SUCCESS_CREATE_NEW_CLIENT_ORG:
                draft.method = action.method;
                draft.status = action.status;
                draft.loading = false;
                draft.newClientOrg = action.newClientOrg;
                draft.error = null;
                draft.createNewClientOrgError = null;
                draft.isReloadOrgList = true;
                break;
            case listOptionsContainerTypes.FAILURE_CREATE_NEW_CLIENT_ORG:
                draft.method = null;
                draft.status = null;
                draft.loading = false;
                draft.newClientOrg = null;
                draft.error = null;
                draft.createNewClientOrgError = action.createNewClientOrgError;
                draft.isReloadOrgList = false;
                break;

            //get Client Organization list
            case listOptionsContainerTypes.REQUEST_GET_CLIENT_ORG_LIST:
                draft.status = null;
                draft.method = null;
                draft.loading = true;
                draft.clientOrgList = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_CLIENT_ORG_LIST:
                draft.status = null;
                draft.method = null;
                draft.loading = false;
                draft.clientOrgList = action.clientOrgList;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_CLIENT_ORG_LIST:
                draft.status = null;
                draft.method = null;
                draft.loading = false;
                draft.clientOrgList = null;
                draft.error = null;
                break;


            // get rolelimits
            case listOptionsContainerTypes.REQUEST_GET_USERS_ROLE_LIMITS:
                draft.status = null;
                draft.method = null;
                draft.roleLimits = null;
                draft.loading = true;
                draft.error = null;
                draft.isReloadRoleLimit = false;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_USERS_ROLE_LIMITS:
                draft.status = null;
                draft.method = null;
                draft.roleLimits = action.roleLimits;
                draft.loading = false;
                draft.error = null;
                draft.isReloadRoleLimit = false;
                break;
            case listOptionsContainerTypes.FAILURE_GET_USERS_ROLE_LIMITS:
                draft.roleLimits = null;
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadRoleLimit = false;
                break;

            // get software storage
            case listOptionsContainerTypes.REQUEST_GET_SOFTWARE_STORAGE:
                draft.softwareStorage = null;
                draft.loading = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_SOFTWARE_STORAGE:
                draft.softwareStorage = action.softwareStorage;
                draft.loading = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_SOFTWARE_STORAGE:
                draft.softwareStorage = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get software backup
            case listOptionsContainerTypes.REQUEST_GET_SOFTWARE_BACKUP:
                draft.softwareBackup = null;
                draft.loading = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_SOFTWARE_BACKUP:
                draft.softwareBackup = action.softwareBackup;
                draft.loading = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_SOFTWARE_BACKUP:
                draft.softwareBackup = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get All Client Organisation
            case listOptionsContainerTypes.REQUEST_GET_ALL_CLIENT_ORG:
                draft.allClientOrgList = null;
                draft.loading = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_ALL_CLIENT_ORG:
                draft.allClientOrgList = action.allClientOrgList;
                draft.loading = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_ALL_CLIENT_ORG:
                draft.allClientOrgList = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // put Client Organisation
            case listOptionsContainerTypes.REQUEST_PUT_CLIENT_ORG:
                draft.allClientOrgList = null;
                draft.loading = true;
                draft.shouldRealoadLinkedUser = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_PUT_CLIENT_ORG:
                draft.allClientOrgList = action.allClientOrgList;
                draft.loading = false;
                draft.shouldRealoadLinkedUser = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_PUT_CLIENT_ORG:
                draft.allClientOrgList = null;
                draft.loading = false;
                draft.shouldRealoadLinkedUser = false;
                draft.error = action.error;
                break;

            // delete Client Organisation
            case listOptionsContainerTypes.REQUEST_DELETE_CLIENT:
                draft.method = null;
                draft.status = null;
                draft.allClientOrgList = null;
                draft.loading = true;
                draft.shouldRealoadClientOrg = false;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_DELETE_CLIENT:
                draft.method = action.method;
                draft.status = action.status;
                draft.allClientOrgList = action.allClientOrgList;
                draft.loading = false;
                draft.shouldRealoadClientOrg = true;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_DELETE_CLIENT:
                draft.method = null;
                draft.status = null;
                draft.allClientOrgList = null;
                draft.loading = false;
                draft.shouldRealoadClientOrg = false;
                draft.error = action.error;
                break;

            //get link Partner Org List
            case listOptionsContainerTypes.REQUEST_GET_LINKED_PARTNER_ORG:
                draft.linkPartnerOrgList = null;
                draft.loading = true;
                draft.error = null;
                draft.isReloadClientsList = false;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_LINKED_PARTNER_ORG:
                draft.linkPartnerOrgList = action.linkPartnerOrgList;
                draft.loading = false;
                draft.error = null;
                draft.isReloadClientsList = false;
                break;
            case listOptionsContainerTypes.FAILURE_GET_LINKED_PARTNER_ORG:
                draft.linkPartnerOrgList = null;
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadClientsList = false;
                break;

            // delete user from Client Organisation list
            case listOptionsContainerTypes.REQUEST_DELETE_USER_FROM_CLIENT_ORG:
                draft.UserFromClientOrgList = null;
                draft.loading = true;
                draft.error = null;
                draft.isReloadClientsList = false;
                break;
            case listOptionsContainerTypes.SUCCESS_DELETE_USER_FROM_CLIENT_ORG:
                draft.UserFromClientOrgList = action.UserFromClientOrgList;
                draft.loading = false;
                draft.error = null;
                draft.isReloadClientsList = true;
                break;
            case listOptionsContainerTypes.FAILURE_DELETE_USER_FROM_CLIENT_ORG:
                draft.UserFromClientOrgList = null;
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadClientsList = false;
                break;

            //post linked partner organizations to user
            case listOptionsContainerTypes.REQUEST_POST_LINKED_PARTNER_ORG_TO_USER:
                draft.postUser = null;
                draft.loading = true;
                draft.error = null;
                draft.isReloadClientsList = false;
                break;
            case listOptionsContainerTypes.SUCCESS_POST_LINKED_PARTNER_ORG_TO_USER:
                draft.postUser = action.postUser;
                draft.loading = false;
                draft.error = null;
                draft.isReloadClientsList = true;
                break;
            case listOptionsContainerTypes.FAILURE_POST_LINKED_PARTNER_ORG_TO_USER:
                draft.postUser = null;
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadClientsList = false;
                break;

            // put org user
            case listOptionsContainerTypes.REQUEST_PUT_ORG_USER:
                draft.status = null;
                draft.user = null;
                draft.loading = false;
                draft.error = null;
                draft.isReloadUserList = false;
                break;

            case listOptionsContainerTypes.SUCCESS_PUT_ORG_USER:
                draft.method = action.method;
                draft.user = action.data;
                draft.loading = false;
                draft.isReloadUserList = true;
                draft.error = null;
                draft.status = action.status;
                break;

            case listOptionsContainerTypes.FAILURE_PUT_ORG_USER:
                draft.status = null;
                draft.user = action.data;
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadUserList = false;
                break;


            //get otp
            case listOptionsContainerTypes.REQUEST_GET_OTP:
                draft.loading = true;
                draft.qrData = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_GET_OTP:
                draft.loading = false;
                draft.qrData = action.qrData;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_GET_OTP:
                draft.loading = false;
                draft.qrData = null;
                draft.error = action.error;
                break;

            //post varify otp
            case listOptionsContainerTypes.REQUEST_POST_VARIFY_OTP:
                draft.loading = true;
                draft.varifyOtpData = null;
                draft.error = null;
                draft.isReloadVarifyOtp = false;
                break;
            case listOptionsContainerTypes.SUCCESS_POST_VARIFY_OTP:
                draft.loading = false;
                draft.varifyOtpData = action.varifyOtpData;
                draft.qrData = action.qrData;
                draft.error = null;
                draft.isReloadVarifyOtp = true;
                break;
            case listOptionsContainerTypes.FAILURE_POST_VARIFY_OTP:
                draft.loading = false;
                draft.varifyOtpData = null;
                draft.qrData = action.qrData;
                draft.error = action.error;
                draft.isReloadVarifyOtp = false;
                break;

            //post validate otp
            case listOptionsContainerTypes.REQUEST_POST_VALIDATE_OTP:
                draft.loading = true;
                draft.validateOtpData = null;
                draft.error = null;
                // draft.isReloadVarifyOtp = false;
                break;
            case listOptionsContainerTypes.SUCCESS_POST_VALIDATE_OTP:
                draft.loading = false;
                draft.validateOtpData = action.validateOtpData;
                // draft.qrData = action.qrData;
                draft.error = null;
                // draft.isReloadVarifyOtp = true;
                break;
            case listOptionsContainerTypes.FAILURE_POST_VALIDATE_OTP:
                draft.loading = false;
                draft.validateOtpData = null;
                // draft.qrData = action.qrData;
                draft.error = action.error;
                // draft.isReloadVarifyOtp = false;
                break;

            //post validate twoFa token
            case listOptionsContainerTypes.REQUEST_POST_VALIDATE_TWOFA_TOKEN:
                draft.loading = true;
                draft.validateTwoFaToken = null;
                draft.error = null;
                // draft.isReloadVarifyOtp = false;
                break;
            case listOptionsContainerTypes.SUCCESS_POST_VALIDATE_TWOFA_TOKEN:
                draft.loading = false;
                draft.validateTwoFaToken = action.validateTwoFaToken;
                // draft.qrData = action.qrData;
                draft.error = null;
                // draft.isReloadVarifyOtp = true;
                //const expiryIn = dayjs().add(action.validateTwoFaToken.expiresIn, 'second');
                //setUserCookies(action.validateTwoFaToken.refreshToken, expiryIn.toDate());
                break;
            case listOptionsContainerTypes.FAILURE_POST_VALIDATE_TWOFA_TOKEN:
                draft.loading = false;
                draft.validateTwoFaToken = null;
                // draft.qrData = action.qrData;
                draft.error = action.error;
                // draft.isReloadVarifyOtp = false;
                break;

            //put organization
            case listOptionsContainerTypes.REQUEST_PUT_ORGANIZATION:
                draft.loading = true;
                draft.error = null;
                draft.isReloadUserList = false;
                draft.shouldRealoadAddresses = false;
                break;

            case listOptionsContainerTypes.SUCCESS_PUT_ORGANIZATION:
                draft.method = action.method;
                draft.loading = true;
                draft.error = null;
                draft.isReloadUserList = false;
                draft.shouldRealoadAddresses = false;
                draft.status = action.status;
                break;

            case listOptionsContainerTypes.FAILURE_PUT_ORGANIZATION:
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadUserList = true;
                draft.shouldRealoadAddresses = false;
                break;

            //post notification
            case listOptionsContainerTypes.REQUEST_POST_NOTIFICATION:
                draft.loading = true;
                draft.data = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_POST_NOTIFICATION:
                draft.loading = false;
                draft.data = action.data;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_POST_NOTIFICATION:
                draft.loading = false;
                draft.data = null;
                draft.error = action.error;
                break;

            //post notification message
            case listOptionsContainerTypes.REQUEST_POST_NOTIFICATION_MESSAGE:
                draft.loading = true;
                draft.data = null;
                draft.error = null;
                break;
            case listOptionsContainerTypes.SUCCESS_POST_NOTIFICATION_MESSAGE:
                draft.loading = false;
                draft.data = action.data;
                draft.error = null;
                break;
            case listOptionsContainerTypes.FAILURE_POST_NOTIFICATION_MESSAGE:
                draft.loading = false;
                draft.data = null;
                draft.error = action.error;
                break;

            //clear list option container
            case clearListOptionContainerTypes.CLEAR_LIST_OPTION_STATUS:
                draft.status = null;
                break;

            //clear error
            case clearErrorTypes.CLEAR_ERROR:
            draft.error = null;
            break;

            default:
                return state;
        }
    })
export default listOptionsContainerReducer;
