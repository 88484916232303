import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ProfilePicture from "../../assets/images/profile-picture.svg";
import StyledButton from "../../components/StyledButton";
import calenderIcon from "../../assets/icons/calender-icon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MeasureExpandSection from "../../components/MeasureExpandSection";
import DatePicker from 'react-date-picker';
import { useDispatch, useSelector } from "react-redux";
import schema from "../../validations/schema";
import { measureGroupsContainerCreators } from "../MeasureGroupsContainer/measureGroupsReducer";
import Dropdown from "../../components/Dropdown";
import { riskAreaContainerCreators } from "../ManageRiskAreasContainer/reducer";
import { listOptionsContainerCreators } from "../OrganisationContainer/reducer";
import dayjs from "dayjs";
import { ListOptionType } from "@onesixeight-nl/classes/dist/classes/listOptionTypeEnum";
import { selectDecodedUserInfo } from "../../AppSelector";
import { InfoDetails } from "../BIAContainer/BIAEdit";
import Container from "../../components/Container/Container";
import GetIcons from "../../components/GetIcons/GetIcons";
import LoadingIcon from "../../assets/icons/loading-icon";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import { TShirtSize } from "@onesixeight-nl/classes";
import { MeasureGroupList } from "../AddMeasureGroupContainer";
import { AllRiskCategoryListData } from "../../components/CentralizePopup/CentralizePopup";
import Modal from "../../components/Modal/Modal";

const testId = `measure-risk-area-container`;

export interface MeasureList {
    measureId?: string;
    measureListId: string;
    nameWhenOther?: string;
    details?: string;
    evidenceDetails?: string;
    selected?: number;
    status?: number;
    evidence?: number;
    task?: Task;
    evidenceTask?: Task;
    name?: string;
    files?: [];
    reminderTasks?: [];
    effort: number;
    impact: number;
    steps: StepsList[];
}

export type StepsList = {
    stepId: string;
    name: string;
    status: number;
}
export interface Task {
    assignedTo: string;
    deadline: Date | string;
    description: string;
}

type ErrorMessage = {
    message: string
}

export type ErrorObject = {
    name?: ErrorMessage;
    description?: ErrorMessage;
    dropDown?: ErrorMessage;
    reminder?: ErrorMessage;
    reminderDetails?: ErrorMessage;
    reminderFrequency?: ErrorMessage;
    stepName?: ErrorMessage;
    impactErr?: ErrorMessage;
    effortErr?: ErrorMessage;
    hasName?: ErrorMessage;
}

export type Users = {
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    emailVerified: boolean,
    mobilePhone: string,
    prefferedLanguage: string,
    created: Date,
    modified: Date,
    role?: string
};

export type Frequency = {
    frequency: string,
    status: number,
    taskId: string,
    taskListId: string,
    assignedTo: string,
    deadline: Date,
    nameWhenOther: string,
    firstName: string
}

export type UserDropdownList = {
    label: string,
    value: string
};

export type ReminderFrequencyList = {
    label: string,
    value: string
}

export type Option = {
    value: string;
    label: string;
}

export type File = {
    lastModified: number;
    lastModifiedDate: Date;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;

    linkedToType?: string,
    linkedToId?: string,
    fileType?: string,
    fileName?: string,
    fileUrl?: string,
    fileId?: string
}

export type ImpactOptionList = {
    label: string,
    value: string
};

export default function MeasureRiskAreasContainer() {

    // hooks
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const intl = useIntl();
    const params = useParams();
    const { id, mgId, singleMeasureId } = params;
    const isCancel = new URLSearchParams(location.search).get('isCancel');

    const {
        organizationUsers,
    } = useSelector((state: RootState) => state.riskAreContainer);

    const measureGroupsSelector = useSelector((state: RootState) => state.measureGroupsContainer);

    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const role: string | undefined = decodedUserInfo?.payload.role;

    const impactOptions: ImpactOptionList[] = [
        { label: "XS", value: String(TShirtSize.XS) },
        { label: "S", value: String(TShirtSize.S) },
        { label: "M", value: String(TShirtSize.M) },
        { label: "L", value: String(TShirtSize.L) },
        { label: "XL", value: String(TShirtSize.XL) },
    ]

    // states
    const [answerType, setAnswerType] = useState<string>('');
    const [answerSubType, setAnswerSubType] = useState<string>('');
    const [periodicReminderAnswerType, setPeriodicReminderAnswerType] = useState<string>('');
    const [monthlyReminder, setMonthlyReminder] = useState<boolean>(false);
    const [isToDoDropDown, setIsToDoDropDown] = useState<boolean>(false);
    const [isReminderDropDown, setIsReminderDropDown] = useState<boolean>(false);
    const [progressDoneDate, setProgressDoneDate] = useState<Date>(new Date());
    const [evidenceDoneDate, setEvidenceDoneDate] = useState<Date>(new Date());
    const [toDoArea, setToDoArea] = useState<string>('');
    const [selected, setSelected] = useState<number | null>();
    const [measures, setMeasures] = useState<MeasureList[]>([])
    const [currentIndex, setCurrentIndex] = useState<number>(measureGroupsSelector.measures && measureGroupsSelector.measures.length >=0 && measureGroupsSelector.measures?.findIndex((ele: MeasureList) => !ele.measureId));
    const [nameWhenOther, setnameWhenOther] = useState<string>('');
    const [details, setDetails] = useState<string>('');
    const [users, setUsers] = useState<Users[]>([]);
    const [formatedDate, setFormatedDate] = useState<string>('');
    const [evidenceFormatedDate, setEvidenceFormatedDate] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File[]>([]);
    const [userData, setUserData] = useState<Users>({
        userId: String(decodedUserInfo?.payload?.userId),
        firstName: "",
        lastName: "",
        email: "",
        emailVerified: false,
        mobilePhone: "",
        prefferedLanguage: "",
        created: new Date(),
        modified: new Date(),
    });
    const [reminderData, setReminderData] = useState<Frequency>({
        frequency: "",
        status: 0,
        taskId: "",
        taskListId: ListOptionType.REMINDERS,
        assignedTo: "",
        deadline: new Date(),
        nameWhenOther: "",
        firstName: '',
    });
    const reminderOption: Option = {
        label: intl.formatMessage({ id: 'add_my_own', defaultMessage: 'add_my_own' }),
        value: "",
    }
    const [userDropdownList, setUserDropdownList] = useState<UserDropdownList[]>([]);
    const [evidenceLaterDropdownList, setEvidenceLaterDropdownList] = useState<UserDropdownList[]>([]);
    const [evidenceNowDropdownList, setEvidenceNowDropdownList] = useState<UserDropdownList[]>([]);
    const initialReminderFrequencyList: ReminderFrequencyList[] = [
        { label: "Monthly", value: "30" },
        { label: "Quarterly", value: "91" },
        { label: "Half yearly", value: "182" },
        { label: "Yearly", value: "365" },
    ]
    const [reminderFrequencyList, setReminderFrequencyList] = useState<ReminderFrequencyList[]>([...initialReminderFrequencyList]);
    const [reminderTasksList, setReminderTasksList] = useState<Frequency[]>([]);
    const [isEditReminder, setIsEditReminder] = useState<number | null>(null);

    const [stepData, setStepData] = useState<StepsList>({
        stepId: "",
        name: "",
        status: 1,
    });
    const [stepsList, setStepsList] = useState<StepsList[]>([]);

    const [measureId, setMeasureId] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [errObj, setErrObj] = useState<ErrorObject>({
        name: undefined,
        description: undefined,
        dropDown: undefined,
        reminder: undefined,
        reminderDetails: undefined,
        reminderFrequency: undefined,
        stepName: undefined,
        impactErr: undefined,
        effortErr: undefined,
        hasName: undefined
    });
    const [loading, setLoading] = useState(false);
    const [measureStatus, setMeasureStatus] = useState<string>('');
    const [lastQuestion, setLastQuestion] = useState<boolean>(false);
    const [impactAnswer, setImpactAnswer] = useState<string>("");
    const [effortAnswer, setEffortAnswer] = useState<string>("");

    const profileArr: string[] = [
        ProfilePicture,
    ];
    const { from }: any = location.state || {};
    const [addMeasureGroupObj, setAddMeasureGroupObj] = useState<MeasureGroupList>()
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | any>()
    const [editMeasureGroupObj, setEditMeasureGroupObj] = useState<MeasureGroupList>();
    const [editMeasureObj, setEditMeasureObj] = useState<MeasureList | any>()
    const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);

    const intialMeasure: MeasureList = {
        measureId: '',
        measureListId: ListOptionType.MEASURES,
        nameWhenOther: '',
        details: '',
        evidenceDetails: '',
        selected: 0,
        effort: 0,
        impact: 0,
        status: 0,
        evidence: 0,
        steps: [...stepsList],
        task: {
            assignedTo: '',
            deadline: '',
            description: '',
        },
        evidenceTask: {
            assignedTo: '',
            deadline: '',
            description: '',
        },
        name: '',
        files: [],
        reminderTasks: [],
    };
    const [singleMeasure, setSingleMeasure] = useState<MeasureList>();
    const [learnMoreInfo, setLearnMoreInfo] = useState<boolean | undefined>(false);
    const findInfoText: any = infoDetails?.length > 0 ? infoDetails?.find((ele) => ele?.attributes?.InfoText) : []
    const InfoTextDetail = findInfoText ? findInfoText?.attributes?.InfoText : {}
    const textShow = InfoTextDetail ? InfoTextDetail?.substring(0, 100) : "";
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);
    const [showInput, setShowInput] = useState<boolean>((!editMeasureObj || (stepsList && stepsList?.length === 0)) ? true : false);
    const [editInput, setEditInput] = useState<boolean>(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.error !== null) {
            setIsWromgOrgModal(true)
        }
    }, [riskAreaSelector.error])

    useEffect(() => {
        if (!editMeasureObj || (stepsList && stepsList?.length === 0)) {
            setShowInput(true)
            setEditIndex(null);
            setStepData({
                stepId: "",
                name: "",
                status: 1,
            });
            setErrObj({ hasName: undefined });
        } else {
            setShowInput(false);
        }
    }, [editMeasureObj, stepsList])

    /* istanbul ignore next */
    function SideDrawerOpen() {
        setLearnMoreInfo(!learnMoreInfo);
    }
    /* istanbul ignore next */
    function SideDrawerClose() {
        setLearnMoreInfo(false);
    }

    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1)

    useEffect(() => {
        const postMeasureData = measureGroupsSelector.postMeasureData
        const postMeasureIndex = measures.findIndex((ele: MeasureList) => ele?.measureListId === measureGroupsSelector?.postMeasureData?.measureListId)
        if (postMeasureIndex > -1 && postMeasureData !== null) {
            measures[postMeasureIndex] = measureGroupsSelector.postMeasureData;
            setMeasures(measures);
        }
      }, [measureGroupsSelector.postMeasureData])

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
    }, [])

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.riskCategory && riskAreaSelector.riskCategory.length && id) {
            const riskCategoryObj = riskAreaSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === id)
            setSingleRiskCategory(riskCategoryObj);
            if (riskCategoryObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [riskAreaSelector.riskCategory])

    useEffect(() => {
        if (measureGroupsSelector?.status !== null && measureGroupsSelector?.status !== undefined && from !== 'cancal') {
            setIsSaved(true);
            setTimeCounter(1);
        }
    }, [measureGroupsSelector.status])

    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved]);

    useEffect(() => {
        if (singleRiskCategory && id) {
            dispatch(measureGroupsContainerCreators.requestGetMeasureGroups(id, '', isCancel, '', [], null, singleRiskCategory));
        }
    }, [id, singleRiskCategory])

    useEffect(() => {
        if (measureGroupsSelector.data && measureGroupsSelector.data.length && mgId) {
            const currentMeasureGroup = measureGroupsSelector.data?.find((ele: MeasureGroupList) => ele.measuregroupId === mgId);
            setEditMeasureGroupObj(currentMeasureGroup)
            setAddMeasureGroupObj(currentMeasureGroup)
        }
    }, [measureGroupsSelector.data, mgId])

    useEffect(() => {
        if (id && mgId && singleRiskCategory) {
            dispatch(measureGroupsContainerCreators.requestGetMeasures(id, mgId, addMeasureGroupObj, '', '', decodedUserInfo, singleRiskCategory));
        }
    }, [id, mgId, singleRiskCategory])

    useEffect(() => {
        if (measureGroupsSelector.measures && measureGroupsSelector.measures.length > 0 && singleMeasureId) {
            const currentMeasure = measureGroupsSelector.measures?.find((ele: MeasureList) => ele.measureId === singleMeasureId);
            setEditMeasureObj(currentMeasure)
            setSingleMeasure(currentMeasure)
        }
    }, [measureGroupsSelector.measures, singleMeasureId])

    useEffect(() => {
        if (measureGroupsSelector.measures && measureGroupsSelector.measures.length >= 0 && !editMeasureObj) {
            if (currentIndex !== null && currentIndex > -1) {
                setCurrentIndex(currentIndex)
                setSingleMeasure(measureGroupsSelector.measures[currentIndex])
            }
            else if (currentIndex === null) {
                const index = measureGroupsSelector.measures?.findIndex((ele: MeasureList) => !ele?.measureId)
                setCurrentIndex(index)
                setSingleMeasure(measureGroupsSelector.measures[index])
            }
            else if (currentIndex === -1) {
                setSingleMeasure({
                    ...intialMeasure,
                    measureListId: '00000000-0000-0000-0000-000000000000'
                });
            }
        }
    }, [measureGroupsSelector.measures, currentIndex])

    /* istanbul ignore next */
    useEffect(() => {
        if (measureGroupsSelector && measureGroupsSelector.files) {
            const latestSelectedState = [...selectedFile, measureGroupsSelector.files]
            setSelectedFile([...latestSelectedState]);
        }
    }, [measureGroupsSelector.files])/* eslint-disable react-hooks/exhaustive-deps */

    /* istanbul ignore next */
    useEffect(() => {
        if (users && users.length) {
            const filteredUser = users.filter((user: Users) => (user.role === "administrator" || user.role === "editor"))
            let newUsers: Users[] | any = filteredUser.map((user: Users) => {
                return {
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.userId
                }
            });
            setUserDropdownList(newUsers);
            setEvidenceLaterDropdownList(newUsers);
            setEvidenceNowDropdownList(newUsers);

            const defaultUser = users.find((user: Users) => user.userId === decodedUserInfo?.payload.userId)
            if (defaultUser && !editMeasureObj) {
                setUserData({ ...defaultUser });
            }
        }
    }, [users])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (decodedUserInfo?.payload?.orgId) {
            dispatch(riskAreaContainerCreators.requestGetOrganizationUsers(decodedUserInfo?.payload?.orgId))
        }
    }, [decodedUserInfo?.payload?.orgId])/* eslint-disable react-hooks/exhaustive-deps */

    // get measure by id
    useEffect(() => {
        if (id && mgId && editMeasureObj?.measureId) {
            dispatch(measureGroupsContainerCreators.requestGetMeasureById(id, mgId, editMeasureObj?.measureId))
        }
    }, [id, mgId, editMeasureObj?.measureId])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (organizationUsers && organizationUsers.length) {
            setUsers(organizationUsers)
        }
    }, [organizationUsers])

    useEffect(() => {
        if (measureGroupsSelector.measures && measureGroupsSelector.measures.length >= 0) {
            const defaultMeasures = measureGroupsSelector.measures.filter((ele: MeasureList) => ele.measureListId !== "00000000-0000-0000-0000-000000000000");
            setMeasures(defaultMeasures);
        }
    }, [measureGroupsSelector.measures])

    useEffect(() => {
        if (stepsList && stepsList.length) {
            const doneStepsList: StepsList[] = stepsList.filter((ele) => ele.status === 3);
            const todoStepsList: StepsList[] = stepsList.filter((ele) => ele.status === 1);

            if (doneStepsList.length === stepsList.length) {
                setMeasureStatus("done")
            } else if (todoStepsList.length === stepsList.length) {
                setMeasureStatus("todo")
            } else {
                setMeasureStatus("inProgress")
            }
        }
    }, [stepsList, stepData]);

    /* istanbul ignore next */
    useEffect(() => {
        if (editMeasureObj) {
            dispatch(measureGroupsContainerCreators.requestGetMeasuresHelpText(editMeasureObj?.measureListId, decodedUserInfo));
        } else if (measures[currentIndex]?.measureListId) {
            dispatch(measureGroupsContainerCreators.requestGetMeasuresHelpText(measures[currentIndex]?.measureListId, decodedUserInfo));
        } else {
            dispatch(measureGroupsContainerCreators.requestGetMeasuresHelpText('00000000-0000-0000-0000-000000000000', decodedUserInfo));
        }
    }, [editMeasureObj, currentIndex,measures])/* eslint-disable react-hooks/exhaustive-deps */
    /* istanbul ignore next */
    useEffect(() => {
        if (measureGroupsSelector && measureGroupsSelector.measuresHelpText) {
            setInfoDetails(measureGroupsSelector.measuresHelpText?.data);
        }
    }, [measureGroupsSelector])

    /* istanbul ignore next */
    useEffect(() => {
        if (editMeasureObj) {
            setCurrentIndex(-1)
            setSingleMeasure(editMeasureObj);
            setMeasureId(editMeasureObj?.measureId);
            if (editMeasureObj?.selected) {
                if (editMeasureObj?.selected === 1) {
                    setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) })
                    if (editMeasureObj?.measureListId === "00000000-0000-0000-0000-000000000000") {
                        setAnswerType('');
                        setSelected(1);
                    } else {
                        setAnswerType('NotRelevant');
                        setSelected(1);
                    }
                } else if (editMeasureObj?.selected === 2) {
                    if (editMeasureObj?.steps?.length > 0) {
                        setStepsList([...editMeasureObj?.steps])
                    } else {
                        setStepsList([])
                    }
                    setImpactAnswer(String(editMeasureObj?.impact));
                    setEffortAnswer(String(editMeasureObj?.effort));
                    if (editMeasureObj?.measureListId === "00000000-0000-0000-0000-000000000000") {
                        setAnswerType("");
                        setSelected(2);
                        setnameWhenOther(editMeasureObj?.nameWhenOther)
                        if (measureGroupsSelector.singleMeasure?.status === 3) {
                            setMeasureStatus('done');
                            if (editMeasureObj?.evidence === 2) {
                                setAnswerSubType("Now");
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) });
                                setDetails(editMeasureObj?.evidenceDetails);
                                if (editMeasureObj?.reminderTasks.length > 0) {
                                    let editReminderTasksList = editMeasureObj?.reminderTasks.map((ele: Frequency) => ({
                                        frequency: ele?.frequency,
                                        status: ele?.status,
                                        taskId: ele?.taskId,
                                        taskListId: ele?.taskListId,
                                        assignedTo: ele?.assignedTo,
                                        deadline: "",
                                        nameWhenOther: ele?.nameWhenOther,
                                        firstName: firstName
                                    }))
                                    setReminderTasksList([...editReminderTasksList])
                                } else {
                                    setReminderTasksList([])
                                }
                                if (editMeasureObj?.files.length > 0) {
                                    setSelectedFile([...editMeasureObj.files]);
                                } else {
                                    setSelectedFile([]);
                                }
                            } else if (editMeasureObj?.evidence === 3) {
                                setAnswerSubType("Later");
                                const date = editMeasureObj?.evidenceTask !== null ? new Date(editMeasureObj?.evidenceTask?.deadline) : new Date();
                                setEvidenceDoneDate(date);
                                setUserData({ ...userData, userId: editMeasureObj?.evidenceTask?.assignedTo !== "" ? editMeasureObj?.evidenceTask?.assignedTo : decodedUserInfo?.payload?.userId })
                            } else if (editMeasureObj?.evidence === 4) {
                                setAnswerSubType("Never");
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) });
                            }
                        } else if (measureGroupsSelector.singleMeasure?.status === 2) {
                            setMeasureStatus("inProgress");
                            setUserData({ ...userData, userId: editMeasureObj?.task?.assignedTo !== "" ? editMeasureObj?.task?.assignedTo : decodedUserInfo?.payload?.userId })
                            const date = editMeasureObj?.task !== null ? new Date(editMeasureObj?.task?.deadline) : new Date();
                            setProgressDoneDate(date)
                            setToDoArea(editMeasureObj?.task?.description);
                        } else if (measureGroupsSelector.singleMeasure?.status === 1) {
                            setMeasureStatus("todo");
                            setUserData({ ...userData, userId: editMeasureObj?.task?.assignedTo !== "" ? editMeasureObj?.task?.assignedTo : decodedUserInfo?.payload?.userId })
                            const date = editMeasureObj?.task !== null ? new Date(editMeasureObj?.task?.deadline) : new Date();
                            setProgressDoneDate(date)
                            setToDoArea(editMeasureObj?.task?.description);
                        }
                    } else {
                        if (measureGroupsSelector.singleMeasure?.status === 1) {
                            setMeasureStatus("todo");
                            setSelected(2);
                            setUserData({ ...userData, userId: editMeasureObj?.task?.assignedTo !== "" ? editMeasureObj?.task?.assignedTo : decodedUserInfo?.payload?.userId })
                            const date = new Date(editMeasureObj?.task?.deadline) || new Date();
                            setProgressDoneDate(date)
                            setToDoArea(editMeasureObj?.task?.description);
                        } else if (measureGroupsSelector.singleMeasure?.status === 2) {
                            setMeasureStatus("inProgress");
                            setSelected(2);
                            setUserData({ ...userData, userId: editMeasureObj?.task?.assignedTo !== "" ? editMeasureObj?.task?.assignedTo : decodedUserInfo?.payload?.userId })
                            const date = new Date(editMeasureObj?.task?.deadline) || new Date();
                            setProgressDoneDate(date)
                            setToDoArea(editMeasureObj?.task?.description);
                        } else if (measureGroupsSelector.singleMeasure?.status === 3) {
                            setAnswerType("Relevant");
                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) });
                            setSelected(2);
                            if (editMeasureObj?.evidence === 2) {
                                setAnswerSubType("Now");
                                if (editMeasureObj?.reminderTasks.length > 0) {
                                    let editReminderTasksList = editMeasureObj?.reminderTasks.map((ele: Frequency) => ({
                                        frequency: ele.frequency,
                                        status: ele.status,
                                        taskId: ele.taskId,
                                        taskListId: ele?.taskListId,
                                        assignedTo: ele.assignedTo,
                                        deadline: "",
                                        nameWhenOther: ele.nameWhenOther,
                                        firstName: firstName
                                    }))
                                    setReminderTasksList([...editReminderTasksList])
                                } else {
                                    setReminderTasksList([])
                                }
                                if (editMeasureObj?.files.length > 0) {
                                    setSelectedFile([...editMeasureObj.files]);
                                } else {
                                    setSelectedFile([]);
                                }
                            } else if (editMeasureObj?.evidence === 3) {
                                setAnswerSubType("Later");
                                const date = new Date(editMeasureObj?.evidenceTask?.deadline) || new Date();
                                setEvidenceDoneDate(date);
                                setUserData({ ...userData, userId: editMeasureObj.evidenceTask?.assignedTo !== "" ? editMeasureObj.evidenceTask?.assignedTo : decodedUserInfo?.payload?.userId })
                            } else if (editMeasureObj?.evidence === 4) {
                                setAnswerSubType("Never");
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) });
                            }
                        }
                    }
                }
            }
        }
    }, [editMeasureObj])/* eslint-disable react-hooks/exhaustive-deps */

    /* istanbul ignore next */
    useEffect(() => {
        if (measureGroupsSelector && measureGroupsSelector.singleMeasure) {
            setSingleMeasure(measureGroupsSelector.singleMeasure);
            setMeasureId(measureGroupsSelector.singleMeasure?.measureId);
            if (measureGroupsSelector.singleMeasure?.selected) {
                if (measureGroupsSelector.singleMeasure?.selected === 1) {
                    setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) })
                    if (measureGroupsSelector.singleMeasure?.measureListId === "00000000-0000-0000-0000-000000000000") {
                        setAnswerType('');
                        setSelected(1);
                    } else {
                        setAnswerType('NotRelevant');
                        setSelected(1);
                    }
                } else if (measureGroupsSelector.singleMeasure?.selected === 2) {
                    if (measureGroupsSelector?.singleMeasure?.steps?.length > 0) {
                        setStepsList([...measureGroupsSelector?.singleMeasure?.steps])
                    } else {
                        setStepsList([])
                    }
                    setImpactAnswer(String(measureGroupsSelector?.singleMeasure?.impact));
                    setEffortAnswer(String(measureGroupsSelector?.singleMeasure?.effort));
                    if (measureGroupsSelector.singleMeasure?.measureListId === "00000000-0000-0000-0000-000000000000") {
                        setAnswerType("");
                        setSelected(2);
                        setnameWhenOther(measureGroupsSelector.singleMeasure?.nameWhenOther)
                        if (measureGroupsSelector.singleMeasure?.status === 3) {
                            setMeasureStatus('done');
                            if (measureGroupsSelector.singleMeasure?.evidence === 2) {
                                setAnswerSubType("Now");
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) });
                                setDetails(measureGroupsSelector?.singleMeasure?.evidenceDetails)
                                if (measureGroupsSelector.singleMeasure?.reminderTasks.length > 0) {
                                    let editReminderTasksList = measureGroupsSelector.singleMeasure?.reminderTasks.map((ele: Frequency) => ({
                                        frequency: ele.frequency,
                                        status: ele.status,
                                        taskId: ele.taskId,
                                        taskListId: ele?.taskListId,
                                        assignedTo: ele.assignedTo,
                                        deadline: "",
                                        nameWhenOther: ele.nameWhenOther,
                                        firstName: firstName
                                    }))
                                    setReminderTasksList([...editReminderTasksList])
                                } else {
                                    setReminderTasksList([])
                                }
                                if (measureGroupsSelector.singleMeasure?.files.length > 0) {
                                    setSelectedFile([...editMeasureObj.files]);
                                } else {
                                    setSelectedFile([]);
                                }
                            } else if (measureGroupsSelector.singleMeasure?.evidence === 3) {
                                setAnswerSubType("Later");
                                const date = new Date(measureGroupsSelector?.singleMeasure?.evidenceTask?.deadline) || new Date();
                                setEvidenceDoneDate(date);
                                setUserData({ ...userData, userId: measureGroupsSelector?.singleMeasure?.evidenceTask?.assignedTo !== "" ? measureGroupsSelector?.singleMeasure?.evidenceTask?.assignedTo : decodedUserInfo?.payload?.userId })
                            } else if (measureGroupsSelector.singleMeasure?.evidence === 4) {
                                setAnswerSubType("Never");
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) });
                            }
                        } else if (measureGroupsSelector.singleMeasure?.status === 2) {
                            setMeasureStatus("inProgress");
                            setUserData({ ...userData, userId: measureGroupsSelector.singleMeasure?.task?.assignedTo !== "" ? measureGroupsSelector.singleMeasure?.task?.assignedTo : decodedUserInfo?.payload?.userId })
                            const date = new Date(measureGroupsSelector.singleMeasure?.task?.deadline);
                            setProgressDoneDate(date)
                            setToDoArea(measureGroupsSelector.singleMeasure?.task?.description);
                        } else if (measureGroupsSelector.singleMeasure?.status === 1) {
                            setMeasureStatus("todo");
                            setUserData({ ...userData, userId: measureGroupsSelector.singleMeasure?.task?.assignedTo !== "" ? measureGroupsSelector.singleMeasure?.task?.assignedTo : decodedUserInfo?.payload?.userId })
                            const date = new Date(measureGroupsSelector.singleMeasure?.task?.deadline);
                            setProgressDoneDate(date)
                            setToDoArea(measureGroupsSelector.singleMeasure?.task?.description);
                        }
                    } else {
                        setAnswerType("Relevant");
                        setSelected(2);
                        if (measureGroupsSelector.singleMeasure?.status === 1) {
                            setMeasureStatus("todo");
                            setUserData({ ...userData, userId: measureGroupsSelector.singleMeasure?.task?.assignedTo !== "" ? measureGroupsSelector.singleMeasure?.task?.assignedTo : decodedUserInfo?.payload?.userId })
                            const date = new Date(measureGroupsSelector.singleMeasure?.task?.deadline);
                            setProgressDoneDate(date)
                            setToDoArea(measureGroupsSelector.singleMeasure?.task?.description);
                        } else if (measureGroupsSelector.singleMeasure?.status === 2) {
                            setMeasureStatus("inProgress");
                            setUserData({ ...userData, userId: measureGroupsSelector.singleMeasure.task?.assignedTo !== "" ? measureGroupsSelector.singleMeasure.task?.assignedTo : decodedUserInfo?.payload?.userId })
                            const date = new Date(measureGroupsSelector.singleMeasure?.task?.deadline);
                            setProgressDoneDate(date)
                            setToDoArea(measureGroupsSelector.singleMeasure?.task?.description);
                        } else if (measureGroupsSelector.singleMeasure?.status === 3) {
                            setMeasureStatus("done");
                            if (measureGroupsSelector.singleMeasure?.evidence === 2) {
                                setAnswerSubType("Now");
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) });
                                setDetails(measureGroupsSelector?.singleMeasure?.evidenceDetails)
                                if (measureGroupsSelector.singleMeasure?.reminderTasks.length > 0) {
                                    let editReminderTasksList = measureGroupsSelector.singleMeasure?.reminderTasks.map((ele: Frequency) => ({
                                        frequency: ele.frequency,
                                        status: ele.status,
                                        taskId: ele.taskId,
                                        taskListId: ele?.taskListId,
                                        assignedTo: ele.assignedTo,
                                        deadline: "",
                                        nameWhenOther: ele.nameWhenOther,
                                        firstName: firstName
                                    }))
                                    setReminderTasksList([...editReminderTasksList])
                                } else {
                                    setReminderTasksList([])
                                }
                                if (measureGroupsSelector.singleMeasure?.files.length > 0) {
                                    setSelectedFile([...editMeasureObj.files]);
                                } else {
                                    setSelectedFile([]);
                                }
                            } else if (measureGroupsSelector.singleMeasure?.evidence === 3) {
                                setAnswerSubType("Later");
                                const date = new Date(measureGroupsSelector?.singleMeasure?.evidenceTask?.deadline) || new Date();
                                setEvidenceDoneDate(date);
                                setUserData({ ...userData, userId: measureGroupsSelector.singleMeasure.evidenceTask?.assignedTo !== "" ? measureGroupsSelector.singleMeasure.evidenceTask?.assignedTo : decodedUserInfo?.payload?.userId })
                            } else if (measureGroupsSelector.singleMeasure?.evidence === 4) {
                                setAnswerSubType("Never");
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload.userId) });
                            }
                        }
                    }
                }
            }
        }
    }, [measureGroupsSelector.singleMeasure])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (measureGroupsSelector?.loadingAfterPost) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [measureGroupsSelector?.loadingAfterPost])

    // functions
    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    async function handlePreviousClick() {
        if (currentIndex === -1) {
            if (id && mgId && measures[measures?.length - 1] && measures[measures?.length - 1]?.measureId) {
                dispatch(measureGroupsContainerCreators.requestGetMeasureById(id, mgId, measures[measures?.length - 1]?.measureId))
            }
            setCurrentIndex(measures.length - 1);
        } else if (currentIndex > -1) {
            setCurrentIndex(currentIndex - 1);
            if (id && mgId && measures[currentIndex - 1] && measures[currentIndex - 1]?.measureId) {
                dispatch(measureGroupsContainerCreators.requestGetMeasureById(id, mgId, measures[currentIndex - 1]?.measureId))
            }
        }
        //setTimeout(() => {
        //    dispatch(measureGroupsContainerCreators.requestGetMeasures(singleRiskCategory.riskCategoryId, addMeasureGroupObj?.measuregroupId, addMeasureGroupObj, '', '', decodedUserInfo, singleRiskCategory));
        //}, 1500)
    }

    function handleCancelClick() {
        if (from === "task") {
            navigate(`/tasks`);
        } else if (from === "dashboard") {
            navigate(`/dashboard?isCancel=0`);
        } else if (from === "manageRiskArea") {
            navigate(`/manage-risk-areas`);
        } else {
            navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj), from: 'cancal' } });
        }
    }

    /* istanbul ignore next */
    function handleAnswerType(answer: string) {
        if (((role === "administrator") || (role === "editor"))) {
            if (answer === 'Relevant') {
                setAnswerType('Relevant');
                setSelected(2);
                setErrObj({ dropDown: undefined })
            } else if (answer === 'NotRelevant') {
                setAnswerType('NotRelevant');
                setSelected(1);
                setErrObj({ dropDown: undefined })
            } else if (answer === 'Yes') {
                setSingleMeasure({
                    ...intialMeasure,
                    measureListId: '00000000-0000-0000-0000-000000000000'
                });
                setAnswerType('Yes');
                setSelected(2);
                setErrObj({ dropDown: undefined, reminder: undefined, reminderFrequency: undefined })
            } else if (answer === 'No') {
                setSingleMeasure({
                    ...intialMeasure,
                    measureListId: '00000000-0000-0000-0000-000000000000'
                });
                setAnswerType('No');
                setSelected(1);
                setErrObj({ dropDown: undefined, reminder: undefined, reminderFrequency: undefined })
            } else if (answer === '') {
                setAnswerType('');
                setSelected(2);
                setErrObj({ dropDown: undefined, reminder: undefined, reminderFrequency: undefined })
            }
        }
    }

    /* istanbul ignore next */
    function getEvidenceValue() {
        if (answerSubType === "Now") {
            return 2
        } else if (answerSubType === "Later") {
            return 3
        } else if (answerSubType === "Never") {
            return 4
        } else {
            return 0
        }
    }

    /* istanbul ignore next */
    const handleInput = (value: any) => {
        userData['userId'] = value;
        setUserData({ ...userData });
    };

    /* istanbul ignore next */
    function handleProgressDoneDate(date: Date) {
        const newDateFormate = dayjs(date).format('YYYY-MM-DD');
        setFormatedDate(newDateFormate.toString());
        setProgressDoneDate(date);
    }

    // Add manual measure
    /* istanbul ignore next */
    function handleAnswerYes() {
        const dropDownErr = schema.measureDropDown.validate(userData.userId || reminderData.assignedTo).error;
        const reminderDetailsErr = schema.reminderDetails.validate(details).error;
        const hasNameErr = editIndex !== null || (showInput && stepData?.name !== "");
        const stepNameErr = schema.stepName.validate(stepData.name).error;

        if (measureStatus === 'done') {
            if (answerSubType === 'Never') {
                const payload = {
                    measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: selected,
                    details: "",
                    evidenceDetails: "",
                    // status: 3,
                    effort: Number(effortAnswer),
                    impact: Number(impactAnswer),
                    evidence: getEvidenceValue(),
                    steps: [...stepsList],
                    task: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    evidenceTask: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    reminderTasks: []
                }
                dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                setErrObj({ name: undefined, reminder: undefined });
                setCurrentIndex(-1);
                setStepData({ ...stepData });
                setStepsList([]);
                setImpactAnswer("");
                setEffortAnswer("");
                setAnswerType('');
                setnameWhenOther('');
                setMeasureStatus('');
                setAnswerSubType('');
                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                setProgressDoneDate(new Date());
                setEvidenceDoneDate(new Date());
                setToDoArea('');
            } else if (answerSubType === 'Later') {
                if (dropDownErr) {
                    setErrObj({ dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined });
                } else {
                    const payload = {
                        measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                        nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                        selected: selected,
                        details: "",
                        evidenceDetails: "",
                        // status: 3,
                        effort: Number(effortAnswer),
                        impact: Number(impactAnswer),
                        evidence: getEvidenceValue(),
                        steps: [...stepsList],
                        task: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        evidenceTask: {
                            assignedTo: userData?.userId !== "" ? userData?.userId : "",
                            deadline: ((evidenceFormatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? evidenceFormatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString()),
                            description: ""
                        },
                        reminderTasks: []
                    }
                    dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                    setErrObj({ name: undefined, reminder: undefined, dropDown: undefined });
                    setCurrentIndex(-1);
                    setAnswerType('');
                    setnameWhenOther('');
                    setMeasureStatus('');
                    setAnswerSubType('');
                    setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                    setProgressDoneDate(new Date());
                    setEvidenceDoneDate(new Date());
                    setToDoArea('');
                    setStepsList([]);
                    setImpactAnswer("");
                    setEffortAnswer("");
                    setStepData({ ...stepData });
                }
            } else if (answerSubType === 'Now') {
                if (reminderDetailsErr) {
                    setErrObj({ reminderDetails: reminderDetailsErr?.message ? { message: intl.formatMessage({ id: reminderDetailsErr?.message, defaultMessage: reminderDetailsErr?.message }) } : undefined });
                } else {
                    setErrObj({ reminderDetails: undefined });
                    const fileList = selectedFile.map((ele) => ({
                        fileId: ele?.fileId,
                    }))
                    if (periodicReminderAnswerType === 'No') {
                        const payload = {
                            measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: selected,
                            details: "",
                            evidenceDetails: details !== "" ? details : "",
                            // status: 3,
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: getEvidenceValue(),
                            steps: [...stepsList],
                            task: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: [],
                            files: fileList.length > 0 ? fileList : []
                        }
                        dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                        setCurrentIndex(-1);
                        setAnswerType('');
                        setnameWhenOther('');
                        setMeasureStatus('');
                        setDetails('');
                        setSelectedFile([]);
                        setAnswerSubType('');
                        setPeriodicReminderAnswerType('');
                        setMonthlyReminder(false);
                        setReminderTasksList([]);
                        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                        setProgressDoneDate(new Date());
                        setEvidenceDoneDate(new Date());
                        setToDoArea('');
                        setStepsList([]);
                        setImpactAnswer("");
                        setEffortAnswer("");
                        setStepData({ ...stepData });
                    } else {
                        const updatedReminderTasksList = reminderTasksList.map((ele) => ({
                            frequency: Number(ele.frequency),
                            status: ele.status,
                            taskId: ele.taskId,
                            taskListId: "00000000-0000-0000-0000-000000000000",
                            assignedTo: ele.assignedTo,
                            deadline: "",
                            nameWhenOther: ele.nameWhenOther
                        }))

                        const payload = {
                            measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: selected,
                            details: "",
                            evidenceDetails: details !== "" ? details : "",
                            // status: 3,
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: getEvidenceValue(),
                            steps: [...stepsList],
                            task: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: updatedReminderTasksList.length > 0 ? updatedReminderTasksList : [],
                            files: fileList.length > 0 ? fileList : []
                        }
                        dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                        setCurrentIndex(-1);
                        setAnswerType('');
                        setnameWhenOther('');
                        setMeasureStatus('');
                        setDetails('');
                        setSelectedFile([]);
                        setAnswerSubType('');
                        setPeriodicReminderAnswerType('');
                        setMonthlyReminder(false);
                        setReminderTasksList([]);
                        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                        setProgressDoneDate(new Date());
                        setEvidenceDoneDate(new Date());
                        setToDoArea('');
                        setStepsList([]);
                        setImpactAnswer("");
                        setEffortAnswer("");
                        setStepData({ ...stepData });
                    }
                }
            }
        } else if (measureStatus === "inProgress" || measureStatus === "todo") {
            if (dropDownErr || hasNameErr) {
                setErrObj({
                    dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                    hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                });
            } else {
                const payload = {
                    measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: selected,
                    details: "",
                    evidenceDetails: "",
                    // status: getStatusValue(manualMeasureAnswerType),
                    effort: Number(effortAnswer),
                    impact: Number(impactAnswer),
                    evidence: 0,
                    steps: [...stepsList],
                    task: {
                        assignedTo: userData?.userId,
                        deadline: (formatedDate !== '' && decodedUserInfo?.payload?.planningMethod !== 2) ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                        description: ""
                    },
                    evidenceTask: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    reminderTasks: []
                }
                dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                setErrObj({ name: undefined, dropDown: undefined, description: undefined, hasName: undefined });
                setCurrentIndex(-1);
                setAnswerType('');
                setnameWhenOther('');
                setMeasureStatus('');
                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                setProgressDoneDate(new Date());
                setToDoArea('');
                setStepsList([]);
                setImpactAnswer("");
                setEffortAnswer("");
                setStepData({ ...stepData });
            }
        }
    }

    // Add manual measure on answer blank
    /* istanbul ignore next */
    function handleAnswerBlank() {
        const dropDownErr = schema.measureDropDown.validate(userData.userId || reminderData.assignedTo).error;
        const reminderDetailsErr = schema.reminderDetails.validate(details).error;
        const hasNameErr = editIndex !== null || (showInput && stepData?.name !== "");;
        const stepNameErr = schema.stepName.validate(stepData.name).error;

        if (measureStatus === 'done') {
            if (answerSubType === 'Never') {
                const payload = {
                    measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: 2,
                    details: "",
                    evidenceDetails: "",
                    // status: 3,
                    effort: Number(effortAnswer),
                    impact: Number(impactAnswer),
                    evidence: getEvidenceValue(),
                    steps: [...stepsList],
                    task: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    evidenceTask: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    reminderTasks: []
                }
                dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, navigate, (addMeasureGroupObj || editMeasureGroupObj)));
                setErrObj({ name: undefined, reminder: undefined });
                setCurrentIndex(-1);
                setAnswerType('');
                setnameWhenOther('');
                setMeasureStatus('');
                setAnswerSubType('');
                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                setProgressDoneDate(new Date());
                setEvidenceDoneDate(new Date());
                setToDoArea('');
                setStepsList([]);
                setImpactAnswer("");
                setEffortAnswer("");
                setStepData({ ...stepData });
            } else if (answerSubType === 'Later') {
                if (dropDownErr || hasNameErr) {
                    setErrObj({
                        dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                        hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                    });
                } else {
                    const payload = {
                        measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                        nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                        selected: 2,
                        details: "",
                        evidenceDetails: "",
                        // status: 3,
                        effort: Number(effortAnswer),
                        impact: Number(impactAnswer),
                        evidence: getEvidenceValue(),
                        steps: [...stepsList],
                        task: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        evidenceTask: {
                            assignedTo: userData?.userId !== "" ? userData?.userId : "",
                            deadline: ((evidenceFormatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? evidenceFormatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString()),
                            description: ""
                        },
                        reminderTasks: []
                    }
                    dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, navigate, (addMeasureGroupObj || editMeasureGroupObj)));
                    setErrObj({ name: undefined, reminder: undefined, dropDown: undefined });
                    setCurrentIndex(-1);
                    setAnswerType('');
                    setnameWhenOther('');
                    setMeasureStatus('');
                    setAnswerSubType('');
                    setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                    setProgressDoneDate(new Date());
                    setEvidenceDoneDate(new Date());
                    setToDoArea('');
                    setStepsList([]);
                    setImpactAnswer("");
                    setEffortAnswer("");
                    setStepData({ ...stepData });
                }
            } else if (answerSubType === 'Now') {
                if (reminderDetailsErr) {
                    setErrObj({ reminderDetails: reminderDetailsErr?.message ? { message: intl.formatMessage({ id: reminderDetailsErr?.message, defaultMessage: reminderDetailsErr?.message }) } : undefined });
                } else {
                    setErrObj({ reminderDetails: undefined });
                    const fileList = selectedFile.map((ele) => ({
                        fileId: ele?.fileId,
                    }))
                    if (periodicReminderAnswerType === '') {
                        const updatedReminderTasksList = reminderTasksList.map((ele) => ({
                            frequency: Number(ele.frequency),
                            status: ele.status,
                            taskId: ele.taskId,
                            taskListId: "00000000-0000-0000-0000-000000000000",
                            assignedTo: ele.assignedTo,
                            deadline: "",
                            nameWhenOther: ele.nameWhenOther
                        }))

                        const payload = {
                            measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: 2,
                            details: "",
                            evidenceDetails: details !== "" ? details : "",
                            // status: 3,
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: getEvidenceValue(),
                            steps: [...stepsList],
                            task: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: updatedReminderTasksList.length > 0 ? updatedReminderTasksList : [],
                            files: fileList.length > 0 ? fileList : []
                        }
                        dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, navigate, (addMeasureGroupObj || editMeasureGroupObj)));
                        setCurrentIndex(-1);
                        setAnswerType('');
                        setnameWhenOther('');
                        setMeasureStatus('');
                        setDetails('');
                        setSelectedFile([]);
                        setAnswerSubType('');
                        setPeriodicReminderAnswerType('');
                        setMonthlyReminder(false);
                        setReminderTasksList([]);
                        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                        setProgressDoneDate(new Date());
                        setEvidenceDoneDate(new Date());
                        setToDoArea('');
                        setStepsList([]);
                        setImpactAnswer("");
                        setEffortAnswer("");
                        setStepData({ ...stepData });
                    }
                }
            }
        } else if (measureStatus === "inProgress" || measureStatus === "todo") {
            if (dropDownErr) {
                setErrObj({
                    dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined

                });
            } else {
                const payload = {
                    measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: 2,
                    details: "",
                    evidenceDetails: "",
                    // status: getStatusValue(manualMeasureAnswerType),
                    effort: Number(effortAnswer),
                    impact: Number(impactAnswer),
                    evidence: 0,
                    steps: [...stepsList],
                    task: {
                        assignedTo: userData?.userId,
                        deadline: (formatedDate !== '' && decodedUserInfo?.payload?.planningMethod !== 2) ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                        description: ""
                    },
                    evidenceTask: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    reminderTasks: []
                }
                dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, navigate, (addMeasureGroupObj || editMeasureGroupObj)));
                setErrObj({ name: undefined, dropDown: undefined, description: undefined });
                setCurrentIndex(-1);
                setAnswerType('');
                setnameWhenOther('');
                setMeasureStatus('');
                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                setProgressDoneDate(new Date());
                setToDoArea('');
                setStepsList([]);
                setImpactAnswer("");
                setEffortAnswer("");
                setStepData({ ...stepData });
            }
        }
    }

    function resetValues() {
        setAnswerType('');
        setAnswerSubType('');
        setDetails('');
        setSelectedFile([]);
        setReminderTasksList([]);
        setPeriodicReminderAnswerType('');
        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
        setProgressDoneDate(new Date());
        setEvidenceDoneDate(new Date());
        setToDoArea('');
        setnameWhenOther('');
        setMeasureStatus('');
        setMonthlyReminder(false);
        setStepData({ ...stepData, name: '' });
        setStepsList([]);
        setImpactAnswer("");
        setEffortAnswer("");
    }

    // Edit manual measure
    /* istanbul ignore next */
    function handleAnswerYesOnEdit(isAddMode?: boolean) {
        const dropDownErr = schema.measureDropDown.validate(userData.userId || reminderData.assignedTo).error;
        const reminderFrequencyErr = schema.reminderFrequencyDropDown.validate(reminderData.frequency).error;
        const descriptionErr = schema.measureDescription.validate(toDoArea).error;
        const reminderDetailsErr = schema.reminderDetails.validate(details).error;
        const reminderNameErr = schema.reminderNameWhenOther.validate(reminderData.nameWhenOther).error;
        const hasNameErr = editIndex !== null || (showInput && stepData?.name !== "");;
        const stepNameErr = schema.stepName.validate(stepData.name).error;

        if (measureStatus === 'done') {
            if (answerSubType === 'Never') {
                const payload = {
                    measureListId: editMeasureObj.measureListId,
                    nameWhenOther: nameWhenOther,
                    selected: 2,
                    details: "",
                    evidenceDetails: "",
                    // status: 3,
                    effort: Number(effortAnswer),
                    impact: Number(impactAnswer),
                    evidence: getEvidenceValue(),
                    steps: [...stepsList],
                    task: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    evidenceTask: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    reminderTasks: []
                }
                dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                if (isAddMode) {
                    resetValues();
                    if (currentIndex + 1 < measures.length) {
                        setCurrentIndex(currentIndex + 1);
                    } else {
                        setCurrentIndex(-1);
                    }
                }
            } else if (answerSubType === 'Later') {
                if (dropDownErr || hasNameErr) {
                    setErrObj({
                        dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                        hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                        //name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                    });
                } else {
                    const payload = {
                        measureListId: editMeasureObj.measureListId,
                        nameWhenOther: nameWhenOther,
                        selected: 2,
                        details: "",
                        evidenceDetails: "",
                        // status: 3,
                        effort: Number(effortAnswer),
                        impact: Number(impactAnswer),
                        evidence: getEvidenceValue(),
                        steps: [...stepsList],
                        task: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        evidenceTask: {
                            assignedTo: userData?.userId !== "" ? userData?.userId : "",
                            deadline: ((evidenceFormatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? evidenceFormatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString()),
                            description: ""
                        },
                        reminderTasks: []
                    }
                    dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                    setErrObj({ dropDown: undefined, stepName: undefined, impactErr: undefined, effortErr: undefined, hasName: undefined })
                    if (isAddMode) {
                        resetValues();
                        if (currentIndex + 1 < measures.length) {
                            setCurrentIndex(currentIndex + 1);
                        } else {
                            setCurrentIndex(-1);
                        }
                    }
                }
            } else if (answerSubType === 'Now') {
                if (reminderDetailsErr) {
                    setErrObj({ reminderDetails: reminderDetailsErr?.message ? { message: intl.formatMessage({ id: reminderDetailsErr?.message, defaultMessage: reminderDetailsErr?.message }) } : undefined });
                } else {
                    setErrObj({ reminderDetails: undefined });
                    const fileList = selectedFile.map((ele) => ({
                        fileId: ele?.fileId,
                    }))
                    if (periodicReminderAnswerType === 'No') {
                        const payload = {
                            measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: 2,
                            details: "",
                            evidenceDetails: details !== "" ? details : "",
                            // status: 3,
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: getEvidenceValue(),
                            steps: [...stepsList],
                            task: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: [],
                            files: fileList.length > 0 ? fileList : []
                        }
                        dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                        if (isAddMode) {
                            resetValues();
                            if (currentIndex + 1 < measures.length) {
                                setCurrentIndex(currentIndex + 1);
                            } else {
                                setCurrentIndex(-1);
                            }
                        }
                    } else if (periodicReminderAnswerType === 'Yes') {
                        if (reminderNameErr || dropDownErr || reminderFrequencyErr) {
                            setErrObj({
                                reminder: reminderNameErr?.message ? { message: intl.formatMessage({ id: reminderNameErr?.message, defaultMessage: reminderNameErr?.message }) } : undefined,
                                dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                                reminderFrequency: reminderFrequencyErr?.message ? { message: intl.formatMessage({ id: reminderFrequencyErr?.message, defaultMessage: reminderFrequencyErr?.message }) } : undefined
                            });
                        } else {
                            let updatedReminderTasksList = [];
                            if (isEditReminder === null) {
                                reminderTasksList.push(reminderData);
                                updatedReminderTasksList = reminderTasksList.map((ele) => ({
                                    frequency: Number(ele.frequency),
                                    status: ele.status,
                                    taskId: ele.taskId,
                                    taskListId: "00000000-0000-0000-0000-000000000000",
                                    assignedTo: ele.assignedTo,
                                    deadline: "",
                                    nameWhenOther: ele.nameWhenOther
                                }))
                                setReminderTasksList(reminderTasksList);
                            } else {
                                let latestArray = [...reminderTasksList];
                                latestArray[isEditReminder] = {
                                    ...reminderData
                                }
                                updatedReminderTasksList = latestArray.map((ele) => ({
                                    frequency: Number(ele.frequency),
                                    status: ele.status,
                                    taskId: ele.taskId,
                                    taskListId: "00000000-0000-0000-0000-000000000000",
                                    assignedTo: ele.assignedTo,
                                    deadline: "",
                                    nameWhenOther: ele.nameWhenOther
                                }))
                                setReminderTasksList(latestArray);
                            }
                            setErrObj({ name: undefined, reminderDetails: undefined, reminder: undefined, reminderFrequency: undefined, dropDown: undefined });
                            setReminderData({
                                frequency: '',
                                status: 0,
                                taskId: "",
                                taskListId: "00000000-0000-0000-0000-000000000000",
                                assignedTo: "",
                                deadline: new Date(),
                                nameWhenOther: "",
                                firstName: ""
                            });

                            const payload = {
                                measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                                nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                                selected: 2,
                                details: "",
                                evidenceDetails: details !== "" ? details : "",
                                // status: 3,
                                effort: Number(effortAnswer),
                                impact: Number(impactAnswer),
                                evidence: getEvidenceValue(),
                                steps: [...stepsList],
                                task: {
                                    assignedTo: "",
                                    deadline: "",
                                    description: ""
                                },
                                evidenceTask: {
                                    assignedTo: "",
                                    deadline: "",
                                    description: ""
                                },
                                reminderTasks: (answerType === "Yes" && answerSubType === "Now") ? updatedReminderTasksList : [],
                                files: fileList.length > 0 ? fileList : []
                            }
                            dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                            if (isAddMode) {
                                resetValues();
                                if (currentIndex + 1 < measures.length) {
                                    setCurrentIndex(currentIndex + 1);
                                } else {
                                    setCurrentIndex(-1);
                                }
                            }
                        }
                    } else {
                        const updatedReminderTasksList = reminderTasksList.map((ele) => ({
                            frequency: Number(ele.frequency),
                            status: ele.status,
                            taskId: ele.taskId,
                            taskListId: "00000000-0000-0000-0000-000000000000",
                            assignedTo: ele.assignedTo,
                            deadline: "",
                            nameWhenOther: ele.nameWhenOther
                        }))

                        const payload = {
                            measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: 2,
                            details: "",
                            evidenceDetails: details !== "" ? details : "",
                            // status: 3,
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: getEvidenceValue(),
                            steps: [...stepsList],
                            task: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: updatedReminderTasksList.length > 0 ? updatedReminderTasksList : [],
                            files: fileList.length > 0 ? fileList : []
                        }
                        dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                        if (isAddMode) {
                            resetValues();
                            if (currentIndex + 1 < measures.length) {
                                setCurrentIndex(currentIndex + 1);
                            } else {
                                setCurrentIndex(-1);
                            }
                        }
                    }
                }
            }
        } else if (measureStatus === "inProgress" || measureStatus === "todo") {
            if (dropDownErr || hasNameErr) {
                setErrObj({
                    dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                    hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                });
            } else {
                const payload = {
                    measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: 2,
                    details: "",
                    evidenceDetails: "",
                    // status: getStatusValue(manualMeasureAnswerType),
                    effort: Number(effortAnswer),
                    impact: Number(impactAnswer),
                    evidence: 0,
                    steps: [...stepsList],
                    task: {
                        assignedTo: userData?.userId,
                        deadline: (formatedDate !== '' ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString()),
                        description: ""
                    },
                    evidenceTask: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    reminderTasks: []
                }
                dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                setErrObj({ name: undefined, dropDown: undefined, description: undefined, stepName: undefined, effortErr: undefined, impactErr: undefined, hasName: undefined });
                if (isAddMode) {
                    resetValues();
                    if (currentIndex + 1 < measures.length) {
                        setCurrentIndex(currentIndex + 1);
                    } else {
                        setCurrentIndex(-1);
                    }
                }
            }
        }
    }

    /* istanbul ignore next */
    function relevantOnEdit(isAddMode?: boolean) {
        const dropDownErr = schema.measureDropDown.validate(userData.userId || reminderData.assignedTo).error;
        const reminderDetailsErr = schema.reminderDetails.validate(details).error;
        const hasNameErr = editIndex !== null || (showInput && stepData?.name !== "");;
        const stepNameErr = schema.stepName.validate(stepData.name).error;

        if (answerSubType === 'Never') {
            const payload = {
                measureListId: editMeasureObj?.measureListId,
                nameWhenOther: nameWhenOther,
                selected: selected,
                details: "",
                evidenceDetails: "",
                // status: 3,
                effort: Number(effortAnswer),
                impact: Number(impactAnswer),
                evidence: getEvidenceValue(),
                steps: [...stepsList],
                task: {
                    assignedTo: "",
                    deadline: "",
                    description: ""
                },
                evidenceTask: {
                    assignedTo: "",
                    deadline: "",
                    description: ""
                },
                reminderTasks: []
            }
            dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
            if (isAddMode) {
                resetValues();
                if (currentIndex + 1 < measures.length) {
                    setCurrentIndex(currentIndex + 1);
                } else {
                    setCurrentIndex(-1);
                }
            }
        }
        else if (answerSubType === 'Later') {
            if (dropDownErr || hasNameErr) {
                setErrObj({
                    dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                    hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                });
            } else {
                const payload = {
                    measureListId: editMeasureObj?.measureListId,
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: selected,
                    details: "",
                    evidenceDetails: "",
                    // status: 3,
                    effort: Number(effortAnswer),
                    impact: Number(impactAnswer),
                    evidence: getEvidenceValue(),
                    steps: [...stepsList],
                    task: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    evidenceTask: {
                        assignedTo: userData?.userId !== "" ? userData?.userId : "",
                        deadline: ((evidenceFormatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? evidenceFormatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString()),
                        description: ""
                    },
                    reminderTasks: []
                }
                dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                if (isAddMode) {
                    resetValues();
                    if (currentIndex + 1 < measures.length) {
                        setCurrentIndex(currentIndex + 1);
                    } else {
                        setCurrentIndex(-1);
                    }
                }
                setAnswerType('');
                setnameWhenOther('');
                setMeasureStatus('');
                setDetails('');
                setSelectedFile([]);
                setAnswerSubType('');
                setPeriodicReminderAnswerType('');
                setMonthlyReminder(false);
                setReminderTasksList([]);
                setUserData({ ...userData, userId: '' });
                setProgressDoneDate(new Date());
                setEvidenceDoneDate(new Date());
                setToDoArea('');
            }
        }
        else if (answerSubType === 'Now') {
            const fileList = selectedFile.map((ele) => ({
                fileId: ele?.fileId,
            }))
            if (reminderDetailsErr) {
                setErrObj({ reminderDetails: reminderDetailsErr?.message ? { message: intl.formatMessage({ id: reminderDetailsErr?.message, defaultMessage: reminderDetailsErr?.message }) } : undefined });
            } else {
                setErrObj({ reminderDetails: undefined });
                const updatedReminderTasksList = reminderTasksList.map((ele) => ({
                    frequency: Number(ele.frequency),
                    status: ele.status,
                    taskId: ele.taskId,
                    taskListId: "00000000-0000-0000-0000-000000000000",
                    assignedTo: ele.assignedTo,
                    deadline: "",
                    nameWhenOther: ele.nameWhenOther
                }))

                const payload = {
                    measureListId: editMeasureObj?.measureListId,
                    nameWhenOther: nameWhenOther,
                    selected: selected,
                    details: "",
                    evidenceDetails: details !== "" ? details : "",
                    // status: getStatusValue(answerType),
                    effort: Number(effortAnswer),
                    impact: Number(impactAnswer),
                    evidence: getEvidenceValue(),
                    steps: [...stepsList],
                    task: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    evidenceTask: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    reminderTasks: (answerType === "Relevant" && answerSubType === "Now") ? updatedReminderTasksList : [],
                    files: (answerType === "Relevant" && answerSubType === "Now") ? fileList : [],
                }
                dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                if (isAddMode) {
                    resetValues();
                    if (currentIndex + 1 < measures.length) {
                        setCurrentIndex(currentIndex + 1);
                    } else {
                        setCurrentIndex(-1);
                    }
                }
            }
        }
    }

    // handle Edit/PUT in Add Mode 
    function handleEditInAddMode(isAddMode: boolean) {
        const nameErr = schema.measureNameWhenOther.validate(nameWhenOther).error;
        const dropDownErr = schema.measureDropDown.validate(userData.userId || reminderData.assignedTo).error;
        const descriptionErr = schema.measureDescription.validate(toDoArea).error;
        const hasNameErr = editIndex !== null || (showInput && stepData?.name !== "");;
        const stepNameErr = schema.stepName.validate(stepData.name).error;
        if (answerType === "") {
            if (nameErr || hasNameErr) {
                setErrObj({
                    name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                    hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                });
            } else {
                handleAnswerYesOnEdit(isAddMode);
            }
        } else {
            if (answerType === "Relevant" && measureStatus === "done") {
                relevantOnEdit(isAddMode);
            } else if (answerType === "NotRelevant") {
                const payload = {
                    measureListId: editMeasureObj?.measureListId,
                    nameWhenOther: nameWhenOther,
                    selected: selected,
                    details: "",
                    evidenceDetails: details !== "" ? details : "",
                    // status: getStatusValue(answerType),
                    effort: Number(effortAnswer),
                    impact: Number(impactAnswer),
                    evidence: 0,
                    steps: [...stepsList],
                    task: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    evidenceTask: {
                        assignedTo: "",
                        deadline: "",
                        description: ""
                    },
                    reminderTasks: [],
                    files: [],
                }
                dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                if (isAddMode) {
                    resetValues();
                    if (currentIndex + 1 < measures.length) {
                        setCurrentIndex(currentIndex + 1);
                    } else {
                        setCurrentIndex(-1);
                    }
                }
            } else if (answerType === "Relevant" && (measureStatus === "inProgress" || measureStatus === "todo")) {
                if (dropDownErr) {
                    setErrObj({
                        dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined
                    });
                } else {
                    const payload = {
                        measureListId: editMeasureObj?.measureListId,
                        nameWhenOther: "",
                        selected: selected,
                        details: "",
                        evidenceDetails: "",
                        // status: getStatusValue(answerType),
                        effort: Number(effortAnswer),
                        impact: Number(impactAnswer),
                        evidence: 0,
                        steps: [...stepsList],
                        task: {
                            assignedTo: userData?.userId,
                            deadline: (formatedDate !== '' ? formatedDate : (editMeasureObj?.task?.deadline ? dayjs(editMeasureObj?.task?.deadline).format('YYYY-MM-DD').toString() : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString())),
                            description: ""
                        },
                        evidenceTask: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        reminderTasks: [],
                        files: []
                    }
                    dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                    setErrObj({ description: undefined, dropDown: undefined });
                    if (isAddMode) {
                        resetValues();
                        if (currentIndex + 1 < measures.length) {
                            setCurrentIndex(currentIndex + 1);
                        } else {
                            setCurrentIndex(-1);
                        }
                    }
                }
            } else {
                if (answerType === "No") {
                    const payload = {
                        measureListId: editMeasureObj?.measureListId,
                        nameWhenOther: nameWhenOther,
                        selected: selected,
                        details: "",
                        evidenceDetails: "",
                        // status: 0,
                        effort: Number(effortAnswer),
                        impact: Number(impactAnswer),
                        evidence: 0,
                        steps: [...stepsList],
                        task: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        evidenceTask: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        reminderTasks: [],
                        files: []
                    }
                    dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, (isAddMode ? '' : navigate), mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                    if (isAddMode) {
                        resetValues();
                        if (currentIndex + 1 < measures.length) {
                            setCurrentIndex(currentIndex + 1);
                        } else {
                            setCurrentIndex(-1);
                        }
                    }
                }
            }
        }
    }

    /* istanbul ignore next */
    function handleNextClick(btn: string) {
        if (measures[currentIndex]?.measureId) {
            // EDIT MEASURE IN ADD MODE
            if (id && mgId && measures[currentIndex + 1]?.measureId) {
                dispatch(measureGroupsContainerCreators.requestGetMeasureById(id, mgId, measures[currentIndex + 1]?.measureId))
            }
            handleEditInAddMode(true);
        }
        else if (editMeasureObj) {
            // Edit Measures
            const nameErr = schema.measureNameWhenOther.validate(nameWhenOther).error;
            const dropDownErr = schema.measureDropDown.validate(userData.userId || reminderData.assignedTo).error;
            const hasNameErr = editIndex !== null || (showInput && stepData?.name !== "");;
            const stepNameErr = schema.stepName.validate(stepData.name).error;
            // const descriptionErr = schema.measureDescription.validate(toDoArea).error;
            if (answerType === "") {
                if (nameErr || hasNameErr) {
                    setErrObj({
                        name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                        hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                    });
                } else {
                    handleAnswerYesOnEdit();
                }
            } else {

                if (answerType === "Relevant" && measureStatus === "done") {
                    relevantOnEdit()
                }
                else if (answerType === "NotRelevant") {
                    const payload = {
                        measureListId: editMeasureObj?.measureListId,
                        nameWhenOther: nameWhenOther,
                        selected: selected,
                        details: "",
                        evidenceDetails: details !== "" ? details : "",
                        // status: getStatusValue(answerType),
                        effort: Number(effortAnswer),
                        impact: Number(impactAnswer),
                        evidence: 0,
                        steps: [...stepsList],
                        task: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        evidenceTask: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        reminderTasks: [],
                        files: [],
                    }
                    dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, navigate, mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                } else if (measureStatus === "inProgress" || measureStatus === "todo") {
                    if (dropDownErr || hasNameErr) {
                        setErrObj({
                            // description: descriptionErr?.message ? { message: intl.formatMessage({ id: descriptionErr?.message, defaultMessage: descriptionErr?.message }) } : undefined,
                            dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                            hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                        });
                    } else {
                        const payload = {
                            measureListId: editMeasureObj?.measureListId,
                            nameWhenOther: "",
                            selected: selected,
                            details: "",
                            evidenceDetails: "",
                            // status: getStatusValue(answerType),
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: 0,
                            steps: [...stepsList],
                            task: {
                                assignedTo: userData?.userId,
                                deadline: (formatedDate !== '' ? formatedDate : (editMeasureObj?.task?.deadline ? dayjs(editMeasureObj?.task?.deadline).format('YYYY-MM-DD').toString() : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString())),
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: [],
                            files: []
                        }
                        dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, navigate, mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                        setErrObj({ description: undefined, dropDown: undefined, stepName: undefined, impactErr: undefined, effortErr: undefined, hasName: undefined });
                    }
                } else {
                    if (answerType === "No") {
                        const payload = {
                            measureListId: editMeasureObj?.measureListId,
                            nameWhenOther: nameWhenOther,
                            selected: selected,
                            details: "",
                            evidenceDetails: "",
                            // status: 0,
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: 0,
                            steps: [...stepsList],
                            task: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: [],
                            files: []
                        }
                        dispatch(measureGroupsContainerCreators.requestPutMeasures(id, payload, navigate, mgId, measureId, from, (addMeasureGroupObj || editMeasureGroupObj)));
                    }
                }
            }
        }
        else {
            // Add Measures
            const dropDownErr = schema.measureDropDown.validate(userData.userId || reminderData.assignedTo).error;
            const reminderFrequencyErr = schema.reminderFrequencyDropDown.validate(reminderData.frequency).error;
            const reminderDetailsErr = schema.reminderDetails.validate(details).error;
            const reminderNameErr = schema.reminderNameWhenOther.validate(reminderData.nameWhenOther).error;
            const hasNameErr =  editIndex !== null || (showInput && stepData?.name !== "");
            const stepNameErr = schema.stepName.validate(stepData.name).error;
            if (singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && lastQuestion) {
                if (answerType === "Relevant" && measureStatus === "done") {
                    if (answerSubType === 'Never') {
                        const payload = {
                            measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: "",
                            selected: selected,
                            details: "",
                            evidenceDetails: "",
                            // status: getStatusValue(answerType),
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: getEvidenceValue(),
                            steps: [...stepsList],
                            task: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: []
                        }
                        dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                        if (btn === 'save-btn') {
                            navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                        } else {
                            setStepsList([]);
                            setImpactAnswer("");
                            setEffortAnswer("");
                            setStepData({ ...stepData });
                            if (currentIndex + 1 < measures.length) {
                                setCurrentIndex(currentIndex + 1);
                                setAnswerType('');
                                setAnswerSubType('');
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                setProgressDoneDate(new Date());
                                setEvidenceDoneDate(new Date());
                                setToDoArea('');
                            } else {
                                setAnswerType('');
                                setAnswerSubType('');
                                setCurrentIndex(-1);
                            }
                        }
                    } else if (answerSubType === 'Later') {
                        if (dropDownErr) {
                            setErrObj({
                                dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                            });
                        } else {
                            const payload = {
                                measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                                nameWhenOther: "",
                                selected: selected,
                                details: "",
                                evidenceDetails: "",
                                // status: getStatusValue(answerType),
                                effort: Number(effortAnswer),
                                impact: Number(impactAnswer),
                                evidence: getEvidenceValue(),
                                steps: [...stepsList],
                                task: {
                                    assignedTo: "",
                                    deadline: "",
                                    description: ""
                                },
                                evidenceTask: {
                                    assignedTo: userData?.userId !== "" ? userData?.userId : "",
                                    deadline: ((evidenceFormatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? evidenceFormatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString()),
                                    description: ""
                                },
                                reminderTasks: []
                            }
                            dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                            if (btn === 'save-btn') {
                                navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                            } else {
                                setStepsList([]);
                                setImpactAnswer("");
                                setEffortAnswer("");
                                setStepData({ ...stepData });
                                if (currentIndex + 1 < measures.length) {
                                    setCurrentIndex(currentIndex + 1);
                                    setAnswerType('');
                                    setAnswerSubType('');
                                    setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                    setProgressDoneDate(new Date());
                                    setEvidenceDoneDate(new Date());
                                    setToDoArea('');
                                } else {
                                    setAnswerType('');
                                    setAnswerSubType('');
                                    setCurrentIndex(-1);
                                }
                            }
                            setErrObj({ dropDown: undefined, impactErr: undefined, stepName: undefined, effortErr: undefined, hasName: undefined })
                        }

                    } else if (answerSubType === 'Now') {
                        if (reminderDetailsErr) {
                            setErrObj({
                                reminderDetails: reminderDetailsErr?.message ? { message: intl.formatMessage({ id: reminderDetailsErr?.message, defaultMessage: reminderDetailsErr?.message }) } : undefined,
                            });
                        } else {
                            setErrObj({ reminderDetails: undefined, stepName: undefined, impactErr: undefined, effortErr: undefined, hasName: undefined });
                            const fileList = selectedFile.map((ele) => ({
                                fileId: ele?.fileId,
                            }))
                            if (periodicReminderAnswerType === 'No') {
                                const payload = {
                                    measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                                    nameWhenOther: "",
                                    selected: selected,
                                    details: "",
                                    evidenceDetails: details !== "" ? details : "",
                                    // status: getStatusValue(answerType),
                                    effort: Number(effortAnswer),
                                    impact: Number(impactAnswer),
                                    evidence: getEvidenceValue(),
                                    steps: [...stepsList],
                                    task: {
                                        assignedTo: "",
                                        deadline: "",
                                        description: ""
                                    },
                                    evidenceTask: {
                                        assignedTo: "",
                                        deadline: "",
                                        description: ""
                                    },
                                    reminderTasks: [],
                                    files: fileList.length > 0 ? fileList : []
                                }
                                dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                                if (btn === 'save-btn') {
                                    navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                                } else {
                                    setStepsList([]);
                                    setImpactAnswer("");
                                    setEffortAnswer("");
                                    setStepData({ ...stepData });
                                    if (currentIndex + 1 < measures.length) {
                                        setCurrentIndex(currentIndex + 1);
                                        setAnswerType('');
                                        setAnswerSubType('');
                                        setDetails('');
                                        setSelectedFile([]);
                                        setPeriodicReminderAnswerType('');
                                        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                        setProgressDoneDate(new Date());
                                        setEvidenceDoneDate(new Date());
                                        setToDoArea('');
                                    } else {
                                        setAnswerType('');
                                        setAnswerSubType('');
                                        setDetails('');
                                        setSelectedFile([]);
                                        setPeriodicReminderAnswerType('');
                                        setCurrentIndex(-1);
                                    }
                                }
                            } else if (periodicReminderAnswerType === 'Yes') {
                                if (reminderNameErr || dropDownErr || reminderFrequencyErr) {
                                    setErrObj({
                                        reminder: reminderNameErr?.message ? { message: intl.formatMessage({ id: reminderNameErr?.message, defaultMessage: reminderNameErr?.message }) } : undefined,
                                        dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                                        reminderFrequency: reminderFrequencyErr?.message ? { message: intl.formatMessage({ id: reminderFrequencyErr?.message, defaultMessage: reminderFrequencyErr?.message }) } : undefined,
                                    });
                                } else {
                                    reminderTasksList.push(reminderData);
                                    setReminderTasksList(reminderTasksList)
                                    setErrObj({ name: undefined, reminderFrequency: undefined, dropDown: undefined, impactErr: undefined, stepName: undefined, hasName: undefined });
                                    setReminderData({
                                        frequency: '',
                                        status: 0,
                                        taskId: "",
                                        taskListId: "00000000-0000-0000-0000-000000000000",
                                        assignedTo: "",
                                        deadline: new Date(),
                                        nameWhenOther: "",
                                        firstName: ""
                                    });

                                    const updatedReminderTasksList = reminderTasksList.map((ele) => ({
                                        frequency: Number(ele.frequency),
                                        status: ele.status,
                                        taskId: ele.taskId,
                                        taskListId: "00000000-0000-0000-0000-000000000000",
                                        assignedTo: ele.assignedTo,
                                        deadline: "",
                                        nameWhenOther: ele.nameWhenOther
                                    }))

                                    const payload = {
                                        measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                                        nameWhenOther: "",
                                        selected: selected,
                                        details: "",
                                        evidenceDetails: details !== "" ? details : "",
                                        // status: getStatusValue(answerType),
                                        effort: Number(effortAnswer),
                                        impact: Number(impactAnswer),
                                        evidence: getEvidenceValue(),
                                        steps: [...stepsList],
                                        task: {
                                            assignedTo: "",
                                            deadline: "",
                                            description: ""
                                        },
                                        evidenceTask: {
                                            assignedTo: "",
                                            deadline: "",
                                            description: ""
                                        },
                                        reminderTasks: (answerType === "Relevant" && answerSubType === "Now") ? updatedReminderTasksList : [],
                                        files: fileList.length > 0 ? fileList : []
                                    }
                                    dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                                    if (btn === 'save-btn') {
                                        navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                                    } else {
                                        setStepsList([]);
                                        setImpactAnswer("");
                                        setEffortAnswer("");
                                        setStepData({ ...stepData });
                                        if (currentIndex + 1 < measures.length) {
                                            setCurrentIndex(currentIndex + 1);
                                            setAnswerType('');
                                            setAnswerSubType('');
                                            setDetails('');
                                            setSelectedFile([]);
                                            setReminderTasksList([]);
                                            setPeriodicReminderAnswerType('');
                                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                            setProgressDoneDate(new Date());
                                            setEvidenceDoneDate(new Date());
                                            setToDoArea('');
                                        } else {
                                            setAnswerType('');
                                            setAnswerSubType('');
                                            setDetails('');
                                            setSelectedFile([]);
                                            setReminderTasksList([]);
                                            setPeriodicReminderAnswerType('');
                                            setCurrentIndex(-1);
                                        }
                                    }
                                }
                            } else {
                                const payload = {
                                    measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                                    nameWhenOther: "",
                                    selected: selected,
                                    details: "",
                                    evidenceDetails: details !== "" ? details : "",
                                    // status: getStatusValue(answerType),
                                    effort: Number(effortAnswer),
                                    impact: Number(impactAnswer),
                                    evidence: getEvidenceValue(),
                                    steps: [...stepsList],
                                    task: {
                                        assignedTo: "",
                                        deadline: "",
                                        description: ""
                                    },
                                    evidenceTask: {
                                        assignedTo: "",
                                        deadline: "",
                                        description: ""
                                    },
                                    reminderTasks: [],
                                    files: fileList.length > 0 ? fileList : []
                                }
                                dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                                if (btn === 'save-btn') {
                                    navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                                } else {
                                    setStepsList([]);
                                    setImpactAnswer("");
                                    setEffortAnswer("");
                                    setStepData({ ...stepData });
                                    if (currentIndex + 1 < measures.length) {
                                        setCurrentIndex(currentIndex + 1);
                                        setAnswerType('');
                                        setAnswerSubType('');
                                        setSelectedFile([]);
                                        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                        setProgressDoneDate(new Date());
                                        setEvidenceDoneDate(new Date());
                                        setToDoArea('');
                                        setDetails('');
                                    } else {
                                        setAnswerType('');
                                        setAnswerSubType('');
                                        setDetails('');
                                        setSelectedFile([]);
                                        setCurrentIndex(-1);
                                    }
                                }

                            }
                        }
                    }
                } else if (answerType === "NotRelevant") {
                    const payload = {
                        measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                        nameWhenOther: "",
                        selected: selected,
                        details: "",
                        evidenceDetails: "",
                        // status: 0,
                        effort: Number(effortAnswer),
                        impact: Number(impactAnswer),
                        evidence: 0,
                        steps: [...stepsList],
                        task: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        evidenceTask: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        reminderTasks: []
                    }
                    dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                    if (btn === 'save-btn') {
                        navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                    } else {
                        setStepsList([]);
                        setImpactAnswer("");
                        setEffortAnswer("");
                        setStepData({ ...stepData });
                        if (currentIndex + 1 < measures.length) {
                            setCurrentIndex(currentIndex + 1);
                            setAnswerType('');
                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                        } else {
                            setCurrentIndex(-1);
                            setAnswerType('');
                        }
                    }
                } else if (answerType === "Relevant" && (measureStatus === "inProgress" || measureStatus === "todo")) {
                    if (dropDownErr || hasNameErr) {

                        setErrObj({
                            dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                            hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                        });
                    } else {
                        const payload = {
                            measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: "",
                            selected: selected,
                            details: "",
                            evidenceDetails: "",
                            // status: getStatusValue(answerType),
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: 0,
                            steps: [...stepsList],
                            task: {
                                assignedTo: userData?.userId,
                                deadline: (formatedDate !== '' && decodedUserInfo?.payload?.planningMethod !== 2) ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: []
                        }
                        dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                        if (btn === 'save-btn') {
                            navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                        } else {
                            setStepsList([]);
                            setImpactAnswer("");
                            setEffortAnswer("");
                            setStepData({ ...stepData });
                            if (currentIndex + 1 < measures.length) {
                                setCurrentIndex(currentIndex + 1);
                                setAnswerType('');
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                setProgressDoneDate(new Date());
                                setToDoArea('');
                            } else {
                                setAnswerType('');
                                setCurrentIndex(-1);
                            }
                        }
                        setErrObj({ description: undefined, dropDown: undefined });
                    }
                } else {
                    if (answerType === "No") {
                        navigate(`/risk-area/${id}`);
                    }
                }

                const nameErr = schema.measureNameWhenOther.validate(nameWhenOther).error;
                if (answerType === "Yes") {
                    if (nameErr || hasNameErr) {
                        setErrObj({
                            name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                            hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                        });
                    } else {
                        handleAnswerYes();
                        setErrObj({ hasName: undefined })
                    }
                }
            } else if (singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion) {
                const nameErr = schema.measureNameWhenOther.validate(nameWhenOther).error;
                if (answerType === "") {
                    if (nameErr || hasNameErr) {
                        setErrObj({
                            name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                            hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,
                        });
                    } else {
                        handleAnswerBlank();
                    }
                }
            } else {
                setLastQuestion(true);
                if (answerType === "Relevant" && measureStatus === "done") {
                    if (answerSubType === 'Never') {
                        const payload = {
                            measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: "",
                            selected: selected,
                            details: "",
                            evidenceDetails: "",
                            // status: getStatusValue(answerType),
                            effort: 0,
                            impact: Number(impactAnswer),
                            evidence: getEvidenceValue(),
                            steps: [...stepsList],
                            task: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: []
                        }
                        dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                        if (btn === 'save-btn') {
                            navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                        } else {
                            setStepsList([]);
                            setImpactAnswer("");
                            setEffortAnswer("");
                            setStepData({ ...stepData });
                            if (currentIndex + 1 < measures.length) {
                                setCurrentIndex(currentIndex + 1);
                                setAnswerType('');
                                setAnswerSubType('');
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                setProgressDoneDate(new Date());
                                setEvidenceDoneDate(new Date());
                                setToDoArea('');
                            } else {
                                setAnswerType('');
                                setAnswerSubType('');
                                setCurrentIndex(-1);
                            }
                        }
                    } else if (answerSubType === 'Later') {
                        if (dropDownErr) {
                            setErrObj({
                                dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                            });
                        } else {
                            const payload = {
                                measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                                nameWhenOther: "",
                                selected: selected,
                                details: "",
                                evidenceDetails: "",
                                // status: getStatusValue(answerType),
                                effort: 0,
                                impact: Number(impactAnswer),
                                evidence: getEvidenceValue(),
                                steps: [...stepsList],
                                task: {
                                    assignedTo: "",
                                    deadline: "",
                                    description: ""
                                },
                                evidenceTask: {
                                    assignedTo: userData?.userId !== "" ? userData?.userId : "",
                                    deadline: ((evidenceFormatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? evidenceFormatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString()),
                                    description: ""
                                },
                                reminderTasks: []
                            }
                            dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                            if (btn === 'save-btn') {
                                navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                            } else {
                                setStepsList([]);
                                setImpactAnswer("");
                                setEffortAnswer("");
                                setStepData({ ...stepData });
                                if (currentIndex + 1 < measures.length) {
                                    setCurrentIndex(currentIndex + 1);
                                    setAnswerType('');
                                    setAnswerSubType('');
                                    setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                    setProgressDoneDate(new Date());
                                    setEvidenceDoneDate(new Date());
                                    setToDoArea('');
                                } else {
                                    setAnswerType('');
                                    setAnswerSubType('');
                                    setCurrentIndex(-1);
                                }
                            }
                            setErrObj({ dropDown: undefined })
                        }

                    } else if (answerSubType === 'Now') {
                        if (reminderDetailsErr) {
                            setErrObj({ reminderDetails: reminderDetailsErr?.message ? { message: intl.formatMessage({ id: reminderDetailsErr?.message, defaultMessage: reminderDetailsErr?.message }) } : undefined });
                        } else {
                            setErrObj({ reminderDetails: undefined });
                            const fileList = selectedFile.map((ele) => ({
                                fileId: ele?.fileId,
                            }))
                            if (periodicReminderAnswerType === 'No') {
                                const payload = {
                                    measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                                    nameWhenOther: "",
                                    selected: selected,
                                    details: "",
                                    evidenceDetails: details !== "" ? details : "",
                                    // status: getStatusValue(answerType),
                                    effort: 0,
                                    impact: Number(impactAnswer),
                                    evidence: getEvidenceValue(),
                                    steps: [...stepsList],
                                    task: {
                                        assignedTo: "",
                                        deadline: "",
                                        description: ""
                                    },
                                    evidenceTask: {
                                        assignedTo: "",
                                        deadline: "",
                                        description: ""
                                    },
                                    reminderTasks: [],
                                    files: fileList.length > 0 ? fileList : []
                                }
                                dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                                if (btn === 'save-btn') {
                                    navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                                } else {
                                    setStepsList([]);
                                    setImpactAnswer("");
                                    setEffortAnswer("");
                                    setStepData({ ...stepData });
                                    if (currentIndex + 1 < measures.length) {
                                        setCurrentIndex(currentIndex + 1);
                                        setAnswerType('');
                                        setAnswerSubType('');
                                        setDetails('');
                                        setSelectedFile([]);
                                        setPeriodicReminderAnswerType('');
                                        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                        setProgressDoneDate(new Date());
                                        setEvidenceDoneDate(new Date());
                                        setToDoArea('');
                                    } else {
                                        setAnswerType('');
                                        setAnswerSubType('');
                                        setDetails('');
                                        setSelectedFile([]);
                                        setPeriodicReminderAnswerType('');
                                        setCurrentIndex(-1);
                                    }
                                }
                            } else {
                                const payload = {
                                    measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                                    nameWhenOther: "",
                                    selected: selected,
                                    details: "",
                                    evidenceDetails: details !== "" ? details : "",
                                    // status: getStatusValue(answerType),
                                    effort: 0,
                                    impact: Number(impactAnswer),
                                    evidence: getEvidenceValue(),
                                    steps: [...stepsList],
                                    task: {
                                        assignedTo: "",
                                        deadline: "",
                                        description: ""
                                    },
                                    evidenceTask: {
                                        assignedTo: "",
                                        deadline: "",
                                        description: ""
                                    },
                                    reminderTasks: [],
                                    files: fileList.length > 0 ? fileList : []
                                }
                                dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                                if (btn === 'save-btn') {
                                    navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                                } else {
                                    setStepsList([]);
                                    setImpactAnswer("");
                                    setEffortAnswer("");
                                    setStepData({ ...stepData });
                                    if (currentIndex + 1 < measures.length) {
                                        setCurrentIndex(currentIndex + 1);
                                        setAnswerType('');
                                        setAnswerSubType('');
                                        setSelectedFile([]);
                                        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                        setProgressDoneDate(new Date());
                                        setEvidenceDoneDate(new Date());
                                        setToDoArea('');
                                        setDetails('');
                                    } else {
                                        setAnswerType('');
                                        setAnswerSubType('');
                                        setDetails('');
                                        setSelectedFile([]);
                                        setCurrentIndex(-1);
                                    }
                                }
                            }
                        }
                    }
                } else if (answerType === "NotRelevant") {
                    const payload = {
                        measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                        nameWhenOther: "",
                        selected: selected,
                        details: "",
                        evidenceDetails: "",
                        // status: 0,
                        effort: 0,
                        impact: 0,
                        evidence: 0,
                        steps: [...stepsList],
                        task: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        evidenceTask: {
                            assignedTo: "",
                            deadline: "",
                            description: ""
                        },
                        reminderTasks: []
                    }
                    dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                    if (btn === 'save-btn') {
                        navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                    } else {
                        setStepsList([]);
                        setImpactAnswer("");
                        setEffortAnswer("");
                        setStepData({ ...stepData });
                        if (currentIndex + 1 < measures.length) {
                            setCurrentIndex(currentIndex + 1);
                            setAnswerType('');
                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                        } else {
                            setCurrentIndex(-1);
                            setAnswerType('');
                        }
                    }
                } else if (answerType === "Relevant" && (measureStatus === "inProgress" || measureStatus === "todo")) {
                    if (dropDownErr || hasNameErr) {
                        setErrObj({
                            // description: descriptionErr?.message ? { message: intl.formatMessage({ id: descriptionErr?.message, defaultMessage: descriptionErr?.message }) } : undefined,
                            dropDown: dropDownErr?.message ? { message: intl.formatMessage({ id: dropDownErr?.message, defaultMessage: dropDownErr?.message }) } : undefined,
                            hasName: hasNameErr ? { message: intl.formatMessage({ id: "save_your_changes", defaultMessage: "save_your_changes" }) } : undefined,

                        });
                    } else {
                        const payload = {
                            measureListId: currentIndex > -1 ? measures[currentIndex]?.measureListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: "",
                            selected: selected,
                            details: "",
                            evidenceDetails: "",
                            // status: getStatusValue(answerType),
                            effort: Number(effortAnswer),
                            impact: Number(impactAnswer),
                            evidence: 0,
                            steps: [...stepsList],
                            task: {
                                assignedTo: userData?.userId,
                                deadline: (formatedDate !== '' && decodedUserInfo?.payload?.planningMethod !== 2) ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                                description: ""
                            },
                            evidenceTask: {
                                assignedTo: "",
                                deadline: "",
                                description: ""
                            },
                            reminderTasks: []
                        }
                        dispatch(measureGroupsContainerCreators.requestPostMeasures(id, payload, mgId, "", (addMeasureGroupObj || editMeasureGroupObj)));
                        if (btn === 'save-btn') {
                            navigate(`/risk-area/${id}?isCancel=0`, { state: { mgFromCancel: (addMeasureGroupObj || editMeasureGroupObj) } });
                        } else {
                            setStepsList([]);
                            setImpactAnswer("");
                            setEffortAnswer("");
                            setStepData({ ...stepData });
                            if (currentIndex + 1 < measures.length) {
                                setCurrentIndex(currentIndex + 1);
                                setAnswerType('');
                                setImpactAnswer("");
                                setEffortAnswer("");
                                setStepsList([]);
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                setProgressDoneDate(new Date());
                                setToDoArea('');
                            } else {
                                setAnswerType('');
                                setCurrentIndex(-1);
                            }
                        }
                        setErrObj({ description: undefined, dropDown: undefined, hasName: undefined });
                    }
                } else {
                    if (answerType === "No") {
                        navigate(`/risk-area/${id}`);
                    }
                }

                const nameErr = schema.measureNameWhenOther.validate(nameWhenOther).error;
                if (answerType === "Yes") {
                    if (nameErr) {
                        setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
                    } else {
                        handleAnswerYes();
                    }
                }
            }
        }
        setLearnMoreInfo(false);
        //if (from !== "task" && !editMeasureObj && answerType !== "No") {
        //    setTimeout(() => {
        //        dispatch(measureGroupsContainerCreators.requestGetMeasures(singleRiskCategory.riskCategoryId, addMeasureGroupObj?.measuregroupId, addMeasureGroupObj, '', '', decodedUserInfo, singleRiskCategory));
        //    }, 1000)
        //}
    }
    /* istanbul ignore next */
    function isDisableBtn() {
        if (singleMeasure?.measureListId !== '00000000-0000-0000-0000-000000000000') {
            if (answerType === '') {
                return true;
            } else if (answerType !== '') {
                return false;
            }
        } else if (singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && lastQuestion) {
            if (answerType === '' || measureStatus === '' || impactAnswer === "" || effortAnswer === "") {
                return true;
            } else if (answerType !== '') {
                return false;
            }
        } else if (singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion) {
            if (measureStatus === '' || impactAnswer === "" || effortAnswer === "") {
                return true;
            } else {
                return false;
            }
        }

    }

    function isDisableNextBtn() {
        if (singleMeasure?.measureListId !== '00000000-0000-0000-0000-000000000000') {
            if (answerType === '') {
                return true;
            } else if (answerType !== '') {
                if (answerType == 'Relevant') {
                    if (measureStatus === '') {
                        return true;
                    } else {
                        if (measureStatus === "done") {
                            if (impactAnswer === "" || impactAnswer === "0") {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            if (impactAnswer === "" || impactAnswer === "0" || effortAnswer === "" || effortAnswer === "0") {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }

                } else {
                    return false;
                }
            }
        } else if (singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && lastQuestion) {
            if (answerType === '') {
                return true;
            } else if (answerType !== '') {
                if (answerType == 'Yes') {
                    if (measureStatus === '') {
                        return true;
                    } else {
                        if (measureStatus === "done") {
                            if (impactAnswer === "" || impactAnswer === "0") {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            if (impactAnswer === "" || impactAnswer === "0" || effortAnswer === "" || effortAnswer === "0") {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                } else {
                    return false;
                }
            }
        } else if (singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion) {
            if (measureStatus === '') {
                return true;
            } else {
                if (measureStatus === "done") {
                    if (impactAnswer === "" || impactAnswer === "0") {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (impactAnswer === "" || impactAnswer === "0" || effortAnswer === "" || effortAnswer === "0") {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
    }

    return (
        <>
            {/*{(measureGroupsSelector.loading || loading) ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :*/}
                <div>
                    <Container>
                        <div className="flex-grow-1 flex flex-col justify-between">
                            <div>
                                <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0'>
                                    <div className="flex items-center font-semibold text-xl">
                                        {/* <img className="w-[40px] h-[40px]" src={getIcon(intl.formatMessage({ id: `icon_riskarea_${singleRiskCategory?.riskListId}` }))} alt='' /> */}
                                        <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                        <div className="ml-3">
                                            <div className="font-semibold text-xl text-[#535353]">
                                                {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                            </div>
                                            <div className="font-bold text-xs text-[#959595] tracking-[1px] font-family-arial">{editMeasureObj ? (editMeasureGroupObj?.name || editMeasureGroupObj?.nameWhenOther) : (addMeasureGroupObj?.name || addMeasureGroupObj?.nameWhenOther)}</div>
                                        </div>
                                    </div>
                                    {isSaved &&
                                        <div className="font-normal text-xs text-[#959595]">
                                            <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                        </div>
                                    }
                                </div>
                                <div className="border w-full"></div>
                                <div>
                                    <div className='m-auto w-full py-7 align-middle items-center flex justify-between'>
                                        <div className="font-semibold text-[32px] sm:text-2xl">
                                            {editMeasureObj ?
                                                <div className="font-semibold text-[32px] sm:text-2xl">
                                                    <div>
                                                        {editMeasureObj.measureListId === "00000000-0000-0000-0000-000000000000"
                                                            ? intl.formatMessage({ id: "edit_measure", defaultMessage: 'edit_measure' })
                                                            : editMeasureObj?.name}
                                                    </div>
                                                </div>
                                                :
                                                measures[currentIndex]?.measureListId ?
                                                    <div className="font-semibold text-[32px] sm:text-2xl">
                                                        <div>{measures[currentIndex]?.name}</div>
                                                    </div>
                                                    :
                                                    lastQuestion ?
                                                        <div className="font-semibold text-[32px] sm:text-2xl">
                                                            <div>{intl.formatMessage({ id: "can_you_name_another_relevant_measures?", defaultMessage: 'can_you_name_another_relevant_measures?' })}</div>
                                                        </div>
                                                        :
                                                        <div className="font-semibold text-[32px] sm:text-2xl">
                                                            <div>{intl.formatMessage({ id: 'add_measure', defaultMessage: 'add_measure' })}</div>
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {infoDetails && infoDetails.length !== 0 &&
                                            <div className=" w-[540px] leading-4 mb-10 text-[#535353] sm:w-full">
                                                <span className="font-normal text-lg">{textShow}</span>
                                                {textShow.length > 90 &&
                                                    <>
                                                        <span>{"... "}</span>
                                                        <span className={"font-semibold text-[#3BA8FC] text-lg cursor-pointer w-max"} onClick={() => SideDrawerOpen()}>{intl.formatMessage({ id: 'learn_more', defaultMessage: 'learn_more' })}</span>
                                                    </>
                                                }
                                            </div>}

                                        <div className={`w-full max-w-[420px] top-0 right-0  h-full fixed z-[1055] border-r sm:overflow-y-auto sm:w-full sm:max-w-full ${!learnMoreInfo ? 'translate-x-[100%] ease-in-out duration-300' : 'translate-x-[0%] ease-in-out duration-300'}`}>
                                            <SideDrawer drawerInfo={infoDetails} SideDrawerClose={SideDrawerClose} />
                                        </div>
                                    </div>
                                    <div className="flex sm:flex-col">
                                        {editMeasureObj ?
                                            editMeasureObj.measureListId === "00000000-0000-0000-0000-000000000000" ?
                                                (
                                                    <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mb-10 mr-10 p-10 sm:p-5">
                                                        <label className="inline-block text-base font-bold mb-1">
                                                            {intl.formatMessage({ id: "name_of_the_measure", defaultMessage: 'name_of_the_measure' })}
                                                        </label>
                                                        <input
                                                            data-testid={`${testId}-name-of-measure-input`}
                                                            disabled={!((role === "administrator") || (role === "editor"))}
                                                            type="text"
                                                            maxLength={100}
                                                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                                                            value={nameWhenOther}
                                                            onChange={(e) => setnameWhenOther(e.target.value)}
                                                        />
                                                        {errObj.name?.message ?
                                                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                                <span>{errObj.name.message}</span>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                )
                                                :
                                                <div className="mr-6 sm:w-full sm:mr-0">
                                                    <div data-testid={`${testId}-relevent-done`} onClick={() => handleAnswerType('Relevant')} className={`w-[416px] h-[56px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg sm:w-full ${answerType === 'Relevant' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'relevant', defaultMessage: 'relevant' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-not-relevent`} onClick={() => handleAnswerType('NotRelevant')} className={`w-[416px] h-[56px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg sm:w-full ${answerType === 'NotRelevant' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'not_relevant', defaultMessage: 'not_relevant' })}</div>
                                                    </div>
                                                </div>
                                            :
                                            <>
                                                {measures[currentIndex]?.measureListId ?
                                                    <div className="mr-6 sm:w-full sm:mr-0">
                                                        <div data-testid={`${testId}-current-index-relevent-done`} onClick={() => handleAnswerType('Relevant')} className={`w-[416px] h-[56px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg sm:w-full ${answerType === 'Relevant' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'relevant', defaultMessage: 'relevant' })}</div>
                                                        </div>
                                                        <div data-testid={`${testId}-current-index-not-relevent`} onClick={() => handleAnswerType('NotRelevant')} className={`w-[416px] h-[56px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg sm:w-full ${answerType === 'NotRelevant' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'not_relevant', defaultMessage: 'not_relevant' })}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    lastQuestion ?
                                                        <div className="mr-6 sm:w-full sm:mr-0">
                                                            <div onClick={() => handleAnswerType('No')} className={`w-[416px] h-[56px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg sm:w-full ${answerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                            </div>
                                                            <div onClick={() => handleAnswerType('Yes')} className={`w-[416px] h-[56px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg sm:w-full ${answerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mb-10 mr-10 p-10 sm:p-5">
                                                            <label className="inline-block text-base font-bold mb-1">
                                                                {intl.formatMessage({ id: "name_of_the_measure", defaultMessage: 'name_of_the_measure' })}
                                                            </label>
                                                            <input
                                                                data-testid={`${testId}-name-of-the-measure`}
                                                                disabled={!((role === "administrator") || (role === "editor"))}
                                                                type="text"
                                                                maxLength={100}
                                                                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                                                                value={nameWhenOther}
                                                                onChange={(e) => setnameWhenOther(e.target.value)}
                                                            />
                                                            {errObj.name?.message ?
                                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                                    <span>{errObj.name.message}</span>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                }
                                            </>
                                        }


                                        {singleMeasure?.measureListId !== '00000000-0000-0000-0000-000000000000' && answerType === 'Relevant' &&
                                            <MeasureExpandSection answerType={answerType} setAnswerType={setAnswerType} nameWhenOther={nameWhenOther} setnameWhenOther={setnameWhenOther} answerSubType={answerSubType} setAnswerSubType={setAnswerSubType} periodicReminderAnswerType={periodicReminderAnswerType} setPeriodicReminderAnswerType={setPeriodicReminderAnswerType} profileArr={profileArr} monthlyReminder={monthlyReminder} setMonthlyReminder={setMonthlyReminder} isToDoDropDown={isToDoDropDown} setIsToDoDropDown={setIsToDoDropDown} isReminderDropDown={isReminderDropDown} setIsReminderDropDown={setIsReminderDropDown}
                                                evidenceDoneDate={evidenceDoneDate} setEvidenceDoneDate={setEvidenceDoneDate} evidenceFormatedDate={evidenceFormatedDate} setEvidenceFormatedDate={setEvidenceFormatedDate} userData={userData} setUserData={setUserData} evidenceLaterDropdownList={evidenceLaterDropdownList} setEvidenceLaterDropdownList={setEvidenceLaterDropdownList} reminderOption={reminderOption} reminderFrequencyList={reminderFrequencyList} setReminderFrequencyList={setReminderFrequencyList}
                                                reminderData={reminderData} setReminderData={setReminderData} evidenceNowDropdownList={evidenceNowDropdownList} setEvidenceNowDropdownList={setEvidenceNowDropdownList} reminderTasksList={reminderTasksList} setReminderTasksList={setReminderTasksList} isEditReminder={isEditReminder} setIsEditReminder={setIsEditReminder} users={users} errObj={errObj} setErrObj={setErrObj} setFirstName={setFirstName} details={details} setDetails={setDetails} userDropdownList={userDropdownList} handleInput={handleInput} progressDoneDate={progressDoneDate} handleProgressDoneDate={handleProgressDoneDate} toDoArea={toDoArea} setToDoArea={setToDoArea} selectedFile={selectedFile} setSelectedFile={setSelectedFile} singleMeasure={singleMeasure} lastQuestion={false}
                                                impactAnswer={impactAnswer} setImpactAnswer={setImpactAnswer} effortAnswer={effortAnswer} setEffortAnswer={setEffortAnswer} ImpactOptionList={impactOptions} stepData={stepData} setStepData={setStepData} stepsList={stepsList} setStepsList={setStepsList} measureStatus={measureStatus} setMeasureStatus={setMeasureStatus} editMeasureObj={editMeasureObj} showInput={showInput} setShowInput={setShowInput} editInput={editInput} setEditInput={setEditInput} editIndex={editIndex} setEditIndex={setEditIndex} />
                                        }

                                        {(singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && lastQuestion && answerType === 'Yes') &&
                                            <MeasureExpandSection answerType={answerType} setAnswerType={setAnswerType} nameWhenOther={nameWhenOther} setnameWhenOther={setnameWhenOther} answerSubType={answerSubType} setAnswerSubType={setAnswerSubType} periodicReminderAnswerType={periodicReminderAnswerType} setPeriodicReminderAnswerType={setPeriodicReminderAnswerType} profileArr={profileArr} monthlyReminder={monthlyReminder} setMonthlyReminder={setMonthlyReminder} isToDoDropDown={isToDoDropDown} setIsToDoDropDown={setIsToDoDropDown} isReminderDropDown={isReminderDropDown} setIsReminderDropDown={setIsReminderDropDown}
                                                evidenceDoneDate={evidenceDoneDate} setEvidenceDoneDate={setEvidenceDoneDate} evidenceFormatedDate={evidenceFormatedDate} setEvidenceFormatedDate={setEvidenceFormatedDate} userData={userData} setUserData={setUserData} evidenceLaterDropdownList={evidenceLaterDropdownList} setEvidenceLaterDropdownList={setEvidenceLaterDropdownList} reminderOption={reminderOption} reminderFrequencyList={reminderFrequencyList} setReminderFrequencyList={setReminderFrequencyList}
                                                reminderData={reminderData} setReminderData={setReminderData} evidenceNowDropdownList={evidenceNowDropdownList} setEvidenceNowDropdownList={setEvidenceNowDropdownList} reminderTasksList={reminderTasksList} setReminderTasksList={setReminderTasksList} isEditReminder={isEditReminder} setIsEditReminder={setIsEditReminder} users={users} errObj={errObj} setErrObj={setErrObj} setFirstName={setFirstName} details={details} setDetails={setDetails} userDropdownList={userDropdownList} handleInput={handleInput} progressDoneDate={progressDoneDate} handleProgressDoneDate={handleProgressDoneDate} toDoArea={toDoArea} setToDoArea={setToDoArea} selectedFile={selectedFile} setSelectedFile={setSelectedFile} singleMeasure={singleMeasure} lastQuestion={true}
                                                impactAnswer={impactAnswer} setImpactAnswer={setImpactAnswer} effortAnswer={effortAnswer} setEffortAnswer={setEffortAnswer} ImpactOptionList={impactOptions} stepData={stepData} setStepData={setStepData} stepsList={stepsList} setStepsList={setStepsList} measureStatus={measureStatus} setMeasureStatus={setMeasureStatus} editMeasureObj={editMeasureObj} showInput={showInput} setShowInput={setShowInput} editInput={editInput} setEditInput={setEditInput} editIndex={editIndex} setEditIndex={setEditIndex} />
                                        }

                                        {singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion && answerType === '' &&
                                            <MeasureExpandSection answerType={answerType} setAnswerType={setAnswerType} nameWhenOther={nameWhenOther} setnameWhenOther={setnameWhenOther} answerSubType={answerSubType} setAnswerSubType={setAnswerSubType} periodicReminderAnswerType={periodicReminderAnswerType} setPeriodicReminderAnswerType={setPeriodicReminderAnswerType} profileArr={profileArr} monthlyReminder={monthlyReminder} setMonthlyReminder={setMonthlyReminder} isToDoDropDown={isToDoDropDown} setIsToDoDropDown={setIsToDoDropDown} isReminderDropDown={isReminderDropDown} setIsReminderDropDown={setIsReminderDropDown}
                                                evidenceDoneDate={evidenceDoneDate} setEvidenceDoneDate={setEvidenceDoneDate} evidenceFormatedDate={evidenceFormatedDate} setEvidenceFormatedDate={setEvidenceFormatedDate} userData={userData} setUserData={setUserData} evidenceLaterDropdownList={evidenceLaterDropdownList} setEvidenceLaterDropdownList={setEvidenceLaterDropdownList} reminderOption={reminderOption} reminderFrequencyList={reminderFrequencyList} setReminderFrequencyList={setReminderFrequencyList}
                                                reminderData={reminderData} setReminderData={setReminderData} evidenceNowDropdownList={evidenceNowDropdownList} setEvidenceNowDropdownList={setEvidenceNowDropdownList} reminderTasksList={reminderTasksList} setReminderTasksList={setReminderTasksList} isEditReminder={isEditReminder} setIsEditReminder={setIsEditReminder} users={users} errObj={errObj} setErrObj={setErrObj} setFirstName={setFirstName} details={details} setDetails={setDetails} userDropdownList={userDropdownList} handleInput={handleInput} progressDoneDate={progressDoneDate} handleProgressDoneDate={handleProgressDoneDate} toDoArea={toDoArea} setToDoArea={setToDoArea} selectedFile={selectedFile} setSelectedFile={setSelectedFile} singleMeasure={singleMeasure} lastQuestion={false}
                                                impactAnswer={impactAnswer} setImpactAnswer={setImpactAnswer} effortAnswer={effortAnswer} setEffortAnswer={setEffortAnswer} ImpactOptionList={impactOptions} stepData={stepData} setStepData={setStepData} stepsList={stepsList} setStepsList={setStepsList} measureStatus={measureStatus} setMeasureStatus={setMeasureStatus} editMeasureObj={editMeasureObj} showInput={showInput} setShowInput={setShowInput} editInput={editInput} setEditInput={setEditInput} editIndex={editIndex} setEditIndex={setEditIndex} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {(measures[currentIndex]?.measureListId || lastQuestion) &&
                                <div className='w-full bottom-0 align-middle items-center flex justify-between'>
                                    <div>
                                        <StyledButton
                                        testId={`${testId}-previous-btn`}
                                            onClick={() => handlePreviousClick()}
                                            disabled={(currentIndex === 0 || editMeasureObj) ? true : false}
                                            buttonClass={'sm:px-2'}
                                            flexReverse={'flex-row-reverse'}
                                            width={'w-48 sm:w-max'}
                                            align={'justify-start'}
                                            text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                            color={'text-[#535353]'}
                                            iconType={'arrowleft'}
                                            backgroundColor={'bg-[#EDEDED]'}
                                        />
                                    </div>
                                    {((role === "administrator") || (role === "editor")) ?
                                        <div>
                                            <StyledButton testId={`${testId}-next-btn-measure-rc`} onClick={() => handleNextClick('next-btn')} buttonClass={'sm:px-2 PrimoryBtn'} disabled={isDisableNextBtn()} width={'w-48 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'next', defaultMessage: 'next' })} color={isDisableNextBtn() ? 'text-[#535353]' : 'text-white'} iconType={isDisableNextBtn() ? 'blackarrow' : 'arrow'} backgroundColor={isDisableNextBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            }
                            <div className="flex mt-4">
                                {(editMeasureObj || (singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion)) ?
                                    <StyledButton
                                        testId={`${testId}-cancel-btn-measure-rc`}
                                        onClick={() => handleCancelClick()}
                                        buttonClass={'sm:px-2'}
                                        flexReverse={'flex-row-reverse'}
                                        width={'w-48 sm:w-max'} align={'justify-start'}
                                        text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}
                                        color={'text-[#535353]'}
                                        backgroundColor={'bg-[#EDEDED]'}
                                    />
                                    :
                                    <div className="w-48 h-[52px] flex items-center justify-center">
                                        <button data-testid={`${testId}-cancel-btn`} onClick={() => handleCancelClick()} className="flex items-center justify-center">
                                            <span className="text-[#535353] font-family-arial font-semibold">{intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}</span>
                                        </button>
                                    </div>

                                }
                                {(editMeasureObj || (singleMeasure?.measureListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion)) && <StyledButton testId={`${testId}-save-btn`} onClick={() => handleNextClick('save-btn')} buttonClass={'sm:px-2 PrimoryBtn'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })} disabled={isDisableNextBtn()} color={isDisableNextBtn() ? 'text-[#535353]' : 'text-white'} backgroundColor={isDisableNextBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />}
                            </div>
                        </div>
                    </Container>
                </div>
            {/*}*/}
            <Modal show={isWromgOrgModal} onClose={() => ""}>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                </div>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-goto-dashboard`}
                        onClick={() => handleWrongOrgModel()}
                        buttonClass={'PrimoryBtn'}
                        width={"w-[220px] sm:w-40"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                        color={"text-white mr-3 text-base"}
                        iconType={'home'}
                        backgroundColor={"bg-black"}
                    />
                </div>
            </Modal>
        </>
    );
}