import axios, {
    AxiosError,
    AxiosPromise,
    AxiosResponse
} from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { appContainerCreators } from '../../AppReducer';
import { USER_COOKIE } from '../../enum';
import { userInfoCall } from '../../services/AppService';
import { getPlansService, subscriptionService, postSubscriptionService, invoicesService, getDiscountCodeService, cancelMyRenewalService, getRoleLimitsService, invoicesPdfService, cancelMySubscriptionService } from '../../services/SubscriptionService';
import { getCmsURL } from '../../utils';
import cookies from '../../utils/coockies';
import { subscriptionContainerTypes, subscriptionContainerCreators, invoicesContainerTypes, invoicesContainerCreators } from './subscriptionReducer';
import { clearListOptionContainerCreators } from '../OrganisationContainer/reducer';
/* istanbul ignore next */
export const plansHelpTextService = (contentType: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[Type][$eq]=${contentType}&locale=${decodedUserInfo?.payload?.prefLang}`)

type PostSubscriptionData = {
    data: {
        approval: boolean;
        discountCode: string;
        planId: string;
        upgradedFrom: string;
    },
    navigate: (path: string, state?: object) => void
}

type CancelMyRenewal = {
    subscriptionId: string;
    setShowDeleteBtnToast: React.Dispatch<React.SetStateAction<boolean>>;
}

type CancelMySubscription = {
    subscriptionId: string;
    setShowDeleteSubscriptionToast: React.Dispatch<React.SetStateAction<boolean>>;
}

type GetRoleLimits = {
    organizationId: string;
}

type GetInvoicePdf = {
    invoiceId: number;
}

type GetPlansHelpTextData = {
    contentType: string;
    decodedUserInfo: DecodedUserInfo | null;
}

export const {
    REQUEST_GET_SUBSCRIPTION_PLANS,
    REQUEST_GET_SUBSCRIPTION,
    REQUEST_POST_SUBSCRIPTION,
    REQUEST_GET_DISCOUNT_CODE,
    REQUEST_DELETE_MY_RENEWAL,
    REQUEST_GET_ROLE_LIMITS,
    REQUEST_GET_PLANS_HELP_TEXT,
    REQUEST_DELETE_MY_SUBSCRIPTION,
} = subscriptionContainerTypes;

const {
    REQUEST_GET_INVOICES,
    REQUEST_GET_INVOICES_PDF
} = invoicesContainerTypes;

/* istanbul ignore next */
export function* getPlansDataCall() {
    try {
        const response: AxiosResponse = yield call(getPlansService);
        yield put(subscriptionContainerCreators.successGetSubscriptionPlans(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(subscriptionContainerCreators.failureGetSubscriptionPlans(e.response?.data.errors[0].message));
        } else {
            yield put(subscriptionContainerCreators.failureGetSubscriptionPlans("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getSubscriptionData() {
    try {
        const response: AxiosResponse = yield call(subscriptionService);
        yield put(clearListOptionContainerCreators.clearListOptionStatus())
        yield put(subscriptionContainerCreators.successGetSubscription(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(subscriptionContainerCreators.failureGetSubscription(e.response?.data.errors[0].message));
        } else {
            yield put(subscriptionContainerCreators.failureGetSubscription("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* postSubscriptionData(data: PostSubscriptionData) {
    try {
        const response: AxiosResponse = yield call(postSubscriptionService, data.data);
        if (response && response.data) {
            if (response.data.redir) {
                window.open(response.data.redir, '_self');
            } else {
                const refreshToken = cookies.get(USER_COOKIE);
                const res: AxiosResponse = yield call(userInfoCall, { refreshToken });
                yield put(appContainerCreators.updateUserInfo(res))
                data.navigate("/dashboard");
            }
        }
        yield put(subscriptionContainerCreators.successPostSubscription(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(subscriptionContainerCreators.failurePostSubscription(e.response?.data.errors[0].message));
        } else {
            yield put(subscriptionContainerCreators.failurePostSubscription("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getInvoicesData() {
    try {
        const response: AxiosResponse = yield call(invoicesService);
        yield put(invoicesContainerCreators.successGetInvoices(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(invoicesContainerCreators.failureGetInvoices(e.response?.data.errors[0].message));
        } else {
            yield put(invoicesContainerCreators.failureGetInvoices("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getDiscountCodeData({ discountCode, planId }: any) {
    try {
        const response: AxiosResponse = yield call(getDiscountCodeService, discountCode, planId);
        yield put(subscriptionContainerCreators.successGetDiscountCode(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(subscriptionContainerCreators.failureGetDiscountCode(e.response?.data.errors[0].message));
        } else {
            yield put(subscriptionContainerCreators.failureGetDiscountCode("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* cancelMyRenewal(data: CancelMyRenewal) {
    try {
        const response: AxiosResponse = yield call(cancelMyRenewalService, data.subscriptionId);
        yield put(subscriptionContainerCreators.successDeleteMyRenewal(response.data, response.config.method))
        if (response) {
            data.setShowDeleteBtnToast(true);
            setTimeout(() => {
                data.setShowDeleteBtnToast(false);
            }, 2000)
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e) {
                data.setShowDeleteBtnToast(true);
                setTimeout(() => {
                    data.setShowDeleteBtnToast(false);
                }, 2000)
            }
            yield put(subscriptionContainerCreators.failureDeleteMyRenewal(e.response?.data.errors[0].message));
        } else {
            yield put(subscriptionContainerCreators.failureDeleteMyRenewal("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getRoleLimits(data: GetRoleLimits) {
    try {
        const response: AxiosResponse = yield call(getRoleLimitsService, data.organizationId);
        yield put(subscriptionContainerCreators.successGetRoleLimits(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(subscriptionContainerCreators.failureGetRoleLimits(e.response?.data.errors[0].message));
        } else {
            yield put(subscriptionContainerCreators.failureGetRoleLimits("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getInvoicesPdf(data: GetInvoicePdf) {
    try {
        const response: AxiosResponse = yield call(invoicesPdfService, data.invoiceId);
        yield put(invoicesContainerCreators.successGetInvoicesPdf(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(invoicesContainerCreators.failureGetInvoicesPdf(e.response?.data.errors[0].message));
        } else {
            yield put(invoicesContainerCreators.failureGetInvoicesPdf("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getPlansHelpTextData(data: GetPlansHelpTextData) {
    try {
        const response: AxiosResponse = yield call(plansHelpTextService, data.contentType, data.decodedUserInfo);
        yield put(subscriptionContainerCreators.successGetPlansHelpText(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(subscriptionContainerCreators.failureGetPlansHelpText(e.response?.data.errors[0].message));
        } else {
            yield put(subscriptionContainerCreators.failureGetPlansHelpText("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* cancelMySubscription(data: CancelMySubscription) {
    try {
        const response: AxiosResponse = yield call(cancelMySubscriptionService, data.subscriptionId);
        if (response) {
            data.setShowDeleteSubscriptionToast(true);
            setTimeout(() => {
                data.setShowDeleteSubscriptionToast(false);
            }, 5000)
            const refreshToken = cookies.get(USER_COOKIE);
            const res: AxiosResponse = yield call(userInfoCall, { refreshToken });
            yield put(appContainerCreators.updateUserInfo(res))
        }
        yield put(subscriptionContainerCreators.successDeleteMySubscription(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e) {
                data.setShowDeleteSubscriptionToast(true);
                setTimeout(() => {
                    data.setShowDeleteSubscriptionToast(false);
                }, 2000)
            }
            yield put(subscriptionContainerCreators.failureDeleteMySubscription(e.response?.data.errors[0].message));
        } else {
            yield put(subscriptionContainerCreators.failureDeleteMySubscription("Something went wrong, please try again later!"));
        }
    }
}



/* istanbul ignore next */
export default function* subscriptionContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_GET_SUBSCRIPTION_PLANS, getPlansDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_SUBSCRIPTION, getSubscriptionData);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_SUBSCRIPTION, postSubscriptionData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_INVOICES, getInvoicesData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_DISCOUNT_CODE, getDiscountCodeData);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_MY_RENEWAL, cancelMyRenewal);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ROLE_LIMITS, getRoleLimits);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_INVOICES_PDF, getInvoicesPdf);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_PLANS_HELP_TEXT, getPlansHelpTextData);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_MY_SUBSCRIPTION, cancelMySubscription);
}
