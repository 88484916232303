import React from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "../../assets/icons/edit-icon.svg";
import InfoIcon from "../../assets/icons/info-i-icon.svg";
import Container from "../../components/Container/Container";
import StyledButton from "../../components/StyledButton";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { listOptionsContainerCreators } from "../OrganisationContainer/reducer";
import LoadingIcon from "../../assets/icons/loading-icon";
import Modal from "../../components/Modal/Modal";
import { biaContainerCreators } from "./biaReducer";
import { ContentType } from "@onesixeight-nl/classes";
import { selectDecodedUserInfo } from "../../AppSelector";
import videojs from 'video.js';
import VideoJS from "../../components/VideoJS/video";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";

const testId = `bia-container`;

type BiaOverview = {
    processId: string;
    processListId: string;
    organizationId: string;
    nameWhenOther: string;
    name: string;
    responsible: string;
    BIA0h: number;
    BIA4h: number;
    BIA24h: number;
    BIA72h: number;
    BIA168h: number;
    BIA720h: number;
    created: Date;
    modified: Date;
}

type BiaPopupVideoAttributes = {
    name: string;
    alternativeText: string;
    caption: string;
    width: null;
    height: null;
    formats: null;
    hash: string;
    ext: string;
    mime: string;
    size: string;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: null;
    createdAt: Date;
    updatedAt: Date;
}

type BiaPopupVideo = {
    data: {
        id: number;
        attributes: BiaPopupVideoAttributes;
    }
}

type BiaPopupDetails = {
    Intro: string;
    Header: string;
    Body: string;
    Button: string;
    Button2: string;
    Link: string;
    Link2: string;
    Video: BiaPopupVideo;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
}

export default function BiaContainer() {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const { search, state } = useLocation()
    const isCancel = new URLSearchParams(search).get('isCancel');
    const { BIAStatus }: RootState = state || {};

    const biaSelector = useSelector((state: RootState) => state.listOptions);
    const biaContentSelector = useSelector((state: RootState) => state.biaContainer);

    const [biaOverview, setBiaOverview] = useState<BiaOverview[]>();
    const [isBIAModal, setIsBIAModal] = useState<boolean>(false);
    const [biaPopupDetails, setBiaPopupDetails] = useState<BiaPopupDetails>();
    const playerRef = React.useRef(null);
    const isHttpsLink = biaPopupDetails?.Video?.data?.attributes?.url.startsWith('https://');
    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: isHttpsLink ? biaPopupDetails?.Video?.data?.attributes?.url : "https://" + biaPopupDetails?.Video?.data?.attributes?.url,
            type: 'video/mp4'
        }]
    };

    useEffect(() => {
        if ((role === "administrator") || (role === "editor")) {
            if (decodedUserInfo && BIAStatus) {
                dispatch(biaContainerCreators.requestGetProdBiaContent(ContentType.BIA, BIAStatus, decodedUserInfo));
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedUserInfo, BIAStatus]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (biaContentSelector && biaContentSelector.biaContent) {
            if (biaContentSelector.biaContent.data && biaContentSelector.biaContent.data[0] && isCancel !== '0') {
                setBiaPopupDetails(biaContentSelector?.biaContent?.data[0].attributes);
                setIsBIAModal(true);
            } else {
                dispatch(listOptionsContainerCreators.requestGetProcesses(navigate, isCancel));
            }
        } else {
            dispatch(listOptionsContainerCreators.requestGetProcesses());
        }
    }, [biaContentSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (biaSelector && biaSelector.processes && biaSelector.processes.length) {
            setBiaOverview(biaSelector.processes);
        }
    }, [biaSelector])

    function gotoDashboard() {
        navigate("/dashboard?isCancel=0");
    }

    function gotoEditBia(processId: string, singleBiaObj: object) {
        navigate(`/bia/${processId}/edit`, { state: { singleBiaObj: singleBiaObj } });
    }

    function getBiaStatus(status: number) {
        switch (status) {
            case 0:
                return intl.formatMessage({ id: 'undefined', defaultMessage: 'undefined' })
            case 1:
                return intl.formatMessage({ id: 'none', defaultMessage: 'none' })
            case 2:
                return intl.formatMessage({ id: 'low', defaultMessage: 'low' })
            case 3:
                return intl.formatMessage({ id: 'bia_medium', defaultMessage: 'bia_medium' })
            case 4:
                return intl.formatMessage({ id: 'high', defaultMessage: 'high' })
            case 5:
                return intl.formatMessage({ id: 'critical', defaultMessage: 'critical' })
            default:
                return ''
        }
    }

    function getBiaStatusColor(status: number) {
        switch (status) {
            case 0:
                return 'rgba(255, 255, 255, 1)'
            case 1:
                return 'rgba(240, 250, 234, 0.5)'
            case 2:
                return 'rgba(240, 250, 234, 1)'
            case 3:
                return 'rgba(255, 244, 235, 0.5)'
            case 4:
                return 'rgba(255, 235, 235, 0.5)'
            case 5:
                return 'rgba(255, 235, 235, 1)'
            default:
                return 'rgba(255, 255, 255, 1)'
        }
    }

    function handleBIAModalClose() {
        setIsBIAModal(false);
        dispatch(listOptionsContainerCreators.requestGetProcesses(navigate, isCancel));
    }

    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });
        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    return (
        <>
            {(biaSelector.loading || biaContentSelector.loading) ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div className='flex flex-col my-10'>
                    <Container>
                        <div className='font-semibold text-5xl mb-4 sm:text-[32px]'>
                            {intl.formatMessage({ id: 'impact_overview', defaultMessage: 'impact_overview' })}
                        </div>
                        <div className='mb-10 font-normal text-xl text-[#535353] sm:w-full sm:mb-8 sm:text-sm sm:flex'>
                            {intl.formatMessage({ id: 'the_overview_shows_you_what_the_impact_is_of_disrupting.', defaultMessage: 'the_overview_shows_you_what_the_impact_is_of_disrupting.' })}
                        </div>
                        <div className="sm:overflow-x-scroll sm:w-full sm:pb-10">
                            <div className=' items-center px-[118px] inline-flex'>
                                <div className="w-[110px] min-h-[25px] flex items-center justify-center text-[12px] font-[400] text-[#959595]">{intl.formatMessage({ id: 'direct', defaultMessage: 'direct' })}</div>
                                <div className="w-[105px] min-h-[25px] flex items-center justify-center text-[12px] font-[400] text-[#959595]">{intl.formatMessage({ id: '4_hours', defaultMessage: '4_hours' })}</div>
                                <div className="w-[105px] min-h-[25px] flex items-center justify-center text-[12px] font-[400] text-[#959595]">{intl.formatMessage({ id: '24_hours', defaultMessage: '24_hours' })}</div>
                                <div className="w-[105px] min-h-[25px] flex items-center justify-center text-[12px] font-[400] text-[#959595]">{intl.formatMessage({ id: '48_hours', defaultMessage: '48_hours' })}</div>
                                <div className="w-[99px] min-h-[25px] flex items-center justify-center text-[12px] font-[400] text-[#959595]">{intl.formatMessage({ id: '1_week', defaultMessage: '1_week' })}</div>
                                <div className="w-[108px] min-h-[25px] flex items-center justify-center text-[12px] font-[400] text-[#959595]">{intl.formatMessage({ id: '1_month', defaultMessage: '1_month' })}</div>
                            </div>
                            <div>
                                {biaOverview?.map((item, index) => (
                                    <div data-testid={`${testId}-bia-overview-${index}`} key={`${testId}-bia-overview-${item.processId}`} className="border-2 h-22 w-[850px] flex rounded-lg mt-[10px]">
                                        <div className="border-r-2">
                                            <div className="font-bold tracking-[1px] h-[80px] w-[116px] rounded-lg justify-center bg-white flex items-center text-[10px] text-[#959595] text-center ">
                                                <div className="w-[92px]">{item?.name || item?.nameWhenOther}</div>
                                            </div>
                                        </div>

                                        <div style={{ backgroundColor: getBiaStatusColor(item?.BIA0h) }} className={`border-r-2 h-[80px] w-[116px] justify-center flex items-center font-bold text-xs text-[#959595]`}>
                                            <div className={`${item?.BIA0h !== null ? "app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-semibold bg-white border-color-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center" : ""}`}>
                                                {getBiaStatus(item?.BIA0h)}
                                            </div>
                                        </div>

                                        <div style={{ backgroundColor: item?.BIA0h !== null ? getBiaStatusColor(item?.BIA4h) : getBiaStatusColor(item?.BIA0h) }} className={`border-r-2 h-[80px] w-[116px] justify-center flex items-center font-bold text-xs text-[#959595]`}>
                                            <div className={`${item?.BIA0h !== null ? "app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-semibold bg-white border-color-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center" : ""}`}>
                                                {getBiaStatus(item?.BIA0h) && getBiaStatus(item?.BIA4h)}
                                            </div>
                                        </div>

                                        <div style={{ backgroundColor: item?.BIA0h !== null ? getBiaStatusColor(item?.BIA24h) : getBiaStatusColor(item?.BIA0h) }} className={`border-r-2 h-[80px] w-[116px] justify-center flex items-center font-bold text-xs text-[#959595]`}>
                                            <div className={`${item?.BIA0h !== null ? "app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-semibold bg-white border-color-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center" : ""}`}>
                                                {getBiaStatus(item?.BIA0h) && getBiaStatus(item?.BIA24h)}
                                            </div>
                                        </div>

                                        <div style={{ backgroundColor: item?.BIA0h !== null ? getBiaStatusColor(item?.BIA72h) : getBiaStatusColor(item?.BIA0h) }} className={`border-r-2 h-[80px] w-[116px] justify-center flex items-center font-bold text-xs text-[#959595]`}>
                                            <div className={`${item?.BIA0h !== null ? "app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-semibold bg-white border-color-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center" : ""}`}>
                                                {getBiaStatus(item?.BIA0h) && getBiaStatus(item?.BIA72h)}
                                            </div>
                                        </div>

                                        <div style={{ backgroundColor: item?.BIA0h !== null ? getBiaStatusColor(item?.BIA168h) : getBiaStatusColor(item?.BIA0h) }} className={`border-r-2 h-[80px] w-[116px] justify-center flex items-center font-bold text-xs text-[#959595]`}>
                                            <div className={`${item?.BIA0h !== null ? "app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-semibold bg-white border-color-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center" : ""}`}>
                                                {getBiaStatus(item?.BIA0h) && getBiaStatus(item?.BIA168h)}
                                            </div>
                                        </div>

                                        <div style={{ backgroundColor: item?.BIA0h !== null ? getBiaStatusColor(item?.BIA720h) : getBiaStatusColor(item?.BIA0h) }} className={`border-r-2 h-[80px] w-[116px] justify-center flex items-center font-bold text-xs text-[#959595]`}>
                                            <div className={`${item?.BIA0h !== null ? "app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-semibold bg-white border-color-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center" : ""}`}>
                                                {getBiaStatus(item?.BIA0h) && getBiaStatus(item?.BIA720h)}
                                            </div>
                                        </div>

                                        <div data-testid={`${testId}-goto-edit-bia`} onClick={() => gotoEditBia(item?.processId, item)} className=" h-[80px] w-[116px] rounded-lg justify-center bg-white flex items-center font-bold text-xs text-[#959595]">
                                            {((role === "administrator") || (role === "editor")) ?
                                                <img data-testid={`${testId}-edit-icon-bia`} className="w-[12px] cursor-pointer h-[12px]" src={EditIcon} alt='' />
                                                :
                                                <img data-testid={`${testId}-info-icon-bia`} className="w-[12px] cursor-pointer h-[12px]" src={InfoIcon} alt='' />
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <div>
                                <StyledButton
                                    testId={`${testId}-goto-dashboard-bia`}
                                    onClick={() => gotoDashboard()}
                                    customClass={'mt-[112px]'}
                                    buttonClass={'PrimoryBtn'}
                                    width={"w-[220px] sm:w-40"}
                                    align={"justify-end"}
                                    text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                                    color={"text-white mr-3 text-base"}
                                    iconType={'home'}
                                    backgroundColor={"bg-black"}
                                />
                            </div>
                        </div>
                    </Container>
                </div>
            }
            <Modal show={isBIAModal} onClose={() => handleBIAModalClose()}>
                <div className={biaPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div onClick={() => handleBIAModalClose()} className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer">
                        {biaPopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {biaPopupDetails?.Header}
                    </div>
                    {biaPopupDetails?.Video?.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(biaPopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {biaPopupDetails?.Button2 && <StyledButton testId={`${testId}-bia-popup-detail-btn2`} onClick={() => biaPopupDetails?.Link2 ? navigate(biaPopupDetails?.Link2) : handleBIAModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'!w-48'} align={'justify-start'} text={biaPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {biaPopupDetails?.Button && <StyledButton testId={`${testId}-bia-popup-detail-btn`} onClick={() => biaPopupDetails?.Link ? navigate(biaPopupDetails?.Link) : handleBIAModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'!w-48'} align={'justify-end'} text={biaPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>
        </>
    )
}
