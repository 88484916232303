import { AxiosPromise } from 'axios';
import { getCmsURL } from '../utils';
import axios from './axios';

export const getLocationsService = (): AxiosPromise => axios.get(`/bucoscanservice/v1/locations`)

export const addLocationsService = (data: any): AxiosPromise => axios.post(`/bucoscanservice/v1/locations`, data)

export const putLocationsService = (id: string, data: any): AxiosPromise => axios.put(`/bucoscanservice/v1/locations/${id}`, data)

export const deleteLocationsService = (id: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/locations/${id}`)

export const getHardwaresService = (locationId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/locations/${locationId}/hardware`)

export const getConnectivitiesService = (locationId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/locations/${locationId}/connectivity`)

export const getSoftwareService = (processId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/processes/${processId}/software`)

export const postSoftwareService = (processId: string, data: object): AxiosPromise => axios.post(`/bucoscanservice/v1/processes/${processId}/software`, data)

export const putSoftwareService = (processId: string, softwareId: string, data: object): AxiosPromise => axios.put(`/bucoscanservice/v1/processes/${processId}/software/${softwareId}`, data)

export const deleteSoftwareService = (processId: string, softwareId: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/processes/${processId}/software/${softwareId}`)

export const postHardwaresService = (locationId: string, data: object): AxiosPromise => axios.post(`/bucoscanservice/v1/locations/${locationId}/hardware`, data)

export const postConnectivitiesService = (locationId: string, data: object): AxiosPromise => axios.post(`/bucoscanservice/v1/locations/${locationId}/connectivity`, data)

export const putHardwaresService = (locationId: string, hardwareId: string, data: object): AxiosPromise => axios.put(`/bucoscanservice/v1/locations/${locationId}/hardware/${hardwareId}`, data)

export const deleteHardwaresService = (locationId: string, hardwareId: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/locations/${locationId}/hardware/${hardwareId}`)

export const putConnectivitiesService = (locationId: string, connectivityId: string, data: object): AxiosPromise => axios.put(`/bucoscanservice/v1/locations/${locationId}/connectivity/${connectivityId}`, data)

export const deleteConnectivityService = (locationId: string, connectivityId: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/locations/${locationId}/connectivity/${connectivityId}`)

export const prodLocationContentService = (roles: string, decodedUserInfo: DecodedUserInfo | null, rcStepStatus: string): AxiosPromise => axios.get(`${getCmsURL()}/popups?populate=Video&filters[Type][$eq]=${"ITCYBERLOCATIONS"}&filters[UserRole][${roles}][$eq]=true&filters[Status][$eq]=${rcStepStatus}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const prodHardwareContentService = (roles: string, decodedUserInfo: DecodedUserInfo | null, rcStepStatus: string): AxiosPromise => axios.get(`${getCmsURL()}/popups?populate=Video&filters[Type][$eq]=${"ITCYBERHARDWARE"}&filters[UserRole][${roles}][$eq]=true&filters[Status][$eq]=${rcStepStatus}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const prodConnectivityContentService = (roles: string, decodedUserInfo: DecodedUserInfo | null, rcStepStatus: string): AxiosPromise => axios.get(`${getCmsURL()}/popups?populate=Video&filters[Type][$eq]=${'ITCYBERCONNECTIVITY'}&filters[UserRole][${roles}][$eq]=true&filters[Status][$eq]=${rcStepStatus}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const prodSoftwareContentService = (roles: string, decodedUserInfo: DecodedUserInfo | null, rcStepStatus: string): AxiosPromise => axios.get(`${getCmsURL()}/popups?populate=Video&filters[Type][$eq]=${'ITCYBERSOFTWARE'}&filters[UserRole][${roles}][$eq]=true&filters[Status][$eq]=${rcStepStatus}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const getHardwareByIdService = (locationId: string, hardwareId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/locations/${locationId}/hardware/${hardwareId}`)

export const getConnectivityByIdService = (locationId: string, connectivityId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/locations/${locationId}/connectivity/${connectivityId}`)

export const getSoftwareByIdService = (processId: string, softwareId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/processes/${processId}/software/${softwareId}`)