import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from "../../components/StyledButton";
import schema from "../../validations/schema";
import { measureGroupsContainerCreators } from "../MeasureGroupsContainer/measureGroupsReducer";
import { InfoDetails } from "../BIAContainer/BIAEdit";
import { selectDecodedUserInfo } from "../../AppSelector";
import Container from "../../components/Container/Container";
import GetIcons from "../../components/GetIcons/GetIcons";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import { ListOptionType } from "@onesixeight-nl/classes";
import LoadingIcon from "../../assets/icons/loading-icon";
import { AllRiskCategoryListData } from "../../components/CentralizePopup/CentralizePopup";
import { riskAreaContainerCreators } from "../ManageRiskAreasContainer/reducer";
import { InitialStateType, redirectUrlCreators } from '../LoginContainer/reducer';
import Modal from "../../components/Modal/Modal";

const testId = `add-event`;

type ErrorMessage = {
  message: string
}

type ErrorObject = {
  name?: ErrorMessage;
}

type AllEventsList = {
  eventId: string;
  eventListId: string;
  nameWhenOther: string;
  selected: number;
  created: Date;
  modified: Date;
  name: string;
}

export default function AddEvents() {

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isCancel = new URLSearchParams(location.search).get('isCancel');
  const intl = useIntl();
  const params = useParams();
  const { id, singleEventId } = params;
  const decodedUserInfo = useSelector(selectDecodedUserInfo);
  const role: string | undefined = decodedUserInfo?.payload?.role;
  const measureGroupsSelector = useSelector((state: RootState) => state.measureGroupsContainer);
  const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);

  // states
  const [answerType, setAnswerType] = useState<string>('');
  const [selected, setSelected] = useState<number | null>();
  const [editEventObj, setEditEventObj] = useState<AllEventsList>()
  const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData>();
  const [currentIndex, setCurrentIndex] = useState<number>((measureGroupsSelector?.events && measureGroupsSelector?.events?.length >= 0) && measureGroupsSelector?.events?.findIndex((ele: AllEventsList) => !ele?.eventId));
  const [nameWhenOther, setnameWhenOther] = useState<string>('');
  const [eventId, setEventId] = useState<string>('');
  const [errObj, setErrObj] = useState<ErrorObject>({
    name: undefined
  });
  const [lastQuestion, setLastQuestion] = useState<boolean>(false);
  const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);
  const [learnMoreInfo, setLearnMoreInfo] = useState<boolean | undefined>(false);
  const findInfoText: any = infoDetails?.length > 0 ? infoDetails?.find((ele) => ele?.attributes?.InfoText) : []
  const InfoTextDetail = findInfoText ? findInfoText?.attributes?.InfoText : {}
  const textShow = InfoTextDetail ? InfoTextDetail?.substring(0, 100) : "";
  const [eventsList, setEventsList] = useState<AllEventsList[]>([]);
  const intialEvent: AllEventsList = {
    eventId: '',
    eventListId: ListOptionType.EVENT,
    nameWhenOther: '',
    selected: 0,
    created: new Date(),
    modified: new Date(),
    name: '',
  };
  const [singleEvent, setSingleEvent] = useState<AllEventsList>();
  const [loading, setLoading] = useState(false);

  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [timeCounter, setTimeCounter] = useState<number>(1)
  const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

  useEffect(() => {
    if (riskAreaSelector && riskAreaSelector.error !== null) {
      setIsWromgOrgModal(true)
    }
  }, [riskAreaSelector.error])

  function handleWrongOrgModel() {
    navigate("/dashboard")
  }

  useEffect(() => {
    if (measureGroupsSelector?.status !== null && measureGroupsSelector?.status !== undefined) {
      setIsSaved(true);
      setTimeCounter(1);
    }
  }, [measureGroupsSelector.status])

  useEffect(() => {
    if (isSaved) {
      let intervalTimeCounter = setInterval(() => {
        setTimeCounter(timeCounter + 1);
        if (timeCounter === 3) {
          setIsSaved(false)
          clearInterval(intervalTimeCounter);
          setTimeCounter(1);
        }
      }, 1000);
      return () => {
        clearInterval(intervalTimeCounter);
      };
    }
  }, [timeCounter, isSaved]);

  function SideDrawerOpen() {
    setLearnMoreInfo(!learnMoreInfo);
  }

  function SideDrawerClose() {
    setLearnMoreInfo(false);
  }

  useEffect(() => {
    const postEventData =measureGroupsSelector.postEventData
    const postEventIndex = eventsList.findIndex((ele: AllEventsList) => ele?.eventListId === measureGroupsSelector?.postEventData?.eventListId)
    if(postEventIndex > -1 && postEventData !== null){
      eventsList[postEventIndex] = measureGroupsSelector.postEventData;
      setEventsList(eventsList);
    }
  }, [measureGroupsSelector.postEventData])

  useEffect(() => {
    if (id) {
      dispatch(riskAreaContainerCreators.requestEditValue(id));
    }
  }, [id])

  useEffect(() => {
    if (riskAreaSelector?.editData) {
      setSingleRiskCategory(riskAreaSelector?.editData)
    }
  }, [riskAreaSelector?.editData])

  useEffect(() => {
    if (singleRiskCategory) {
      dispatch(measureGroupsContainerCreators.requestGetEvents(id, '', isCancel, '', decodedUserInfo, singleRiskCategory));
      dispatch(redirectUrlCreators.clearRedirectUrl())
    }
  }, [singleRiskCategory])

  useEffect(() => {
    if (measureGroupsSelector.events && singleEventId) {
      const currentEvent = measureGroupsSelector.events?.find((ele: AllEventsList) => ele.eventId === singleEventId);
      setEditEventObj(currentEvent)
    }
  }, [measureGroupsSelector.events, singleEventId])


  useEffect(() => {
    if (measureGroupsSelector.events && measureGroupsSelector.events.length >= 0 && !editEventObj) {
      if (currentIndex !== null && currentIndex > -1) {
        setCurrentIndex(currentIndex)
        setSingleEvent(measureGroupsSelector.events[currentIndex])
      }
      else if (currentIndex === null) {
        const index = measureGroupsSelector?.events?.findIndex((ele: AllEventsList) => !ele?.eventId)
        setCurrentIndex(index)
        setSingleEvent(measureGroupsSelector.events[index])
      }
      else if (currentIndex === -1) {
        setSingleEvent({
          ...intialEvent,
          eventListId: '00000000-0000-0000-0000-000000000000'
        });
      }
    }
  }, [measureGroupsSelector.events, currentIndex])

  useEffect(() => {
    if (measureGroupsSelector.events && measureGroupsSelector.events.length && singleRiskCategory) {
      const defaultEvents = measureGroupsSelector.events?.filter((ele: AllEventsList) => ele.eventListId !== "00000000-0000-0000-0000-000000000000");
      setEventsList(defaultEvents);
    }
  }, [measureGroupsSelector.events, singleRiskCategory])

  useEffect(() => {
    if (editEventObj) {
      setCurrentIndex(-1);
      setEventId(editEventObj?.eventId);
      if (editEventObj?.selected) {
        setSelected(editEventObj?.selected)
        if (editEventObj?.eventListId === "00000000-0000-0000-0000-000000000000") {
          setAnswerType("")
          setnameWhenOther(editEventObj?.nameWhenOther)
        } else {
          setAnswerType("MightHappen")
        }
      } else {
        setSelected(editEventObj?.selected)
        if (editEventObj?.eventListId === "00000000-0000-0000-0000-000000000000") {
          setAnswerType('')
        } else {
          setAnswerType('VeryUnlikelyOrImpossible');
        }
      }
    }
  }, [editEventObj])

  useEffect(() => {
    if (measureGroupsSelector.singleEvent && !editEventObj) {
      setEventId(measureGroupsSelector.singleEvent?.eventId);
      if (measureGroupsSelector.singleEvent?.selected) {
        setSelected(measureGroupsSelector.singleEvent?.selected)
        if (measureGroupsSelector.singleEvent?.eventListId === "00000000-0000-0000-0000-000000000000") {
          setAnswerType("")
          setnameWhenOther(measureGroupsSelector.singleEvent?.nameWhenOther)
        } else {
          setAnswerType("MightHappen")
        }
      }
      else {
        setSelected(measureGroupsSelector.singleEvent?.selected)
        if (measureGroupsSelector.singleEvent?.eventListId === "00000000-0000-0000-0000-000000000000") {
          setAnswerType('')
        } else {
          setAnswerType('VeryUnlikelyOrImpossible');
        }
      }
    }
  }, [measureGroupsSelector.singleEvent])

  useEffect(() => {
    if (editEventObj) {
      dispatch(measureGroupsContainerCreators.requestGetEventsHelpText(editEventObj?.eventListId, decodedUserInfo));
    } else if (eventsList[currentIndex]?.eventListId) {
      dispatch(measureGroupsContainerCreators.requestGetEventsHelpText(eventsList[currentIndex]?.eventListId, decodedUserInfo));
    } else {
      dispatch(measureGroupsContainerCreators.requestGetEventsHelpText('00000000-0000-0000-0000-000000000000', decodedUserInfo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEventObj, currentIndex])/* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (measureGroupsSelector && measureGroupsSelector.eventsHelpText) {
      setInfoDetails(measureGroupsSelector.eventsHelpText?.data);
    }
  }, [measureGroupsSelector])


  useEffect(() => {
    if (measureGroupsSelector?.loadingAfterPost) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [measureGroupsSelector?.loadingAfterPost])

  // functions

  function handleAnswerType(answer: string) {
    if (((role === "administrator") || (role === "editor"))) {
      if (answer === 'MightHappen') {
        setAnswerType('MightHappen');
        setSelected(1);
      } else if (answer === 'VeryUnlikelyOrImpossible') {
        setAnswerType('VeryUnlikelyOrImpossible');
        setSelected(0);
      } else if (answer === 'Yes') {
        setAnswerType('Yes');
        setSelected(1);
      } else if (answer === 'No') {
        setAnswerType('No');
        setSelected(0);
      }
    }
  }

  function handleCancelClick() {
    navigate(`/events/${id}?isCancel=0`, { state: { from: 'cancal' } });
  }

  async function handlePreviousEvent() {
    if (currentIndex === -1) {
      if (id && eventsList[eventsList?.length - 1] && eventsList[eventsList?.length - 1]?.eventId) {
        dispatch(measureGroupsContainerCreators.requestGetEventById(id, eventsList[eventsList?.length - 1]?.eventId))
      }
      setCurrentIndex(eventsList.length - 1);
    } else if (currentIndex > -1) {
      setCurrentIndex(currentIndex - 1);
      if (id && eventsList[currentIndex - 1] && eventsList[currentIndex - 1]?.eventId) {
        dispatch(measureGroupsContainerCreators.requestGetEventById(id, eventsList[currentIndex - 1]?.eventId))
      }
    }
    //setTimeout(() => {
    //  dispatch(measureGroupsContainerCreators.requestGetEvents(id, '', isCancel, '', decodedUserInfo, singleRiskCategory));
    //}, 1500)
  }

  function handleEditEventInAddMode(isAddMode: boolean) {
    const nameErr = schema.eventNameWhenOther.validate(nameWhenOther).error;
    if (answerType === "") {
      if (nameErr) {
        setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
      } else {
        const payload = {
          eventListId: '00000000-0000-0000-0000-000000000000',
          nameWhenOther: nameWhenOther,
          selected: 1,
        }
        dispatch(measureGroupsContainerCreators.requestPutEvents(id, payload, (isAddMode ? '' : navigate), eventId));
        setErrObj({ name: undefined });
        if (isAddMode) {
          setAnswerType('');
          setnameWhenOther('');
          if (currentIndex + 1 < eventsList.length) {
            setCurrentIndex(currentIndex + 1);
          } else {
            setCurrentIndex(-1);
          }
        }
      }
    } else {
      if (answerType === "MightHappen" || answerType === "VeryUnlikelyOrImpossible") {
        const payload = {
          eventListId: eventsList[currentIndex]?.eventListId,
          nameWhenOther: nameWhenOther,
          selected: selected,
        }
        dispatch(measureGroupsContainerCreators.requestPutEvents(id, payload, (isAddMode ? '' : navigate), eventId));
        if (isAddMode) {
          setAnswerType('');
          setnameWhenOther('');
          if (currentIndex + 1 < eventsList.length) {
            setCurrentIndex(currentIndex + 1);
          } else {
            setCurrentIndex(-1);
          }
        }
      }
      else {
        if (answerType === "No") {
          const payload = {
            eventListId: eventsList[currentIndex]?.eventListId,
            nameWhenOther: nameWhenOther,
            selected: selected,
          }
          dispatch(measureGroupsContainerCreators.requestPutEvents(id, payload, (isAddMode ? '' : navigate), eventId));
          if (isAddMode) {
            setAnswerType('');
            setnameWhenOther('');
            setCurrentIndex(currentIndex + 1);
          }
        }
      }
    }
  }

  /* istanbul ignore next */
  function handleNextClick() {
    if (eventsList[currentIndex]?.eventId) {
      // EDIT EVENT IN ADD MODE
      if (id && eventsList[currentIndex + 1]?.eventId) {
        dispatch(measureGroupsContainerCreators.requestGetEventById(id, eventsList[currentIndex + 1]?.eventId))
      }
      handleEditEventInAddMode(true);
    }
    else if (editEventObj) {
      // Edit Event
      const nameErr = schema.eventNameWhenOther.validate(nameWhenOther).error;
      if (answerType === "") {
        if (nameErr) {
          setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
        } else {
          const payload = {
            eventListId: '00000000-0000-0000-0000-000000000000',
            nameWhenOther: nameWhenOther,
            selected: 1,
          }
          dispatch(measureGroupsContainerCreators.requestPutEvents(id, payload, navigate, eventId));
          setErrObj({ name: undefined });
        }
      } else {
        if (answerType === "MightHappen" || answerType === "VeryUnlikelyOrImpossible") {
          const payload = {
            eventListId: editEventObj?.eventListId,
            nameWhenOther: nameWhenOther,
            selected: selected,
          }
          dispatch(measureGroupsContainerCreators.requestPutEvents(id, payload, navigate, eventId));
        }
        else {
          if (answerType === "No") {
            const payload = {
              eventListId: editEventObj?.eventListId,
              nameWhenOther: nameWhenOther,
              selected: selected,
            }
            dispatch(measureGroupsContainerCreators.requestPutEvents(id, payload, navigate, eventId));
          }
        }
      }
    }
    else {
      // Add Event
      if (answerType === "MightHappen" || answerType === "VeryUnlikelyOrImpossible") {
        const payload = {
          eventListId: currentIndex > -1 ? eventsList[currentIndex]?.eventListId : '00000000-0000-0000-0000-000000000000',
          nameWhenOther: "",
          selected: selected
        }
        dispatch(measureGroupsContainerCreators.requestPostEvents(id, payload));
        setLastQuestion(true);
        if (currentIndex + 1 < eventsList.length) {
          setCurrentIndex(currentIndex + 1);
          setAnswerType('');
        } else {
          setCurrentIndex(-1);
          setAnswerType('');
        }
      }
      else {
        if (lastQuestion) {
          const nameErr = schema.eventNameWhenOther.validate(nameWhenOther).error;
          if (answerType === "Yes") {
            if (nameErr) {
              setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
            } else {
              const index = measureGroupsSelector?.events?.findIndex((ele: AllEventsList) => !ele?.eventId)
              const payload = {
                eventListId: '00000000-0000-0000-0000-000000000000',
                nameWhenOther: nameWhenOther,
                selected: selected
              }
              dispatch(measureGroupsContainerCreators.requestPostEvents(id, payload));
              setCurrentIndex(-1)
              setErrObj({ name: undefined });
              setAnswerType('');
              setnameWhenOther('');
            }
          }
          else if (answerType === "No") {
            navigate(`/risk-area/${id}`);
          }
        } else {
          const nameErr = schema.eventNameWhenOther.validate(nameWhenOther).error;
          if (nameErr) {
            setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
          } else {
            const payload = {
              eventListId: currentIndex > -1 ? eventsList[currentIndex]?.eventListId : '00000000-0000-0000-0000-000000000000',
              nameWhenOther: nameWhenOther,
              selected: 1
            }
            dispatch(measureGroupsContainerCreators.requestPostEvents(id, payload, navigate));
            setErrObj({ name: undefined });
            setnameWhenOther('');
          }
        }
      }
    }
    setLearnMoreInfo(false);
    //if (!editEventObj && answerType !== "No") {
    //  setTimeout(() => {
    //    dispatch(measureGroupsContainerCreators.requestGetEvents(id, '', isCancel, '', decodedUserInfo, singleRiskCategory));
    //  }, 1000)
    //}
  }

  function isDisableBtn() {
    if (eventsList[currentIndex]?.eventListId) {
      if (answerType === '') {
        return true;
      } else {
        return false;
      }
    } else {
      if (lastQuestion) {
        if (answerType === '') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  function handleBtnText() {
    if (editEventObj) {
      return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
    } else {
      if (eventsList[currentIndex]?.eventListId) {
        return intl.formatMessage({ id: 'next', defaultMessage: 'next' })
      } else {
        if (lastQuestion) {
          return intl.formatMessage({ id: 'next', defaultMessage: 'next' })
        } else {
          return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
        }
      }
    }
  }

  return (
    <>
      {/*{(measureGroupsSelector.loading || loading) ?
        <div className='app-loader'>
          <LoadingIcon />
        </div>
        :*/}
      <div>
        <Container>
          <div className="flex-grow-1 flex flex-col justify-between">
            <div>
              <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0'>
                <div className="flex items-center font-semibold text-xl">
                  <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                  <div className="ml-3">
                    <div className="font-semibold text-xl text-[#535353]">
                      {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                    </div>
                  </div>
                </div>
                {isSaved &&
                  <div className="font-normal text-xs text-[#959595]">
                    <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                  </div>
                }
              </div>
              <div className="border w-full"></div>
              <div>
                <div className='m-auto w-full py-7 align-middle items-center flex justify-between'>
                  {editEventObj ?
                    <div className="font-semibold text-[32px] sm:text-2xl">
                      <div>{editEventObj.eventListId === "00000000-0000-0000-0000-000000000000"
                        ? intl.formatMessage({ id: `edit_events`, defaultMessage: 'edit_events' })
                        : intl.formatMessage({ id: `event_${singleRiskCategory?.riskListId}`, defaultMessage: `event_${singleRiskCategory?.riskListId}` }) + editEventObj?.name}</div>
                    </div>
                    :
                    eventsList[currentIndex]?.eventListId ?
                      <div className="font-semibold text-[32px] sm:text-2xl">
                        <div data-testid={`${testId}-event-single-risk`}>{intl.formatMessage({ id: `event_${singleRiskCategory?.riskListId}`, defaultMessage: `event_${singleRiskCategory?.riskListId}` }) + eventsList[currentIndex]?.name}</div>
                      </div>
                      :
                      lastQuestion ?
                        <div className="font-semibold text-[32px] sm:text-2xl">
                          <div>{intl.formatMessage({ id: `event_00000000-0000-0000-0000-000000000000`, defaultMessage: `event_00000000-0000-0000-0000-000000000000` })}</div>
                        </div>
                        :
                        <div className="font-semibold text-[32px] sm:text-2xl">
                          <div>{intl.formatMessage({ id: 'add_events', defaultMessage: 'add_events' })}</div>
                        </div>
                  }
                </div>
                <div>
                  {infoDetails && infoDetails.length !== 0 &&
                    <div className=" w-[540px] leading-4 mb-10 text-[#535353] sm:w-full">
                      <span className="font-normal text-lg">{textShow}</span>
                      {textShow.length > 90 &&
                        <>
                          <span>{"... "}</span>
                          <span data-testid={`${testId}-learn-more-click`} className={"font-semibold text-[#3BA8FC] text-lg cursor-pointer w-max"} onClick={() => SideDrawerOpen()}>{intl.formatMessage({ id: 'learn_more', defaultMessage: 'learn_more' })}</span>
                        </>
                      }
                    </div>}

                  <div className={`w-full max-w-[420px] top-0 right-0  h-full fixed z-[1055] border-r sm:overflow-y-auto sm:w-full sm:max-w-full ${!learnMoreInfo ? 'translate-x-[100%] ease-in-out duration-300' : 'translate-x-[0%] ease-in-out duration-300'}`}>
                    <SideDrawer testId={`${testId}-side-drawer`} drawerInfo={infoDetails} SideDrawerClose={SideDrawerClose} />
                  </div>
                </div>
                <div className="flex sm:flex-col">
                  {editEventObj ?
                    <>
                      {editEventObj.eventListId === "00000000-0000-0000-0000-000000000000" ?
                        <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mb-10 mr-10 p-10 sm:p-5">
                          <label className="inline-block text-base font-bold mb-1">
                            {intl.formatMessage({ id: "name_of_the_event", defaultMessage: 'name_of_the_event' })}
                          </label>
                          <input
                            data-testid={`${testId}-name-of-event`}
                            type="text"
                            maxLength={100}
                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                            value={nameWhenOther}
                            onChange={(e) => setnameWhenOther(e.target.value)}
                          />
                          {errObj.name?.message ?
                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                              <span>{errObj.name.message}</span>
                            </div>
                            :
                            null
                          }
                        </div>
                        :
                        <div className="mr-6 sm:w-full sm:mr-0">
                          <div data-testid={`${testId}-might-happen`} onClick={() => handleAnswerType('MightHappen')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'MightHappen' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "might_happen", defaultMessage: 'might_happen' })}</div>
                          </div>
                          <div data-testid={`${testId}-very-untill`} onClick={() => handleAnswerType('VeryUnlikelyOrImpossible')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'VeryUnlikelyOrImpossible' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "very_unlikely_or_impossible", defaultMessage: 'very_unlikely_or_impossible' })}</div>
                          </div>
                        </div>
                      }
                    </>
                    :
                    <>
                      {eventsList[currentIndex]?.eventListId ?
                        <div className="mr-6 sm:w-full sm:mr-0">
                          <div data-testid={`${testId}-might-happen-event-container`} onClick={() => handleAnswerType('MightHappen')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'MightHappen' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "might_happen", defaultMessage: 'might_happen' })}</div>
                          </div>
                          <div data-testid={`${testId}-very-unlikely-impossible-event-container`} onClick={() => handleAnswerType('VeryUnlikelyOrImpossible')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'VeryUnlikelyOrImpossible' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "very_unlikely_or_impossible", defaultMessage: 'very_unlikely_or_impossible' })}</div>
                          </div>
                        </div>
                        :
                        lastQuestion ?
                          <div className="mr-6 sm:w-full sm:mr-0">
                            <div data-testid={`${testId}-no-anser-type-add-event`} onClick={() => handleAnswerType('No')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                              <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "no", defaultMessage: 'no' })}</div>
                            </div>
                            <div data-testid={`${testId}-yes-answer-type-add-event`} onClick={() => handleAnswerType('Yes')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                              <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "yes", defaultMessage: 'yes' })}</div>
                            </div>
                          </div>
                          :
                          <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mb-10 mr-10 p-10 sm:p-5">
                            <label className="inline-block text-base font-bold mb-1">
                              {intl.formatMessage({ id: "name_of_the_event", defaultMessage: 'name_of_the_event' })}
                            </label>
                            <input
                              data-testid={`${testId}-event-name`}
                              type="text"
                              maxLength={100}
                              className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                              value={nameWhenOther}
                              onChange={(e) => setnameWhenOther(e.target.value)}
                            />
                            {errObj.name?.message ?
                              <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                <span>{errObj.name.message}</span>
                              </div>
                              :
                              null
                            }
                          </div>
                      }
                    </>
                  }
                  {answerType === 'Yes' ?
                    <div className="w-full max-w-[414px] border rounded-lg bg-white mb-10 p-10 sm:p-5">
                      <div>
                        <div>
                          <label className="inline-block text-base font-bold mb-1">
                            {intl.formatMessage({ id: "name_of_the_event", defaultMessage: 'name_of_the_event' })}
                          </label>
                          <input
                            data-testid={`${testId}-type-yes-event-name`}
                            type="text"
                            maxLength={100}
                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.name?.message ? 'border-[red]' : ''}`}
                            value={nameWhenOther}
                            onChange={(e) => setnameWhenOther(e.target.value)}
                          />
                        </div>
                      </div>
                      {errObj.name?.message ?
                        <div className='text-red-600 font-normal text-sm h-3 mt-1 flex items-start justify-start'>
                          <span>{errObj.name.message}</span>
                        </div>
                        :
                        null
                      }
                    </div>
                    :
                    <></>
                  }
                </div>
              </div>
            </div>
            <div className='w-full bottom-0 align-middle items-center flex flex-col justify-between'>
              {(eventsList[currentIndex]?.eventListId || (lastQuestion)) &&
                <div className='w-full bottom-0 align-middle items-center flex justify-between'>
                  <div>
                    <StyledButton
                      onClick={() => handlePreviousEvent()}
                      disabled={(currentIndex === 0 || editEventObj) ? true : false}
                      buttonClass={'sm:px-2'}
                      flexReverse={'flex-row-reverse'}
                      width={'w-48 sm:w-max'}
                      align={'justify-start'}
                      text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                      color={'text-[#535353]'}
                      iconType={'arrowleft'}
                      backgroundColor={'bg-[#EDEDED]'}
                    />
                  </div>
                  {((role === "administrator") || (role === "editor")) ?
                    <div>
                      <StyledButton testId={`${testId}-next-btn`} onClick={() => handleNextClick()} buttonClass={'sm:px-2 PrimoryBtn'} disabled={isDisableBtn()} width={'w-48 sm:w-max'} align={'justify-end'} text={handleBtnText()} color={isDisableBtn() ? 'text-[#535353]' : 'text-white'} iconType={isDisableBtn() ? 'blackarrow' : 'arrow'} backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />
                    </div>
                    :
                    <></>
                  }
                </div>
              }
              <div className="w-full flex items-center justify-between mt-4">
                {(editEventObj || (singleEvent?.eventListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion)) ?
                  <StyledButton testId={`${testId}-cancel-btn`} onClick={() => handleCancelClick()} buttonClass={'sm:px-2'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })} color={'text-[#535353]'} backgroundColor={'bg-[#EDEDED]'} />
                  :
                  <div className="w-48 h-[52px] flex items-center justify-center">
                    <button data-testid={`${testId}-cancel-btn-2`} onClick={() => handleCancelClick()} className="flex items-center justify-center">
                      <span className="text-[#535353] font-family-arial font-semibold">{intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}</span>
                    </button>
                  </div>
                }
                {(editEventObj || (singleEvent?.eventListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion)) && <StyledButton testId={`${testId}-handle-next-btn`} onClick={() => handleNextClick()} buttonClass={'sm:px-2 PrimoryBtn'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })} disabled={isDisableBtn()} color={isDisableBtn() ? 'text-[#535353]' : 'text-white'} backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/*}s*/}
      <Modal show={isWromgOrgModal} onClose={() => ""}>
        <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
          <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
        </div>
        <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
          <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
        </div>
        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
          <StyledButton
            testId={`${testId}-goto-dashboard`}
            onClick={() => handleWrongOrgModel()}
            buttonClass={'PrimoryBtn'}
            width={"w-[220px] sm:w-40"}
            align={"justify-end"}
            text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
            color={"text-white mr-3 text-base"}
            iconType={'home'}
            backgroundColor={"bg-black"}
          />
        </div>
      </Modal>
    </>
  );
}

