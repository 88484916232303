import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: matrixContainerTypes, Creators: matrixContainerCreators } = createActions({
    // get Accountant Comments
    requestGetAccountantComments: [],
    successGetAccountantComments: ['acctComments'],
    failureGetAccountantComments: ['error'],

    // put Accountant Comments
    requestPutAccountantComments: ['comments'],
    successPutAccountantComments: ['acctComments'],
    failurePutAccountantComments: ['error'],
});

export type InitialStateType = {
    acctComments: object | null;
    error: string | null;
    loading: boolean | null;
    shouldReloadAcctComments: boolean;
}

export const initialState: InitialStateType = { acctComments: null, error: null, loading: null, shouldReloadAcctComments: false };

export const matrixContainerReducer = (state = initialState, action: any) =>
    produce(state, (draft) => {
        switch (action.type) {
            // get Accountant Comments
            case matrixContainerTypes.REQUEST_GET_ACCOUNTANT_COMMENTS:
                draft.acctComments = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldReloadAcctComments = false;
                break;
            case matrixContainerTypes.SUCCESS_GET_ACCOUNTANT_COMMENTS:
                draft.acctComments = action.acctComments;
                draft.loading = false;
                draft.error = null;
                draft.shouldReloadAcctComments = false;
                break;
            case matrixContainerTypes.FAILURE_GET_ACCOUNTANT_COMMENTS:
                draft.acctComments = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldReloadAcctComments = false;
                break;

            // put Accountant Comments
            case matrixContainerTypes.REQUEST_PUT_ACCOUNTANT_COMMENTS:
                draft.acctComments = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldReloadAcctComments = false;
                break;
            case matrixContainerTypes.SUCCESS_PUT_ACCOUNTANT_COMMENTS:
                draft.acctComments = action.acctComments;
                draft.loading = false;
                draft.error = null;
                draft.shouldReloadAcctComments = true;
                break;
            case matrixContainerTypes.FAILURE_PUT_ACCOUNTANT_COMMENTS:
                draft.acctComments = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldReloadAcctComments = false;
                break;

            default:
                return state;
        }
    })
export default matrixContainerReducer;
