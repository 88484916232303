import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { InfoDetails } from '../../containers/BIAContainer/BIAEdit';
import { PlanList } from '../../containers/PlansContainer';
import { SubscriptionData } from '../../containers/SubscriptionContainer'
import Container from '../Container/Container';
import InfoCard from '../InfoCard';
import PlanCard from '../PlanCard';
import SelectPlan from '../SelectPlan/SelectPlan';
import CloseIcon from "../../assets/icons/close-icon.svg";
import InfoQIcon from "../../assets/icons/info-q-icon.svg";

type ControlRisksArray = {
    id: string;
    title: string;
}

type Props = {
    controlRisks: ControlRisksArray[];
    planList: PlanList[];
    showMoreInfo?: boolean;
    infoDetails?: InfoDetails[];
    handleShowMoreInfo?: () => void;
    upgradeSubscription?: boolean;
    manualRenewal?: boolean;
    subscriptionData?: SubscriptionData;
    testId?: string;
}

export default function PlansLayout(props: Props) {

    const { planList, showMoreInfo, infoDetails, handleShowMoreInfo, upgradeSubscription, manualRenewal, subscriptionData, testId=`plan-layout` } = props;

    // hooks
    const intl = useIntl();

    // states
    const [isPlanStarted, setIsPlanStarted] = useState<boolean>(false);
    const [singleProgram, setSingleProgram] = useState<PlanList | undefined>();

    // functions
    /* istanbul ignore next */
    function handleSingleProgram(card: PlanList) {
        setSingleProgram(card);
    }

    useEffect(() => {
        if (manualRenewal) {
            setIsPlanStarted(true);
        }
    }, [manualRenewal])
    /* istanbul ignore next */
    if (isPlanStarted) {
        return <SelectPlan testId={`${testId}-select-plan`} isPlanStarted={isPlanStarted} planList={planList} singleProgram={singleProgram} handleCancelClick={() => setIsPlanStarted(false)} upgradeSubscription={upgradeSubscription} manualRenewal={manualRenewal} subscriptionData={subscriptionData} />
    }

    return (
        <div>
            <Container>
                <div data-testid={`${testId}`} className="flex justify-between items-center mt-10 mb-8">
                    <div>
                        <div className='mb-4 font-semibold text-5xl flex items-start justify-start sm:mt-0 sm:text-3xl'>
                            {upgradeSubscription ? intl.formatMessage({ id: 'upgrade_subscription_header', defaultMessage: 'upgrade_subscription_header' }) : intl.formatMessage({ id: 'plans_header', defaultMessage: 'plans_header' })}
                        </div>
                        <div className='font-normal mb-20 text-left text-xl text-[#535353] sm:w-full sm:text-base font-family-arial'>
                            {intl.formatMessage({ id: 'plans_description', defaultMessage: 'plans_description' })}
                        </div>
                    </div>
                    <div className="relative">
                        <div data-testid={`${testId}-show-more-info`} onClick={() => handleShowMoreInfo && handleShowMoreInfo()} className={` mb-[25px] ml-[31px] cursor-pointer flex items-center justify-center border rounded-full ${showMoreInfo ? 'border-[#535353]' : 'border-[#959595]'} py-1 px-3`}>
                            <div className={`mr-1 mb-[2px] font-normal text-xs ${showMoreInfo ? 'text-[#535353]' : 'text-[#959595]'}`}>{intl.formatMessage({ id: 'info', defaultMessage: 'info' })}</div>
                            {showMoreInfo ?
                                <img className="w-[12px] h-[12px]" src={CloseIcon} alt='' />
                                :
                                <img className="w-[12px] h-[12px]" src={InfoQIcon} alt='' />
                            }
                        </div>
                        {showMoreInfo &&
                            <div className="absolute right-0 z-[1] flex flex-col min-h-max">
                                {infoDetails && infoDetails.map((infoCard, index) => (
                                    <InfoCard key={infoCard?.id} infoCard={infoCard} />
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div className='flex mb-16 sm:mb-0 sm:overflow-x-auto'>
                    {planList.map((card, index) => (
                        <PlanCard testId={`${testId}-plan-card-${index}`} key={card?.planId} card={card} planId={card.planId} name={card.name} price={card.price} usersCounter={card?.usersCounter}
                            isPlanStarted={isPlanStarted} setIsPlanStarted={setIsPlanStarted} handleSingleProgram={handleSingleProgram} upgradeSubscription={upgradeSubscription} />
                    ))}
                </div>
            </Container>
        </div>
    );
}
