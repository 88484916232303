import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import StyledButton from '../StyledButton/index';
import ViewIcon from '../../assets/icons/view.svg';
import ViewOffIcon from '../../assets/icons/view-off.svg';
import NotificationToast from '../ToastComponent';
import { languageProviderActions } from '../../containers/LanguageProvider/reducer';
import { DEFAULT_LOCALE } from '../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { InitialStateType, userContainerCreators } from '../../containers/LoginContainer/reducer';
import schema from '../../validations/schema';
import AuthHeader from '../AuthHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectLanguage } from '../../containers/LanguageProvider/selectors';
import { createAccountContainerCreators } from './reducer';
import LanguageDropdown from './LanguageDropdown';
import cookies from '../../utils/coockies';
import { EXPIRY_IN, USER_COOKIE } from '../../enum';
import { store } from '../../store';
import { appContainerCreators } from '../../AppReducer';

const testId = `create-account`;

type ErrorMessage = {
    message: string
}

export type ErrorObject = {
    email?: ErrorMessage;
    password?: ErrorMessage;
    firstName?: ErrorMessage;
    lastName?: ErrorMessage;
    phoneNumber?: ErrorMessage;
    name?: ErrorMessage;
    kvKNumber?: ErrorMessage;
    street?: ErrorMessage;
    houseNumber?: ErrorMessage;
    houseNumberSuffix?: ErrorMessage;
    postalcode?: ErrorMessage;
    city?: ErrorMessage;
    country?: ErrorMessage;
    repeatPassword?: ErrorMessage;
};

export type PrefLanguageList = {
    label: string,
    value: string
};

export default function CreateAccount() {

    // hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const createAccountSelector = useSelector((state: RootState) => state.createAccountContainer);
    const loginSelector: InitialStateType = useSelector((state: RootState) => state.loginContainer);
    const langSelector = useSelector(selectLanguage);

    // states
    const [activeTab, setActiveTab] = useState<number>(1);
    const [isShowPass, setIsShowPass] = useState<boolean>(true);
    const country: string = "";
    const [isShowRepeatPass, setIsShowRepeatPass] = useState<boolean>(true);
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const initialPrefLanguageList: PrefLanguageList[] = [
        { label: "Nederlands", value: "nl" },
        { label: "English", value: "en" },
    ]
    const prefLanguageList: PrefLanguageList[] = [...initialPrefLanguageList];
    const [prefLanguage, setPrefLanguage] = useState<string>(DEFAULT_LOCALE);
    const [isOrgExist, setIsOrgExist] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);

    const loading = loginSelector.loading;
    const createAccountErrorMessage = (loginSelector.checkEmailError || loginSelector.createAccountErr);

    const { firstName, lastName, phoneNumber, name, kvKNumber, street, houseNumber, houseNumberSuffix, postalcode, city, email, password, repeatPassword, errObj } = createAccountSelector.data;
    const [searchParams] = useSearchParams();
    const lang: string | null = searchParams.get('lang')

    useEffect(() => {
        const userTockenValue = cookies.get(USER_COOKIE)
        if (!!userTockenValue && userTockenValue !== 'null') {
            store.dispatch(appContainerCreators.clearUserInfo())
            cookies.remove(USER_COOKIE);
            cookies.remove(EXPIRY_IN);
        }
    }, [])

    useEffect(() => {
        if (lang === "en" || lang === "nl") {
            dispatch(languageProviderActions.changeLocale(lang));
        }/* eslint-disable react-hooks/exhaustive-deps */
    }, [lang])/* eslint-disable react-hooks/exhaustive-deps */


    useEffect(() => {
        if (isFocus) {
            document.getElementById("isOrgExist")?.focus();
        }
    }, [isFocus])

    useEffect(() => {
        setPrefLanguage(langSelector?.locale)
    }, [langSelector?.locale])

    // functions
    /* istanbul ignore next */
    async function handleLanguageDropDown(value: string) {
        const element = document.querySelector("[data-type='prefferedLanguage']");
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
        if (value === 'en') {
            navigate(`?lang=en`)
            await dispatch(languageProviderActions.changeLocale(value));
        } else {
            navigate(`?lang=nl`)
            await dispatch(languageProviderActions.changeLocale(value));
        }
    }
    /* istanbul ignore next */
    function handleIsOrgExist() {
        setIsOrgExist(!isOrgExist);
    }
    /* istanbul ignore next */
    function handleKeyPressForCreateAccount(e: any) {
        if (e.key === 'Enter') {
            handleCreateAccountClick();
        }
    }

    function handleCreateAccountClick() {
        const emailErr = schema.email.validate(email).error;
        const pwdErr = schema.password.validate(password).error;
        const firstNameErr = schema.firstName.validate(firstName).error;
        const lastNameErr = schema.lastName.validate(lastName).error;
        const phoneNumberErr = schema.phoneNumber.validate(phoneNumber).error;
        const nameErr = schema.name.validate(name).error;
        const kvKNumberErr = schema.kvKNumber.validate(kvKNumber).error;
        const streetErr = schema.street.validate(street).error;
        const houseNumberErr = schema.houseNumber.validate(houseNumber).error;
        const postalcodeErr = schema.postalcode.validate(postalcode).error;
        const cityErr = schema.city.validate(city).error;
        const repeatPasswordErr = schema.repeatPassword.validate(repeatPassword).error;
        if (emailErr || pwdErr || firstNameErr || lastNameErr || nameErr || kvKNumberErr || streetErr || houseNumberErr || postalcodeErr || cityErr || repeatPasswordErr) {
            dispatch(createAccountContainerCreators.setCreateAccountData({
                ...createAccountSelector.data,
                errObj: {
                    ...createAccountSelector?.data?.errObj,
                    email: emailErr?.message ? emailErr?.message : undefined,
                    password: pwdErr?.message ? pwdErr?.message : undefined,
                    firstName: firstNameErr?.message ? firstNameErr?.message : undefined,
                    lastName: lastNameErr?.message ? lastNameErr?.message : undefined,
                    name: nameErr?.message ? nameErr?.message : undefined,
                    kvKNumber: kvKNumberErr?.message ? kvKNumberErr?.message : undefined,
                    street: streetErr?.message ? streetErr?.message : undefined,
                    houseNumber: houseNumberErr?.message ? houseNumberErr?.message : undefined,
                    houseNumberSuffix: undefined,
                    postalcode: postalcodeErr?.message ? postalcodeErr?.message : undefined,
                    city: cityErr?.message ? cityErr?.message : undefined,
                    country: undefined,
                    repeatPassword: repeatPasswordErr?.message ? repeatPasswordErr?.message : undefined
                }
            }))
        } else {
            dispatch(createAccountContainerCreators.setCreateAccountData({
                ...createAccountSelector.data,
                errObj: {
                    ...createAccountSelector?.data?.errObj,
                    email: undefined, password: undefined, firstName: undefined, lastName: undefined, name: undefined, phoneNumber: undefined, kvKNumber: undefined, street: undefined, houseNumber: undefined, houseNumberSuffix: undefined, postalcode: undefined, city: undefined, country: undefined, repeatPassword: undefined
                }
            }));
            dispatch(userContainerCreators.requestSetUser({ duplicateOrgIntentional: isOrgExist }, {
                user: { firstName, lastName, email, mobilePhone: phoneNumber, password, prefferedLanguage: prefLanguage },
                organization: { companyName: name, chamberofcommercenr: kvKNumber, street, houseNumber: Number(houseNumber), houseNumberSuffix, postalcode, city, country }
            }, setShowToast, setIsOrgExist, setIsFocus, setActiveTab, navigate));
        }
    }

    return (
        <>
            <div>
                <AuthHeader activeTab={activeTab} setActiveTab={setActiveTab} testId={testId} />
            </div>
            <div className="flex items-center justify-center">
                <div className='m-auto sm:w-full sm:p-4'>
                    <div className='mt-16 mb-10 font-semibold text-[32px] flex items-start justify-start'>
                        {intl.formatMessage({ id: 'create_account', defaultMessage: 'create_account' })}
                    </div>
                    <div className='mb-4 font-semibold text-xl flex items-start justify-start'>
                        {intl.formatMessage({ id: 'personal', defaultMessage: 'personal' })}
                    </div>
                    <div className='bg-white p-10 w-96 h-full border rounded-lg sm:w-full sm:p-5'>
                        <div className='flex'>
                            <div className='w-full'>
                                <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                    <span className='font-family-arial'>{intl.formatMessage({ id: 'first_name', defaultMessage: 'first_name' })}</span>
                                </div>
                                <input data-testid={`${testId}-firstname`} maxLength={100} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.firstName?.message ? 'border-[red]' : ''}`} type='text' value={firstName} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, firstName: e.target.value }))} />
                                {errObj.firstName ?
                                    <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                        <span>{intl.formatMessage({ id: errObj.firstName, defaultMessage: errObj.firstName })}</span>
                                    </div>
                                    :
                                    <div className='mb-8'></div>
                                }
                            </div>
                        </div>

                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                            <span className='font-family-arial'>{intl.formatMessage({ id: 'last_name', defaultMessage: 'last_name' })}</span>
                        </div>
                        <input data-testid={`${testId}-lastname`} maxLength={100} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.lastName?.message ? 'border-[red]' : ''}`} type='text' value={lastName} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, lastName: e.target.value }))} />
                        {errObj.lastName ?
                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                <span>{intl.formatMessage({ id: errObj.lastName, defaultMessage: errObj.lastName })}</span>
                            </div>
                            :
                            <div className='mb-8'></div>
                        }

                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                            <span className='font-family-arial'>{intl.formatMessage({ id: 'phone_number_(optional)', defaultMessage: 'phone_number_(optional)' })}</span>
                        </div>
                        <input data-testid={`${testId}-mobilephone`} maxLength={12} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.phoneNumber?.message ? 'border-[red]' : ''}`} type='text' value={phoneNumber} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, phoneNumber: e.target.value }))} />
                        {errObj.phoneNumber?.message ?
                            <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                                <span>{errObj.phoneNumber?.message}</span>
                            </div>
                            :
                            <div className='mb-8'></div>
                        }


                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm font-family-arial'>
                            {intl.formatMessage({ id: 'preffered_language', defaultMessage: 'preffered_language' })}
                        </div>
                        {/* language dropdown */}
                        <div className="flex">
                            <div data-type='prefferedLanguage' className="w-full">
                                <LanguageDropdown
                                    testId={`${testId}-preffered-lang-dropdown`}
                                    options={[...prefLanguageList]}
                                    value={prefLanguage}
                                    onChange={(value: string) =>
                                        handleLanguageDropDown(value)
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className='mt-10 mb-4 font-semibold text-xl flex items-start justify-start'>
                        {intl.formatMessage({ id: 'organisation', defaultMessage: 'organisation' })}
                    </div>
                    <div className='bg-white p-10 w-96 min-h-max border rounded-lg sm:w-full sm:p-5'>
                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                            <span className='font-family-arial'>{intl.formatMessage({ id: 'name', defaultMessage: 'name' })}</span>
                        </div>
                        <input data-testid={`${testId}-name-one`} maxLength={100} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.name?.message ? 'border-[red]' : ''}`} type='text' value={name} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, name: e.target.value }))} />
                        {errObj.name ?
                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                <span>{intl.formatMessage({ id: errObj.name, defaultMessage: errObj.name })}</span>
                            </div>
                            :
                            <div className='mb-8'></div>
                        }

                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                            <span className='font-family-arial'>{intl.formatMessage({ id: 'kvK_number', defaultMessage: 'kvK_number' })}</span>
                        </div>
                        <input data-testid={`${testId}-kvknumber`} maxLength={45} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.kvKNumber?.message ? 'border-[red]' : ''}`} type='text' value={kvKNumber} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, kvKNumber: e.target.value }))} />
                        {errObj.kvKNumber ?
                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                <span>{intl.formatMessage({ id: errObj.kvKNumber, defaultMessage: errObj.kvKNumber })}</span>
                            </div>
                            :
                            <div className='mb-8'></div>
                        }

                        {(createAccountErrorMessage === 'Organization already exist') &&
                            <div className='flex items-center mb-8'>
                                <div className='w-full max-w-[276px] font-semibold text-base text-[#535353]'>{intl.formatMessage({ id: 'organization_already_exists', defaultMessage: 'organization_already_exists' })}</div>
                                <input autoFocus={isFocus} data-testid={`${testId}-handle-is-org-exist`} id={'isOrgExist'} className={`max-w-[20px] h-[20px] border hover:border-[#C1C1C1]`} type="checkbox" name="" checked={isOrgExist} onChange={() => handleIsOrgExist()} />
                            </div>
                        }

                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                            <span className='font-family-arial'>{intl.formatMessage({ id: 'street_address', defaultMessage: 'street_address' })}</span>
                        </div>
                        <input data-testid={`${testId}-street`} maxLength={100} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.street?.message ? 'border-[red]' : ''}`} type='text' value={street} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, street: e.target.value }))} />
                        {errObj.street ?
                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                <span>{intl.formatMessage({ id: errObj.street, defaultMessage: errObj.street })}</span>
                            </div>
                            :
                            <div className='mb-8'></div>
                        }

                        <div className='flex'>
                            <div className='mr-3'>
                                <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                    <span className='font-family-arial'>{intl.formatMessage({ id: 'number', defaultMessage: 'number' })}</span>
                                </div>
                                <input data-testid={`${testId}-housenumber`} maxLength={15} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.houseNumber?.message ? 'border-[red]' : ''}`} type='text' value={houseNumber} onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    // if value is not blank, then test the regex
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, houseNumber: e.target.value }))
                                    }
                                }
                                } />
                                {errObj.houseNumber ?
                                    <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                        <span>{intl.formatMessage({ id: errObj.houseNumber, defaultMessage: errObj.houseNumber })}</span>
                                    </div>
                                    :
                                    <div className='mb-8'></div>
                                }
                            </div>
                            <div>
                                <div className='font-bold text-base h-8 flex items-start justify-start sm:text-sm'>
                                    <span className='font-family-arial'>{intl.formatMessage({ id: 'affix_(optional)', defaultMessage: 'affix_(optional)' })}</span>
                                </div>
                                <input data-testid={`${testId}-housenumbersuffix`} maxLength={10} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11`} type='text' value={houseNumberSuffix} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, houseNumberSuffix: e.target.value }))} />
                            </div>
                        </div>

                        <div className='flex'>
                            <div className='mr-3'>
                                <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                    <span className='font-family-arial'>{intl.formatMessage({ id: 'zipcode', defaultMessage: 'zipcode' })}</span>
                                </div>
                                <input data-testid={`${testId}-postalcode`} maxLength={7} className={`bg-zinc-50 border rounded-lg px-3 mb-1 min-w-[100px] w-full h-11 ${errObj.postalcode?.message ? 'border-[red]' : ''}`} type='text' value={postalcode} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, postalcode: e.target.value }))} />
                                {errObj.postalcode ?
                                    <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                        <span>{intl.formatMessage({ id: errObj.postalcode, defaultMessage: errObj.postalcode })}</span>
                                    </div>
                                    :
                                    <div className='mb-8'></div>
                                }
                            </div>
                            <div>
                                <div className='font-bold text-base h-8 flex items-start justify-start sm:text-sm'>
                                    <span className='font-family-arial'>{intl.formatMessage({ id: 'city', defaultMessage: 'city' })}</span>
                                </div>
                                <input data-testid={`${testId}-city`} maxLength={100} className={`bg-zinc-50 border rounded-lg px-3 mb-1 min-w-[180px] w-full h-11 ${errObj.city?.message ? 'border-[red]' : ''}`} type='text' value={city} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, city: e.target.value }))} />
                                {errObj.city?.message ?
                                    <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                        <span>{intl.formatMessage({ id: errObj.city, defaultMessage: errObj.city })}</span>
                                    </div>
                                    :
                                    <div className='mb-8'></div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='mt-10 mb-4 font-semibold text-xl flex items-start justify-start'>
                        {intl.formatMessage({ id: 'account', defaultMessage: 'account' })}
                    </div>
                    <div className='bg-white p-10 w-96 min-h-96 border rounded-lg sm:w-full sm:p-5'>
                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                            <span className='font-family-arial'>{intl.formatMessage({ id: 'email_address', defaultMessage: 'email_address' })}</span>
                        </div>
                        <input data-testid={`${testId}-email`} maxLength={254} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.email?.message ? 'border-[red]' : ''}`} type='text' value={email} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, email: e.target.value }))} />
                        {errObj.email ?
                            <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                                <span>{intl.formatMessage({ id: errObj.email, defaultMessage: errObj.email })}</span>
                            </div>
                            :
                            <div className='mb-8'></div>
                        }

                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                            <span className='font-family-arial'>{intl.formatMessage({ id: 'password', defaultMessage: 'password' })}</span>
                        </div>
                        {isShowPass ?
                            <div className='flex items-end'>
                                <input data-testid={`${testId}-password`} maxLength={60} className={`bg-zinc-50 border rounded-lg pl-3 pr-8 mb-1 w-full h-11 relative ${errObj.password?.message ? 'border-[red]' : ''}`} type='password' value={password} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, password: e.target.value }))} />
                                <span className='z-0'>
                                    <img data-testid={`${testId}-password-viewon`} className='z-0 mb-3 -mx-7 max-w-[23px] cursor-pointer' src={ViewIcon} alt='' onClick={() => setIsShowPass(false)} />
                                </span>
                            </div>
                            :
                            <div className='flex items-end'>
                                <input data-testid={`${testId}-password-two`} maxLength={60} className={`bg-zinc-50 border rounded-lg pl-3 pr-8 mb-1 w-full h-11 relative ${errObj.password?.message ? 'border-[red]' : ''}`} type='text' value={password} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, password: e.target.value }))} />
                                <span className='z-0'>
                                    <img data-testid={`${testId}-psw-viewoff`} className='z-0 mb-3 -mx-7 max-w-[23px] cursor-pointer' src={ViewOffIcon} alt='' onClick={() => setIsShowPass(true)} />
                                </span>
                            </div>
                        }
                        <div className='text-zinc-600 font-normal text-xs h-5 flex items-start justify-start'>
                            <span className='font-family-arial'>{intl.formatMessage({ id: 'your_password_needs_to_be_at_least_8_characters_long', defaultMessage: 'your_password_needs_to_be_at_least_8_characters_long' })}</span>
                        </div>
                        {errObj.password ?
                            <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                                <span>{intl.formatMessage({ id: errObj.password, defaultMessage: errObj.password })}</span>
                            </div>
                            :
                            <div className='mb-4' />
                        }

                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                            <span className='font-family-arial'>{intl.formatMessage({ id: 'repeat_password', defaultMessage: 'repeat_password' })}</span>
                        </div>
                        {isShowRepeatPass ?
                            <div className='flex items-end'>
                                <input data-testid={`${testId}-repeatpassword`} maxLength={60} className={`bg-zinc-50 border rounded-lg pl-3 pr-8 mb-1 w-full h-11 relative ${errObj.repeatPassword?.message ? 'border-[red]' : ''}`} type='password' value={repeatPassword} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, repeatPassword: e.target.value }))} onKeyPress={(e) => handleKeyPressForCreateAccount && handleKeyPressForCreateAccount(e)} />
                                <span className='z-0'>
                                    <img data-testid={`${testId}-repeatpassword-viewon`} className='z-0 mb-3 -mx-7 max-w-[23px] cursor-pointer' src={ViewIcon} alt='' onClick={() => setIsShowRepeatPass(false)} />
                                </span>
                            </div>
                            :
                            <div className='flex items-end'>
                                <input data-testid={`${testId}-repeatpassword-two`} maxLength={60} className={`bg-zinc-50 border rounded-lg pl-3 pr-8 mb-1 w-full h-11 relative ${errObj.repeatPassword?.message ? 'border-[red]' : ''}`} type='text' value={repeatPassword} onChange={(e) => dispatch(createAccountContainerCreators.setCreateAccountData({ ...createAccountSelector.data, repeatPassword: e.target.value }))} onKeyPress={(e) => handleKeyPressForCreateAccount && handleKeyPressForCreateAccount(e)} />
                                <span className='z-0'>
                                    <img data-testid={`${testId}-repeatpassword-viewof`} className='z-0 mb-3 -mx-7 max-w-[23px] cursor-pointer' src={ViewOffIcon} alt='' onClick={() => setIsShowRepeatPass(true)} />
                                </span>
                            </div>
                        }
                        {errObj.repeatPassword ?
                            <div className='text-red-600 font-normal text-sm h-3 mb-3 flex items-start justify-start'>
                                <span>{intl.formatMessage({ id: errObj.repeatPassword, defaultMessage: errObj.repeatPassword })}</span>
                            </div>
                            :
                            <div className='mb-3' />
                        }
                    </div>
                    <div className='flex justify-end'>
                        <div>
                            <StyledButton testId={`${testId}-create-account-button`} buttonClass={'PrimoryBtn'} customClass={'my-10'} width={'w-60 sm:w-full'} align={'justify-end'} text={intl.formatMessage({ id: 'create_account', defaultMessage: 'create_account' })} color={'text-white'} iconType={'arrow'} backgroundColor={'bg-black'} onClick={() => handleCreateAccountClick()} loading={loading} />
                        </div>
                    </div>
                    <NotificationToast testId={`${testId}-notfication-toast`} text={createAccountErrorMessage ? createAccountErrorMessage : intl.formatMessage({ id: 'create_account_success', defaultMessage: 'create_account_success' })} iconType={createAccountErrorMessage ? intl.formatMessage({ id: 'failure', defaultMessage: 'failure' }) : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} color={createAccountErrorMessage ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showToast} onClick={() => setShowToast(false)} />
                </div>
            </div>
        </>
    );
}
