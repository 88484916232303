import { AxiosPromise } from 'axios';
import axios from './axios';

export const getPlansService = (): AxiosPromise => axios.get(`/subscriptionservice/v1/plans`)

export const subscriptionService = (): AxiosPromise => axios.get(`/subscriptionservice/v1/subscriptions`)

export const postSubscriptionService = (data: object): AxiosPromise => axios.post(`/subscriptionservice/v1/subscriptions`, data)

export const getDiscountCodeService = (discountCode: string,planId:string): AxiosPromise => axios.get(`/subscriptionservice/v1/discounts/${discountCode}?planId=${planId}`)

export const invoicesService = (): AxiosPromise => axios.get(`/subscriptionservice/v1/subscriptions/invoices`)

export const invoicesPdfService = (invoiceId:number): AxiosPromise => axios.get(`/subscriptionservice/v1/subscriptions/invoices/${invoiceId}`)

export const cancelMyRenewalService = (subscriptionId: string): AxiosPromise => axios.delete(`/subscriptionservice/v1/subscriptions/${subscriptionId}/autorenewal`)

export const getRoleLimitsService = (organizationId:string): AxiosPromise => axios.get(`/organizationservice/v1/organizations/${organizationId}/rolelimits`)

export const cancelMySubscriptionService = (subscriptionId: string): AxiosPromise => axios.delete(`/subscriptionservice/v1/subscriptions/${subscriptionId}`)