import {
    AxiosError,
    AxiosResponse
} from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { getAccountantCommentsService, putAccountantCommentsService } from '../../services/MatrixServices';
import { matrixContainerCreators, matrixContainerTypes } from './matrixReducer';

const {
    REQUEST_GET_ACCOUNTANT_COMMENTS,
    REQUEST_PUT_ACCOUNTANT_COMMENTS
} = matrixContainerTypes;

type PutAccountantCommentsData = {
    comments: object;
}


/* istanbul ignore next */
export function* getAccountantCommentsData() {
    try {
        const response: AxiosResponse = yield call(getAccountantCommentsService);
        yield put(matrixContainerCreators.successGetAccountantComments(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(matrixContainerCreators.failureGetAccountantComments(e.response?.data.errors[0].message));
        } else {
            yield put(matrixContainerCreators.failureGetAccountantComments("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* putAccountantCommentsData(data: PutAccountantCommentsData) {
    try {
        const response: AxiosResponse = yield call(putAccountantCommentsService, data.comments);
        yield put(matrixContainerCreators.successPutAccountantComments(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(matrixContainerCreators.failurePutAccountantComments(e.response?.data.errors[0].message));
        } else {
            yield put(matrixContainerCreators.failurePutAccountantComments("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export default function* matrixContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ACCOUNTANT_COMMENTS, getAccountantCommentsData);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_ACCOUNTANT_COMMENTS, putAccountantCommentsData);
}
