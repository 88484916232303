import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import schema from "../../validations/schema";
import StyledButton from "../StyledButton";
import { listOptionsContainerCreators } from "../../containers/OrganisationContainer/reducer";
import { Organisation } from "../CurrentOrganisationLayout/CurrentOrganisationLayout";
import Modal from "../Modal/Modal";
import { selectDecodedUserInfo } from "../../AppSelector";
import NotificationToast from "../ToastComponent";
import LoadingIcon from "../../assets/icons/loading-icon";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "../Container/Container";

type Props = {
    onCancel: (toast: boolean) => void;
    testId?: string
};

type ErrorMessage = {
    message: string
}

type ErrorObject = {
    email?: ErrorMessage;
    password?: ErrorMessage;
    firstName?: ErrorMessage;
    lastName?: ErrorMessage;
    phoneNumber?: ErrorMessage;
    name?: ErrorMessage;
    kvKNumber?: ErrorMessage;
    street?: ErrorMessage;
    houseNumber?: ErrorMessage;
    houseNumberSuffix?: ErrorMessage;
    postalcode?: ErrorMessage;
    city?: ErrorMessage;
    country?: ErrorMessage;
    repeatPassword?: ErrorMessage;
    users?: ErrorMessage;
    emailAddress?: ErrorMessage;
}

type LinkedUser = {
    created: Date;
    email: string;
    emailVerified: boolean;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    modified: Date;
    prefferedLanguage: string;
    userId: string;
}

export default function AddClient(props: Props) {

    const { onCancel, testId = `add-client` } = props;

    // hooks
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { fromDashboard }: RootState = location.state || {};

    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
    // states
    const [errObj, setErrObj] = useState<ErrorObject>({
        email: undefined,
        password: undefined,
        firstName: undefined,
        lastName: undefined,
        phoneNumber: undefined,
        name: undefined,
        kvKNumber: undefined,
        street: undefined,
        houseNumber: undefined,
        houseNumberSuffix: undefined,
        postalcode: undefined,
        city: undefined,
        country: undefined,
        repeatPassword: undefined,
        users: undefined,
        emailAddress: undefined
    });
    const [name, setName] = useState<string>("");
    const [kvKNumber, setKvKNumber] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string>('');
    const [houseNumberSuffix, setHouseNumberSuffix] = useState<string>("");
    const [postalcode, setPostalcode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [isOrgExist, setIsOrgExist] = useState<boolean>(false);
    const isFocus: boolean = false;
    const [linkedUsers, setLinkedUsers] = useState<LinkedUser[]>([]);
    const errorMessage = listOptionsSelector.error;
    const [currentOrgInfo, setCurrentOrgInfo] = useState<Organisation | undefined>()
    const [isAddClientModal, setIsAddClientModal] = useState<boolean>(false);
    const [isAddClientEmailModal, setIsAddClientEmailModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [selectedUsers, setSelectedUsers] = useState<LinkedUser[]>([]);
    const [selectedUsersList, setSelectedUsersList] = useState<LinkedUser[]>([]);
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
    const [selectedEmailsList, setSelectedEmailsList] = useState<string[]>([]);
    const [showAddClientToast, setShowAddClientToast] = useState<boolean>(false);
    const [counter, setCounter] = useState<number>(0);
    const [minimumLimitErr, setMinimumLimitErr] = useState<boolean>(false);
    const [minimumEmailLimitErr, setMinimumEmailLimitErr] = useState<boolean>(false);

    useEffect(() => {
        if (isFocus) {
            document.getElementById("isOrgExist")?.focus();
        }
    }, [isFocus])

    useEffect(() => {
        if (decodedUserInfo && decodedUserInfo.payload) {
            dispatch(listOptionsContainerCreators.requestGetCurrentOrgInfo(decodedUserInfo.payload.orgId))
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedUserInfo])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (listOptionsSelector.currentOrgInfo) {
            setCurrentOrgInfo(listOptionsSelector.currentOrgInfo)
        }
    }, [listOptionsSelector])

    useEffect(() => {
        if (currentOrgInfo) {
            dispatch(listOptionsContainerCreators.requestGetUserLinkToOrg(currentOrgInfo.organizationId))
        }
    }, [currentOrgInfo])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (listOptionsSelector && listOptionsSelector.linkedUserList) {
            setLinkedUsers(listOptionsSelector.linkedUserList);
        } else {
            setLinkedUsers([])
        }
    }, [listOptionsSelector])

    // functions
    function handleIsOrgExist() {
        setIsOrgExist(!isOrgExist);
    }
  /* istanbul ignore next */
    function handleSaveOrgInfo() {
        const nameErr = schema.name.validate(name).error;
        const kvKNumberErr = schema.kvKNumber.validate(kvKNumber).error;
        const streetErr = schema.street.validate(street).error;
        const houseNumberErr = schema.houseNumber.validate(houseNumber).error;
        const postalcodeErr = schema.postalcode.validate(postalcode).error;
        const cityErr = schema.city.validate(city).error;

        if (nameErr || kvKNumberErr || streetErr || houseNumberErr || postalcodeErr || cityErr) {
            setErrObj({
                name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                kvKNumber: kvKNumberErr?.message ? { message: intl.formatMessage({ id: kvKNumberErr?.message, defaultMessage: kvKNumberErr?.message }) } : undefined,
                street: streetErr?.message ? { message: intl.formatMessage({ id: streetErr?.message, defaultMessage: streetErr?.message }) } : undefined,
                houseNumber: houseNumberErr?.message ? { message: intl.formatMessage({ id: houseNumberErr?.message, defaultMessage: houseNumberErr?.message }) } : undefined,
                houseNumberSuffix: undefined,
                postalcode: postalcodeErr?.message ? { message: intl.formatMessage({ id: postalcodeErr?.message, defaultMessage: postalcodeErr?.message }) } : undefined,
                city: cityErr?.message ? { message: intl.formatMessage({ id: cityErr?.message, defaultMessage: cityErr?.message }) } : undefined
            });
        } else {
            if (selectedUsers.length === 0) {
                setMinimumLimitErr(true);
            } else {
                setMinimumLimitErr(false);
                if (selectedEmailsList.length === 0) {
                    setMinimumEmailLimitErr(true)
                } else {
                    setMinimumEmailLimitErr(false)
                    const userList = selectedUsersList.map((ele: LinkedUser) => (
                        ele.userId
                    ))
                    const inviteList = selectedEmailsList.map((ele: string) => (
                        ele
                    ))
                    const payload = {
                        companyName: name,
                        chamberofcommercenr: kvKNumber,
                        street,
                        houseNumber: Number(houseNumber),
                        houseNumberSuffix,
                        postalcode,
                        city,
                        country: 'NL',
                        users: userList,
                        invites: inviteList
                    }
                    currentOrgInfo && dispatch(listOptionsContainerCreators.requestCreateNewClientOrg(currentOrgInfo.organizationId, payload, setShowAddClientToast, onCancel, navigate));
                }
            }
            setErrObj({ name: undefined, kvKNumber: undefined, street: undefined, houseNumber: undefined, houseNumberSuffix: undefined, postalcode: undefined, city: undefined });
        }
    }

    function handleAddUserBtn() {
        setIsAddClientModal(true);
    }

    function handleAddEmailBtn() {
        setIsAddClientEmailModal(true);
        setSelectedEmails([...selectedEmailsList]);
    }

    function handleAddClientModalClose() {
        setSelectedUsers([...selectedUsersList]);
        setIsAddClientModal(false);
        setMinimumLimitErr(false);
        setCounter(selectedUsersList.length)
    }

    function handleAddClientEmailModalClose() {
        selectedEmails.length > 0 && setSelectedEmails([...selectedEmailsList]);
        setEmail('');
        setErrObj({ email: undefined });
        setIsAddClientEmailModal(false);
    }

    function handleSelectedUsers(selectedUser: LinkedUser, isChecked: boolean) {
        if (isChecked) {
            const latestArray = selectedUsers.filter((ele) => ele.userId !== selectedUser.userId);
            setSelectedUsers([...latestArray]);
            setCounter(counter - 1)
        } else {
            setSelectedUsers([...selectedUsers, selectedUser]);
            setCounter(counter + 1)
        }
    }

    function handleAddNewClientInOrg() {
        setSelectedUsersList([...selectedUsers]);
        setIsAddClientModal(false);
        setMinimumLimitErr(false);
    }

    function handleAddNewClientEmailInOrg() {
        const emailErr = schema.addClientEmail.validate(email).error;
        if (emailErr) {
            setErrObj({ email: emailErr?.message ? { message: intl.formatMessage({ id: emailErr?.message, defaultMessage: emailErr?.message }) } : undefined });
        } else {
            setSelectedEmails([...selectedEmails, email]);
            setSelectedEmailsList([...selectedEmails, email]);
            setIsAddClientEmailModal(false);
            setErrObj({ email: undefined });
            setEmail('');
        }
        setMinimumEmailLimitErr(false)
    }

    function handleCancel() {
        if (fromDashboard) {
            navigate("/dashboard");
        } else {
            onCancel(false);
        }
    }

    function removeEmails(item: string, index: number) {
        if (index !== -1) {
            selectedEmailsList.splice(index, 1);
            setSelectedEmailsList([...selectedEmailsList])
        }
    }

    return (
        <>
            {listOptionsSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div>
                    <Container>
                        <div data-testid={testId} className="flex items-center justify-center">
                            <div className="w-full sm:w-full sm:p-4">
                                <div className='mt-10 mb-4 font-semibold text-2xl flex items-start justify-start'>
                                    {intl.formatMessage({ id: 'add_client', defaultMessage: 'add_client' })}
                                </div>
                                <div className='bg-white p-10 w-full min-h-max border rounded-lg sm:w-full sm:p-5'>
                                    <div className="flex gap-8 sm:flex-col sm:gap-0">
                                        <div className='flex-1'>
                                            <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                                <span>{intl.formatMessage({ id: 'name', defaultMessage: 'name' })}</span>
                                            </div>
                                            <input maxLength={100} data-testid={`${testId}-name`} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.name?.message ? 'border-[red]' : ''}`} type='text' value={name} onChange={(e) => setName(e.target.value)} />
                                            {errObj.name?.message ?
                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                    <span data-testid={`${testId}-err-name`}>{errObj.name?.message}</span>
                                                </div>
                                                :
                                                <div className='mb-8'></div>
                                            }
                                        </div>

                                        <div className="flex-1">
                                            <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                                <span>{intl.formatMessage({ id: 'kvK_number', defaultMessage: 'kvK_number' })}</span>
                                            </div>
                                            <input maxLength={45} data-testid={`${testId}-kvknumber`} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.kvKNumber?.message ? 'border-[red]' : ''}`} type='text' value={kvKNumber} onChange={(e) => setKvKNumber(e.target.value)} />
                                            {errObj.kvKNumber?.message ?
                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                    <span>{errObj.kvKNumber?.message}</span>
                                                </div>
                                                :
                                                <div className='mb-8'></div>
                                            }
                                        </div>
                                    </div>

                                    {(errorMessage === 'Organization already exist') &&
                                        <div className='flex items-center mb-8'>
                                            <div className='w-full max-w-[276px] font-semibold text-base text-[#535353]'>{intl.formatMessage({ id: 'organization_already_exists', defaultMessage: 'organization_already_exists' })}</div>
                                            <input data-testid={`${testId}-err-msg`} autoFocus={isFocus} id={'isOrgExist'} className={`max-w-[20px] h-[20px] border hover:border-[#C1C1C1]`} type="checkbox" name="" checked={isOrgExist} onChange={() => handleIsOrgExist()} />
                                        </div>
                                    }

                                    <div className="flex gap-8">
                                        <div className='flex-1'>
                                            <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                                <span>{intl.formatMessage({ id: 'street_address', defaultMessage: 'street_address' })}</span>
                                            </div>
                                            <input maxLength={100} data-testid={`${testId}-street`} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.street?.message ? 'border-[red]' : ''}`} type='text' value={street} onChange={(e) => setStreet(e.target.value)} />
                                            {errObj.street?.message ?
                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                    <span>{errObj.street?.message}</span>
                                                </div>
                                                :
                                                <div className='mb-8'></div>
                                            }
                                        </div>
                                    </div>

                                    <div className="flex gap-8 sm:flex-col sm:gap-0">

                                        <div className="flex-1">
                                            <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                                <span>{intl.formatMessage({ id: 'number', defaultMessage: 'number' })}</span>
                                            </div>
                                            <input maxLength={15} data-testid={`${testId}-housenumber`} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.houseNumber?.message ? 'border-[red]' : ''}`} type='text' value={houseNumber} onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                // if value is not blank, then test the regex
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    setHouseNumber(e.target.value)
                                                }
                                            }} />
                                            {errObj.houseNumber?.message ?
                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                    <span>{errObj.houseNumber?.message}</span>
                                                </div>
                                                :
                                                <div className='mb-8'></div>
                                            }
                                        </div>

                                        <div className='flex-1'>
                                            <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                                <span>{intl.formatMessage({ id: 'affix_(optional)', defaultMessage: 'affix_(optional)' })}</span>
                                            </div>
                                            <input maxLength={10} data-testid={`${testId}-housenumbersuffix`} className='bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11' type='text' value={houseNumberSuffix} onChange={(e) => setHouseNumberSuffix(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className='flex sm:flex-col gap-8 sm:gap-0 sm:mt-[32px]'>
                                        <div className="flex-1 max-w-[220px] sm:max-w-full">
                                            <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                                <span>{intl.formatMessage({ id: 'zipcode', defaultMessage: 'zipcode' })}</span>
                                            </div>
                                            <input maxLength={7} data-testid={`${testId}-postalcode`} className={`bg-zinc-50 border rounded-lg px-3 mb-1 min-w-[100px] w-full h-11 ${errObj.postalcode?.message ? 'border-[red]' : ''}`} type='text' value={postalcode} onChange={(e) => setPostalcode(e.target.value)} />
                                            {errObj.postalcode?.message ?
                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                    <span>{errObj.postalcode?.message}</span>
                                                </div>
                                                :
                                                <div className='mb-8'></div>
                                            }
                                        </div>

                                        <div className="flex-1 w-full">
                                            <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                                <span>{intl.formatMessage({ id: 'city', defaultMessage: 'city' })}</span>
                                            </div>
                                            <input maxLength={100} data-testid={`${testId}-city`} className={`bg-zinc-50 border rounded-lg px-3 mb-1 min-w-[180px] w-full h-11 ${errObj.city?.message ? 'border-[red]' : ''}`} type='text' value={city} onChange={(e) => setCity(e.target.value)} />
                                            {errObj.city?.message ?
                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                    <span>{errObj.city?.message}</span>
                                                </div>
                                                :
                                                <div className='mb-8'></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* users and email */}
                                <div className='bg-white p-10 w-full mt-10 min-h-max border rounded-lg sm:w-full sm:p-5'>
                                    <div className="flex-1">
                                        <div className='font-bold text-lg h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                            <span>{intl.formatMessage({ id: 'colleagues', defaultMessage: 'colleagues' })}</span>
                                        </div>
                                        <div className='font-semibold text-base text-[#535353] mb-5'>{intl.formatMessage({ id: 'colleagues_title', defaultMessage: 'colleagues_title' })}</div>
                                        <div className='flex flex-col mb-4'>
                                            {selectedUsersList.map((ele, idx) => (
                                                <div key={idx} className="flex flex-col">
                                                    <div className="flex">
                                                        <div className="mb-1">{ele.firstName} {ele.lastName}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {minimumLimitErr &&
                                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>{intl.formatMessage({ id: 'min_colleagues_limit', defaultMessage: 'min_colleagues_limit' })}</div>
                                        }
                                        <StyledButton
                                            testId={`${testId}-add-user-button`}
                                            buttonClass={'px-4 py-2'}
                                            width={'min-w-36 max-w-max'}
                                            height={`h-10`}
                                            align={'justify-start'}
                                            text={`${intl.formatMessage({ id: 'change_colleagues', defaultMessage: 'change_colleagues' })}`}
                                            color={'text-gray-700'}
                                            iconType={"add"}
                                            flexReverse={"flex-row-reverse"}
                                            backgroundColor={'transparent border-1'}
                                            onClick={() => handleAddUserBtn()}
                                        />
                                    </div>
                                </div>

                                <div className='bg-white p-10 w-full mt-10 min-h-max border rounded-lg sm:w-full sm:p-5'>
                                    <div className="flex-1">
                                        <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                            <span>{intl.formatMessage({ id: 'email_address', defaultMessage: 'email_address' })}</span>
                                        </div>
                                        <div className='font-semibold text-base text-[#535353] mb-5'>{intl.formatMessage({ id: 'email_address_title', defaultMessage: 'email_address_title' })}</div>
                                        <div className='flex flex-col mb-4'>
                                            {selectedEmailsList.map((ele, index) => (
                                                <div key={`${testId}-select-email-${selectedEmailsList?.indexOf(ele)}`}>
                                                    <div className="flex flex-col">
                                                        <div className="flex items-center">
                                                            <div className="mb-1">{ele}</div>
                                                            <div data-testid={`${testId}-remove-user`} className='cursor-pointer ml-2 flex flex-col'>
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" onClick={() => removeEmails(ele, index)} xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                        <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                        {selectedEmailsList.length >= 3 &&
                                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>{intl.formatMessage({ id: 'more_than_3_email_is_not_allow', defaultMessage: 'more_than_3_email_is_not_allow' })}</div>
                                        }
                                        {minimumEmailLimitErr &&
                                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>{intl.formatMessage({ id: 'please_add_atleast_1_email', defaultMessage: 'please_add_atleast_1_email' })}</div>
                                        }
                                        <StyledButton
                                            disabled={selectedEmailsList.length >= 3 ? true : false}
                                            testId={`${testId}-add-email-button`}
                                            buttonClass={'px-4 py-2'}
                                            width={'min-w-36 max-w-max'}
                                            height={`h-10`}
                                            align={'justify-start'}
                                            text={`${intl.formatMessage({ id: 'add_email', defaultMessage: 'add_email' })}`}
                                            color={'text-gray-700'}
                                            iconType={"add"}
                                            flexReverse={"flex-row-reverse"}
                                            backgroundColor={'transparent border-1'}
                                            onClick={() => handleAddEmailBtn()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full align-middle items-center flex justify-between mt-8 sm:gap-8">
                            <StyledButton
                                testId={`${testId}-cancel-button`}
                                width={"w-48 sm:w-full"}
                                align={"justify-start"}
                                text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                                color={"text-[#535353]"}
                                flexReverse={"flex-row-reverse"}
                                backgroundColor={"bg-[#EDEDED]"}
                                onClick={() => handleCancel()}
                            />
                            <StyledButton
                                testId={`${testId}-save-button`}
                                buttonClass={'PrimoryBtn'}
                                width={"w-48 sm:w-full"}
                                align={"justify-end"}
                                text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
                                color={"text-white"}
                                iconType={""}
                                backgroundColor={"bg-black"}
                                onClick={() => handleSaveOrgInfo()}
                            />
                            {showAddClientToast && <NotificationToast text={listOptionsSelector.createNewClientOrgError ? listOptionsSelector.createNewClientOrgError : intl.formatMessage({ id: 'client_added_successfully', defaultMessage:'client_added_successfully' })} iconType={listOptionsSelector.createNewClientOrgError ? intl.formatMessage({ id: 'failure' }) : intl.formatMessage({ id: 'success' })} color={listOptionsSelector.createNewClientOrgError ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showAddClientToast} onClick={() => setShowAddClientToast(false)} />}
                        </div>
                    </Container>
                </div>
            }
            {(isAddClientModal) &&
                <div>
                    <Modal show={isAddClientModal} onClose={() => handleAddClientModalClose()}>
                        <div className="mb-4">{`${intl.formatMessage({ id: 'colleagues', defaultMessage: 'colleagues' })}`}</div>
                        <div className="mb-10">
                            {linkedUsers.map((user, idx) => {
                                const isChecked = selectedUsers && !!selectedUsers.find((ele) => ele.userId === user.userId)
                                return (
                                    <div key={idx} className='flex mb-2'>
                                        <input disabled={counter >= 5 && !isChecked} data-testid={`${testId}-linked-user-${idx}`} id={user.userId} className={`max-w-[20px] h-[20px] border hover:border-[#C1C1C1]`} type="checkbox" name="" checked={isChecked} onChange={(e) => handleSelectedUsers(user, isChecked)} />
                                        <label htmlFor={user.userId} className='flex flex-wrap w-full font-normal text-sm text-[#505565] ml-2'>{user.firstName} {user.lastName}</label>
                                    </div>
                                )
                            })}
                        </div>

                        {counter >= 5 &&
                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>{intl.formatMessage({ id: 'more_than_5_colleagues_is_not_allow', defaultMessage: 'more_than_5_colleagues_is_not_allow' })}</div>
                        }

                        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                            <StyledButton
                                testId={`${testId}-add-client-modal-close-button`}
                                width={"w-48 sm:w-full"}
                                align={"justify-start"}
                                text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                                color={"text-[#535353]"}
                                flexReverse={"flex-row-reverse"}
                                backgroundColor={"bg-[#EDEDED]"}
                                onClick={() => handleAddClientModalClose()}
                            />
                            <StyledButton
                                disabled={counter > 5}
                                testId={`${testId}-new-client-in-org-button`}
                                width={"w-48 sm:w-full"}
                                align={"justify-end"}
                                buttonClass={'PrimoryBtn'}
                                text={intl.formatMessage({ id: "add", defaultMessage: 'add' })}
                                backgroundColor={'bg-black'}
                                color={'text-white'}
                                onClick={() => handleAddNewClientInOrg()}
                            />
                        </div>
                    </Modal>
                </div>
            }
            {(isAddClientEmailModal) &&
                <div>
                    <Modal show={isAddClientEmailModal} onClose={() => handleAddClientEmailModalClose()}>
                        <div className="flex flex-col">
                            <div className="mb-4">{`${intl.formatMessage({ id: 'email_address', defaultMessage: 'email_address' })}`}</div>
                            <input maxLength={254} data-testid={`${testId}-email`} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.email?.message ? 'border-[red]' : ''}`} type='text' value={email} onChange={(e) => setEmail(e.target.value)}
                            />
                            {errObj.email?.message ?
                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                    <span>{errObj.email?.message}</span>
                                </div>
                                :
                                <div className='mb-2'></div>
                            }
                        </div>
                        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                            <StyledButton
                                testId={`${testId}-add-client-email-modal-cancel-button`}
                                width={"w-48 sm:w-full"}
                                align={"justify-start"}
                                text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                                color={"text-[#535353]"}
                                flexReverse={"flex-row-reverse"}
                                backgroundColor={"bg-[#EDEDED]"}
                                onClick={() => handleAddClientEmailModalClose()}
                            />
                            <StyledButton
                                testId={`${testId}-add-new-client-email-org-button`}
                                width={"w-48 sm:w-full"}
                                align={"justify-end"}
                                buttonClass={'PrimoryBtn'}
                                text={intl.formatMessage({ id: "add", defaultMessage: 'add' })}
                                backgroundColor={'bg-black'}
                                color={'text-white'}
                                onClick={() => handleAddNewClientEmailInOrg()}
                            />
                        </div>
                    </Modal>
                </div>
            }
        </>
    );
}