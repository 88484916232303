import Cookies from 'universal-cookie';
import { USER_COOKIE, EXPIRY_IN } from '../enum';

const cookies = new Cookies();

export const setUserCookies = (refreshToken: string | null, expiryIn: Date) => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    cookies.set(EXPIRY_IN, expiryIn, {
        path: "/"
    });
    cookies.set(USER_COOKIE, refreshToken, {
        path: "/",
        maxAge: date.getTime(),
    });
}

export default cookies;
