import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from '../../components/StyledButton';
import EditIcon from '../../assets/icons/edit-icon.svg';
import AddLocationContainer from '../AddLocationContainer/AddLocationContainer';
import { locationContainerCreators } from './locationReducer';
import LoadingIcon from "../../assets/icons/loading-icon";
import { selectDecodedUserInfo } from '../../AppSelector';
import Modal from '../../components/Modal/Modal';
import VideoJS from '../../components/VideoJS/video';
import videojs from 'video.js';
import Container from '../../components/Container/Container';
import GetIcons from '../../components/GetIcons/GetIcons';
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";
import InfoIcon from "../../assets/icons/info-i-icon.svg";

const testId = `location-container`;

export type Address = {
    [key: string]: any;
    type?: string;
    locationId?: string;
    street: string;
    houseNumber: number | null;
    houseNumberSuffix: string;
    postalcode: string;
    city: string;
    name?: string;
    availableConnectivityCount?: number;
    availableHardwareCount?: number;
    connectivityCount?: number;
    hardwareCount?: number;
}

export type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
    step: number;
    stepStatus: number;
}

type LocationPopupVideoAttributes = {
    name: string;
    alternativeText: string;
    caption: string;
    width: null;
    height: null;
    formats: null;
    hash: string;
    ext: string;
    mime: string;
    size: string;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: null;
    createdAt: Date;
    updatedAt: Date;
}

type LocationPopupVideo = {
    data: {
        id: number;
        attributes: LocationPopupVideoAttributes;
    }
}

type LocationPopupDetails = {
    Intro: string;
    Header: string;
    Body: string;
    Button: string;
    Button2: string;
    Link: string;
    Link2: string;
    Video: LocationPopupVideo;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
}

export default function LocationContainer() {

    // hooks
    const intl = useIntl();
    const params = useParams();
    const { pathname, search } = useLocation();
    const isCancel = new URLSearchParams(search).get('isCancel');
    const { id } = params;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLocations = pathname.includes(`/locations/${id}`)

    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const locationSelector = useSelector((state: RootState) => state.locationContainer);

    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;

    // states
    const { shouldRealoadAddresses } = useSelector((state: RootState) => state.locationContainer);
    const [locationsList, setLocationsList] = useState([]);
    const [hostingLocationsList, setHostingLocationsList] = useState([]);
    const [location, setLocation] = useState<Address | null>(null);
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>();
    const [isAddLocation, setIsAddLocation] = useState<boolean>(false);
    const [isLocationModal, setIsLocationModal] = useState<boolean>(false);
    const [locationPopupDetails, setLocationPopupDetails] = useState<LocationPopupDetails>();
    const playerRef = React.useRef(null);
    const isHttpsLink = locationPopupDetails?.Video?.data?.attributes?.url.startsWith('https://');
    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: isHttpsLink ? locationPopupDetails?.Video?.data?.attributes?.url : "https://" + locationPopupDetails?.Video?.data?.attributes?.url,
            type: 'video/mp4'
        }]
    };

    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    useEffect(() => {
        if (locationSelector?.status !== null && locationSelector?.status !== undefined && locationSelector?.method !== "delete" && locationSelector?.method !== null) {
            setIsRemoved(false);
            setIsSaved(true);
            setTimeCounter(1);
        } else if (locationSelector?.method !== null && locationSelector?.method !== undefined && locationSelector?.method === "delete" && (locationSelector?.method !== "post" || locationSelector?.method !== "put")) {
            setIsSaved(false)
            setIsRemoved(true);
            setTimeCounter(1);
        }
    }, [locationSelector])

    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.riskCategory && riskAreaSelector.riskCategory.length && id) {
            const riskCategoryObj = riskAreaSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === id)
            setSingleRiskCategory(riskCategoryObj);
            if (riskCategoryObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [riskAreaSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if ((role === "administrator") || (role === "editor")) {
            if (decodedUserInfo && singleRiskCategory) {
                dispatch(locationContainerCreators.requestGetProdLocationContent(decodedUserInfo, singleRiskCategory));
            }
        }
    }, [decodedUserInfo, singleRiskCategory]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector?.locationContent) {
            if (locationSelector?.locationContent?.data && locationSelector?.locationContent?.data[0] && isCancel !== '0') {
                setLocationPopupDetails(locationSelector?.locationContent?.data[0]?.attributes);
                setIsLocationModal(true);
            } else {
                setIsLocationModal(false);
            }
        } else {
            setIsLocationModal(false);
        }
    }, [locationSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        dispatch(locationContainerCreators.requestGetLocations());
    }, []);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (shouldRealoadAddresses) {
            dispatch(locationContainerCreators.requestGetLocations());
        }
    }, [shouldRealoadAddresses]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.locations && locationSelector.locations.length > 0) {
            const filteredLocations = locationSelector.locations.filter((ele: any) => ele.type !== 'hosting')
            setLocationsList(filteredLocations)
            const filteredHostingLocations = locationSelector.locations.filter((ele: any) => ele.type === 'hosting')
            setHostingLocationsList(filteredHostingLocations)
        }
    }, [locationSelector])


    // functions

    const removeLocation = (id: string) => {
        dispatch(locationContainerCreators.requestDeleteLocations(id));
    }

    function gotoAddLocation(locationDetail: Address | null) {
        navigate(`/locations/${id}/add-location`);
    }

    function gotoEditLocation(locationDetail: Address | null) {
        navigate(`/locations/${id}/edit-location/${locationDetail?.locationId}`);
    }

    function handleLocationModalClose() {
        setIsLocationModal(false);
        navigate(`/locations/${id}?isCancel=0`)
    }

    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });
        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    const goToNext = () => {
        navigate(`/hardware/${id}?isCancel=5`)
    }

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    const loadingIcon = <div className='app-loader'>
        <LoadingIcon />
    </div>

    return (
        <>
            {locationSelector.loading ?
                loadingIcon
                :
                <div>
                    <Container>
                        <div className="flex-grow-1 flex flex-col justify-between">
                            <div>
                                <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0 sm:pb-6'>
                                    <div className="flex items-center font-semibold text-xl">
                                        <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                        <div className="ml-3 font-semibold text-xl text-[#535353]">
                                            {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                        </div>
                                    </div>
                                    {isSaved &&
                                        <div className="font-normal text-xs text-[#959595] text-end">
                                            <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                        </div>
                                    }
                                    {isRemoved &&
                                        <div className="font-normal text-xs text-[#959595] text-end">
                                            <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                                        </div>
                                    }
                                </div>
                                {/* Navbar */}
                                <div className="w-full block mb-10" id="navbar-default">
                                    <ul className="flex sm:flex-wrap flex-row mt-[30px] text-xl font-semibold ">
                                        {singleRiskCategory?.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400' &&
                                            <>
                                                <li data-testid={`${testId}-navigate-location`} onClick={() => navigate(`/locations/${id}?isCancel=4`)}>
                                                    <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer ${isLocations && '!text-[#121212]'}`}>{intl.formatMessage({ id: 'locations', defaultMessage: 'locations' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-hardware`} onClick={() => navigate(`/hardware/${id}?isCancel=5`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'hardware', defaultMessage: 'hardware' })}</span>
                                                </li>
                                                <li data-testid={`${testId}navigate-connectivity`} onClick={() => navigate(`/connectivity/${id}?isCancel=6`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'connectivity', defaultMessage: 'connectivity' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-software`} onClick={() => navigate(`/software/${id}?isCancel=7`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'software', defaultMessage: 'software' })}</span>
                                                </li>
                                            </>
                                        }
                                        <li data-testid={`${testId}-navigate-events`} onClick={() => navigate(`/events/${id}?isCancel=8`)}>
                                            <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`}>{intl.formatMessage({ id: 'events', defaultMessage: 'events' })}</span>
                                        </li>
                                        <li data-testid={`${testId}-navigate-risk-areas`} onClick={() => navigate(`/risk-area/${id}?isCancel=9`)}>
                                            <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`} aria-current="page">{intl.formatMessage({ id: 'measure_groups', defaultMessage: 'measure_groups' })}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="border w-full"></div>
                                <div className="my-10 sm:my-8 max-w-[952px]">
                                    <div className='mt-8'>
                                        <div className='mt-10'>
                                            <div className="font-semibold text-[32px] mb-8 sm:text-2xl">{intl.formatMessage({ id: 'organization_step2_text_2', defaultMessage: 'organization_step2_text_2' })}</div>
                                            {/* <h3 className='text-3xl font-semibold'>{intl.formatMessage({ id: 'organization_step2_text_2' })}</h3> */}
                                            <p className='max-w-[39rem] text-xl text-[#535353] mt-5 font-family-arial' >
                                                {intl.formatMessage({ id: 'organization_step2_text', defaultMessage: 'organization_step2_text' })}
                                            </p>
                                        </div>
                                        <div className='mt-8 p-6 bg-white border rounded'>
                                            <p className='text-lg font-semibold text-[#121212] mb-6'>{intl.formatMessage({ id: 'locations', defaultMessage: 'locations' })}</p>
                                            {locationsList.length > 0 ?
                                                <div className='mb-4 border rounded-md bg-zinc-50'>
                                                    {locationsList.map((address: Address, index: number) => (
                                                        <div data-testid={`${testId}-location-list-${index}`} className={'relative pl-4 pr-12 py-3 font-semibold' + (index !== 0 ? " border-t " : "")} key={address?.locationId}>
                                                            {address.name}, {address.street} {address.houseNumber}{address.houseNumberSuffix}, {address.postalcode} {address.city}
                                                            {address?.type === 'hosting' &&
                                                                <>
                                                                    <span data-testid={`${testId}-remove-location`} className='absolute top-4 right-4 cursor-pointer' onClick={() => removeLocation(address.locationId ?? "")}>
                                                                    </span>
                                                                </>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className='mt-8 p-6 bg-white border rounded'>
                                            <p className='text-lg font-semibold text-[#121212] mb-6'>{intl.formatMessage({ id: 'hosting_locations', defaultMessage: 'hosting_locations' })}</p>
                                            {hostingLocationsList.length > 0 ?
                                                <div className='mb-4 border rounded-md bg-zinc-50'>
                                                    {hostingLocationsList.map((address: Address, index: number) => (
                                                        <div className={'relative pl-4 pr-12 py-3 font-semibold' + (index !== 0 ? " border-t " : "")} key={index}>
                                                            {address?.name}, {address.street} {address.houseNumber}{address.houseNumberSuffix}, {address.postalcode} {address.city}
                                                            {address?.type === 'hosting' &&
                                                                <>
                                                                    {((role === "administrator") || (role === "editor")) ?
                                                                        <>
                                                                            <span className='absolute top-4 right-4 cursor-pointer' onClick={() => removeLocation(address.locationId ?? "")}>
                                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                                        <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                            <span className='absolute cursor-pointer top-5 right-11' onClick={() => gotoEditLocation(address)}>
                                                                                <img src={EditIcon} alt="" />
                                                                            </span>
                                                                        </>
                                                                        :
                                                                        <span className='absolute cursor-pointer top-5 right-5' onClick={() => gotoAddLocation(address)}>
                                                                            <img className="w-[12px] cursor-pointer h-[12px]" src={InfoIcon} alt='' />
                                                                        </span>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                null
                                            }
                                            {((role === "administrator") || (role === "editor")) ?
                                                <div>
                                                    <StyledButton
                                                        testId={`${testId}-org-step2-text-btn`}
                                                        width={'w-60'}
                                                        align={'justify-start'}
                                                        text={`${intl.formatMessage({ id: 'organization_step2_text_button', defaultMessage: 'organization_step2_text_button' })}`}
                                                        color={'text-gray-700'}
                                                        flexReverse={'flex-row-reverse'}
                                                        iconType={'add'}
                                                        backgroundColor={'transparent border-1'}
                                                        onClick={() => gotoAddLocation(null)}
                                                    />
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                                <StyledButton
                                    testId={`${testId}-next-btn`}
                                    buttonClass={'PrimoryBtn'}
                                    width={'w-48 sm:w-40'}
                                    align={'justify-end'}
                                    text={intl.formatMessage({ id: 'next', defaultMessage: 'next' })}
                                    color={'text-white'}
                                    iconType={'arrow'}
                                    backgroundColor={'bg-black'}
                                    onClick={goToNext}
                                />
                            </div>
                        </div>
                    </Container>
                </div>
            }
            <Modal show={isLocationModal} onClose={() => handleLocationModalClose()}>
                <div data-testid={`${testId}-location-popup`} className={locationPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer">
                        {locationPopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {locationPopupDetails?.Header}
                    </div>
                    {locationPopupDetails?.Video?.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(locationPopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {locationPopupDetails?.Button2 && <StyledButton testId={`${testId}-location-popup-btn2`} onClick={() => locationPopupDetails?.Link2 ? navigate(locationPopupDetails?.Link2) : handleLocationModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={locationPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {locationPopupDetails?.Button && <StyledButton testId={`${testId}-location-popup-btn1`} onClick={() => locationPopupDetails?.Link ? navigate(locationPopupDetails?.Link) : handleLocationModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={locationPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>
            <Modal show={isWromgOrgModal} onClose={() => ""}>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                </div>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-goto-dashboard-bia`}
                        onClick={() => handleWrongOrgModel()}
                        buttonClass={'PrimoryBtn'}
                        width={"w-[220px] sm:w-40"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                        color={"text-white mr-3 text-base"}
                        iconType={'home'}
                        backgroundColor={"bg-black"}
                    />
                </div>
            </Modal>
        </>
    )
}
