/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { appContainerReducer } from './AppReducer';
import homeContainerReducer from './containers/DemoContainer/reducer';
import loginContainerReducer from './containers/LoginContainer/reducer';
import plansContainerReducer from './containers/PlansContainer/reducer';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import listOptionsContainerReducer from './containers/OrganisationContainer/reducer';
import dashboardContainerReducer from './containers/ProtectedContainer/dashboardReducer';
import subscriptionContainerReducer from './containers/SubscriptionContainer/subscriptionReducer';
import riskAreContainerReducer from './containers/ManageRiskAreasContainer/reducer';
import measureGroupsContainerReducer from './containers/MeasureGroupsContainer/measureGroupsReducer';
import addMeasureGroupContainerReducer from './containers/AddMeasureGroupContainer/addMeasureGroupReducer';
import taskContainerReducer from './containers/TasksContainer/taskReducer';
import locationContainerReducer from './containers/LocationsContainer/locationReducer';
import biaContainerReducer from './containers/BIAContainer/biaReducer';
import matrixContainerReducer from './containers/MatrixContainer/matrixReducer';
import resetPasswordReducer from './components/ResetPasswordLayout/reducer';
import createAccountReducer from './components/CreateAccount/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
const createReducer = (injectedReducer = {}) => {
  return combineReducers({
    ...injectedReducer,
    appContainer: appContainerReducer,
    homeContainer: homeContainerReducer,
    loginContainer: loginContainerReducer,
    plansContainer: plansContainerReducer,
    language: languageProviderReducer,
    listOptions: listOptionsContainerReducer,
    dashboardContainer: dashboardContainerReducer,
    subscriptionContainer: subscriptionContainerReducer,
    riskAreContainer: riskAreContainerReducer,
    measureGroupsContainer: measureGroupsContainerReducer,
    addMeasureGroupContainer: addMeasureGroupContainerReducer,
    taskContainer: taskContainerReducer,
    locationContainer: locationContainerReducer,
    biaContainer: biaContainerReducer,
    matrixContainer: matrixContainerReducer,
    resetPassContainer: resetPasswordReducer,
    createAccountContainer: createAccountReducer,
  });
}

export default createReducer;
