import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: biaContainerTypes, Creators: biaContainerCreators } = createActions({
    requestGetBiaContent: ['contentType'],
    successGetBiaContent: ['biaContent'],
    failureGetBiaContent: ['error'],

    // PROD BIA content
    requestGetProdBiaContent: ['contentType', 'BIAStatus', 'decodedUserInfo'],
    successGetProdBiaContent: ['biaContent'],
    failureGetProdBiaContent: ['error'],

    // BIA Help Text
    requestGetBiaHelpText: ['contentType', 'decodedUserInfo'],
    successGetBiaHelpText: ['biaHelpText'],
    failureGetBiaHelpText: ['error'],
});

export type InitialStateType = {
    biaContent: object | null;
    error: string | null;
    loading: boolean | null;
    biaHelpText: object | null;
}

export const initialState: InitialStateType = { biaContent: null, error: null, loading: null, biaHelpText: null };

export const biaContainerReducer = (state = initialState, action: any) =>
    produce(state, (draft) => {
        switch (action.type) {
            case biaContainerTypes.REQUEST_GET_BIA_CONTENT:
                draft.biaContent = null;
                draft.loading = true;
                draft.error = null;
                break;
            case biaContainerTypes.SUCCESS_GET_BIA_CONTENT:
                draft.biaContent = action.biaContent;
                draft.loading = false;
                draft.error = null;
                break;
            case biaContainerTypes.FAILURE_GET_BIA_CONTENT:
                draft.biaContent = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get PROD bia content
            case biaContainerTypes.REQUEST_GET_PROD_BIA_CONTENT:
                draft.biaContent = null;
                draft.loading = true;
                draft.error = null;
                break;
            case biaContainerTypes.SUCCESS_GET_PROD_BIA_CONTENT:
                draft.biaContent = action.biaContent;
                draft.loading = false;
                draft.error = null;
                break;
            case biaContainerTypes.FAILURE_GET_PROD_BIA_CONTENT:
                draft.biaContent = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get bia help text
            case biaContainerTypes.REQUEST_GET_BIA_HELP_TEXT:
                draft.biaHelpText = null;
                draft.loading = false;
                draft.error = null;
                break;
            case biaContainerTypes.SUCCESS_GET_BIA_HELP_TEXT:
                draft.biaHelpText = action.biaHelpText;
                draft.loading = false;
                draft.error = null;
                break;
            case biaContainerTypes.FAILURE_GET_BIA_HELP_TEXT:
                draft.biaHelpText = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            default:
                return state;
        }
    })
export default biaContainerReducer;
