import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from "../../components/StyledButton";
import EditIcon from '../../assets/icons/edit-icon.svg';
import Container from "../../components/Container/Container";
import { measureGroupsContainerCreators } from "../MeasureGroupsContainer/measureGroupsReducer";
import LoadingIcon from "../../assets/icons/loading-icon";
import { ContentType } from "@onesixeight-nl/classes";
import Modal from "../../components/Modal/Modal";
import videojs from 'video.js';
import VideoJS from "../../components/VideoJS/video";
import { selectDecodedUserInfo } from "../../AppSelector";
import GetIcons from "../../components/GetIcons/GetIcons";
import { riskAreaContainerCreators } from "../ManageRiskAreasContainer/reducer";
import { EventPopupDetails } from "../MeasureGroupsContainer";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";
import { clearMeasureGroupStatusCreators } from "../AddMeasureGroupContainer/addMeasureGroupReducer";

const testId = `event-container`;

type RelavantTypeArray = {
    id: string,
    name: string,
    status: string,
}

export type Events = {
    id: string;
    name: string;
    nameWhenOther: string;
    selected: number;
    relavantType: RelavantTypeArray[],
    eventListId: string;
    created: Date;
    eventId: string;
    modified: Date;
}

type EventsList = {
    eventListId: string;
    name: string;
}

type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
}


export default function EventsContainer() {
    // hooks
    const dispatch = useDispatch();
    const { pathname, search, state } = useLocation();
    const isCancel = new URLSearchParams(search).get('isCancel');
    const navigate = useNavigate();
    const params = useParams();
    const { id } = params;
    const intl = useIntl();
    const isEvents = pathname.includes(`/events/${id}`)
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const measureGroupsSelector = useSelector((state: RootState) => state.measureGroupsContainer);
    const role: string | undefined = decodedUserInfo?.payload?.role;

    // states
    const riskCategoryId: string | undefined = id;
    const [events, setEvents] = useState<Events[]>([]);
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>();
    // const [eventsList, seteventsList] = useState<EventsList[]>([]);
    const [isDeleteEventModal, setIsDeleteEventModal] = useState<boolean>(false)
    const [selectedEvent, setSelectedEvent] = useState<Events>()


    //Selector

    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const EventsSelector = useSelector((state: RootState) => state.measureGroupsContainer);
    const [isEVENTModal, setIsEVENTModal] = useState<boolean>(false);
    const [eventPopupDetails, setEventPopupDetails] = useState<EventPopupDetails>();
    const playerRef = React.useRef(null);
    const isHttpsLink = eventPopupDetails?.Video?.data?.attributes?.url.startsWith("https://")

    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: (isHttpsLink ? eventPopupDetails?.Video?.data?.attributes?.url : "https://" + eventPopupDetails?.Video?.data?.attributes?.url),
            type: 'video/mp4'
        }]
    };
    const { from }: RootState = state || {};
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    useEffect(() => {
        if (measureGroupsSelector && measureGroupsSelector.error !== null) {
            setIsWromgOrgModal(true)
        }
    }, [measureGroupsSelector.error])

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        if (EventsSelector?.status !== null && EventsSelector?.status !== undefined && EventsSelector?.method !== "delete" && EventsSelector?.method !== null) {
            setIsRemoved(false)
            setIsSaved(true);
            setTimeCounter(1);
            dispatch(clearMeasureGroupStatusCreators.clearMeasureGroupStatus());
        } else if (EventsSelector?.method !== undefined && EventsSelector?.method === "delete" && (EventsSelector?.method !== "post" || EventsSelector?.method !== "put")) {
            setIsSaved(false)
            setIsRemoved(true);
            setTimeCounter(1);
            dispatch(clearMeasureGroupStatusCreators.clearMeasureGroupStatus());
        }
    }, [EventsSelector.status, EventsSelector.method])

    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskCategoryId])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, singleRiskCategory));
    }, [riskCategoryId])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (EventsSelector.shouldRealoadEvents) {
            dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, '', isCancel, '', decodedUserInfo, singleRiskCategory));
        }
    }, [riskCategoryId, EventsSelector.shouldRealoadEvents])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (EventsSelector && EventsSelector?.events) {
            const updatedArray = EventsSelector.events.filter((ele: any) => ele?.eventListId && ele.eventId)
            const statusOfSelected: any = { 1: 1, 0: 2 };
            const sortedArr = updatedArray.sort(
                (a: Events, b: Events) => statusOfSelected[a.selected] - statusOfSelected[b.selected]
            );
            setEvents(sortedArr);
            // const eventsFilterList = EventsSelector.events.filter((ele: any) => !ele?.eventId);
            // seteventsList(eventsFilterList)
        }
    }, [EventsSelector])

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.riskCategory && riskAreaSelector.riskCategory.length && riskCategoryId) {

            const riskCategoryObj = riskAreaSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === riskCategoryId)
            setSingleRiskCategory(riskCategoryObj);
        }
    }, [riskAreaSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
       const isUndefineEvents = EventsSelector?.events?.length && EventsSelector?.events?.filter((ele: any) => ele?.selected === undefined || ele?.eventId === undefined);
       if (decodedUserInfo && singleRiskCategory && isUndefineEvents && isUndefineEvents?.length) {
           if ((role === "administrator") || (role === "editor")) {
               dispatch(measureGroupsContainerCreators.requestGetProdEventContent(ContentType.EVENTS, singleRiskCategory, decodedUserInfo));
           }
       }
    }, [decodedUserInfo, singleRiskCategory]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (EventsSelector && EventsSelector.eventContent) {
            if (EventsSelector.eventContent.data && EventsSelector.eventContent.data[0] && isCancel !== '0') {
                setEventPopupDetails(EventsSelector.eventContent.data[0].attributes);
                setIsEVENTModal(true);
            } else {
                setIsEVENTModal(false);
            }
        } else {
            setIsEVENTModal(false);
        }
    }, [EventsSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    // / functions

    function gotoAddEvents() {
        navigate(`/events/${riskCategoryId}/addevents`, { state: { eventsList: EventsSelector.events, singleRiskCategory: singleRiskCategory } })
    }

    function gotoEditEvents(me: Events) {
        navigate(`/events/${riskCategoryId}/editevents/${me?.eventId}`, { state: { eventsList: EventsSelector.events, singleRiskCategory: singleRiskCategory, editEventObj: me } })
    }

    function handleEventModalClose() {
        setIsEVENTModal(false);
        if (EventsSelector.events && EventsSelector.events.length && isCancel !== '0' && singleRiskCategory) {
            navigate(`/events/${id}/addevents`, { state: { eventsList: EventsSelector.events, singleRiskCategory: singleRiskCategory } });
        }
    }
    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });
        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    // delete Event modal function
    const deleteEventModelOpen = (me: Events, event?: any) => {
        setSelectedEvent(me)
        setIsDeleteEventModal(true)
    }

    const deleteCategory = () => {
        dispatch(measureGroupsContainerCreators.requestDeleteEvents(riskCategoryId, selectedEvent?.eventId))
        setIsDeleteEventModal(false)
    }

    function handleDeleteEventModalClose() {
        setIsDeleteEventModal(false)
    }

    const goToPrevious = () => {
        navigate(`/software/${id}?isCancel=7`)
    }

    const goToNext = () => {
        navigate(`/risk-area/${id}?isCancel=9`)
    }

    return (
        <>
            {EventsSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div>
                    <Container>
                        <div className="flex-grow-1 flex flex-col justify-between">
                            <div>
                                <div className='m-auto w-full pb-7 align-middle items-center flex justify-between'>
                                    <div className="flex items-center font-semibold text-xl">
                                        <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                        <div className="ml-3 font-semibold text-xl text-[#535353]">
                                            {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                        </div>
                                    </div>
                                    {isSaved &&
                                        <div className="font-normal text-xs text-[#959595] text-end">
                                            <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                        </div>
                                    }
                                    {isRemoved &&
                                        <div className="font-normal text-xs text-[#959595] text-end">
                                            <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                                        </div>
                                    }
                                </div>

                                {/* Navbar */}
                                <div className="mb-10 w-full block" id="navbar-default">
                                    <ul className="flex sm:flex-wrap flex-row mt-[30px] text-xl font-semibold ">
                                        {singleRiskCategory?.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400' &&
                                            <>
                                                <li data-testid={`${testId}-navigate-location`} onClick={() => navigate(`/locations/${id}?isCancel=4`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'locations', defaultMessage: 'locations' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-hardware`} onClick={() => navigate(`/hardware/${id}?isCancel=5`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'hardware', defaultMessage: 'hardware' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-connectivity`} onClick={() => navigate(`/connectivity/${id}?isCancel=6`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'connectivity', defaultMessage: 'connectivity' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-software`} onClick={() => navigate(`/software/${id}?isCancel=7`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'software', defaultMessage: 'software' })}</span>
                                                </li>
                                            </>
                                        }

                                        <li data-testid={`${testId}-event`} onClick={() => navigate(`/events/${id}?isCancel=8`)}>
                                            <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer ${isEvents && '!text-[#121212]'}`}>{intl.formatMessage({ id: 'events', defaultMessage: 'events' })}</span>
                                        </li>
                                        <li data-testid={`${testId}-mesure-grp`} onClick={() => navigate(`/risk-area/${id}?isCancel=9`, { state: { from: "event" } })}>
                                            <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`} aria-current="page">{intl.formatMessage({ id: 'measure_groups', defaultMessage: 'measure_groups' })}</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border w-full"></div>

                                <div className="my-10 sm:my-6">
                                    <div className="font-semibold text-[32px] mb-8 sm:text-2xl">{intl.formatMessage({ id: 'events', defaultMessage: 'events' })}</div>
                                    <div className="flex flex-col">
                                        {events.length > 0 ?
                                            <div>
                                                {events.map((me, index) => (
                                                    <div data-testid={`${testId}-mapping-events-${index}`} key={index} className="mb-4 p-6 bg-white border rounded-lg sm:w-full sm:p-3">
                                                        <div className="flex items-center">
                                                            <div className={`w-[6px] h-[6px] rounded-lg ${me?.selected ? 'bg-green-500' : 'bg-red-500'} mr-1`}></div>
                                                            <div className="font-bold text-[10px] text-[#959595] uppercase tracking-[1px] mr-1 font-family-arial">{me?.selected ? intl.formatMessage({ id: 'might_happen', defaultMessage: 'might_happen' }) : intl.formatMessage({ id: 'very_unlikely_or_impossible', defaultMessage: 'very_unlikely_or_impossible' })}</div>
                                                            {((role === "administrator") || (role === "editor")) ? <div className="flex items-center"> <img data-testid={`${testId}-edit-icon`} onClick={() => gotoEditEvents(me)} className="w-[10px] h-[10px] cursor-pointer" src={EditIcon} alt='' />
                                                                {me.eventListId === "00000000-0000-0000-0000-000000000000" &&
                                                                    <div data-testid={`${testId}-delete-event-popup-open`} className="flex w-[14px] h-[14px] ml-2 items-center justify-between cursor-pointer" onClick={(event) => deleteEventModelOpen(me, event)}>
                                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                                <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                }
                                                            </div> : <></>}
                                                        </div>
                                                        <div className={`flex items-center justify-between`}>
                                                            <div className="font-semibold text-xl sm:text-sm">{me?.name || me?.nameWhenOther}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className="flex items-center justify-center min-h-[30vh]">
                                            </div>
                                        }
                                        {((role === "administrator") || (role === "editor")) ? <div className="mt-4 sm:mt-2">
                                            <StyledButton testId={`${testId}-go-to-add-event-btn`} onClick={() => gotoAddEvents()} buttonClass={'border-[#535353]'} flexReverse={'flex-row-reverse'} width={'w-[232px] sm:w-full'} height={'h-12 sm:h-10'} align={'justify-start'} text={intl.formatMessage({ id: 'add_events', defaultMessage: 'add_events' })} color={'text-[#535353] font-bold'} iconType={'add'} backgroundColor={'bg-[#FAFAFA]'} />
                                        </div> : <></>}
                                    </div>
                                </div>
                            </div>
                            <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                                {singleRiskCategory?.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400' &&
                                    <StyledButton
                                        testId={`${testId}-previous-btn`}
                                        width={'w-48 sm:w-full'}
                                        align={'justify-start'}
                                        text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                        color={'text-gray-700'}
                                        flexReverse={'flex-row-reverse'}
                                        iconType={'arrowleft'}
                                        backgroundColor={'bg-gray-300'}
                                        onClick={goToPrevious}
                                    />
                                }
                                <StyledButton
                                    testId={`${testId}-next-btn`}
                                    buttonClass={'PrimoryBtn'}
                                    width={'w-48 sm:w-full'}
                                    align={'justify-end'}
                                    text={intl.formatMessage({ id: 'next', defaultMessage: 'next' })}
                                    color={'text-white'}
                                    iconType={'arrow'}
                                    backgroundColor={'bg-black'}
                                    onClick={goToNext}
                                />
                            </div>
                        </div>
                    </Container>
                </div>
            }
            <Modal show={isEVENTModal} onClose={() => handleEventModalClose()}>
                <div className={eventPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                        {eventPopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {eventPopupDetails?.Header}
                    </div>
                    {eventPopupDetails?.Video.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(eventPopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {eventPopupDetails?.Button2 && <StyledButton testId={`${testId}-popup-btn-one`} onClick={() => eventPopupDetails?.Link2 ? navigate(eventPopupDetails?.Link2) : handleEventModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={eventPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {eventPopupDetails?.Button && <StyledButton testId={`${testId}-popup-btn-two`} onClick={() => eventPopupDetails?.Link ? navigate(eventPopupDetails?.Link) : handleEventModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={eventPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>
            <div>
                <Modal data-testid={`${testId}-dlt-event-modal`} show={isDeleteEventModal} onClose={() => handleDeleteEventModalClose()}>
                    <div>{`${intl.formatMessage({ id: 'are_you_sure', defaultMessage: 'are_you_sure' })}`}</div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-cancel-delete-event`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => handleDeleteEventModalClose()}
                        />
                        <StyledButton
                            testId={`${testId}-delete-event`}
                            buttonClass={'PrimoryBtn'}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "delete", defaultMessage: 'delete' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => deleteCategory()}
                        />
                    </div>
                </Modal>
                <Modal show={isWromgOrgModal} onClose={() => ""}>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                    </div>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                    </div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-goto-dashboard`}
                            onClick={() => handleWrongOrgModel()}
                            buttonClass={'PrimoryBtn'}
                            width={"w-[220px] sm:w-40"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                            color={"text-white mr-3 text-base"}
                            iconType={'home'}
                            backgroundColor={"bg-black"}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
}