import { all, fork } from "redux-saga/effects";

import HomeContainerSaga from "./containers/DemoContainer/saga";
import LoginContainerSaga from "./containers/LoginContainer/saga";
import listOptionsContainerSaga from "./containers/OrganisationContainer/saga";
import PlansContainerSaga from "./containers/PlansContainer/saga";
import DashboardContainerSaga from "./containers/ProtectedContainer/dashboardSaga";
import SubscriptionContainerSaga from "./containers/SubscriptionContainer/subscriptionSaga";
import AppContainerSaga from "./AppSaga";
import RiskAreaContainerSaga from "./containers/ManageRiskAreasContainer/saga";
import MeasureGroupsContainerSaga from "./containers/MeasureGroupsContainer/measureGroupsSaga";
import AddMeasureGroupContainerSaga from "./containers/AddMeasureGroupContainer/addMeasureGroupSaga";
import TaskContainerSaga from "./containers/TasksContainer/taskSaga";
import LocationContainerSaga from "./containers/LocationsContainer/locationSaga";
import BiaContainerSaga from "./containers/BIAContainer/biaSaga";
import MatrixContainerSaga from "./containers/MatrixContainer/matrixSaga";
import resetPassContainerSaga from "./components/ResetPasswordLayout/saga";

export default function* rootSaga() {
  yield all([fork(HomeContainerSaga)]);
  yield all([fork(LoginContainerSaga)]);
  yield all([fork(PlansContainerSaga)]);
  yield all([fork(DashboardContainerSaga)]);
  yield all([fork(SubscriptionContainerSaga)]);
  yield all([fork(listOptionsContainerSaga)]);
  yield all([fork(AppContainerSaga)]);
  yield all([fork(RiskAreaContainerSaga)]);
  yield all([fork(MeasureGroupsContainerSaga)]);
  yield all([fork(AddMeasureGroupContainerSaga)]);
  yield all([fork(TaskContainerSaga)]);
  yield all([fork(LocationContainerSaga)]);
  yield all([fork(BiaContainerSaga)]);
  yield all([fork(MatrixContainerSaga)]);
  yield all([fork(resetPassContainerSaga)]);
}
