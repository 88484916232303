import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectDecodedUserInfo } from '../../AppSelector';
import LoadingIcon from '../../assets/icons/loading-icon';
import AddOrganization from '../../components/AddOrganization/AddOrganization';
import NavigationMenu from '../../components/NavigationMenu/NavigationMenu';
import StyledButton from '../../components/StyledButton';
import { dashboardContainerCreators } from '../ProtectedContainer/dashboardReducer';
import EditCurrentOrganisationPage from '../../components/CurrentOrganisationLayout/EditCurrentOrganisationPage';
import AddClient from '../../components/AddClient/AddClient';
import NotificationToast from '../../components/ToastComponent';
import Container from '../../components/Container/Container';

const testId = `organization-list-container`;

type Organization = {
    organizationId: string;
    companyName: string;
    chamberofcommercenr: string;
    street: string;
    houseNumber: number;
    houseNumberSuffix: null;
    postalcode: string;
    city: string;
    country: null;
    partnerId: null;
    created: Date;
    modified: Date;
}

export type Organisation = {
    organizationId: string;
    companyName: string;
    chamberofcommercenr: string;
    street: string;
    houseNumber: string;
    houseNumberSuffix: string;
    postalcode: string;
    city: string;
    country: string;
    type: string;
    created: Date;
    modified: Date;
}

export default function OrganizationListContainer() {

    // hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const dashboardSelector = useSelector((state: RootState) => state.dashboardContainer);
    const listOptionsSelector = useSelector((state: RootState) => state.listOptions);

    // states
    const [organizationList, setOrganizationList] = useState<Organization[]>([]);
    const [isAddOrganization, setIsAddOrganization] = useState<boolean>(false);
    const [isEditOrganisation, setIsEditOrganisation] = useState<boolean>(false);
    const [editOrganisation, setEditOrganisation] = useState<Organisation | null>(null);
    const [isAddClient, setIsAddClient] = useState<boolean>(false);
    const [showAddClientToast, setShowAddClientToast] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1)
    useEffect(() => {
      if (dashboardSelector?.status !== null && dashboardSelector?.status !== undefined) {
          setIsSaved(true);
          setTimeCounter(1);
      }
  }, [dashboardSelector?.status])
  /* istanbul ignore next */
  useEffect(() => {
      if (isSaved) {
          let intervalTimeCounter = setInterval(() => {
              setTimeCounter(timeCounter + 1);
              if (timeCounter === 3) {
                  setIsSaved(false)
                  clearInterval(intervalTimeCounter);
                  setTimeCounter(1);
              }
          }, 1000);
          return () => {
              clearInterval(intervalTimeCounter);
          };
      }
  }, [timeCounter, isSaved]);
  
    useEffect(() => {
        if (decodedUserInfo && decodedUserInfo.payload) {
            // get Org List
            dispatch(dashboardContainerCreators.requestGetOrgList(decodedUserInfo.payload.userId))
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedUserInfo])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (listOptionsSelector.isReloadOrgList) {
            // get Org List
            decodedUserInfo && dispatch(dashboardContainerCreators.requestGetOrgList(decodedUserInfo.payload.userId))
        }
    }, [listOptionsSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (dashboardSelector) {
            if (dashboardSelector.orgList && dashboardSelector.orgList.length > 0) {
                const orglist = [...dashboardSelector.orgList];
                const type2OrgList = orglist.filter((ele) => ele.type === 2).sort((a, b) => a.companyName.localeCompare(b.companyName));
                const type1OrgList = orglist.filter((ele) => ele.type === 1).sort((a, b) => a.companyName.localeCompare(b.companyName));
                const type0OrgList = orglist.filter((ele) => ele.type === 0).sort((a, b) => a.companyName.localeCompare(b.companyName));
                setOrganizationList([...type2OrgList, ...type1OrgList, ...type0OrgList]);
            }
        }
    }, [dashboardSelector])

    // functions
    function handleSelectOrganization(organizationId: string) {
        const payload = {
            organizationId: organizationId,
        }
        dispatch(dashboardContainerCreators.requestPostSwitchOrg(payload));
        localStorage.clear();
    }
        /* istanbul ignore next */
    function gotoAddOrganization() {
        setIsAddOrganization(true);
    }
        /* istanbul ignore next */
    function onCancelOrg() {
        setIsAddOrganization(false);
    }
/* istanbul ignore next */
    function onCancelUser() {
        setIsEditOrganisation(false)
        setEditOrganisation(null)
        if (decodedUserInfo && decodedUserInfo.payload) {
            // get Org List
            dispatch(dashboardContainerCreators.requestGetOrgList(decodedUserInfo.payload.userId))
        }
    }

    if (isAddOrganization) {
        return <AddOrganization testId={`${testId}-add-org`} onCancel={() => onCancelOrg()} />
    }
/* istanbul ignore next */
    if (isEditOrganisation && editOrganisation) {
        return <EditCurrentOrganisationPage testId={`${testId}-edit-current-org`} editOrganisation={editOrganisation} onCancel={() => onCancelUser()} />
    }

    function gotoAddClient() {
        setIsAddClient(true);
    }
/* istanbul ignore next */
    function onCancelAddClient(toast: boolean) {
        setIsAddClient(false);
        if (toast) {
            setShowAddClientToast(true);
            setTimeout(() => {
                setShowAddClientToast(false);
            }, 4000)
        }
    }
/* istanbul ignore next */
    if (isAddClient) {
        return <AddClient testId={`${testId}-ad-client`} onCancel={(toast) => onCancelAddClient(toast)} />
    }

    return (
        <>
            {dashboardSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div className='flex flex-col'>
                    <Container>
                    {isSaved ?
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                            </div>
                            :
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div className="h-4">{"  "}</div>
                            </div>
                        }
                        <NavigationMenu />
                        <div data-testid={`${testId}-org-list-container`} className='my-10 mt-10 font-semibold text-4xl flex items-start justify-start sm:text-3xl'>
                            {intl.formatMessage({ id: 'organizations', defaultMessage: 'organizations' })}
                        </div>
                        <div className='flex flex-col'>
                            {organizationList?.length > 0 ?
                                <div className='flex flex-col mb-4'>
                                    {organizationList.map((org, index) => (
                                        <div data-testid={`${testId}-org-list-${index}`} key={index} className={`flex flex-col`}>
                                            <div className='w-full min-h-[76px] mb-2 p-4 border rounded-lg bg-white'>
                                                <div className='flex h-[50px] items-center justify-between'>
                                                    <div className='font-semibold org-list-elip text-xl min-w-[500px] sm:text-base sm:min-w-full'>{org?.companyName}</div>
                                                    <div className="w-[100px] flex items-center justify-between">
                                                        {org.organizationId !== decodedUserInfo?.payload.orgId &&
                                                            <div className=''>
                                                                <StyledButton customClass='h-[40px]' testId={`${testId}-select-btn`} onClick={() => handleSelectOrganization(org.organizationId)} buttonClass={'PrimoryBtn'} flexReverse={'flex-row-reverse'} width={'w-[100px]'} height={'h-[40px]'} align={'justify-end'} text={intl.formatMessage({ id: 'select', defaultMessage: 'select' })} color={'text-white'} iconType={''} backgroundColor={'bg-black'} />
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className="flex items-center justify-center min-h-[60vh]">
                                    <div className="font-normal text-2xl text-[#535353]">{intl.formatMessage({ id: 'no_org_found', defaultMessage: 'no_org_found' })}</div>
                                </div>
                            }
                        </div>
                        <div className='flex items-center justify-between'>
                            {((role === "administrator") || (role === "editor")) && (decodedUserInfo?.payload.orgType === 0) &&
                                <div className="mt-4 sm:mt-2">
                                    <StyledButton testId={`${testId}-add-org-btn`} onClick={() => gotoAddOrganization()} buttonClass={'border-[#535353] px-4'} flexReverse={'flex-row-reverse'} width={'w-max sm:w-full'} height={'h-12 sm:h-10'} align={'justify-start'} text={intl.formatMessage({ id: 'add_organization', defaultMessage: 'add_organization' })} color={'text-[#535353] font-bold'} iconType={'add'} backgroundColor={'bg-[#FAFAFA]'} />
                                </div>
                            }
                            {(((role === "administrator") || (role === "editor")) && decodedUserInfo?.payload.orgType === 1) &&
                                <div className="mt-4 sm:mt-2 w-full bottom-0 align-middle items-center flex justify-between">
                                    <StyledButton testId={`${testId}-add-client-btn`} onClick={() => gotoAddClient()} buttonClass={'border-[#535353] px-4'} flexReverse={'flex-row-reverse'} width={'w-max sm:w-full'} height={'h-12 sm:h-10'} align={'justify-end'} text={intl.formatMessage({ id: 'add_client', defaultMessage: 'add_client' })} color={'text-[#535353] font-bold'} iconType={'add'} backgroundColor={'bg-[#FAFAFA]'} />
                                </div>
                            }
                            {showAddClientToast && <NotificationToast text={listOptionsSelector.createNewClientOrgError ? listOptionsSelector.createNewClientOrgError : intl.formatMessage({ id: 'client_added_successfully', defaultMessage: 'client_added_successfully' })} iconType={listOptionsSelector.createNewClientOrgError ? intl.formatMessage({ id: 'failure', defaultMessage: 'failure' }) : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} color={listOptionsSelector.createNewClientOrgError ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showAddClientToast} onClick={() => setShowAddClientToast(false)} />}
                        </div>
                    </Container>
                </div>
            }
        </>
    );
}