import Joi from "joi";

const password = Joi.string().max(60)
    .pattern(/^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^\w\s])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^\w\s])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s])).{8,60}$/).required().messages({ 'string.empty': 'password_require', "string.pattern.base": "in_correct_pattern", 'string.max': 'password_max_length_validation' }); /* eslint-disable-line */

const logInPass = Joi.string().max(60)
    .required().messages({ 'string.empty': 'password_require', 'string.max': 'password_max_length_validation' }); /* eslint-disable-line */

const email = Joi.string().required().max(254).email({ minDomainSegments: 2, tlds: false }).messages({ 'string.empty': 'email_require', 'string.max': 'email_max_length_validation' });

const firstName = Joi.string().max(100).required().messages({ 'string.empty': 'first_name_require', 'string.max': 'firstname_max_length_validation' });

const lastName = Joi.string().max(100).required().messages({ 'string.empty': 'last_name_require', 'string.max': 'lastname_max_length_validation' });

const phoneNumber = Joi.string().allow('').max(12)
    .pattern(new RegExp('^(?:0|\\+)[0-9\\s.\\/-]{6,20}$')).messages({ "string.pattern.base": "in_correct_pattern", 'string.max': 'phone_number_max_length_validation' });

const name = Joi.string().max(100).required().messages({ 'string.empty': 'name_require', 'string.max': 'name_max_length_validation', 'string.base': 'name_require' });

const kvKNumber = Joi.string().max(45).required().messages({ 'string.empty': 'kvk_number_require', 'string.max': 'kvk_number_length_validation' });

const companyName = Joi.string().max(100).required().messages({ 'string.empty': 'company_name_require', 'string.max': 'company_name_length_validation' });

const chamberofcommercenr = Joi.string().max(45).required().messages({ 'string.empty': 'coc_require', 'string.max': 'coc_length_validation' });

const street = Joi.string().max(100).required().messages({ 'string.empty': 'street_require', 'string.max': 'street_length_validation' });

const houseNumber = Joi.number().integer().min(1).max(999999999999999).required().messages({ 'number.empty': 'house_number_require', 'number.max': 'house_number_length_validation', 'number.base': 'house_number_require_msg' });

const houseNumberSuffix = Joi.string().max(10).required().messages({ 'string.empty': 'house_number_suffix_require', 'string.max': 'house_number_suffix_length_validation' });

const postalcode = Joi.string().max(7).required().messages({ 'string.empty': 'postal_code_require', 'string.max': 'postal_code_length_validation' });

const city = Joi.string().max(100).required().messages({ 'string.empty': 'city_require', 'string.max': 'city_length_validation' });

const country = Joi.string().max(20).required().messages({ 'string.empty': 'country_require', 'string.max': 'country_length_validation' });

const repeatPassword = Joi.string().max(60)
    .pattern(/^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^\w\s])|(?=.*?[A-Z])(?=.*?[\d])(?=.*?[^\w\s])|(?=.*?[a-z])(?=.*?[\d])(?=.*?[^\w\s])).{8,60}$/).required().messages({ 'string.empty': 'repeat_pass_require', "string.pattern.base": "in_correct_pattern", 'string.max': 'repeat_password_length_validation' });/* eslint-disable-line */  //NOSONAR 

const productName = Joi.string().min(1).max(100).required().messages({ 'string.empty': 'product_name_require', 'string.max': 'product_name_length_validation' });

const process = Joi.string().required().messages({ 'string.empty': 'process_validation' });

const processNameOther = Joi.string().required().messages({ 'string.empty': 'process_name_field_validation' });

const riskNameOther = Joi.string().max(100).required().messages({ 'string.empty': 'name_of_risk_area_require', 'string.max': 'risk_area_name_length_validation' });

const nameWhenOther = Joi.string().max(100).required().messages({ 'string.empty': 'name_of_measure_group_require', 'string.max': 'measure_group_name_length_validation' });

const eventNameWhenOther = Joi.string().max(100).required().messages({ 'string.empty': 'name_of_event_require', 'string.max': 'event_name_length_validation' });

const measureNameWhenOther = Joi.string().max(100).required().messages({ 'string.empty': 'name_of_measure_require', 'string.max': 'measure_name_length_validation' });

const measureDescription = Joi.string().required().messages({ 'string.empty': 'measure_description_validation' });

const measureDropDown = Joi.string().required().messages({ 'string.empty': 'responsible_measure_dropdown_validation' });

const reminderFrequencyDropDown = Joi.string().required().messages({ 'string.empty': 'reminder_frequency_dropdown_validation' });

const reminderDetails = Joi.string().required().messages({ 'string.empty': 'evidence_details_validation' });

const reminderNameWhenOther = Joi.string().max(100).required().messages({ 'string.empty': 'name_of_reminder_require', 'string.max': 'reminder_name_validation' });

const SoftwareNameWhenOther = Joi.string().max(100).required().messages({ 'string.empty': 'name_of_software_require', 'string.max': 'software_name_validation' });

const HardwareNameWhenOther = Joi.string().max(100).required().messages({ 'string.empty': 'name_of_hardware_require', 'string.max': 'hardware_name_validation' });

const connectivityNameWhenOther = Joi.string().max(100).required().messages({ 'string.empty': 'name_of_connectivity_require', 'string.max': 'connectivity_name_validation' });

const manageRiskAreasResponsible = Joi.string().required().messages({ 'string.empty': 'responsiblr_risk_area_validation' });

const addInviteRole = Joi.string().required().messages({ 'string.empty': 'role_of_invitr_user_validation' });

const addClientEmail = Joi.string().max(254)
    .email({ minDomainSegments: 2, tlds: { allow: false } }).messages({ 'string.max': 'add_client_email_length_validation' })

const SoftwareStorageList = Joi.string().required().messages({ 'string.empty': 'software_storage_validation' });

const SoftwareBackupList = Joi.string().required().messages({ 'string.empty': 'software_backup_validation' });

const Code = Joi.string().min(6).max(6).required().messages({ 'string.empty': 'code_requird',"string.max":'enter_valid_number',"string.min":'enter_valid_number' });

const stepName = Joi.string().max(100).required().messages({ 'string.empty': 'name_of_step_require', 'string.max': 'step_name_validation' });
/* eslint-disable-next-line */
export default {
    email,
    Code,
    password,
    logInPass,
    firstName,
    lastName,
    phoneNumber,
    name,
    kvKNumber,
    companyName,
    chamberofcommercenr,
    street,
    houseNumber,
    houseNumberSuffix,
    postalcode,
    city,
    country,
    repeatPassword,
    productName,
    process,
    processNameOther,
    riskNameOther,
    nameWhenOther,
    eventNameWhenOther,
    measureNameWhenOther,
    measureDescription,
    measureDropDown,
    reminderFrequencyDropDown,
    reminderDetails,
    reminderNameWhenOther,
    SoftwareNameWhenOther,
    HardwareNameWhenOther,
    connectivityNameWhenOther,
    manageRiskAreasResponsible,
    addInviteRole,
    addClientEmail,
    SoftwareStorageList,
    SoftwareBackupList,
    stepName
};
