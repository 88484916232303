import UsersLayout from '../../components/UsersLayout'

const testId = `user-container`;

export default function UsersContainer() {
  return (
    <div>
      <UsersLayout testId={testId} />
    </div>
  )
}

