import AddLocationContainer from "./containers/AddLocationContainer/AddLocationContainer";
import AddMeasureGroupContainer from "./containers/AddMeasureGroupContainer";
import AssessmentContainer from "./containers/AssessmentContainer";
import BiaContainer from "./containers/BIAContainer";
import EditBiaContainer from "./containers/BIAContainer/BIAEdit";
import EventsContainer from "./containers/EventsContainer";
import AddEvents from "./containers/EventsContainer/AddEvents";
import Add_Hardware from "./containers/HardwareContainer/AddHardware";
import Hardware from "./containers/HardwareContainer/Hardware";
import LocationContainer from "./containers/LocationsContainer/LocationContainer";
import LoginContainer from "./containers/LoginContainer";
import ManageRiskAreasContainer from "./containers/ManageRiskAreasContainer";
import MatrixContainer from "./containers/MatrixContainer";
import MeasureGroupsContainer from "./containers/MeasureGroupsContainer";
import MeasureRiskAreasContainer from "./containers/MeasureRiskAreasContainer";
import OrganisationContainer from "./containers/OrganisationContainer";
import OrganisationStep2Container from "./containers/OrganisationContainer/Step2";
import OrganisationStep3Container from "./containers/OrganisationContainer/Step3";
import OrganisationStep4Container from "./containers/OrganisationContainer/step4";
import PlansContainer from "./containers/PlansContainer";
import ProtectedContainer from "./containers/ProtectedContainer";
import RiskAreasContainer from "./containers/RiskAreasContainer";
import SubscriptionContainer from "./containers/SubscriptionContainer";
import TasksContainer from "./containers/TasksContainer";
import routeConstants from "./utils/routeConstants";
// import Softwere from "./containers/LocationsContainer/Softwere";
import SoftwareFirstScreen from "./containers/SoftwareContainer/SoftwareFirstScreen";
import Add_Softwere from "./containers/SoftwareContainer/Add_Software";
import UsersContainer from "./containers/UserContainer";
import AccountLayout from "./components/AccountLayout/AccountLayout";
import VerifyEmailContainer from "./containers/VerifyEmailContainer";
import UseInviteContainer from "./containers/UseInvite";
import OrganizationListContainer from "./containers/OrganizationListContainer";
import CurrentOrganizationContainer from "./containers/CurrentOrganisation";
import ActiveSubscriptionPage from "./containers/ProtectedContainer/ActiveSubscriptionPage";
import AddClient from "./components/AddClient/AddClient";
import ConnectivityContainer from "./containers/ConnectivityContainer/ConnectivityContainer";
import AddConnectivity from "./containers/ConnectivityContainer/AddConnectivity";
import CreateAccount from "./components/CreateAccount/CreateAccount";
import ResetPasswordLayout from "./components/ResetPasswordLayout/ResetPasswordLayout";
import NewVersion from "./containers/NewVersionContainer/NewVersion";
import TwoFa from "./containers/TwoFaPage";
import AuthTwoFa from "./containers/TwoFaPage/AuthTwoFa";
import AuthDone from "./containers/TwoFaPage/AuthDone";
import AddAddress from "./containers/OrganisationContainer/SubViews/AddAddress";
import AddProduct from "./containers/OrganisationContainer/SubViews/AddProduct";
import AddProcess from "./containers/OrganisationContainer/SubViews/AddProcess";

declare type RouteConfigType = {
  [key: string]: {
    component: (props?: any) => JSX.Element;
    route: string;
    isProtected: boolean;
  };
};

export const routeConfig: RouteConfigType = {
  home: {
    component: LoginContainer,
    ...routeConstants.home,
  },
  createAccount: {
    component: CreateAccount,
    ...routeConstants.createAccount,
  },
  plans: {
    component: PlansContainer,
    ...routeConstants.plans,
  },
  subscription: {
    component: SubscriptionContainer,
    ...routeConstants.subscription,
  },
  riskAreas: {
    component: RiskAreasContainer,
    ...routeConstants.riskAreas,
  },
  riskAreasEdit: {
    component: RiskAreasContainer,
    ...routeConstants.riskAreasEdit,
  },
  addMeasureRiskAreas: {
    component: MeasureRiskAreasContainer,
    ...routeConstants.addMeasureRiskAreas,
  },
  editMeasureRiskAreas: {
    component: MeasureRiskAreasContainer,
    ...routeConstants.editMeasureRiskAreas,
  },
  assessment: {
    component: AssessmentContainer,
    ...routeConstants.assessment,
  },
  manageRiskAreas: {
    component: ManageRiskAreasContainer,
    ...routeConstants.manageRiskAreas,
  },
  bia: {
    component: BiaContainer,
    ...routeConstants.bia,
  },
  editBia: {
    component: EditBiaContainer,
    ...routeConstants.editBia,
  },
  measureGroups: {
    component: MeasureGroupsContainer,
    ...routeConstants.measureGroups,
  },
  events: {
    component: EventsContainer,
    ...routeConstants.events,
  },
  addEvents: {
    component: AddEvents,
    ...routeConstants.addEvents,
  },
  editEvents: {
    component: AddEvents,
    ...routeConstants.editEvents,
  },
  addMeasureGroup: {
    component: AddMeasureGroupContainer,
    ...routeConstants.addMeasureGroup,
  },
  editMeasureGroup: {
    component: AddMeasureGroupContainer,
    ...routeConstants.editMeasureGroup,
  },
  locations: {
    component: LocationContainer,
    ...routeConstants.locations,
  },
  addLocation: {
    component: AddLocationContainer,
    ...routeConstants.addLocation,
  },
  editLocation: {
    component: AddLocationContainer,
    ...routeConstants.editLocation,
  },
  // softwareOverview: {
  //   component: Softwere,
  //   ...routeConstants.softwareOverview,
  // },
  addSoftware: {
    component: Add_Softwere,
    ...routeConstants.addSoftware,
  },
  editSoftware: {
    component: Add_Softwere,
    ...routeConstants.editSoftware,
  },
  software: {
    component: SoftwareFirstScreen,
    ...routeConstants.software,
  },
  dashboard: {
    component: ProtectedContainer,
    ...routeConstants.dashboard,
  },
  tasks: {
    component: TasksContainer,
    ...routeConstants.tasks,
  },
  matrix: {
    component: MatrixContainer,
    ...routeConstants.matrix,
  },
  organisation: {
    component: OrganisationContainer,
    ...routeConstants.organisation,
  },
  organisationStep2: {
    component: OrganisationStep2Container,
    ...routeConstants.organisationStep2,
  },
  addOrganisationStep2: {
    component: AddAddress,
    ...routeConstants.addOrganisationStep2,
  },
  editOrganisationStep2: {
    component: AddAddress,
    ...routeConstants.editOrganisationStep2,
  },
  organisationStep3: {
    component: OrganisationStep3Container,
    ...routeConstants.organisationStep3,
  },
  addOrganisationStep3: {
    component: AddProduct,
    ...routeConstants.addOrganisationStep3,
  },
  editOrganisationStep3: {
    component: AddProduct,
    ...routeConstants.editOrganisationStep3,
  },
  organisationStep4: {
    component: OrganisationStep4Container,
    ...routeConstants.organisationStep4,
  },
  addOrganisationStep4: {
    component: AddProcess,
    ...routeConstants.addOrganisationStep4,
  },
  editOrganisationStep4: {
    component: AddProcess,
    ...routeConstants.editOrganisationStep4,
  },
  hardware: {
    component: Hardware,
    ...routeConstants.hardware,
  },
  addhardware: {
    component: Add_Hardware,
    ...routeConstants.addhardware,
  },
  edithardware: {
    component: Add_Hardware,
    ...routeConstants.edithardware,
  },
  users: {
    component: UsersContainer,
    ...routeConstants.users,
  },
  accountlayout: {
    component: AccountLayout,
    ...routeConstants.accountlayout,
  },
  verifyEmail: {
    component: VerifyEmailContainer,
    ...routeConstants.verifyEmail,
  },
  useInvite: {
    component: UseInviteContainer,
    ...routeConstants.useInvite,
  },
  organizationList: {
    component: OrganizationListContainer,
    ...routeConstants.organizationList,
  },
  currentOrganization: {
    component: CurrentOrganizationContainer,
    ...routeConstants.currentOrganization,
  },
  activesubscription: {
    component: ActiveSubscriptionPage,
    ...routeConstants.activesubscription,
  },
  addClient: {
    component: AddClient,
    ...routeConstants.addClient,
  },
  editClient: {
    component: AddClient,
    ...routeConstants.editClient,
  },
  connectivity: {
    component: ConnectivityContainer,
    ...routeConstants.connectivity
  },
  addConnectivity: {
    component: AddConnectivity,
    ...routeConstants.addConnectivity
  },
  editConnectivity: {
    component: AddConnectivity,
    ...routeConstants.editConnectivity
  },
  changePassword: {
    component: ResetPasswordLayout,
    ...routeConstants.changePassword,
  },
  newVersion: {
    component: NewVersion,
    ...routeConstants.newVersion,
  },
  twoFa: {
    component: TwoFa,
    ...routeConstants.twoFa,
  },
  authDone: {
    component: AuthDone,
    ...routeConstants.authDone,
  },
  authentication: {
    component: AuthTwoFa,
    ...routeConstants.authentication,
  },
  //notFound: {
  //  component: ProtectedContainer,
  //  ...routeConstants.notFound,
  //}
};
