import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../components/StyledButton';
import AddAddress from './SubViews/AddAddress';
import { listOptionsContainerCreators } from './reducer';
import EditIcon from '../../assets/icons/edit-icon.svg';
import { selectDecodedUserInfo } from '../../AppSelector';
import Container from '../../components/Container/Container';
import { method } from 'lodash';

const testId = `step-2`;

export type Address = {
    [key: string]: any;
    type?: string;
    locationId?: string;
    street: string;
    houseNumber: number | null;
    houseNumberSuffix: string;
    postalcode: string;
    city: string;
    name?: string;
    availableConnectivityCount?: number | undefined;
    availableHardwareCount?: number | undefined;
    connectivityCount?: number | undefined;
    hardwareCount?: number | undefined;
}

export default function OrganisationStep2Container() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { addresses, shouldRealoadAddresses } = useSelector((state: RootState) => state.listOptions);
    const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
    const [showAddAddress, setShowAddAddress] = useState<boolean>(false);
    const [addressesList, setAddressesList] = useState([]);
    const [address, setAddress] = useState<Address | null>(null);
    const intl = useIntl();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload?.role;
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1)

    useEffect(() => {
        if (listOptionsSelector?.status !== null && listOptionsSelector?.status !== undefined && listOptionsSelector?.method !== "delete" && listOptionsSelector?.method !== null) {
            setIsRemoved(false)
            setIsSaved(true);
            setTimeCounter(1);
        } else if (listOptionsSelector?.method !== null && listOptionsSelector?.method !== undefined && listOptionsSelector?.method === "delete" && (listOptionsSelector?.method !== "post" || listOptionsSelector?.method !== "put")) {
            setIsSaved(false)
            setIsRemoved(true);
            setTimeCounter(1);
        }
    }, [listOptionsSelector])
/* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    const goToNext = () => {
        navigate("/organisation/step3", { state: { from: 'step2' } });
    }

    const removeAddress = (id: string) => {
        dispatch(listOptionsContainerCreators.requestDeleteAddress(id));
    }

    const editAddress = (addressDetail: Address | null) => {
        navigate(`/organisation/step2/edit-Location/${addressDetail?.locationId}`);  
    }

    const addAddress = (addressDetail: Address | null) => {
        navigate("/organisation/step2/add-location");  
    }

    useEffect(() => {
        dispatch(listOptionsContainerCreators.requestGetAddresses(dispatch, decodedUserInfo, intl));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (addresses && addresses.length >= 0) {
            const filteredAddresses = addresses.filter((ele: any) => ele.type !== 'hosting')
            setAddressesList(filteredAddresses)
        }
    }, [addresses])


    useEffect(() => {
        if (shouldRealoadAddresses) {
            dispatch(listOptionsContainerCreators.requestGetAddresses());
        }
    }, [shouldRealoadAddresses]);/* eslint-disable react-hooks/exhaustive-deps */

    function isDisableBtn() {
        if ((role === "administrator") || (role === "editor")) {
            if (!addressesList.length) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <div>
            <Container>
                <div className="flex-grow-1 flex flex-col justify-between">
                    <div>
                        <div className='m-auto w-full pb-7 align-middle items-center flex justify-between border-b sm:m-0 sm:pb-6'>
                            <div className="font-semibold text-xl">
                                <div>{intl.formatMessage({ id: 'organisation', defaultMessage: 'organisation' })}</div>
                            </div>
                            {isSaved &&
                                <div className="font-normal text-xs text-[#959595]">
                                    <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                </div>
                            }
                            {isRemoved &&
                                <div className="font-normal text-xs text-[#959595]">
                                    <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                                </div>
                            }
                        </div>
                        <div className="my-10 sm:my-8 max-w-[952px]">
                            <div className='mt-8'>
                                <div className='mt-10'>
                                    <h3 className='text-3xl font-semibold'>{intl.formatMessage({ id: 'organization_step2_text_2', defaultMessage: 'organization_step2_text_2' })}</h3>
                                    <p className='max-w-[39rem] text-xl text-[#535353] mt-5 font-family-arial' >
                                        {intl.formatMessage({ id: 'organization_step2_text_new', defaultMessage: 'organization_step2_text_new' })}
                                    </p>
                                </div>
                                <div className='mt-8 p-6 bg-white border rounded'>
                                    <p className='text-lg font-semibold text-[#121212] mb-6'>{intl.formatMessage({ id: 'addresses', defaultMessage: 'addresses' })}</p>
                                    {addressesList.length > 0
                                        ? <div className='mb-4 border rounded-md bg-zinc-50'>
                                            {addressesList.map((address: Address, index: number) => (
                                                <div data-testid={`${testId}-address-list-0`} className={'relative pl-4 pr-12 py-3 font-semibold' + (index !== 0 ? " border-t " : "")} key={`${testId}-address-list-${address.locationId}`}>
                                                    {address.name}, {address.street} {address.houseNumber}{address.houseNumberSuffix}, {address.postalcode} {address.city}
                                                    {((role === "administrator") || (role === "editor")) &&
                                                        <>
                                                            <span data-testid={`${testId}-remove-address`} className='absolute top-4 right-4  cursor-pointer' onClick={() => removeAddress(address.locationId ?? "")}>
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                        <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span data-testid={`${testId}-edit-address`} className='absolute cursor-pointer top-5 right-11' onClick={() => editAddress(address)}>
                                                                <img data-testid={`${testId}-edit-reminder-img`} src={EditIcon} alt="" />
                                                            </span>
                                                        </>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                        : null
                                    }
                                    {((role === "administrator") || (role === "editor")) &&
                                        <div>
                                            <StyledButton
                                                testId={`${testId}-org-step2-btn`}
                                                width={'w-60'}
                                                align={'justify-start'}
                                                text={`${intl.formatMessage({ id: 'organization_step2_text_button', defaultMessage: 'organization_step2_text_button' })}`}
                                                color={'text-gray-700'}
                                                flexReverse={'flex-row-reverse'}
                                                iconType={'add'}
                                                backgroundColor={'transparent border-1'}
                                                onClick={() => addAddress(null)}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                        <StyledButton
                            testId={`${testId}-previous-btn`}
                            width={'w-48 sm:w-full'}
                            align={'justify-start'}
                            text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                            color={'text-gray-700'}
                            flexReverse={'flex-row-reverse'}
                            iconType={'arrowleft'}
                            backgroundColor={'bg-gray-300'}
                            onClick={() => navigate("/organisation")}
                        />
                        <StyledButton
                            testId={`${testId}-next-btn`}
                            buttonClass={'PrimoryBtn'}
                            width={'w-48 sm:w-full'}
                            align={'justify-end'}
                            text={intl.formatMessage({ id: 'next', defaultMessage: 'next' })}
                            color={'text-white'}
                            iconType={'arrow'}
                            backgroundColor={isDisableBtn() ? 'bg-zinc-400' : 'bg-black'}
                            onClick={goToNext}
                            disabled={isDisableBtn()}
                        />
                    </div>
                </div>
            </Container>
        </div>
    )
}
