import { MatrixRiskCategoryType } from "../../containers/MatrixContainer";
import { useIntl } from "react-intl";
import Tooltip from "rc-tooltip";
import GetIcons from "../GetIcons/GetIcons";
import EditIcon from '../../assets/icons/edit-icon.svg';
import { useNavigate } from "react-router-dom";
import { selectDecodedUserInfo } from '../../AppSelector';
import { useSelector } from "react-redux";

type Props = {
    matrixRiskCategory: MatrixRiskCategoryType[];
    testId?: string;
    from?: string;
}

export default function CustomMatrixChart(props: Props) {

    const { matrixRiskCategory, testId = ``, from } = props;
    const intl = useIntl();
    const navigate = useNavigate();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;

    const yAxis = [
        {
            id: 5,
            label: intl.formatMessage({ id: 'very_well_arranged', defaultMessage: 'very_well_arranged' }),
        },
        {
            id: 4,
            label: intl.formatMessage({ id: 'well_arranged', defaultMessage: 'well_arranged' }),
        },
        {
            id: 3,
            label: intl.formatMessage({ id: 'arranged', defaultMessage: 'arranged' }),
        },
        {
            id: 2,
            label: intl.formatMessage({ id: 'slightly_arranged', defaultMessage: 'slightly_arranged' }),
        },
        {
            id: 1,
            label: intl.formatMessage({ id: 'nothing_arranged', defaultMessage: 'nothing_arranged' }),
        },
    ]

    function editAssesment(item: MatrixRiskCategoryType) {
        navigate(`/assessment/${item.riskCategoryId}`, { state: { from: from } })
    }

    const getData = (x: number, y: number) => {
        const mydata = matrixRiskCategory.filter((ele) => ele.riskFactor === x && ele.assesedStatus === y);
        if (mydata) {
            return <div className="flex flex-wrap items-start justify-between h-max font-bold text-sm text-black text-center px-2 py-1">
                {mydata.map((item, idx) => {
                    if (idx < 2) {
                        return (
                            <div key={item?.riskCategoryId}>
                                {(item?.name?.length >= 6 || item?.nameWhenOther?.length >= 6) ?
                                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>{`${item.name || item.nameWhenOther}`}</span>}>
                                        <div key={item?.riskCategoryId} className="bg-white flex items-center justify-center h-[32px] border border-[#C1C1C1] rounded-lg px-2 mb-1">
                                            <GetIcons item={item} height={"16"} width={"16"} />
                                            <div className="app-elip">{item.name || item.nameWhenOther}</div>
                                            {((role === "administrator") || (role === "editor")) &&
                                                <img onClick={() => editAssesment(item)} className='cursor-pointer ml-1' src={EditIcon} alt='' />
                                            }
                                        </div>
                                    </Tooltip>
                                    :
                                    <div key={item?.riskCategoryId} className="bg-white flex items-center justify-center h-[32px] border border-[#C1C1C1] rounded-lg px-2 mb-1">
                                        <GetIcons item={item} height={"16"} width={"16"} />
                                        <div className="app-elip">{item.name || item.nameWhenOther}</div>
                                        {((role === "administrator") || (role === "editor")) &&
                                            <img onClick={() => editAssesment(item)} className='cursor-pointer ml-1' src={EditIcon} alt='' />}
                                    </div>
                                }
                            </div>
                        )
                    }
                    return <></>;
                })}
                {mydata.length > 2 && <div className="bg-white flex items-center justify-center h-[32px] border border-[#C1C1C1] rounded-lg px-2">
                    {'+' + (mydata.length - 2)}
                </div>}
            </div>
        }
        return <></>
    }

    const getColor = (x: number, y: number) => {
        if (x === 5 && y === 1) {
            return "bg-[#FFEBEB]";
        } else if (x >= 4 && y <= 2) {
            return "bg-[#FFEBEB] bg-opacity-[0.5]"
        } else if (x >= 3 && y <= 3) {
            return "bg-[#FFF4EB] bg-opacity-[0.5]"
        }
        else if (x >= 2 && y <= 4) {
            return "bg-[#F0FAEA] bg-opacity-[0.5]"
        } else if (x >= 1 && y <= 5) {
            return "bg-[#F0FAEA]"
        }
        return "";
    }

    return (
        <>
            <div data-testid={`${testId}`} className="flex flex-col -ml-[111px] sm:ml-0">
                {yAxis.map((item) => (
                    <div key={item.id} className="flex">
                        <div className="flex items-center justify-end text-right font-normal text-sm text-[#959595] w-[116px] h-[116px] border-r border-r-[#C1C1C1]">
                            <div className="mr-2 font-family-arial">{item.label}</div>
                        </div>
                        <div className={`w-[116px] h-[116px] border border-[#EDEDED] ${getColor(1, item.id)}`}>
                            {getData(1, item.id)}
                        </div>
                        <div className={`w-[116px] h-[116px] border border-[#EDEDED] ${getColor(2, item.id)}`}>
                            {getData(2, item.id)}
                        </div>
                        <div className={`w-[116px] h-[116px] border border-[#EDEDED] ${getColor(3, item.id)}`}>
                            {getData(3, item.id)}
                        </div>
                        <div className={`w-[116px] h-[116px] border border-[#EDEDED] ${getColor(4, item.id)}`}>
                            {getData(4, item.id)}
                        </div>
                        <div className={`w-[116px] h-[116px] border border-[#EDEDED] ${getColor(5, item.id)}`}>
                            {getData(5, item.id)}
                        </div>
                    </div>
                ))}

                <div className="flex font-normal ml-[86px] text-sm text-[#959595]">
                    <div className="flex items-start justify-center text-center w-[30px] h-[30px] border-t border-t-[#C1C1C1] border-r border-r-[#C1C1C1]">
                    </div>
                    <div className="flex items-start justify-center text-center w-[116px] h-[116px] border-t border-t-[#C1C1C1]">
                        <div className="mt-2 font-family-arial">{intl.formatMessage({ id: 'low_risk', defaultMessage: 'low_risk' })}</div>
                    </div>
                    <div className="flex items-start justify-center text-center w-[116px] h-[116px] border-t border-t-[#C1C1C1]">
                        <div className="mt-2 font-family-arial">{intl.formatMessage({ id: 'limited', defaultMessage: 'limited' })}</div>
                    </div>
                    <div className="flex items-start justify-center text-center w-[116px] h-[116px] border-t border-t-[#C1C1C1]">
                        <div className="mt-2 font-family-arial">{intl.formatMessage({ id: 'medium', defaultMessage: 'medium' })}</div>
                    </div>
                    <div className="flex items-start justify-center text-center w-[116px] h-[116px] border-t border-t-[#C1C1C1]">
                        <div className="mt-2 font-family-arial">{intl.formatMessage({ id: 'important', defaultMessage: 'important' })}</div>
                    </div>
                    <div className="flex items-start justify-center text-center w-[116px] h-[116px] border-t border-t-[#C1C1C1]">
                        <div className="mt-2 font-family-arial">{intl.formatMessage({ id: 'crucial', defaultMessage: 'crucial' })}</div>
                    </div>
                </div>
            </div>
        </>
    );
}
