import { AxiosPromise } from "axios";
import axios from "./axios";

export const riskCategoryContentService = (contentType: string): AxiosPromise => axios.get(`/bucoscanservice/v1/content/${contentType}`)

export const addRiskCategoryDataService = (data: object): AxiosPromise =>
  axios.post(`/bucoscanservice/v1/riskcategories`, data);

export const getRiskCategoriesDataService = (): AxiosPromise =>
  axios.get(`/bucoscanservice/v1/riskcategories`);

export const getRiskCategoryValueDataService = (id: string): AxiosPromise =>
  axios.get(`/bucoscanservice/v1/riskcategories/${id}`);

  export const putRiskCategoryDataService = (id: string, data:object): AxiosPromise =>
  axios.put(`/bucoscanservice/v1/riskcategories/${id}`, data);

export const getListOptionsDataService = (id: string): AxiosPromise =>
  axios.get(`/bucoscanservice/v1/listoptions?typeId=` + id);

export const deleteRiskCategoryService =(riskCategoryId: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/riskcategories/${riskCategoryId}`);

export const setDataInLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key: string) => {
  const dataItems = localStorage.getItem(key);
  return dataItems && JSON.parse(dataItems);
};
