import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import { put, call, takeLatest } from "redux-saga/effects";
import { DefaultActionCreators } from "reduxsauce";
import {
  fileUploadCall,
  getEventsService,
  getMeasureGroupsService,
  getMeasuresService,
  postEventsService,
  putEventsService,
  postAddMeasureService,
  putAddMeasureService,
  getMeasureByIdService,
  deleteFileService,
  eventContentService,
  measureGroupContentService,
  deleteEventsService,
  deleteMeasureService,
  deleteMeasureGroupService,
  getEventByIdService,
  getMeasureGroupByIdService
} from "../../services/MeasureGroupsService";
import {
  measureGroupsContainerTypes,
  measureGroupsContainerCreators,
} from "./measureGroupsReducer";
import { ContentType, Statuses } from "@onesixeight-nl/classes";
import { getCmsURL } from "../../utils";
import { AllRiskCategoryListData } from "../../components/CentralizePopup/CentralizePopup";
import { clearMeasureGroupStatusCreators, disableMeasureGroupLoadingCreators } from "../AddMeasureGroupContainer/addMeasureGroupReducer";
import { redirectUrlCreators } from "../LoginContainer/reducer";

export const prodEventContentService = (roles: string, contentType: string, singleRiskCategory: any, rcStepStatus: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/popups?populate=Video&filters[Type][$eq]=${'EVENTS'}&filters[UserRole][${roles}][$eq]=true&filters[Status][$eq]=${rcStepStatus}&filters[RiskCategory][riskCategoryId][$eq]=${singleRiskCategory?.riskListId}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const prodMeasureGroupContentService = (roles: string, contentType: string, singleRiskCategory: any, rcStepStatus: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/popups?populate=Video&filters[Type][$eq]=${'MEASUREGROUPS'}&filters[UserRole][${roles}][$eq]=true&filters[Status][$eq]=${rcStepStatus}&filters[RiskCategory][riskCategoryId][$eq]=${singleRiskCategory?.riskListId}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const prodMeasuresContentService = (roles: string, contentType: string, status: string, selectedSingleMeasure: any, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/popups?populate=Video&filters[Type][$eq]=${'MEASURES'}&filters[UserRole][${roles}][$eq]=true&filters[Status][$eq]=${status}&filters[MeasureGroup][measureGroupId][$eq]=${selectedSingleMeasure?.measuregroupListId}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const eventsHelpTextService = (eventListId: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[Event][$eq]=${eventListId}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const measureGroupsHelpTextService = (measuregroupListId: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[MeasureGroup][$eq]=${measuregroupListId}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const measuresHelpTextService = (measureListId: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[Measure][$eq]=${measureListId}&locale=${decodedUserInfo?.payload?.prefLang}`)

type MeasureGroupsDataType = {
  id: string;
  navigate?: (path: string, state?: object) => void;
  isCancel: string;
  dispatch?: (action: DefaultActionCreators) => void;
  events?: object[];
  decodedUserInfo?: DecodedUserInfo | null;
  riskCategoryObj?: AllRiskCategoryListData;
};

type MeasureGroups = {
  availableMeasureCount: number;
  measureCount: number;
  measuregroupId: string;
  name: string;
  nameWhenOther: string;
  selected: boolean;
  measuresShow: boolean;
  subgroup: string;
}

type MeasuresDataType = {
  id: string;
  mgId: string;
  singleMeasureGroup: MeasureGroups;
  navigate?: (path: string, state?: object) => void;
  isCancel: string;
  decodedUserInfo: DecodedUserInfo | null;
  dispatch?: (action: DefaultActionCreators) => void;
  riskCategoryObj?: AllRiskCategoryListData;
};

type GetMeasuresByIdData = {
  id: string;
  mgId: string;
  mId: string;
}

type PostMeasuresDataType = {
  id: string;
  data: object;
  mgId: string;
  navigate?: (path: string, state?: object) => void;
  mgFromCancel?: object;
};

type PutMeasuresDataType = {
  id: string;
  data: object;
  navigate: (path: string, state?: object) => void;
  mgId: string;
  mId: string;
  from?: string;
  mgFromCancel?: object;
};

type EventsDataType = {
  id: string;
  navigate?: (path: string, state?: object) => void;
  isCancel: string;
  dispatch?: (action: DefaultActionCreators) => void;
  decodedUserInfo: DecodedUserInfo | null;
  riskCategoryObj?: AllRiskCategoryListData;
};

type PutEventsDataType = {
  id: string;
  data: object;
  navigate: (path: string, state?: object) => void;
  eId: string;
};

type PostEventsDataType = {
  id: string;
  data: object;
  navigate?: (path: string, state?: object) => void;
}

type PostFilesType = {
  data: object;
  fileId: string;
  dispatch: (action: DefaultActionCreators) => void;
  setProgressValue: React.Dispatch<React.SetStateAction<number>>;
};

type DeleteFilesType = {
  fileId: string;
};

type GetEventContentData = {
  contentType: string;
  filterId: string
}

type GetEventsHelpTextData = {
  eventListId: string;
  decodedUserInfo: DecodedUserInfo | null;
}

type GetMeasureGroupsHelpTextData = {
  measuregroupListId: string;
  decodedUserInfo: DecodedUserInfo | null;
}

type GetMeasuresHelpTextData = {
  measureListId: string;
  decodedUserInfo: DecodedUserInfo | null;
}

type DeleteMeasuregroup = {
  riskCategoryId: string;
  measuregroupId: string;
}

type GetProdEventContentData = {
  contentType: string;
  singleRiskCategory: any;
  decodedUserInfo: DecodedUserInfo | null;
}

type GetMeasureGroupContentData = {
  contentType: string;
  filterId: string
}

type GetProdMeasureGroupContentData = {
  contentType: string;
  singleRiskCategory: any;
  decodedUserInfo: DecodedUserInfo | null;
}

type GetProdMeasuresContentData = {
  contentType: string;
  status: string;
  selectedSingleMeasure: any;
  decodedUserInfo: DecodedUserInfo | null;
}

type DeleteEvents = {
  riskCategoryId: string;
  eventId: string;
}

type DeleteMeasure = {
  riskCategoryId: string;
  measureGroupId: string;
  measureId: string;
}

type GetEventByIdData = {
  id: string;
  eventId: string;
}

type GetMeasureGroupByIdData = {
  id: string;
  mgId: string;
}

const { REQUEST_GET_MEASURE_GROUPS, REQUEST_GET_MEASURES, REQUEST_GET_EVENTS, REQUEST_POST_EVENTS, REQUEST_PUT_EVENTS, REQUEST_POST_MEASURES, REQUEST_PUT_MEASURES, REQUEST_GET_MEASURE_BY_ID
  , REQUEST_POST_FILES, REQUEST_DELETE_FILES, REQUEST_GET_EVENT_CONTENT, REQUEST_GET_MEASURE_GROUP_CONTENT, REQUEST_GET_PROD_EVENT_CONTENT, REQUEST_GET_PROD_MEASURE_GROUP_CONTENT, REQUEST_GET_PROD_MEASURES_CONTENT,
  REQUEST_GET_EVENTS_HELP_TEXT, REQUEST_GET_MEASURE_GROUPS_HELP_TEXT, REQUEST_GET_MEASURES_HELP_TEXT, REQUEST_DELETE_EVENTS, REQUEST_DELETE_MEASURE_GROUP, REQUEST_DELETE_MEASURES,
  REQUEST_GET_EVENT_BY_ID, REQUEST_GET_MEASURE_GROUP_BY_ID } =
  measureGroupsContainerTypes;

/* istanbul ignore next */
export function* getMeasureGroupsData(data: MeasureGroupsDataType) {
  try {
    const response: AxiosResponse = yield call(
      getMeasureGroupsService,
      data.id
    );
    const singleRiskCategory = data?.riskCategoryObj;
    if (response && response.data && singleRiskCategory) {
      const eventsLength = data.events && data.events.length;
      const measuregroupsLength = response.data.length;
      const isUndefineMeasureGroups = response.data?.filter((ele: any) => ele?.selected === undefined || ele?.measuregroupId === undefined);
      const role: string | undefined = data?.decodedUserInfo?.payload.role;
      if (isUndefineMeasureGroups && isUndefineMeasureGroups.length && data.isCancel !== '0') {
        if ((role === "administrator") || (role === "editor")) {
          data.dispatch && data.dispatch(measureGroupsContainerCreators.requestGetProdMeasureGroupContent(ContentType.MEASUREGROUPS, singleRiskCategory, data.decodedUserInfo));
        }
      }
      else {
        const isAvailableUndefineMeasure = response.data?.filter((ele: any) => ele?.availableMeasureCount !== undefined && ele?.availableMeasureCount > 0 && ele.selected === true);
        const singleMeasureGroup = response.data?.find((ele: any) => ele?.availableMeasureCount > 0 && ele.selected === true);
        if (singleMeasureGroup && data.isCancel !== '0' && isAvailableUndefineMeasure) {
          if ((role === "administrator") || (role === "editor")) {
            data.dispatch && data.dispatch(measureGroupsContainerCreators.requestGetMeasures(data.id, singleMeasureGroup.measuregroupId, singleMeasureGroup, data.navigate, data.isCancel, data.decodedUserInfo, data.dispatch, isAvailableUndefineMeasure));
          }
        } else if (data.isCancel !== '0' && isAvailableUndefineMeasure.length === 0 && singleRiskCategory.status !== 3) {
          if (singleRiskCategory.riskListId === '00000000-0000-0000-0000-000000000000') {
            if (eventsLength && eventsLength > 0 && measuregroupsLength > 0) {
              data.navigate && data.navigate(`/assessment/${data.id}`);
            }
          } else {
            data.navigate && data.navigate(`/assessment/${data.id}`);
          }
        } else if (data.isCancel !== '0' && isAvailableUndefineMeasure.length === 0 && singleRiskCategory.status === 3) {
          data.navigate && data.navigate(`/risk-area/${data.id}`, { state: { singleRiskCategory: singleRiskCategory } });
        }
      }
    }
    yield put(clearMeasureGroupStatusCreators.clearMeasureGroupStatus());
    yield put(
      disableMeasureGroupLoadingCreators.disableMeasureGroupLoading()
    );
    yield put(
      measureGroupsContainerCreators.successGetMeasureGroups(response.data)
    );
    yield put(redirectUrlCreators.clearRedirectUrl())
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failureGetMeasureGroups(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failureGetMeasureGroups(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* getMeasuresData(data: MeasuresDataType) {
  try {
    const response: AxiosResponse = yield call(
      getMeasuresService,
      data.id, data.mgId
    );
    const singleRiskCategory = data?.riskCategoryObj;
    if (response && response.data && singleRiskCategory) {
      const isUndefineMeasures = response.data.filter((ele: any) => ele?.selected === undefined || ele?.measureId === undefined);

      if (isUndefineMeasures && isUndefineMeasures.length && data.isCancel !== '0' && data.navigate) {
        if (data?.singleMeasureGroup?.measureCount === 0 && data?.singleMeasureGroup?.availableMeasureCount > 0) {
          data?.dispatch && data?.dispatch(measureGroupsContainerCreators.requestGetProdMeasuresContent(ContentType.MEASURES, 'TODO', data.singleMeasureGroup, data.decodedUserInfo));
        } else if (data?.singleMeasureGroup?.measureCount > 0 && data?.singleMeasureGroup?.availableMeasureCount > 0) {
          data?.dispatch && data?.dispatch(measureGroupsContainerCreators.requestGetProdMeasuresContent(ContentType.MEASURES, 'DOING', data.singleMeasureGroup, data.decodedUserInfo));
        } else if (data?.singleMeasureGroup?.measureCount > 0 && data?.singleMeasureGroup?.availableMeasureCount === 0) {
          data?.dispatch && data?.dispatch(measureGroupsContainerCreators.requestGetProdMeasuresContent(ContentType.MEASURES, 'DONE', data.singleMeasureGroup, data.decodedUserInfo));
        }
      }
    }
    yield put(
      measureGroupsContainerCreators.successGetMeasures(response.data)
    );
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failureGetMeasures(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failureGetMeasures(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* getEventsData(data: EventsDataType) {
  try {
    const response: AxiosResponse = yield call(getEventsService, data.id);
    const singleRiskCategory = data?.riskCategoryObj;
    if (response && response.data && singleRiskCategory) {
      const isUndefineEvents = response.data.filter((ele: any) => ele?.selected === undefined || ele?.eventId === undefined);
      const role: string | undefined = data?.decodedUserInfo?.payload.role;
      if (isUndefineEvents && isUndefineEvents.length && data.isCancel !== '0') {
        if ((role === "administrator") || (role === "editor")) {
          data.dispatch && data.dispatch(measureGroupsContainerCreators.requestGetProdEventContent(ContentType.EVENTS, singleRiskCategory, data.decodedUserInfo));
        }
      } else {
        if ((role === "administrator") || (role === "editor")) {
          data.dispatch && data.dispatch(measureGroupsContainerCreators.requestGetMeasureGroups(singleRiskCategory.riskCategoryId, data.navigate, data.isCancel, data.dispatch, response.data, data.decodedUserInfo, singleRiskCategory));
        }
      }
    }
    yield put(clearMeasureGroupStatusCreators.clearMeasureGroupStatus());
    yield put(measureGroupsContainerCreators.successGetEvents(response.data));
    yield put(redirectUrlCreators.clearRedirectUrl())
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failureGetEvents(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failureGetEvents(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* postEventsData(data: PostEventsDataType) {
  try {
    const response: AxiosResponse = yield call(postEventsService, data.id, data.data);
    if (response && data.navigate) {
      data.navigate(`/events/${data.id}?isCancel=0`);
    }
    yield put(measureGroupsContainerCreators.successPostEvents(response.data, response.status, response.config.method));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failurePostEvents(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failurePostEvents(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* putEventsData(data: PutEventsDataType) {
  try {
    const response: AxiosResponse = yield call(putEventsService, data.id, data.data, data.eId);
    if (response && data.navigate) {
      data.navigate(`/events/${data.id}?isCancel=0`);
    }
    yield put(measureGroupsContainerCreators.successPutEvents(response.data, response.status, response.config.method));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failurePutEvents(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failurePutEvents(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* postMeasuresData(data: PostMeasuresDataType) {
  try {
    const response: AxiosResponse = yield call(postAddMeasureService, data.id, data.data, data.mgId);
    if (response && data.navigate && data.mgFromCancel) {
      data.navigate(`/risk-area/${data.id}?isCancel=0`, { state: { mgFromCancel: data.mgFromCancel } });
    }
    yield put(measureGroupsContainerCreators.successPostMeasures(response?.headers?.measureid, response.status, response.config.method, response.data));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failurePostMeasures(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failurePostMeasures(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* putMeasuresData(data: PutMeasuresDataType) {
  try {
    const response: AxiosResponse = yield call(putAddMeasureService, data.id, data.data, data.mgId, data.mId);
    if (data.from === "task") {
      data.navigate(`/tasks`);
    } else if (data.from === "dashboard") {
      data.navigate(`/dashboard?isCancel=0`);
    } else if (data.from === "manageRiskArea") {
      data.navigate(`/manage-risk-areas`);
    } else {
      response && data.navigate && data.navigate(`/risk-area/${data.id}?isCancel=0`, { state: { mgFromCancel: data.mgFromCancel } });
    }
    // data.navigate(`/risk-area/${data.id}`);
    yield put(measureGroupsContainerCreators.successPutMeasures(response.data, response.status, response.config.method));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failurePutMeasures(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failurePutMeasures(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* getMeasuresByIdData(data: GetMeasuresByIdData) {
  try {
    const response: AxiosResponse = yield call(
      getMeasureByIdService,
      data.id, data.mgId, data.mId
    );
    yield put(
      measureGroupsContainerCreators.successGetMeasureById(response.data)
    );
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failureGetMeasureById(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failureGetMeasureById(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* postFilesData(data: PostFilesType) {
  try {
    const response: AxiosResponse = yield call(fileUploadCall, data);
    yield put(measureGroupsContainerCreators.successPostFiles(response.data));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failurePostFiles(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failurePostFiles(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* deleteFilesData(data: DeleteFilesType) {
  try {
    const response: AxiosResponse = yield call(deleteFileService, data.fileId);
    yield put(measureGroupsContainerCreators.successDeleteFiles(response.data));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failureDeleteFiles(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failureDeleteFiles(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* getEventContentData(data: GetEventContentData) {
  try {
    const response: AxiosResponse = yield call(eventContentService, data.contentType, data.filterId);
    yield put(measureGroupsContainerCreators.successGetEventContent(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureGetEventContent(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureGetEventContent("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getMeasureGroupContentData(data: GetMeasureGroupContentData) {
  try {
    const response: AxiosResponse = yield call(measureGroupContentService, data.contentType, data.filterId);
    yield put(measureGroupsContainerCreators.successGetMeasureGroupContent(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureGetMeasureGroupContent(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureGetMeasureGroupContent("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getProdEventContentData(data: GetProdEventContentData) {
  const role = data.decodedUserInfo?.payload.role;
  const getRoles = () => {
    if (role === 'administrator') {
      return 'Administrator'
    } else if (role === 'editor') {
      return 'Editor'
    } else if (role === 'reader') {
      return 'Reader'
    } else if (role === 'accountant') {
      return 'Accountant'
    } else if (role === 'insureanceadvisor') {
      return 'InsureanceAdvisor'
    } else if (role === 'partneraccountant') {
      return 'PartnerAccountant'
    } else if (role === 'partnerinsureanceadvisor') {
      return 'PartnerInsureanceAdvisor'
    } else {
      return ''
    }
  }
  const currentStep: number = 4;
  const rcStepStatus = () => {
    if (data.singleRiskCategory?.step) {
      if (currentStep < data.singleRiskCategory?.step) {
        return 'DONE';
      } else if (currentStep > data.singleRiskCategory?.step) {
        return 'TODO';
      } else {
        const currentStatus = Statuses[data.singleRiskCategory?.stepStatus];
        return currentStatus;
      }
    } else {
      const currentStatus = Statuses[data.singleRiskCategory?.status];
      return currentStatus;
    }
  }
  try {
    const response: AxiosResponse = yield call(prodEventContentService, getRoles(), data.contentType, data.singleRiskCategory, rcStepStatus(), data.decodedUserInfo);
    yield put(measureGroupsContainerCreators.successGetProdEventContent(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureGetProdEventContent(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureGetProdEventContent("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getProdMeasureGroupContentData(data: GetProdMeasureGroupContentData) {
  const role = data.decodedUserInfo?.payload.role;
  const getRoles = () => {
    if (role === 'administrator') {
      return 'Administrator'
    } else if (role === 'editor') {
      return 'Editor'
    } else if (role === 'reader') {
      return 'Reader'
    } else if (role === 'accountant') {
      return 'Accountant'
    } else if (role === 'insureanceadvisor') {
      return 'InsureanceAdvisor'
    } else if (role === 'partneraccountant') {
      return 'PartnerAccountant'
    } else if (role === 'partnerinsureanceadvisor') {
      return 'PartnerInsureanceAdvisor'
    } else {
      return ''
    }
  }
  const currentStep: number = 5;
  const rcStepStatus = () => {
    if (data.singleRiskCategory?.step) {
      if (currentStep < data.singleRiskCategory?.step) {
        return 'DONE';
      } else if (currentStep > data.singleRiskCategory?.step) {
        return 'TODO';
      } else {
        const currentStatus = Statuses[data.singleRiskCategory?.stepStatus];
        return currentStatus;
      }
    } else {
      const currentStatus = Statuses[data.singleRiskCategory?.status];
      return currentStatus;
    }
  }
  try {
    const response: AxiosResponse = yield call(prodMeasureGroupContentService, getRoles(), data.contentType, data.singleRiskCategory, rcStepStatus(), data.decodedUserInfo);
    yield put(measureGroupsContainerCreators.successGetProdMeasureGroupContent(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureGetProdMeasureGroupContent(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureGetProdMeasureGroupContent("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getProdMeasuresContentData(data: GetProdMeasuresContentData) {
  const role = data.decodedUserInfo?.payload.role;
  const getRoles = () => {
    if (role === 'administrator') {
      return 'Administrator'
    } else if (role === 'editor') {
      return 'Editor'
    } else if (role === 'reader') {
      return 'Reader'
    } else if (role === 'accountant') {
      return 'Accountant'
    } else if (role === 'insureanceadvisor') {
      return 'InsureanceAdvisor'
    } else if (role === 'partneraccountant') {
      return 'PartnerAccountant'
    } else if (role === 'partnerinsureanceadvisor') {
      return 'PartnerInsureanceAdvisor'
    } else {
      return ''
    }
  }
  try {
    const response: AxiosResponse = yield call(prodMeasuresContentService, getRoles(), data.contentType, data.status, data.selectedSingleMeasure, data.decodedUserInfo);
    yield put(measureGroupsContainerCreators.successGetProdMeasuresContent(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureGetProdMeasuresContent(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureGetProdMeasuresContent("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getEventsHelpTextData(data: GetEventsHelpTextData) {
  try {
    const response: AxiosResponse = yield call(eventsHelpTextService, data.eventListId, data.decodedUserInfo);
    yield put(measureGroupsContainerCreators.successGetEventsHelpText(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureGetEventsHelpText(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureGetEventsHelpText("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getMeasureGroupsHelpTextData(data: GetMeasureGroupsHelpTextData) {
  try {
    const response: AxiosResponse = yield call(measureGroupsHelpTextService, data.measuregroupListId, data.decodedUserInfo);
    yield put(measureGroupsContainerCreators.successGetMeasureGroupsHelpText(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureGetMeasureGroupsHelpText(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureGetMeasureGroupsHelpText("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getMeasuresHelpTextData(data: GetMeasuresHelpTextData) {
  try {
    const response: AxiosResponse = yield call(measuresHelpTextService, data.measureListId, data.decodedUserInfo);
    yield put(measureGroupsContainerCreators.successGetMeasuresHelpText(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureGetMeasuresHelpText(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureGetMeasuresHelpText("Something went wrong, please try again later!"));
    }
  }
}

//DELETE EVENTS
/* istanbul ignore next */
export function* deleteEvents(data: DeleteEvents) {
  try {
    const response: AxiosResponse = yield call(deleteEventsService, data.riskCategoryId, data.eventId);
    yield put(measureGroupsContainerCreators.successDeleteEvents(response.data, response.config.method))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureDeleteEvents(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureDeleteEvents("Something went wrong, please try again later!"));
    }
  }
}

//DELETE MEASURE
/* istanbul ignore next */
export function* deleteMeasure(data: DeleteMeasure) {
  try {
    const response: AxiosResponse = yield call(deleteMeasureService, data.riskCategoryId, data.measureGroupId, data.measureId);
    yield put(measureGroupsContainerCreators.successDeleteMeasures(response.data, response.config.method))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureDeleteMeasures(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureDeleteMeasures("Something went wrong, please try again later!"));
    }
  }
}

//DELETE MEASUREGROUP
/* istanbul ignore next */
export function* deleteMeasureGroup(data: DeleteMeasuregroup) {
  try {
    const response: AxiosResponse = yield call(deleteMeasureGroupService, data.riskCategoryId, data.measuregroupId);
    yield put(measureGroupsContainerCreators.successDeleteMeasureGroup(response.data, response.config.method))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(measureGroupsContainerCreators.failureDeleteMeasureGroup(e.response?.data.errors[0].message));
    } else {
      yield put(measureGroupsContainerCreators.failureDeleteMeasureGroup("Something went wrong, please try again later!"));
    }
  }
}
/* istanbul ignore next */
export function* getEventByIdData(data: GetEventByIdData) {
  try {
    const response: AxiosResponse = yield call(getEventByIdService, data.id, data.eventId);
    yield put(measureGroupsContainerCreators.successGetEventById(response.data));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failureGetEventById(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failureGetEventById(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}
/* istanbul ignore next */
export function* getMeasureGroupByIdData(data: GetMeasureGroupByIdData) {
  try {
    const response: AxiosResponse = yield call(getMeasureGroupByIdService, data.id, data.mgId);
    yield put(measureGroupsContainerCreators.successGetMeasureGroupById(response.data));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        measureGroupsContainerCreators.failureGetMeasureGroupById(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        measureGroupsContainerCreators.failureGetMeasureGroupById(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export default function* measureGroupsContainerSaga() {
  // @ts-ignore
  yield takeLatest(REQUEST_GET_MEASURE_GROUPS, getMeasureGroupsData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_MEASURES, getMeasuresData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_EVENTS, getEventsData);
  // @ts-ignore
  yield takeLatest(REQUEST_POST_EVENTS, postEventsData);
  // @ts-ignore
  yield takeLatest(REQUEST_PUT_EVENTS, putEventsData);
  // @ts-ignore
  yield takeLatest(REQUEST_POST_MEASURES, postMeasuresData);
  // @ts-ignore
  yield takeLatest(REQUEST_PUT_MEASURES, putMeasuresData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_MEASURE_BY_ID, getMeasuresByIdData);
  // @ts-ignore
  yield takeLatest(REQUEST_POST_FILES, postFilesData);
  // @ts-ignore
  yield takeLatest(REQUEST_DELETE_FILES, deleteFilesData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_EVENT_CONTENT, getEventContentData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_MEASURE_GROUP_CONTENT, getMeasureGroupContentData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_PROD_EVENT_CONTENT, getProdEventContentData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_PROD_MEASURE_GROUP_CONTENT, getProdMeasureGroupContentData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_PROD_MEASURES_CONTENT, getProdMeasuresContentData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_EVENTS_HELP_TEXT, getEventsHelpTextData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_MEASURE_GROUPS_HELP_TEXT, getMeasureGroupsHelpTextData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_MEASURES_HELP_TEXT, getMeasuresHelpTextData);
  // @ts-ignore
  yield takeLatest(REQUEST_DELETE_EVENTS, deleteEvents);
  // @ts-ignore
  yield takeLatest(REQUEST_DELETE_MEASURES, deleteMeasure);
  // @ts-ignore
  yield takeLatest(REQUEST_DELETE_MEASURE_GROUP, deleteMeasureGroup);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_EVENT_BY_ID, getEventByIdData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_MEASURE_GROUP_BY_ID, getMeasureGroupByIdData);
}
