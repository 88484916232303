export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getAppConfig = () => {
    return (window as any).appConfig;
}

export const getBaseURL = () => {
    return (window as any).appConfig?.baseUrl;
}

export const getCmsURL = () => {
    return (window as any).appConfig.cmsBaseUrl;
}