import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from '../../components/StyledButton';
import EditIcon from "../../assets/icons/edit-icon.svg"
import { useIntl } from 'react-intl';
import LoadingIcon from '../../assets/icons/loading-icon';
import { clearLocationStatusCreators, locationContainerCreators } from '../LocationsContainer/locationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { selectDecodedUserInfo } from '../../AppSelector';
import Modal from '../../components/Modal/Modal';
import VideoJS from '../../components/VideoJS/video';
import videojs from 'video.js';
import Container from '../../components/Container/Container';
import GetIcons from '../../components/GetIcons/GetIcons';
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";
import InfoIcon from "../../assets/icons/info-i-icon.svg";
import CloseIcon from "../../assets/icons/close-icon.svg";
import { hardwareBtn, hardwareDetailsBtn, hardwareDetailsBtnWrap, locationDetails } from '../../utils/HardwareUtilsFolder/HardwareUtils';

const testId = `hardware`;

export type Address = {
    [key: string]: any;
    type?: string;
    street: string;
    locationId?: string;
    houseNumber: number | null;
    postalcode: string;
    houseNumberSuffix: string;
    name?: string;
    city: string;
}

export type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    riskCategoryId: string;
    nameWhenOther: string | null;
    riskListId: string | null;
    riskFactor: number | null;
    status: number;
    selected: number | null;
}

export type Singlelocations = {
    nameWhenOther: string;
    city: string;
    country: null;
    created: string;
    houseNumber: number;
    houseNumberSuffix: string;
    locationId: string;
    modified: string;
    name: string;
    organizationId: string;
    postalcode: string;
    street: string;
    type: string;
}

export type HardwaresList = {
    hardwareId: string;
    hardwareListId: string;
    name: string;
    nameWhenOther: string;
    selected: boolean;
    maintainHardware: number;
    maintainOS: number;
    inSupport: number;
    virusScanner: number;
    replacements: number;
    created: Date | string;
    modified: Date | string;
    locationId: string;
    organizationId: string;
}

type HardwarePopupVideoAttributes = {
    name: string;
    alternativeText: string;
    caption: string;
    width: null;
    height: null;
    formats: null;
    hash: string;
    ext: string;
    mime: string;
    size: string;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: null;
    createdAt: Date;
    updatedAt: Date;
}

type HardwarePopupVideo = {
    data: {
        id: number;
        attributes: HardwarePopupVideoAttributes;
    }
}

type HardwarePopupDetails = {
    Intro: string;
    Header: string;
    Body: string;
    Button: string;
    Button2: string;
    Link: string;
    Link2: string;
    Video: HardwarePopupVideo;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
}

export default function Hardware() {
    // hoooks
    const intl = useIntl();
    const params = useParams();
    const { pathname, search, state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = params;
    const isHardware = pathname.includes(`/hardware/${id}`)
    const locationSelector = useSelector((state: RootState) => state.locationContainer);
    const riskAreasSelector = useSelector((state: RootState) => state.riskAreContainer);
    const hardwareSelector = useSelector((state: RootState) => state.locationContainer);
    const isCancel = new URLSearchParams(search).get('isCancel');
    const { showPopup }: RootState = state || {}
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;

    // states 
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>();
    const [singlelocations, setSinglelocations] = useState<Singlelocations>();
    const [hardwareCount, setHardwareCount] = useState();
    const [locationsList, setLocationsList] = useState<Singlelocations[]>([]);
    const [hardwaresList, setHardwaresList] = useState<HardwaresList[]>([]);
    //const [hardwareQuestions, setHardwareQuestions] = useState([]);
    const [isDeleteHardwareModal, setIsDeleteHardwareModal] = useState<boolean>(false);
    const [selectedHardware, setSelectedHardware] = useState<HardwaresList>();

    const [isHardwareModal, setIsHardwareModal] = useState<boolean>(false);
    const [hardwarePopupDetails, setHardwarePopupDetails] = useState<HardwarePopupDetails>();

    const playerRef = React.useRef(null);
    const isHttpsLink = hardwarePopupDetails?.Video?.data?.attributes?.url.startsWith('https://');
    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: isHttpsLink ? hardwarePopupDetails?.Video?.data?.attributes?.url : "https://" + hardwarePopupDetails?.Video?.data?.attributes?.url,
            type: 'video/mp4'
        }]
    };

    const userRoles = ((role === "administrator") || (role === "editor"));
    const { from }: RootState = state || {};
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1)
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        if (locationSelector?.status !== null && locationSelector?.status !== undefined && locationSelector?.method !== "delete" && locationSelector?.method !== null) {
            setIsRemoved(false)
            setIsSaved(true);
            setTimeCounter(1);
        } else if (locationSelector?.method !== null && locationSelector?.method !== undefined && locationSelector?.method === "delete" && (locationSelector?.method !== "post" || locationSelector?.method !== "put")) {
            setIsSaved(false)
            setIsRemoved(true);
            setTimeCounter(1);
            dispatch(clearLocationStatusCreators.clearLocationStatus());
        }
    }, [locationSelector?.status, locationSelector?.method])

    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    dispatch(clearLocationStatusCreators.clearLocationStatus());
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreasSelector && riskAreasSelector.riskCategory && riskAreasSelector.riskCategory.length && id) {
            const riskCategoryObj = riskAreasSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === id)
            setSingleRiskCategory(riskCategoryObj);
            if (riskCategoryObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [riskAreasSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (singleRiskCategory) {
            dispatch(locationContainerCreators.requestGetLocations(decodedUserInfo, dispatch, singleRiskCategory, isCancel, navigate, 'Hardware', showPopup));
        }
    }, [singleRiskCategory]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.locations && locationSelector.locations.length > 0) {
            setLocationsList(locationSelector.locations)
            const availableHardwareCount = locationSelector?.locations.find((ele: any) => ele.availableHardwareCount > 0)
            setHardwareCount(availableHardwareCount)
        }
        /* istanbul ignore next */
        if (locationSelector && locationSelector?.hardwareContent) {
            if (locationSelector?.hardwareContent?.data && locationSelector?.hardwareContent?.data[0] && isCancel !== '0') {
                setHardwarePopupDetails(locationSelector?.hardwareContent?.data[0]?.attributes);
                setIsHardwareModal(true);
            } else {
                setIsHardwareModal(false);
                if (hardwareCount && isCancel !== '0') {
                    dispatch(locationContainerCreators.requestGetHardwares(hardwareCount, singleRiskCategory, isCancel, navigate));
                }
            }
        } else {
            setIsHardwareModal(false);
        }
    }, [locationSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (singlelocations) {
            dispatch(locationContainerCreators.requestGetHardwares(singlelocations, singleRiskCategory, isCancel, ''));
        }
    }, [singlelocations]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (hardwareSelector && hardwareSelector.hardwares && hardwareSelector.hardwares.length > 0) {
            const filteredHardwares = hardwareSelector.hardwares.filter((ele: any) => ele.hardwareId)
            setHardwaresList(filteredHardwares)
            //const filteredUndefinedHardwares = hardwareSelector.hardwares.filter((ele: any) => !ele.hardwareId)
            //setHardwareQuestions(filteredUndefinedHardwares)
        } else {
            setHardwaresList([])
            //setHardwareQuestions([])
        }
    }, [hardwareSelector])

    // function
    function gotoEditHardware(hardwareObj: HardwaresList) {
        navigate(`/hardware/${id}/edithardware/${singlelocations?.locationId}/${hardwareObj?.hardwareId}`, { state: { hardwareQuestions: hardwareSelector.hardwares, selectedLocations: singlelocations, editHardwareObj: hardwareObj } });
    }
    /* istanbul ignore next */
    function gotoAddHardware() {
        navigate(`/hardware/${id}/addhardware/${singlelocations?.locationId}`, { state: { hardwareQuestions: hardwareSelector.hardwares, selectedLocations: singlelocations } });
    }

    function handleLocationShow(locationId: string) {

        if (locationId === singlelocations?.locationId) {
            setSinglelocations(undefined)
        } else {
            const locationNewList: Singlelocations[] = [...locationsList];
            const singleMeasure = locationNewList.find((ele: Singlelocations) => ele.locationId === locationId)
            if (singleMeasure) {
                setSinglelocations(singleMeasure);
            }
        }
    }
    /* istanbul ignore next */
    function handleHardwareModalClose() {
        setIsHardwareModal(false);
    }
    /* istanbul ignore next */
    function handleHardwareAdd() {
        if (hardwareCount && isCancel !== '0') {
            dispatch(locationContainerCreators.requestGetHardwares(hardwareCount, singleRiskCategory, isCancel, navigate));
        }
        setIsHardwareModal(false);
    }
    /* istanbul ignore next */
    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });
        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    function getOverviewValues(value: number) {
        if (value === 1) {
            return intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })
        } else if (value === 2) {
            return intl.formatMessage({ id: 'no', defaultMessage: 'no' })
        } else if (value === 3) {
            return intl.formatMessage({ id: 'don’t_know', defaultMessage: 'don’t_know' })
        } else if (value === 4) {
            return intl.formatMessage({ id: 'NA', defaultMessage: 'NA' })
        } else {
            return "";
        }
    }

    const goToPrevious = () => {
        navigate(`/locations/${id}?isCancel=4`)
    }

    const goToNext = () => {
        navigate(`/connectivity/${id}?isCancel=6`)
    }

    function handleDeleteHardwareModalOpen(item: HardwaresList) {
        setSelectedHardware(item);
        setIsDeleteHardwareModal(true);
    }

    function handleDeleteHardwareModalClose() {
        setIsDeleteHardwareModal(false);
    }

    const deleteHardwareItem = () => {
        dispatch(locationContainerCreators.requestDeleteHardware(singlelocations?.locationId, selectedHardware?.hardwareId))
        setSinglelocations(undefined);
        setSelectedHardware(undefined);
        setIsDeleteHardwareModal(false);
    }
    /* istanbul ignore next */
    return (
        <>
            {locationSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div>
                    <Container>
                        <div className='m-auto pb-7 w-full align-middle items-center flex justify-between sm:m-0 sm:pb-6'>
                            <div className="flex text-xl items-center font-semibold">
                                <GetIcons height={"40"} width={"40"} item={singleRiskCategory} />
                                <div className="font-semibold ml-3 text-xl text-[#535353]">
                                    {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                </div>
                            </div>
                            {isSaved &&
                                <div className="font-normal text-xs text-[#959595] text-end">
                                    <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                </div>
                            }
                            {isRemoved &&
                                <div className="font-normal text-xs text-[#959595] text-end">
                                    <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                                </div>
                            }
                        </div>
                        {/* Navbar */}
                        <div className="w-full block mb-10" id="navbar-default">
                            <ul className="flex flex-row mt-[30px] text-xl font-semibold sm:flex-wrap">
                                {singleRiskCategory?.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400' &&
                                    <>
                                        <li data-testid={`${testId}-navigate-location`} onClick={() => navigate(`/locations/${id}?isCancel=4`)}>
                                            <span className="min-w-max mr-10 block text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'locations', defaultMessage: 'locations' })}</span>
                                        </li>
                                        <li data-testid={`${testId}-navigate-hardware`} onClick={() => navigate(`/hardware/${id}?isCancel=5`)}>
                                            <span className={`min-w-max mr-10 block  text-[#959595] hover:text-[#535353] cursor-pointer ${hardwareBtn(isHardware)}`}>{intl.formatMessage({ id: 'hardware', defaultMessage: 'hardware' })}</span>
                                        </li>
                                        <li data-testid={`${testId}navigate-connectivity`} onClick={() => navigate(`/connectivity/${id}?isCancel=6`)}>
                                            <span className="min-w-max mr-10 block text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'connectivity', defaultMessage: 'connectivity' })}</span>
                                        </li>
                                        <li data-testid={`${testId}-navigate-software`} onClick={() => navigate(`/software/${id}?isCancel=7`)}>
                                            <span className="min-w-max mr-10 block text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'software', defaultMessage: 'software' })}</span>
                                        </li>
                                    </>
                                }

                                <li data-testid={`${testId}-navigate-events`} onClick={() => navigate(`/events/${id}?isCancel=8`)}>
                                    <span className={`min-w-max block text-[#959595] mr-10 hover:text-[#535353] cursor-pointer`}>{intl.formatMessage({ id: 'events', defaultMessage: 'events' })}</span>
                                </li>
                                <li data-testid={`${testId}-navigate-risk-areas`} onClick={() => navigate(`/risk-area/${id}?isCancel=9`)}>
                                    <span className={`min-w-max block text-[#959595] mr-10 hover:text-[#535353] cursor-pointer`} aria-current="page">{intl.formatMessage({ id: 'measure_groups', defaultMessage: 'measure_groups' })}</span>
                                </li>
                            </ul>
                        </div>

                        <div className="border w-full"></div>

                        <div className='my-10 sm:my-8 max-w[952px]'>
                            <div className='mt-8'>
                                <div className='mt-10'>
                                    <div className="font-bold text-[32px] mb-[16px] sm:text-2xl">{intl.formatMessage({ id: 'organization_step2_text_hard', defaultMessage: 'organization_step2_text_hard' })}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {locationsList.length > 0 &&
                                <div>
                                    {locationsList.map((location, idx) => {
                                        const singlelocationsId = (singlelocations && location.locationId === singlelocations.locationId);
                                        return (
                                            <div data-testid={`${testId}-location-list-${idx}`} key={location?.locationId} className='w-full bg-white border rounded-lg mb-4 p-6 sm:overflow-x-auto'>
                                                <div className='flex items-center justify-between'>
                                                    <div className={`app-elip-hsc text-xl sm:text-sm font-semibold text-black cursor-pointer ${locationDetails(singlelocationsId)}`} onClick={() => handleLocationShow(location.locationId)}>
                                                        {location?.name || location?.nameWhenOther || location.street || location.city || location.country}
                                                    </div>
                                                    <div data-testid={`${testId}-location-show`} onClick={() => handleLocationShow(location.locationId)} className={`flex items-center border rounded-full cursor-pointer ${hardwareDetailsBtnWrap(singlelocationsId)} px-4 py-1 sm:px-2 sm:py-1`}>
                                                        <div className={`font-bold font-family-arial text-sm sm:text-[11px] ${hardwareDetailsBtn(singlelocationsId)} mr-2`}>{intl.formatMessage({ id: 'hardware_detail', defaultMessage: 'hardware_detail' })}</div>
                                                        {singlelocationsId ?
                                                            <img data-testid={`${testId}-close-icon`} src={CloseIcon} alt='' />
                                                            :
                                                            <img data-testid={`${testId}-info-icon`} src={InfoIcon} alt='' />
                                                        }
                                                    </div>
                                                </div>
                                                {singlelocationsId &&
                                                    <>
                                                        <table>
                                                            <thead>
                                                                {hardwaresList.length > 0 &&
                                                                    <tr className='flex items-center min-w-[812px] mt-10'>
                                                                        <td className='w-[116px] min-h-[25px]'></td>
                                                                        <td className="w-[116px] min-h-[25px] flex flex-wrap items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] font-family-arial">{intl.formatMessage({ id: 'maintain', defaultMessage: 'maintain' })}</td>
                                                                        <td className="w-[116px] min-h-[25px] flex flex-wrap items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] font-family-arial">{intl.formatMessage({ id: 'upgrade_patch', defaultMessage: 'upgrade_patch' })}</td>
                                                                        <td className="w-[116px] min-h-[25px] flex flex-wrap items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] font-family-arial">{intl.formatMessage({ id: 'Support', defaultMessage: 'Support' })}</td>
                                                                        <td className="w-[116px] min-h-[25px] flex flex-wrap items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] font-family-arial">{intl.formatMessage({ id: 'virus_scanners', defaultMessage: 'virus_scanners' })}</td>
                                                                        <td className="w-[116px] min-h-[25px] flex flex-wrap items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] font-family-arial">{intl.formatMessage({ id: 'lifeCycle', defaultMessage: 'lifeCycle' })}</td>
                                                                        <td className='w-[116px] min-h-[25px]'></td>
                                                                    </tr>
                                                                }
                                                            </thead>
                                                            <tbody>
                                                                {hardwaresList?.map((item, index) => (
                                                                    item.selected === true &&
                                                                    <tr data-testid={`${testId}-hardware-list-${index}`} key={item?.hardwareId} className='border-1 h-[100px] min-w-[812px] flex rounded-lg mt-[10px]'>
                                                                        <td className='border uppercase font-bold tracking-[1px] h-[80px] w-[116px] rounded-l-lg justify-center bg-white flex items-center text-[10px] text-[#959595] text-center font-family-arial'>
                                                                            {item?.name || item?.nameWhenOther}
                                                                        </td>
                                                                        <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FFF9F0] flex items-center text-[10px] text-[#959595] text-center "'>
                                                                            <div data-testid={`${testId}-maintain-hardware`} className='"app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-bold bg-white border-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center font-family-arial' >

                                                                                {getOverviewValues(item.maintainHardware)}
                                                                            </div>
                                                                        </td>
                                                                        <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FFF9F0] flex items-center text-[10px] text-[#959595] text-center "'>
                                                                            <div data-testid={`${testId}-maintain-os`} className='"app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-bold bg-white border-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center font-family-arial' >
                                                                                {getOverviewValues(item.maintainOS)}
                                                                            </div>
                                                                        </td>
                                                                        <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FFF9F0] flex items-center text-[10px] text-[#959595] text-center "'>
                                                                            <div data-testid={`${testId}-maintain-in-support`} className='"app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-bold bg-white border-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center font-family-arial' >
                                                                                {getOverviewValues(item.inSupport)}
                                                                            </div>
                                                                        </td>
                                                                        <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FFF9F0] flex items-center text-[10px] text-[#959595] text-center "'>
                                                                            <div data-testid={`${testId}-maintain-virus-scanner`} className='"app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-bold bg-white border-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center font-family-arial' >
                                                                                {getOverviewValues(item.virusScanner)}
                                                                            </div>
                                                                        </td>
                                                                        <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FFF9F0] flex items-center text-[10px] text-[#959595] text-center "'>
                                                                            <div className='"app-elip min-w-max max-w-[64px] border-[1px] text-[#121212] font-bold bg-white border-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center font-family-arial' >
                                                                                {getOverviewValues(item.replacements)}
                                                                            </div>
                                                                        </td>
                                                                        {userRoles ?
                                                                            <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center rounded-r-lg bg-white flex items-center text-[10px] text-[#959595] text-center'>
                                                                                <img data-testid={`${testId}-edit-hardware`} onClick={() => gotoEditHardware(item)} className="w-[12px] cursor-pointer h-[12px]" src={EditIcon} alt="" />
                                                                                {item.hardwareListId === "00000000-0000-0000-0000-000000000000" &&
                                                                                    <div data-testid={`${testId}-dlt-hardware-modal-open`} className="w-max flex ml-2 items-center justify-between cursor-pointer" onClick={() => handleDeleteHardwareModalOpen(item)}>
                                                                                        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                                                <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                            :
                                                                            <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center rounded-r-lg bg-white flex items-center text-[10px] text-[#959595] text-center'>
                                                                                <img data-testid={`${testId}-without-user-role-edit-hardware`} onClick={() => gotoEditHardware(item)} className="w-[12px] cursor-pointer h-[12px]" src={InfoIcon} alt='' />
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        {userRoles ?
                                                            <div>
                                                                <StyledButton
                                                                    testId={`${testId}-add-hardware-btn`}
                                                                    width={'w-60'}
                                                                    align={'justify-start'}
                                                                    text={intl.formatMessage({ id: "Add_Hardware", defaultMessage: 'Add_Hardware' })}
                                                                    color={'text-gray-700'}
                                                                    flexReverse={'flex-row-reverse'}
                                                                    iconType={'add'}
                                                                    backgroundColor={'transparent border-1'}
                                                                    onClick={() => gotoAddHardware()}
                                                                />
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        <table>
                                                            <tbody>
                                                                {hardwaresList?.map((item, index) => (
                                                                    item.selected === false &&
                                                                    <tr key={item?.hardwareId} className='border-1 h-[100px] w-[800px] flex rounded-lg mt-[15px]'>
                                                                        <td className='border-[1px] font-bold tracking-[1px] h-[80px] w-[116px] rounded-l-lg justify-center bg-white flex items-center text-[10px] text-[#959595] text-center font-family-arial'>
                                                                            {item?.name || item?.nameWhenOther}
                                                                        </td>
                                                                        <td className=' border-t-[1px] border-b-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FAFAFA] flex items-center text-[10px] text-[#959595] text-center'>
                                                                        </td>
                                                                        <td className='border-t-[1px] border-b-[1px] border-l-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FAFAFA] flex items-center text-[10px] text-[#959595] text-center'>
                                                                        </td>
                                                                        <td className='border-t-[1px] border-b-[1px] relative border-l-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FAFAFA] flex items-center text-[10px] text-[#959595] text-center'>
                                                                            <div className='w-[552px] overflow-visible absolute opacity-50 text-[#535353] font-semibold text-[12px] bg-white border border-color-[#C1C1C1] rounded-lg h-[32px] flex items-center justify-center font-family-arial' >
                                                                                {intl.formatMessage({ id: 'not_on_this_location', defaultMessage: 'not_on_this_location' })}
                                                                            </div>
                                                                        </td>
                                                                        <td className='border-t-[1px] border-b-[1px] border-l-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FAFAFA] flex items-center text-[10px] text-[#959595] text-center'>
                                                                        </td>
                                                                        <td className='border-t-[1px] border-b-[1px] border-l-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center bg-[#FAFAFA] flex items-center text-[10px] text-[#959595] text-center'>
                                                                        </td>
                                                                        <td className='border-[1px] font-bold tracking-[1px] h-[80px] w-[116px] rounded-r-lg justify-center bg-white flex items-center text-[10px] text-[#959595] text-center'>
                                                                            {userRoles ?
                                                                                <img onClick={() => gotoEditHardware(item)} className="w-[12px] cursor-pointer h-[12px]" src={EditIcon} alt="" />
                                                                                :
                                                                                <img onClick={() => gotoEditHardware(item)} className="w-[12px] cursor-pointer h-[12px]" src={InfoIcon} alt="" />}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                        <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                            <StyledButton
                                testId={`${testId}-previous-btn`}
                                width={'w-48 sm:w-full'}
                                align={'justify-start'}
                                text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                color={'text-gray-700'}
                                flexReverse={'flex-row-reverse'}
                                iconType={'arrowleft'}
                                backgroundColor={'bg-gray-300'}
                                onClick={goToPrevious}
                            />
                            <StyledButton
                                testId={`${testId}-hardware-next-btn`}
                                buttonClass={'PrimoryBtn'}
                                width={'w-48 sm:w-full'}
                                align={'justify-end'}
                                text={intl.formatMessage({ id: 'next', defaultMessage: 'next' })}
                                color={'text-white'}
                                iconType={'arrow'}
                                backgroundColor={'bg-black'}
                                onClick={goToNext}
                            />
                        </div>
                    </Container>
                </div>
            }
            <Modal show={isHardwareModal} onClose={() => handleHardwareModalClose()}>
                <div className={hardwarePopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                        {hardwarePopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {hardwarePopupDetails?.Header}
                    </div>
                    {hardwarePopupDetails?.Video?.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(hardwarePopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {hardwarePopupDetails?.Button2 && <StyledButton testId={`${testId}-popup-btn-one`} onClick={() => hardwarePopupDetails?.Link2 ? navigate(hardwarePopupDetails?.Link2) : handleHardwareModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={hardwarePopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {hardwarePopupDetails?.Button && <StyledButton testId={`${testId}-popup-btn-two`} onClick={() => hardwarePopupDetails?.Link ? navigate(hardwarePopupDetails?.Link) : handleHardwareAdd()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={hardwarePopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>

            <div>
                <Modal show={isDeleteHardwareModal} onClose={() => handleDeleteHardwareModalClose()}>
                    <div>{`${intl.formatMessage({ id: 'are_you_sure', defaultMessage: 'are_you_sure' })}`}</div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-modal-cancel-btn`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => handleDeleteHardwareModalClose()}
                        />
                        <StyledButton
                            testId={`${testId}-delete-btn`}
                            buttonClass={'PrimoryBtn'}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "delete", defaultMessage: 'delete' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => deleteHardwareItem()}
                        />
                    </div>
                </Modal>
                <Modal show={isWromgOrgModal} onClose={() => ""}>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                    </div>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                    </div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-goto-dashboard-bia`}
                            onClick={() => handleWrongOrgModel()}
                            buttonClass={'PrimoryBtn'}
                            width={"w-[220px] sm:w-40"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                            color={"text-white mr-3 text-base"}
                            iconType={'home'}
                            backgroundColor={"bg-black"}
                        />
                    </div>
                </Modal>
            </div>
        </>
    )
}
