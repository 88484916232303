import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../components/StyledButton';
import { listOptionsContainerCreators } from './reducer';
import EditIcon from '../../assets/icons/edit-icon.svg';
import AddProcess from './SubViews/AddProcess';
import { selectDecodedUserInfo } from '../../AppSelector';
import Container from '../../components/Container/Container';

const testId = `step-4`;

export type Process = {
    [key: string]: any;
    processId?: string;
    processListId?: string,
    nameWhenOther?: string,
    responsible: string,
    BIA0h: number | null,
    BIA4h: number,
    BIA24h: number,
    BIA72h: number,
    BIA168h: number,
    BIA720h: number
}

export default function OrganisationStep4Container() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const { processes, shouldRealoadProcesses, listOptionsProcesses } = useSelector((state: RootState) => state.listOptions);
    const intl = useIntl();
    const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1)

    useEffect(() => {
        if (listOptionsSelector?.status !== null && listOptionsSelector?.status !== undefined && listOptionsSelector?.method !== "delete" && listOptionsSelector?.method !== null) {
            setIsRemoved(false)
            setIsSaved(true);
            setTimeCounter(1);
        } else if (listOptionsSelector?.method !== null && listOptionsSelector?.method !== undefined && listOptionsSelector?.method === "delete" && (listOptionsSelector?.method !== "post" || listOptionsSelector?.method !== "put")) {
            setIsSaved(false)
            setIsRemoved(true);
            setTimeCounter(1);
        }
    }, [listOptionsSelector])
/* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    const goToNext = () => {
        navigate("/dashboard?isCancel=0");
    }

    const removeProcess = (id: string) => {
        dispatch(listOptionsContainerCreators.requestDeleteProcess(id));
    }

    const editProcess = (processDetail: Process | null) => {
        navigate(`/organisation/step4/edit-Process/${processDetail?.processId}`);  
    }

    const addProcess = (processDetail: Process | null) => {
        navigate("/organisation/step4/add-Process");  
    }

    useEffect(() => {
        dispatch(listOptionsContainerCreators.requestGetProcesses());
        dispatch(listOptionsContainerCreators.requestGetListOptionsProcesses());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (shouldRealoadProcesses) {
            dispatch(listOptionsContainerCreators.requestGetProcesses());
        }
    }, [shouldRealoadProcesses]);/* eslint-disable react-hooks/exhaustive-deps */

    function isDisableBtn() {
        if ((role === "administrator") || (role === "editor")) {
            if (!processes.length) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <div>
            <Container>
                <div className="flex flex-grow-1 flex-col justify-between">
                    <div>
                        <div className='m-auto w-full align-middle pb-7 items-center flex justify-between border-b sm:m-0 sm:pb-6'>
                            <div className="text-xl font-semibold">
                                <div className="text-xl font-semibold">{intl.formatMessage({ id: 'organisation', defaultMessage: 'organisation' })}</div>
                            </div>
                            {isSaved &&
                                <div className="font-normal text-xs text-[#959595]">
                                    <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                </div>
                            }
                            {isRemoved &&
                                <div className="font-normal text-xs text-[#959595]">
                                    <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                                </div>
                            }
                        </div>
                        <div className="my-10 max-w-[952px] sm:my-8">
                            <div className='mt-8'>
                                <div className='mt-10'>
                                    <h3 className='text-3xl font-semibold'>{intl.formatMessage({ id: 'organization_step4_text', defaultMessage: 'organization_step4_text' })}</h3>
                                    <p className='max-w-xl text-xl text-[#535353] mt-5 font-family-arial' >
                                        {intl.formatMessage({ id: 'organization_step4_text_2', defaultMessage: 'organization_step4_text_2' })}
                                    </p>
                                </div>
                                <div className='mt-8 p-6 bg-white border rounded'>
                                    <p className='text-lg font-semibold text-[#121212] mb-6'>{intl.formatMessage({ id: 'processes', defaultMessage: 'processes' })}</p>
                                    {processes.length
                                        ? <div className='mb-4 border rounded-md bg-zinc-50'>
                                            {processes.map((process: Process, index: number) => {
                                                let processName: string = listOptionsProcesses.find((p: any) => p.listOptionId === process.processListId)?.name ?? "";
                                                if (processName === "") {
                                                    processName = process.nameWhenOther ?? "";
                                                } else {
                                                    processName = process.name
                                                }

                                                return (
                                                    <div className={'relative pl-4 pr-16 py-3 font-semibold' + (index !== 0 ? " border-t " : "")} key={index}>
                                                        {processName !== "" ? processName : <>&nbsp;</>}
                                                        {((role === "administrator") || (role === "editor")) &&
                                                            <>
                                                                <span className='absolute top-4 right-4  cursor-pointer' onClick={() => removeProcess(process.processId ?? "")}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                            <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span className='absolute cursor-pointer top-5 right-11' onClick={() => editProcess(process)}>
                                                                    <img src={EditIcon} alt="" />
                                                                </span>
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        : null
                                    }
                                    {((role === "administrator") || (role === "editor")) &&
                                        <div>
                                            <StyledButton
                                                align={'justify-start'}
                                                text={`${intl.formatMessage({ id: 'organization_step4_text_button', defaultMessage: 'organization_step4_text_button' })}`}
                                                width={'w-60'}
                                                color={'text-gray-700'}
                                                flexReverse={'flex-row-reverse'}
                                                backgroundColor={'transparent border-1'}
                                                onClick={() => addProcess(null)}
                                                iconType={'add'}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full items-center align-middle flex justify-between mt-8 gap-4'>
                        <StyledButton
                            testId={`${testId}-previous-btn-step4`}
                            align={'justify-start'}
                            text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                            color={'text-gray-700'}
                            width={'w-48 sm:w-full'}
                            flexReverse={'flex-row-reverse'}
                            onClick={() => navigate("/organisation/step3")}
                            backgroundColor={'bg-gray-300'}
                            iconType={'arrowleft'}
                        />
                        <StyledButton
                            testId={`${testId}-next-btn-step4`}
                            buttonClass={'PrimoryBtn'}
                            disabled={isDisableBtn()}
                            width={'w-48 sm:w-full'}
                            text={((role === "administrator") || (role === "editor")) ? intl.formatMessage({ id: 'finish', defaultMessage: 'finish' }) : intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}
                            align={'justify-end'}
                            onClick={goToNext}
                            color={'text-white'}
                            iconType={((role === "administrator") || (role === "editor")) ? 'arrow' : ''}
                            backgroundColor={isDisableBtn() ? 'bg-zinc-400' : 'bg-black'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    )
}
