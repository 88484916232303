import {
  AxiosError,
  AxiosResponse
} from 'axios';
import jwtDecode from 'jwt-decode';
import { IntlShape } from 'react-intl';
import { put, call, takeLatest } from 'redux-saga/effects';
import { appContainerCreators } from '../../AppReducer';
import { addUserToOrgByInvite } from '../../services/Organization';
import { loginUserService, createUserService, forgotPasswordService, verifyEmailService } from '../../services/UserService';
import { dashboardContainerCreators } from '../ProtectedContainer/dashboardReducer';
import { loginContainerTypes, loginContainerCreators, userContainerTypes, userContainerCreators } from './reducer';

type AuthDataCallType = {
  data: {
    email: string;
    password: string;
  };
  inviteId?: string;
  intl?: IntlShape;
}

type PasswordForgottenCallType = {
  data: {
    email: string;
  };
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPasswordForgotten: React.Dispatch<React.SetStateAction<boolean>>;
}

type EmailVarificationCall = {
  emailVerificationId: string;
}

type SetUserDataCallType = {
  isOrgExist: {
    duplicateOrgIntentional: boolean;
  },
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setIsOrgExist: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFocus: React.Dispatch<React.SetStateAction<boolean>>;
  data: {
    user: object,
    organization: object
  }
  navigate?: (path: string, state?: object) => void;
}

const {
  REQUEST_GET_AUTH,
  REQUEST_POST_EMAIL,
  REQUEST_GET_VERIFY_EMAIL
} = loginContainerTypes;

const {
  REQUEST_SET_USER,
} = userContainerTypes;

/* istanbul ignore next */
export function* authDataCall(data: AuthDataCallType) {
  try {
    const response: AxiosResponse = yield call(loginUserService, data.data);
    yield put(appContainerCreators.updateUserInfo(response.data))
    if (response && response.data && data.inviteId) {
      let inviteToast = null;
      try {
        const userInfo: DecodedUserInfo = jwtDecode(response.data.accessToken)
        const addInviteAPI: AxiosResponse = yield call(addUserToOrgByInvite, userInfo?.payload.userId, data.inviteId);
        if (addInviteAPI) {
          inviteToast = true;
        }
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.response?.status === 409) {
            inviteToast = data?.intl && data?.intl.formatMessage({ id: 'invite_already_used_or_not_linked_to_this_user' })
          } else {
            inviteToast = e.response?.data.errors[0].message;
          }
        } else {
          inviteToast = "Something went wrong, please try again later!";
        }
      }
      const payload = {
        organizationId: "",
      }
      // yield put(dashboardContainerCreators.requestPostSwitchOrg(payload, inviteToast));
      yield put(dashboardContainerCreators.inviteToastMsg(inviteToast));
    }
    yield put(loginContainerCreators.successGetAuth(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(loginContainerCreators.failureGetAuth(e.response?.data.errors[0].message));
    } else {
      yield put(loginContainerCreators.failureGetAuth("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* createUserAsync(data: SetUserDataCallType) {
  try {
    const response: AxiosResponse = yield call(createUserService, data.isOrgExist.duplicateOrgIntentional, data.data);
    if (response) {
      data?.navigate && data?.navigate(`/`, { state: { toastFromCreateAccount: true } });
    }
    yield put(userContainerCreators.successSetUser(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e) {
        data.setShowToast(true);
        setTimeout(() => {
          data.setShowToast(false);
          if (e instanceof AxiosError && e.response && e.response?.data.errors[0].message === 'Organization already exist') {
            data.setIsFocus(true);
          }
        }, 5000)
      }
      yield put(userContainerCreators.failureSetUser(e.response?.data.errors[0].message));
    } else {
      yield put(userContainerCreators.failureSetUser("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* passwordForgottenCall(data: PasswordForgottenCallType) {
  try {
    const response: AxiosResponse = yield call(forgotPasswordService, data.data);
    if (response) {
      data.setShowToast(true);
      setTimeout(() => {
        data.setIsPasswordForgotten(false);
        data.setShowToast(false);
      }, 2000)
    }
    yield put(loginContainerCreators.successPostEmail(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(loginContainerCreators.failurePostEmail(e.response?.data.errors[0].message));
    } else {
      yield put(loginContainerCreators.failurePostEmail("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* emailVarificationCall(data: EmailVarificationCall) {
  try {
    const response: AxiosResponse = yield call(verifyEmailService, data.emailVerificationId);
    yield put(loginContainerCreators.successGetVerifyEmail(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(loginContainerCreators.failureGetVerifyEmail(e.response?.data.errors[0].message));
    } else {
      yield put(loginContainerCreators.failureGetVerifyEmail("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export default function* loginContainerSaga() {
  // @ts-ignore
  yield takeLatest(REQUEST_GET_AUTH, authDataCall);
  // @ts-ignore
  yield takeLatest(REQUEST_SET_USER, createUserAsync);
  // @ts-ignore
  yield takeLatest(REQUEST_POST_EMAIL, passwordForgottenCall);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_VERIFY_EMAIL, emailVarificationCall);
}
