import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl';
import { selectDecodedUserInfo } from '../../AppSelector';
import { useSelector } from 'react-redux';

export default function NavigationMenu() {

    const testId = `navigation-menu`;
    // hooks
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isSubscription = pathname.includes('/subscription');
    const isUsers = pathname.includes('/users');
    const isOrganizationList = pathname.includes('/organization-list');
    const isCurrentOrganization = pathname.includes('/current-organisation');
    const decodedInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedInfo?.payload.role;

    // function
    const gotoUsers = () => {
        navigate("/users");
    }
    const gotoSubscription = () => {
        navigate("/subscription");
    }
    const gotoOrganizationList = () => {
        navigate("/organization-list");
    }
    const gotoCurrentOrganisation = () => {
        navigate("/current-organisation");
    }
    const intl = useIntl();

    return (
        <>
            <div className="w-full block" id="navbar-default">
                <ul className="flex sm:flex-wrap flex-row sm:py-3 py-12 text-xl font-semibold border-b border-b-[#C1C1C1]">
                    <li>
                        <span data-testid={`${testId}-current-org`} onClick={() => gotoCurrentOrganisation()} className={`w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer ${isCurrentOrganization && '!text-[#121212]'}`} aria-current="page">{decodedInfo?.payload.orgName}</span>
                    </li>

                    {(role === "administrator") && decodedInfo?.payload.orgType === 0 &&
                        <li>
                            <span data-testid={`${testId}-subscription`} onClick={() => gotoSubscription()} className={`block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer ${isSubscription && '!text-[#121212]'}`} aria-current="page">{intl.formatMessage({ id: 'subscription', defaultMessage: 'subscription' })}</span>
                        </li>
                    }
                    {(role === "administrator" || role === "partneraccountant" || role === "partnerinsuranceadvisor") &&
                        <li>
                            <span data-testid={`${testId}-users`} onClick={() => gotoUsers()} className={`block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer ${isUsers && '!text-[#121212]'}`} aria-current="page">{intl.formatMessage({ id: 'users', defaultMessage: 'users' })}</span>
                        </li>
                    }
                    <li>
                        <span data-testid={`${testId}-select-org`} onClick={() => gotoOrganizationList()} className={`block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer ${isOrganizationList && '!text-[#121212]'}`} aria-current="page">{intl.formatMessage({ id: 'select_organization', defaultMessage: 'select_organization' })}</span>
                    </li>
                </ul>
            </div>
        </>
    );
}