import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import StyledButton from "../../components/StyledButton";
import ProfilePicture from "../../assets/images/profile-picture.svg";
import MoreQuestionsExpand from "../../components/QuestionsExpand";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { riskAreaContainerCreators } from "../ManageRiskAreasContainer/reducer";
import { useDispatch, useSelector } from "react-redux";
import { SelectedOptions } from "@onesixeight-nl/classes/dist/classes/selectedOptionsEnum"
import { RiskFactors } from '@onesixeight-nl/classes/dist/classes/riskFactorEnum';
import schema from "../../validations/schema";
import LoadingIcon from "../../assets/icons/loading-icon";
import { listOptionsContainerCreators } from "../OrganisationContainer/reducer";
import { Users } from "../ManageRiskAreasContainer";
import dayjs from "dayjs";
import { InfoDetails } from "../BIAContainer/BIAEdit";
import { selectDecodedUserInfo } from "../../AppSelector";
import Container from "../../components/Container/Container";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import Modal from "../../components/Modal/Modal";
import { timeout } from "../../utils";
import { set } from "lodash";

const testId = `risk-area-container`;

export type RiskCategory = {
    riskListId: string,
    nameWhenOther?: string,
    selected: number,
    riskFactor: number,
    assesedStatus: number,
    name?: string;
    task: {
        assignedTo: string,
        deadline: string,
    }
};

type RiskAreasList = {
    riskCategoryId: string;
    name: string;
    nameWhenOther: string;
    riskListId: string,
    riskFactor: number,
    selected: number,
    status: number,
    task: {
        assignedTo: string,
        deadline: string,
    }
};

const intialRiskCategory: RiskCategory = {
    riskListId: '',
    nameWhenOther: '',
    selected: 0,
    riskFactor: 0,
    assesedStatus: 0,
    name: '',
    task: {
        assignedTo: '',
        deadline: '',
    }
};

type ErrorMessage = {
    message: string
}

type ErrorObject = {
    nameWhenOther?: ErrorMessage;
    responsible?: ErrorMessage;
};

export type UndefinedRiskAreaCategory = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string | null;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
}

export type UserDropdownList = {
    label: string,
    value: string
};

export default function RiskAreasContainer() {

    //hooks
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const { id } = params;
    const intl = useIntl();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload?.role;

    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const { loading } = useSelector((state: RootState) => state.listOptions);
    const { organizationUsers } = useSelector((state: RootState) => state.riskAreContainer);

    //states
    const [allRiskCategoryList, setAllRiskCategoryList] = useState<RiskAreasList[]>([]); // All Risk Category
    const [riskAreaCategory, setriskAreaCategory] = useState<RiskCategory | any>({ ...intialRiskCategory });
    const [undefinedRiskAreaCategory, setUndefinedRiskAreaCategory] = useState<RiskAreasList[] | any>([]);
    const [currentIndex, setCurrentIndex] = useState<number>((riskAreaSelector.riskCategory && riskAreaSelector.riskCategory?.length >= 0) && riskAreaSelector.riskCategory?.findIndex((ele: RiskAreasList) => !ele.riskCategoryId));
    const [errObj, setErrObj] = useState<ErrorObject>({
        nameWhenOther: undefined,
        responsible: undefined,
    });
    const [answerType, setAnswerType] = useState<string>('');
    const [answerSubType, setAnswerSubType] = useState<string>('');
    const nameOfRiskArea: boolean = false;
    const [measuresDefinedDate, setMeasuresDefinedDate] = useState<Date>(new Date());
    const [riskFactorValue, setRiskFactorValue] = useState<number>(75);
    const [formatedDate, setFormatedDate] = useState<string>('');
    const [userData, setUserData] = useState<Users>({
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        emailVerified: false,
        mobilePhone: "",
        prefferedLanguage: "",
        created: new Date(),
        modified: new Date(),
    });
    const [userDropdownList, setUserDropdownList] = useState<UserDropdownList[]>([]);
    const profileArr: string[] = [
        ProfilePicture,
        ProfilePicture,
    ];
    const [users, setUsers] = useState<Users[]>([]);
    const [editRiskCategoryObj, setEditRiskCategoryObj] = useState<any>()
    const [singleRiskCategory, setSingleRiskCategory] = useState<any>(riskAreaSelector && riskAreaSelector.riskCategory?.length && riskAreaSelector?.riskCategory?.find((ele: RiskAreasList) => ele.riskCategoryId === id))
    const [nameOfRiskCategory, setNameOfRiskCategory] = useState<string>('');
    const [lastQuetion, setLastQuetion] = useState<boolean>(false);

    const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);
    const [learnMoreInfo, setLearnMoreInfo] = useState<boolean | undefined>(false);
    const findInfoText: any = infoDetails?.length > 0 ? infoDetails?.find((ele) => ele?.attributes?.InfoText) : []
    const InfoTextDetail = findInfoText ? findInfoText?.attributes?.InfoText : {}
    const textShow = InfoTextDetail ? InfoTextDetail?.substring(0, 100) : "";
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector?.riskCategory) {
            setAllRiskCategoryList(riskAreaSelector?.riskCategory)
            setUndefinedRiskAreaCategory(riskAreaSelector?.riskCategory)
        }
    }, [riskAreaSelector?.riskCategory])

    useEffect(() => {
        if (riskAreaSelector?.riskCategory && id) {
            const currentRiskCategory = riskAreaSelector?.riskCategory?.find((ele: RiskAreasList) => ele.riskCategoryId === id);
            setEditRiskCategoryObj(currentRiskCategory)
            setSingleRiskCategory(currentRiskCategory)
        }
    }, [riskAreaSelector?.riskCategory, id])

    useEffect(() => {
        if (singleRiskCategory === undefined && id) {
            setTimeout(() => {
                setIsWromgOrgModal(true)
            }, 700);
        }
    }, [singleRiskCategory, riskAreaSelector?.riskCategory, editRiskCategoryObj, id])

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    function SideDrawerOpen() {
        setLearnMoreInfo(!learnMoreInfo);
    }

    function SideDrawerClose() {
        setLearnMoreInfo(false);
    }

    useEffect(() => {
        const postRiskCategory = riskAreaSelector.postRiskCategory;
        const postRiskIndex = undefinedRiskAreaCategory.findIndex((ele: RiskAreasList) => ele?.riskListId === riskAreaSelector?.postRiskCategory?.riskListId)
        if (postRiskIndex > -1 && postRiskCategory !== null) {
            undefinedRiskAreaCategory[postRiskIndex] = riskAreaSelector.postRiskCategory;
            setUndefinedRiskAreaCategory(undefinedRiskAreaCategory);
        }
    }, [riskAreaSelector.postRiskCategory])

    const [postLoading, setPostLoading] = useState(false);

    useEffect(() => {
        if (riskAreaSelector?.loadingAfterPost) {
            setPostLoading(true);
        } else {
            setPostLoading(false);
        }
    }, [riskAreaSelector?.loadingAfterPost])

    useEffect(() => {
        if (riskAreaSelector?.riskCategory && riskAreaSelector?.riskCategory.length >= 0 && !editRiskCategoryObj) {
            setCurrentIndex((riskAreaSelector.riskCategory && riskAreaSelector.riskCategory?.length >= 0) && riskAreaSelector.riskCategory?.findIndex((ele: RiskAreasList) => !ele.riskCategoryId))
            if (currentIndex !== null && currentIndex > -1) {
                setCurrentIndex(currentIndex)
                setriskAreaCategory(riskAreaSelector?.riskCategory[currentIndex])
            }
            else if (currentIndex === null) {
                const index = riskAreaSelector?.riskCategory?.findIndex((ele: RiskAreasList) => !ele?.riskCategoryId)
                setCurrentIndex(index)
                setriskAreaCategory(riskAreaSelector?.riskCategory[index])
            }
            else if (currentIndex === -1) {
                setriskAreaCategory({
                    ...intialRiskCategory,
                    riskListId: '00000000-0000-0000-0000-000000000000'
                });
            }
        }
    }, [riskAreaSelector, riskAreaSelector.riskCategory, currentIndex])

    useEffect(() => {
        if (decodedUserInfo?.payload?.orgId) {
            dispatch(riskAreaContainerCreators.requestGetOrganizationUsers(decodedUserInfo?.payload?.orgId))
        }
    }, [decodedUserInfo?.payload?.orgId])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (organizationUsers && organizationUsers.length) {
            setUsers(organizationUsers)
        }
    }, [organizationUsers])
    /* istanbul ignore next */
    useEffect(() => {
        if (users && users.length) {
            const filteredUser = users.filter((user: Users) => (user.role === "administrator" || user.role === "editor"))
            let newUsers: Users[] | any = filteredUser.map((user: Users) => {
                return {
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.userId
                }
            });
            setUserDropdownList(newUsers);
            if (editRiskCategoryObj && editRiskCategoryObj?.task) {
                setUserData({ ...userData, userId: editRiskCategoryObj?.task?.assignedTo || String(decodedUserInfo?.payload?.userId) })
                const date = new Date(editRiskCategoryObj?.task?.deadline) || new Date();
                setMeasuresDefinedDate(date)
            } else {
                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) })
                const date = new Date();
                setMeasuresDefinedDate(date)
            }

            const defaultUser = users.find((user: Users) => user.userId === decodedUserInfo?.payload?.userId)
            if (defaultUser && !id) {
                setUserData({ ...defaultUser });
            }

        }
    }, [users])/* eslint-disable react-hooks/exhaustive-deps */
    /* istanbul ignore next */
   
    useEffect(() => {
        if (riskAreaSelector.riskCategory && riskAreaSelector.riskCategory.length) {
            const defaultRiskCategories = riskAreaSelector.riskCategory?.filter((ele: RiskAreasList) => ele.riskListId !== "00000000-0000-0000-0000-000000000000");
            setUndefinedRiskAreaCategory(defaultRiskCategories);
        }
    }, [riskAreaSelector.riskCategory])

    /* istanbul ignore next */
    useEffect(() => {
        if (editRiskCategoryObj) {
            setCurrentIndex(-1);
            setriskAreaCategory({
                name: editRiskCategoryObj?.name,
                riskListId: editRiskCategoryObj?.riskListId,
                selected: editRiskCategoryObj?.selected,
                riskFactor: editRiskCategoryObj?.riskFactor * 25,
                nameWhenOther: editRiskCategoryObj?.nameWhenOther,
                assesedStatus: editRiskCategoryObj?.assesedStatus,
                task: {
                    assignedTo: editRiskCategoryObj?.task?.assignedTo,
                    deadline: editRiskCategoryObj?.task?.deadline,
                }
            })
            if (editRiskCategoryObj?.nameWhenOther !== null) {
                setNameOfRiskCategory(editRiskCategoryObj?.nameWhenOther);
            } else {
                setNameOfRiskCategory('');
            }

            const isNonZeroID = editRiskCategoryObj?.riskListId !== '00000000-0000-0000-0000-000000000000'

            if (editRiskCategoryObj?.selected === 0) {
                setAnswerType('')
                setAnswerSubType('')
            } else if (editRiskCategoryObj?.selected === 1) {
                isNonZeroID ? setAnswerType('No') : setAnswerType('')
                setAnswerSubType('')
            } else if (editRiskCategoryObj?.selected === 2) {
                isNonZeroID ? setAnswerType('Yes') : setAnswerType('')
                setAnswerSubType('Now')
            } else if (editRiskCategoryObj?.selected === 3) {
                isNonZeroID ? setAnswerType('Yes') : setAnswerType('')
                setAnswerSubType('Later')
            }
            setUserData({ ...userData, userId: editRiskCategoryObj?.task?.assignedTo });
            if (editRiskCategoryObj?.task?.deadline) {
                const date = new Date(editRiskCategoryObj?.task?.deadline);
                setMeasuresDefinedDate(date);
            } else {
                const date = new Date();
                setMeasuresDefinedDate(date);
            }

            if (editRiskCategoryObj?.selected === 1) {
                setRiskFactorValue(75)
            } else {
                setRiskFactorValue(editRiskCategoryObj?.riskFactor * 25);
            }
        }

    }, [editRiskCategoryObj])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector.editData && !editRiskCategoryObj) {
            setriskAreaCategory({
                name: riskAreaSelector.editData?.name,
                riskListId: riskAreaSelector?.editData?.riskListId,
                selected: riskAreaSelector?.editData?.selected,
                riskFactor: riskAreaSelector?.editData?.riskFactor * 25,
                nameWhenOther: riskAreaSelector?.editData?.nameWhenOther,
                assesedStatus: riskAreaSelector?.editData?.assesedStatus,
                task: {
                    assignedTo: riskAreaSelector.editData?.task?.assignedTo,
                    deadline: riskAreaSelector.editData?.task?.deadline,
                }
            })
            if (riskAreaSelector?.editData?.nameWhenOther !== null) {
                setNameOfRiskCategory(riskAreaSelector?.editData?.nameWhenOther);
            } else {
                setNameOfRiskCategory('');
            }

            const isNonZeroID = riskAreaSelector?.editData?.riskListId !== '00000000-0000-0000-0000-000000000000'

            if (riskAreaSelector?.editData?.selected === 0) {
                setAnswerType('')
                setAnswerSubType('')
            } else if (riskAreaSelector?.editData?.selected === 1) {
                isNonZeroID ? setAnswerType('No') : setAnswerType('')
                setAnswerSubType('')
            } else if (riskAreaSelector?.editData?.selected === 2) {
                isNonZeroID ? setAnswerType('Yes') : setAnswerType('')
                setAnswerSubType('Now')
            } else if (riskAreaSelector?.editData?.selected === 3) {
                isNonZeroID ? setAnswerType('Yes') : setAnswerType('')
                setAnswerSubType('Later')
            }
            setUserData({ ...userData, userId: riskAreaSelector.editData?.task?.assignedTo });
            if (riskAreaSelector.editData?.task?.deadline) {
                const date = new Date(riskAreaSelector.editData?.task?.deadline);
                setMeasuresDefinedDate(date);
            } else {
                const date = new Date();
                setMeasuresDefinedDate(date);
            }

            if (riskAreaSelector?.editData?.selected === 1) {
                setRiskFactorValue(75)
            } else {
                setRiskFactorValue(riskAreaSelector?.editData?.riskFactor * 25);
            }
        }

    }, [riskAreaSelector?.editData])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector?.status !== null && riskAreaSelector?.status !== undefined) {
            setIsSaved(true);
            setTimeCounter(1);
        }
    }, [riskAreaSelector])

    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved]);

    /* istanbul ignore next */
    useEffect(() => {
        if (editRiskCategoryObj && editRiskCategoryObj?.riskListId) {
            dispatch(riskAreaContainerCreators.requestGetRiskCategoryHelpText(editRiskCategoryObj?.riskListId, decodedUserInfo));
        }
    }, [])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaCategory && riskAreaCategory?.riskListId && !editRiskCategoryObj?.riskListId) {
            dispatch(riskAreaContainerCreators.requestGetRiskCategoryHelpText(riskAreaCategory?.riskListId, decodedUserInfo));
        }
    }, [riskAreaCategory?.riskListId])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.riskCategoryHelpText) {
            setInfoDetails(riskAreaSelector.riskCategoryHelpText?.data);
        }
    }, [riskAreaSelector])

    // functions
    const handleCancel = () => {
        navigate("/manage-risk-areas?isCancel=0");
    }

    async function handlePreviousRiskCategory() {
        if (currentIndex === -1) {
            if (undefinedRiskAreaCategory[undefinedRiskAreaCategory?.length - 1] && undefinedRiskAreaCategory[undefinedRiskAreaCategory?.length - 1]?.riskCategoryId) {
                dispatch(riskAreaContainerCreators.requestEditValue(undefinedRiskAreaCategory[undefinedRiskAreaCategory?.length - 1]?.riskCategoryId));
            }
            setCurrentIndex(undefinedRiskAreaCategory.length - 1);
        } else if (currentIndex > -1) {
            setCurrentIndex(currentIndex - 1);
            if (undefinedRiskAreaCategory[currentIndex - 1] && undefinedRiskAreaCategory[currentIndex - 1]?.riskCategoryId) {
                dispatch(riskAreaContainerCreators.requestEditValue(undefinedRiskAreaCategory[currentIndex - 1]?.riskCategoryId));
            }
        }
        //setTimeout(() => {
        //    dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        //}, 1500)
    }

    function resetValues() {
        setAnswerSubType('')
        setAnswerType('')
        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
        setMeasuresDefinedDate(new Date())
        setRiskFactorValue(75)
        setriskAreaCategory(
            {
                ...riskAreaCategory,
                nameWhenOther: '',
                //riskListId: "00000000-0000-0000-0000-000000000000",
            }
        )
        setNameOfRiskCategory('');
    }

    function handleEditRiskCategoryInAddMode(isAddMode: boolean, id: string) {
        const payload = {
            riskListId: riskAreaCategory?.riskListId,
            selected: (answerType === 'No') ? SelectedOptions.NOTRELEVANT : (answerSubType === 'Now') ? SelectedOptions.NOW : SelectedOptions.LATER,
            riskFactor: answerType === 'No' ? RiskFactors.UNDEFINED : Math.round(riskFactorValue / 25),
            assesedStatus: riskAreaCategory.assesedStatus !== 0 ? riskAreaCategory.assesedStatus : 0,
            nameWhenOther: riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' ? nameOfRiskCategory : '',
            task: {
                assignedTo: userData?.userId,
                deadline: (formatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
            }
        }
        const otherNameErr = schema.riskNameOther.validate(nameOfRiskCategory).error;
        const responsibleErr = schema.manageRiskAreasResponsible.validate(userData.userId).error;
        if (answerType === "Yes") {
            if (answerSubType === 'Later') {
                dispatch(riskAreaContainerCreators.requestPutRiskCategory(id, { ...payload, ...(isAddMode ? '' : navigate) }));
                setErrObj({ nameWhenOther: undefined });
                if (isAddMode) {
                    resetValues();
                    if (currentIndex + 1 < undefinedRiskAreaCategory?.length) {
                        setCurrentIndex(currentIndex + 1);
                    } else {
                        setCurrentIndex(-1);
                        setriskAreaCategory({ ...riskAreaCategory, riskListId: "00000000-0000-0000-0000-000000000000" })
                    }
                }
            } else if (answerSubType === 'Now') {
                if (!responsibleErr) {
                    dispatch(riskAreaContainerCreators.requestPutRiskCategory(id, { ...payload, ...(isAddMode ? '' : navigate) }));
                    setErrObj({ nameWhenOther: undefined, responsible: undefined });
                    if (isAddMode) {
                        resetValues();
                        if (currentIndex + 1 < undefinedRiskAreaCategory?.length) {
                            setCurrentIndex(currentIndex + 1);
                        } else {
                            setCurrentIndex(-1);
                            setriskAreaCategory({ ...riskAreaCategory, riskListId: "00000000-0000-0000-0000-000000000000" })
                        }
                    }
                } else {
                    setErrObj({
                        nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined,
                        responsible: responsibleErr?.message ? { message: intl.formatMessage({ id: responsibleErr?.message, defaultMessage: responsibleErr?.message }) } : undefined
                    });
                }
            }
        } else if (riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && answerType === "") {
            if (answerSubType === 'Later') {
                if (!otherNameErr) {
                    dispatch(riskAreaContainerCreators.requestPutRiskCategory(id, { ...payload, ...(isAddMode ? '' : navigate) }));
                    setErrObj({ nameWhenOther: undefined });
                    if (isAddMode) {
                        resetValues();

                        if (currentIndex + 1 < undefinedRiskAreaCategory?.length) {
                            setCurrentIndex(currentIndex + 1);
                        } else {
                            setCurrentIndex(-1);
                            setriskAreaCategory({ ...riskAreaCategory, riskListId: "00000000-0000-0000-0000-000000000000" })
                        }
                    }
                } else {
                    setErrObj({ nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined });
                }
            } else if (answerSubType === 'Now') {
                if (!otherNameErr && !responsibleErr) {
                    dispatch(riskAreaContainerCreators.requestPutRiskCategory(id, { ...payload, ...(isAddMode ? '' : navigate) }));
                    setErrObj({ nameWhenOther: undefined, responsible: undefined });
                    if (isAddMode) {
                        resetValues();

                        if (currentIndex + 1 < undefinedRiskAreaCategory?.length) {
                            setCurrentIndex(currentIndex + 1);
                        } else {
                            setCurrentIndex(-1);
                            setriskAreaCategory({ ...riskAreaCategory, riskListId: "00000000-0000-0000-0000-000000000000" })
                        }
                    }
                } else {
                    setErrObj({
                        nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined,
                        responsible: responsibleErr?.message ? { message: intl.formatMessage({ id: responsibleErr?.message, defaultMessage: responsibleErr?.message }) } : undefined
                    });
                }
            }
        }
        else {
            dispatch(riskAreaContainerCreators.requestPutRiskCategory(id, { ...payload, ...(isAddMode ? '' : navigate) }));
            if (isAddMode) {
                resetValues();
                if (currentIndex + 1 < undefinedRiskAreaCategory?.length) {
                    setCurrentIndex(currentIndex + 1);
                } else {
                    setCurrentIndex(-1);
                    setriskAreaCategory({ ...riskAreaCategory, riskListId: "00000000-0000-0000-0000-000000000000" })
                }
            }
        }
    }

    /* istanbul ignore next */
    const addRiskArea = () => {
        if (undefinedRiskAreaCategory[currentIndex]?.riskCategoryId) {
            // EDIT RISK CATEGORY IN ADD MODE
            if (undefinedRiskAreaCategory[currentIndex + 1]?.riskCategoryId) {
                dispatch(riskAreaContainerCreators.requestEditValue(undefinedRiskAreaCategory[currentIndex + 1]?.riskCategoryId))
            }
            handleEditRiskCategoryInAddMode(true, undefinedRiskAreaCategory[currentIndex]?.riskCategoryId);
        }
        else if (editRiskCategoryObj && !undefinedRiskAreaCategory[currentIndex]?.riskCategoryId) {
            const payload = {
                riskListId: riskAreaCategory?.riskListId,
                selected: (answerType === 'No') ? SelectedOptions.NOTRELEVANT : (answerSubType === 'Now') ? SelectedOptions.NOW : SelectedOptions.LATER,
                riskFactor: answerType === 'No' ? RiskFactors.UNDEFINED : Math.round(riskFactorValue / 25),
                assesedStatus: riskAreaCategory.assesedStatus !== 0 ? riskAreaCategory.assesedStatus : 0,
                nameWhenOther: riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' ? nameOfRiskCategory : '',
                task: {
                    assignedTo: userData?.userId,
                    deadline: (formatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                }
            }
            const otherNameErr = schema.riskNameOther.validate(nameOfRiskCategory).error;
            const responsibleErr = schema.manageRiskAreasResponsible.validate(userData.userId).error;
            if (answerType === "Yes") {
                if (answerSubType === 'Later') {
                    dispatch(riskAreaContainerCreators.requestPutRiskCategory(editRiskCategoryObj?.riskCategoryId, { ...payload, navigate }));
                    setErrObj({ nameWhenOther: undefined });
                } else if (answerSubType === 'Now') {
                    if (!responsibleErr) {
                        dispatch(riskAreaContainerCreators.requestPutRiskCategory(editRiskCategoryObj?.riskCategoryId, { ...payload, navigate }));
                        setErrObj({ nameWhenOther: undefined, responsible: undefined });
                    } else {
                        setErrObj({
                            nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined,
                            responsible: responsibleErr?.message ? { message: intl.formatMessage({ id: responsibleErr?.message, defaultMessage: responsibleErr?.message }) } : undefined
                        });
                    }
                }
            } else if (riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && answerType === "") {
                if (answerSubType === 'Later') {
                    if (!otherNameErr) {
                        dispatch(riskAreaContainerCreators.requestPutRiskCategory(editRiskCategoryObj?.riskCategoryId, { ...payload, navigate }));
                        setErrObj({ nameWhenOther: undefined });
                    } else {
                        setErrObj({ nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined });
                    }
                } else if (answerSubType === 'Now') {
                    if (!otherNameErr && !responsibleErr) {
                        dispatch(riskAreaContainerCreators.requestPutRiskCategory(editRiskCategoryObj?.riskCategoryId, { ...payload, navigate }));
                        setErrObj({ nameWhenOther: undefined, responsible: undefined });
                    } else {
                        setErrObj({
                            nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined,
                            responsible: responsibleErr?.message ? { message: intl.formatMessage({ id: responsibleErr?.message, defaultMessage: responsibleErr?.message }) } : undefined
                        });
                    }
                }
            }
            else {
                dispatch(riskAreaContainerCreators.requestPutRiskCategory(editRiskCategoryObj?.riskCategoryId, { ...payload, navigate }));
            }
        }
        else {
            // add riskacategory
            if (riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && lastQuetion) {
                const newRiskCategory: RiskCategory = {
                    nameWhenOther: riskAreaCategory.nameWhenOther,
                    assesedStatus: 0,
                    selected: (answerType === 'No') ? SelectedOptions.NOTRELEVANT : (answerSubType === 'Now') ? SelectedOptions.NOW : SelectedOptions.LATER,
                    riskListId: "00000000-0000-0000-0000-000000000000",
                    riskFactor: answerType === 'No' ? RiskFactors.UNDEFINED : Math.round(riskFactorValue / 25),
                    task: {
                        assignedTo: userData?.userId,
                        deadline: (formatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                    }
                }
                const otherNameErr = schema.riskNameOther.validate(riskAreaCategory.nameWhenOther).error;
                const responsibleErr = schema.manageRiskAreasResponsible.validate(userData.userId).error;
                if (answerType === "Yes") {
                    if (answerSubType === 'Later') {
                        if (!otherNameErr) {
                            setErrObj({ nameWhenOther: undefined });
                            dispatch(riskAreaContainerCreators.requestAddRiskCategory(newRiskCategory));
                            setAnswerSubType('')
                            setAnswerType('')
                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                            setMeasuresDefinedDate(new Date())
                            setRiskFactorValue(75)
                            setriskAreaCategory(
                                {
                                    ...riskAreaCategory,
                                    nameWhenOther: '',
                                    riskListId: "00000000-0000-0000-0000-000000000000",
                                }
                            )
                            setNameOfRiskCategory('');
                        } else {
                            setErrObj({ nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined });
                        }
                    } else if (answerSubType === 'Now') {
                        if (!otherNameErr && !responsibleErr) {
                            setErrObj({ nameWhenOther: undefined, responsible: undefined });
                            dispatch(riskAreaContainerCreators.requestAddRiskCategory(newRiskCategory));
                            setAnswerSubType('')
                            setAnswerType('')
                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                            setMeasuresDefinedDate(new Date())
                            setRiskFactorValue(75)
                            setriskAreaCategory(
                                {
                                    ...riskAreaCategory,
                                    nameWhenOther: '',
                                    riskListId: "00000000-0000-0000-0000-000000000000",
                                }
                            )
                            setNameOfRiskCategory('');
                        } else {
                            setErrObj({
                                nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined,
                                responsible: responsibleErr?.message ? { message: intl.formatMessage({ id: responsibleErr?.message, defaultMessage: responsibleErr?.message }) } : undefined
                            });
                        }
                    }
                } else {
                    navigate("/manage-risk-areas?isCancel=0");
                }
            } else if (riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && !lastQuetion) {
                const newRiskCategory: RiskCategory = {
                    nameWhenOther: nameOfRiskCategory,
                    assesedStatus: 0,
                    selected: (answerType === 'No') ? SelectedOptions.NOTRELEVANT : (answerSubType === 'Now') ? SelectedOptions.NOW : SelectedOptions.LATER,
                    riskListId: "00000000-0000-0000-0000-000000000000",
                    riskFactor: answerType === 'No' ? RiskFactors.UNDEFINED : Math.round(riskFactorValue / 25),
                    task: {
                        assignedTo: userData?.userId,
                        deadline: (formatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                    }
                }
                const otherNameErr = schema.riskNameOther.validate(nameOfRiskCategory).error;
                const responsibleErr = schema.manageRiskAreasResponsible.validate(userData.userId).error;
                if (answerType === "") {
                    if (answerSubType === 'Later') {
                        if (!otherNameErr) {
                            setErrObj({ nameWhenOther: undefined });
                            dispatch(riskAreaContainerCreators.requestAddRiskCategory(newRiskCategory, navigate));
                            setAnswerSubType('')
                            setAnswerType('')
                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                            setMeasuresDefinedDate(new Date())
                            setRiskFactorValue(75)
                            setriskAreaCategory(
                                {
                                    ...riskAreaCategory,
                                    nameWhenOther: '',
                                    riskListId: "00000000-0000-0000-0000-000000000000",
                                }
                            )
                            setNameOfRiskCategory('');
                        } else {
                            setErrObj({ nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined });
                        }
                    } else if (answerSubType === 'Now') {
                        if (!otherNameErr && !responsibleErr) {
                            setErrObj({ nameWhenOther: undefined, responsible: undefined });
                            dispatch(riskAreaContainerCreators.requestAddRiskCategory(newRiskCategory, navigate));
                            setAnswerSubType('')
                            setAnswerType('')
                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                            setMeasuresDefinedDate(new Date())
                            setRiskFactorValue(75)
                            setriskAreaCategory(
                                {
                                    ...riskAreaCategory,
                                    nameWhenOther: '',
                                    riskListId: "00000000-0000-0000-0000-000000000000",
                                }
                            )
                            setNameOfRiskCategory('');
                        } else {
                            setErrObj({
                                nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined,
                                responsible: responsibleErr?.message ? { message: intl.formatMessage({ id: responsibleErr?.message, defaultMessage: responsibleErr?.message }) } : undefined
                            });
                        }
                    }
                } else {
                    navigate("/manage-risk-areas?isCancel=0");
                }
            } else {
                setLastQuetion(true);
                const newRiskCategory: RiskCategory = {
                    nameWhenOther: riskAreaCategory?.nameWhenOther,
                    assesedStatus: 0,
                    selected: (answerType === 'No') ? SelectedOptions.NOTRELEVANT : (answerSubType === 'Now') ? SelectedOptions.NOW : SelectedOptions.LATER,
                    riskListId: undefinedRiskAreaCategory[currentIndex]?.riskListId || '00000000-0000-0000-0000-000000000000',
                    riskFactor: answerType === 'No' ? RiskFactors.UNDEFINED : Math.round(riskFactorValue / 25),
                    task: {
                        assignedTo: userData?.userId,
                        deadline: (formatedDate !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? formatedDate : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                    }
                }
                const riskAreaIndex = currentIndex;
                if (riskAreaIndex > -1) {

                    const responsibleErr = schema.manageRiskAreasResponsible.validate(userData.userId).error;
                    if (answerType === "Yes") {
                        if (answerSubType === 'Later') {
                            dispatch(riskAreaContainerCreators.requestAddRiskCategory(newRiskCategory));
                            setErrObj({ nameWhenOther: undefined });
                            if (currentIndex + 1 < undefinedRiskAreaCategory?.length) {
                                setCurrentIndex(currentIndex + 1);
                            } else {
                                setCurrentIndex(-1);
                                setriskAreaCategory({ ...riskAreaCategory, riskListId: "00000000-0000-0000-0000-000000000000" })
                            }
                            setAnswerSubType('')
                            setAnswerType('')
                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                            setMeasuresDefinedDate(new Date())
                            setRiskFactorValue(75)
                            if (undefinedRiskAreaCategory?.length === riskAreaIndex + 1) {
                                setriskAreaCategory({
                                    ...intialRiskCategory,
                                    riskListId: '00000000-0000-0000-0000-000000000000'
                                });
                            } else {
                                setriskAreaCategory(undefinedRiskAreaCategory[riskAreaIndex + 1]);
                            }
                        } else if (answerSubType === 'Now') {
                            if (!responsibleErr) {
                                dispatch(riskAreaContainerCreators.requestAddRiskCategory(newRiskCategory));
                                setErrObj({ responsible: undefined });
                                if (currentIndex + 1 < undefinedRiskAreaCategory?.length) {
                                    setCurrentIndex(currentIndex + 1);
                                } else {
                                    setCurrentIndex(-1);
                                    setriskAreaCategory({ ...riskAreaCategory, riskListId: "00000000-0000-0000-0000-000000000000" })
                                }
                                setAnswerSubType('')
                                setAnswerType('')
                                setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                                setMeasuresDefinedDate(new Date())
                                setRiskFactorValue(75)
                                if (undefinedRiskAreaCategory?.length === riskAreaIndex + 1) {
                                    setriskAreaCategory({
                                        ...intialRiskCategory,
                                        riskListId: '00000000-0000-0000-0000-000000000000'
                                    });
                                } else {
                                    setriskAreaCategory(undefinedRiskAreaCategory[riskAreaIndex + 1]);
                                }
                            } else {
                                setErrObj({ responsible: responsibleErr?.message ? { message: intl.formatMessage({ id: responsibleErr?.message, defaultMessage: responsibleErr?.message }) } : undefined });
                            }
                        }
                    } else {
                        dispatch(riskAreaContainerCreators.requestAddRiskCategory(newRiskCategory));
                        if (currentIndex + 1 < undefinedRiskAreaCategory?.length) {
                            setCurrentIndex(currentIndex + 1);
                        } else {
                            setCurrentIndex(-1);
                            setriskAreaCategory({ ...riskAreaCategory, riskListId: "00000000-0000-0000-0000-000000000000" })
                        }
                        setAnswerSubType('')
                        setAnswerType('')
                        setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                        setMeasuresDefinedDate(new Date())
                        setRiskFactorValue(75)
                        if (undefinedRiskAreaCategory?.length === riskAreaIndex + 1) {
                            setriskAreaCategory({
                                ...intialRiskCategory,
                                riskListId: '00000000-0000-0000-0000-000000000000'
                            });
                        } else {
                            setriskAreaCategory(undefinedRiskAreaCategory[riskAreaIndex + 1]);
                        }
                    }
                }
                else {
                    setriskAreaCategory({
                        ...intialRiskCategory,
                        riskListId: '00000000-0000-0000-0000-000000000000'
                    });
                    const otherNameErr = nameOfRiskCategory ? schema.riskNameOther.validate(nameOfRiskCategory).error : riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' ? schema.riskNameOther.validate(nameOfRiskCategory).error : false;
                    if (answerType === "Yes") {
                        if (!otherNameErr) {
                            dispatch(riskAreaContainerCreators.requestAddRiskCategory(newRiskCategory));
                            setErrObj({ nameWhenOther: undefined });
                            setAnswerSubType('')
                            setAnswerType('')
                            setUserData({ ...userData, userId: String(decodedUserInfo?.payload?.userId) });
                            setMeasuresDefinedDate(new Date())
                            setRiskFactorValue(75)
                        }
                        else {
                            setErrObj({ nameWhenOther: otherNameErr?.message ? { message: intl.formatMessage({ id: otherNameErr?.message, defaultMessage: otherNameErr?.message }) } : undefined });
                        }
                    } else {
                        navigate("/manage-risk-areas?isCancel=0");
                    }
                }
            }
        }
        setLearnMoreInfo(false);
        //if (!id && answerType !== "") {
        //    setTimeout(() => {
        //        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        //    }, 1000)
        //}
    }
    /* istanbul ignore next */
    const handleAddressInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameOfRiskCategory(event.target.value);
    };

    function isDisableBtn() {
        if (riskAreaCategory?.riskListId !== '00000000-0000-0000-0000-000000000000') {
            if (answerType === '') {
                return true;
            } else if (answerType === 'No') {
                return false;
            } else if (answerType === 'Yes') {
                if (answerSubType === '') {
                    return true;
                } else {
                    return false;
                }
            }
        } else if (riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && lastQuetion) {
            if (answerType === '') {
                return true;
            } else if (answerType === 'No') {
                return false;
            } else if (answerType === 'Yes') {
                if (answerSubType === '') {
                    return true;
                } else {
                    return false;
                }
            }
        } else if (riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && !lastQuetion) {
            if (answerSubType === '') {
                return true;
            } else {
                return false;
            }
        }
    }
    return (
        <>
            <div>
                {/*{(riskAreaSelector.loading || loading || postLoading) ?
                    <div className='app-loader'>
                        <LoadingIcon />
                    </div>
                    :*/}
                <div className="flex-grow-1 flex flex-col justify-between">
                    <Container>
                        <div>
                            <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0'>
                                <div className="font-semibold text-xl">
                                    <div>{intl.formatMessage({ id: 'risk_areas', defaultMessage: 'risk_areas' })}</div>
                                </div>
                                {isSaved &&
                                    <div className="font-normal text-xs text-[#959595]">
                                        <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                    </div>
                                }
                            </div>
                            <div className="border w-full"></div>
                            <div>
                                <div className="font-bold pt-7 text-xs text-[#959595]">
                                </div>
                                <div className='m-auto w-full pb-2 align-middle items-center flex justify-between'>
                                    {editRiskCategoryObj ?
                                        <div className="font-semibold text-[32px] sm:text-2xl">
                                            <div>{editRiskCategoryObj.riskListId === "00000000-0000-0000-0000-000000000000"
                                                ? intl.formatMessage({ id: `edit_risk_area`, defaultMessage: 'edit_risk_area' })
                                                : intl.formatMessage({ id: `manage_riskarea_${editRiskCategoryObj?.riskListId}`, defaultMessage: `manage_riskarea_${editRiskCategoryObj?.riskListId}` })}</div>
                                        </div>
                                        :
                                        undefinedRiskAreaCategory[currentIndex]?.riskListId ?
                                            <div data-testid={`${testId}-manage-risk-area`} className="font-semibold text-[32px] sm:text-2xl">
                                                {intl.formatMessage({ id: `manage_riskarea_${undefinedRiskAreaCategory[currentIndex]?.riskListId}`, defaultMessage: `manage_riskarea_${undefinedRiskAreaCategory[currentIndex]?.riskListId}` })}
                                            </div>
                                            :
                                            <div className="font-semibold text-[32px] sm:text-2xl">
                                                {lastQuetion ?
                                                    intl.formatMessage({ id: `manage_riskarea_00000000-0000-0000-0000-000000000000`, defaultMessage: `manage_riskarea_00000000-0000-0000-0000-000000000000` })
                                                    :
                                                    intl.formatMessage({ id: `add_risk_area`, defaultMessage: 'add_risk_area' })
                                                }

                                            </div>
                                    }
                                </div>
                                <div>
                                    {infoDetails && infoDetails.length !== 0 &&
                                        <div className=" w-[540px] leading-4 mb-10 text-[#535353] sm:w-full">
                                            <span className="font-normal text-lg">{textShow}</span>
                                            {textShow.length > 90 &&
                                                <>
                                                    <span>{"... "}</span>
                                                    <span data-testid={`${testId}-learn-more-click`} className={"font-semibold text-[#3BA8FC] text-lg cursor-pointer w-max"} onClick={() => SideDrawerOpen()}>{intl.formatMessage({ id: 'learn_more', defaultMessage: 'learn_more' })}</span>
                                                </>
                                            }
                                        </div>}

                                    <div className={`w-full max-w-[420px] top-0 right-0  h-full fixed z-[1055] border-r sm:overflow-y-auto sm:w-full sm:max-w-full ${!learnMoreInfo ? 'translate-x-[100%] ease-in-out duration-300' : 'translate-x-[0%] ease-in-out duration-300'}`}>
                                        <SideDrawer testId={`${testId}-side-drawer`} drawerInfo={infoDetails} SideDrawerClose={SideDrawerClose} />
                                    </div>
                                </div>
                                <div className="flex sm:flex-col">
                                    {editRiskCategoryObj ?
                                        <>
                                            {editRiskCategoryObj.riskListId === "00000000-0000-0000-0000-000000000000" ?
                                                <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mb-10 mr-10 p-10 sm:p-5">
                                                    <label className="inline-block text-base font-bold mb-1">
                                                        {intl.formatMessage({ id: "name_of_the_risk_area", defaultMessage: 'name_of_the_risk_area' })}
                                                    </label>
                                                    <input
                                                        data-testid={`${testId}-name-of-risk-input`}
                                                        type="text"
                                                        maxLength={100}
                                                        className='bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2'
                                                        value={nameOfRiskCategory}
                                                        onChange={handleAddressInput}
                                                    />
                                                    {errObj.nameWhenOther?.message ?
                                                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                            <span>{errObj.nameWhenOther.message}</span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                <div className="mr-6 sm:w-full sm:mr-0">
                                                    <div data-testid={`${testId}-type-no-edit-risk-obj`} onClick={() => setAnswerType('No')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-type-yes-edit-risk-obj`} onClick={() => setAnswerType('Yes')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        :
                                        undefinedRiskAreaCategory[currentIndex]?.riskListId ?
                                            <div className="mr-6 sm:w-full sm:mr-0">
                                                <div data-testid={`${testId}-type-no-undefind-rc`} onClick={() => setAnswerType('No')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                    <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                </div>
                                                <div data-testid={`${testId}-type-yes-undefind-rc`} onClick={() => setAnswerType('Yes')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                    <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                {lastQuetion ?
                                                    <div className="mr-6 sm:w-full sm:mr-0">
                                                        <div data-testid={`${testId}-last-que-type-no`} onClick={() => setAnswerType('No')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                        </div>
                                                        <div data-testid={`${testId}-last-que-type-yes`} onClick={() => setAnswerType('Yes')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mb-10 mr-10 p-10 sm:p-5">
                                                        <label className="inline-block text-base font-bold mb-1">
                                                            {intl.formatMessage({ id: "name_of_the_risk_area", defaultMessage: 'name_of_the_risk_area' })}
                                                        </label>
                                                        <input
                                                            data-testid={`${testId}-name-of-riskare-two-input`}
                                                            type="text"
                                                            maxLength={100}
                                                            className='bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2'
                                                            value={nameOfRiskCategory}
                                                            onChange={handleAddressInput}
                                                        />
                                                        {errObj.nameWhenOther?.message ?
                                                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                                <span>{errObj.nameWhenOther.message}</span>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                }
                                            </div>
                                    }

                                    {riskAreaCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' && answerType === 'Yes' &&
                                        <MoreQuestionsExpand testId={`${testId}-more-question-1`} answerSubType={answerSubType} setAnswerSubType={setAnswerSubType} profileArr={profileArr} nameOfRiskArea={nameOfRiskArea} riskFactorValue={riskFactorValue} setRiskFactorValue={setRiskFactorValue} measuresDefinedDate={measuresDefinedDate} setMeasuresDefinedDate={setMeasuresDefinedDate} filterd={undefinedRiskAreaCategory} intialRiskCategory={riskAreaCategory} errRiskNameOther={errObj} setriskAreaCategory={setriskAreaCategory} answerType={answerType} isEditMode={!!id} riskListId={riskAreaCategory?.riskListId}
                                            formatedDate={formatedDate} setFormatedDate={setFormatedDate} userData={userData} setUserData={setUserData} userDropdownList={userDropdownList} setUserDropdownList={setUserDropdownList} lastQuetion={false}
                                        />
                                    }

                                    {riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && answerType === 'Yes' && lastQuetion &&
                                        <MoreQuestionsExpand testId={`${testId}-more-question-2`} answerSubType={answerSubType} setAnswerSubType={setAnswerSubType} profileArr={profileArr} nameOfRiskArea={nameOfRiskArea} riskFactorValue={riskFactorValue} setRiskFactorValue={setRiskFactorValue} measuresDefinedDate={measuresDefinedDate} setMeasuresDefinedDate={setMeasuresDefinedDate} filterd={undefinedRiskAreaCategory} intialRiskCategory={riskAreaCategory} errRiskNameOther={errObj} setriskAreaCategory={setriskAreaCategory} answerType={answerType} isEditMode={!!id} riskListId={riskAreaCategory?.riskListId}
                                            formatedDate={formatedDate} setFormatedDate={setFormatedDate} userData={userData} setUserData={setUserData} userDropdownList={userDropdownList} setUserDropdownList={setUserDropdownList} lastQuetion={true}
                                        />
                                    }

                                    {riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && !lastQuetion &&
                                        <MoreQuestionsExpand testId={`${testId}-more-question-3`} answerSubType={answerSubType} setAnswerSubType={setAnswerSubType} profileArr={profileArr} nameOfRiskArea={nameOfRiskArea} riskFactorValue={riskFactorValue} setRiskFactorValue={setRiskFactorValue} measuresDefinedDate={measuresDefinedDate} setMeasuresDefinedDate={setMeasuresDefinedDate} filterd={undefinedRiskAreaCategory} intialRiskCategory={riskAreaCategory} errRiskNameOther={errObj} setriskAreaCategory={setriskAreaCategory} answerType={answerType} isEditMode={!!id} riskListId={riskAreaCategory?.riskListId}
                                            formatedDate={formatedDate} setFormatedDate={setFormatedDate} userData={userData} setUserData={setUserData} userDropdownList={userDropdownList} setUserDropdownList={setUserDropdownList} lastQuetion={false}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                        {(undefinedRiskAreaCategory[currentIndex]?.riskListId || (lastQuetion)) &&
                            <div className='w-full bottom-0 align-middle items-center flex justify-between'>
                                <div>
                                    <StyledButton
                                        testId={`${testId}-previous-btn-rc`}
                                        onClick={() => handlePreviousRiskCategory()}
                                        disabled={(currentIndex === 0 || id) ? true : false}
                                        buttonClass={'sm:px-2'}
                                        flexReverse={'flex-row-reverse'}
                                        width={'w-48 sm:w-max'}
                                        align={'justify-start'}
                                        text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                        color={'text-[#535353]'}
                                        iconType={'arrowleft'}
                                        backgroundColor={'bg-[#EDEDED]'}
                                    />
                                </div>
                                {((role === "administrator") || (role === "editor")) ?
                                    <div>
                                        <StyledButton testId={`${testId}-next-btn`} onClick={() => addRiskArea()} buttonClass={'sm:px-2 PrimoryBtn'} disabled={isDisableBtn()} width={'w-48 sm:w-max'} align={'justify-end'} text={riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && !lastQuetion ? intl.formatMessage({ id: 'save', defaultMessage: 'save' }) : intl.formatMessage({ id: 'next', defaultMessage: 'next' })} color={isDisableBtn() ? 'text-[#535353]' : 'text-white'} iconType={isDisableBtn() ? 'blackarrow' : 'arrow'} backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        }
                        <div className="w-full flex items-center justify-between mt-4">
                            {(id || (riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && !lastQuetion)) ?
                                <StyledButton testId={`${testId}-cancel-btn`} onClick={() => handleCancel()} buttonClass={'sm:px-2'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })} color={'text-[#535353]'} backgroundColor={'bg-[#EDEDED]'} />
                                :
                                <div className="w-48 h-[52px] flex items-center justify-center">
                                    <button data-testid={`${testId}-cancel-btn-2`} onClick={() => handleCancel()} className="flex items-center justify-center">
                                        <span className="text-[#535353] font-family-arial font-semibold">{intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}</span>
                                    </button>
                                </div>
                            }
                            {(id || (riskAreaCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && !lastQuetion)) && <StyledButton testId={`${testId}-save-btn-add-riskarea`} onClick={() => addRiskArea()} buttonClass={'sm:px-2 PrimoryBtn'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })} disabled={isDisableBtn()} color={isDisableBtn() ? 'text-[#535353]' : 'text-white'} backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />}
                        </div>
                    </Container>
                </div>
                {/*}*/}
                {singleRiskCategory === undefined &&
                    <Modal show={isWromgOrgModal} onClose={() => ""}>
                        <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                            <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                        </div>
                        <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                            <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                        </div>
                        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                            <StyledButton
                                testId={`${testId}-goto-dashboard-bia`}
                                onClick={() => handleWrongOrgModel()}
                                buttonClass={'PrimoryBtn'}
                                width={"w-[220px] sm:w-40"}
                                align={"justify-end"}
                                text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                                color={"text-white mr-3 text-base"}
                                iconType={'home'}
                                backgroundColor={"bg-black"}
                            />
                        </div>
                    </Modal>}
            </div>
        </>
    );
}