import axios, {
    AxiosError,
    AxiosPromise,
    AxiosResponse
} from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { biaContentService } from '../../services/BIAService';
import { biaContainerTypes, biaContainerCreators } from './biaReducer';
import { Statuses } from "@onesixeight-nl/classes";
import { getCmsURL } from '../../utils';
/* istanbul ignore next */
export const prodBiaContentService = (roles: string, contentType: string, BIAStatus: number, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/popups?populate=Video&filters[Type][$eq]=${'BIA'}&filters[UserRole][${roles}][$eq]=true&filters[Status][$eq]=${Statuses[BIAStatus]}&locale=${decodedUserInfo?.payload?.prefLang}`)
/* istanbul ignore next */
export const biaHelpTextService = (contentType: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[Type][$eq]=${contentType}&locale=${decodedUserInfo?.payload?.prefLang}`)

type GetBiaContentData = {
    contentType: string;
}

type GetProdBiaContentData = {
    contentType: string;
    BIAStatus: number;
    decodedUserInfo: DecodedUserInfo | null;
}

type GetBiaHelpTextData = {
    contentType: string;
    decodedUserInfo: DecodedUserInfo | null;
}

const {
    REQUEST_GET_BIA_CONTENT,
    REQUEST_GET_PROD_BIA_CONTENT,
    REQUEST_GET_BIA_HELP_TEXT
} = biaContainerTypes;

/* istanbul ignore next */
export function* getBiaContentData(data: GetBiaContentData) {
    try {
        const response: AxiosResponse = yield call(biaContentService, data.contentType);
        yield put(biaContainerCreators.successGetBiaContent(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(biaContainerCreators.failureGetBiaContent(e.response?.data.errors[0].message));
        } else {
            yield put(biaContainerCreators.failureGetBiaContent("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getProdBiaContentData(data: GetProdBiaContentData) {
    const role = data.decodedUserInfo?.payload.role;
    const getRoles = () => {
        if (role === 'administrator') {
            return 'Administrator'
        } else if (role === 'editor') {
            return 'Editor'
        } else if (role === 'reader') {
            return 'Reader'
        } else if (role === 'accountant') {
            return 'Accountant'
        } else if (role === 'insureanceadvisor') {
            return 'InsureanceAdvisor'
        } else if (role === 'partneraccountant') {
            return 'PartnerAccountant'
        } else if (role === 'partnerinsureanceadvisor') {
            return 'PartnerInsureanceAdvisor'
        } else {
            return ''
        }
    }
    try {
        const response: AxiosResponse = yield call(prodBiaContentService, getRoles(), data.contentType, data.BIAStatus, data.decodedUserInfo);
        yield put(biaContainerCreators.successGetProdBiaContent(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(biaContainerCreators.failureGetProdBiaContent(e.response?.data.errors[0].message));
        } else {
            yield put(biaContainerCreators.failureGetProdBiaContent("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getBiaHelpTextData(data: GetBiaHelpTextData) {
    try {
        const response: AxiosResponse = yield call(biaHelpTextService, data.contentType, data.decodedUserInfo);
        yield put(biaContainerCreators.successGetBiaHelpText(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(biaContainerCreators.failureGetBiaHelpText(e.response?.data.errors[0].message));
        } else {
            yield put(biaContainerCreators.failureGetBiaHelpText("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export default function* biaContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_GET_BIA_CONTENT, getBiaContentData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_PROD_BIA_CONTENT, getProdBiaContentData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_BIA_HELP_TEXT, getBiaHelpTextData);
}
