import { ListOptionType, Statuses } from "@onesixeight-nl/classes";
import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import { put, call, takeLatest } from "redux-saga/effects";
import { getOrganizationUsersService } from "../../services/OrganizationUser";
import { deleteRiskCategoryService, getListOptionsDataService, getRiskCategoryValueDataService, putRiskCategoryDataService, riskCategoryContentService } from "../../services/RiskArea";
import { addRiskCategoryDataService } from "../../services/RiskArea";
import { getRiskCategoriesDataService } from "../../services/RiskArea";
import { getCmsURL } from "../../utils";
import { riskAreaContainerTypes, riskAreaContainerCreators } from "./reducer";
import { clearDashboardContainerCreators } from "../ProtectedContainer/dashboardReducer";
import { clearLocationStatusCreators } from "../LocationsContainer/locationReducer";
import { redirectUrlCreators } from "../LoginContainer/reducer";

/* istanbul ignore next */
export const prodRiskCategoryContentService = (roles: string, contentType: string, manageRiskCategoryStatus: number, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/popups?populate=Video&filters[Type][$eq]=${'MANAGERISKAREAS'}&filters[UserRole][${roles}][$eq]=true&filters[Status][$eq]=${Statuses[manageRiskCategoryStatus]}&locale=${decodedUserInfo?.payload?.prefLang}`)

/* istanbul ignore next */
export const manageRiskHelpTextService = (contentType: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[Type][$eq]=${contentType}&locale=${decodedUserInfo?.payload?.prefLang}`)

/* istanbul ignore next */
export const riskCategoryHelpTextService = (riskListId: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[RiskCategory][$eq]=${riskListId}&locale=${decodedUserInfo?.payload?.prefLang}`)

/* istanbul ignore next */
export const assessmentsHelpTextService = (contentType: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[Type][$eq]=${contentType}&locale=${decodedUserInfo?.payload?.prefLang}`)

type ListOptionsDataCallType = {
  id: string;
  data: RiskCategory;
  assessment?: boolean;
};

type GetRiskCategoriesDataCall = {
  navigate?: (path: string) => void;
  isCancel?: string;
}

type GetRiskCategoryContent = {
  contentType: string;
}

type DeleteRiskCategory = {
  riskCategoryId: string;
}

type GetProdRiskCategoryContent = {
  contentType: string;
  manageRiskCategoryStatus: number;
  decodedUserInfo: DecodedUserInfo | null;
}

type GetManageRiskHelpTextData = {
  contentType: string;
  decodedUserInfo: DecodedUserInfo | null;
}

type GetRiskCategoryHelpTextData = {
  riskListId: string;
  decodedUserInfo: DecodedUserInfo | null;
}

type GetAssessmentsHelpTextData = {
  contentType: string;
  decodedUserInfo: DecodedUserInfo | null;
}

type RiskCategory = {
  data: {
    riskListId: string;
    nameWhenOther: string;
    selected: number;
    riskFactor: number;
    assesedStatus: number;
  };
  navigate?: (path: string) => void;
};

const { REQUEST_ADD_RISK_CATEGORY, REQUEST_GET_RISK_CATEGORIES, REQUEST_GET_LIST_OPTIONS, REQUEST_EDIT_VALUE, REQUEST_PUT_RISK_CATEGORY, REQUEST_GET_ORGANIZATION_USERS, REQUEST_GET_RISK_CATEGORY_CONTENT,
  REQUEST_GET_PROD_RISK_CATEGORY_CONTENT, REQUEST_GET_MANAGE_RISK_HELP_TEXT, REQUEST_GET_RISK_CATEGORY_HELP_TEXT, REQUEST_DELETE_RISK_CATEGORY, REQUEST_GET_ASSESSMENTS_HELP_TEXT } =
  riskAreaContainerTypes;

/* istanbul ignore next */
export function* addRiskCategoriesDataCall(data: RiskCategory) {
  try {
    const response: AxiosResponse = yield call(
      addRiskCategoryDataService,
      data.data
    );
    if (response && data.navigate) {
      data.navigate("/manage-risk-areas?isCancel=0");
    }
    yield put(riskAreaContainerCreators.successAddRiskCategory(response.data, response.status, response.config.method));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        riskAreaContainerCreators.failureAddRiskCategory(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        riskAreaContainerCreators.failureAddRiskCategory(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* getRiskCategoriesDataCall(data: GetRiskCategoriesDataCall) {
  try {
    const response: AxiosResponse = yield call(
      getRiskCategoriesDataService,
    );
    if (response && response.data) {
      const filteredRCOverview = response.data.find((ele: any) => ele.selected === 0);
      if (filteredRCOverview && data.isCancel !== '0' && data.navigate) {
        data.navigate("/risk-areas");
      }
    }
    yield put(clearLocationStatusCreators.clearLocationStatus());
    yield put(clearDashboardContainerCreators.clearDashboardStatus());
    yield put(riskAreaContainerCreators.successGetRiskCategories(response.data));
    yield put(redirectUrlCreators.clearRedirectUrl())
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        riskAreaContainerCreators.failureGetRiskCategories(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        riskAreaContainerCreators.failureGetRiskCategories(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
export function* getListoptionsDataCall() {
  try {
    const response: AxiosResponse = yield call(
      getListOptionsDataService,
      ListOptionType.RISK_CATEGORY
    );
    yield put(riskAreaContainerCreators.successGetListOptions(response.data));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        riskAreaContainerCreators.failureGetListOptions(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        riskAreaContainerCreators.failureGetListOptions(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
//risk category value
export function* getRiskCategoryValueDataCall(data: ListOptionsDataCallType) {
  try {
    const response: AxiosResponse = yield call(
      getRiskCategoryValueDataService,
      data.id
    );
    yield put(redirectUrlCreators.clearRedirectUrl())
    yield put(riskAreaContainerCreators.successEditValue(response.data));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        riskAreaContainerCreators.failureEditValue(
          e.response?.data.errors[0].message
        )
      );
      yield put(redirectUrlCreators.clearRedirectUrl())
    } else {
      yield put(
        riskAreaContainerCreators.failureEditValue(
          "Something went wrong, please try again later!"
        )
      );
      yield put(redirectUrlCreators.clearRedirectUrl())
    }
  }
}

/* istanbul ignore next */
export function* getOrganizationUsersDataCall(data: ListOptionsDataCallType) {
  try {
    const response: AxiosResponse = yield call(
      getOrganizationUsersService,
      data.id
    );
    yield put(riskAreaContainerCreators.successGetOrganizationUsers(response.data));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        riskAreaContainerCreators.failureGetOrganizationUsers(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        riskAreaContainerCreators.failureGetOrganizationUsers(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
//Put
export function* putRiskCategoryDataCall(data: ListOptionsDataCallType) {
  try {
    const response: AxiosResponse = yield call(
      putRiskCategoryDataService,
      data.id, data.data
    );
    if (!data.assessment) {
      data.data.navigate && data.data.navigate('/manage-risk-areas?isCancel=0')
    }
    yield put(riskAreaContainerCreators.successPutRiskCategory(response.data, response.status, response.config.method));
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(
        riskAreaContainerCreators.failurePutRiskCategory(
          e.response?.data.errors[0].message
        )
      );
    } else {
      yield put(
        riskAreaContainerCreators.failurePutRiskCategory(
          "Something went wrong, please try again later!"
        )
      );
    }
  }
}

/* istanbul ignore next */
//DELETE
export function* deleteRiskCategory(data: DeleteRiskCategory) {
  try {
    const response: AxiosResponse = yield call(deleteRiskCategoryService, data.riskCategoryId);
    yield put(riskAreaContainerCreators.successDeleteRiskCategory(response.data, response.config.method))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(riskAreaContainerCreators.failureDeleteRiskCategory(e.response?.data.errors[0].message));
    } else {
      yield put(riskAreaContainerCreators.failureDeleteRiskCategory("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getRiskCategoryContent(data: GetRiskCategoryContent) {
  try {
    const response: AxiosResponse = yield call(riskCategoryContentService, data.contentType);
    yield put(riskAreaContainerCreators.successGetRiskCategoryContent(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(riskAreaContainerCreators.failureGetRiskCategoryContent(e.response?.data.errors[0].message));
    } else {
      yield put(riskAreaContainerCreators.failureGetRiskCategoryContent("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getProdRiskCategoryContent(data: GetProdRiskCategoryContent) {
  const role = data.decodedUserInfo?.payload.role;
  const getRoles = () => {
    if (role === 'administrator') {
      return 'Administrator'
    } else if (role === 'editor') {
      return 'Editor'
    } else if (role === 'reader') {
      return 'Reader'
    } else if (role === 'accountant') {
      return 'Accountant'
    } else if (role === 'insureanceadvisor') {
      return 'InsureanceAdvisor'
    } else if (role === 'partneraccountant') {
      return 'PartnerAccountant'
    } else if (role === 'partnerinsureanceadvisor') {
      return 'PartnerInsureanceAdvisor'
    } else {
      return ''
    }
  }
  try {
    const response: AxiosResponse = yield call(prodRiskCategoryContentService, getRoles(), data.contentType, data.manageRiskCategoryStatus, data.decodedUserInfo);
    yield put(riskAreaContainerCreators.successGetProdRiskCategoryContent(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(riskAreaContainerCreators.failureGetProdRiskCategoryContent(e.response?.data.errors[0].message));
    } else {
      yield put(riskAreaContainerCreators.failureGetProdRiskCategoryContent("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getManageRiskHelpTextData(data: GetManageRiskHelpTextData) {
  try {
    const response: AxiosResponse = yield call(manageRiskHelpTextService, data.contentType, data.decodedUserInfo);
    yield put(riskAreaContainerCreators.successGetManageRiskHelpText(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(riskAreaContainerCreators.failureGetManageRiskHelpText(e.response?.data.errors[0].message));
    } else {
      yield put(riskAreaContainerCreators.failureGetManageRiskHelpText("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getRiskCategoryHelpTextData(data: GetRiskCategoryHelpTextData) {
  try {
    const response: AxiosResponse = yield call(riskCategoryHelpTextService, data.riskListId, data.decodedUserInfo);
    yield put(riskAreaContainerCreators.successGetRiskCategoryHelpText(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(riskAreaContainerCreators.failureGetRiskCategoryHelpText(e.response?.data.errors[0].message));
    } else {
      yield put(riskAreaContainerCreators.failureGetRiskCategoryHelpText("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export function* getAssessmentsHelpTextData(data: GetAssessmentsHelpTextData) {
  try {
    const response: AxiosResponse = yield call(assessmentsHelpTextService, data.contentType, data.decodedUserInfo);
    yield put(riskAreaContainerCreators.successGetAssessmentsHelpText(response.data))
  } catch (e) {
    if (e instanceof AxiosError) {
      yield put(riskAreaContainerCreators.failureGetAssessmentsHelpText(e.response?.data.errors[0].message));
    } else {
      yield put(riskAreaContainerCreators.failureGetAssessmentsHelpText("Something went wrong, please try again later!"));
    }
  }
}

/* istanbul ignore next */
export default function* riskAreaContainerSaga() {
  // @ts-ignore
  yield takeLatest(REQUEST_GET_LIST_OPTIONS, getListoptionsDataCall);
  // @ts-ignore
  yield takeLatest(REQUEST_ADD_RISK_CATEGORY, addRiskCategoriesDataCall);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_RISK_CATEGORIES, getRiskCategoriesDataCall);
  // @ts-ignore
  yield takeLatest(REQUEST_PUT_RISK_CATEGORY, putRiskCategoryDataCall);
  // @ts-ignore
  yield takeLatest(REQUEST_EDIT_VALUE, getRiskCategoryValueDataCall);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_ORGANIZATION_USERS, getOrganizationUsersDataCall);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_RISK_CATEGORY_CONTENT, getRiskCategoryContent);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_PROD_RISK_CATEGORY_CONTENT, getProdRiskCategoryContent);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_MANAGE_RISK_HELP_TEXT, getManageRiskHelpTextData);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_RISK_CATEGORY_HELP_TEXT, getRiskCategoryHelpTextData);
  // @ts-ignore
  yield takeLatest(REQUEST_DELETE_RISK_CATEGORY, deleteRiskCategory);
  // @ts-ignore
  yield takeLatest(REQUEST_GET_ASSESSMENTS_HELP_TEXT, getAssessmentsHelpTextData);
}
