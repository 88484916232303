import { useEffect, useState, useRef } from "react";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg";
import { useIntl } from "react-intl";

type Option = {
    value: string;
    label: string;
}

type Props = {
    value?: string;
    options?: Option[];
    onChange?: (value: string) => void;
    testId?: string;
    error?: string;
}


const LanguageDropdown = ({ value, options, onChange, testId = '', error = '' }: Props) => {
    const intl = useIntl();
    const ref = useRef<HTMLHeadingElement>(null);
    const [showLangOptions, setShowLangOptions] = useState<boolean>(false);
    const selectedLangItem: Option | null = options?.find((item) => item.value === value) || null;

    const initialOption: Option = {
        label: intl.formatMessage({ id: 'select', defaultMessage: 'select' }),
        value: "",
    }

    const [selectedOption, setSelectedOption] = useState<Option>(selectedLangItem ?? initialOption);
    const handleChange = (option: Option) => {
        setSelectedOption({ ...option });
        setShowLangOptions(false);
        if (onChange) onChange(option.value);
    }

    useEffect(() => {
        if (selectedLangItem && Object.keys(selectedLangItem).length) {
            setSelectedOption(selectedLangItem || initialOption)
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, selectedLangItem]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        const handleClickOutsideArea = (event: any) => {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                setShowLangOptions(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutsideArea);
    }, [ref]);

    return (
        <div className="relative" ref={ref} data-testid={testId}>
            <div>
                <button type="button" className={`inline-flex justify-between font-family-arial w-full rounded-md border shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none ${error !== '' ? 'border-[red]' : 'border-gray-300'}`} aria-expanded="true" aria-haspopup="true" onClick={() => setShowLangOptions(!showLangOptions)}>
                    {selectedOption?.label}
                    <img src={ChevronDownIcon} alt="" />
                </button>
            </div>
            <div className={(showLangOptions ? "block " : "hidden ") + "origin-top-right absolute right-0 w-full z-50 rounded-md shadow-lg overflow-hidden bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"} role="menu" aria-orientation="vertical">
                <ul role="listbox" className="max-h-56 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                    {options?.map((option: Option, index: number) => (
                        <li role="option" aria-selected data-testid={`${testId}-option-item-${index}`} className={`${selectedOption.value === option.value ? "bg-[#FCAF3B] " : ""} text-sm text-gray-900 cursor-pointer select-none hover:bg-[#FCAF3B] relative py-2 pl-4 pr-9`} onClick={() => handleChange(option)} key={`${testId}-option-item-${option.value}`}>
                            <div className="flex items-center space-x-3">
                                <span className="font-normal font-family-arial block truncate">
                                    {option.label}
                                </span>
                            </div>
                        </li>
                    )
                    )}
                </ul>
            </div>
        </div>
    )
}

export default LanguageDropdown
