import { useState, useEffect } from 'react'
import LoadingIcon from '../../assets/icons/loading-icon';
import NotificationToast from '../ToastComponent';
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import StyledButton from '../StyledButton';
import { selectDecodedUserInfo } from '../../AppSelector';
import { listOptionsContainerCreators } from '../../containers/OrganisationContainer/reducer';
import EditCurrentOrganisationPage from './EditCurrentOrganisationPage';
import Modal from '../Modal/Modal';
import { useNavigate } from 'react-router-dom';
import Container from '../Container/Container';
import Dropdown from '../Dropdown';

const testId = `current-organization`;

export type Organisation = {
  organizationId: string;
  companyName: string;
  chamberofcommercenr: string;
  street: string;
  houseNumber: string;
  houseNumberSuffix: string;
  postalcode: string;
  city: string;
  country: string;
  type: string;
  created: Date;
  modified: Date;
  twoFa: number;
  twoFaOk: boolean;
  planningMethod: number;
}

type TwoFaDropDownList = {
  label: string,
  value: number
};

export default function CurrentOrganisationLayout() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
  const decodedInfo: DecodedUserInfo | null = useSelector(selectDecodedUserInfo);
  const role: string | undefined = decodedInfo?.payload.role;
  const [currentOrgInfo, setCurrentOrgInfo] = useState<Organisation>()
  const [isDeleteCurrentOrgModal, setIsDeleteCurrentOrgModal] = useState<boolean>(false)
  const [showDeleteCurrrentOrgToast, setShowDeleteCurrentOrgToast] = useState<boolean>(false)
  const [isEditOrganisation, setIsEditOrganisation] = useState<boolean>(false);
  const [editOrganisation, setEditOrganisation] = useState<Organisation | null>(null);
  const [isEmailModal, setIsEmailModal] = useState<boolean>(false);
  const [isPlanningModal, setIsPlanningModal] = useState<boolean>(false);
  const [defualtTwoFa, setDefualtTwoFa] = useState<number>();
  const twoFaDropDownList: TwoFaDropDownList[] = [
    { label: intl.formatMessage({ id: "email_or_authenticator", defaultMessage: "email_or_authenticator" }), value: 1 },
    { label: intl.formatMessage({ id: "authenticator", defaultMessage: "authenticator" }), value: 2 },
  ]
  const faDropDownList: TwoFaDropDownList[] = [...twoFaDropDownList];

  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [timeCounter, setTimeCounter] = useState<number>(1);
  const [planingMethod, setPlaningMethod] = useState<number>(0);

  useEffect(() => {
    if (currentOrgInfo && currentOrgInfo?.planningMethod !== null) {
      setPlaningMethod(currentOrgInfo?.planningMethod)
    }
  }, [currentOrgInfo])

  useEffect(() => {
    if (decodedInfo && decodedInfo.payload) {
      dispatch(listOptionsContainerCreators.requestGetCurrentOrgInfo(decodedInfo.payload.orgId))
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedInfo?.payload])/* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (listOptionsSelector.isReloadOrgInfo) {
      decodedInfo && dispatch(listOptionsContainerCreators.requestGetCurrentOrgInfo(decodedInfo.payload.orgId))
    }
  }, [listOptionsSelector.isReloadOrgInfo])/* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (listOptionsSelector.currentOrgInfo) {
      setCurrentOrgInfo(listOptionsSelector.currentOrgInfo)
      setDefualtTwoFa(listOptionsSelector?.currentOrgInfo?.twoFa)
    }
  }, [listOptionsSelector])

  useEffect(() => {
    if (listOptionsSelector?.status !== null && listOptionsSelector?.status !== undefined && !listOptionsSelector.currentOrgInfo) {
      setIsSaved(true);
      setTimeCounter(1);
    }
  }, [listOptionsSelector.status])

  useEffect(() => {
    if (isSaved) {
      let intervalTimeCounter = setInterval(() => {
        setTimeCounter(timeCounter + 1);
        if (timeCounter === 3) {
          setIsSaved(false)
          clearInterval(intervalTimeCounter);
          setTimeCounter(1);
        }
      }, 1000);
      return () => {
        clearInterval(intervalTimeCounter);
      };
    }
  }, [timeCounter, isSaved]);

  function onCancelUser() {
    setIsEditOrganisation(false)
    setEditOrganisation(null)
  }

  function handleDeleteCurrentOrgModalClose() {
    setIsDeleteCurrentOrgModal(false)
  }

  function editOrganisationPage() {
    if (currentOrgInfo) {
      setIsEditOrganisation(true)
      setEditOrganisation(currentOrgInfo);
    }
  }

  const deleteOrganization = () => {
    setIsDeleteCurrentOrgModal(true);
  }

  function handleDeleteCurrentOrg() {
    dispatch(listOptionsContainerCreators.requestDeleteUserOrg(decodedInfo?.payload.orgId, setShowDeleteCurrentOrgToast, navigate))
    setIsDeleteCurrentOrgModal(false);
  }

  if (isEditOrganisation && editOrganisation) {
    return <EditCurrentOrganisationPage editOrganisation={editOrganisation} onCancel={() => onCancelUser()} />
  }

  function editEmailModalOpen() {
    setIsEmailModal(true);
  }

  function editPlanningMethod() {
    setIsPlanningModal(true);
  }

  function handlePlanningModalClose() {
    setIsPlanningModal(false);
  }

  function handleEmailModalClose() {
    setIsEmailModal(false);
  }

  function handlePlanningMethodSave() {
    const payload = {
      companyName: currentOrgInfo?.companyName,
      chamberofcommercenr: currentOrgInfo?.chamberofcommercenr,
      street: currentOrgInfo?.street,
      houseNumber: Number(currentOrgInfo?.houseNumber),
      houseNumberSuffix: currentOrgInfo?.houseNumberSuffix,
      postalcode: currentOrgInfo?.postalcode,
      city: currentOrgInfo?.city,
      country: currentOrgInfo?.country,
      twoFa: defualtTwoFa,
      planningMethod: planingMethod

    }
    dispatch(listOptionsContainerCreators.requestPutCurrentOrgInfo(currentOrgInfo?.organizationId, payload))
    setIsPlanningModal(false);
  }

  function handleEmailSave() {
    const payload = {
      companyName: currentOrgInfo?.companyName,
      chamberofcommercenr: currentOrgInfo?.chamberofcommercenr,
      street: currentOrgInfo?.street,
      houseNumber: Number(currentOrgInfo?.houseNumber),
      houseNumberSuffix: currentOrgInfo?.houseNumberSuffix,
      postalcode: currentOrgInfo?.postalcode,
      city: currentOrgInfo?.city,
      country: currentOrgInfo?.country,
      twoFa: defualtTwoFa
    }
    dispatch(listOptionsContainerCreators.requestPutCurrentOrgInfo(currentOrgInfo?.organizationId, payload))
    setIsEmailModal(false);
  }

  function twoFactor() {
    if (currentOrgInfo?.twoFa === 1) {
      return intl.formatMessage({ id: "email_or_authenticator", defaultMessage: "email_or_authenticator" })
    } else if (currentOrgInfo?.twoFa === 2) {
      return intl.formatMessage({ id: "authenticator", defaultMessage: "authenticator" })
    }
  }

  function handleSetTwoFa(value: number) {
    setDefualtTwoFa(value);
  }

  function planMethod() {
    if (currentOrgInfo?.planningMethod === 1) {
      return intl.formatMessage({ id: "deadline_based", defaultMessage: "deadline_based" })
    } else if (currentOrgInfo?.planningMethod === 2) {
      return intl.formatMessage({ id: "agile_based", defaultMessage: "agile_based" })
    } else {
      return intl.formatMessage({ id: "to_be_selected", defaultMessage: "to_be_selected" })
    }
  }

  function handleChangePlaningMethod(method: number) {
    setPlaningMethod(method);
  }

  return (
    <>
      {listOptionsSelector.loading ?
        <div className='app-loader'>
          <LoadingIcon />
        </div>
        :
        <div>
          <Container>
            {isSaved ?
              <div className="font-normal text-xs text-[#959595] text-end">
                <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
              </div>
              :
              <div className="font-normal text-xs text-[#959595] text-end">
                <div className="h-4">{"  "}</div>
              </div>
            }
            <NavigationMenu />
            <div className='my-10 mt-10 font-semibold text-4xl flex items-start justify-start sm:text-3xl'>
              {intl.formatMessage({ id: 'current_organization', defaultMessage: 'current_organization' })}
            </div>
            <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
              <div className="flex gap-8">
                <div className='flex-1'>
                  <div className='flex-col'>
                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                      {intl.formatMessage({ id: "name", defaultMessage: 'name' })}
                    </label>
                  </div>
                  <div>
                    <label data-testid={`${testId}-company-name`}>
                      {currentOrgInfo && currentOrgInfo.companyName ? currentOrgInfo.companyName : "-"}
                    </label>
                  </div>
                </div>
                <div className="flex-1">
                  <div className='flex-col'>
                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                      {intl.formatMessage({ id: "kvK_number", defaultMessage: 'kvK_number' })}
                    </label>
                  </div>
                  <div>
                    <label data-testid={`${testId}-chamber-of-commercenr`}>
                      {currentOrgInfo && currentOrgInfo.chamberofcommercenr ? currentOrgInfo.chamberofcommercenr : "-"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex gap-8">
                <div className="flex-1">
                  <div className='flex-col'>
                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                      {intl.formatMessage({ id: "address", defaultMessage: 'address' })}
                    </label>
                  </div>
                  <div>
                    <label>
                      {currentOrgInfo && currentOrgInfo.street ? currentOrgInfo.street : ""}{" "}
                      {currentOrgInfo && currentOrgInfo.houseNumber ? currentOrgInfo.houseNumber : ""}{" "}
                      {currentOrgInfo && currentOrgInfo.houseNumberSuffix ? currentOrgInfo.houseNumberSuffix : ""}{" "}<br />
                      {currentOrgInfo && currentOrgInfo.postalcode ? currentOrgInfo.postalcode : ""}{" "}
                      {currentOrgInfo && currentOrgInfo.city ? currentOrgInfo.city : ""}
                    </label>
                  </div>
                </div>
              </div>
              {(role === "administrator") ?
                <div className="w-full align-middle items-center flex sm:flex-col justify-between mt-8 gap-4">
                  <StyledButton
                    testId={`${testId}-edit-organization`}
                    buttonClass={'px-4 py-2'}
                    width={'min-w-36 sm:w-full'}
                    height={`h-10`}
                    align={'justify-start'}
                    text={`${intl.formatMessage({ id: 'edit_organisation', defaultMessage: 'edit_organisation' })}`}
                    color={'text-gray-700'}
                    flexReverse={'flex-row-reverse'}
                    iconType={""}
                    backgroundColor={'transparent border-1'}
                    onClick={() => editOrganisationPage()}
                  />

                  {(decodedInfo?.payload.orgType === 0) &&
                    <StyledButton
                      testId={`${testId}-delete-organization`}
                      buttonClass={'px-4 py-2'}
                      width={'min-w-36 max-w-max'}
                      height={`h-10`}
                      align={'justify-end'}
                      text={intl.formatMessage({ id: 'Delete_organization', defaultMessage: 'Delete_organization' })}
                      color={'text-gray-700'}
                      flexReverse={'flex-row-reverse'}
                      iconType={""}
                      backgroundColor={'transparent border-1'}
                      onClick={() => deleteOrganization()}
                    />
                  }
                </div>
                :
                <></>
              }
              {showDeleteCurrrentOrgToast && <NotificationToast text={listOptionsSelector.error ? listOptionsSelector.error : ''} iconType={listOptionsSelector.error ? intl.formatMessage({ id: 'failure' }) : intl.formatMessage({ id: 'success' })} color={listOptionsSelector.error ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showDeleteCurrrentOrgToast} onClick={() => setShowDeleteCurrentOrgToast(false)} />}
            </div>

            <div>
              <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                <div className="flex-1">
                  <div className='flex-col'>
                    <div className='inline-block text-base font-bold mb-1 min-w-max'>
                      {intl.formatMessage({ id: 'two_factor_login', defaultMessage: 'two_factor_login' })}
                    </div>
                    <div data-testid={`${testId}-two-fa`} className="">
                      {twoFactor()}
                    </div>
                  </div>
                </div>
                {(role === "administrator") &&
                  <div>
                    <StyledButton
                      testId={`${testId}-edit-two-fa`}
                      buttonClass={'px-4 py-2'}
                      width={'min-w-36 max-w-max'}
                      height={`h-10`}
                      align={'justify-start'}
                      text={intl.formatMessage({ id: 'edit_two_fa', defaultMessage: 'edit_two_fa' })}
                      color={'text-gray-700'}
                      flexReverse={'flex-row-reverse'}
                      iconType={""}
                      backgroundColor={'transparent border-1'}
                      onClick={() => editEmailModalOpen()}
                    />
                    {/*{showToast && <NotificationToast text={listOptionsSelector.inviteError ? listOptionsSelector.inviteError : intl.formatMessage({ id: 'added_to_org' })} iconType={listOptionsSelector.inviteError ? intl.formatMessage({ id: 'failure' }) : intl.formatMessage({ id: 'success' })} color={listOptionsSelector.inviteError ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showToast} onClick={() => setShowToast(false)} />}*/}
                  </div>
                }
              </div>
            </div>

            <div>
              <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                <div className="flex-1">
                  <div className='flex-col'>
                    <div className='inline-block text-base font-bold mb-1 min-w-max'>
                      {intl.formatMessage({ id: 'planning_method', defaultMessage: 'planning_method' })}
                    </div>
                    <div data-testid={`${testId}-planning-method`} className="">
                      {planMethod()}
                    </div>
                  </div>
                </div>
                {(role === "administrator") &&
                  <div>
                    <StyledButton
                    testId={`${testId}-edit-plan-method-btn`}
                      buttonClass={'px-4 py-2'}
                      width={'min-w-36 max-w-max'}
                      height={`h-10`}
                      align={'justify-start'}
                      text={intl.formatMessage({ id: 'edit_planning_method', defaultMessage: 'edit_planning_method' })}
                      color={'text-gray-700'}
                      flexReverse={'flex-row-reverse'}
                      iconType={""}
                      backgroundColor={'transparent border-1'}
                      onClick={() => editPlanningMethod()}
                    />
                    {/*{showToast && <NotificationToast text={listOptionsSelector.inviteError ? listOptionsSelector.inviteError : intl.formatMessage({ id: 'added_to_org' })} iconType={listOptionsSelector.inviteError ? intl.formatMessage({ id: 'failure' }) : intl.formatMessage({ id: 'success' })} color={listOptionsSelector.inviteError ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showToast} onClick={() => setShowToast(false)} />}*/}
                  </div>
                }
              </div>
            </div>
            <div>
              <Modal data-testid={`${testId}-planning-modal`} show={isPlanningModal} onClose={() => ""}>
                <div className="lg:min-w-[700px] lg:min-h-[225px] " >
                  <div className="flex font-bold ml-4 h-10">
                    {intl.formatMessage({ id: 'planning_method', defaultMessage: 'planning_method' })}
                  </div>
                  <div className='flex justify-center items-center h-[160px] w-[700px] space-x-4'>
                    <div data-testid={`${testId}-plan-method-click`} className={`border rounded-xl w-[45%] h-full p-4 ${planingMethod === 1 ? 'bg-[#FFF9F0] border-[#FCAF3B]' : 'bg-white border '} cursor-pointer`} onClick={() => handleChangePlaningMethod(1)}>
                      <div className="font-bold flex">
                        {intl.formatMessage({ id: 'deadline_based', defaultMessage: 'deadline_based' })}
                        <span className='m-1 hidden'>
                          <input data-testid={`${testId}-change-plan-input`} type="radio" onChange={() => handleChangePlaningMethod(1)} checked={planingMethod === 1} />
                        </span>
                      </div>
                      <div className="mt-2">
                        {intl.formatMessage({ id: 'deadline_based_content', defaultMessage: 'deadline_based_content' })}
                      </div>
                    </div>
                    <div data-testid={`${testId}-plan-method-click-2`} className={`border rounded-xl w-[45%] h-full p-4 ${planingMethod === 2 ? 'bg-[#FFF9F0] border-[#FCAF3B]' : 'bg-white border '} cursor-pointer`} onClick={() => handleChangePlaningMethod(2)}>
                      <div className="font-bold flex">
                        {intl.formatMessage({ id: 'agile_based', defaultMessage: 'agile_based' })}
                        <span className='m-1 hidden'>
                          <input data-testid={`${testId}-change-plan-input-2`} type="radio" onChange={() => handleChangePlaningMethod(2)} checked={planingMethod === 2} />
                        </span>
                      </div>
                      <div className="mt-2">
                        {intl.formatMessage({ id: 'agile_based_content', defaultMessage: 'agile_based_content' })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-4 gap-4">
                  <StyledButton
                    testId={`${testId}-plan-modal-save-btn`}
                    disabled={planingMethod === 0}
                    width={"w-48 sm:w-full"}
                    align={"justify-end"}
                    buttonClass={'PrimoryBtn'}
                    text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
                    backgroundColor={'bg-black'}
                    color={'text-white'}
                    onClick={() => handlePlanningMethodSave()}
                  />
                </div>
              </Modal>
            </div>
            <div>
              <Modal show={isEmailModal} onClose={() => handleEmailModalClose()}>
                <div className="lg:min-w-[400px] " >
                  <div className="font-bold mb-2">
                    {intl.formatMessage({ id: 'edit_two_fa', defaultMessage: 'edit_two_fa' })}
                  </div>

                  <Dropdown
                    options={[...faDropDownList]}
                    value={defualtTwoFa}
                    onChange={(value: number) =>
                      handleSetTwoFa(value)
                    }
                  />
                  <div className="font-semibold text-xl text-[#121212] mb-6">
                  </div>
                  <div className='max-w-[414px]'>{intl.formatMessage({ id: "authenticator_dropdown_below_text", defaultMessage: "authenticator_dropdown_below_text" })}</div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                  <StyledButton
                    testId={`${testId}-auth-modal-cancel-btn`}
                    width={"w-48 sm:w-full"}
                    align={"justify-start"}
                    text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                    color={"text-[#535353]"}
                    flexReverse={"flex-row-reverse"}
                    backgroundColor={"bg-[#EDEDED]"}
                    onClick={() => handleEmailModalClose()}
                  />
                  <StyledButton
                    testId={`${testId}-auth-modal-save-btn`}
                    width={"w-48 sm:w-full"}
                    align={"justify-end"}
                    buttonClass={'PrimoryBtn'}
                    text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
                    backgroundColor={'bg-black'}
                    color={'text-white'}
                    onClick={() => handleEmailSave()}
                  />
                </div>
              </Modal>
            </div>
          </Container>
        </div>
      }
      {(isDeleteCurrentOrgModal) &&
        <div>
          <Modal show={isDeleteCurrentOrgModal} onClose={() => handleDeleteCurrentOrgModalClose()}>
            <div>{`${intl.formatMessage({ id: 'are_you_sure', defaultMessage: 'are_you_sure' })}`}</div>
            <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
              <StyledButton
                testId={`${testId}-cancel-delete-organization`}
                width={"w-48 sm:w-full"}
                align={"justify-start"}
                text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                color={"text-[#535353]"}
                flexReverse={"flex-row-reverse"}
                backgroundColor={"bg-[#EDEDED]"}
                onClick={() => handleDeleteCurrentOrgModalClose()}
              />
              <StyledButton
                testId={`${testId}-delete-current-org`}
                width={"w-48 sm:w-full"}
                buttonClass={'PrimoryBtn'}
                align={"justify-end"}
                text={intl.formatMessage({ id: "delete", defaultMessage: 'delete' })}
                backgroundColor={'bg-black'}
                color={'text-white'}
                onClick={() => handleDeleteCurrentOrg()}
              />
            </div>
          </Modal>
        </div>
      }
    </>
  )
}

