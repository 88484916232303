import axios from 'axios';
import dayjs from 'dayjs';
import { store } from '../store';
import { appContainerCreators } from '../AppReducer';
import { EXPIRY_IN, USER_COOKIE } from '../enum';
import { getBaseURL, timeout } from '../utils';
import cookies, { setUserCookies } from '../utils/coockies';

// Create instance called instance
let instance = axios.create({
    baseURL: getBaseURL(),
});

var accessToken: string = "";

export const setAccessToken = (token: string) => accessToken = token;

instance.interceptors.request.use(async (request: any) => {
    if (request.headers) {
        const refreshToken = cookies.get(USER_COOKIE);
        const expiryIn = cookies.get(EXPIRY_IN);
        const expiryDate = dayjs(expiryIn);
        const currentDate = dayjs(new Date());
        const dateDiff = expiryDate.diff(currentDate, 'minute');

        if (!refreshToken || refreshToken === '') {
            store.dispatch(appContainerCreators.clearUserInfo())
            window.location.pathname = '/';
        }

        if (dateDiff <= 0) {
            try {
                const rawResponse = await fetch(`${getBaseURL()}/organizationservice/v1/auth/refresh-token`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ refreshToken })
                });
                if (rawResponse.status === 200) {
                    const response = await rawResponse.json();
                    accessToken = response?.accessToken;
                    const expiryIn = dayjs().add(response?.expiresIn, 'second');
                    setUserCookies(response?.refreshToken, expiryIn.toDate());
                } else {
                    await timeout(2000);
                    const expiryIn = cookies.get(EXPIRY_IN);
                    const expiryDate = dayjs(expiryIn);
                    const currentDate = dayjs(new Date());
                    const dateDiff = expiryDate.diff(currentDate, 'minute');
                    if (dateDiff <= 0) {
                        store.dispatch(appContainerCreators.clearUserInfo())
                        window.location.pathname = '/';
                    }
                }
            } catch (e) {
                store.dispatch(appContainerCreators.clearUserInfo())
                window.location.pathname = '/';
            }
        }
        request.headers.Authorization = accessToken;
        // if ((request.url)?.includes('/refresh-token')) {
        //     request.headers.Authorization = '';
        // } else {
        //     request.headers.Authorization = accessToken;
        // }
    }
    return request;
}, (error: any) => {
    return error;
});

export default instance;
