import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectDecodedUserInfo } from "../../AppSelector";
import { locationContainerCreators } from "../../containers/LocationsContainer/locationReducer";
import { measureGroupsContainerCreators } from "../../containers/MeasureGroupsContainer/measureGroupsReducer";
import Modal from "../Modal/Modal";
import videojs from 'video.js';
import VideoJS from "../VideoJS/video";
import StyledButton from "../StyledButton";
import LoadingIcon from "../../assets/icons/loading-icon";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";
import rehypeRaw from "rehype-raw";
import { useIntl } from "react-intl";

export type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
}

type PopupVideoAttributes = {
    name: string;
    alternativeText: string;
    caption: string;
    width: null;
    height: null;
    formats: null;
    hash: string;
    ext: string;
    mime: string;
    size: string;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: null;
    createdAt: Date;
    updatedAt: Date;
}

type PopupVideo = {
    data: {
        id: number;
        attributes: PopupVideoAttributes;
    }
}

export type PopupDetails = {
    Intro: string;
    Header: string;
    Body: string;
    Button: string;
    Button2: string;
    Link: string;
    Link2: string;
    Video: PopupVideo;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
}

type EventsList = {
    eventListId: string;
    name: string;
}

type MeasureGroupList = {
    measuregroupListId: string;
    name: string;
}

type MeasuresQuestions = {
    measureListId: string;
    name: string;
}

type RelavantTypeArray = {
    id: string,
    name: string,
    status: string,
}

type MeasureGroups = {
    availableMeasureCount: number;
    measureCount: number;
    measuregroupId: string;
    name: string;
    nameWhenOther: string;
    selected: boolean;
    measuresShow: boolean;
    subgroup: string;
    relavantType: RelavantTypeArray[],
    measuregroupListId: string;
}

type Props = {
    riskCategoryId: string;
    from: string;
}

export default function CentralizePopup(props: Props) {

    const { riskCategoryId, from } = props;
    const intl = useIntl();
    // hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    var isCancel: string | null = null;
    new URLSearchParams(search).get('isCancel');

    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const { riskCategory } = useSelector((state: RootState) => state.riskAreContainer);

    const locationSelector = useSelector((state: RootState) => state.locationContainer);

    const EventsSelector = useSelector((state: RootState) => state.measureGroupsContainer);
    const measureGroupsSelector = useSelector((state: RootState) => state.measureGroupsContainer);
    const measuresSelector = useSelector((state: RootState) => state.measureGroupsContainer);

    // states
    const [selectedRCData, setSelectedRCData] = useState<AllRiskCategoryListData>();

    const [isLocationModal, setIsLocationModal] = useState<boolean>(false);
    const [locationPopupDetails, setLocationPopupDetails] = useState<PopupDetails>();

    const [isHardwareModal, setIsHardwareModal] = useState<boolean>(false);
    const [hardwarePopupDetails, setHardwarePopupDetails] = useState<PopupDetails>();
    const [hardwareCount, setHardwareCount] = useState();

    const [isConnectivityModal, setIsConnectivityModal] = useState<boolean>(false);
    const [connectivityPopupDetails, setConnectivityPopupDetails] = useState<PopupDetails>();
    const [connectivityCount, setConnectivityCount] = useState();

    const [isSoftwareModal, setIsSoftwareModal] = useState<boolean>(false);
    const [softwarePopupDetails, setSoftwarePopupDetails] = useState<PopupDetails>();
    const [softwareCount, setSoftwareCount] = useState();

    const [isEVENTModal, setIsEVENTModal] = useState<boolean>(false);
    const [eventPopupDetails, setEventPopupDetails] = useState<PopupDetails>();
    const [eventsList, seteventsList] = useState<EventsList[]>([]);

    const [isMeasureGroupModal, setIsMeasureGroupModal] = useState<boolean>(false);
    const [measureGroupPopupDetails, setMeasureGroupPopupDetails] = useState<PopupDetails>();
    //const [measureGroupList, setMeasureGroupList] = useState<MeasureGroupList[]>([]);
    const [singleMeasureGroup, setSingleMeasureGroup] = useState<MeasureGroups>();
    // const [measuresQuestions, setMeasuresQuestions] = useState<MeasuresQuestions[]>([]);

    const [isMeasuresModal, setIsMeasuresModal] = useState<boolean>(false);
    const [measuresPopupDetails, setMeasuresPopupDetails] = useState<PopupDetails>();

    const playerRef = React.useRef(null);
    const isHttpsLink = (eventPopupDetails?.Video?.data?.attributes?.url.startsWith("https://") || measureGroupPopupDetails?.Video?.data?.attributes?.url.startsWith("https://") || measuresPopupDetails?.Video?.data?.attributes?.url.startsWith("https://") || locationPopupDetails?.Video?.data?.attributes?.url.startsWith("https://") || connectivityPopupDetails?.Video?.data?.attributes?.url.startsWith("https://") || hardwarePopupDetails?.Video?.data?.attributes?.url.startsWith("https://") || softwarePopupDetails?.Video?.data?.attributes?.url.startsWith("https://"));
    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: isHttpsLink ? (eventPopupDetails?.Video?.data?.attributes?.url || measureGroupPopupDetails?.Video?.data?.attributes?.url || measuresPopupDetails?.Video?.data?.attributes?.url || locationPopupDetails?.Video?.data?.attributes?.url || connectivityPopupDetails?.Video?.data?.attributes?.url || hardwarePopupDetails?.Video?.data?.attributes?.url || softwarePopupDetails?.Video?.data?.attributes?.url) : "https://" + (eventPopupDetails?.Video?.data?.attributes?.url || "https://" + measureGroupPopupDetails?.Video?.data?.attributes?.url || "https://" + measuresPopupDetails?.Video?.data?.attributes?.url || "https://" + locationPopupDetails?.Video?.data?.attributes?.url || "https://" + connectivityPopupDetails?.Video?.data?.attributes?.url || "https://" + hardwarePopupDetails?.Video?.data?.attributes?.url || "https://" + softwarePopupDetails?.Video?.data?.attributes?.url),
            type: 'video/mp4'
        }]
    };

    useEffect(() => {
        if (isCancel === null) {
            // eslint-disable-next-line
            isCancel = null;
        } else {
            isCancel = new URLSearchParams(search).get('isCancel');
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCancel])/* eslint-disable react-hooks/exhaustive-deps */

    // main useEffect
    useEffect(() => {
        if (riskCategoryId) {
            gotoMeasureGroups(riskCategoryId)
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskCategoryId])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector?.locationContent) {
            if (locationSelector?.locationContent?.data && locationSelector?.locationContent?.data[0] && isCancel !== '0') {
                setLocationPopupDetails(locationSelector?.locationContent?.data[0]?.attributes);
                setIsHardwareModal(false);
                setIsConnectivityModal(false);
                setIsSoftwareModal(false);
                setIsEVENTModal(false);
                setIsMeasureGroupModal(false);
                setIsMeasuresModal(false);
                setIsLocationModal(true);
            } else {
                setIsLocationModal(false);
            }
        } else {
            setIsLocationModal(false);
            setIsEVENTModal(false);
            setIsMeasureGroupModal(false);
        }
    }, [locationSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.locations && locationSelector.locations.length > 0) {
            const availableHardwareCount = locationSelector?.locations.find((ele: any) => ele.availableHardwareCount > 0)
            setHardwareCount(availableHardwareCount)
        }

        if (locationSelector && locationSelector?.hardwareContent) {
            if (locationSelector?.hardwareContent?.data && locationSelector?.hardwareContent?.data[0] && isCancel !== '0') {
                setHardwarePopupDetails(locationSelector?.hardwareContent?.data[0]?.attributes);
                setIsLocationModal(false);
                setIsConnectivityModal(false);
                setIsSoftwareModal(false);
                setIsEVENTModal(false);
                setIsMeasureGroupModal(false);
                setIsMeasuresModal(false);
                setIsHardwareModal(true);
            } else {
                setIsHardwareModal(false);
                selectedRCData && navigate(`/hardware/${selectedRCData?.riskCategoryId}?isCancel=5`)
            }
        } else {
            setIsHardwareModal(false);
        }
    }, [locationSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {

        if (locationSelector && locationSelector.processes && locationSelector.processes.length > 0) {
            const isSkip = new URLSearchParams(search).get('skip') || -1;
            const availableSoftwareCountArray = locationSelector.processes.filter((ele: any) => ele.softwareCount === 0)
            const isEveryOneEmpty = availableSoftwareCountArray.length === locationSelector.processes.length;
            //don't change here double equale...
            const findIndexOfCurrent = locationSelector.processes.findIndex((ele: any) => ele.processId === isSkip);
            const nextIndex = locationSelector.processes.findIndex((ele: any, index: number) => index > findIndexOfCurrent && ele.softwareCount === 0);
            if (isEveryOneEmpty && isSkip === -1) {
                setSoftwareCount(locationSelector.processes[0])
            } else if (nextIndex !== -1 && findIndexOfCurrent !== -1) {
                setSoftwareCount(locationSelector.processes[nextIndex])
            }
        }

        if (locationSelector && locationSelector?.softwareContent) {
            if (locationSelector?.softwareContent?.data && locationSelector?.softwareContent?.data[0] && isCancel !== '0') {
                setSoftwarePopupDetails(locationSelector?.softwareContent?.data[0]?.attributes);
                setIsLocationModal(false);
                setIsHardwareModal(false);
                setIsConnectivityModal(false);
                setIsEVENTModal(false);
                setIsMeasureGroupModal(false);
                setIsMeasuresModal(false);
                setIsSoftwareModal(true);
            } else {
                setIsSoftwareModal(false);
                selectedRCData && navigate(`/software/${selectedRCData?.riskCategoryId}?isCancel=6`)
            }
        } else {
            setIsSoftwareModal(false);
        }
    }, [locationSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.locations && locationSelector.locations.length > 0) {
            const availableConnectivityCount = locationSelector?.locations.find((ele: any) => ele.availableConnectivityCount > 0)
            setConnectivityCount(availableConnectivityCount)
        }

        if (locationSelector && locationSelector?.connectivityContent) {
            if (locationSelector?.connectivityContent?.data && locationSelector?.connectivityContent?.data[0] && isCancel !== '0') {
                setConnectivityPopupDetails(locationSelector?.connectivityContent?.data[0]?.attributes);
                setIsLocationModal(false);
                setIsHardwareModal(false);
                setIsSoftwareModal(false);
                setIsEVENTModal(false);
                setIsMeasureGroupModal(false);
                setIsMeasuresModal(false);
                setIsConnectivityModal(true);
            } else {
                setIsConnectivityModal(false);
                selectedRCData && navigate(`/connectivity/${selectedRCData && selectedRCData?.riskCategoryId}?isCancel=6`)
            }
        } else {
            setIsConnectivityModal(false);
        }
    }, [locationSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (EventsSelector && EventsSelector.eventContent) {
            if (EventsSelector.eventContent.data && EventsSelector.eventContent.data[0] && isCancel !== "0") {
                setEventPopupDetails(EventsSelector.eventContent.data[0].attributes);
                setIsLocationModal(false);
                setIsHardwareModal(false);
                setIsConnectivityModal(false);
                setIsSoftwareModal(false);
                setIsMeasureGroupModal(false);
                setIsMeasuresModal(false);
                setIsEVENTModal(true);
            } else {
                setIsEVENTModal(false);
                if (eventsList && eventsList.length && isCancel !== '0' && selectedRCData) {
                    navigate(`/events/${selectedRCData?.riskCategoryId}/addevents`, { state: { eventsList: eventsList, singleRiskCategory: selectedRCData } });
                }
            }
        } else {
            setIsEVENTModal(false);
            setIsLocationModal(false);
        }
    }, [EventsSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (measureGroupsSelector && measureGroupsSelector.measureGroupContent) {
            if (measureGroupsSelector.measureGroupContent.data && measureGroupsSelector.measureGroupContent.data[0] && isCancel !== "0") {
                setMeasureGroupPopupDetails(measureGroupsSelector.measureGroupContent.data[0].attributes);
                setIsLocationModal(false);
                setIsHardwareModal(false);
                setIsConnectivityModal(false);
                setIsSoftwareModal(false);
                setIsEVENTModal(false);
                setIsMeasuresModal(false);
                setIsMeasureGroupModal(true);
            } else {
                setIsMeasureGroupModal(false);
                if (measureGroupsSelector.data && measureGroupsSelector.data.length && isCancel !== '0' && selectedRCData) {
                    navigate(`/risk-area/${selectedRCData.riskCategoryId}/add-measure-group`, { state: { measureGroupList: measureGroupsSelector.data, singleRiskCategory: selectedRCData } });
                }
            }
        } else {
            setIsMeasureGroupModal(false);
        }
    }, [measureGroupsSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (measuresSelector && measuresSelector.measuresContent) {
            if (measuresSelector.measuresContent.data && measuresSelector.measuresContent.data[0] && isCancel !== '0') {
                setMeasuresPopupDetails(measuresSelector.measuresContent.data[0].attributes);
                setIsLocationModal(false);
                setIsHardwareModal(false);
                setIsConnectivityModal(false);
                setIsSoftwareModal(false);
                setIsEVENTModal(false);
                setIsMeasureGroupModal(false);
                setIsMeasuresModal(true);
            } else {
                setIsMeasuresModal(false);
                if (measureGroupsSelector.measures && measureGroupsSelector.measures.length && singleMeasureGroup && selectedRCData && isCancel !== '0') {
                    navigate(`/measures/${selectedRCData?.riskCategoryId}/add-measures/${singleMeasureGroup.measuregroupId}`, { state: { measuresList: measureGroupsSelector.measures, singleRiskCategory: selectedRCData, addMeasureGroupObj: singleMeasureGroup } });
                }
            }
        } else {
            setIsMeasuresModal(false);
        }
    }, [measuresSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (EventsSelector && EventsSelector.events) {
            // const eventsFilterList = EventsSelector.events.filter((ele: any) => !ele?.eventId);
            seteventsList(EventsSelector.events)
        }
        if (measureGroupsSelector && measureGroupsSelector.data && measureGroupsSelector.data.length >= 0) {
            //const measureGroupFilterList = measureGroupsSelector.data.filter((ele: any) => !ele?.measuregroupId);
            //setMeasureGroupList(measureGroupFilterList)
            const singleMeasureGroupObj = measureGroupsSelector.data?.find((ele: any) => ele?.availableMeasureCount > 0 && ele.selected === true);
            setSingleMeasureGroup(singleMeasureGroupObj)
        }
        // if (measureGroupsSelector && measureGroupsSelector.measures && measureGroupsSelector.measures.length >= 0) {
        //     const measureQuestionList = measureGroupsSelector.measures.filter((ele: any) => !ele?.measureId);
        //     setMeasuresQuestions(measureQuestionList)
        // }
    }, [measureGroupsSelector])/* eslint-disable react-hooks/exhaustive-deps */

    // functions

    function fromTaskAutoFlow(riskCategoryObj: any) {
        if (riskCategoryObj.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400') {
            if (riskCategoryObj.step === 0) {
                if (decodedUserInfo) {
                    navigate(`/tasks?isCancel=4`)
                    dispatch(locationContainerCreators.requestGetProdLocationContent(decodedUserInfo, riskCategoryObj));
                }
            } else if (riskCategoryObj.step === 1) {
                if (decodedUserInfo) {
                    navigate(`/tasks?isCancel=5`)
                    dispatch(locationContainerCreators.requestGetLocations(decodedUserInfo, dispatch, riskCategoryObj, isCancel, navigate, 'Hardware', true));
                }
            } else if (riskCategoryObj.step === 2) {
                if (decodedUserInfo) {
                    navigate(`/tasks?isCancel=6`)
                    dispatch(locationContainerCreators.requestGetLocations(decodedUserInfo, dispatch, riskCategoryObj, isCancel, navigate, 'Connectivity', true));
                }
            } else if (riskCategoryObj.step === 3) {
                if (decodedUserInfo) {
                    navigate(`/tasks?isCancel=7`)
                    const isSkip = new URLSearchParams(search).get('skip') || 0;
                    dispatch(locationContainerCreators.requestGetSoftwareProcesses(decodedUserInfo, dispatch, riskCategoryObj, isCancel, navigate, intl, true, isSkip));
                }
            } else if (riskCategoryObj.step === 4) {
                navigate(`/tasks?isCancel=8`)
                dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, riskCategoryObj));
            } else if (riskCategoryObj.step === 5) {
                navigate(`/tasks?isCancel=9`)
                dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, riskCategoryObj));
            }
        } else if (riskCategoryObj.riskListId === '00000000-0000-0000-0000-000000000000') {
            navigate(`/risk-area/${riskCategoryId}`, { state: { singleRiskCategory: riskCategoryObj } });
        } else {
            navigate(`/tasks?isCancel=9`)
            dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, riskCategoryObj));
        }
    }

    function fromManageRiskAreaAutoFlow(riskCategoryObj: any) {
        if (riskCategoryObj.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400') {
            if (riskCategoryObj.step === 0) {
                if (decodedUserInfo) {
                    navigate(`/manage-risk-areas?isCancel=4`)
                    dispatch(locationContainerCreators.requestGetProdLocationContent(decodedUserInfo, riskCategoryObj));
                }
            } else if (riskCategoryObj.step === 1) {
                if (decodedUserInfo) {
                    navigate(`/manage-risk-areas?isCancel=5`)
                    dispatch(locationContainerCreators.requestGetLocations(decodedUserInfo, dispatch, riskCategoryObj, isCancel, navigate, 'Hardware', true));
                }
            } else if (riskCategoryObj.step === 2) {
                if (decodedUserInfo) {
                    navigate(`/manage-risk-areas?isCancel=6`)
                    dispatch(locationContainerCreators.requestGetLocations(decodedUserInfo, dispatch, riskCategoryObj, isCancel, navigate, 'Connectivity', true));
                }
            } else if (riskCategoryObj.step === 3) {
                if (decodedUserInfo) {
                    navigate(`/manage-risk-areas?isCancel=7`)
                    const isSkip = new URLSearchParams(search).get('skip') || 0;
                    dispatch(locationContainerCreators.requestGetSoftwareProcesses(decodedUserInfo, dispatch, riskCategoryObj, isCancel, navigate, intl, true, isSkip));
                }
            } else if (riskCategoryObj.step === 4) {
                navigate(`/manage-risk-areas?isCancel=8`)
                dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, riskCategoryObj));
            } else if (riskCategoryObj.step === 5) {
                navigate(`/manage-risk-areas?isCancel=9`)
                dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, riskCategoryObj));
            }
        } else if (riskCategoryObj.riskListId === '00000000-0000-0000-0000-000000000000') {
            navigate(`/risk-area/${riskCategoryId}`, { state: { singleRiskCategory: riskCategoryObj } });
        } else {
            navigate(`/manage-risk-areas?isCancel=9`)
            dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, riskCategoryObj));
        }
    }

    function fromOtherPlace(riskCategoryObj: any) {
        if (riskCategoryObj.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400') {
            if (riskCategoryObj.step === 0) {
                if (decodedUserInfo) {
                    navigate(`/dashboard?isCancel=4`)
                    dispatch(locationContainerCreators.requestGetProdLocationContent(decodedUserInfo, riskCategoryObj));
                }
            } else if (riskCategoryObj.step === 1) {
                if (decodedUserInfo) {
                    navigate(`/dashboard?isCancel=5`)
                    dispatch(locationContainerCreators.requestGetLocations(decodedUserInfo, dispatch, riskCategoryObj, isCancel, navigate, 'Hardware', true));
                }
            } else if (riskCategoryObj.step === 2) {
                if (decodedUserInfo) {
                    navigate(`/dashboard?isCancel=6`)
                    dispatch(locationContainerCreators.requestGetLocations(decodedUserInfo, dispatch, riskCategoryObj, isCancel, navigate, 'Connectivity', true));
                }
            } else if (riskCategoryObj.step === 3) {
                if (decodedUserInfo) {
                    navigate(`/dashboard?isCancel=7`)
                    const isSkip = new URLSearchParams(search).get('skip') || 0;
                    dispatch(locationContainerCreators.requestGetSoftwareProcesses(decodedUserInfo, dispatch, riskCategoryObj, isCancel, navigate, intl, true, isSkip));
                }
            } else if (riskCategoryObj.step === 4) {
                navigate(`/dashboard?isCancel=8`)
                dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, riskCategoryObj));
            } else if (riskCategoryObj.step === 5) {
                navigate(`/dashboard?isCancel=9`)
                dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, riskCategoryObj));
            }
        } else if (riskCategoryObj.riskListId === '00000000-0000-0000-0000-000000000000') {
            navigate(`/risk-area/${riskCategoryId}`, { state: { singleRiskCategory: riskCategoryObj } });
        } else {
            navigate(`/dashboard?isCancel=9`)
            dispatch(measureGroupsContainerCreators.requestGetEvents(riskCategoryId, navigate, isCancel, dispatch, decodedUserInfo, riskCategoryObj));
        }
    }

    function gotoMeasureGroups(riskCategoryId: string) {
        const riskCategoryObj = riskCategory.find((ele: any) => ele?.riskCategoryId === riskCategoryId)
        setSelectedRCData(riskCategoryObj)
        if (from === 'task') {
            fromTaskAutoFlow(riskCategoryObj)
        } else if (from === 'manageRiskArea') {
            fromManageRiskAreaAutoFlow(riskCategoryObj)
        } else {
            fromOtherPlace(riskCategoryObj)
        }

    }

    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });
        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    function handleLocationModalClose() {
        setIsLocationModal(false);
        navigate(`/locations/${selectedRCData?.riskCategoryId}?isCancel=0`)
    }

    function handleHardwareModalClose() {
        setIsHardwareModal(false);
        if (hardwareCount && isCancel !== '0') {
            dispatch(locationContainerCreators.requestGetHardwares(hardwareCount, selectedRCData, isCancel, navigate));
        }
    }

    function handleSoftwareModalClose() {
        setIsSoftwareModal(false);
        if (softwareCount && isCancel !== '0') {
            const isSkip = new URLSearchParams(search).get('skip') || -1;
            dispatch(locationContainerCreators.requestGetSoftwares(softwareCount, selectedRCData, isCancel, navigate, intl, isSkip));
        }
    }

    function handleConnectivityModalClose() {
        setIsConnectivityModal(false);
        if (connectivityCount && isCancel !== '0') {
            dispatch(locationContainerCreators.requestGetConnectivities(connectivityCount, selectedRCData, isCancel, navigate));
        }
    }

    function handleEventModalClose() {
        setIsEVENTModal(false);
        if (eventsList && eventsList.length && isCancel !== '0' && selectedRCData) {
            navigate(`/events/${selectedRCData?.riskCategoryId}/addevents`, { state: { eventsList: eventsList, singleRiskCategory: selectedRCData } });
        }
    }

    function handleMeasureGroupModalClose() {
        setIsMeasureGroupModal(false);
        if (measureGroupsSelector.data && measureGroupsSelector.data.length && isCancel !== '0' && selectedRCData) {
            navigate(`/risk-area/${selectedRCData.riskCategoryId}/add-measure-group`, { state: { measureGroupList: measureGroupsSelector.data, singleRiskCategory: selectedRCData } });
        }
    }

    function handleMeasuresModalClose() {
        setIsMeasuresModal(false);
        if (measureGroupsSelector.measures && measureGroupsSelector.measures.length && singleMeasureGroup && selectedRCData && isCancel !== '0') {
            navigate(`/measures/${selectedRCData?.riskCategoryId}/add-measures/${singleMeasureGroup.measuregroupId}`, { state: { measuresList: measureGroupsSelector.measures, singleRiskCategory: selectedRCData, addMeasureGroupObj: singleMeasureGroup } });
        }
    }



    return (
        <>
            <Modal show={isLocationModal} onClose={() => handleLocationModalClose()}>
                <div className={locationPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                        {locationPopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {locationPopupDetails?.Header}
                    </div>
                    {locationPopupDetails?.Video?.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(locationPopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {locationPopupDetails?.Button2 && <StyledButton onClick={() => locationPopupDetails?.Link2 ? navigate(locationPopupDetails?.Link2) : handleLocationModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={locationPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {locationPopupDetails?.Button && <StyledButton onClick={() => locationPopupDetails?.Link ? navigate(locationPopupDetails?.Link) : handleLocationModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={locationPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>

            <Modal show={isHardwareModal} onClose={() => handleHardwareModalClose()}>
                <div className={hardwarePopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                        {hardwarePopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {hardwarePopupDetails?.Header}
                    </div>
                    {hardwarePopupDetails?.Video?.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(hardwarePopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {hardwarePopupDetails?.Button2 && <StyledButton onClick={() => hardwarePopupDetails?.Link2 ? navigate(hardwarePopupDetails?.Link2) : handleHardwareModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={hardwarePopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {hardwarePopupDetails?.Button && <StyledButton onClick={() => hardwarePopupDetails?.Link ? navigate(hardwarePopupDetails?.Link) : handleHardwareModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={hardwarePopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>

            <Modal show={isSoftwareModal} onClose={() => handleSoftwareModalClose()}>
                <div className={softwarePopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                        {softwarePopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {softwarePopupDetails?.Header}
                    </div>
                    {softwarePopupDetails?.Video?.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(softwarePopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {softwarePopupDetails?.Button2 && <StyledButton onClick={() => softwarePopupDetails?.Link2 ? navigate(softwarePopupDetails?.Link2) : handleSoftwareModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={softwarePopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {softwarePopupDetails?.Button && <StyledButton onClick={() => softwarePopupDetails?.Link ? navigate(softwarePopupDetails?.Link) : handleSoftwareModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={softwarePopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>

            <Modal show={isConnectivityModal} onClose={() => handleConnectivityModalClose()}>
                <div className={connectivityPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                        {connectivityPopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {connectivityPopupDetails?.Header}
                    </div>
                    {connectivityPopupDetails?.Video?.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(connectivityPopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {connectivityPopupDetails?.Button2 && <StyledButton onClick={() => connectivityPopupDetails?.Link2 ? navigate(connectivityPopupDetails?.Link2) : handleConnectivityModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={connectivityPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {connectivityPopupDetails?.Button && <StyledButton onClick={() => connectivityPopupDetails?.Link ? navigate(connectivityPopupDetails?.Link) : handleConnectivityModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={connectivityPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>

            <Modal show={isEVENTModal} onClose={() => handleEventModalClose()}>
                <div className={eventPopupDetails?.Video.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                        {eventPopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {eventPopupDetails?.Header}
                    </div>
                    {eventPopupDetails?.Video.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(eventPopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {eventPopupDetails?.Button2 && <StyledButton onClick={() => eventPopupDetails?.Link ? navigate(eventPopupDetails?.Link) : handleEventModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={eventPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {eventPopupDetails?.Button && <StyledButton onClick={() => eventPopupDetails?.Link ? navigate(eventPopupDetails?.Link) : handleEventModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={eventPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>

            <Modal show={isMeasureGroupModal} onClose={() => handleMeasureGroupModalClose()}>
                {(measureGroupsSelector.loading) ?
                    <div className='app-loader'>
                        <LoadingIcon />
                    </div>
                    :
                    <div className={measureGroupPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                        <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                            {measureGroupPopupDetails?.Intro}
                        </div>
                        <div className="font-semibold text-xl text-[#121212] mb-6">
                            {measureGroupPopupDetails?.Header}
                        </div>
                        {measureGroupPopupDetails?.Video?.data &&
                            <div className="mb-10">
                                <VideoJS options={options} onReady={handlePlayerReady} />
                            </div>
                        }
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(measureGroupPopupDetails?.Body)}</ReactMarkdown>
                        <div className="flex items-center justify-between">
                            {measureGroupPopupDetails?.Button2 && <StyledButton onClick={() => measureGroupPopupDetails?.Link2 ? navigate(measureGroupPopupDetails?.Link2) : handleMeasureGroupModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={measureGroupPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                            {measureGroupPopupDetails?.Button && <StyledButton onClick={() => measureGroupPopupDetails?.Link ? navigate(measureGroupPopupDetails?.Link) : handleMeasureGroupModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={measureGroupPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        </div>
                    </div>
                }
            </Modal>

            <Modal show={isMeasuresModal} onClose={() => handleMeasuresModalClose()}>
                {(measuresSelector.loading) ?
                    <div className='app-loader'>
                        <LoadingIcon />
                    </div>
                    :
                    <div className={measuresPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                        <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer font-family-arial">
                            {measuresPopupDetails?.Intro}
                        </div>
                        <div className="font-semibold text-xl text-[#121212] mb-6">
                            {measuresPopupDetails?.Header}
                        </div>
                        {measuresPopupDetails?.Video?.data &&
                            <div className="mb-10">
                                <VideoJS options={options} onReady={handlePlayerReady} />
                            </div>
                        }
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(measuresPopupDetails?.Body)}</ReactMarkdown>
                        <div className="flex items-center justify-between">
                            {measuresPopupDetails?.Button2 && <StyledButton onClick={() => measuresPopupDetails?.Link2 ? navigate(measuresPopupDetails?.Link2) : handleMeasuresModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={measuresPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                            {measuresPopupDetails?.Button && <StyledButton onClick={() => measuresPopupDetails?.Link ? navigate(measuresPopupDetails?.Link) : handleMeasuresModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={measuresPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
}