import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: locationContainerTypes, Creators: locationContainerCreators } = createActions({
    // get location
    requestGetLocations: ['decodedUserInfo', 'dispatch', 'singleRiskCategory', 'isCancel', 'navigate', 'isCallingFrom', 'showPopup'],
    successGetLocations: ['locations'],
    failureGetLocations: ['error'],

    // get processes
    requestGetSoftwareProcesses: ['decodedUserInfo', 'dispatch', 'singleRiskCategory', 'isCancel', 'navigate', 'intl', 'showPopup', 'isSkip'],
    successGetSoftwareProcesses: ['processes'],
    failureGetSoftwareProcesses: ['error'],

    // get hardware
    requestGetHardwares: ['locationObj', 'singleRiskCategory', 'isCancel', 'navigate'],
    successGetHardwares: ['hardwares', 'method'],
    failureGetHardwares: ['error'],

    // post hardware
    requestPostHardwares: ['locationId', 'data'],
    successPostHardwares: ['postHardwareData', 'status'],
    failurePostHardwares: ['error'],

    // put hardware
    requestPutHardwares: ['locationId', 'hardwareId', 'data'],
    successPutHardwares: ['hardwares', 'status', 'method'],
    failurePutHardwares: ['error'],

    // delete hardware
    requestDeleteHardware: ['locationId', 'hardwareId'],
    successDeleteHardware: ['hardwares', 'method'],
    failureDeleteHardware: ['error'],

    // get connectivity
    requestGetConnectivities: ['locationObj', 'singleRiskCategory', 'isCancel', 'navigate'],
    successGetConnectivities: ['connectivities'],
    failureGetConnectivities: ['error'],

    // post connectivity
    requestPostConnectivities: ['locationId', 'data'],
    successPostConnectivities: ['postConnectivitiesData', 'status', 'method'],
    failurePostConnectivities: ['error'],

    // put connectivity
    requestPutConnectivities: ['locationId', 'connectivityId', 'data'],
    successPutConnectivities: ['connectivities', 'status', 'method'],
    failurePutConnectivities: ['error'],

    // delete connectivity
    requestDeleteConnectivity: ['locationId', 'connectivityId'],
    successDeleteConnectivity: ['connectivities', 'method'],
    failureDeleteConnectivity: ['error'],

    // post location
    requestPostLocations: ['data'],
    successPostLocations: ['locations', 'status', 'method'],
    failurePostLocations: ['error'],

    // post software
    requestPostSoftwares: ['processId', 'data'],
    successPostSoftwares: ['PostSoftwares', 'status', 'method'],
    failurePostSoftwares: ['error'],

    // put Softwares
    requestPutSoftwares: ['processId', 'softwareId', 'data'],
    successPutSoftwares: ['connectivities', 'status', 'method'],
    failurePutSoftwares: ['error'],

    // get softwares
    requestGetSoftwares: ['processObj', 'singleRiskCategory', 'isCancel', 'navigate', 'intl', 'isSkip'],
    successGetSoftwares: ['softwares'],
    failureGetSoftwares: ['error'],

    // delete softwares
    requestDeleteSoftware: ['processId', 'softwareId'],
    successDeleteSoftware: ['softwares', 'method'],
    failureDeleteSoftware: ['error'],

    // put location
    requestPutLocations: ['id', 'data'],
    successPutLocations: ['locations', 'status', 'method'],
    failurePutLocations: ['error'],

    // delete location
    requestDeleteLocations: ['data'],
    successDeleteLocations: ['locations', 'method'],
    failureDeleteLocations: ['error'],

    //Location content api for PROD
    requestGetProdLocationContent: ['decodedUserInfo', 'riskCategoryObj'],
    successGetProdLocationContent: ['locationContent'],
    failureGetProdLocationContent: ['error'],

    //Software content api for PROD
    requestGetProdSoftwareContent: ['decodedUserInfo', 'riskCategoryObj'],
    successGetProdSoftwareContent: ['softwareContent'],
    failureGetProdSoftwareContent: ['error'],

    //Hardware content api for PROD
    requestGetProdHardwareContent: ['decodedUserInfo', 'riskCategoryObj'],
    successGetProdHardwareContent: ['hardwareContent'],
    failureGetProdHardwareContent: ['error'],

    //Connectivity content api for PROD
    requestGetProdConnectivityContent: ['decodedUserInfo', 'riskCategoryObj'],
    successGetProdConnectivityContent: ['connectivityContent'],
    failureGetProdConnectivityContent: ['error'],

    // HARDWARE Help Text
    requestGetHardwareHelpText: ['contentType', 'decodedUserInfo'],
    successGetHardwareHelpText: ['hardwareHelpText'],
    failureGetHardwareHelpText: ['error'],

    // SOFTWARE Help Text
    requestGetSoftwareHelpText: ['contentType', 'decodedUserInfo'],
    successGetSoftwareHelpText: ['softwareHelpText'],
    failureGetSoftwareHelpText: ['error'],

    // CONNECTIVITY Help Text
    requestGetConnectivityHelpText: ['contentType', 'decodedUserInfo'],
    successGetConnectivityHelpText: ['connectivityHelpText'],
    failureGetConnectivityHelpText: ['error'],

    // get hardware by id
    requestGetHardwareById: ['locationId', 'hardwareId'],
    successGetHardwareById: ['singleHardware'],
    failureGetHardwareById: ['error'],

    // get connectivity by id
    requestGetConnectivityById: ['locationId', 'connectivityId'],
    successGetConnectivityById: ['singleConnectivity'],
    failureGetConnectivityById: ['error'],

    // get software by id
    requestGetSoftwareById: ['locationId', 'softwareId'],
    successGetSoftwareById: ['singleSoftware'],
    failureGetSoftwareById: ['error'],
});

export const { Types: clearLocationStatusTypes, Creators: clearLocationStatusCreators } = createActions({
    //clear status
    clearLocationStatus: []
});

export type InitialStateType = {
    locations: object[] | null;
    processes: object[] | null;
    error: string | null;
    loading: boolean | null;
    shouldRealoadAddresses?: boolean;
    hardwares: object[] | null;
    connectivities: object[] | null;
    softwares: object[] | null;
    locationContent: object | null;
    softwareContent: object | null;
    hardwareContent: object | null;
    connectivityContent: object | null;
    hardwareHelpText: object | null;
    softwareHelpText: object | null;
    connectivityHelpText: object | null;
    status?: number | null;
    singleHardware?: object | null;
    singleConnectivity?: object | null;
    singleSoftware?: object | null;
    method?: string | null;
    loadingAfterPost?: boolean;
    postHardwareData?: object | null;
    PostSoftwares?: object | null;
    postConnectivitiesData?: object | null;
}

export const initialState: InitialStateType = {
    locations: null, processes: null, error: null, loading: null, shouldRealoadAddresses: false, hardwares: null, connectivities: null, softwares: null, locationContent: null, softwareContent: null, hardwareContent: null, connectivityContent: null, hardwareHelpText: null, softwareHelpText: null, connectivityHelpText: null, status: null, singleHardware: null, singleConnectivity: null, singleSoftware: null, method
        : null, loadingAfterPost: false, postHardwareData: null, PostSoftwares: null, postConnectivitiesData: null
};

export const locationContainerReducer = (state = initialState, action: any) =>
    produce(state, (draft) => {
        switch (action.type) {
            // get location
            case locationContainerTypes.REQUEST_GET_LOCATIONS:
                draft.method = null;
                draft.status = null;
                draft.locations = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                break;
            case locationContainerTypes.SUCCESS_GET_LOCATIONS:
                draft.method = null;
                draft.status = null;
                draft.locations = action.locations;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                break;
            case locationContainerTypes.FAILURE_GET_LOCATIONS:
                draft.method = null;
                draft.status = null;
                draft.locations = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                break;

            // get processes
            case locationContainerTypes.REQUEST_GET_SOFTWARE_PROCESSES:
                draft.status = null;
                draft.loading = true;
                draft.processes = null;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_GET_SOFTWARE_PROCESSES:
                draft.status = null;
                draft.loading = false;
                draft.processes = action.processes;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_GET_SOFTWARE_PROCESSES:
                draft.status = null;
                draft.loading = false;
                draft.processes = null;
                draft.error = action.error;
                break;

            // get hardware
            case locationContainerTypes.REQUEST_GET_HARDWARES:
                draft.loadingAfterPost = false;
                draft.singleHardware = null;
                draft.status = null;
                draft.method = null;
                draft.hardwares = null;
                draft.postHardwareData = null;
                draft.hardwareContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                break;
            case locationContainerTypes.SUCCESS_GET_HARDWARES:
                draft.singleHardware = null;
                draft.status = null;
                draft.method = null;
                draft.hardwares = action.hardwares;
                draft.postHardwareData = null;
                draft.hardwareContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                break;
            case locationContainerTypes.FAILURE_GET_HARDWARES:
                draft.singleHardware = null;
                draft.status = null;
                draft.method = null;
                draft.hardwares = null;
                draft.postHardwareData = null;
                draft.hardwareContent = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                break;

            // post hardware
            case locationContainerTypes.REQUEST_POST_HARDWARES:
                draft.loadingAfterPost = true;
                draft.hardwares = null;
                draft.postHardwareData = null;
                draft.hardwareContent = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;
            case locationContainerTypes.SUCCESS_POST_HARDWARES:
                draft.method = action.method;
                draft.hardwares = null;
                draft.postHardwareData = action.postHardwareData;
                draft.hardwareContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = action.status;
                break;
            case locationContainerTypes.FAILURE_POST_HARDWARES:
                draft.hardwares = null;
                draft.postHardwareData = null;
                draft.hardwareContent = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;

            // put hardware
            case locationContainerTypes.REQUEST_PUT_HARDWARES:
                draft.hardwares = null;
                draft.hardwareContent = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;
            case locationContainerTypes.SUCCESS_PUT_HARDWARES:
                draft.method = action.method;
                draft.hardwares = action.hardwares;
                draft.hardwareContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = action.status;
                break;
            case locationContainerTypes.FAILURE_PUT_HARDWARES:
                draft.hardwares = null;
                draft.hardwareContent = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;

            // delete hardware
            case locationContainerTypes.REQUEST_DELETE_HARDWARE:
                draft.hardwares = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_DELETE_HARDWARE:
                draft.method = action.method;
                draft.hardwares = action.hardwares;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_DELETE_HARDWARE:
                draft.hardwares = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // delete software
            case locationContainerTypes.REQUEST_DELETE_SOFTWARE:
                draft.softwares = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_DELETE_SOFTWARE:
                draft.method = action.method;
                draft.softwares = action.softwares;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_DELETE_SOFTWARE:
                draft.softwares = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get connectivity
            case locationContainerTypes.REQUEST_GET_CONNECTIVITIES:
                draft.loadingAfterPost = false;
                draft.singleConnectivity = null;
                draft.method = null;
                draft.status = null;
                draft.connectivities = null;
                draft.connectivityContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                break;
            case locationContainerTypes.SUCCESS_GET_CONNECTIVITIES:
                draft.singleConnectivity = null;
                draft.method = null;
                draft.status = null;
                draft.connectivities = action.connectivities;
                draft.connectivityContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                break;
            case locationContainerTypes.FAILURE_GET_CONNECTIVITIES:
                draft.singleConnectivity = null;
                draft.method = null;
                draft.status = null;
                draft.connectivities = null;
                draft.connectivityContent = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                break;

            // post connectivity
            case locationContainerTypes.REQUEST_POST_CONNECTIVITIES:
                draft.loadingAfterPost = true;
                draft.connectivities = null;
                draft.postConnectivitiesData = null;
                draft.connectivityContent = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;
            case locationContainerTypes.SUCCESS_POST_CONNECTIVITIES:
                draft.method = action.method;
                draft.connectivities = null;
                draft.postConnectivitiesData = action.postConnectivitiesData;
                draft.connectivityContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = action.status;
                break;
            case locationContainerTypes.FAILURE_POST_CONNECTIVITIES:
                draft.connectivities = null;
                draft.postConnectivitiesData = null;
                draft.connectivityContent = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;

            // put connectivity
            case locationContainerTypes.REQUEST_PUT_CONNECTIVITIES:
                draft.connectivities = null;
                draft.connectivityContent = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;
            case locationContainerTypes.SUCCESS_PUT_CONNECTIVITIES:
                draft.method = action.method;
                draft.connectivities = action.connectivities;
                draft.connectivityContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = action.status;
                break;
            case locationContainerTypes.FAILURE_PUT_CONNECTIVITIES:
                draft.connectivities = null;
                draft.connectivityContent = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;

            // delete connectivity
            case locationContainerTypes.REQUEST_DELETE_CONNECTIVITY:
                draft.connectivities = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_DELETE_CONNECTIVITY:
                draft.method = action.method;
                draft.connectivities = action.connectivities;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_DELETE_CONNECTIVITY:
                draft.connectivities = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // post location
            case locationContainerTypes.REQUEST_POST_LOCATIONS:
                draft.locations = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;
            case locationContainerTypes.SUCCESS_POST_LOCATIONS:
                draft.method = action.method;
                draft.locations = action.locations;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = true;
                draft.status = action.status;
                break;
            case locationContainerTypes.FAILURE_POST_LOCATIONS:
                draft.locations = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;

            // get softwares
            case locationContainerTypes.REQUEST_GET_SOFTWARES:
                draft.loadingAfterPost = false;
                draft.singleSoftware = null;
                draft.method = null;
                draft.status = null;
                draft.softwares = null;
                draft.PostSoftwares = null;
                draft.softwareContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                break;
            case locationContainerTypes.SUCCESS_GET_SOFTWARES:
                draft.singleSoftware = null;
                draft.method = null;
                draft.status = null;
                draft.softwares = action.softwares;
                draft.PostSoftwares = null;
                draft.softwareContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = true;
                break;
            case locationContainerTypes.FAILURE_GET_SOFTWARES:
                draft.singleSoftware = null;
                draft.method = null;
                draft.status = null;
                draft.softwares = null;
                draft.PostSoftwares = null;
                draft.softwareContent = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                break;

            // post softwares
            case locationContainerTypes.REQUEST_POST_SOFTWARES:
                draft.loadingAfterPost = true;
                draft.softwares = null;
                draft.PostSoftwares = null;
                draft.softwareContent = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;
            case locationContainerTypes.SUCCESS_POST_SOFTWARES:
                draft.method = action.method;
                draft.PostSoftwares = action.PostSoftwares;
                draft.softwareContent = null;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = true;
                draft.status = action.status;
                break;
            case locationContainerTypes.FAILURE_POST_SOFTWARES:
                draft.softwares = null;
                draft.PostSoftwares = null;
                draft.softwareContent = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;

            // put softwares
            case locationContainerTypes.REQUEST_PUT_SOFTWARES:
                draft.softwares = null;
                draft.softwareContent = null;
                draft.loading = true;
                draft.error = null;
                draft.status = null;
                break;
            case locationContainerTypes.SUCCESS_PUT_SOFTWARES:
                draft.method = action.method;
                draft.softwares = action.softwares;
                draft.softwareContent = null;
                draft.loading = false;
                draft.error = null;
                draft.status = action.status;
                break;
            case locationContainerTypes.FAILURE_PUT_SOFTWARES:
                draft.softwares = null;
                draft.softwareContent = null;
                draft.loading = false;
                draft.error = action.error;
                draft.status = null;
                break;

            // put location
            case locationContainerTypes.REQUEST_PUT_LOCATIONS:
                draft.locations = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;
            case locationContainerTypes.SUCCESS_PUT_LOCATIONS:
                draft.method = action.method;
                draft.locations = action.locations;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = true;
                draft.status = action.status;
                break;
            case locationContainerTypes.FAILURE_PUT_LOCATIONS:
                draft.locations = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                draft.status = null;
                break;

            // delete location
            case locationContainerTypes.REQUEST_DELETE_LOCATIONS:
                draft.locations = null;
                draft.loading = true;
                draft.error = null;
                draft.shouldRealoadAddresses = false;
                break;
            case locationContainerTypes.SUCCESS_DELETE_LOCATIONS:
                draft.locations = action.locations;
                draft.loading = false;
                draft.error = null;
                draft.shouldRealoadAddresses = true;
                draft.method = action.method;
                break;
            case locationContainerTypes.FAILURE_DELETE_LOCATIONS:
                draft.locations = null;
                draft.loading = false;
                draft.error = action.error;
                draft.shouldRealoadAddresses = false;
                break;

            // PROD Location content api
            case locationContainerTypes.REQUEST_GET_PROD_LOCATION_CONTENT:
                draft.status = null;
                draft.method = null;
                draft.locationContent = null;
                draft.hardwareContent = null;
                draft.softwareContent = null;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_GET_PROD_LOCATION_CONTENT:
                draft.status = null;
                draft.method = null;
                draft.locationContent = action.locationContent;
                draft.hardwareContent = null;
                draft.softwareContent = null;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_GET_PROD_LOCATION_CONTENT:
                draft.locationContent = null;
                draft.hardwareContent = null;
                draft.softwareContent = null;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // PROD Software content api
            case locationContainerTypes.REQUEST_GET_PROD_SOFTWARE_CONTENT:
                draft.locationContent = null;
                draft.hardwareContent = null;
                draft.softwareContent = null;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_GET_PROD_SOFTWARE_CONTENT:
                draft.locationContent = null;
                draft.hardwareContent = null;
                draft.softwareContent = action.softwareContent;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_GET_PROD_SOFTWARE_CONTENT:
                draft.locationContent = null;
                draft.hardwareContent = null;
                draft.softwareContent = null;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // PROD hardware content api
            case locationContainerTypes.REQUEST_GET_PROD_HARDWARE_CONTENT:
                draft.locationContent = null;
                draft.softwareContent = null;
                draft.hardwareContent = null;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_GET_PROD_HARDWARE_CONTENT:
                draft.locationContent = null;
                draft.softwareContent = null;
                draft.hardwareContent = action.hardwareContent;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_GET_PROD_HARDWARE_CONTENT:
                draft.locationContent = null;
                draft.softwareContent = null;
                draft.hardwareContent = null;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // PROD Connectivity content api
            case locationContainerTypes.REQUEST_GET_PROD_CONNECTIVITY_CONTENT:
                draft.locationContent = null;
                draft.softwareContent = null;
                draft.hardwareContent = null;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_GET_PROD_CONNECTIVITY_CONTENT:
                draft.locationContent = null;
                draft.softwareContent = null;
                draft.hardwareContent = null;
                draft.connectivityContent = action.connectivityContent;
                draft.locations = null;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_GET_PROD_CONNECTIVITY_CONTENT:
                draft.locationContent = null;
                draft.softwareContent = null;
                draft.hardwareContent = null;
                draft.connectivityContent = null;
                draft.locations = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get HARDWARE help text
            case locationContainerTypes.REQUEST_GET_HARDWARE_HELP_TEXT:
                draft.singleHardware = null;
                draft.hardwareHelpText = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_GET_HARDWARE_HELP_TEXT:
                draft.singleHardware = null;
                draft.hardwareHelpText = action.hardwareHelpText;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_GET_HARDWARE_HELP_TEXT:
                draft.singleHardware = null;
                draft.hardwareHelpText = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get SOFTWARE help text
            case locationContainerTypes.REQUEST_GET_SOFTWARE_HELP_TEXT:
                draft.singleSoftware = null;
                draft.softwareHelpText = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_GET_SOFTWARE_HELP_TEXT:
                draft.singleSoftware = null;
                draft.softwareHelpText = action.softwareHelpText;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_GET_SOFTWARE_HELP_TEXT:
                draft.singleSoftware = null;
                draft.softwareHelpText = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get CONNECTIVITY help text
            case locationContainerTypes.REQUEST_GET_CONNECTIVITY_HELP_TEXT:
                draft.singleConnectivity = null;
                draft.connectivityHelpText = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_GET_CONNECTIVITY_HELP_TEXT:
                draft.singleConnectivity = null;
                draft.connectivityHelpText = action.connectivityHelpText;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_GET_CONNECTIVITY_HELP_TEXT:
                draft.singleConnectivity = null;
                draft.connectivityHelpText = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get hardware by id
            case locationContainerTypes.REQUEST_GET_HARDWARE_BY_ID:
                draft.loadingAfterPost = true;
                draft.singleHardware = null;
                draft.loading = true;
                draft.error = null;
                draft.postHardwareData = null;
                break;
            case locationContainerTypes.SUCCESS_GET_HARDWARE_BY_ID:
                draft.singleHardware = action.singleHardware;
                draft.loading = false;
                draft.error = null;
                draft.postHardwareData = null;
                break;
            case locationContainerTypes.FAILURE_GET_HARDWARE_BY_ID:
                draft.singleHardware = null;
                draft.loading = false;
                draft.error = action.error;
                draft.postHardwareData = null;
                break;

            // get connectivity by id
            case locationContainerTypes.REQUEST_GET_CONNECTIVITY_BY_ID:
                draft.loadingAfterPost = true;
                draft.singleConnectivity = null;
                draft.loading = true;
                draft.error = null;
                draft.postConnectivitiesData = null;
                break;
            case locationContainerTypes.SUCCESS_GET_CONNECTIVITY_BY_ID:
                draft.singleConnectivity = action.singleConnectivity;
                draft.loading = false;
                draft.error = null;
                draft.postConnectivitiesData = null;
                break;
            case locationContainerTypes.FAILURE_GET_CONNECTIVITY_BY_ID:
                draft.singleConnectivity = null;
                draft.loading = false;
                draft.error = action.error;
                draft.postConnectivitiesData = null;
                break;

            // get software by id
            case locationContainerTypes.REQUEST_GET_SOFTWARE_BY_ID:
                draft.loadingAfterPost = true;
                draft.singleSoftware = null;
                draft.loading = true;
                draft.error = null;
                break;
            case locationContainerTypes.SUCCESS_GET_SOFTWARE_BY_ID:
                draft.singleSoftware = action.singleSoftware;
                draft.loading = false;
                draft.error = null;
                break;
            case locationContainerTypes.FAILURE_GET_SOFTWARE_BY_ID:
                draft.singleSoftware = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            //clear location status
            case clearLocationStatusTypes.CLEAR_LOCATION_STATUS:
                draft.status = null;
                draft.method = null;
                break;

            default:
                return state;
        }
    })
export default locationContainerReducer;
