import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: plansContainerTypes, Creators: plansContainerCreators } = createActions({
    requestGetPlans: [''],
    successGetPlans: ['plans'],
    failureGetPlans: ['error'],
});

export type InitialStateType = {
    plans: object | null;
    error: string | null;
    loading: boolean | null;
}

export const initialState: InitialStateType = { plans: null, error: null, loading: null };

export const plansContainerReducer = (state = initialState, action: any) =>
    produce(state, (draft) => {
        switch (action.type) {
            case plansContainerTypes.REQUEST_GET_PLANS:
                draft.plans = null;
                draft.loading = true;
                draft.error = null;
                break;
            case plansContainerTypes.SUCCESS_GET_PLANS:
                draft.plans = action.plans;
                draft.loading = false;
                draft.error = null;
                break;
            case plansContainerTypes.FAILURE_GET_PLANS:
                draft.plans = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            default:
                return state;
        }
    })
export default plansContainerReducer;
