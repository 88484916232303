import { ErrorObject } from "../../containers/HardwareContainer/AddHardware";
import { HardwaresList } from "../../containers/HardwareContainer/Hardware";

function getReplacementValue(answerType6: string) {
    if (answerType6 === "Yes") {
        return 1;
    } else if (answerType6 === "No") {
        return 2;
    } else if (answerType6 === "DontKnow") {
        return 3;
    } else if (answerType6 === "NotApplicable") {
        return 4;
    } else {
        return 0;
    }
}

function getShowMoreInfo(showMoreInfo: boolean) {
    return `${showMoreInfo ? 'border-[#535353]' : 'border-[#959595]'}`
}

function infoBtnStyle(showMoreInfo: boolean) {
    return `${showMoreInfo ? 'text-[#535353]' : 'text-[#959595]'}`
}

function onThisLocationBtn(answerType3: string) {
    return `${answerType3 === 'OnThisLocation' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function notOnThisLocationBtn(answerType3: string) {
    return `${answerType3 === 'NotOnThisLocation' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function noBtn(answerType3: string) {
    return `${answerType3 === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function yesBtn(answerType3: string) {
    return `${answerType3 === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function nameErrObj(errObj: ErrorObject) {
    return `${errObj.name?.message ? 'border-[red]' : ''}`
}

function queOneYesBtn(answerType: string) {
    return `${answerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queOneNoBtn(answerType: string) {
    return `${answerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function answerTypeDontKnowBtn(answerType: string) {
    return `${answerType === 'DontKnow' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function answerTypeNotApplicableBtn(answerType: string) {
    return `${answerType === 'NotApplicable' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queTwoAnsTypeYesBtn(answerType2: string) {
    return `${answerType2 === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queTwoAnsTypeNoBtn(answerType2: string) {
    return `${answerType2 === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queTwoAnsTypeDontKnowBtn(answerType2: string) {
    return `${answerType2 === 'DontKnow' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queTwoAnsTypeNotApplicableBtn(answerType2: string) {
    return `${answerType2 === 'NotApplicable' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queThreeAnsTypeYesBtn(answerType4: string) {
    return `${answerType4 === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queThreeAnsTypeNoBtn(answerType4: string) {
    return `${answerType4 === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queThreeAnsTypeDontKnowBtn(answerType4: string) {
    return `${answerType4 === 'DontKnow' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queThreeAnsTypeNotApplicableBtn(answerType4: string) {
    return `${answerType4 === 'NotApplicable' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queFourAnsTypeYesBtn(answerType5: string) {
    return `${answerType5 === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queFourAnsTypeNoBtn(answerType5: string) {
    return `${answerType5 === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queFourAnsTypeDontKnowBtn(answerType5: string) {
    return `${answerType5 === 'DontKnow' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queFourAnsTypeNotApplicableBtn(answerType5: string) {
    return `${answerType5 === 'NotApplicable' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queFiveAnsTypeYesBtn(answerType6: string) {
    return `${answerType6 === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queFiveAnsTypeNoBtn(answerType6: string) {
    return `${answerType6 === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queFiveAnsTypeDontKnowBtn(answerType6: string) {
    return `${answerType6 === 'DontKnow' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

function queFiveAnsTypeNotApplicableBtn(answerType6: string) {
    return `${answerType6 === 'NotApplicable' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}


function hardwareNameWhenOther(answerType3: string, editHardwareObj: any, singleHardware: HardwaresList | undefined, lastQuestion: boolean) {
    return (answerType3 === (editHardwareObj ? '' : 'Yes') || answerType3 === 'OnThisLocation' || (singleHardware?.hardwareListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion))
}


export { getReplacementValue, getShowMoreInfo, infoBtnStyle, onThisLocationBtn, notOnThisLocationBtn, noBtn, yesBtn, nameErrObj, queOneYesBtn, queOneNoBtn, answerTypeDontKnowBtn, answerTypeNotApplicableBtn, queTwoAnsTypeYesBtn, queTwoAnsTypeNoBtn, queTwoAnsTypeDontKnowBtn, queTwoAnsTypeNotApplicableBtn, queThreeAnsTypeYesBtn, queThreeAnsTypeNoBtn, queThreeAnsTypeDontKnowBtn, queThreeAnsTypeNotApplicableBtn, queFourAnsTypeYesBtn, queFourAnsTypeNoBtn, queFourAnsTypeDontKnowBtn, queFourAnsTypeNotApplicableBtn, queFiveAnsTypeYesBtn, queFiveAnsTypeNoBtn, queFiveAnsTypeDontKnowBtn, queFiveAnsTypeNotApplicableBtn, hardwareNameWhenOther }