import { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from '../../components/StyledButton';
import { locationContainerCreators } from '../LocationsContainer/locationReducer';
import LoadingIcon from '../../assets/icons/loading-icon';
import Dropdown from '../../components/Dropdown';
import schema from '../../validations/schema';
import { listOptionsContainerCreators } from "../OrganisationContainer/reducer";
import { ContentType, ListOptionType } from '@onesixeight-nl/classes';
import Container from '../../components/Container/Container';
import GetIcons from '../../components/GetIcons/GetIcons';
import { BiaOverview, SoftwareList } from './SoftwareFirstScreen';
import { selectDecodedUserInfo } from '../../AppSelector';
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import { InfoDetails } from '../BIAContainer/BIAEdit';
import { handleSelectedAnsType, nameError } from '../../utils/SoftwareUtilsFolder/AddSoftwareUtils';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import Modal from '../../components/Modal/Modal';
import { redirectUrlCreators } from '../LoginContainer/reducer';

const testId = `add-software`;

export type Address = {
    [key: string]: any;
    type?: string;
    locationId?: string;
    street: string;
    houseNumber: number | null;
    houseNumberSuffix: string;
    postalcode: string;
    city: string;
    name?: string;
}

export type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
}

type ErrorMessage = {
    message: string
}

export type ErrorObject = {
    name?: ErrorMessage;
    sStorage?: ErrorMessage;
    sBackup?: ErrorMessage;
}

type SoftwareStorageList = {
    value: string;
    label: string;
}

type SoftwareBackupList = {
    value: string;
    label: string;
}

type SoftwareStorage = {
    listOptionId: string;
    name: string;
}

type SoftwareBackup = {
    listOptionId: string;
    name: string;
}

function lastQueBlock(lastQuestion: boolean, intl: IntlShape, isAddMode: boolean) {
    return (lastQuestion && isAddMode) ?
        <div className="font-semibold text-[32px] sm:text-2xl">
            <div> {intl.formatMessage({ id: "can_you_name_another_software?", defaultMessage: 'can_you_name_another_software?' })}</div>
        </div >
        :
        <div className="font-semibold text-[32px] sm:text-2xl">
            <div>{intl.formatMessage({ id: 'add_software', defaultMessage: 'add_software' })}</div>
        </div>
}

function softwareNameBlock(lastQuestion: boolean, intl: IntlShape, role: string | undefined, setMainAnswerType: React.Dispatch<React.SetStateAction<string>>, setSoftwareNameWhenOther: React.Dispatch<React.SetStateAction<string>>, errObj: ErrorObject, softwareNameWhenOther: string, mainAnswerType: string, isAddMode: boolean) {//NOSONAR
    function handleMainAnswerType(answer: string) {
        if (((role === "administrator") || (role === "editor"))) {
            if (answer === 'Yes') {
                setMainAnswerType('Yes');
            } else if (answer === 'No') {
                setMainAnswerType('No');
            }
        }
    }

    return (lastQuestion && isAddMode) ?
        <div className='mt-8'>
            <div data-testid={`${testId}-main-answer-type-no`} onClick={() => handleMainAnswerType('No')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${mainAnswerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
            </div>
            <div data-testid={`${testId}-main-answer-type-yes`} onClick={() => handleMainAnswerType('Yes')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${mainAnswerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
            </div>
        </div>
        :

        handlerSoftwareNameWhenOther(intl, setSoftwareNameWhenOther, errObj, softwareNameWhenOther)

}

function handlerSoftwareNameWhenOther(intl: IntlShape, setSoftwareNameWhenOther: React.Dispatch<React.SetStateAction<string>>, errObj: ErrorObject, softwareNameWhenOther: string) {
    return <div className='w-full max-w-[414px] border rounded-lg bg-white h-[160px] mt-8 mb-10 mr-10 p-10 sm:p-5'>
        <div>
            <p className='text-base font-bold text-[#121212] mb-2 sm:text-base sm:font-bold'>{intl.formatMessage({ id: 'software_name', defaultMessage: 'software_name' })}</p>
            <input
                data-testid={`${testId}-software-name-one`}
                type="text"
                maxLength={100}
                value={softwareNameWhenOther}
                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-3 ${errObj.name?.message ? 'border-[red]' : ''}`}
                onChange={(e) => setSoftwareNameWhenOther(e.target.value)}
            />
            {errObj.name?.message ?
                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                    <span>{errObj.name.message}</span>
                </div>
                :
                null
            }
        </div>
    </div>
}

function lastQueTitle(editSoftwareObj: any, intl: IntlShape, lastQuestion: boolean, isAddMode: boolean) {
    return editSoftwareObj ?
        <div className="font-semibold text-[32px] sm:text-2xl">
            {intl.formatMessage({ id: "edit_software", defaultMessage: 'edit_software' })}
        </div>
        :
        lastQueBlock(lastQuestion, intl, isAddMode)
}

function nameWhanOtherErr(errMsg: string | undefined) {
    return errMsg ?
        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
            <span>{errMsg}</span>
        </div>
        :
        null

}

function styleBtn(isDisableBtn: boolean, value1: string, value2: string) {
    return isDisableBtn ? value1 : value2
}

function SideDrawerHtml(infoDetails: InfoDetails[], textShow: string, SideDrawerClose: () => void, intl: IntlShape, SideDrawerOpen: () => void, learnMoreInfo: boolean | undefined) {
    return <div>
        {infoDetails && infoDetails.length !== 0 &&
            <div className=" w-[540px] leading-4 mb-10 text-[#535353] sm:w-full">
                <span className="font-normal text-lg">{textShow}</span>
                {textShow.length > 90 &&
                    <>
                        <span>{"... "}</span>
                        <span data-testid={`${testId}-learn-more-click`} className={"font-semibold text-[#3BA8FC] text-lg cursor-pointer w-max"} onClick={() => SideDrawerOpen()}>{intl.formatMessage({ id: 'learn_more', defaultMessage: 'learn_more' })}</span>
                    </>
                }
            </div>}

        <div className={`w-full max-w-[420px] top-0 right-0  h-full fixed z-[1055] border-r sm:overflow-y-auto sm:w-full sm:max-w-full ${!learnMoreInfo ? 'translate-x-[100%] ease-in-out duration-300' : 'translate-x-[0%] ease-in-out duration-300'}`}>
            <SideDrawer drawerInfo={infoDetails} SideDrawerClose={SideDrawerClose} />
        </div>
    </div>
}

export default function Add_Softwere() {
    // hooks
    const intl = useIntl();
    const params = useParams();
    const { state, search } = useLocation();
    const { id, processId, softwareId } = params;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const { sLastQuestion }: RootState = state || {};
    const [singleProcess, setSingleProcess] = useState<BiaOverview>()
    const [editSoftwareObj, setEditSoftwareObj] = useState<SoftwareList>()
    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const locationSelector = useSelector((state: RootState) => state.locationContainer);
    const {
        softwareStorage,
        softwareBackup,
    } = useSelector((state: RootState) => state.listOptions);

    // states
    const [mainAnswerType, setMainAnswerType] = useState<string>('');
    const [answerType, setAnswerType] = useState<string>('');
    const [answerType2, setAnswerType2] = useState<string>('');
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>();
    const [softwareNameWhenOther, setSoftwareNameWhenOther] = useState<string>("");
    const [softwareStorageList, setSoftwareStorageList] = useState<SoftwareStorageList[]>([]);
    const [selectedSoftwareStorage, setSelectedSoftwareStorage] = useState("");
    const [softwareBackupList, setSoftwareBackupList] = useState<SoftwareBackupList[]>([]);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const [selectedSoftwareBackup, setSelectedSoftwareBackup] = useState("");
    const [errObj, setErrObj] = useState<ErrorObject>({
        name: undefined,
        sStorage: undefined,
        sBackup: undefined,
    });
    const [lastQuestion, setLastQuestion] = useState<boolean>(true);
    const [isAddMode, setIsAddMode] = useState<boolean>(true);

    const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);

    const intialSoftware: SoftwareList = {
        softwareId: '',
        name: '',
        inSupport: 0,
        patches: 0,
        storage: '',
        backup: '',
        created: '',
        modified: '',
        storageName: '',
        backupName: '',
    };
    const [singleSoftware, setSingleSoftware] = useState<SoftwareList>({ ...intialSoftware });
    const mainAnswerTypeHandler = (mainAnswerType === (editSoftwareObj ? '' : 'Yes') || !lastQuestion)
    const userRoles = ((role === "administrator") || (role === "editor"))
    const [learnMoreInfo, setLearnMoreInfo] = useState<boolean | undefined>(false);
    const findInfoText: any = infoDetails?.length > 0 ? infoDetails?.find((ele) => ele?.attributes?.InfoText) : []
    const InfoTextDetail = findInfoText ? findInfoText?.attributes?.InfoText : {}
    const textShow = InfoTextDetail ? InfoTextDetail?.substring(0, 100) : "";
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
    const [currentIndex, setCurrentIndex] = useState<number>(locationSelector?.softwares?.length ?? 0);
    const [softwareList, setSoftwareList] = useState<SoftwareList[]>([]);
    const isCancel = new URLSearchParams(search).get('isCancel');
    const [loading, setLoading] = useState(false);
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    useEffect(() => {
        if (locationSelector && locationSelector?.softwares && locationSelector?.softwares?.length > 0) {
            setSoftwareList(locationSelector?.softwares);
        }
    }, [locationSelector?.softwares])

    useEffect(() => {
        if (locationSelector?.status !== null && locationSelector?.status !== undefined) {
            setIsSaved(true);
            setTimeCounter(1);
        }
    }, [locationSelector])
    /* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved]);

    function SideDrawerOpen() {
        setLearnMoreInfo(!learnMoreInfo);
    }

    function SideDrawerClose() {
        setLearnMoreInfo(false);
    }

    useEffect(() => {
        const postSoftware = locationSelector.PostSoftwares
        if (postSoftware) {
            softwareList.push(postSoftware);
            setSoftwareList(softwareList)
        }
    }, [locationSelector.PostSoftwares, softwareList])

    useEffect(() => {
        if (editSoftwareObj) {
            setSingleSoftware(editSoftwareObj);
        }
    }, [editSoftwareObj])

    useEffect(() => {
        setCurrentIndex(0);
        setIsAddMode(sLastQuestion);
        setLastQuestion(sLastQuestion);
    }, [sLastQuestion])

    useEffect(() => {
        if ((currentIndex === softwareList?.length) && !isAddMode) {
            setIsAddMode(true);
        }
    }, [currentIndex, softwareList])
    /* istanbul ignore next */
    const getEditValues = (value: number) => {
        if (value === 1) {
            return 'Yes';
        } else if (value === 2) {
            return 'No';
        } else if (value === 3) {
            return 'DontKnow';
        } else if (value === 4) {
            return 'NotApplicable';
        } else if (value === 0) {
            return '';
        }
    }

    useEffect(() => {
        if (editSoftwareObj) {
            setSoftwareNameWhenOther(editSoftwareObj?.name)
            setAnswerType(String(getEditValues(editSoftwareObj?.inSupport)));
            setAnswerType2(String(getEditValues(editSoftwareObj?.patches)));
            setSelectedSoftwareStorage(editSoftwareObj?.storage);
            setSelectedSoftwareBackup(editSoftwareObj?.backup);
        }
    }, [editSoftwareObj])

    useEffect(() => {
        if (locationSelector.singleSoftware && !editSoftwareObj) {
            setSoftwareNameWhenOther(locationSelector.singleSoftware?.name)
            setAnswerType(String(getEditValues(locationSelector.singleSoftware?.inSupport)));
            setAnswerType2(String(getEditValues(locationSelector.singleSoftware?.patches)));
            setSelectedSoftwareStorage(locationSelector.singleSoftware?.storage);
            setSelectedSoftwareBackup(locationSelector.singleSoftware?.backup);
        }
    }, [locationSelector.singleSoftware])

    type Option = {
        value: string;
        label: string;
    }
    const initialOption: Option = {
        label: intl.formatMessage({ id: 'select', defaultMessage: 'select' }),
        value: "",
    }

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.riskCategory && riskAreaSelector.riskCategory.length && id) {
            const riskCategoryObj = riskAreaSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === id)
            setSingleRiskCategory(riskCategoryObj);
            if (riskCategoryObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [riskAreaSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (singleRiskCategory) {
            const isSkip = new URLSearchParams(search).get('skip') || -1;
            dispatch(locationContainerCreators.requestGetSoftwareProcesses(decodedUserInfo, "", singleRiskCategory, isCancel, "", intl, false, isSkip));
            dispatch(redirectUrlCreators.clearRedirectUrl())
        }
    }, [singleRiskCategory, id])

    useEffect(() => {
        if (locationSelector && locationSelector.processes && locationSelector.processes.length && processId) {
            const currentProccess = locationSelector.processes.find((ele: any) => ele?.processId === processId)
            setSingleProcess(currentProccess);
        }
    }, [locationSelector.processes, processId])

    useEffect(() => {
        if (singleProcess) {
            dispatch(locationContainerCreators.requestGetSoftwares(singleProcess, singleRiskCategory, isCancel, '', intl));
            dispatch(redirectUrlCreators.clearRedirectUrl())
        }
    }, [singleProcess, processId, id]);

    useEffect(() => {
        if (locationSelector && locationSelector.softwares && locationSelector.softwares.length && softwareId) {
            const currentsoftware = locationSelector.softwares.find((ele: any) => ele?.softwareId === softwareId)
            setEditSoftwareObj(currentsoftware);
            setSingleSoftware(currentsoftware)
        }
    }, [locationSelector.softwares, softwareId])
    /* istanbul ignore next */
    useEffect(() => {
        dispatch(listOptionsContainerCreators.requestGetSoftwareStorage(ListOptionType.SOFTWARE_STORAGE));
        dispatch(listOptionsContainerCreators.requestGetSoftwareBackup(ListOptionType.SOFTWARE_BACKUP));
    }, []);/* eslint-disable react-hooks/exhaustive-deps */
    /* istanbul ignore next */
    useEffect(() => {
        if (softwareStorage && softwareStorage.length) {
            let newSoftwareStorageList = softwareStorage.map((softwareStorageItem: SoftwareStorage) => {
                return {
                    label: softwareStorageItem.name,
                    value: softwareStorageItem.listOptionId
                }
            });
            setSoftwareStorageList(newSoftwareStorageList);
        }
        if (softwareBackup && softwareBackup.length) {
            let newSoftwareBackupList = softwareBackup.map((SoftwareBackupItem: SoftwareBackup) => {
                return {
                    label: SoftwareBackupItem.name,
                    value: SoftwareBackupItem.listOptionId
                }
            });
            setSoftwareBackupList(newSoftwareBackupList);
        }
    }, [softwareStorage, softwareBackup]);
    /* istanbul ignore next */
    useEffect(() => {
        dispatch(locationContainerCreators.requestGetSoftwareHelpText(ContentType.SOFTWARE, decodedUserInfo));
    }, [decodedUserInfo])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.softwareHelpText) {
            setInfoDetails(locationSelector.softwareHelpText?.data);
        }
    }, [locationSelector])

    useEffect(() => {
        if (locationSelector?.loadingAfterPost) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [locationSelector?.loadingAfterPost])

    // functions
    /* istanbul ignore next */
    function getinSupportValue() {
        if (answerType === "Yes") {
            return 1;
        } else if (answerType === "No") {
            return 2;
        } else if (answerType === "DontKnow") {
            return 3;
        } else if (answerType === "NotApplicable") {
            return 4;
        } else {
            return 0;
        }
    };
    /* istanbul ignore next */
    function getpatches() {
        if (answerType2 === "Yes") {
            return 1;
        } else if (answerType2 === "No") {
            return 2;
        } else if (answerType2 === "DontKnow") {
            return 3;
        } else if (answerType2 === "NotApplicable") {
            return 4;
        } else {
            return 0;
        }
    };

    function handleCancelClick() {
        navigate(`/software/${id}?isCancel=0`);
    }
    /* istanbul ignore next */
    function handlePreviousSoftware() {
        setIsAddMode(false);
        if (currentIndex === -1) {
            if (singleProcess?.processId && softwareList[softwareList?.length - 1] && softwareList[softwareList?.length - 1]?.softwareId) {
                dispatch(locationContainerCreators.requestGetSoftwareById(singleProcess?.processId, softwareList[softwareList?.length - 1]?.softwareId))
            }
            setCurrentIndex(softwareList.length - 1);
        } else if (currentIndex > -1) {
            setCurrentIndex(currentIndex - 1);
            if (singleProcess?.processId && softwareList[currentIndex - 1] && softwareList[currentIndex - 1]?.softwareId) {
                dispatch(locationContainerCreators.requestGetSoftwareById(singleProcess?.processId, softwareList[currentIndex - 1]?.softwareId))
            }
        }
        //setTimeout(() => {
        //    dispatch(locationContainerCreators.requestGetSoftwares(singleProcess, singleRiskCategory, isCancel, '', intl));
        //}, 1000)
    }
    /* istanbul ignore next */
    function handleEditSoftwareInAddMode(isAddMode: boolean) {
        const nameErr = schema.SoftwareNameWhenOther.validate(softwareNameWhenOther).error;
        const SoftwareStorageErr = schema.SoftwareStorageList.validate(selectedSoftwareStorage).error;
        const SoftwareBackupErr = schema.SoftwareBackupList.validate(selectedSoftwareBackup).error;
        if (mainAnswerType === '') {
            if (nameErr || SoftwareStorageErr || SoftwareBackupErr) {
                setErrObj({
                    name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                    sStorage: SoftwareStorageErr?.message ? { message: intl.formatMessage({ id: SoftwareStorageErr?.message, defaultMessage: SoftwareStorageErr?.message }) } : undefined,
                    sBackup: SoftwareBackupErr?.message ? { message: intl.formatMessage({ id: SoftwareBackupErr?.message, defaultMessage: SoftwareBackupErr?.message }) } : undefined,
                });
            } else if (answerType !== "" && answerType2 !== "" && softwareNameWhenOther !== "" && selectedSoftwareStorage !== "" && selectedSoftwareBackup !== "") {
                setErrObj({ name: undefined, sStorage: undefined, sBackup: undefined });
                setMainAnswerType('');
                setSoftwareNameWhenOther('');
                setAnswerType('');
                setAnswerType2('');
                setSelectedSoftwareStorage('');
                setSelectedSoftwareBackup('');

                const payload = {
                    name: softwareNameWhenOther !== "" ? softwareNameWhenOther : "",
                    inSupport: getinSupportValue(),
                    patches: getpatches(),
                    storage: selectedSoftwareStorage,
                    backup: selectedSoftwareBackup
                }
                dispatch(locationContainerCreators.requestPutSoftwares(singleProcess?.processId, softwareList[currentIndex]?.softwareId, payload))
                if (isAddMode) {
                    setCurrentIndex(currentIndex + 1);
                } else {
                    navigate(`/software/${id}?isCancel=0`);
                }
            }
        } else {
            navigate(`/software/${id}`);
        }
    }

    /* istanbul ignore next */
    function handleNextBtn() { //NOSONAR
        const nameErr = schema.SoftwareNameWhenOther.validate(softwareNameWhenOther).error;
        const SoftwareStorageErr = schema.SoftwareStorageList.validate(selectedSoftwareStorage).error;
        const SoftwareBackupErr = schema.SoftwareBackupList.validate(selectedSoftwareBackup).error;
        if (softwareList[currentIndex]?.softwareId && !editSoftwareObj && sLastQuestion) {
            // EDIT SOFTWARE IN ADD MODE
            if (singleProcess?.processId && softwareList[currentIndex + 1]?.softwareId) {
                dispatch(locationContainerCreators.requestGetSoftwareById(singleProcess?.processId, softwareList[currentIndex + 1]?.softwareId))
            }
            handleEditSoftwareInAddMode(true);
        } else if (editSoftwareObj) {
            if (mainAnswerType === '') {
                if (nameErr || SoftwareStorageErr || SoftwareBackupErr) {
                    setErrObj({
                        name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                        sStorage: SoftwareStorageErr?.message ? { message: intl.formatMessage({ id: SoftwareStorageErr?.message, defaultMessage: SoftwareStorageErr?.message }) } : undefined,
                        sBackup: SoftwareBackupErr?.message ? { message: intl.formatMessage({ id: SoftwareBackupErr?.message, defaultMessage: SoftwareBackupErr?.message }) } : undefined,
                    });
                } else if (answerType !== "" && answerType2 !== "" && softwareNameWhenOther !== "" && selectedSoftwareStorage !== "" && selectedSoftwareBackup !== "") {
                    setErrObj({ name: undefined, sStorage: undefined, sBackup: undefined });
                    setMainAnswerType('');
                    setSoftwareNameWhenOther('');
                    setAnswerType('');
                    setAnswerType2('');
                    setSelectedSoftwareStorage('');
                    setSelectedSoftwareBackup('');

                    const payload = {
                        name: softwareNameWhenOther !== "" ? softwareNameWhenOther : "",
                        inSupport: getinSupportValue(),
                        patches: getpatches(),
                        storage: selectedSoftwareStorage,
                        backup: selectedSoftwareBackup
                    }
                    dispatch(locationContainerCreators.requestPutSoftwares(singleProcess?.processId, singleSoftware?.softwareId, payload))
                    navigate(`/software/${id}?isCancel=0`);
                }
            } else {
                navigate(`/software/${id}`);
            }
        } else {
            if (lastQuestion) {
                if (mainAnswerType === 'Yes') {
                    if (nameErr || SoftwareStorageErr || SoftwareBackupErr) {
                        setErrObj({
                            name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                            sStorage: SoftwareStorageErr?.message ? { message: intl.formatMessage({ id: SoftwareStorageErr?.message, defaultMessage: SoftwareStorageErr?.message }) } : undefined,
                            sBackup: SoftwareBackupErr?.message ? { message: intl.formatMessage({ id: SoftwareBackupErr?.message, defaultMessage: SoftwareBackupErr?.message }) } : undefined,
                        });
                    } else if (answerType !== "" && answerType2 !== "" && softwareNameWhenOther !== "" && selectedSoftwareStorage !== "" && selectedSoftwareBackup !== "") {
                        setErrObj({ name: undefined, sStorage: undefined, sBackup: undefined });
                        setMainAnswerType('');
                        setSoftwareNameWhenOther('');
                        setAnswerType('');
                        setAnswerType2('');
                        setSelectedSoftwareStorage('');
                        setSelectedSoftwareBackup('');

                        const payload = {
                            name: softwareNameWhenOther !== "" ? softwareNameWhenOther : "",
                            inSupport: getinSupportValue(),
                            patches: getpatches(),
                            storage: selectedSoftwareStorage,
                            backup: selectedSoftwareBackup
                        }
                        dispatch(locationContainerCreators.requestPostSoftwares(singleProcess?.processId, payload))
                        if (locationSelector && locationSelector?.softwares && locationSelector?.softwares?.length >= 0) {
                            setCurrentIndex(locationSelector?.softwares?.length + 1);
                        }
                    }
                } else {
                    navigate(`/software/${id}?skip=${singleProcess?.processId}`, { state: { showPopup: false } });
                }
            } else {
                if (mainAnswerType === '') {
                    if (nameErr || SoftwareStorageErr || SoftwareBackupErr) {
                        setErrObj({
                            name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                            sStorage: SoftwareStorageErr?.message ? { message: intl.formatMessage({ id: SoftwareStorageErr?.message, defaultMessage: SoftwareStorageErr?.message }) } : undefined,
                            sBackup: SoftwareBackupErr?.message ? { message: intl.formatMessage({ id: SoftwareBackupErr?.message, defaultMessage: SoftwareBackupErr?.message }) } : undefined,
                        });
                    } else if (answerType !== "" && answerType2 !== "" && softwareNameWhenOther !== "" && selectedSoftwareStorage !== "" && selectedSoftwareBackup !== "") {
                        setErrObj({ name: undefined, sStorage: undefined, sBackup: undefined });
                        setMainAnswerType('');
                        setSoftwareNameWhenOther('');
                        setAnswerType('');
                        setAnswerType2('');
                        setSelectedSoftwareStorage('');
                        setSelectedSoftwareBackup('');

                        const payload = {
                            name: softwareNameWhenOther !== "" ? softwareNameWhenOther : "",
                            inSupport: getinSupportValue(),
                            patches: getpatches(),
                            storage: selectedSoftwareStorage,
                            backup: selectedSoftwareBackup
                        }
                        dispatch(locationContainerCreators.requestPostSoftwares(singleProcess?.processId, payload))
                        navigate(`/software/${id}?isCancel=0`);
                    }
                } else {
                    navigate(`/software/${id}?isCancel=0`);
                }
            }
        }
        setLearnMoreInfo(false);
        //if (!editSoftwareObj && mainAnswerType !== "No") {
        //    setTimeout(() => {
        //        dispatch(locationContainerCreators.requestGetSoftwares(singleProcess, singleRiskCategory, isCancel, '', intl));
        //    }, 1000)
        //}
    }
    /* istanbul ignore next */
    function handleSoftwareStorageDropDown(value: string) {
        setSelectedSoftwareStorage(value)
    }

    function handleSoftwareBackupDropDown(value: string) {
        setSelectedSoftwareBackup(value)
    }
    /* istanbul ignore next */
    function handleAnswerType(answer: string) {
        if (userRoles) {
            if (answer === 'Yes') {
                setAnswerType('Yes');
            } else if (answer === 'No') {
                setAnswerType('No');
            } else if (answer === 'DontKnow') {
                setAnswerType('DontKnow');
            } else if (answer === 'NotApplicable') {
                setAnswerType('NotApplicable');
            }
        }
    }
    /* istanbul ignore next */
    function handleAnswer(answer: string) {
        if (userRoles) {
            if (answer === 'Yes') {
                setAnswerType2('Yes');
            } else if (answer === 'No') {
                setAnswerType2('No');
            } else if (answer === 'DontKnow') {
                setAnswerType2('DontKnow');
            } else if (answer === 'NotApplicable') {
                setAnswerType2('NotApplicable');
            }
        }
    }
    /* istanbul ignore next */
    function handleBtnText() {
        if (editSoftwareObj) {
            return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
        } else {
            if (lastQuestion) {
                return intl.formatMessage({ id: 'next', defaultMessage: 'next' })
            } else {
                return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
            }
        }
    }

    const temp = (answerType === '' || answerType2 === '')

    function isDisableBtn(): boolean {
        if (lastQuestion && isAddMode) {
            if (mainAnswerType === '') {
                return true;
            } else if (mainAnswerType === 'No') {
                return false;
            } else if (mainAnswerType === 'Yes') {
                if (temp) {
                    return true;
                } else {
                    return false;
                }
            }
        } else if (!lastQuestion) {
            if (temp) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    return (
        <>
            {/*{locationSelector.loading || loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :*/}
            <div>
                <Container>
                    <div className="flex-grow-1 flex flex-col justify-between">
                        <div>
                            <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0 sm:pb-6'>
                                <div className="flex items-center font-semibold text-xl">
                                    <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                    <div className="ml-3 font-semibold text-xl text-[#535353]">
                                        {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                    </div>
                                </div>
                                {isSaved &&
                                    <div className="font-normal text-xs text-[#959595]">
                                        <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                    </div>
                                }
                            </div>
                            <div className="border w-full"></div>
                            <div className='w-full align-middle items-center flex justify-between mt-5'>
                                <div className='flex-col mt-5'>
                                    <div className="font-bold text-xs text-[#959595] sm:text-2xl uppercase font-family-arial">
                                        {singleProcess?.name || singleProcess?.nameWhenOther}
                                    </div>
                                    {lastQueTitle(editSoftwareObj, intl, lastQuestion, isAddMode)}
                                </div>
                            </div>
                            {SideDrawerHtml(infoDetails, textShow, SideDrawerClose, intl, SideDrawerOpen, learnMoreInfo)}
                        </div>

                        <div className='flex sm:flex-col'>
                            {editSoftwareObj ?
                                <div className='w-full max-w-[414px] border rounded-lg bg-white h-[160px] mt-8 mb-10 mr-10 p-10 sm:p-5'>
                                    <div>
                                        <p className='text-base font-bold text-[#121212] mb-2 sm:text-base sm:font-bold'>{intl.formatMessage({ id: 'software_name', defaultMessage: 'software_name' })}</p>
                                        <input
                                            data-testid={`${testId}-software-name`}
                                            type="text"
                                            maxLength={100}
                                            value={softwareNameWhenOther}
                                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-3 ${nameError(errObj)}`}
                                            onChange={(e) => setSoftwareNameWhenOther(e.target.value)}
                                        />
                                        {nameWhanOtherErr(errObj.name?.message)}
                                    </div>
                                </div>
                                :
                                softwareNameBlock(lastQuestion, intl, role, setMainAnswerType, setSoftwareNameWhenOther, errObj, softwareNameWhenOther, mainAnswerType, isAddMode)
                            }

                            {(mainAnswerTypeHandler || !isAddMode) &&
                                <div className='w-full max-w-[414px] ml-[24px] border rounded-lg bg-white mb-10 mt-8 p-6 sm:p-5 sm:ml-[0px]'>
                                    <div className="flex-1">
                                        {mainAnswerType === 'Yes' &&
                                            <div className='mb-10'>
                                                <p className='text-base font-bold text-[#121212] mb-2 sm:text-base sm:font-bold font-family-arial'>{intl.formatMessage({ id: 'software_name', defaultMessage: 'software_name' })}</p>
                                                <input
                                                    data-testid={`${testId}answer-type-software-name`}
                                                    disabled={!userRoles}
                                                    type="text"
                                                    maxLength={100}
                                                    value={softwareNameWhenOther}
                                                    className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-3 ${nameError(errObj)}`}
                                                    onChange={(e) => setSoftwareNameWhenOther(e.target.value)}
                                                />
                                                {nameWhanOtherErr(errObj.name?.message)}
                                            </div>
                                        }
                                        <div>
                                            <label className='inline-block text-base font-bold mb-1 sm:text-base sm:font-bold font-family-arial'>{intl.formatMessage({ id: 'Is_supplier_support_available?', defaultMessage: 'Is_supplier_support_available?' })}</label>
                                            <div className="mr-6 sm:w-full sm:mr-0">
                                                <div className='flex gap-[24px]'>
                                                    <div data-testid={`${testId}-answer-type-yes`} onClick={() => handleAnswerType('Yes')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${handleSelectedAnsType(answerType, 'Yes')} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-answer-type-no`} onClick={() => handleAnswerType('No')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full sm:taxt-base sm:font-semibold ${handleSelectedAnsType(answerType, 'No')} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                    </div>
                                                </div>

                                                <div className='flex gap-[24px]'>
                                                    <div data-testid={`${testId}-answer-type-dont-know`} onClick={() => handleAnswerType('DontKnow')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full sm:w[180px] sm:h-[48px] ${handleSelectedAnsType(answerType, 'DontKnow')} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'don’t_know', defaultMessage: 'don’t_know' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-answer-type-not-applicable`} onClick={() => handleAnswerType('NotApplicable')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${handleSelectedAnsType(answerType, 'NotApplicable')} flex items-center justify-center mb-[40px]`}>
                                                        <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'not_applicable', defaultMessage: 'not_applicable' })}</div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <label className='inline-block text-base font-bold mb-1 font-family-arial'>{intl.formatMessage({ id: 'are_upgrades_and_patches_installed_systematically', defaultMessage: 'are_upgrades_and_patches_installed_systematically' })}</label>
                                                    <div className='flex gap-[24px]'>
                                                        <div data-testid={`${testId}-upgrade-type-yes`} onClick={() => handleAnswer('Yes')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${handleSelectedAnsType(answerType2, 'Yes')} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                        </div>
                                                        <div data-testid={`${testId}-upgrade-type-no`} onClick={() => handleAnswer('No')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${handleSelectedAnsType(answerType2, 'No')} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                        </div>
                                                    </div>

                                                    <div className='flex gap-[24px]'>
                                                        <div data-testid={`${testId}-upgrade-type-dont-know`} onClick={() => handleAnswer('DontKnow')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${handleSelectedAnsType(answerType2, 'DontKnow')} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-base sm:taxt-base sm:font-semibold ">{intl.formatMessage({ id: 'don’t_know', defaultMessage: 'don’t_know' })}</div>
                                                        </div>
                                                        <div data-testid={`${testId}-upgrade-type-not-applicable`} onClick={() => handleAnswer('NotApplicable')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${handleSelectedAnsType(answerType2, 'NotApplicable')} flex items-center justify-center mb-[40px]`}>
                                                            <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'not_applicable', defaultMessage: 'not_applicable' })}</div>
                                                        </div>
                                                    </div>

                                                    <div className="w-full mb-[40px]">
                                                        <label className="inline-block text-base font-bold mb-1 sm:text-base sm:font-bold font-family-arial">
                                                            {intl.formatMessage({ id: "where_do_your_store_your_data_from_this_software/application?", defaultMessage: 'where_do_your_store_your_data_from_this_software/application?' })}
                                                        </label>
                                                        <Dropdown
                                                            testId={`${testId}-software-storage-list`}
                                                            options={[...[initialOption], ...softwareStorageList]}
                                                            value={selectedSoftwareStorage}
                                                            onChange={(value: string) =>
                                                                handleSoftwareStorageDropDown(value)
                                                            }
                                                            error={errObj.sStorage?.message}
                                                        />
                                                        {nameWhanOtherErr(errObj.sStorage?.message)}
                                                    </div>

                                                    <div className="w-full">
                                                        <label className="inline-block text-base font-bold mb-1 sm:text-base sm:font-bold font-family-arial">
                                                            {intl.formatMessage({ id: "second_label", defaultMessage: 'second_label' })}
                                                        </label>
                                                        <Dropdown
                                                            options={[...[initialOption], ...softwareBackupList]}
                                                            value={selectedSoftwareBackup}
                                                            onChange={(value: string) =>
                                                                handleSoftwareBackupDropDown(value)
                                                            }
                                                            error={errObj.sBackup?.message}
                                                        />
                                                        {nameWhanOtherErr(errObj.sBackup?.message)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {((lastQuestion || isAddMode || currentIndex > 0)) &&
                        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4 sm:ml-[0px] sm:gap-0">
                            <div>
                                <StyledButton
                                    testId={`${testId}-previous-btn`}
                                    onClick={() => handlePreviousSoftware()}
                                    disabled={(currentIndex === 0) ? true : false}
                                    buttonClass={'sm:px-2'}
                                    flexReverse={'flex-row-reverse'}
                                    width={'w-48 sm:w-max'}
                                    align={'justify-start'}
                                    text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                    color={'text-[#535353]'}
                                    iconType={'arrowleft'}
                                    backgroundColor={'bg-[#EDEDED]'}
                                />
                            </div>
                            {userRoles &&
                                <div>
                                    <StyledButton
                                        testId={`${testId}-next-btn`}
                                        width={'w-48 sm:w-max'}
                                        buttonClass={'sm:px-2 PrimoryBtn'}
                                        disabled={isDisableBtn()}
                                        align={"justify-end"}
                                        text={handleBtnText()}
                                        backgroundColor={styleBtn(isDisableBtn(), "bg-[#EDEDED]", "bg-black")}
                                        color={styleBtn(isDisableBtn(), "text-[#535353]", "text-white")}
                                        iconType={styleBtn(isDisableBtn(), "blackarrow", "arrow")}
                                        onClick={() => handleNextBtn()}
                                    />
                                </div>}
                        </div>
                    }
                    <div className="w-full flex items-center justify-between mt-4">
                        {(editSoftwareObj || !lastQuestion) ?
                            <StyledButton testId={`${testId}-cancel-btn`} onClick={() => handleCancelClick()} buttonClass={'sm:px-2'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })} color={'text-[#535353]'} backgroundColor={'bg-[#EDEDED]'} />
                            :
                            <div className="w-48 h-[52px] flex items-center justify-center">
                                <button onClick={() => handleCancelClick()} className="flex items-center justify-center">
                                    <span className="text-[#535353] font-family-arial font-semibold">{intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}</span>
                                </button>
                            </div>
                        }
                        {(editSoftwareObj || !lastQuestion) && <StyledButton testId={`${testId}-next-btn-software-screem`} onClick={() => handleNextBtn()} buttonClass={'sm:px-2 PrimoryBtn'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })} disabled={isDisableBtn()} color={isDisableBtn() ? 'text-[#535353]' : 'text-white'} backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />}
                    </div>
                </Container>
            </div>
            {/*}*/}
            <Modal show={isWromgOrgModal} onClose={() => ""}>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                </div>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-goto-dashboard-btn`}
                        onClick={() => handleWrongOrgModel()}
                        buttonClass={'PrimoryBtn'}
                        width={"w-[220px] sm:w-40"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                        color={"text-white mr-3 text-base"}
                        iconType={'home'}
                        backgroundColor={"bg-black"}
                    />
                </div>
            </Modal>
        </>
    )
}
