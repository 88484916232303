import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../../components/Container/Container";
import StyledButton from "../../../components/StyledButton";
import { listOptionsContainerCreators } from "../reducer";
import { Address } from "../Step2";
import schema from "../../../validations/schema";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../../components/Modal/Modal";

const testId = `add-address`;

const initialAddress: Address = {
  houseNumberSuffix: "",
  city: "",
  houseNumber: null,
  street: "",
  postalcode: "",
  name: ""
};


type ErrorMessage = {
  message: string;
}
type ErrorObject = {
  street?: ErrorMessage;
  houseNumber?: ErrorMessage;
  postalcode?: ErrorMessage;
  city?: ErrorMessage;
  name?: ErrorMessage;
};
const AddAddress = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const intl = useIntl();
  const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
  const [address, setAddress] = useState<Address>({ ...initialAddress });
  const [errObj, setErrObj] = useState<ErrorObject>({
    name: undefined,
    street: undefined,
    houseNumber: undefined,
    postalcode: undefined,
    city: undefined,
  });
  const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

  function handleWrongOrgModel() {
    navigate("/dashboard")
  }

  useEffect(() => {
    dispatch(listOptionsContainerCreators.requestGetAddresses());
  }, [])

  useEffect(() => {
    if (listOptionsSelector && listOptionsSelector.addresses && listOptionsSelector.addresses.length > 0 && id) {
      const currentLocation = listOptionsSelector.addresses?.find((ele: Address) => ele?.locationId === id)
      setAddress({ ...currentLocation })
      if (currentLocation === undefined) {
        setIsWromgOrgModal(true)
      }
    }
  }, [listOptionsSelector.addresses, id])

  const handleAddressInput = (key: string, value: string) => {
    address[key] = value;
    setAddress({ ...address });
  };

  const addAddress = () => {
    const nameErr = schema.name.validate(address.name).error;
    const streetErr = schema.street.validate(address.street).error;
    const houseNumberErr = schema.houseNumber.validate(
      address.houseNumber
    ).error;
    const postalcodeErr = schema.postalcode.validate(address.postalcode).error;
    const cityErr = schema.city.validate(address.city).error;

    if (nameErr || streetErr || houseNumberErr || postalcodeErr || cityErr) {
      setErrObj({
        name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
        street: streetErr?.message ? { message: intl.formatMessage({ id: streetErr?.message, defaultMessage: streetErr?.message }) } : undefined,
        houseNumber: houseNumberErr?.message ? { message: intl.formatMessage({ id: houseNumberErr?.message, defaultMessage: houseNumberErr?.message }) } : undefined,
        postalcode: postalcodeErr?.message ? { message: intl.formatMessage({ id: postalcodeErr?.message, defaultMessage: postalcodeErr?.message }) } : undefined,
        city: cityErr?.message ? { message: intl.formatMessage({ id: cityErr?.message, defaultMessage: cityErr?.message }) } : undefined,
      });
    } else {
      setErrObj({
        name: undefined,
        street: undefined,
        houseNumber: undefined,
        postalcode: undefined,
        city: undefined,
      });
      address.houseNumber = Number(address.houseNumber);
      if (id) {
        let addressData: Address = { ...address };
        delete addressData.locationId;
        delete addressData.created;
        delete addressData.modified;
        delete addressData.organizationId;
        delete addressData.type;
        delete addressData.country;
        delete addressData.availableConnectivityCount;
        delete addressData.availableHardwareCount;
        delete addressData.connectivityCount;
        delete addressData.hardwareCount;
        dispatch(listOptionsContainerCreators.requestPutAddress(address.locationId, addressData));
      }
      else {
        dispatch(listOptionsContainerCreators.requestAddAddress(address));
      }
      navigate("/organisation/step2")
    }
  };

  function onCancel() {
    navigate(`/organisation/step2`)
  }

  return (
    <div className="flex-grow-1 flex flex-col justify-between">
      <Container>
        <div>
          <div className="m-auto w-full pb-7 align-middle items-center flex justify-between border-b sm:m-0 sm:pb-6">
            <div className="font-semibold text-xl">
              <div>{intl.formatMessage({ id: "organisation", defaultMessage: 'organisation' })}</div>
            </div>
          </div>
          <div className="my-10 sm:my-8">
            <div className="mt-8">
              <p className="max-w-xl text-2xl text-black font-semibold">
                {intl.formatMessage({ id: id ? "organization_step2_text_Edit" : "organization_step2_text_Add", defaultMessage: id ? "organization_step2_text_Edit" : "organization_step2_text_Add" })}
              </p>
              <div className="mt-7">
                <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                  <div>
                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                      {intl.formatMessage({ id: "name", defaultMessage: 'name' })}
                    </label>
                    <input
                      data-testid={`${testId}-name-org-sub-views`}
                      type="text"
                      maxLength={100}
                      className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.name?.message ? 'border-[red]' : ''}`}
                      value={address?.name ?? ""}
                      onChange={(event) =>
                        handleAddressInput("name", event.target.value)
                      }
                    />
                    <div className='text-zinc-600 font-normal text-xs mt-1 h-5 flex items-start justify-start font-family-arial'>
                      <span>{intl.formatMessage({ id: 'use_a_name_for_this_location_label', defaultMessage: 'use_a_name_for_this_location_label' })}</span>
                    </div>
                    {errObj.name?.message ? (
                      <div className="text-red-600 font-normal text-sm h-3 mb-5 sm:mt-7 flex items-start justify-start">
                        <span>{errObj.name?.message}</span>
                      </div>
                    ) : (
                      <div className="mb-8"></div>
                    )}
                  </div>
                  <div>
                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                      {intl.formatMessage({ id: "street_address", defaultMessage: 'street_address' })}
                    </label>
                    <input
                      data-testid={`${testId}-street-address-sub-views`}
                      type="text"
                      maxLength={100}
                      className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.street?.message ? 'border-[red]' : ''}`}
                      value={address?.street ?? ""}
                      onChange={(event) =>
                        handleAddressInput("street", event.target.value)
                      }
                    />
                    {errObj.street?.message ? (
                      <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                        <span>{errObj.street?.message}</span>
                      </div>
                    ) : (
                      <div className="mb-8"></div>
                    )}
                  </div>
                  <div className="flex gap-8 sm:flex-col">
                    <div className="flex-1">
                      <label className="inline-block text-base font-bold mb-1 font-family-arial">
                        {intl.formatMessage({ id: "number", defaultMessage: 'number' })}
                      </label>
                      <input data-testid={`${testId}-house-number-sub-views`} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.houseNumber?.message ? 'border-[red]' : ''}`} maxLength={15} type='text' value={address?.houseNumber ?? ""} onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        // if value is not blank, then test the regex
                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleAddressInput("houseNumber", e.target.value)
                        }
                      }} />

                      {errObj.houseNumber?.message ? (
                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                          <span>{errObj.houseNumber?.message}</span>
                        </div>
                      ) : (
                        <div className="mb-8"></div>
                      )}
                    </div>
                    <div className="flex-1">
                      <label className="inline-block text-base font-bold mb-1 font-family-arial">
                        {intl.formatMessage({ id: "affix_(optional)", defaultMessage: 'affix_(optional)' })}
                      </label>
                      <input
                        data-testid={`${testId}-suffix-sub-views`}
                        type="text"
                        maxLength={10}
                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full`}
                        value={address?.houseNumberSuffix ?? ""}
                        onChange={(event) =>
                          handleAddressInput(
                            "houseNumberSuffix",
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="flex sm:flex-wrap gap-8 sm:gap-0">
                    <div className="flex flex-col max-w-[220px] w-full">
                      <label className="inline-block text-base font-bold mb-1 font-family-arial">
                        {intl.formatMessage({ id: "zipcode", defaultMessage: 'zipcode' })}
                      </label>
                      <input
                        data-testid={`${testId}-zipcode-subviews`}
                        type="text"
                        maxLength={7}
                        className={`bg-zinc-50 border  rounded-lg p-3 py-2 w-full ${errObj.postalcode?.message ? 'border-[red]' : ''}`}
                        value={address?.postalcode ?? ""}
                        onChange={(event) =>
                          handleAddressInput("postalcode", event.target.value)
                        }
                      />
                      {errObj.postalcode?.message ? (
                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                          <span>{errObj.postalcode?.message}</span>
                        </div>
                      ) : (
                        <div className="mb-8"></div>
                      )}
                    </div>
                    <div className="flex flex-col w-full">
                      <label className="inline-block text-base font-bold mb-1 font-family-arial">
                        {intl.formatMessage({ id: "city", defaultMessage: 'city' })}
                      </label>
                      <input
                        data-testid={`${testId}-city-subviews`}
                        required
                        type="text"
                        maxLength={100}
                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.city?.message ? 'border-[red]' : ''}`}
                        value={address?.city ?? ""}
                        onChange={(event) =>
                          handleAddressInput("city", event.target.value)
                        }
                      />
                      {errObj.city?.message ? (
                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                          <span>{errObj.city?.message}</span>
                        </div>
                      ) : (
                        <div className="mb-8"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
          <StyledButton
            testId={`${testId}-cancel-btn-subviews`}
            width={"w-48 sm:w-full"}
            align={"justify-start"}
            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
            color={"text-gray-700"}
            flexReverse={"flex-row-reverse"}
            backgroundColor={"bg-gray-300"}
            onClick={() => onCancel()}
          />
          <StyledButton
            testId={`${testId}-save-subviews`}
            buttonClass={'PrimoryBtn'}
            width={"w-48 sm:w-full"}
            align={"justify-end"}
            text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
            color={"text-white"}
            iconType={"arrow"}
            backgroundColor={"bg-black"}
            onClick={() => addAddress()}
          />
        </div>
      </Container>
      <Modal show={isWromgOrgModal} onClose={() => ""}>
        <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
          <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
        </div>
        <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
          <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
        </div>
        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
          <StyledButton
            testId={`${testId}-goto-dashboard`}
            onClick={() => handleWrongOrgModel()}
            buttonClass={'PrimoryBtn'}
            width={"w-[220px] sm:w-40"}
            align={"justify-end"}
            text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
            color={"text-white mr-3 text-base"}
            iconType={'home'}
            backgroundColor={"bg-black"}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddAddress;
