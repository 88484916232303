import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { selectDecodedUserInfo } from "../../AppSelector";
import { dashboardContainerCreators } from "../../containers/ProtectedContainer/dashboardReducer";
import schema from "../../validations/schema";
import Container from "../Container/Container";
import StyledButton from "../StyledButton";

type Props = {
    onCancel: () => void
    testId?: string
};

type ErrorMessage = {
    message: string
}

type ErrorObject = {
    email?: ErrorMessage;
    password?: ErrorMessage;
    firstName?: ErrorMessage;
    lastName?: ErrorMessage;
    phoneNumber?: ErrorMessage;
    name?: ErrorMessage;
    kvKNumber?: ErrorMessage;
    street?: ErrorMessage;
    houseNumber?: ErrorMessage;
    houseNumberSuffix?: ErrorMessage;
    postalcode?: ErrorMessage;
    city?: ErrorMessage;
    country?: ErrorMessage;
    repeatPassword?: ErrorMessage;
}

export default function AddOrganization(props: Props) {

    const { onCancel, testId = `add-organization` } = props;

    // hooks
    const intl = useIntl();
    const dispatch = useDispatch();

    const dashboardSelector = useSelector((state: RootState) => state.dashboardContainer);
    const decodedUserInfo = useSelector(selectDecodedUserInfo);

    // states
    const [errObj, setErrObj] = useState<ErrorObject>({
        email: undefined,
        password: undefined,
        firstName: undefined,
        lastName: undefined,
        phoneNumber: undefined,
        name: undefined,
        kvKNumber: undefined,
        street: undefined,
        houseNumber: undefined,
        houseNumberSuffix: undefined,
        postalcode: undefined,
        city: undefined,
        country: undefined,
        repeatPassword: undefined,
    });
    const [name, setName] = useState<string>("");
    const [kvKNumber, setKvKNumber] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string>('');
    const [houseNumberSuffix, setHouseNumberSuffix] = useState<string>("");
    const [postalcode, setPostalcode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [isOrgExist, setIsOrgExist] = useState<boolean>(false);
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const errorMessage = dashboardSelector.error;

    useEffect(() => {
        if (isFocus) {
            document.getElementById("isOrgExist")?.focus();
        }
    }, [isFocus])

    // functions
    function handleIsOrgExist() {
        setIsOrgExist(!isOrgExist);
    }

    function handleSaveOrgInfo() {
        const nameErr = schema.name.validate(name).error;
        const kvKNumberErr = schema.kvKNumber.validate(kvKNumber).error;
        const streetErr = schema.street.validate(street).error;
        const houseNumberErr = schema.houseNumber.validate(houseNumber).error;
        const postalcodeErr = schema.postalcode.validate(postalcode).error;
        const cityErr = schema.city.validate(city).error;
        if (nameErr || kvKNumberErr || streetErr || houseNumberErr || postalcodeErr || cityErr) {
            setErrObj({
                name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
                kvKNumber: kvKNumberErr?.message ? { message: intl.formatMessage({ id: kvKNumberErr?.message, defaultMessage: kvKNumberErr?.message }) } : undefined,
                street: streetErr?.message ? { message: intl.formatMessage({ id: streetErr?.message, defaultMessage: streetErr?.message }) } : undefined,
                houseNumber: houseNumberErr?.message ? { message: intl.formatMessage({ id: houseNumberErr?.message, defaultMessage: houseNumberErr?.message }) } : undefined,
                houseNumberSuffix: undefined,
                postalcode: postalcodeErr?.message ? { message: intl.formatMessage({ id: postalcodeErr?.message, defaultMessage: postalcodeErr?.message }) } : undefined,
                city: cityErr?.message ? { message: intl.formatMessage({ id: cityErr?.message, defaultMessage: cityErr?.message }) } : undefined
            });
        } else {
            const payload = {
                companyName: name,
                chamberofcommercenr: kvKNumber,
                street,
                houseNumber: Number(houseNumber),
                houseNumberSuffix,
                postalcode,
                city,
                country: 'NL'
            }
            dispatch(dashboardContainerCreators.requestPostAddOrg(isOrgExist, payload, onCancel, decodedUserInfo));
            setErrObj({ email: undefined, password: undefined, firstName: undefined, lastName: undefined, name: undefined, phoneNumber: undefined, kvKNumber: undefined, street: undefined, houseNumber: undefined, houseNumberSuffix: undefined, postalcode: undefined, city: undefined, country: undefined, repeatPassword: undefined });
            setIsOrgExist(false);
            setIsFocus(true);
        }
    }

    return (
        <>
            <Container>
                <div data-testid={testId} className="flex items-center justify-center">
                    <div className="w-full sm:w-full sm:p-4">
                        <div className='mt-10 mb-4 font-semibold text-2xl flex items-start justify-start'>
                            {intl.formatMessage({ id: 'add_organization', defaultMessage: 'add_organization' })}
                        </div>
                        <div className='bg-white p-10 w-full min-h-max border rounded-lg sm:w-full sm:p-5'>
                            <div className="flex gap-8 sm:flex-col sm:gap-0">
                                <div className='flex-1'>
                                    <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                        <span className="font-family-arial">{intl.formatMessage({ id: 'name', defaultMessage: 'name' })}</span>
                                    </div>
                                    <input data-testid={`${testId}-name`} maxLength={100} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.name?.message ? 'border-[red]' : ''}`} type='text' value={name} onChange={(e) => setName(e.target.value)} />
                                    {errObj.name?.message ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                            <span>{errObj.name?.message}</span>
                                        </div>
                                        :
                                        <div className='mb-8'></div>
                                    }
                                </div>

                                <div className="flex-1">
                                    <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                        <span className="font-family-arial">{intl.formatMessage({ id: 'kvK_number', defaultMessage: 'kvK_number' })}</span>
                                    </div>
                                    <input data-testid={`${testId}-kvknumber`} maxLength={45} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.kvKNumber?.message ? 'border-[red]' : ''}`} type='text' value={kvKNumber} onChange={(e) => setKvKNumber(e.target.value)} />
                                    {errObj.kvKNumber?.message ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                            <span>{errObj.kvKNumber?.message}</span>
                                        </div>
                                        :
                                        <div className='mb-8'></div>
                                    }
                                </div>
                            </div>

                            {(errorMessage === 'Organization already exist') &&
                                <div className='flex items-center mb-8'>
                                    <div className='w-full max-w-[276px] font-semibold text-base text-[#535353]'>{intl.formatMessage({ id: 'organization_already_exists', defaultMessage: 'organization_already_exists' })}</div>
                                    <input autoFocus={isFocus} id={'isOrgExist'} className={`max-w-[20px] h-[20px] border hover:border-[#C1C1C1]`} type="checkbox" name="" checked={isOrgExist} onChange={() => handleIsOrgExist()} />
                                </div>
                            }

                            <div className="flex gap-8">
                                <div className='flex-1'>
                                    <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                        <span className="font-family-arial">{intl.formatMessage({ id: 'street_address', defaultMessage: 'street_address' })}</span>
                                    </div>
                                    <input data-testid={`${testId}-street`} maxLength={100} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.street?.message ? 'border-[red]' : ''}`} type='text' value={street} onChange={(e) => setStreet(e.target.value)} />
                                    {errObj.street?.message ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                            <span>{errObj.street?.message}</span>
                                        </div>
                                        :
                                        <div className='mb-8'></div>
                                    }
                                </div>

                            </div>
                            <div className="flex gap-8 sm:flex-col sm:gap-0">
                                <div className="flex-1">
                                    <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                        <span className="font-family-arial">{intl.formatMessage({ id: 'number', defaultMessage: 'number' })}</span>
                                    </div>
                                    <input data-testid={`${testId}-housenumber`} maxLength={12} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.houseNumber?.message ? 'border-[red]' : ''}`} type='text' value={houseNumber} onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        // if value is not blank, then test the regex
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setHouseNumber(e.target.value)
                                        }
                                    }} />
                                    {errObj.houseNumber?.message ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                            <span>{errObj.houseNumber?.message}</span>
                                        </div>
                                        :
                                        <div className='mb-8'></div>
                                    }
                                </div>

                                <div className='flex-1'>
                                    <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                        <span className="font-family-arial">{intl.formatMessage({ id: 'affix_(optional)', defaultMessage: 'affix_(optional)' })}</span>
                                    </div>
                                    <input data-testid={`${testId}-housenumbersuffix`} maxLength={10} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11`} type='text' value={houseNumberSuffix} onChange={(e) => setHouseNumberSuffix(e.target.value)} />
                                </div>
                            </div>

                            <div className='flex gap-8 sm:flex-col sm:gap-0 sm:mt-[32px]'>
                                <div className="flex-1 max-w-[220px] sm:max-w-full">
                                    <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                        <span className="font-family-arial">{intl.formatMessage({ id: 'zipcode', defaultMessage: 'zipcode' })}</span>
                                    </div>
                                    <input data-testid={`${testId}-postalcode`} maxLength={7} className={`bg-zinc-50 border rounded-lg px-3 mb-1 min-w-[100px] w-full h-11 ${errObj.postalcode?.message ? 'border-[red]' : ''}`} type='text' value={postalcode} onChange={(e) => setPostalcode(e.target.value)} />
                                    {errObj.postalcode?.message ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                            <span>{errObj.postalcode?.message}</span>
                                        </div>
                                        :
                                        <div className='mb-8'></div>
                                    }
                                </div>
                                <div className="flex-1">
                                    <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                                        <span className="font-family-arial">{intl.formatMessage({ id: 'city', defaultMessage: 'city' })}</span>
                                    </div>
                                    <input data-testid={`${testId}-city`} maxLength={100} className={`bg-zinc-50 border rounded-lg px-3 mb-1 min-w-[180px] w-full h-11 ${errObj.city?.message ? 'border-[red]' : ''}`} type='text' value={city} onChange={(e) => setCity(e.target.value)} />
                                    {errObj.city?.message ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                            <span>{errObj.city?.message}</span>
                                        </div>
                                        :
                                        <div className='mb-8'></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full align-middle items-center flex sm:gap-8 justify-between mt-8">
                    <StyledButton
                        testId={`${testId}-cancel-button`}
                        width={"w-48 sm:w-full"}
                        align={"justify-start"}
                        text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                        color={"text-[#535353]"}
                        flexReverse={"flex-row-reverse"}
                        backgroundColor={"bg-[#EDEDED]"}
                        onClick={() => onCancel()}
                    />
                    <StyledButton
                        testId={`${testId}-save-button`}
                        width={"w-48 sm:w-full"}
                        buttonClass={'PrimoryBtn'}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
                        color={"text-white"}
                        iconType={""}
                        backgroundColor={"bg-black"}
                        onClick={() => handleSaveOrgInfo()}
                    />
                </div>
            </Container>
        </>
    );
}