import { useState, useEffect } from 'react';
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../components/Container/Container";
import StyledButton from "../../components/StyledButton";
import schema from "../../validations/schema";
import { Address, AllRiskCategoryListData } from "../LocationsContainer/LocationContainer";
import { locationContainerCreators } from "../LocationsContainer/locationReducer";
import GetIcons from '../../components/GetIcons/GetIcons';
import { useNavigate, useParams } from 'react-router-dom';
import { selectDecodedUserInfo } from '../../AppSelector';
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import Modal from '../../components/Modal/Modal';

const testId = `add-location-container`;

const initialAddress: Address = {
    type: "hosting",
    houseNumberSuffix: "",
    city: "",
    houseNumber: null,
    street: "",
    postalcode: "",
    name: "",
};


type ErrorMessage = {
    message: string
}

type ErrorObject = {
    street?: ErrorMessage;
    houseNumber?: ErrorMessage;
    postalcode?: ErrorMessage;
    city?: ErrorMessage;
    name?: ErrorMessage;
};
export default function AddLocationContainer() {

    // hooks
    const dispatch = useDispatch();
    const intl = useIntl();
    const params = useParams();
    const { id, locationId } = params;
    const navigate = useNavigate();


    // states
    // eslint-disable-next-line
    const [answerType, setAnswerType] = useState<string>('');
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const riskAreasSelector = useSelector((state: RootState) => state.riskAreContainer);
    const locationSelector = useSelector((state: RootState) => state.locationContainer);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>()
    const [location, setLocation] = useState<Address>({ ...initialAddress });
    const [errObj, setErrObj] = useState<ErrorObject>({
        street: undefined,
        houseNumber: undefined,
        postalcode: undefined,
        city: undefined,
        name: undefined,
    });
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
    }, [id])
 /* istanbul ignore next */
    useEffect(() => {
        if (riskAreasSelector && riskAreasSelector.riskCategory && riskAreasSelector.riskCategory.length && id) {
            const riskCategoryObj = riskAreasSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === id)
            setSingleRiskCategory(riskCategoryObj);
            if (riskCategoryObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [riskAreasSelector])

    useEffect(() => {
        dispatch(locationContainerCreators.requestGetLocations());
    }, []);
 /* istanbul ignore next */
    useEffect(() => {
        if (locationSelector && locationSelector.locations && locationSelector.locations.length > 0 && locationId) {
            const currentLocation = locationSelector?.locations?.find((ele: Address) => ele?.locationId === locationId)
            setLocation({ ...currentLocation })
        }
    }, [locationSelector.locations, locationId])

    const handleAddressInput = (key: string, value: string) => {
        location[key] = value;
        setLocation({ ...location });
    };
 /* istanbul ignore next */
    const addAddress = () => {
        const streetErr = schema.street.validate(location.street).error;
        const houseNumberErr = schema.houseNumber.validate(
            location.houseNumber
        ).error;
        const postalcodeErr = schema.postalcode.validate(location.postalcode).error;
        const cityErr = schema.city.validate(location.city).error;
        const nameErr = schema.name.validate(location.name).error;

        if (streetErr || houseNumberErr || postalcodeErr || cityErr || nameErr) {
            setErrObj({
                street: streetErr?.message ? { message: intl.formatMessage({ id: streetErr?.message, defaultMessage: streetErr?.message }) } : undefined,
                houseNumber: houseNumberErr?.message ? { message: intl.formatMessage({ id: houseNumberErr?.message, defaultMessage: houseNumberErr?.message }) } : undefined,
                postalcode: postalcodeErr?.message ? { message: intl.formatMessage({ id: postalcodeErr?.message, defaultMessage: postalcodeErr?.message }) } : undefined,
                city: cityErr?.message ? { message: intl.formatMessage({ id: cityErr?.message, defaultMessage: cityErr?.message }) } : undefined,
                name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
            });
        } else {
            setErrObj({
                street: undefined,
                houseNumber: undefined,
                postalcode: undefined,
                city: undefined,
                name: undefined,
            });
            location.houseNumber = Number(location.houseNumber);
            if (locationId) {
                let addressData: Address = { ...location };
                delete addressData.locationId;
                delete addressData.created;
                delete addressData.modified;
                delete addressData.organizationId;
                delete addressData.country;
                delete addressData.availableConnectivityCount;
                delete addressData.availableHardwareCount;
                delete addressData.connectivityCount;
                delete addressData.hardwareCount;
                dispatch(locationContainerCreators.requestPutLocations(location.locationId, addressData));
            }
            else {
                dispatch(locationContainerCreators.requestPostLocations(location));
                setAnswerType('');
                setLocation({
                    type: "hosting",
                    houseNumberSuffix: "",
                    city: "",
                    houseNumber: null,
                    street: "",
                    postalcode: "",
                    name: "",
                });
            }
            navigate(`/locations/${id}?isCancel=0`)
        }
    };

    function onCancel() {
        navigate(`/locations/${id}?isCancel=0`)
    }

    return (
        <>
            <Container>
                <div className="flex-grow-1 flex flex-col justify-between">
                    <div>
                        <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0 sm:pb-6'>
                            <div className="flex items-center font-semibold text-xl">
                                <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                <div className="ml-3 font-semibold text-xl text-[#535353]">
                                    {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                </div>
                            </div>
                        </div>
                        <div className="border w-full"></div>
                        <Container>
                            <div className="flex flex-col sm:flex-col">
                                <p className="my-8 max-w-xl text-2xl text-black font-semibold">
                                    {intl.formatMessage({ id: location ? "organization_step2_text_Edit" : "organization_step2_text_Add", defaultMessage: location ? "organization_step2_text_Edit" : "organization_step2_text_Add" })}
                                </p>
                                <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                                    <div>
                                        <label className="inline-block text-base font-bold mb-1">
                                            {intl.formatMessage({ id: "name", defaultMessage: 'name' })}
                                        </label>
                                        <input
                                            disabled={!((role === "administrator") || (role === "editor"))}
                                            data-testid={`${testId}-name`}
                                            type="text"
                                            maxLength={100}
                                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.name?.message ? 'border-[red]' : ''}`}
                                            value={location?.name}
                                            onChange={(event) =>
                                                handleAddressInput("name", event.target.value)
                                            }
                                        />
                                        {errObj.name?.message ? (
                                            <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                                <span>{errObj.name?.message}</span>
                                            </div>
                                        ) : (
                                            <div className="mb-8"></div>
                                        )}
                                    </div>
                                    <div>
                                        <label className="inline-block text-base font-bold mb-1">
                                            {intl.formatMessage({ id: "street_address", defaultMessage: 'street_address' })}
                                        </label>
                                        <input
                                            disabled={!((role === "administrator") || (role === "editor"))}
                                            data-testid={`${testId}-street-add`}
                                            type="text"
                                            maxLength={100}
                                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.street?.message ? 'border-[red]' : ''}`}
                                            value={location.street}
                                            onChange={(event) =>
                                                handleAddressInput("street", event.target.value)
                                            }
                                        />
                                        {errObj.street?.message ? (
                                            <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                                <span>{errObj.street?.message}</span>
                                            </div>
                                        ) : (
                                            <div className="mb-8"></div>
                                        )}
                                    </div>
                                    <div className="flex sm:flex-wrap gap-8 sm:gap-0">
                                        <div className="flex flex-col w-full">
                                            <label className="inline-block text-base font-bold mb-1">
                                                {intl.formatMessage({ id: "number", defaultMessage: 'number' })}
                                            </label>
                                            <input
                                                disabled={!((role === "administrator") || (role === "editor"))}
                                                data-testid={`${testId}-house-number`}
                                                type="text"
                                                maxLength={15}
                                                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.houseNumber?.message ? 'border-[red]' : ''}`}
                                                value={location.houseNumber ?? ""}
                                                onChange={(event) =>
                                                    handleAddressInput("houseNumber", event.target.value)
                                                }
                                            />

                                            {errObj.houseNumber?.message ? (
                                                <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                                    <span>{errObj.houseNumber?.message}</span>
                                                </div>
                                            ) : (
                                                <div className="mb-8"></div>
                                            )}
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <label className="inline-block text-base font-bold mb-1 min-w-max">
                                                {intl.formatMessage({ id: "affix_(optional)", defaultMessage: 'affix_(optional)' })}
                                            </label>
                                            <input
                                                disabled={!((role === "administrator") || (role === "editor"))}
                                                data-testid={`${testId}-house-number-suffix`}
                                                type="text"
                                                maxLength={10}
                                                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full`}
                                                value={location.houseNumberSuffix}
                                                onChange={(event) =>
                                                    handleAddressInput(
                                                        "houseNumberSuffix",
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex sm:flex-wrap gap-8">
                                        <div className="flex flex-col max-w-[220px] w-full">
                                            <label className="inline-block text-base font-bold mb-1">
                                                {intl.formatMessage({ id: "zipcode", defaultMessage: 'zipcode' })}
                                            </label>
                                            <input
                                                disabled={!((role === "administrator") || (role === "editor"))}
                                                data-testid={`${testId}-zipcode`}
                                                type="text"
                                                maxLength={7}
                                                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.postalcode?.message ? 'border-[red]' : ''}`}
                                                value={location.postalcode}
                                                onChange={(event) =>
                                                    handleAddressInput("postalcode", event.target.value)
                                                }
                                            />
                                            {errObj.postalcode?.message ? (
                                                <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                                    <span>{errObj.postalcode?.message}</span>
                                                </div>
                                            ) : (
                                                <div className="mb-8"></div>
                                            )}
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <label className="inline-block text-base font-bold mb-1">
                                                {intl.formatMessage({ id: "city", defaultMessage: 'city' })}
                                            </label>
                                            <input
                                                disabled={!((role === "administrator") || (role === "editor"))}
                                                data-testid={`${testId}-city`}
                                                required
                                                type="text"
                                                maxLength={100}
                                                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.city?.message ? 'border-[red]' : ''}`}
                                                value={location.city}
                                                onChange={(event) =>
                                                    handleAddressInput("city", event.target.value)
                                                }
                                            />
                                            {errObj.city?.message ? (
                                                <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                                    <span>{errObj.city?.message}</span>
                                                </div>
                                            ) : (
                                                <div className="mb-8"></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-cancel-button`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => onCancel()}
                        />
                        {((role === "administrator") || (role === "editor")) &&
                            <div>
                                <StyledButton
                                    testId={`${testId}-save-button`}
                                    buttonClass={'PrimoryBtn'}
                                    width={"w-48 sm:w-full"}
                                    align={"justify-end"}
                                    text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
                                    color={"text-white"}
                                    iconType={"arrow"}
                                    backgroundColor={"bg-black"}
                                    onClick={() => addAddress()}
                                />
                            </div>}
                    </div>
                </div>
            </Container>
            <Modal show={isWromgOrgModal} onClose={() => ""}>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                </div>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-goto-dashboard-bia`}
                        onClick={() => handleWrongOrgModel()}
                        buttonClass={'PrimoryBtn'}
                        width={"w-[220px] sm:w-40"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                        color={"text-white mr-3 text-base"}
                        iconType={'home'}
                        backgroundColor={"bg-black"}
                    />
                </div>
            </Modal>
        </>
    );
};
