import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../../components/Container/Container";
import StyledButton from "../../../components/StyledButton";
import { listOptionsContainerCreators } from "../reducer";
import { Process } from "../step4";
import Dropdown from "../../../components/Dropdown";
import schema from "../../../validations/schema";
import { getDropdownValues } from "../index";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../../components/Modal/Modal";

const testId = `add-process`;

const initialProcess: Process = {
  nameWhenOther: "",
  processListId: "",
  responsible: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  BIA0h: null,
  BIA4h: 5,
  BIA24h: 5,
  BIA72h: 5,
  BIA168h: 5,
  BIA720h: 5
};


type ErrorMessage = {
  message: string;
}

type ErrorObject = {
  processListId?: ErrorMessage;
  nameWhenOther?: ErrorMessage;
};

type Option = {
  value: string;
  label: string;
};


const AddProcess = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const intl = useIntl();
  const [process, setProcess] = useState<Process>({ ...initialProcess });
  const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
  const { processes, listOptionsProcesses } = useSelector((state: RootState | any) => state.listOptions);
  const initialOption: Option = {
    label: intl.formatMessage({ id: 'select', defaultMessage: 'select' }),
    value: "",
  };

  const [filteredDropdownList, setFilteredDropdownList] = useState<RootState>([]);

  const [errObj, setErrObj] = useState<ErrorObject>({
    nameWhenOther: undefined,
    processListId: undefined,
  });
  const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

  function handleWrongOrgModel() {
    navigate("/dashboard")
  }

  useEffect(() => {
    dispatch(listOptionsContainerCreators.requestGetProcesses());
    dispatch(listOptionsContainerCreators.requestGetListOptionsProcesses());
  }, []);

  useEffect(() => {
    if (listOptionsSelector && listOptionsSelector.processes && listOptionsSelector.processes.length > 0 && id) {
      const currentprocess = listOptionsSelector.processes?.find((ele: Process) => ele?.processId === id)
      setProcess({ ...currentprocess })
      if (currentprocess === undefined) {
        setIsWromgOrgModal(true)
      }
    }
  }, [listOptionsSelector.processes, id])

  const handleProcessInput = (key: string, value: string) => {
    const newProcess = { ...process };
    const selectedProcess = filteredDropdownList.find((ele: any) => ele.listOptionId === value)
    if (selectedProcess && value !== '00000000-0000-0000-0000-000000000000') {
      newProcess[key] = value;
      newProcess['name'] = selectedProcess.name;
      newProcess['nameWhenOther'] = '';
    } else {
      if (value !== '00000000-0000-0000-0000-000000000000') {
        newProcess['nameWhenOther'] = value;
      } else {
        newProcess['nameWhenOther'] = '';
        newProcess[key] = value;
      }
    }
    setProcess(newProcess);
  };

  const getFilteredProcessList = (processes: Process[], listOptionsProcesses: RootState) => {
    const filteredProcesses = listOptionsProcesses.filter(function (lOProcess: any) {
      return !processes.find(function (process) {
        return lOProcess.listOptionId === process.processListId
      })
    })
    setFilteredDropdownList(filteredProcesses);
  }

  useEffect(() => {
    if (processes && processes.length > 0 && listOptionsProcesses && listOptionsProcesses.length > 0) {
      getFilteredProcessList(processes, listOptionsProcesses);
    } else {
      setFilteredDropdownList(listOptionsProcesses);
    }
  }, [processes, listOptionsProcesses])

  const getNonOtherValue = () => {
    if (process.processListId !== '00000000-0000-0000-0000-000000000000') {
      return [{ listOptionId: process?.processListId, name: process?.name }]
    } else {
      return []
    }
  }

  const processDropdownOptions = id ? getDropdownValues.call([...getNonOtherValue(), ...filteredDropdownList]) : getDropdownValues.call(filteredDropdownList);

  const addProcess = () => {
    const processErr = schema.process.validate(
      process.processListId
    ).error;
    const processNameOtherErr = schema.processNameOther.validate(
      process.nameWhenOther
    ).error;

    if (
      (processErr || process.processListId === "00000000-0000-0000-0000-000000000000") && (processNameOtherErr)
    ) {
      setErrObj({
        processListId: processErr?.message ? { message: intl.formatMessage({ id: processErr?.message, defaultMessage: processErr?.message }) } : undefined,
        nameWhenOther: processNameOtherErr?.message ? { message: intl.formatMessage({ id: processNameOtherErr?.message, defaultMessage: processNameOtherErr?.message }) } : undefined,
      });
    } else {
      setErrObj({
        processListId: undefined,
        nameWhenOther: undefined,
      });

      if (id) {
        let processData: Process = { ...process };
        delete processData.processId;
        delete processData.created;
        delete processData.modified;
        delete processData.organizationId;
        delete processData.name;
        delete processData.softwareCount
        dispatch(
          listOptionsContainerCreators.requestPutProcess(
            process.processId,
            processData,
          )
        );
      } else {
        let processData: Process = { ...process };
        delete processData.name;
        dispatch(listOptionsContainerCreators.requestAddProcess(processData));
      }
      navigate("/organisation/step4")
    }
  };

  function onCancel() {
    navigate(`/organisation/step4`)
  }

  return (
    <div className="flex-grow-1 flex flex-col justify-between">
      <Container>
        <div>
          <div className="m-auto w-full pb-7 align-middle items-center flex justify-between border-b sm:m-0 sm:pb-6">
            <div className="font-semibold text-xl">
              <div>{intl.formatMessage({ id: "organisation", defaultMessage: 'organisation' })}</div>
            </div>
          </div>
          <div className="my-10 sm:my-8">
            <div className="mt-8">
              <p className="max-w-xl text-2xl text-black font-semibold">
                {intl.formatMessage({
                  id: id
                    ? "organization_step4_text_Edit"
                    : "organization_step4_text",
                  defaultMessage: id
                    ? "organization_step4_text_Edit"
                    : "organization_step4_text"
                })}
              </p>
              <div className="mt-7">
                <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                  <div>
                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                      {intl.formatMessage({ id: "process", defaultMessage: 'process' })}
                    </label>
                    <div>
                      <Dropdown
                        options={[...[initialOption], ...processDropdownOptions, {
                          value: "00000000-0000-0000-0000-000000000000",
                          label: intl.formatMessage({ id: "other", defaultMessage: 'other' }),
                        }]}
                        value={process.processListId}
                        onChange={(value: string) =>
                          handleProcessInput("processListId", value)
                        }
                        error={errObj.processListId?.message}
                      />
                      {errObj.processListId?.message ?
                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                          <span>{errObj.processListId.message}</span>
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                  {process.processListId === "00000000-0000-0000-0000-000000000000"
                    ? <div>
                      <label className="inline-block text-base font-bold mb-1">
                        {intl.formatMessage({ id: "process_other", defaultMessage: 'process_other' })}
                      </label>
                      <div>
                        <input
                          data-testid={`${testId}-process-other-subviews`}
                          type="text"
                          maxLength={100}
                          className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.nameWhenOther?.message ? 'border-[red]' : ''}`}
                          value={process?.nameWhenOther ?? ""}
                          onChange={event => handleProcessInput("nameWhenOther", event.target.value)}
                        />
                        {errObj.nameWhenOther?.message ?
                          <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                            <span>{errObj.nameWhenOther.message}</span>
                          </div>
                          :
                          null
                        }
                      </div>
                    </div>
                    : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
          <StyledButton
            testId={`${testId}-cancel-btn-addprocess`}
            width={"w-48 sm:w-full"}
            align={"justify-start"}
            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
            color={"text-gray-700"}
            flexReverse={"flex-row-reverse"}
            backgroundColor={"bg-gray-300"}
            onClick={() => onCancel()}
          />
          <div>
            <StyledButton
              testId={`${testId}-save-btn-addprocess`}
              buttonClass={'PrimoryBtn'}
              width={"w-48 sm:w-full"}
              align={"justify-end"}
              text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
              color={"text-white"}
              iconType={"arrow"}
              backgroundColor={"bg-black"}
              onClick={() => addProcess()}
            />
          </div>
        </div>
      </Container>
      <Modal show={isWromgOrgModal} onClose={() => ""}>
        <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
          <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
        </div>
        <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
          <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
        </div>
        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
          <StyledButton
            testId={`${testId}-goto-dashboard`}
            onClick={() => handleWrongOrgModel()}
            buttonClass={'PrimoryBtn'}
            width={"w-[220px] sm:w-40"}
            align={"justify-end"}
            text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
            color={"text-white mr-3 text-base"}
            iconType={'home'}
            backgroundColor={"bg-black"}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddProcess;
