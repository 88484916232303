import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import StyledButton from '../StyledButton/index';
import ViewIcon from '../../assets/icons/view.svg';
import ViewOffIcon from '../../assets/icons/view-off.svg';
import NotificationToast from '../ToastComponent';
import { PrefLanguageList } from '../../containers/LoginContainer';
import cookies from '../../utils/coockies';
import { useSelector } from 'react-redux';

type ErrorMessage = {
    message: string
}

type ErrorObject = {
    email?: ErrorMessage;
    password?: ErrorMessage;
    firstName?: ErrorMessage;
    lastName?: ErrorMessage;
    phoneNumber?: ErrorMessage;
    name?: ErrorMessage;
    kvKNumber?: ErrorMessage;
    street?: ErrorMessage;
    houseNumber?: ErrorMessage;
    houseNumberSuffix?: ErrorMessage;
    postalcode?: ErrorMessage;
    city?: ErrorMessage;
    country?: ErrorMessage;
    repeatPassword?: ErrorMessage;
}

type Props = {
    inviteId?: string;
    activeTab: number;
    setActiveTab: React.Dispatch<React.SetStateAction<number>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    isRememberMe: boolean;
    setIsRememberMe: React.Dispatch<React.SetStateAction<boolean>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    isPasswordForgotten: boolean;
    setIsPasswordForgotten: React.Dispatch<React.SetStateAction<boolean>>;
    errObj: ErrorObject;
    handleLoginClick: () => void;
    onChangeCheckbox: () => void;
    isShowPass: boolean;
    setIsShowPass: React.Dispatch<React.SetStateAction<boolean>>;
    isShowRepeatPass: boolean;
    setIsShowRepeatPass: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean | null;
    errorMessage: string | null;
    createAccountErrorMessage?: string | null;
    isOrgExist: boolean;
    setIsOrgExist: React.Dispatch<React.SetStateAction<boolean>>;
    showLoginToast: boolean;
    setShowLoginToast: React.Dispatch<React.SetStateAction<boolean>>;
    showToast: boolean;
    setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
    firstName: string;
    setFirstName: React.Dispatch<React.SetStateAction<string>>;
    lastName: string;
    setLastName: React.Dispatch<React.SetStateAction<string>>;
    phoneNumber: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    prefLanguage: string;
    setPrefLanguage: React.Dispatch<React.SetStateAction<string>>;
    prefLanguageList: PrefLanguageList[];
    setPrefLanguageList: React.Dispatch<React.SetStateAction<PrefLanguageList[]>>;
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    kvKNumber: string;
    setKvKNumber: React.Dispatch<React.SetStateAction<string>>;
    street: string;
    setStreet: React.Dispatch<React.SetStateAction<string>>;
    houseNumber: string;
    setHouseNumber: React.Dispatch<React.SetStateAction<string>>;
    houseNumberSuffix: string;
    setHouseNumberSuffix: React.Dispatch<React.SetStateAction<string>>;
    postalcode: string;
    setPostalcode: React.Dispatch<React.SetStateAction<string>>;
    city: string;
    setCity: React.Dispatch<React.SetStateAction<string>>;
    country: string;
    setCountry: React.Dispatch<React.SetStateAction<string>>;
    repeatPassword: string;
    setRepeatPassword: React.Dispatch<React.SetStateAction<string>>;
    handleCreateAccountClick: () => void;
    isFocus: boolean;
    setIsFocus: React.Dispatch<React.SetStateAction<boolean>>;
    toastFromInvite?: boolean;
    handleKeyPressForLogin?: (e: any) => void;
    handleKeyPressForCreateAccount?: (e: any) => void;
    testId?: string;
}

export default function AuthLayout(props: Props) {

    const intl = useIntl();
    const inviteUserSelector = useSelector((state: RootState) => state.listOptions);

    const { inviteId, email, setEmail, isRememberMe, setIsRememberMe, password, setPassword, setIsPasswordForgotten, errObj, handleLoginClick, showLoginToast, setShowLoginToast, isShowPass, setIsShowPass, loading, createAccountErrorMessage, errorMessage, showToast, setShowToast,
        isFocus, onChangeCheckbox, toastFromInvite, handleKeyPressForLogin, testId = `auth-layout` } = props;

    const gotoForgetPassword = () => {
        setIsPasswordForgotten(true);
    }
       /* istanbul ignore next */
    useEffect(() => {
        if (cookies.get("email") && ((cookies.get("isRememberMe")))) {
            setEmail(cookies.get("email"));
            setIsRememberMe(cookies.get("isRememberMe") === "true");
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (isFocus) {
            document.getElementById("isOrgExist")?.focus();
        }
    }, [isFocus])
   /* istanbul ignore next */
    return (
        <>
            <div className='m-auto sm:w-full sm:p-4'>
                <div className='mt-16 mb-10 font-semibold text-[32px] flex items-start justify-start'>
                    {intl.formatMessage({ id: 'login', defaultMessage: 'login' })}
                </div>

                {inviteId &&
                    <div className='text-red-600 font-normal text-sm h-11 mb-[55px] flex items-start justify-start w-[400px]'>
                        {intl.formatMessage({ id: 'right_above_the_login_block', defaultMessage: 'right_above_the_login_block' })}
                    </div>
                }

                <div className='bg-white p-10 w-96 h-84 border rounded-lg sm:w-full sm:h-74 sm:p-5'>
                    <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                        <span className='font-family-arial'>{intl.formatMessage({ id: 'email_address', defaultMessage: 'email_address' })}</span>
                    </div>
                    <input data-testid={`${testId}-email-authlayout`} maxLength={254} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.email?.message ? 'border-[red]' : ''}`} type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                    {errObj.email?.message ?
                        <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                            <span>{errObj.email?.message}</span>
                        </div>
                        :
                        <div className='mb-8'></div>
                    }

                    <div className='font-bold text-base h-8 mr-6 flex items-start justify-start sm:text-sm'>
                        <span className='font-family-arial'>{intl.formatMessage({ id: 'password', defaultMessage: 'password' })}</span>
                    </div>

                    {isShowPass ?
                        <div className='flex items-end'>
                            <input data-testid={`${testId}-password-authlayout`} maxLength={60} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 relative ${errObj.password?.message ? 'border-[red]' : ''}`} type='password' value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => handleKeyPressForLogin && handleKeyPressForLogin(e)} />
                            <span className='z-0'>
                                <img data-testid={`${testId}-password-viewon-authlayout`} className='z-0 mb-3 -mx-7 max-w-[23px] cursor-pointer' src={ViewIcon} alt='' onClick={() => setIsShowPass(false)} />
                            </span>
                        </div>
                        :
                        <div className='flex items-end'>
                            <input data-testid={`${testId}-password`} maxLength={60} className={`bg-zinc-50 border rounded-lg pl-3 pr-8 mb-1 w-full h-11 relative ${errObj.password?.message ? 'border-[red]' : ''}`} type='text' value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => handleKeyPressForLogin && handleKeyPressForLogin(e)} />
                            <span className='z-0'>
                                <img data-testid={`${testId}-password-viewoff`} className='z-0 mb-3 -mx-7 max-w-[23px] cursor-pointer' src={ViewOffIcon} alt='' onClick={() => setIsShowPass(true)} />
                            </span>
                        </div>
                    }

                    {errObj.password?.message ?
                        <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                            <span>{errObj.password?.message}</span>
                        </div>
                        :
                        <div className='mb-4' />
                    }

                    {
                        errorMessage && <div className='text-red-600 font-normal text-sm flex items-start justify-start'>
                            <span>{errorMessage}</span>
                        </div>
                    }
                    <div className='flex items-center mb-6'>
                        <input id='remember-me' data-testid={`${testId}-remember-me-authlayout`} className={`max-w-[20px] h-[20px] border hover:border-[#C1C1C1]`} type="checkbox" name="" checked={isRememberMe} onChange={() => { onChangeCheckbox() }} />
                        <label htmlFor='remember-me' className='font-normal text-sm ml-2 '>{intl.formatMessage({ id: 'remember_me', defaultMessage: 'remember_me' })}</label>
                    </div>
                    <div className='text-cyan-500 font-semibold text-base flex items-start justify-start sm:text-sm'>
                        <span data-testid={`${testId}-goto-forgate-password-button`} onClick={() => gotoForgetPassword()} className='cursor-pointer'>{intl.formatMessage({ id: 'password_forgotten?', defaultMessage: 'password_forgotten?' })}</span>
                    </div>
                </div>

                <div className='items-center flex justify-end'>
                    <div>
                        <StyledButton testId={`${testId}-login-button`} customClass={'my-10'} buttonClass={'PrimoryBtn'} width={'w-48 sm:w-full'} align={'justify-end'} text={intl.formatMessage({ id: 'login', defaultMessage: 'login' })} color={'text-white'} iconType={'arrow'} backgroundColor={'bg-black'} onClick={() => handleLoginClick()} loading={loading} />
                    </div>
                </div>
                {showLoginToast && <NotificationToast testId={`${testId}-toast-1`} text={errorMessage ? errorMessage : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} iconType={errorMessage ? intl.formatMessage({ id: 'failure', defaultMessage: 'failure' }) : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} color={errorMessage ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showLoginToast} onClick={() => setShowLoginToast(false)} />}
                {toastFromInvite && <NotificationToast testId={`${testId}-toast-3`} text={inviteUserSelector.createAccountFromInviteError ? inviteUserSelector.createAccountFromInviteError : intl.formatMessage({ id: 'create_account_success_from_invite', defaultMessage: 'create_account_success_from_invite' })} iconType={inviteUserSelector.createAccountFromInviteError ? intl.formatMessage({ id: 'failure', defaultMessage: 'failure' }) : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} color={inviteUserSelector.createAccountFromInviteError ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showToast} onClick={() => setShowToast(false)} />}
                {(showToast && !toastFromInvite) && <NotificationToast testId={`${testId}-toast-2`} text={createAccountErrorMessage ? createAccountErrorMessage : intl.formatMessage({ id: 'create_account_success', defaultMessage: 'create_account_success' })} iconType={createAccountErrorMessage ? intl.formatMessage({ id: 'failure', defaultMessage: 'failure' }) : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} color={createAccountErrorMessage ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showToast} onClick={() => setShowToast(false)} />}
            </div>
        </>
    );
}
