import jwtDecode from 'jwt-decode';
import { createSelector } from 'reselect';
import {
  AppReducerInitialType,
  initialState
} from './AppReducer';
import { USER_COOKIE, EXPIRY_IN } from './enum';
import cookies from './utils/coockies';
const selectAppContainerDomain = (state: RootState) => state.appContainer || initialState

const selectUserInfo = createSelector(selectAppContainerDomain, (substate: AppReducerInitialType) => {
  return substate.userInfo
});

const selectRefreshToken = createSelector(selectAppContainerDomain, (substate: AppReducerInitialType) => {
  return substate.userInfo ? substate.userInfo.refreshToken : cookies.get(USER_COOKIE);
});

const selectExpiryIn = createSelector(selectAppContainerDomain, (substate: AppReducerInitialType) => {
  return substate.userInfo ? substate.userInfo.expiresIn : cookies.get(EXPIRY_IN);
});

const selectDecodedUserInfo = createSelector(selectAppContainerDomain, (substate: AppReducerInitialType): DecodedUserInfo | null => {
  const userInfo = substate.userInfo;
  if (userInfo && userInfo.accessToken) {
    return jwtDecode(userInfo.accessToken);
  }

  const userInfoData: any | undefined = cookies.get(USER_COOKIE);
  if (userInfoData) {
    return jwtDecode(userInfoData);
  }
  return null;
});
export {
  selectDecodedUserInfo,
  selectUserInfo,
  selectRefreshToken,
  selectExpiryIn
}
