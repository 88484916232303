import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PlansLayout from "../../components/PlansLayout";
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionContainerCreators } from "../SubscriptionContainer/subscriptionReducer";
import { InfoDetails } from "../BIAContainer/BIAEdit";
import { selectDecodedUserInfo } from "../../AppSelector";
import { ContentType } from "@onesixeight-nl/classes";
import { useLocation } from "react-router-dom";

const testId = `plan-container`;

type ControlRisksArray = {
    id: string;
    title: string;
}

type UsersCounterArray = {
    id: string;
    counter: string;
    userType: string;
}

export type PlanList = {
    planId: string;
    name: number;
    price: number;
    created: string;
    usersCounter?: UsersCounterArray[];
    btnText?: string;
    keyBanifits?: string[];
    subscriptionState?: number;
    yearlyFee?: number;
    year1Fee?: number;
    extraMonths?: number;
    vat?: number;
    firstPayment?: number;
}

export default function PlansContainer() {

    const dispatch = useDispatch();
    const intl = useIntl();
    const location = useLocation();
    const { upgradeSubscription, manualRenewal, subscriptionData }: RootState = location.state || {};
    const decodedUserInfo = useSelector(selectDecodedUserInfo);

    const subscriptionSelector = useSelector((state: RootState) => state.subscriptionContainer);


    const controlRisks: ControlRisksArray[] = [
        {
            id: '1',
            title: 'Increase the resilience of your organisation',
        },
        {
            id: '2',
            title: 'Become stronger in prosperity and adversity',
        },
        {
            id: '3',
            title: 'Be ahead of competition!',
        },
    ];

    const usersCounterBasic: UsersCounterArray[] = [
        {
            id: '1',
            counter: '1',
            userType: intl.formatMessage({ id: 'users_counter_basic_administrator' ,defaultMessage:'users_counter_basic_administrator'}),
        },
        {
            id: '2',
            counter: '1',
            userType: intl.formatMessage({ id: 'users_counter_basic_accountant' ,defaultMessage:'users_counter_basic_accountant'}),
        },
    ];

    const usersCounterPro: UsersCounterArray[] = [
        {
            id: '1',
            counter: '2',
            userType: intl.formatMessage({ id: 'users_counter_pro_administrators' ,defaultMessage:'users_counter_pro_administrators'}),
        },
        {
            id: '2',
            counter: '3',
            userType: intl.formatMessage({ id: 'users_counter_pro_editors' ,defaultMessage:'users_counter_pro_editors'}),
        },
        {
            id: '3',
            counter: '5',
            userType: intl.formatMessage({ id: 'users_counter_pro_read_only' ,defaultMessage:'users_counter_pro_read_only'}),
        },
        {
            id: '4',
            counter: '1',
            userType: intl.formatMessage({ id: 'users_counter_pro_accountant' ,defaultMessage:'users_counter_pro_accountant'}),
        },
    ];

    const usersCounterPremium: UsersCounterArray[] = [
        {
            id: '1',
            counter: '3',
            userType: intl.formatMessage({ id: 'users_counter_primium_administrators' ,defaultMessage:'users_counter_primium_administrators'}),
        },
        {
            id: '2',
            counter: '12',
            userType: intl.formatMessage({ id: 'users_counter_primium_editors' ,defaultMessage:'users_counter_primium_editors'}),
        },
        {
            id: '3',
            counter: '25',
            userType: intl.formatMessage({ id: 'users_counter_primium_read_only' ,defaultMessage:'users_counter_primium_read_only'}),
        },
        {
            id: '4',
            counter: '5',
            userType: intl.formatMessage({ id: 'users_counter_primium_accountants' ,defaultMessage:'users_counter_primium_accountants'}),
        },
    ];

    const basicKeyBenifits = [
        intl.formatMessage({ id: 'basic_key_benefits_1' ,defaultMessage:'basic_key_benefits_1'}),
        intl.formatMessage({ id: 'basic_key_benefits_2' ,defaultMessage:'basic_key_benefits_2'}),
        intl.formatMessage({ id: 'basic_key_benefits_3' ,defaultMessage:'basic_key_benefits_3'}),
        intl.formatMessage({ id: 'basic_key_benefits_4' ,defaultMessage:'basic_key_benefits_4'}),
        intl.formatMessage({ id: 'basic_key_benefits_5' ,defaultMessage:'basic_key_benefits_5'}),
        intl.formatMessage({ id: 'basic_key_benefits_6' ,defaultMessage:'basic_key_benefits_6'}),
    ]

    const proKeyBenifits = [
        intl.formatMessage({ id: 'all_the_advantages_of_Basic_plus' ,defaultMessage:'all_the_advantages_of_Basic_plus'}),
        intl.formatMessage({ id: 'pro_key_benefits_1' ,defaultMessage:'pro_key_benefits_1'}),
        intl.formatMessage({ id: 'pro_key_benefits_2' ,defaultMessage:'pro_key_benefits_2'}),
    ]

    const premiumKeyBenifits = [
        intl.formatMessage({ id: 'all_the_advantages_of_Pro_plus' ,defaultMessage:'all_the_advantages_of_Pro_plus'}),
        intl.formatMessage({ id: 'premium_key_benefits_1' ,defaultMessage:'premium_key_benefits_1'}),
    ]

    const [planList, setPlanList] = useState<PlanList[]>([]);
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
    const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);

    useEffect(() => {
        dispatch(subscriptionContainerCreators.requestGetSubscriptionPlans());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore next */
    const getUserCounter = (itemNumber: number) => {
        if (itemNumber === 1) {
            return usersCounterBasic
        } else if (itemNumber === 2) {
            return usersCounterPro
        } else if (itemNumber === 3) {
            return usersCounterPremium
        }
        return []
    }
/* istanbul ignore next */
    const getPlanButtonText = (itemNumber: number) => {
        if (itemNumber === 1) {
            return intl.formatMessage({ id: 'plan_btn_basic' ,defaultMessage:'plan_btn_basic'})
        } else if (itemNumber === 2) {
            return intl.formatMessage({ id: 'plan_btn_pro' ,defaultMessage:'plan_btn_pro'})
        } else if (itemNumber === 3) {
            return intl.formatMessage({ id: 'plan_btn_premium' ,defaultMessage:'plan_btn_premium'})
        }
        return ""
    }
/* istanbul ignore next */
    const getKeyBenifits = (itemNumber: number) => {
        if (itemNumber === 1) {
            return [...basicKeyBenifits]
        } else if (itemNumber === 2) {
            return [...proKeyBenifits]
        } else if (itemNumber === 3) {
            return [...premiumKeyBenifits]
        }
        return []
    }

    useEffect(() => {
        if (subscriptionSelector && subscriptionSelector.plans && subscriptionSelector.plans.length > 0) {
            const sortedPlans = [...subscriptionSelector.plans].sort((a: PlanList, b: PlanList) => Number(a.name) - Number(b.name))
            const addedTextPlans = [...sortedPlans].map((planItem) => {
                return ({
                    ...planItem,
                    btnText: getPlanButtonText(Number(planItem.name)),
                    usersCounter: getUserCounter(Number(planItem.name)),
                    keyBanifits: getKeyBenifits(Number(planItem.name))
                })
            })
            setPlanList(addedTextPlans);
        }
    }, [subscriptionSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (showMoreInfo && decodedUserInfo) {
            dispatch(subscriptionContainerCreators.requestGetPlansHelpText(ContentType.PLANS, decodedUserInfo));
        }
    }, [showMoreInfo, decodedUserInfo])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (subscriptionSelector && subscriptionSelector.planHelpText) {
            setInfoDetails(subscriptionSelector.planHelpText?.data);
        }
    }, [subscriptionSelector])

    function handleShowMoreInfo() {
        setShowMoreInfo(!showMoreInfo);
    }

    return (
        <>
            <PlansLayout testId={`${testId}-plan-layout`} controlRisks={controlRisks} planList={planList} showMoreInfo={showMoreInfo} infoDetails={infoDetails} handleShowMoreInfo={handleShowMoreInfo} upgradeSubscription={upgradeSubscription} manualRenewal={manualRenewal} subscriptionData={subscriptionData} />
        </>
    );
}