import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from "../../components/StyledButton";
import { addMeasureGroupContainerCreators, editMeasureGroupContainerCreators } from "./addMeasureGroupReducer";
import schema from "../../validations/schema";
import { measureGroupsContainerCreators } from "../MeasureGroupsContainer/measureGroupsReducer";
import { InfoDetails } from "../BIAContainer/BIAEdit";
import { selectDecodedUserInfo } from "../../AppSelector";
import Container from "../../components/Container/Container";
import GetIcons from "../../components/GetIcons/GetIcons";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import LoadingIcon from "../../assets/icons/loading-icon";
import { riskAreaContainerCreators } from "../ManageRiskAreasContainer/reducer";
import { AllRiskCategoryListData } from "../../components/CentralizePopup/CentralizePopup";
import Modal from "../../components/Modal/Modal";
import { MeasureList } from "../MeasureGroupsContainer";

const testId = `add-measuregroup-container`;

type ErrorMessage = {
    message: string
}

type ErrorObject = {
    name?: ErrorMessage;
}

export type MeasureGroupList = {
    measuregroupId: string;
    subgroup: number;
    measuregroupListId: string;
    nameWhenOther: string;
    selected: boolean;
    measureCount: number;
    name: string;
    availableMeasureCount: number;
}

export default function AddMeasureGroupContainer() {

    // hooks
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const addMgLocation = useLocation();
    const isCancel = new URLSearchParams(addMgLocation.search).get('isCancel');
    const intl = useIntl();
    const parameters = useParams();
    const { id, singleMeasureGroupId } = parameters;
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const userRole: string | undefined = decodedUserInfo?.payload?.role;

    const addMgSelector = useSelector((state: RootState) => state.addMeasureGroupContainer);
    const measureGroupsSelector = useSelector((state: RootState) => state.measureGroupsContainer);
    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);

    // states
    const [answerType, setAnswerType] = useState<string>('');
    const [selected, setSelected] = useState<boolean | null>();
    // const { editMeasureGroupObj }: RootState = addMgLocation.state || {};
    const [editMeasureGroupObj, setEditMeasureGroupObj] = useState<MeasureGroupList>()
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData>();
    const [currentMgIndex, setCurrentMgIndex] = useState<number>(measureGroupsSelector.data && measureGroupsSelector.data?.length && measureGroupsSelector.data?.findIndex((ele: MeasureGroupList) => !ele.measuregroupId));
    const [nameWhenOther, setnameWhenOther] = useState<string>('');
    const [measureGroupId, setMeasureGroupId] = useState<string>('');
    const [errObj, setErrObj] = useState<ErrorObject>({
        name: undefined
    });

    const [lastQuestionMg, setLastQuestionMg] = useState<boolean>(false);
    const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);
    const [learnMoreInfo, setLearnMoreInfo] = useState<boolean | undefined>(false);
    const findInfoText: any = infoDetails?.length > 0 ? infoDetails?.find((ele) => ele?.attributes?.InfoText) : []
    const InfoTextDetail = findInfoText ? findInfoText?.attributes?.InfoText : {}
    const textShow = InfoTextDetail ? InfoTextDetail?.substring(0, 100) : "";

    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
    const [measureGroupList, setMeasureGroupList] = useState<MeasureGroupList[]>([]);
    const intialMeasureGroup: MeasureGroupList = {
        measuregroupId: '',
        subgroup: 0,
        measuregroupListId: '',
        nameWhenOther: '',
        selected: false,
        measureCount: 0,
        name: '',
        availableMeasureCount: 0,
    };
    const [singleMeasureGroup, setSingleMeasureGroup] = useState<MeasureGroupList>();
    const [loading, setLoading] = useState(false);
    const [addMgLoading, setAddMgLoading] = useState(false);
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.error !== null) {
            setIsWromgOrgModal(true)
        }
    }, [riskAreaSelector.error])

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        const postMeasureGroupData = addMgSelector.postMeasureGroupData
        const postMeasureGroupIndex = measureGroupList.findIndex((ele: MeasureGroupList) => ele?.measuregroupListId === addMgSelector?.postMeasureGroupData?.measuregroupListId)
        if (postMeasureGroupIndex > -1 && postMeasureGroupData !== null) {
            measureGroupList[postMeasureGroupIndex] = addMgSelector.postMeasureGroupData;
            setMeasureGroupList(measureGroupList);
        }
    }, [addMgSelector.postMeasureGroupData])

    useEffect(() => {
        if (id) {
            dispatch(riskAreaContainerCreators.requestEditValue(id));
        }
    }, [id])

    useEffect(() => {
        if (riskAreaSelector?.editData) {
            setSingleRiskCategory(riskAreaSelector?.editData)
        }
    }, [riskAreaSelector?.editData])

    useEffect(() => {
        if (singleRiskCategory) {
            dispatch(measureGroupsContainerCreators.requestGetMeasureGroups(id, '', isCancel, '', [], null, singleRiskCategory));
        }
    }, [singleRiskCategory, id])

    useEffect(() => {
        if (measureGroupsSelector.data && singleMeasureGroupId) {
            const currentMeasureGroup = measureGroupsSelector.data?.find((ele: MeasureGroupList) => ele.measuregroupId === singleMeasureGroupId);
            setEditMeasureGroupObj(currentMeasureGroup)
            setSingleMeasureGroup(currentMeasureGroup)
        }
    }, [measureGroupsSelector.data, singleMeasureGroupId])

    useEffect(() => {
        if (measureGroupsSelector.data && measureGroupsSelector.data.length >= 0 && !editMeasureGroupObj) {
            if (currentMgIndex !== null && currentMgIndex > -1) {
                setCurrentMgIndex(currentMgIndex)
                setSingleMeasureGroup(measureGroupsSelector.data[currentMgIndex])
            }
            else if (currentMgIndex === null) {
                const index = measureGroupsSelector.data?.findIndex((ele: MeasureGroupList) => !ele?.measuregroupId)
                setCurrentMgIndex(index)
                setSingleMeasureGroup(measureGroupsSelector.data[index])
            }
            else if (currentMgIndex === -1) {
                setSingleMeasureGroup({
                    ...intialMeasureGroup,
                    measuregroupListId: '00000000-0000-0000-0000-000000000000'
                });
            }
        }
    }, [measureGroupsSelector.data, currentMgIndex])

    useEffect(() => {
        if (measureGroupsSelector.data && measureGroupsSelector.data.length >= 0) {
            const defaultMeasureGroup = measureGroupsSelector.data?.filter((ele: MeasureGroupList) => ele.measuregroupListId !== "00000000-0000-0000-0000-000000000000");
            setMeasureGroupList(defaultMeasureGroup);
        }
    }, [measureGroupsSelector.data])

    useEffect(() => {
        if (addMgSelector?.status !== null && addMgSelector?.status !== undefined) {
            setIsSaved(true);
            setTimeCounter(1);
        }
    }, [addMgSelector.status])
/* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved]);

    function SideDrawerOpen() {
        setLearnMoreInfo(!learnMoreInfo);
    }

    function SideDrawerClose() {
        setLearnMoreInfo(false);
    }

    useEffect(() => {
        if (editMeasureGroupObj) {
            setCurrentMgIndex(-1);
            setMeasureGroupId(editMeasureGroupObj?.measuregroupId);
            if (editMeasureGroupObj?.selected) {
                setSelected(editMeasureGroupObj?.selected)
                if (editMeasureGroupObj?.measuregroupListId === "00000000-0000-0000-0000-000000000000") {
                    setAnswerType("")
                    setnameWhenOther(editMeasureGroupObj?.nameWhenOther)
                } else {
                    setAnswerType("Relevant")
                }
            } else {
                setSelected(editMeasureGroupObj?.selected)
                if (editMeasureGroupObj?.measuregroupListId === "00000000-0000-0000-0000-000000000000") {
                    setAnswerType('')
                } else {
                    setAnswerType('NotRelevant');
                }
            }
        }
    }, [editMeasureGroupObj])

    useEffect(() => {
        if (measureGroupsSelector.singleMeasureGroup && !editMeasureGroupObj) {
            setMeasureGroupId(measureGroupsSelector.singleMeasureGroup?.measuregroupId);
            if (measureGroupsSelector.singleMeasureGroup?.selected) {
                setSelected(measureGroupsSelector.singleMeasureGroup?.selected)
                if (measureGroupsSelector.singleMeasureGroup?.measuregroupListId === "00000000-0000-0000-0000-000000000000") {
                    setAnswerType("")
                    setnameWhenOther(measureGroupsSelector.singleMeasureGroup?.nameWhenOther)
                } else {
                    setAnswerType("Relevant")
                }
            } else {
                setSelected(measureGroupsSelector.singleMeasureGroup?.selected)
                if (measureGroupsSelector.singleMeasureGroup?.measuregroupListId === "00000000-0000-0000-0000-000000000000") {
                    setAnswerType('')
                } else {
                    setAnswerType('NotRelevant');
                }
            }
        }
    }, [measureGroupsSelector.singleMeasureGroup])

    useEffect(() => {
        if (editMeasureGroupObj) {
            dispatch(measureGroupsContainerCreators.requestGetMeasureGroupsHelpText(editMeasureGroupObj?.measuregroupListId, decodedUserInfo));
        } else if (measureGroupList[currentMgIndex]?.measuregroupListId) {
            dispatch(measureGroupsContainerCreators.requestGetMeasureGroupsHelpText(measureGroupList[currentMgIndex]?.measuregroupListId, decodedUserInfo));
        } else {
            dispatch(measureGroupsContainerCreators.requestGetMeasureGroupsHelpText('00000000-0000-0000-0000-000000000000', decodedUserInfo));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMeasureGroupObj, currentMgIndex,measureGroupList])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (measureGroupsSelector && measureGroupsSelector.measureGroupsHelpText) {
            setInfoDetails(measureGroupsSelector.measureGroupsHelpText?.data);
        }
    }, [measureGroupsSelector])


    useEffect(() => {
        if (addMgSelector?.loadingAfterPost) {
            setAddMgLoading(true);
        } else {
            setAddMgLoading(false);
        }
    }, [addMgSelector?.loadingAfterPost])

    useEffect(() => {
        if (measureGroupsSelector?.loadingAfterPost) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [measureGroupsSelector?.loadingAfterPost])

    // functions

    function handleAnswerType(answer: string) {
        if (((userRole === "administrator") || (userRole === "editor"))) {
            if (answer === 'Relevant') {
                setAnswerType('Relevant');
                setSelected(true);
            } else if (answer === 'NotRelevant') {
                setAnswerType('NotRelevant');
                setSelected(false);
            } else if (answer === 'Yes') {
                setAnswerType('Yes');
                setSelected(true);
            } else if (answer === 'No') {
                setAnswerType('No');
                setSelected(false);
            }
        }
    }

    function handleCancelClick() {
        navigate(`/risk-area/${id}?isCancel=0`);
    }
/* istanbul ignore next */
    async function handlePreviousMeasureGroup() {
        if (currentMgIndex === -1) {
            if (id && measureGroupList[measureGroupList?.length - 1] && measureGroupList[measureGroupList?.length - 1]?.measuregroupId) {
                dispatch(measureGroupsContainerCreators.requestGetMeasureGroupById(id, measureGroupList[measureGroupList?.length - 1]?.measuregroupId))
            }
            setCurrentMgIndex(measureGroupList.length - 1);
        } else if (currentMgIndex > -1) {
            setCurrentMgIndex(currentMgIndex - 1);
            if (id && measureGroupList[currentMgIndex - 1] && measureGroupList[currentMgIndex - 1]?.measuregroupId) {
                dispatch(measureGroupsContainerCreators.requestGetMeasureGroupById(id, measureGroupList[currentMgIndex - 1]?.measuregroupId))
            }
        }
        //setTimeout(() => {
        //    dispatch(measureGroupsContainerCreators.requestGetMeasureGroups(id, '', isCancel, '', [], null, singleRiskCategory));
        //}, 1500)
    }
/* istanbul ignore next */
    function handleEditMeasureGroupInAddMode(isAddMode: boolean) {
        const nameErr = schema.nameWhenOther.validate(nameWhenOther).error;
        if (answerType === "") {
            if (nameErr) {
                setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
            } else {
                const payload = {
                    subgroup: 0,
                    measuregroupListId: editMeasureGroupObj?.measuregroupListId,
                    nameWhenOther: nameWhenOther,
                    selected: true,
                }
                dispatch(editMeasureGroupContainerCreators.requestPutMeasureGroups(id, payload, (isAddMode ? '' : navigate), measureGroupId));
                setErrObj({ name: undefined });
                if (isAddMode) {
                    setAnswerType('');
                    setnameWhenOther('');
                    if (currentMgIndex + 1 < measureGroupList.length) {
                        setCurrentMgIndex(currentMgIndex + 1);
                    } else {
                        setCurrentMgIndex(-1);
                    }
                }
            }
        } else {
            if (answerType === "Relevant" || answerType === "NotRelevant") {
                const payload = {
                    subgroup: 0,
                    measuregroupListId: editMeasureGroupObj?.measuregroupListId,
                    nameWhenOther: nameWhenOther,
                    selected: selected,
                }
                dispatch(editMeasureGroupContainerCreators.requestPutMeasureGroups(id, payload, (isAddMode ? '' : navigate), measureGroupId));
                if (isAddMode) {
                    setAnswerType('');
                    setnameWhenOther('');
                    if (currentMgIndex + 1 < measureGroupList.length) {
                        setCurrentMgIndex(currentMgIndex + 1);
                    } else {
                        setCurrentMgIndex(-1);
                    }
                }
            }
            else {
                if (answerType === "No") {
                    const payload = {
                        subgroup: 0,
                        measuregroupListId: editMeasureGroupObj?.measuregroupListId,
                        nameWhenOther: nameWhenOther,
                        selected: selected,
                    }
                    dispatch(editMeasureGroupContainerCreators.requestPutMeasureGroups(id, payload, (isAddMode ? '' : navigate), measureGroupId));
                    if (isAddMode) {
                        setAnswerType('');
                        setnameWhenOther('');
                        setCurrentMgIndex(currentMgIndex + 1);
                    }
                    navigate(`/risk-area/${id}`);
                }
            }
        }
    }

    /* istanbul ignore next */
    function handleNextClick() {
        if (measureGroupList[currentMgIndex]?.measuregroupId) {
            // EDIT MEASURE-GROUP IN ADD MODE
            if (id && measureGroupList[currentMgIndex + 1]?.measuregroupId) {
                dispatch(measureGroupsContainerCreators.requestGetMeasureGroupById(id, measureGroupList[currentMgIndex + 1]?.measuregroupId))
            }
            handleEditMeasureGroupInAddMode(true);
        }
        else if (editMeasureGroupObj) {
            const nameErr = schema.nameWhenOther.validate(nameWhenOther).error;
            if (answerType === "") {
                if (nameErr) {
                    setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
                } else {
                    const payload = {
                        subgroup: 0,
                        measuregroupListId: editMeasureGroupObj?.measuregroupListId,
                        nameWhenOther: nameWhenOther,
                        selected: true,
                    }
                    dispatch(editMeasureGroupContainerCreators.requestPutMeasureGroups(id, payload, navigate, measureGroupId));
                    setErrObj({ name: undefined });
                }
            } else {
                if (answerType === "Relevant" || answerType === "NotRelevant") {
                    const payload = {
                        subgroup: 0,
                        measuregroupListId: editMeasureGroupObj?.measuregroupListId,
                        nameWhenOther: nameWhenOther,
                        selected: selected,
                    }
                    dispatch(editMeasureGroupContainerCreators.requestPutMeasureGroups(id, payload, navigate, measureGroupId));
                }
                else {
                    if (answerType === "No") {
                        const payload = {
                            subgroup: 0,
                            measuregroupListId: editMeasureGroupObj?.measuregroupListId,
                            nameWhenOther: nameWhenOther,
                            selected: selected,
                        }
                        dispatch(editMeasureGroupContainerCreators.requestPutMeasureGroups(id, payload, navigate, measureGroupId));
                        navigate(`/risk-area/${id}`);
                    }
                }
            }
        }
        else {
            // Add measure groups
            if (answerType === "Relevant" || answerType === "NotRelevant") {
                const payload = {
                    subgroup: 0,
                    measuregroupListId: currentMgIndex > -1 ? measureGroupList[currentMgIndex]?.measuregroupListId : '00000000-0000-0000-0000-000000000000',
                    nameWhenOther: "",
                    selected: selected
                }
                dispatch(addMeasureGroupContainerCreators.requestPostAddMeasureGroup(id, payload));
                setLastQuestionMg(true);
                if (currentMgIndex + 1 < measureGroupList.length) {
                    setCurrentMgIndex(currentMgIndex + 1);
                    setAnswerType('');
                } else {
                    setCurrentMgIndex(-1);
                    setAnswerType('');
                }
            }
            else {
                if (lastQuestionMg) {
                    const nameErr = schema.nameWhenOther.validate(nameWhenOther).error;
                    if (answerType === "Yes") {
                        if (nameErr) {
                            setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
                        } else {
                            const payload = {
                                subgroup: 0,
                                measuregroupListId: currentMgIndex > -1 ? measureGroupList[currentMgIndex]?.measuregroupListId : '00000000-0000-0000-0000-000000000000',
                                nameWhenOther: nameWhenOther,
                                selected: selected
                            }
                            dispatch(addMeasureGroupContainerCreators.requestPostAddMeasureGroup(id, payload));
                            setCurrentMgIndex(-1)
                            setErrObj({ name: undefined });
                            setAnswerType('');
                            setnameWhenOther('');
                        }
                    }
                    else if (answerType === "No") {
                        navigate(`/risk-area/${id}`);
                    }
                } else {
                    const nameErr = schema.nameWhenOther.validate(nameWhenOther).error;
                    if (nameErr) {
                        setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
                    } else {
                        const payload = {
                            subgroup: 0,
                            measuregroupListId: currentMgIndex > -1 ? measureGroupList[currentMgIndex]?.measuregroupListId : '00000000-0000-0000-0000-000000000000',
                            nameWhenOther: nameWhenOther,
                            selected: true
                        }
                        dispatch(addMeasureGroupContainerCreators.requestPostAddMeasureGroup(id, payload, navigate));
                        setErrObj({ name: undefined });
                        setnameWhenOther('');
                    }
                }
            }
        }
        setLearnMoreInfo(false);
        //if (!editMeasureGroupObj && answerType !== "No") {
        //    setTimeout(() => {
        //        dispatch(measureGroupsContainerCreators.requestGetMeasureGroups(id, '', isCancel, '', [], null, singleRiskCategory));
        //    }, 1000)
        //}
    }

    function isDisableBtn() {
        if (measureGroupList[currentMgIndex]?.measuregroupListId) {
            if (answerType === '') {
                return true;
            } else {
                return false;
            }
        } else {
            if (lastQuestionMg) {
                if (answerType === '') {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    function handleBtnText() {
        if (editMeasureGroupObj) {
            return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
        } else {
            if (measureGroupList[currentMgIndex]?.measuregroupListId) {
                return intl.formatMessage({ id: 'next', defaultMessage: 'next' })
            } else {
                if (lastQuestionMg) {
                    return intl.formatMessage({ id: 'next', defaultMessage: 'next' })
                } else {
                    return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
                }
            }
        }
    }


    return (
        <>
            {/*{(measureGroupsSelector.loading || loading || addMgLoading) ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :*/}
            <div>
                <Container>
                    <div className="flex-grow-1 flex flex-col justify-between">
                        <div>
                            <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0'>
                                <div className="flex items-center font-semibold text-xl">
                                    <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                    <div className="ml-3">
                                        <div data-testid={`${testId}-add-measuregroup`} className="font-semibold text-xl text-[#535353]">
                                            {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                        </div>
                                    </div>
                                </div>
                                {isSaved &&
                                    <div className="font-normal text-xs text-[#959595]">
                                        <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                    </div>
                                }
                            </div>
                            <div className="border w-full"></div>
                            <div>
                                <div className='m-auto w-full py-7 align-middle items-center flex justify-between'>
                                    {editMeasureGroupObj ?
                                        <div data-testid={`${testId}-edit-mg`} className="font-semibold text-[32px] sm:text-2xl">
                                            <div>{editMeasureGroupObj.measuregroupListId === "00000000-0000-0000-0000-000000000000"
                                                ? intl.formatMessage({ id: "edit_measure_group", defaultMessage: 'edit_measure_group' })
                                                : intl.formatMessage({ id: "measure_group:", defaultMessage: 'measure_group:' }) + editMeasureGroupObj?.name}</div>
                                        </div>
                                        :
                                        <>
                                            {measureGroupList[currentMgIndex]?.measuregroupListId ?
                                                <div data-testid={`${testId}-mg`} className="font-semibold text-[32px] sm:text-2xl">
                                                    <div>{intl.formatMessage({ id: "measure_group:", defaultMessage: 'measure_group:' }) + measureGroupList[currentMgIndex]?.name}</div>
                                                </div>
                                                :
                                                <>
                                                    {lastQuestionMg ?
                                                        <div data-testid={`${testId}-can-u-name-another`} className="font-semibold text-[32px] sm:text-2xl">
                                                            <div>{intl.formatMessage({ id: "can_you_name_another_relevant_measure_group?", defaultMessage: 'can_you_name_another_relevant_measure_group?' })}</div>
                                                        </div>
                                                        :
                                                        <div data-testid={`${testId}-add-mg`} className="font-semibold text-[32px] sm:text-2xl">
                                                            <div>{intl.formatMessage({ id: 'add_measure_group', defaultMessage: 'add_measure_group' })}</div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                                <div>
                                    {infoDetails && infoDetails.length !== 0 &&
                                        <div className=" w-[540px] leading-4 mb-10 text-[#535353] sm:w-full">
                                            <span className="font-normal text-lg">{textShow}</span>
                                            {textShow.length > 90 &&
                                                <>
                                                    <span>{"... "}</span>
                                                    <span className={"font-semibold text-[#3BA8FC] text-lg cursor-pointer w-max"} onClick={() => SideDrawerOpen()}>{intl.formatMessage({ id: 'learn_more', defaultMessage: 'learn_more' })}</span>
                                                </>
                                            }
                                        </div>}

                                    <div className={`w-full max-w-[420px] top-0 right-0  h-full fixed z-[1055] border-r sm:overflow-y-auto sm:w-full sm:max-w-full ${!learnMoreInfo ? 'translate-x-[100%] ease-in-out duration-300' : 'translate-x-[0%] ease-in-out duration-300'}`}>
                                        <SideDrawer testId={`${testId}-side-drawer`} drawerInfo={infoDetails} SideDrawerClose={SideDrawerClose} />
                                    </div>
                                </div>
                                <div className="flex sm:flex-col">
                                    {editMeasureGroupObj ?
                                        <>
                                            {editMeasureGroupObj.measuregroupListId === "00000000-0000-0000-0000-000000000000" ?
                                                <div data-testid={`${testId}-name-of-mg`} className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mb-10 mr-10 p-10 sm:p-5">
                                                    <label className="inline-block text-base font-bold mb-1">
                                                        {intl.formatMessage({ id: "name_of_the_measure_groups", defaultMessage: 'name_of_the_measure_groups' })}
                                                    </label>
                                                    <input
                                                        data-testid={`${testId}-mg-input`}
                                                        type="text"
                                                        maxLength={100}
                                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                                                        value={nameWhenOther}
                                                        onChange={(e) => setnameWhenOther(e.target.value)}
                                                    />
                                                    {errObj.name?.message ?
                                                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                            <span>{errObj.name.message}</span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                <div className="mr-6 sm:w-full sm:mr-0">
                                                    <div data-testid={`${testId}-relevant`} onClick={() => handleAnswerType('Relevant')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Relevant' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "relevent_define_measure", defaultMessage: 'relevent_define_measure' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-not-relevant`} onClick={() => handleAnswerType('NotRelevant')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'NotRelevant' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "not_relevant", defaultMessage: 'not_relevant' })}</div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        :
                                        <>
                                            {measureGroupList[currentMgIndex]?.measuregroupListId ?
                                                <div className="mr-6 sm:w-full sm:mr-0">
                                                    <div data-testid={`${testId}-relevent-add-mg`} onClick={() => handleAnswerType('Relevant')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Relevant' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "relevent_define_measure", defaultMessage: 'relevent_define_measure' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-not-relevent-add-mg`} onClick={() => handleAnswerType('NotRelevant')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'NotRelevant' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "not_relevant", defaultMessage: 'not_relevant' })}</div>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {lastQuestionMg ?
                                                        <div className="mr-6 sm:w-full sm:mr-0">
                                                            <div data-testid={`${testId}-answer-type-no`} onClick={() => handleAnswerType('No')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "no", defaultMessage: 'no' })}</div>
                                                            </div>
                                                            <div data-testid={`${testId}-answer-type-yes`} onClick={() => handleAnswerType('Yes')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: "yes", defaultMessage: 'yes' })}</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mb-10 mr-10 p-10 sm:p-5">
                                                            <label className="inline-block text-base font-bold mb-1">
                                                                {intl.formatMessage({ id: "name_of_the_measure_groups", defaultMessage: 'name_of_the_measure_groups' })}
                                                            </label>
                                                            <input
                                                                data-testid={`${testId}-name-of-the-mg`}
                                                                type="text"
                                                                maxLength={100}
                                                                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                                                                value={nameWhenOther}
                                                                onChange={(e) => setnameWhenOther(e.target.value)}
                                                            />
                                                            {errObj.name?.message ?
                                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                                    <span>{errObj.name.message}</span>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                    {answerType === 'Yes' ?
                                        <div className="w-full max-w-[414px] border rounded-lg bg-white mb-10 p-10 sm:p-5">
                                            <div>
                                                <div>
                                                    <label className="inline-block text-base font-bold mb-1">
                                                        {intl.formatMessage({ id: "name_of_the_measure_groups", defaultMessage: 'name_of_the_measure_groups' })}
                                                    </label>
                                                    <input
                                                        data-testid={`${testId}-name-of-the-mg-another`}
                                                        type="text"
                                                        maxLength={100}
                                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                                                        value={nameWhenOther}
                                                        onChange={(e) => setnameWhenOther(e.target.value)}
                                                    />

                                                </div>
                                            </div>
                                            {errObj.name?.message ?
                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                    <span>{errObj.name.message}</span>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='w-full bottom-0 align-middle items-center flex flex-col justify-between'>
                            {(measureGroupList[currentMgIndex]?.measuregroupListId || (lastQuestionMg)) &&
                                <div className='w-full bottom-0 align-middle items-center flex justify-between'>
                                    {/*<div>*/}
                                    <StyledButton
                                        testId={`${testId}-previous-btn`}
                                        onClick={() => handlePreviousMeasureGroup()}
                                        disabled={(currentMgIndex === 0 || editMeasureGroupObj) ? true : false}
                                        buttonClass={'sm:px-2'}
                                        flexReverse={'flex-row-reverse'}
                                        width={'w-48 sm:w-max'}
                                        align={'justify-start'}
                                        text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                        color={'text-[#535353]'}
                                        iconType={'arrowleft'}
                                        backgroundColor={'bg-[#EDEDED]'}
                                    />
                                    {/*</div>*/}
                                    {((userRole === "administrator") || (userRole === "editor")) ?
                                        <StyledButton testId={`${testId}-next-btn`} onClick={() => handleNextClick()} buttonClass={'sm:px-2 PrimoryBtn'} disabled={isDisableBtn()} width={'w-48 sm:w-max'} align={'justify-end'} text={handleBtnText()} color={isDisableBtn() ? 'text-[#535353]' : 'text-white'} iconType={isDisableBtn() ? 'blackarrow' : 'arrow'} backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />
                                        :
                                        <></>
                                    }
                                </div>
                            }

                            <div className="w-full flex items-center justify-between mt-4">
                                {(editMeasureGroupObj || (singleMeasureGroup?.measuregroupListId === '00000000-0000-0000-0000-000000000000' && !lastQuestionMg)) ?
                                    <StyledButton testId={`${testId}-cancel-btn`} onClick={() => handleCancelClick()} buttonClass={'sm:px-2'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })} color={'text-[#535353]'} backgroundColor={'bg-[#EDEDED]'} />
                                    :
                                    <div className="w-48 h-[52px] flex items-center justify-center">
                                        <button data-testid={`${testId}-cancel-btn-2`} onClick={() => handleCancelClick()} className="flex items-center justify-center">
                                            <span className="text-[#535353] font-family-arial font-semibold">{intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}</span>
                                        </button>
                                    </div>
                                }
                                {(editMeasureGroupObj || (singleMeasureGroup?.measuregroupListId === '00000000-0000-0000-0000-000000000000' && !lastQuestionMg)) && <StyledButton testId={`${testId}-next-btn-2`} onClick={() => handleNextClick()} buttonClass={'sm:px-2 PrimoryBtn'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })} disabled={isDisableBtn()} color={isDisableBtn() ? 'text-[#535353]' : 'text-white'} backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/*}*/}
            <Modal show={isWromgOrgModal} onClose={() => ""}>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                </div>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-goto-dashboard-btn`}
                        onClick={() => handleWrongOrgModel()}
                        buttonClass={'PrimoryBtn'}
                        width={"w-[220px] sm:w-40"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                        color={"text-white mr-3 text-base"}
                        iconType={'home'}
                        backgroundColor={"bg-black"}
                    />
                </div>
            </Modal>
        </>
    );
}