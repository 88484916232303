import {
    AxiosError,
    AxiosResponse
} from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { postAddMeasureGroupService, putMeasureGroupsService } from '../../services/MeasureGroupsService';
import { addMeasureGroupContainerTypes, addMeasureGroupContainerCreators } from './addMeasureGroupReducer';
import { editMeasureGroupContainerTypes, editMeasureGroupContainerCreators } from './addMeasureGroupReducer';
/* istanbul ignore next */
type PostAddMeasureGroupDataType = {
    id: string;
    data: {
        subgroup: number,
        measuregroupListId: string,
        nameWhenOther: string,
        selected: boolean
    };
    navigate?: (path: string) => void;
}

type PutAddMeasureGroupDataType = {
    id: string;
    data: {
        subgroup: number,
        measuregroupListId: string,
        nameWhenOther: string,
        selected: boolean
    };
    navigate?: (path: string) => void;
    mgId: string;
}

const {
    REQUEST_POST_ADD_MEASURE_GROUP,
} = addMeasureGroupContainerTypes;

const {
    REQUEST_PUT_MEASURE_GROUPS,
} = editMeasureGroupContainerTypes;

/* istanbul ignore next */
export function* postAddMeasureGroupData(data: PostAddMeasureGroupDataType) {
    try {
        const response: AxiosResponse = yield call(postAddMeasureGroupService, data.id, data.data);
        if (response && data.navigate) {
            data.navigate(`/risk-area/${data.id}?isCancel=0`);
        }
        yield put(addMeasureGroupContainerCreators.successPostAddMeasureGroup(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(addMeasureGroupContainerCreators.failurePostAddMeasureGroup(e.response?.data.errors[0].message));
        } else {
            yield put(addMeasureGroupContainerCreators.failurePostAddMeasureGroup("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* putMeasureGroupsData(data: PutAddMeasureGroupDataType) {
    try {
        const response: AxiosResponse = yield call(putMeasureGroupsService, data.id, data.data, data.mgId);
        if (response && data.navigate) {
            data.navigate(`/risk-area/${data.id}?isCancel=0`);
        }
        yield put(editMeasureGroupContainerCreators.successPutMeasureGroups(response.data, response.status,response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(editMeasureGroupContainerCreators.failurePutMeasureGroups(e.response?.data.errors[0].message));
        } else {
            yield put(editMeasureGroupContainerCreators.failurePutMeasureGroups("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export default function* addMeasureGroupContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_POST_ADD_MEASURE_GROUP, postAddMeasureGroupData);

    // @ts-ignore
    yield takeLatest(REQUEST_PUT_MEASURE_GROUPS, putMeasureGroupsData);
}
