import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from '../../components/StyledButton';
import EditIcon from '../../assets/icons/edit-icon.svg';
import { clearLocationStatusCreators, locationContainerCreators } from '../LocationsContainer/locationReducer';
import LoadingIcon from "../../assets/icons/loading-icon";
import { selectDecodedUserInfo } from '../../AppSelector';
import Modal from '../../components/Modal/Modal';
import VideoJS from '../../components/VideoJS/video';
import videojs from 'video.js';
import Container from '../../components/Container/Container';
import GetIcons from '../../components/GetIcons/GetIcons';
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";
import InfoIcon from "../../assets/icons/info-i-icon.svg";
import CloseIcon from "../../assets/icons/close-icon.svg";
import Tooltip from 'rc-tooltip';

const testId = `software-first-screen`;

export type Address = {
    [key: string]: any;
    type?: string;
    locationId?: string;
    street: string;
    houseNumber: number | null;
    houseNumberSuffix: string;
    postalcode: string;
    city: string;
    name?: string;
}

export type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
}

export type BiaOverview = {
    processId: string;
    processListId: string;
    organizationId: string;
    nameWhenOther: string;
    name: string;
    responsible: string;
    BIA0h: number;
    BIA4h: number;
    BIA24h: number;
    BIA72h: number;
    BIA168h: number;
    BIA720h: number;
    created: string;
    modified: string;
    availableSoftwareCount?: number;
}

export type SoftwareList = {
    softwareId: string;
    name: string;
    inSupport: number;
    patches: number;
    storage: string;
    backup: string;
    created: Date | string;
    modified: Date | string;
    storageName: string;
    backupName: string;
}

type SoftwarePopupVideoAttributes = {
    name: string;
    alternativeText: string;
    caption: string;
    width: null;
    height: null;
    formats: null;
    hash: string;
    ext: string;
    mime: string;
    size: string;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: null;
    createdAt: Date;
    updatedAt: Date;
}

type SoftwarePopupVideo = {
    data: {
        id: number;
        attributes: SoftwarePopupVideoAttributes;
    }
}

type SoftwarePopupDetails = {
    Intro: string;
    Header: string;
    Body: string;
    Button: string;
    Button2: string;
    Link: string;
    Link2: string;
    Video: SoftwarePopupVideo;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
}

export default function SoftwareFirstScreen() {

    // hooks
    const intl = useIntl();
    const params = useParams();
    const { pathname, search, state } = useLocation();
    const { id } = params;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSoftware = pathname.includes(`/software/${id}`)

    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const locationSelector = useSelector((state: RootState) => state.locationContainer);
    const { softwares } = useSelector((state: RootState) => state.locationContainer);
    const isCancel = new URLSearchParams(search).get('isCancel');
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const { showPopup, from }: RootState = state || {}

    // states
    const [biaOverview, setBiaOverview] = useState<BiaOverview[]>([]);
    const [softwareList, setSoftwareList] = useState<SoftwareList[]>([]);
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>();
    const [singleProcess, setSingleProcess] = useState<BiaOverview>();
    const [softwareCount, setSoftwareCount] = useState();
    const [isDeleteSoftwareModal, setIsDeleteSoftwareModal] = useState<boolean>(false);
    const [selectedSoftware, setSelectedSoftware] = useState<SoftwareList>();
    const [isSoftwareModal, setIsSoftwareModal] = useState<boolean>(false);
    const [softwarePopupDetails, setSoftwarePopupDetails] = useState<SoftwarePopupDetails>();
    const userRoles = ((role === "administrator") || (role === "editor"))

    const playerRef = React.useRef(null);
    const isHttpsLink = softwarePopupDetails?.Video?.data?.attributes?.url.startsWith('https://');
    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: isHttpsLink ? softwarePopupDetails?.Video?.data?.attributes?.url : "https://" + softwarePopupDetails?.Video?.data?.attributes?.url,
            type: 'video/mp4'
        }]
    };

    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1)
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    useEffect(() => {
        if (locationSelector?.status !== null && locationSelector?.status !== undefined && locationSelector?.method !== "delete" && locationSelector?.method !== null) {
            setIsRemoved(false)
            setIsSaved(true);
            setTimeCounter(1);
        } else if (locationSelector?.method !== null && locationSelector?.method !== undefined && locationSelector?.method === "delete" && (locationSelector?.method !== "post" || locationSelector?.method !== "put")) {
            setIsSaved(false)
            setIsRemoved(true);
            setTimeCounter(1);
            dispatch(clearLocationStatusCreators.clearLocationStatus());
        }
    }, [locationSelector])
/* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    dispatch(clearLocationStatusCreators.clearLocationStatus());
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.riskCategory && riskAreaSelector.riskCategory.length && id) {
            const riskCategoryObj = riskAreaSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === id)
            setSingleRiskCategory(riskCategoryObj);
            if (riskCategoryObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskAreaSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (singleRiskCategory) {
            const isSkip = new URLSearchParams(search).get('skip') || -1;
            dispatch(locationContainerCreators.requestGetSoftwareProcesses(decodedUserInfo, dispatch, singleRiskCategory, isCancel, navigate, intl, showPopup, isSkip));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleRiskCategory]);/* eslint-disable react-hooks/exhaustive-deps */

    function overviewBIA() {
        if (locationSelector && locationSelector.processes && locationSelector.processes.length > 0) {

            setBiaOverview(locationSelector.processes);
            const isSkip = new URLSearchParams(search).get('skip') || -1;
            const availableSoftwareCountArray = locationSelector.processes.filter((ele: any) => ele.softwareCount === 0)
            const isEveryOneEmpty = availableSoftwareCountArray.length === locationSelector.processes.length;

            const findIndexOfCurrent = locationSelector.processes.findIndex((ele: any) => ele.processId === isSkip);
            const nextIndex = locationSelector.processes.findIndex((ele: any, index: number) => index > findIndexOfCurrent && ele.softwareCount === 0);
            if (isEveryOneEmpty && isSkip === -1) {
                setSoftwareCount(locationSelector.processes[0])
            } else if (nextIndex !== -1 && findIndexOfCurrent !== -1) {
                setSoftwareCount(locationSelector.processes[nextIndex])
            }
        }
    }

    useEffect(() => {
        overviewBIA()
        if (locationSelector && locationSelector?.softwareContent) {
            if (locationSelector?.softwareContent?.data && locationSelector?.softwareContent?.data[0] && isCancel !== '0') {
                setSoftwarePopupDetails(locationSelector?.softwareContent?.data[0]?.attributes);
                setIsSoftwareModal(true);
            } else {
                setIsSoftwareModal(false);
                if (softwareCount && isCancel !== '0') {
                    const isSkip = new URLSearchParams(search).get('skip') || -1;
                    dispatch(locationContainerCreators.requestGetSoftwares(softwareCount, singleRiskCategory, isCancel, navigate, intl, isSkip));
                }
            }
        } else {
            setIsSoftwareModal(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    // get software
    useEffect(() => {
        if (singleProcess) {
            dispatch(locationContainerCreators.requestGetSoftwares(singleProcess, singleRiskCategory, isCancel, '', intl));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleProcess]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (softwares && softwares.length > 0) {
            const filteredSoftwares = softwares.filter((ele: any) => ele.softwareId)
            setSoftwareList(filteredSoftwares);
        } else {
            setSoftwareList([]);
        }
    }, [softwares])

    // functions

    function gotoEditSoftware(softwareObj: any) {
        navigate(`/software/${id}/editSoftware/${singleProcess?.processId}/${softwareObj.softwareId}`, { state: { singleProcess: singleProcess, editSoftwareObj: softwareObj } });
    }

    function gotoAddSoftware() {
        if (singleProcess) {
            navigate(`/software/${id}/addSoftware/${singleProcess?.processId}`, { state: { singleProcess: singleProcess, sLastQuestion: false } });
        }
    }

    function handleSingleProcess(processId: string) {
        if (processId === singleProcess?.processId) {
            setSingleProcess(undefined);
        } else {
            const newProcessList: BiaOverview[] = [...biaOverview];
            const selectedProcess = newProcessList.find((ele: BiaOverview) => ele.processId === processId);
            if (selectedProcess) {
                setSingleProcess(selectedProcess);
            }
        }
    }

    function handleSoftwareModalClose() {
        setIsSoftwareModal(false);
    }
/* istanbul ignore next */
    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });
        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    const getSOValue = (value: any) => {
        if (value === 1) {
            return intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })
        } else if (value === 2) {
            return intl.formatMessage({ id: 'no', defaultMessage: 'no' })
        } else if (value === 3) {
            return intl.formatMessage({ id: 'don’t_know', defaultMessage: 'don’t_know' })
        } else if (value === 4) {
            return intl.formatMessage({ id: 'NA', defaultMessage: 'NA' })
        }
    }

    function handleAddSoftwareYes() {
        if (softwareCount && isCancel !== '0') {
            dispatch(locationContainerCreators.requestGetSoftwares(softwareCount, singleRiskCategory, isCancel, navigate, intl));
        }
        setIsSoftwareModal(false);
    }

    const goToPrevious = () => {
        navigate(`/connectivity/${id}?isCancel=6`)
    }

    const goToNext = () => {
        navigate(`/events/${id}?isCancel=8`)
    }

    function handleDeleteSoftwareModalOpen(item: SoftwareList) {
        setSelectedSoftware(item);
        setIsDeleteSoftwareModal(true);
    }

    function handleDeleteSoftwareModalClose() {
        setIsDeleteSoftwareModal(false);
    }

    const deleteSoftwareItem = () => {
        dispatch(locationContainerCreators.requestDeleteSoftware(singleProcess?.processId, selectedSoftware?.softwareId))
        setSingleProcess(undefined);
        setSelectedSoftware(undefined);
        setIsDeleteSoftwareModal(false);
    }

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }
    
    return (
        <>
            {locationSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div>
                    <Container>
                        <div className="flex-grow-1 flex flex-col justify-between">
                            <div>
                                <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0 sm:pb-6'>
                                    <div className="flex items-center font-semibold text-xl">
                                        <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                        <div className="ml-3 font-semibold text-xl text-[#535353]">
                                            {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                        </div>
                                    </div>
                                    {isSaved &&
                                        <div className="font-normal text-xs text-[#959595]">
                                            <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                        </div>
                                    }
                                    {isRemoved &&
                                        <div className="font-normal text-xs text-[#959595]">
                                            <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                                        </div>
                                    }
                                </div>

                                {/* Navbar */}
                                <div className="mb-10 w-full block" id="navbar-default">
                                    <ul className="flex sm:flex-wrap flex-row mt-[30px] text-xl font-semibold ">
                                        {singleRiskCategory?.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400' &&
                                            <>
                                                <li data-testid={`${testId}-navigate-location`} onClick={() => navigate(`/locations/${id}?isCancel=4`)}>
                                                    <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`}>{intl.formatMessage({ id: 'locations', defaultMessage: 'locations' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-hardware`} onClick={() => navigate(`/hardware/${id}?isCancel=5`)}>
                                                    <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`}>{intl.formatMessage({ id: 'hardware', defaultMessage: 'hardware' })}</span>
                                                </li>
                                                <li data-testid={`${testId}navigate-connectivity`} onClick={() => navigate(`/connectivity/${id}?isCancel=6`)}>
                                                    <span className="min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer">{intl.formatMessage({ id: 'connectivity', defaultMessage: 'connectivity' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-software`} onClick={() => navigate(`/software/${id}?isCancel=7`)}>
                                                    <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer ${isSoftware && '!text-[#121212]'}`}>{intl.formatMessage({ id: 'software', defaultMessage: 'software' })}</span>
                                                </li>
                                            </>
                                        }

                                        <li data-testid={`${testId}-navigate-events`} onClick={() => navigate(`/events/${id}?isCancel=8`)}>
                                            <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`}>{intl.formatMessage({ id: 'events', defaultMessage: 'events' })}</span>
                                        </li>
                                        <li data-testid={`${testId}-navigate-risk-areas`} onClick={() => navigate(`/risk-area/${id}?isCancel=9`)}>
                                            <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`} aria-current="page">{intl.formatMessage({ id: 'measure_groups', defaultMessage: 'measure_groups' })}</span>
                                        </li>

                                    </ul>
                                </div>

                                <div className="border w-full"></div>

                                <div className="my-10 sm:my-8 max-w-[952px]">
                                    <div className='mt-8'>
                                        <div className='mt-10 mb-[32px]'>
                                            <div className="font-semibold text-[32px] mb-[16px] sm:text-2xl">{intl.formatMessage({ id: 'overview_Software', defaultMessage: 'overview_Software' })}</div>
                                        </div>
                                        <div>
                                            {biaOverview?.length > 0 &&
                                                <div>
                                                    {biaOverview?.map((process, idx) => {
                                                        const showOverview = (singleProcess && process.processId === singleProcess.processId)
                                                        return (
                                                            <div data-testid={`${testId}-bia-overview-${idx}`} key={process?.processId} className='w-full bg-white border rounded-lg mb-4 p-6 sm:overflow-x-auto'>
                                                                <div className='flex items-center justify-between'>
                                                                    <div data-testid={`${testId}-handle-single-process`} onClick={() => handleSingleProcess(process.processId)} className={`app-elip-hsc text-xl sm:text-sm font-semibold text-black cursor-pointer ${showOverview ? 'mb-6 sm:mb-3' : ''}`}>
                                                                        {process.name || process?.nameWhenOther}
                                                                    </div>
                                                                    <div data-testid={`${testId}-single-process`} onClick={() => handleSingleProcess(process.processId)} className={`flex items-center border rounded-full cursor-pointer ${showOverview ? 'border-[#535353]' : 'border-[#959595]'} px-4 py-1 sm:px-2 sm:py-1`}>
                                                                        <div className={`font-bold font-family-arial text-sm sm:text-[11px] ${showOverview ? 'text-[#535353]' : 'text-[#959595]'} mr-2`}>{intl.formatMessage({ id: 'software_detail', defaultMessage: 'software_detail' })}</div>
                                                                        {showOverview ?
                                                                            <img data-testid={`${testId}-close-icon`} src={CloseIcon} alt='' />
                                                                            :
                                                                            <img data-testid={`${testId}-info-icon`} src={InfoIcon} alt='' />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {showOverview &&
                                                                    <div>
                                                                        {softwareList.length > 0 &&
                                                                            <>
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr className='flex items-center mt-10'>
                                                                                            <td className="w-[138px] min-h-[25px] flex items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595]"></td>
                                                                                            <td className="w-[138px] min-h-[25px] flex items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] font-family-arial">{intl.formatMessage({ id: 'Support', defaultMessage: 'Support' })}</td>
                                                                                            <td className="w-[138px] min-h-[25px] flex items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] font-family-arial">{intl.formatMessage({ id: 'upgrade_patch', defaultMessage: 'upgrade_patch' })}</td>
                                                                                            <td className="w-[138px] min-h-[25px] flex items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] font-family-arial">{intl.formatMessage({ id: 'storage', defaultMessage: 'storage' })}</td>
                                                                                            <td className="w-[138px] min-h-[25px] flex items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] font-family-arial">{intl.formatMessage({ id: 'back_up_frequency', defaultMessage: 'back_up_frequency' })}</td>
                                                                                            <td className="w-[138px] min-h-[25px] flex items-center tracking-tighter text-center justify-center text-sm font-[400] text-[#959595] "></td>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {softwareList?.map((item, index) =>
                                                                                            <tr data-testid={`${testId}-software-list-${index}`} key={item?.softwareId} className='border-1 h-[100px] w-[800px] flex rounded-lg mt-[10px]'>
                                                                                                <td className='border font-bold tracking-[1px] h-[80px] w-[145px] rounded-l-lg justify-center bg-white flex items-center text-[10px] text-[#959595] text-center uppercase font-family-arial'>
                                                                                                    {item?.name}
                                                                                                </td>
                                                                                                <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[145px] justify-center bg-[#FFF9F0] flex items-center text-[10px] text-[#959595] text-center "'>
                                                                                                    <div className='"app-elip min-w-max max-w-[64px] font-bold border-[1px] text-[#121212] bg-white border-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center font-family-arial' >
                                                                                                        {item.inSupport !== 0 && getSOValue(item.inSupport)}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[145px] justify-center bg-[#FFF9F0] flex items-center text-[10px] text-[#959595] text-center "'>
                                                                                                    <div className='"app-elip min-w-max max-w-[64px] font-bold border-[1px] text-[#121212] bg-white border-[#C1C1C1] rounded-lg h-[32px] p-[6px] flex items-center justify-center font-family-arial' >
                                                                                                        {item.patches !== 0 && getSOValue(item.patches)}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[145px] justify-center bg-[#FFF9F0] flex items-center text-[10px] text-[#959595] text-center "'>
                                                                                                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>{`${item.storageName}`}</span>}>
                                                                                                        <div className='min-w-[115px] max-w-[64px] border-[1px] text-[#121212] font-bold bg-white border-[#C1C1C1] rounded-lg h-[32px] p-[6px] block truncate font-family-arial' >
                                                                                                            {item.storageName}
                                                                                                        </div>
                                                                                                    </Tooltip>
                                                                                                </td>
                                                                                                <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[145px] justify-center bg-[#FFF9F0] flex items-center text-[10px] text-[#959595] text-center "'>
                                                                                                    <Tooltip placement="bottom" trigger={['hover']} overlay={<span>{`${item.backupName}`}</span>}>
                                                                                                        <div className='min-w-[115px] max-w-[64px] border-[1px] text-[#121212] font-bold bg-white border-[#C1C1C1] rounded-lg h-[32px] p-[6px] block truncate font-family-arial' >
                                                                                                            {item.backupName}
                                                                                                        </div>
                                                                                                    </Tooltip>
                                                                                                </td>
                                                                                                <td className='border-t-[1px] border-b-[1px] border-r-[1px] font-bold tracking-[1px] h-[80px] w-[116px] justify-center rounded-r-lg bg-white flex items-center text-[10px] text-[#959595] text-center "'>
                                                                                                    {userRoles ?
                                                                                                        <>
                                                                                                            <img data-testid={`${testId}-edit-software-img`} className="w-[12px] cursor-pointer h-[12px]" onClick={() => gotoEditSoftware(item)} src={EditIcon} alt="" />
                                                                                                            <div data-testid={`${testId}-delete-software-modal`} className="w-max flex ml-2 items-center justify-between cursor-pointer" onClick={() => handleDeleteSoftwareModalOpen(item)}>
                                                                                                                <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                                                                        <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            </div>
                                                                                                        </> :
                                                                                                        <img data-testid={`${testId}-info-icon-1`} className="w-[12px] cursor-pointer h-[12px]" src={InfoIcon} alt="" />
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </>
                                                                        }
                                                                        {userRoles ?
                                                                            <div>
                                                                                <StyledButton
                                                                                    testId={`${testId}-add-software-btn`}
                                                                                    customClass='text-bold'
                                                                                    width={'w-60'}
                                                                                    align={'justify-start'}
                                                                                    text={intl.formatMessage({ id: 'add_software', defaultMessage: 'add_software' })}
                                                                                    color={'text-gray-700'}
                                                                                    flexReverse={'flex-row-reverse'}
                                                                                    iconType={'add'}
                                                                                    backgroundColor={'transparent border-1'}
                                                                                    onClick={() => gotoAddSoftware()}
                                                                                />
                                                                            </div>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                                <StyledButton
                                    testId={`${testId}-previous-btn`}
                                    width={'w-48 sm:w-full'}
                                    align={'justify-start'}
                                    text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                    color={'text-gray-700'}
                                    flexReverse={'flex-row-reverse'}
                                    iconType={'arrowleft'}
                                    backgroundColor={'bg-gray-300'}
                                    onClick={goToPrevious}
                                />
                                <StyledButton
                                    testId={`${testId}-next-btn`}
                                    buttonClass={'PrimoryBtn'}
                                    width={'w-48 sm:w-full'}
                                    align={'justify-end'}
                                    text={intl.formatMessage({ id: 'next', defaultMessage: 'next' })}
                                    color={'text-white'}
                                    iconType={'arrow'}
                                    backgroundColor={'bg-black'}
                                    onClick={goToNext}
                                />
                            </div>
                        </div>
                    </Container>
                </div>
            }
            <Modal show={isSoftwareModal} onClose={() => handleSoftwareModalClose()}>
                <div data-testid={`${testId}-software-modal`} className={softwarePopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer">
                        {softwarePopupDetails?.Intro}
                    </div>
                    <div className="font-semibold text-xl text-[#121212] mb-6">
                        {softwarePopupDetails?.Header}
                    </div>
                    {softwarePopupDetails?.Video?.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(softwarePopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {softwarePopupDetails?.Button2 && <StyledButton testId={`${testId}-software-popup-btn2`} onClick={() => softwarePopupDetails?.Link2 ? navigate(softwarePopupDetails?.Link2) : handleSoftwareModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={softwarePopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {softwarePopupDetails?.Button && <StyledButton testId={`${testId}-software-popup-btn`} onClick={() => softwarePopupDetails?.Link ? navigate(softwarePopupDetails?.Link) : handleAddSoftwareYes()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={softwarePopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>

            <div>
                <Modal show={isDeleteSoftwareModal} onClose={() => handleDeleteSoftwareModalClose()}>
                    <div>{`${intl.formatMessage({ id: 'are_you_sure', defaultMessage: 'are_you_sure' })}`}</div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-cancel-button`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => handleDeleteSoftwareModalClose()}
                        />
                        <StyledButton
                            testId={`${testId}-delete-btn`}
                            buttonClass={'PrimoryBtn'}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "delete", defaultMessage: 'delete' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => deleteSoftwareItem()}
                        />
                    </div>
                </Modal>
                <Modal show={isWromgOrgModal} onClose={() => ""}>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                    </div>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                    </div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-goto-dashboard-bia`}
                            onClick={() => handleWrongOrgModel()}
                            buttonClass={'PrimoryBtn'}
                            width={"w-[220px] sm:w-40"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                            color={"text-white mr-3 text-base"}
                            iconType={'home'}
                            backgroundColor={"bg-black"}
                        />
                    </div>
                </Modal>
            </div>
        </>
    )
}
