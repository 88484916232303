import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from '../../components/StyledButton';
import schema from '../../validations/schema';
import Container from '../../components/Container/Container';
import GetIcons from '../../components/GetIcons/GetIcons';
import LoadingIcon from '../../assets/icons/loading-icon';
import { AllRiskCategoryListData, ConnectivitiesList } from './ConnectivityContainer';
import { Singlelocations } from '../HardwareContainer/Hardware';
import { locationContainerCreators } from '../LocationsContainer/locationReducer';
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import { selectDecodedUserInfo } from '../../AppSelector';
import { InfoDetails } from '../BIAContainer/BIAEdit';
import { ContentType } from '@onesixeight-nl/classes';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import Modal from '../../components/Modal/Modal';

const testId = `add-connectivity`;

type ErrorMessage = {
    message: string;
}

type ErrorObject = {
    name?: ErrorMessage;
}

export default function AddConnectivity() {

    // hooks
    const intl = useIntl();
    const params = useParams();
    const { state, search } = useLocation();
    const { id, locationId, connectivityId } = params;
    const isCancel = new URLSearchParams(search).get('isCancel');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const raSelector = useSelector((state: RootState) => state.riskAreContainer);
    const { shouldRealoadAddresses } = useSelector((state: RootState) => state.locationContainer);
    const locationSelector = useSelector((state: RootState) => state.locationContainer);
    const connectivitySelector = useSelector((state: RootState) => state.locationContainer);
    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);

    // states
    const [answerType, setAnswerType] = useState<string>('');
    const [singlelocations, setSinglelocations] = useState<Singlelocations>();
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>();
    const [nameWhenOther, setNameWhenOther] = useState<string>("")
    const [editConnectivityObj, setEditConnectivityObj] = useState<ConnectivitiesList>()
    const [currentIndex, setCurrentIndex] = useState<number>(locationSelector && locationSelector.connectivities && locationSelector.connectivities?.findIndex((ele: ConnectivitiesList) => !ele.connectivityId));
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const [errObj, setErrObj] = useState<ErrorObject>({
        name: undefined
    });

    const [lastQuestion, setLastQuestion] = useState<boolean>(false);

    const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);

    const [connectivityQuestions, setConnectivityQuestions] = useState<ConnectivitiesList[]>([]);
    const intialConnectivity: ConnectivitiesList = {
        connectivityId: '',
        connectivityListId: '',
        name: '',
        nameWhenOther: '',
        selected: false,
        created: '',
        modified: '',
        locationId: '',
        organizationId: '',
    };
    const [singleConnectivity, setSingleConnectivity] = useState<ConnectivitiesList>();
    const [learnMoreInfo, setLearnMoreInfo] = useState<boolean | undefined>(false);
    const findInfoText: any = infoDetails?.length > 0 ? infoDetails?.find((ele) => ele?.attributes?.InfoText) : []
    const InfoTextDetail = findInfoText ? findInfoText?.attributes?.InfoText : {}
    const textShow = InfoTextDetail ? InfoTextDetail?.substring(0, 100) : "";

    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
    const [loading, setLoading] = useState(false);
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.error !== null) {
            setIsWromgOrgModal(true)
        }
    }, [riskAreaSelector.error])

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        if (locationSelector.connectivities && locationSelector.connectivities.length) {
            const defaultConnectivities = locationSelector.connectivities?.filter((ele: ConnectivitiesList) => ele.connectivityListId !== "00000000-0000-0000-0000-000000000000");
            setConnectivityQuestions(defaultConnectivities);
        }
    }, [locationSelector.connectivities])

    useEffect(() => {
        if (locationSelector?.status !== null && locationSelector?.status !== undefined) {
            setIsSaved(true);
            setTimeCounter(1);
        }
    }, [locationSelector])

    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved]);

    function SideDrawerOpen() {
        setLearnMoreInfo(!learnMoreInfo);
    }

    function SideDrawerClose() {
        setLearnMoreInfo(false);
    }

    useEffect(() => {
        const postConnectivitiesData = locationSelector.postConnectivitiesData
        const postConnectivityIndex = connectivityQuestions.findIndex((ele: ConnectivitiesList) => ele?.connectivityListId === locationSelector?.postConnectivitiesData?.connectivityListId)
        if (postConnectivityIndex > -1 && postConnectivitiesData !== null) {
            connectivityQuestions[postConnectivityIndex] = locationSelector.postConnectivitiesData;
            setConnectivityQuestions(connectivityQuestions);
        }
      }, [locationSelector.postConnectivitiesData])

    useEffect(() => {
        if (editConnectivityObj) {
            setCurrentIndex(-1);
            setSingleConnectivity(editConnectivityObj);
            if (editConnectivityObj.selected) {
                if (editConnectivityObj?.connectivityListId !== '00000000-0000-0000-0000-000000000000') {
                    setAnswerType('OnThisLocation');
                } else {
                    setAnswerType('');
                    setNameWhenOther(editConnectivityObj?.nameWhenOther);
                }
            } else {
                if (editConnectivityObj?.connectivityListId !== '00000000-0000-0000-0000-000000000000') {
                    setAnswerType('NotOnThisLocation');
                } else {
                    setAnswerType('');
                }
            }
        }
    }, [editConnectivityObj])

    useEffect(() => {
        if (locationSelector.singleConnectivity && !editConnectivityObj) {
            setSingleConnectivity(locationSelector.singleConnectivity);
            if (locationSelector.singleConnectivity.selected) {
                if (locationSelector.singleConnectivity?.connectivityListId !== '00000000-0000-0000-0000-000000000000') {
                    setAnswerType('OnThisLocation');
                } else {
                    setAnswerType('');
                    setNameWhenOther(locationSelector.singleConnectivity?.nameWhenOther);
                }
            } else {
                if (locationSelector.singleConnectivity?.connectivityListId !== '00000000-0000-0000-0000-000000000000') {
                    setAnswerType('NotOnThisLocation');
                } else {
                    setAnswerType('');
                }
            }
        }
    }, [locationSelector.singleConnectivity])

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
    }, [id])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (raSelector && raSelector.riskCategory && raSelector.riskCategory.length && id) {
            const riskCategoryObj = raSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === id)
            setSingleRiskCategory(riskCategoryObj);
            if (riskCategoryObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [raSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        dispatch(locationContainerCreators.requestGetLocations());
    }, []);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        shouldRealoadAddresses && dispatch(locationContainerCreators.requestGetLocations());
    }, [shouldRealoadAddresses]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.locations && locationSelector.locations.length > 0) {
            const availableConnectivityCount = locationSelector?.locations?.find((ele: any) => ele.locationId === locationId)
            setSinglelocations(availableConnectivityCount)
        }
    }, [locationSelector.locations, locationSelector])

    useEffect(() => {
        if (singlelocations) {
            dispatch(locationContainerCreators.requestGetConnectivities(singlelocations, singleRiskCategory, isCancel, ''));
        }
    }, [singlelocations])

    useEffect(() => {
        if (connectivitySelector && connectivitySelector.connectivities && connectivitySelector.connectivities.length > 0 && connectivityId) {
            const currentConnectivity = connectivitySelector?.connectivities?.find((ele: ConnectivitiesList) => ele?.connectivityId === connectivityId)
            setSingleConnectivity(currentConnectivity)
            setEditConnectivityObj(currentConnectivity)
        }
    }, [connectivitySelector.connectivities, locationId])

    useEffect(() => {
        if (connectivitySelector?.connectivities && connectivitySelector?.connectivities.length >= 0 && !editConnectivityObj) {
            if (currentIndex !== null && currentIndex > -1) {
                setCurrentIndex(currentIndex)
                setSingleConnectivity(connectivitySelector.connectivities[currentIndex])
            }
            else if (currentIndex === null) {
                const index = connectivitySelector?.connectivities?.findIndex((ele: ConnectivitiesList) => !ele?.connectivityId)
                setCurrentIndex(index)
                setSingleConnectivity(connectivitySelector?.connectivities[index])
            }
            else if (currentIndex === -1) {
                setSingleConnectivity({
                    ...intialConnectivity,
                    connectivityListId: '00000000-0000-0000-0000-000000000000'
                });
            }
        }
    }, [connectivitySelector?.connectivities, currentIndex])

    useEffect(() => {
        dispatch(locationContainerCreators.requestGetConnectivityHelpText(ContentType.CONNECTIVITY, decodedUserInfo));
    }, [])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.connectivityHelpText) {
            setInfoDetails(locationSelector.connectivityHelpText?.data);
        }
    }, [locationSelector])


    useEffect(() => {
        if (locationSelector?.loadingAfterPost) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [locationSelector?.loadingAfterPost])

    // functions
    function handleAnswerType(answer: string) {
        if (((role === "administrator") || (role === "editor"))) {
            if (answer === 'OnThisLocation') {
                setAnswerType('OnThisLocation');
            } else if (answer === 'NotOnThisLocation') {
                setAnswerType('NotOnThisLocation');
            } else if (answer === 'Yes') {
                setAnswerType('Yes');
                setSingleConnectivity({
                    ...intialConnectivity,
                    connectivityListId: '00000000-0000-0000-0000-000000000000'
                });
            } else if (answer === 'No') {
                setAnswerType('No');
                setSingleConnectivity({
                    ...intialConnectivity,
                    connectivityListId: '00000000-0000-0000-0000-000000000000'
                });
            }
        }
    }
 /* istanbul ignore next */
    function handleConnectivityCancel() {
        navigate(`/connectivity/${id}?isCancel=0`, { state: { from: 'cancal' } });
    }
/* istanbul ignore next */
    async function handlePreviousConnectivity() {
        if (currentIndex === -1) {
            if (singlelocations?.locationId && connectivityQuestions[connectivityQuestions?.length - 1] && connectivityQuestions[connectivityQuestions?.length - 1]?.connectivityId) {
                dispatch(locationContainerCreators.requestGetConnectivityById(singlelocations?.locationId, connectivityQuestions[connectivityQuestions?.length - 1]?.connectivityId))
            }
            setCurrentIndex(connectivityQuestions.length - 1);
        } else if (currentIndex > -1) {
            setCurrentIndex(currentIndex - 1);
            if (singlelocations?.locationId && connectivityQuestions[currentIndex - 1] && connectivityQuestions[currentIndex - 1]?.connectivityId) {
                dispatch(locationContainerCreators.requestGetConnectivityById(singlelocations?.locationId, connectivityQuestions[currentIndex - 1]?.connectivityId))
            }
        }
        //setTimeout(() => {
        //    dispatch(locationContainerCreators.requestGetConnectivities(singlelocations, singleRiskCategory, isCancel, ''));
        //}, 1500)
    }

    function isDisableBtn() {
        if (singleConnectivity?.connectivityListId !== '00000000-0000-0000-0000-000000000000') {
            if (answerType === '') {
                return true;
            } else {
                return false;
            }
        } else if (singleConnectivity?.connectivityListId === '00000000-0000-0000-0000-000000000000' && lastQuestion) {
            if (answerType === '') {
                return true;
            } else {
                return false;
            }
        } else if (singleConnectivity?.connectivityListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion) {
            if (answerType !== '') {
                return true;
            }
        }
    }

    function getSelectedValue() {
        if (answerType === "OnThisLocation") {
            return true;
        } else if (answerType === "NotOnThisLocation") {
            return false;
        } else if (answerType === 'Yes') {
            return true;
        } else if (answerType === 'No') {
            return false;
        } else if (answerType === '') {
            return true;
        }
    }
/* istanbul ignore next */
    function handleBtnText() {
        if (editConnectivityObj) {
            return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
        } else {
            if (connectivityQuestions[currentIndex]?.connectivityListId) {
                return intl.formatMessage({ id: 'next', defaultMessage: 'next' })
            } else {
                if (lastQuestion) {
                    return intl.formatMessage({ id: 'next', defaultMessage: 'next' })
                } else {
                    return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
                }
            }
        }
    }
 /* istanbul ignore next */
    function handleEditConnectivityInAddMode(isAddMode: boolean) {
        const nameErr = schema.connectivityNameWhenOther.validate(nameWhenOther).error;
        if (answerType === "") {
            if (nameErr) {
                setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
            } else {
                const payload = {
                    connectivityListId: editConnectivityObj?.connectivityListId,
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: getSelectedValue(),
                }
                dispatch(locationContainerCreators.requestPutConnectivities(singlelocations?.locationId, connectivityQuestions[currentIndex]?.connectivityId, payload));
                setErrObj({ name: undefined });
                setAnswerType('');
                setNameWhenOther('');
                if (isAddMode) {
                    if (currentIndex + 1 < connectivityQuestions.length) {
                        setCurrentIndex(currentIndex + 1);
                      } else {
                        setCurrentIndex(-1);
                      }
                } else {
                    navigate(`/connectivity/${id}?isCancel=0`);
                }
            }
        } else {
            if (answerType === 'OnThisLocation' || answerType === 'NotOnThisLocation') {
                const payload = {
                    connectivityListId: editConnectivityObj?.connectivityListId,
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: getSelectedValue(),
                }
                dispatch(locationContainerCreators.requestPutConnectivities(singlelocations?.locationId, connectivityQuestions[currentIndex]?.connectivityId, payload));
                setErrObj({ name: undefined });
                setAnswerType('');
                setNameWhenOther('');
                if (isAddMode) {
                    if (currentIndex + 1 < connectivityQuestions.length) {
                        setCurrentIndex(currentIndex + 1);
                      } else {
                        setCurrentIndex(-1);
                      }
                } else {
                    navigate(`/connectivity/${id}?isCancel=0`);
                }
            }
            else {
                if (answerType === "No") {
                    const payload = {
                        connectivityListId: editConnectivityObj?.connectivityListId,
                        nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                        selected: getSelectedValue(),
                    }
                    dispatch(locationContainerCreators.requestPutConnectivities(singlelocations?.locationId, connectivityQuestions[currentIndex]?.connectivityId, payload));
                    setErrObj({ name: undefined });
                    setAnswerType('');
                    setNameWhenOther('');
                    if (isAddMode) {
                        if (currentIndex + 1 < connectivityQuestions.length) {
                            setCurrentIndex(currentIndex + 1);
                          } else {
                            setCurrentIndex(-1);
                          }
                    } else {
                        navigate(`/connectivity/${id}?isCancel=0`);
                    }
                }
            }
        }
    }

    /* istanbul ignore next */
    function handleNextBtn() {
        if (connectivityQuestions[currentIndex]?.connectivityId) {
            // EDIT CONNECTIVITY IN ADD MODE
            if (singlelocations?.locationId && connectivityQuestions[currentIndex + 1]?.connectivityId) {
                dispatch(locationContainerCreators.requestGetConnectivityById(singlelocations?.locationId, connectivityQuestions[currentIndex + 1]?.connectivityId))
            }
            handleEditConnectivityInAddMode(true);
        }
        else if (editConnectivityObj) {
            const nameErr = schema.connectivityNameWhenOther.validate(nameWhenOther).error;
            if (answerType === "") {
                if (nameErr) {
                    setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
                } else {
                    const payload = {
                        connectivityListId: editConnectivityObj?.connectivityListId,
                        nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                        selected: getSelectedValue(),
                    }
                    dispatch(locationContainerCreators.requestPutConnectivities(editConnectivityObj?.locationId, editConnectivityObj?.connectivityId, payload));
                    setErrObj({ name: undefined });
                    setAnswerType('');
                    setNameWhenOther('');
                    navigate(`/connectivity/${id}?isCancel=0`);
                }
            } else {
                if (answerType === 'OnThisLocation' || answerType === 'NotOnThisLocation') {
                    const payload = {
                        connectivityListId: editConnectivityObj?.connectivityListId,
                        nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                        selected: getSelectedValue(),
                    }
                    dispatch(locationContainerCreators.requestPutConnectivities(editConnectivityObj?.locationId, editConnectivityObj?.connectivityId, payload));
                    setErrObj({ name: undefined });
                    setAnswerType('');
                    setNameWhenOther('');
                    navigate(`/connectivity/${id}?isCancel=0`);
                }
                else {
                    if (answerType === "No") {
                        const payload = {
                            connectivityListId: editConnectivityObj?.connectivityListId,
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: getSelectedValue(),
                        }
                        dispatch(locationContainerCreators.requestPutConnectivities(editConnectivityObj?.locationId, editConnectivityObj?.connectivityId, payload));
                        setErrObj({ name: undefined });
                        setAnswerType('');
                        setNameWhenOther('');
                        navigate(`/connectivity/${id}?isCancel=0`);
                    }
                }
            }
        } else {
            // ADD Connectivity
            if (singleConnectivity?.connectivityListId === '00000000-0000-0000-0000-000000000000' && lastQuestion) {
                if (answerType === 'Yes') {
                    const nameErr = schema.connectivityNameWhenOther.validate(nameWhenOther).error
                    if (nameErr) {
                        setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
                    } else {
                        const payload = {
                            connectivityListId: "00000000-0000-0000-0000-000000000000",
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: getSelectedValue(),
                        }
                        dispatch(locationContainerCreators.requestPostConnectivities(singlelocations && singlelocations?.locationId, payload));
                        setErrObj({ name: undefined });
                        setAnswerType('');
                        setNameWhenOther('');
                    }
                } else {
                    navigate(`/connectivity/${id}`, { state: { showPopup: false } });
                }
            } else if (singleConnectivity?.connectivityListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion) {
                if (answerType === '') {
                    const nameErr = schema.connectivityNameWhenOther.validate(nameWhenOther).error
                    if (nameErr) {
                        setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
                    } else {
                        const payload = {
                            connectivityListId: "00000000-0000-0000-0000-000000000000",
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: getSelectedValue(),
                        }
                        dispatch(locationContainerCreators.requestPostConnectivities(singlelocations && singlelocations?.locationId, payload));
                        setErrObj({ name: undefined });
                        setAnswerType('');
                        setNameWhenOther('');
                        if (currentIndex + 1 < connectivityQuestions.length) {
                            setCurrentIndex(currentIndex + 1);
                        } else {
                            setCurrentIndex(-1);
                        }
                        navigate(`/connectivity/${id}?isCancel=0`);
                    }
                } else {
                    navigate(`/connectivity/${id}?isCancel=0`);
                }
            } else {
                if (answerType === 'OnThisLocation' || answerType === 'NotOnThisLocation') {
                    const payload = {
                        connectivityListId: connectivityQuestions[currentIndex]?.connectivityListId,
                        nameWhenOther: '',
                        selected: getSelectedValue(),
                    }
                    dispatch(locationContainerCreators.requestPostConnectivities(singlelocations && singlelocations?.locationId, payload));
                    setErrObj({ name: undefined });
                    setAnswerType('');
                    setNameWhenOther('');
                    setLastQuestion(true);
                    if (currentIndex + 1 < connectivityQuestions.length) {
                        setCurrentIndex(currentIndex + 1);
                    } else {
                        setCurrentIndex(-1);
                    }
                }
            }
        }
        setLearnMoreInfo(false);
        //if (!editConnectivityObj && answerType !== "No") {
        //    setTimeout(() => {
        //        dispatch(locationContainerCreators.requestGetConnectivities(singlelocations, singleRiskCategory, isCancel, ''));
        //    }, 1000)
        //}
    }


    return (
        <>
            {/*{locationSelector.loading || loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :*/}
                <div>
                    <Container>
                        <div className='m-auto w-full pb-[28px] align-middle items-center flex justify-between sm:m-0 sm:pb-6'>
                            <div className="flex items-center font-semibold text-xl">
                                <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                <div className="ml-[12px] font-semibold text-xl text-[#535353]">
                                    {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                </div>
                            </div>
                            {isSaved &&
                                <div className="font-normal text-xs text-[#959595]">
                                    <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                </div>
                            }
                        </div>
                        <div className="border w-full"></div>
                        <div>
                            <div className="flex-grow-1 flex flex-col justify-between">
                                <div>
                                    <div className='w-full align-middle items-center flex justify-between  mt-5'>
                                        <div className='flex-col mt-5'>
                                            <div className="font-bold text-xs text-[#959595] sm:text-2xl uppercase">
                                                {singlelocations?.name || singlelocations?.nameWhenOther}
                                            </div>
                                            {editConnectivityObj ?
                                                <div className="font-semibold text-[32px] sm:text-2xl">
                                                    <div>{editConnectivityObj?.connectivityListId === "00000000-0000-0000-0000-000000000000"
                                                        ? intl.formatMessage({ id: "Edit_Connectivity", defaultMessage: 'Edit_Connectivity' })
                                                        : editConnectivityObj?.name}</div>
                                                </div>
                                                :
                                                <>
                                                    {connectivityQuestions[currentIndex]?.connectivityListId ?
                                                        <div data-testid={`${testId}-connectivity-question-name`} className="font-semibold text-[32px] sm:text-2xl">
                                                            <div>{connectivityQuestions[currentIndex]?.name}</div>
                                                        </div>
                                                        :
                                                        <>
                                                            {lastQuestion ?
                                                                <div className="font-semibold text-[32px] sm:text-2xl">
                                                                    <div>{intl.formatMessage({ id: "can_you_name_another_connectivity?", defaultMessage: 'can_you_name_another_connectivity?' })}</div>
                                                                </div>
                                                                :
                                                                <div className="font-semibold text-[32px] sm:text-2xl">
                                                                    <div>{intl.formatMessage({ id: 'Add_Connectivity', defaultMessage: 'Add_Connectivity' })}</div>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {infoDetails && infoDetails.length !== 0 &&
                                            <div className=" w-[540px] leading-4 mb-10 text-[#535353] sm:w-full">
                                                <span className="font-normal text-lg">{textShow}</span>
                                                {textShow.length > 90 &&
                                                    <>
                                                        <span>{"... "}</span>
                                                        <span data-testid={`${testId}-learn-more-click`} className={"font-semibold text-[#3BA8FC] text-lg cursor-pointer w-max"} onClick={() => SideDrawerOpen()}>{intl.formatMessage({ id: 'learn_more', defaultMessage: 'learn_more' })}</span>
                                                    </>
                                                }
                                            </div>}

                                        <div className={`w-full max-w-[420px] top-0 right-0  h-full fixed z-[1055] border-r sm:overflow-y-auto sm:w-full sm:max-w-full ${!learnMoreInfo ? 'translate-x-[100%] ease-in-out duration-300' : 'translate-x-[0%] ease-in-out duration-300'}`}>
                                            <SideDrawer drawerInfo={infoDetails} SideDrawerClose={SideDrawerClose} />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex gap-[24px] sm:flex-col'>
                                    <div className="flex sm:flex-col sm:w-full">
                                        {editConnectivityObj ?
                                            <>
                                                {editConnectivityObj.connectivityListId === "00000000-0000-0000-0000-000000000000" ?
                                                    <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mt-8 mb-10 mr-10 p-10 sm:p-5">
                                                        <label className="inline-block text-base font-bold mb-1">
                                                            {intl.formatMessage({ id: "enter_manual_connectivity", defaultMessage: 'enter_manual_connectivity' })}
                                                        </label>
                                                        <input
                                                            data-testid={`${testId}-manual-connectivity`}
                                                            type="text"
                                                            maxLength={100}
                                                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                                                            value={nameWhenOther}
                                                            onChange={(e) => setNameWhenOther(e.target.value)}
                                                        />
                                                        {errObj.name?.message ?
                                                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                                <span>{errObj.name.message}</span>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    :
                                                    <div className='mt-8'>
                                                        <div data-testid={`${testId}-on-this-location`} onClick={() => handleAnswerType('OnThisLocation')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${answerType === 'OnThisLocation' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-center text-2xl sm:text-xl">{intl.formatMessage({ id: 'on_this_location', defaultMessage: 'on_this_location' })}</div>
                                                        </div>
                                                        <div data-testid={`${testId}-not-on-this-location`} onClick={() => handleAnswerType('NotOnThisLocation')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${answerType === 'NotOnThisLocation' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-center text-2xl sm:text-xl">{intl.formatMessage({ id: 'not_on_this_location', defaultMessage: 'not_on_this_location' })}</div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                                {connectivityQuestions[currentIndex]?.connectivityListId ?
                                                    <div className='mt-8'>
                                                        <div data-testid={`${testId}-current-on-this-location`} onClick={() => handleAnswerType('OnThisLocation')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${answerType === 'OnThisLocation' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'on_this_location', defaultMessage: 'on_this_location' })}</div>
                                                        </div>
                                                        <div data-testid={`${testId}-current-not-on-this-location`} onClick={() => handleAnswerType('NotOnThisLocation')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${answerType === 'NotOnThisLocation' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'not_on_this_location', defaultMessage: 'not_on_this_location' })}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {lastQuestion ?
                                                            <div className='mt-8'>
                                                                <div data-testid={`${testId}-answer-type-no`} onClick={() => handleAnswerType('No')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${answerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                                    <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                                </div>
                                                                <div data-testid={`${testId}-answer-type-yes`} onClick={() => handleAnswerType('Yes')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                                    <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mt-8 mb-10 mr-10 p-10 sm:p-5">
                                                                <label className="inline-block text-base font-bold mb-1">
                                                                    {intl.formatMessage({ id: "enter_manual_connectivity", defaultMessage: 'enter_manual_connectivity' })}
                                                                </label>
                                                                <input
                                                                    data-testid={`${testId}-enter-manual-connectivity`}
                                                                    type="text"
                                                                    maxLength={100}
                                                                    className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                                                                    value={nameWhenOther}
                                                                    onChange={(e) => setNameWhenOther(e.target.value)}
                                                                />
                                                                {errObj.name?.message ?
                                                                    <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                                        <span>{errObj.name.message}</span>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>

                                    {(answerType === 'Yes') &&
                                        <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mt-8 mb-10 mr-10 p-10 sm:p-5">
                                            <label className="inline-block text-base font-bold mb-1">
                                                {intl.formatMessage({ id: "enter_manual_connectivity", defaultMessage: 'enter_manual_connectivity' })}
                                            </label>
                                            <input
                                                data-testid={`${testId}-enter-connectivity`}
                                                type="text"
                                                maxLength={100}
                                                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errObj.name?.message ? 'border-[red]' : ''}`}
                                                value={nameWhenOther}
                                                onChange={(e) => setNameWhenOther(e.target.value)}
                                            />
                                            {errObj.name?.message ?
                                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                    <span>{errObj.name.message}</span>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    }
                                </div>
                                <div className='flex gap-[24px]'></div>
                            </div>
                            {(connectivityQuestions[currentIndex]?.connectivityListId || (lastQuestion)) &&
                                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                                    <div>
                                        <StyledButton
                                            testId={`${testId}-previous-btn`}
                                            onClick={() => handlePreviousConnectivity()}
                                            disabled={(currentIndex === 0 || editConnectivityObj) ? true : false}
                                            buttonClass={'sm:px-2'}
                                            flexReverse={'flex-row-reverse'}
                                            width={'w-48 sm:w-max'}
                                            align={'justify-start'}
                                            text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                            color={'text-[#535353]'}
                                            iconType={'arrowleft'}
                                            backgroundColor={'bg-[#EDEDED]'}
                                        />
                                    </div>
                                    {((role === "administrator") || (role === "editor")) &&
                                        <div>
                                            <StyledButton
                                                testId={`${testId}-next-btn`}
                                                buttonClass={'PrimoryBtn'}
                                                width={"w-48 sm:w-40"}
                                                align={"justify-end"}
                                                text={handleBtnText()}
                                                disabled={isDisableBtn()}
                                                backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'}
                                                color={isDisableBtn() ? 'text-[#535353]' : 'text-white'}
                                                iconType={isDisableBtn() ? 'blackarrow' : 'arrow'}
                                                onClick={() => handleNextBtn()}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            <div className="w-full flex items-center justify-between mt-4">
                                {(editConnectivityObj || (singleConnectivity?.connectivityListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion)) ?
                                    <StyledButton testId={`${testId}-cancel-btn`} onClick={() => handleConnectivityCancel()} buttonClass={'sm:px-2'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })} color={'text-[#535353]'} backgroundColor={'bg-[#EDEDED]'} />
                                    :
                                    <div className="w-48 h-[52px] flex items-center justify-center">
                                        <button data-testid={`${testId}-cancel-btn-2`} onClick={() => handleConnectivityCancel()} className="flex items-center justify-center">
                                            <span className="text-[#535353] font-family-arial font-semibold">{intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}</span>
                                        </button>
                                    </div>
                                }
                                {(editConnectivityObj || (singleConnectivity?.connectivityListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion)) && <StyledButton testId={`${testId}-handle-next-btn`} onClick={() => handleNextBtn()} buttonClass={'sm:px-2 PrimoryBtn'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })} disabled={isDisableBtn()} color={isDisableBtn() ? 'text-[#535353]' : 'text-white'} backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />}
                            </div>
                        </div>
                    </Container>
                </div>
            {/*}*/}
            <Modal show={isWromgOrgModal} onClose={() => ""}>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                </div>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-goto-dashboard-bia`}
                        onClick={() => handleWrongOrgModel()}
                        buttonClass={'PrimoryBtn'}
                        width={"w-[220px] sm:w-40"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                        color={"text-white mr-3 text-base"}
                        iconType={'home'}
                        backgroundColor={"bg-black"}
                    />
                </div>
            </Modal>
        </>
    );
}