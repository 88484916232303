import React from "react";
import { useIntl } from "react-intl";
import StyledButton from "../StyledButton";
import { PlanList } from "../../containers/PlansContainer";
import { selectDecodedUserInfo } from "../../AppSelector";
import { useSelector } from "react-redux";

type UsersCounterArray = {
    id: string;
    counter: string;
    userType: string;
}

type Props = {
    card: PlanList;
    planId: string;
    name: number;
    price: number;
    usersCounter?: UsersCounterArray[];
    isPlanStarted: boolean;
    setIsPlanStarted: React.Dispatch<React.SetStateAction<boolean>>;
    handleSingleProgram: (card: PlanList) => void;
    testId?: string;
    upgradeSubscription?: boolean;
}

export default function PlanCard(props: Props) {

    const { card, name, price, setIsPlanStarted, handleSingleProgram, testId = "plan-Card", upgradeSubscription } = props;
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const userInfo = decodedUserInfo?.payload;

    const intl = useIntl();

    function handleGetStarted() {
        if ((decodedUserInfo?.payload.role && decodedUserInfo?.payload.role === 'administrator' && decodedUserInfo?.payload.plan === 0) || upgradeSubscription) {
            setIsPlanStarted(true);
            handleSingleProgram(card);
        }
    }

    return (
        <>
            <div data-testid={testId} className="mr-6 relative">
                <div className="w-[301px] bg-white p-6 border rounded-lg flex flex-col justify-between">
                    <div className="min-h-[220px]">
                        <div className="flex font-semibold text-2xl text-left w-full mb-6 sm:text-xl">{intl.formatMessage({ id: `plan_${name}`, defaultMessage: `plan_${name}` })}</div>
                        <div className="w-full mb-2">
                            {card.usersCounter && card.usersCounter.map((uc) => (
                                <div key={uc?.id} className="flex items-center">
                                    <span className="font-semibold text-xl mr-2 sm:text-base">{uc.counter}</span>
                                    <span className="font-normal text-xl sm:text-base">{uc.userType}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        {card?.keyBanifits && card?.keyBanifits.length > 0 &&
                            <div className="mb-3 min-h-[200px]">
                                {(name === 1) &&
                                    <div className="font-semibold text-lg">{intl.formatMessage({ id: 'key_benefits', defaultMessage: 'key_benefits' })}</div>
                                }
                                <ul className="pl-[20px]">
                                    {card?.keyBanifits.map((cardItem, index) =>
                                        <li key={index} className={`${(name !== 1 && index === 0) ? 'font-semibold text-lg ml-[-23px]' : 'font-normal list-disc'}  sm:text-base `}>
                                            {cardItem}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        }
                        <div className="flex items-baseline justify-between min-h-[56px] mb-3">
                            <div className="font-semibold text-5xl sm:text-3xl">
                                {`${'€ ' + price}`}
                            </div>
                            <div className="font-normal text-base text-slate-400 font-family-arial">
                                {intl.formatMessage({ id: 'annually', defaultMessage: 'annually' })}
                            </div>
                        </div>
                        {userInfo && (userInfo?.plan < card.name) ?
                            <div className="flex">
                                <StyledButton testId={`${testId}-get-started`} onClick={() => handleGetStarted()} width={'w-full'} align={'justify-center'} text={card.btnText || ''} iconType={'blackarrow'} color={'text-zinc-600'} backgroundColor={''} />
                            </div>
                            :
                            <div className="h-[52px] flex items-center justify-center">
                                {card.name === userInfo?.plan && <div className="font-semibold text-base font-family-arial italic">{intl.formatMessage({ id: 'current_plan', defaultMessage: 'current_plan' })}</div>}
                            </div>
                        }
                    </div>
                </div>
                {userInfo && (userInfo?.plan >= card.name) && <div className='mr-6 flex top-0 absolute z-0 bg-white bg-opacity-80 w-[301px] h-[602px] opacity-80 rounded-[10px] border'></div>}
            </div>
        </>
    );
}