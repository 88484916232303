import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../../AppSelector';
import Container from '../../components/Container/Container';
import StyledButton from '../../components/StyledButton';
import { getBaseURL } from '../../utils';
import { dashboardContainerCreators } from '../ProtectedContainer/dashboardReducer';
import { saveAs } from 'file-saver';
import NotificationToast from '../../components/ToastComponent';
import { useNavigate } from 'react-router-dom';
import CustomMatrixChart from '../../components/CustomMatrixChart/CustomMatrixChart';
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import { MatrixRiskCategoryType } from '../MatrixContainer';
import LoadingIcon from '../../assets/icons/loading-icon';

const testId = `new-version`;

export default function NewVersion() {
    // hooks
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dashboardSelector = useSelector((state: RootState) => state.dashboardContainer);
    const rcMatrixSelector = useSelector((state: RootState) => state.riskAreContainer);

    //states
    const [versionId, setVersionId] = useState('');
    const [showPostVersionToast, setShowPostVersionToast] = useState<boolean>(false);
    const [matrixRiskCategory, setMatrixRiskCategory] = useState<MatrixRiskCategoryType[]>([]);
    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
 /* istanbul ignore next */
    useEffect(() => {
        if ((riskAreaSelector?.status !== null && riskAreaSelector?.status !== undefined)||(dashboardSelector?.status !== null && dashboardSelector?.status !== undefined)) {
            setIsSaved(true);
            setTimeCounter(1);
        }
    }, [riskAreaSelector.status,dashboardSelector?.status])
 /* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved])

 /* istanbul ignore next */
    useEffect(() => {
        if (dashboardSelector && dashboardSelector?.postVersion && dashboardSelector?.postVersion?.versionid) {
            setVersionId(dashboardSelector?.postVersion?.versionid);
        } else {
            setVersionId('');
        }
    }, [dashboardSelector])

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (rcMatrixSelector.shouldRealoadRiskCategory) {
            dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        }
    }, [rcMatrixSelector.shouldRealoadRiskCategory])/* eslint-disable react-hooks/exhaustive-deps */
    /* istanbul ignore next */
    useEffect(() => {
        if (rcMatrixSelector && rcMatrixSelector.riskCategory && rcMatrixSelector.riskCategory.length > 0) {
            const filteredRiskCategory = rcMatrixSelector.riskCategory.filter((ele: MatrixRiskCategoryType) => ele.riskFactor !== undefined && ele.riskFactor !== 0 && ele.assesedStatus !== undefined && ele.assesedStatus !== 0);
            setMatrixRiskCategory(filteredRiskCategory);
        }
    }, [rcMatrixSelector])

    // function
     /* istanbul ignore next */
    function handleCreateNewVersion() {
        dispatch(dashboardContainerCreators.requestPostNewVersion(setShowPostVersionToast))
    }

    const userInfo = useSelector(selectUserInfo);
    const accessToken: string = userInfo?.accessToken || '';
    /* istanbul ignore next */
    async function downloadNewVersion() {
        try {
            const versionResponse: any = await fetch(`${getBaseURL()}/bucoscanservice/v1/versions/document/${versionId}`, {
                method: 'GET',
                headers: {
                    authorization: accessToken
                }
            });

            const filename = versionResponse.headers.get('content-disposition')
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .replaceAll('"', '')
                .trim()
                ;
            const blob = await versionResponse.blob();

            const fileName = filename.substring(0, filename.lastIndexOf('.'))

            // Download the file
            saveAs(blob, fileName);

        } catch (error) {
            console.error('Failed to download file', error);
            throw new Error('Failed to download file');
        }
    }

    function gotoDashboard() {
        navigate("/dashboard?isCancel=0");
    }

    return (
        <>
            {rcMatrixSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div>
                    <Container>
                    {isSaved ?
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                            </div>
                            :
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div className="h-4">{"  "}</div>
                            </div>
                        }
                        <div className='flex items-center justify-center'>
                            <div className="w-full my-10 sm:my-6 sm:w-full">
                                <div className={"min-h-[70vh] flex flex-col justify-between"}>
                                    <div>
                                        {versionId ?
                                            <>
                                                <div className='font-semibold text-5xl sm:text-2xl'>{intl.formatMessage({ id: 'version_created', defaultMessage: 'version_created' })}</div>
                                                <div className="max-w-[586px] font-normal my-4 text-xl text-[#535353] font-family-arial">{intl.formatMessage({ id: 'version_was_created_succesfully', defaultMessage: 'version_was_created_succesfully' })}</div>
                                                <StyledButton
                                                    testId={`${testId}-download-btn`}
                                                    width={'w-max sm:w-max sm:m-0 sm:p-[10px] '}
                                                    buttonClass={"px-5"}
                                                    color={'text-black'}
                                                    backgroundColor={'bg-white'}
                                                    align={'justify-start'}
                                                    text={intl.formatMessage({ id: 'download', defaultMessage: 'download' })}
                                                    iconType={'downloadgrey'}
                                                    onClick={() => downloadNewVersion()}
                                                />
                                            </>
                                            :
                                            <>
                                                <div className='font-semibold text-5xl sm:text-2xl'>{intl.formatMessage({ id: 'new_version', defaultMessage: 'new_version' })}</div>
                                                <div className="max-w-[586px] font-normal my-4 text-xl text-[#535353] font-family-arial">{intl.formatMessage({ id: 'new_version_paragraph', defaultMessage: 'new_version_paragraph' })}</div>
                                                <div className='matrix-chart sm:overflow-x-auto'>
                                                    <CustomMatrixChart testId={`${testId}-custom-matrix-chart`} matrixRiskCategory={matrixRiskCategory} from={"newVersion"} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="w-full align-middle flex mt-8">
                                        {!versionId ?
                                            <StyledButton
                                                testId={`${testId}-create-new-version-btn`}
                                                onClick={() => handleCreateNewVersion()}
                                                customClass={'mt-[0px]'}
                                                buttonClass={'PrimoryBtn'}
                                                flexReverse={'flex-row-reverse sm:flex-row'}
                                                width={'w-max sm:w-max'}
                                                align={'justify-end'}
                                                text={intl.formatMessage({ id: 'create_new_version', defaultMessage: 'create_new_version' })}
                                                color={'text-white'}
                                                backgroundColor={'bg-black'}
                                            />
                                            :
                                            <StyledButton
                                                testId={`${testId}-new-version-dashboard-btn`}
                                                onClick={() => gotoDashboard()}
                                                customClass={'mt-[190px]'}
                                                buttonClass={'PrimoryBtn'}
                                                width={"w-[220px] sm:w-40"}
                                                align={"justify-end"}
                                                text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                                                color={"text-white mr-3 text-base"}
                                                iconType={'home'}
                                                backgroundColor={"bg-black"}
                                            />
                                        }
                                        {showPostVersionToast && <NotificationToast testId={`${testId}-version-created-toast`} text={dashboardSelector.postVersionError ? dashboardSelector.postVersionError : intl.formatMessage({ id: 'version_was_created_succesfully', defaultMessage: 'version_was_created_succesfully' })} iconType={(dashboardSelector.postVersionError) ? intl.formatMessage({ id: 'failure', defaultMessage: 'failure' }) : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} color={(dashboardSelector.postVersionError) ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showPostVersionToast} onClick={() => setShowPostVersionToast(false)} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            }
        </>
    )
}
