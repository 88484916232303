import { useEffect, useState } from "react";
import { InviteUser } from './index'
import { useIntl } from 'react-intl';
import { useSelector } from "react-redux";
import { selectDecodedUserInfo } from "../../AppSelector";

type Props = {
    user: InviteUser,
    handleDeleteInviteUser: (inviteId: any) => void,
    handleResendInviteUser: (user: InviteUser, inviteId: any) => void
    index: number
    testId?: string
}

export default function InviteUserItem(props: Props) {
    const { user, handleDeleteInviteUser, index, handleResendInviteUser, testId = `invite-user` } = props;
    const [isDisable, setIsDisable] = useState(false);
    const intl = useIntl();
    const decodedInfo: DecodedUserInfo | null = useSelector(selectDecodedUserInfo);
    const userRole: string | undefined = decodedInfo?.payload.role;



    useEffect(() => {
        if (isDisable) {
            setTimeout(() => {
                setIsDisable(false)
            }, 30000)
        }
    }, [isDisable])

    const handleOnClickOfResend = (inviteUser: InviteUser, inviteId: any) => {
        setIsDisable(true);
        handleResendInviteUser(inviteUser, inviteId)
    }

    return (
        <div data-testid={testId} className={"grid sm:grid-cols-1 grid-cols-2 items-center justify-between  px-4 py-3 font-semibold " + (index !== 0 ? " border-t " : "")} key={index}>
            <div data-testid={`${testId}-email`} className="user-details-elip">{user.email}</div>
            <>
                {(userRole === "partneraccountant" || userRole === "partnerinsuranceadvisor") ?
                    <>
                        {decodedInfo?.payload.plan === 0 ?
                            <div className="flex items-center justify-end">
                                <div data-testid={`${testId}-resend-user`} className={`${isDisable ? "text-[#959595]" : "cursor-pointer text-[#3BA8FC]"} mr-2 sm:mr-0 user-role-elip`} onClick={() => !isDisable ? handleOnClickOfResend(user, user.inviteId) : ""} > {intl.formatMessage({ id: 'resend_user' })} </div>
                                <span data-testid={`${testId}-invite-id`} className='cursor-pointer' onClick={() => handleDeleteInviteUser(user.inviteId)}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                            <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                            :
                            <></>
                        }
                    </>
                    :

                    <div className="flex items-center justify-end">
                        <div data-testid={`${testId}-resend-user`} className={`${isDisable ? "text-[#959595]" : "cursor-pointer text-[#3BA8FC]"} mr-2 sm:mr-0 user-role-elip`} onClick={() => !isDisable ? handleOnClickOfResend(user, user.inviteId) : ""} > {intl.formatMessage({ id: 'resend_user' , defaultMessage:'resend_user'})} </div>

                        <span data-testid={`${testId}-invite-id`} className='cursor-pointer' onClick={() => handleDeleteInviteUser(user.inviteId)}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                    <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                </g>
                            </svg>
                        </span>
                    </div>

                }
            </>
        </div>
    )
}