import { ErrorObject } from "../../containers/SoftwareContainer/Add_Software"

function infoBtnWrapper(showMoreInfo: boolean) {
    return `${showMoreInfo ? 'border-[#535353]' : 'border-[#959595]'}`
}

function infoBtn(showMoreInfo: boolean) {
    return `${showMoreInfo ? 'text-[#535353]' : 'text-[#959595]'}`
}

function nameError(errObj: ErrorObject) {
    return `${errObj.name?.message ? 'border-[red]' : ''}`
}

function handleSelectedAnsType(answerType: string, answer: string) {
    return `${answerType === answer ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'}`
}

export { infoBtnWrapper, infoBtn, nameError, handleSelectedAnsType }