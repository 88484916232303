const Container = (props: any) => {

    return (
        <div className={"flex flex-col items-center w-full"}>
            <div className="max-w-[992px] w-full">
                {props.children}
            </div>
        </div>
    )
}

export default Container
