import {
    AxiosError,
    AxiosResponse
} from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { taskService, dashBoardTaskListOneService, dashBoardTaskListTwoService, taskListUpdateService, todoTaskListService, doingTaskListService } from '../../services/TaskService';
import { taskContainerTypes, taskContainerCreators } from './taskReducer';
import { clearMeasureStatusCreators } from '../MeasureGroupsContainer/measureGroupsReducer';

type TaskData = {
    offset: number;
    pageSize: number;
}

type TaskListData = {
    offset: number;
    pageSize: number;
    dashboardList: number;
    status:number;
}

type TaskListUpdateData = {
    taskId: string;
    data: object
}

const {
    REQUEST_GET_TASK,
    REQUEST_GET_TASK_ONE_LIST,
    REQUEST_GET_TASK_TWO_LIST,
    REQUEST_PUT_TASK_LIST,
    REQUEST_GET_TODO_TASK_LIST,
    REQUEST_GET_DOING_TASK_LIST
} = taskContainerTypes;

/* istanbul ignore next */
export function* getTasksData(data: TaskData) {
    try {
        const response: AxiosResponse = yield call(taskService, data.offset, data.pageSize);
        yield put(clearMeasureStatusCreators.clearMeasureStatus());
        yield put(taskContainerCreators.successGetTask(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(taskContainerCreators.failureGetTask(e.response?.data.errors[0].message));
        } else {
            yield put(taskContainerCreators.failureGetTask("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putTasksListData(data: TaskListUpdateData) {
    try {
        const response: AxiosResponse = yield call(taskListUpdateService,data.taskId,data.data);
        yield put(taskContainerCreators.successPutTaskList(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(taskContainerCreators.failurePutTaskList(e.response?.data.errors[0].message));
        } else {
            yield put(taskContainerCreators.failurePutTaskList("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getTaskListOneData(data: TaskListData) {
    try {
        const response: AxiosResponse = yield call(dashBoardTaskListOneService, data.offset, data.pageSize,data.dashboardList);
        // yield put(clearMeasureStatusCreators.clearMeasureStatus());
        yield put(taskContainerCreators.successGetTaskOneList(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(taskContainerCreators.failureGetTaskOneList(e.response?.data.errors[0].message));
        } else {
            yield put(taskContainerCreators.failureGetTaskOneList("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getTaskListTwoData(data: TaskListData) {
    try {
        const response: AxiosResponse = yield call(dashBoardTaskListTwoService, data.offset, data.pageSize,data.dashboardList);
        // yield put(clearMeasureStatusCreators.clearMeasureStatus());
        yield put(taskContainerCreators.successGetTaskTwoList(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(taskContainerCreators.failureGetTaskTwoList(e.response?.data.errors[0].message));
        } else {
            yield put(taskContainerCreators.failureGetTaskTwoList("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* todoTaskListData(data: TaskListData) {
    try {
        const response: AxiosResponse = yield call(todoTaskListService, data.offset, data.pageSize,data.status);
        // yield put(clearMeasureStatusCreators.clearMeasureStatus());
        yield put(taskContainerCreators.successGetTodoTaskList(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(taskContainerCreators.failureGetTodoTaskList(e.response?.data.errors[0].message));
        } else {
            yield put(taskContainerCreators.failureGetTodoTaskList("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* doingTaskListData(data: TaskListData) {
    try {
        const response: AxiosResponse = yield call(doingTaskListService, data.offset, data.pageSize,data.status);
        // yield put(clearMeasureStatusCreators.clearMeasureStatus());
        yield put(taskContainerCreators.successGetDoingTaskList(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(taskContainerCreators.failureGetDoingTaskList(e.response?.data.errors[0].message));
        } else {
            yield put(taskContainerCreators.failureGetDoingTaskList("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export default function* taskContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_GET_TASK, getTasksData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_TASK_ONE_LIST, getTaskListOneData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_TASK_TWO_LIST, getTaskListTwoData);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_TASK_LIST, putTasksListData);
     // @ts-ignore
     yield takeLatest(REQUEST_GET_TODO_TASK_LIST, todoTaskListData);
     // @ts-ignore
     yield takeLatest(REQUEST_GET_DOING_TASK_LIST, doingTaskListData);
}
