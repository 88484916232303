import axios, {
    AxiosError,
    AxiosPromise,
    AxiosResponse
} from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { dashboardService, downloadReportService, newVersionService, welcomeTextService } from '../../services/DashboardService';
import { dashboardContainerTypes, dashboardContainerCreators, welcomeContainerTypes, welcomeContainerCreators } from './dashboardReducer';
import { getOrgListService, switchOrgService, createNewOrgService, deleteOrgService } from '../../services/Organization';
import { appContainerCreators } from '../../AppReducer';
import { Statuses } from '@onesixeight-nl/classes';
import { getCmsURL } from '../../utils';
import { clearListOptionContainerCreators } from '../OrganisationContainer/reducer';

/* istanbul ignore next */
export const linkPartnerLogoService = (partnerId: string): AxiosPromise => axios.get(`${getCmsURL()}/partners?populate=logo&filters[partnerId][$eq]=${partnerId}`)

/* istanbul ignore next */
export const noOrgWelcomeTextService = (decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/welcomes?filters[OrganizationSelected][$eq]=false&locale=${decodedUserInfo?.payload?.prefLang}`)
/* istanbul ignore next */
export const orgWelcomeTextService = (roles: string, decodedUserInfo: DecodedUserInfo | null, type: string, status: number, isPlan: boolean, isPartnerId: boolean): AxiosPromise => {
    /* istanbul ignore next */
    const url = `${getCmsURL()}/welcomes?populate[0]=popup&populate[1]=popup.video&filters[UserRole][${roles}][$eq]=true&filters[Plan][$eq]=${isPlan}&filters[${type}][$eq]=${Statuses[status]}&locale=${decodedUserInfo?.payload?.prefLang}${isPartnerId ? `&filters[Partner][$eq]=${isPartnerId}` : ''}${decodedUserInfo?.payload?.orgType === 1 ? '&filters[IsPartnerOrganization][$eq]=true' : ''}`

    return axios.get(url)
}

/* istanbul ignore next */
export const partnerOrgWelcomeTextService = (roles: string, isPlan: boolean, isPartnerOrganization: boolean, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/welcomes?populate[0]=popup&populate[1]=popup.video&filters[UserRole][${roles}][$eq]=true&filters[Plan][$eq]=${isPlan}&filters[IsPartnerOrganization][$eq]=${isPartnerOrganization}&locale=${decodedUserInfo?.payload?.prefLang}`)

type GetOrgListData = {
    userId: string;
}

type GetPartnerInfo = {
    partnerId: string;
}

type SwitchOrgData = {
    data: {
        organizationId: string;
    },
    inviteToast?: boolean | string | null;
}

type CreateNewOrgData = {
    duplicateOrgIntentional: boolean;
    data: object;
    onCancel: () => void;
    decodedUserInfo: DecodedUserInfo | null;
}

type DeleteOrgData = {
    organizationId: string;
}

/* istanbul ignore next */
type GetNoOrgWelcomeData = {
    decodedUserInfo: DecodedUserInfo | null
}

/* istanbul ignore next */
type GetOrgWelcomeTextData = {
    orgWelcomeTextObj: {
        decodedUserInfo: DecodedUserInfo | null;
        type: string;
        status: number;
        plan: boolean;
        partnerId: boolean;
    }
}

/* istanbul ignore next */
type GetPartnerOrgWelcomeTextData = {
    orgWelcomeTextObj: {
        decodedUserInfo: DecodedUserInfo | null;
        plan: boolean;
        isPartnerOrganization: boolean;
    }
}

type PostNewVersion = {
    setShowPostVersionToast?: React.Dispatch<React.SetStateAction<boolean>>;
}

const {
    REQUEST_GET_STATUS,
    REQUEST_GET_ORG_LIST,
    REQUEST_POST_SWITCH_ORG,
    REQUEST_POST_ADD_ORG,
    REQUEST_DELETE_ORG,
    REQUEST_GET_PARTNER_LOGO,
    REQUEST_GET_NEW_VERSION,
    REQUEST_POST_NEW_VERSION
} = dashboardContainerTypes;

const {
    REQUEST_GET_WELCOME,
    REQUEST_GET_NO_ORG_WELCOME,
    REQUEST_GET_ORG_WELCOME_TEXT,
    REQUEST_GET_PARTNER_ORG_WELCOME_TEXT
} = welcomeContainerTypes;

/* istanbul ignore next */
export function* getStatusData() {
    try {
        const response: AxiosResponse = yield call(dashboardService);
        yield put(dashboardContainerCreators.successGetStatus(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(dashboardContainerCreators.failureGetStatus(e.response?.data.errors[0].message));
        } else {
            yield put(dashboardContainerCreators.failureGetStatus("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getWelcomeData() {
    try {
        const response: AxiosResponse = yield call(welcomeTextService);
        yield put(welcomeContainerCreators.successGetWelcome(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(welcomeContainerCreators.failureGetWelcome(e.response?.data.errors[0].message));
        } else {
            yield put(welcomeContainerCreators.failureGetWelcome("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getNoOrgWelcomeData(data: GetNoOrgWelcomeData) {
    try {
        const response: AxiosResponse = yield call(noOrgWelcomeTextService, data.decodedUserInfo);
        yield put(welcomeContainerCreators.successGetNoOrgWelcome(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(welcomeContainerCreators.failureGetNoOrgWelcome(e.response?.data.errors[0].message));
        } else {
            yield put(welcomeContainerCreators.failureGetNoOrgWelcome("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getOrgWelcomeTextData(data: GetOrgWelcomeTextData) {
    const requestData = data.orgWelcomeTextObj;
    const role = requestData.decodedUserInfo?.payload.role;
    const getRoles = () => {
        if (role === 'administrator') {
            return 'Administrator'
        } else if (role === 'editor') {
            return 'Editor'
        } else if (role === 'reader') {
            return 'Reader'
        } else if (role === 'accountant') {
            return 'Accountant'
        } else if (role === 'insureanceadvisor') {
            return 'InsureanceAdvisor'
        } else if (role === 'partneraccountant') {
            return 'PartnerAccountant'
        } else if (role === 'partnerinsureanceadvisor') {
            return 'PartnerInsureanceAdvisor'
        } else {
            return ''
        }
    }
    try {
        const response: AxiosResponse = yield call(orgWelcomeTextService, getRoles(), requestData.decodedUserInfo, requestData.type, requestData.status, requestData.plan, requestData.partnerId);
        yield put(welcomeContainerCreators.successGetOrgWelcomeText(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(welcomeContainerCreators.failureGetOrgWelcomeText(e.response?.data.errors[0].message));
        } else {
            yield put(welcomeContainerCreators.failureGetOrgWelcomeText("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getPartnerOrgWelcomeTextData(data: GetPartnerOrgWelcomeTextData) {
    const requestData = data.orgWelcomeTextObj;
    const role = requestData.decodedUserInfo?.payload.role;
    const getRoles = () => {
        if (role === 'administrator') {
            return 'Administrator'
        } else if (role === 'editor') {
            return 'Editor'
        } else if (role === 'reader') {
            return 'Reader'
        } else if (role === 'accountant') {
            return 'Accountant'
        } else if (role === 'insureanceadvisor') {
            return 'InsureanceAdvisor'
        } else if (role === 'partneraccountant') {
            return 'PartnerAccountant'
        } else if (role === 'partnerinsureanceadvisor') {
            return 'PartnerInsureanceAdvisor'
        } else {
            return ''
        }
    }
    try {
        const response: AxiosResponse = yield call(partnerOrgWelcomeTextService, getRoles(), requestData.plan, requestData.isPartnerOrganization, requestData.decodedUserInfo);
        yield put(welcomeContainerCreators.successGetPartnerOrgWelcomeText(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(welcomeContainerCreators.failureGetPartnerOrgWelcomeText(e.response?.data.errors[0].message));
        } else {
            yield put(welcomeContainerCreators.failureGetPartnerOrgWelcomeText("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getOrgListData(data: GetOrgListData) {
    try {
        const response: AxiosResponse = yield call(getOrgListService, data.userId);
        yield put(clearListOptionContainerCreators.clearListOptionStatus())
        yield put(dashboardContainerCreators.successGetOrgList(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(dashboardContainerCreators.failureGetOrgList(e.response?.data.errors[0].message));
        } else {
            yield put(dashboardContainerCreators.failureGetOrgList("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* switchOrgData(data: SwitchOrgData) {
    try {
        const response: AxiosResponse = yield call(switchOrgService, data.data);
        yield put(appContainerCreators.updateUserInfo(response.data))
        yield put(dashboardContainerCreators.successPostSwitchOrg(response.data, data?.inviteToast, response.status))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(dashboardContainerCreators.failurePostSwitchOrg(e.response?.data.errors[0].message));
        } else {
            yield put(dashboardContainerCreators.failurePostSwitchOrg("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* createNewOrgData(data: CreateNewOrgData) {
    try {
        const response: AxiosResponse = yield call(createNewOrgService, data.duplicateOrgIntentional, data.data);
        if (response) {
            data.onCancel();
            yield put(dashboardContainerCreators.requestGetOrgList(data.decodedUserInfo?.payload.userId))
        }
        yield put(dashboardContainerCreators.successPostAddOrg(response.data, response.status))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(dashboardContainerCreators.failurePostAddOrg(e.response?.data.errors[0].message));
        } else {
            yield put(dashboardContainerCreators.failurePostAddOrg("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* deleteOrgData(data: DeleteOrgData) {
    try {
        const response: AxiosResponse = yield call(deleteOrgService, data.organizationId);
        yield put(dashboardContainerCreators.successDeleteOrg(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(dashboardContainerCreators.failureDeleteOrg(e.response?.data.errors[0].message));
        } else {
            yield put(dashboardContainerCreators.failureDeleteOrg("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getPartnerLogo(data: GetPartnerInfo) {
    try {
        const response: AxiosResponse = yield call(linkPartnerLogoService, data.partnerId);
        yield put(dashboardContainerCreators.successGetPartnerLogo(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(dashboardContainerCreators.failureGetPartnerLogo(e.response?.data.errors[0].message));
        } else {
            yield put(dashboardContainerCreators.failureGetPartnerLogo("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* downloadReportsData() {
    try {
        const response: AxiosResponse = yield call(downloadReportService);
        yield put(dashboardContainerCreators.successGetNewVersion(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(dashboardContainerCreators.failureGetNewVersion(e.response?.data.errors[0].message));
        } else {
            yield put(dashboardContainerCreators.failureGetNewVersion("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* postNewVersion(data: PostNewVersion) {
    try {
        const response: AxiosResponse = yield call(newVersionService);
        yield put(dashboardContainerCreators.successPostNewVersion(response.headers, response.status))
    } catch (e) {
        if (e) {
            data.setShowPostVersionToast && data.setShowPostVersionToast(true);
            setTimeout(() => {
                data.setShowPostVersionToast && data.setShowPostVersionToast(false);
            }, 5000)
        }
        if (e instanceof AxiosError) {
            yield put(dashboardContainerCreators.failurePostNewVersion(e.response?.data.errors[0].message));
        } else {
            yield put(dashboardContainerCreators.failurePostNewVersion("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export default function* dashboardContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_GET_STATUS, getStatusData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_WELCOME, getWelcomeData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_NO_ORG_WELCOME, getNoOrgWelcomeData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ORG_WELCOME_TEXT, getOrgWelcomeTextData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_PARTNER_ORG_WELCOME_TEXT, getPartnerOrgWelcomeTextData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ORG_LIST, getOrgListData);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_SWITCH_ORG, switchOrgData);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_ADD_ORG, createNewOrgData);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_ORG, deleteOrgData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_PARTNER_LOGO, getPartnerLogo);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_NEW_VERSION, downloadReportsData);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_NEW_VERSION, postNewVersion);
}