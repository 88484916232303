import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import EditIcon from '../../assets/icons/edit-icon.svg';
import StyledButton from '../StyledButton';
import DatePicker from "react-date-picker";
import Dropdown from "../Dropdown";
import calenderIcon from "../../assets/icons/calender-icon.svg";
import dayjs from 'dayjs';
import { ErrorObject, File, Frequency, MeasureList, Option, ReminderFrequencyList, StepsList, UserDropdownList, Users, ImpactOptionList } from "../../containers/MeasureRiskAreasContainer";
import schema from "../../validations/schema";
import { useDispatch, useSelector } from "react-redux";
import { measureGroupsContainerCreators } from "../../containers/MeasureGroupsContainer/measureGroupsReducer";
import { selectDecodedUserInfo, selectUserInfo } from "../../AppSelector";
import Tooltip from 'rc-tooltip/es/Tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { getBaseURL } from "../../utils";
import { saveAs } from 'file-saver';

const testId = `measure-expand-section`;

type Props = {
    answerType: string;
    setAnswerType: React.Dispatch<React.SetStateAction<string>>;
    nameWhenOther: string;
    setnameWhenOther: React.Dispatch<React.SetStateAction<string>>;
    answerSubType: string;
    setAnswerSubType: React.Dispatch<React.SetStateAction<string>>;
    periodicReminderAnswerType: string;
    setPeriodicReminderAnswerType: React.Dispatch<React.SetStateAction<string>>;
    profileArr: string[];
    monthlyReminder: boolean;
    setMonthlyReminder: React.Dispatch<React.SetStateAction<boolean>>;
    isToDoDropDown: boolean;
    setIsToDoDropDown: React.Dispatch<React.SetStateAction<boolean>>;
    isReminderDropDown: boolean;
    setIsReminderDropDown: React.Dispatch<React.SetStateAction<boolean>>;
    evidenceDoneDate: Date;
    setEvidenceDoneDate: React.Dispatch<React.SetStateAction<Date>>;
    evidenceFormatedDate: string;
    setEvidenceFormatedDate: React.Dispatch<React.SetStateAction<string>>;
    userData: Users;
    setUserData: React.Dispatch<React.SetStateAction<Users>>;
    evidenceLaterDropdownList: UserDropdownList[];
    setEvidenceLaterDropdownList: React.Dispatch<React.SetStateAction<UserDropdownList[]>>;
    reminderOption: Option;
    reminderFrequencyList: ReminderFrequencyList[];
    setReminderFrequencyList: React.Dispatch<React.SetStateAction<ReminderFrequencyList[]>>;
    reminderData: Frequency;
    setReminderData: React.Dispatch<React.SetStateAction<Frequency>>;
    evidenceNowDropdownList: UserDropdownList[];
    setEvidenceNowDropdownList: React.Dispatch<React.SetStateAction<UserDropdownList[]>>;
    reminderTasksList: Frequency[];
    setReminderTasksList: React.Dispatch<React.SetStateAction<Frequency[]>>;
    isEditReminder: number | null;
    setIsEditReminder: React.Dispatch<React.SetStateAction<number | null>>;
    users: Users[];
    errObj: ErrorObject;
    setErrObj: React.Dispatch<React.SetStateAction<ErrorObject>>;
    setFirstName: React.Dispatch<React.SetStateAction<string>>;
    details: string;
    setDetails: React.Dispatch<React.SetStateAction<string>>;
    userDropdownList: UserDropdownList[];
    handleInput: (value: string) => void;
    progressDoneDate: Date;
    handleProgressDoneDate: (date: Date) => void;
    toDoArea: string;
    setToDoArea: React.Dispatch<React.SetStateAction<string>>;
    selectedFile: File[];
    setSelectedFile: React.Dispatch<React.SetStateAction<File[]>>;
    singleMeasure: MeasureList | undefined;
    lastQuestion: boolean;
    stepData: StepsList;
    setStepData: React.Dispatch<React.SetStateAction<StepsList>>;
    stepsList: StepsList[];
    setStepsList: React.Dispatch<React.SetStateAction<StepsList[]>>;
    impactAnswer: string;
    setImpactAnswer: React.Dispatch<React.SetStateAction<string>>;
    effortAnswer: string;
    setEffortAnswer: React.Dispatch<React.SetStateAction<string>>;
    ImpactOptionList: ImpactOptionList[];
    measureStatus?: string;
    setMeasureStatus?: React.Dispatch<React.SetStateAction<string>>;
    editMeasureObj?: any;
    showInput: boolean;
    setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
    editIndex: number | null;
    setEditIndex: React.Dispatch<React.SetStateAction<number | null>>;
    editInput: boolean;
    setEditInput: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MeasureExpandSection(props: Props) {

    const [progressValue, setProgressValue] = useState<number | null>(null);
    const dispatch = useDispatch();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const [largeFileMsg, setLargeFileMsg] = useState<boolean>();

    const intl = useIntl();

    const initialOption: Option = {
        label: intl.formatMessage({ id: 'select', defaultMessage: 'select' }),
        value: "",
    }

    const { answerType, nameWhenOther, setnameWhenOther, answerSubType, setAnswerSubType,
        evidenceDoneDate, setEvidenceDoneDate, setEvidenceFormatedDate, userData, setUserData, evidenceLaterDropdownList,
        users, errObj, setErrObj, setFirstName, details, setDetails, userDropdownList, handleInput, progressDoneDate,
        handleProgressDoneDate, selectedFile, setSelectedFile, lastQuestion, impactAnswer, setImpactAnswer,
        effortAnswer, setEffortAnswer, stepData, setStepData, stepsList, setStepsList, measureStatus, setMeasureStatus, editMeasureObj, showInput, setShowInput, editIndex, setEditIndex, editInput, setEditInput } = props;


    const handleLaterDropDown = (value: any) => {
        userData['userId'] = value;
        setUserData({ ...userData });
    };

    function handleEvidenceDoneDate(date: Date) {
        const newDateFormate = dayjs(date).format('YYYY-MM-DD');
        setEvidenceFormatedDate(newDateFormate.toString());
        setEvidenceDoneDate(date);
    }

    function handleDetails(value: string) {
        setDetails(value);
    }

    /* istanbul ignore next */
    const onFileChangeHandler = (e: any) => {
        const data = new FormData()
        data.append('fileId', '')
        data.append('linkedToType', '')
        data.append('linkedToId', '')
        data.append('file', e.target.files[0])

        const fsize = e.target.files.item(0).size;
        const fileSize = Math.round((fsize / (1024 * 1024)));
        if (e.target.files[0] && fileSize <= 10) {
            dispatch(measureGroupsContainerCreators.requestPostFiles(data, setProgressValue))
            setLargeFileMsg(false)
        }
        else {
            setLargeFileMsg(true)
            setErrObj({ dropDown: undefined })
        }
        e.target.value = '';
    }

    function handleDeleteFiles(index: number, fileId: any) {
        if (index !== -1) {
            selectedFile.splice(index, 1);
            setSelectedFile([...selectedFile])
        }
    }

    function handleAnsSubType(answerSubTypeValue: string) {
        if (((role === "administrator") || (role === "editor"))) {
            setAnswerSubType(answerSubTypeValue)
            setErrObj({ dropDown: undefined })
        }
    }

    const userInfo = useSelector(selectUserInfo);
    const accessToken: string = userInfo?.accessToken || '';
    /* istanbul ignore next */
    async function downloadEvidenceFile(fileId: any) {
        try {
            const response: any = await fetch(`${getBaseURL()}/bucoscanservice/v1/files/${fileId}`, {
                headers: {
                    authorization: accessToken
                }
            });
            const filename = response.headers.get('content-disposition')
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .replaceAll('"', '')
                .trim()
                ;
            const blob = await response.blob();
            saveAs(blob, filename);

        } catch (error) {
            console.error('Failed to download file', error);
            throw new Error('Failed to download file');
        }
    }
    /* istanbul ignore next */
    // steps
    function handleCheckStep(stepsListItem: StepsList, index: number) {
        let latestArray = [...stepsList];
        if (index !== null) {
            latestArray[index] = {
                status: stepsListItem.status === 1 ? 3 : 1,
                name: stepsListItem.name,
                stepId: stepsListItem.stepId,
            }
            setStepsList(latestArray)
        }
    }

    function handleStepName(value: string) {
        setStepData({ ...stepData, name: value });
    }

    function handleAddSteps() {
        const stepNameErr = schema.stepName.validate(stepData.name).error;
        if (stepNameErr) {
            setErrObj({
                stepName: stepNameErr?.message ? { message: intl.formatMessage({ id: stepNameErr?.message, defaultMessage: stepNameErr?.message }) } : undefined
            });
        } else {
            stepsList.push(stepData);
            setStepsList(stepsList);
            setErrObj({ stepName: undefined });
            setStepData({
                stepId: "",
                name: "",
                status: 1,
            });
        }
    }

    function handleKeyPressForAddStep(e: any) {
        if (e.key === 'Enter') {
            handleAddSteps();
        }
    }

    function handleShowStepInput() {
        setEditInput && setEditInput(false);
        setEditIndex && setEditIndex(null)
        setErrObj({ stepName: undefined });
        setStepData({
            stepId: "",
            name: "",
            status: 1,
        });
        setShowInput(true);
    }

    function handleHideStepInput() {
        setErrObj({ stepName: undefined });
        setStepData({
            stepId: "",
            name: "",
            status: 1,
        });
        setShowInput(false);
    }


    function handleEditStep(stepsListItem: StepsList, index: number) {
        setEditIndex(index);
        setErrObj({ stepName: undefined });
        setStepData({
            stepId: stepsListItem.stepId,
            name: stepsListItem.name,
            status: stepsListItem.status,
        });
        //setEditInput(!editInput);
        setShowInput(false);
    }

    function handleSaveSteps() {
        const stepNameErr = schema.stepName.validate(stepData.name).error;
        if (stepNameErr) {
            setErrObj({
                stepName: stepNameErr?.message ? { message: intl.formatMessage({ id: stepNameErr?.message, defaultMessage: stepNameErr?.message }) } : undefined
            });
        } else {
            setErrObj({ stepName: undefined });
            let latestArray = [...stepsList];
            if (editIndex !== null) {
                latestArray[editIndex] = {
                    ...stepData
                }
                setStepsList(latestArray)
            }
            setEditIndex(null);
            setStepData({
                stepId: "",
                name: "",
                status: 1,
            });
        }

    }

    function handleHideEditStepInput() {
        setEditIndex(null);
        setEditInput(false);
    }

    function handleKeyPressForEditStep(e: any) {
        if (e.key === 'Enter') {
            handleSaveSteps();
        }
    }
    function handleImpactAns(value: string) {
        setImpactAnswer(value)
    }

    function handleEffortAns(value: string) {
        setEffortAnswer(value)
    }

    function removeSteps(index: number) {
        if (index !== -1) {
            stepsList.splice(index, 1);
            setStepsList([...stepsList])
        }
    }

    return (
        <>
            <div className="w-full max-w-[414px] border rounded-lg bg-white mb-10 p-10 sm:p-5">
                {answerType === 'Yes' && lastQuestion &&
                    <div className="mb-4">
                        <div>
                            <div>
                                <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                    {intl.formatMessage({ id: "name_of_the_measure", defaultMessage: 'name_of_the_measure' })}
                                </label>
                                <input
                                    data-testid={`${testId}-name-when-other`}
                                    type="text"
                                    className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.name?.message ? 'border-[red]' : ''}`}
                                    value={nameWhenOther}
                                    onChange={(e) => setnameWhenOther(e.target.value)}
                                />
                            </div>
                        </div>
                        {errObj.name?.message ?
                            <div className='text-red-600 font-normal text-sm h-3 mt-1 flex items-start justify-start'>
                                <span>{errObj.name.message}</span>
                            </div>
                            :
                            null
                        }
                    </div>
                }
                {(answerType === 'Relevant' || answerType === 'Yes' || answerType === '') &&
                    <div className="mb-10">
                        <div className="font-bold text-base mb-2 font-family-arial">{intl.formatMessage({ id: 'steps_done_to_be_done', defaultMessage: 'steps_done_to_be_done' })}</div>
                        <div>
                            {stepsList.length > 0 &&
                                <div>
                                    {stepsList.map((stepsListItem, index) => {
                                        const isChecked = stepsListItem.status !== 1 ? true : false;
                                        return (
                                            <div data-testid={`${testId}-step-list-map-${index}`} key={index}>
                                                {!editInput && (index !== editIndex) ?
                                                    <div className="flex mb-2 items-center break-words justify-between">
                                                        <div className="flex">
                                                            <input data-testid={`${testId}-check-step-input`} className={`max-w-[20px] h-[20px] border hover:border-[#C1C1C1]`} type="checkbox" name="" checked={isChecked} onChange={(e) => handleCheckStep(stepsListItem, index)} />
                                                            <label data-testid={`${testId}-handle-edit-step-click`} className={`max-w-[280px] break-all flex flex-wrap font-normal text-sm cursor-pointer ${isChecked ? 'text-[#959595]' : 'text-black'} ml-2`} onClick={() => handleEditStep(stepsListItem, index)}>{stepsListItem.name}</label>
                                                        </div>
                                                        <div className='cursor-pointer ml-2 flex flex-col'>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" data-testid={`${testId}-remove-step`} onClick={() => removeSteps(index)} xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                    <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="mb-2">
                                                        <input
                                                            data-testid={`${testId}-edit-step-input`}
                                                            placeholder={intl.formatMessage({ id: 'edit_a_step', defaultMessage: 'edit_a_step' })}
                                                            type="text"
                                                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${(errObj.stepName?.message || errObj.hasName?.message) ? 'border-[red]' : ''}`}
                                                            value={stepData.name}
                                                            onChange={(e) => {
                                                                const regex = /^\S.*[a-zA-Z\s]*$/;
                                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                                    handleStepName(e.target.value)
                                                                }
                                                            }}
                                                            onKeyPress={(e) => handleKeyPressForEditStep(e)}
                                                        />
                                                        {(errObj.stepName?.message || errObj.hasName?.message) ?
                                                            <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                                                                <span>{errObj?.stepName?.message || errObj?.hasName?.message}</span>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        <div className="flex mt-2">
                                                            <StyledButton testId={`${testId}-save-steps`} onClick={() => handleSaveSteps()} customClass={'!h-[32px] !w-max mr-2'} buttonClass={'border-[#535353] px-4'} flexReverse={'flex-row-reverse'} height={'h-[32px]'} width={'w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })} color={'text-[#535353]'} backgroundColor={'bg-white'} />
                                                            <StyledButton testId={`${testId}-cancel-steps`} disabled={stepsList.length === 0} onClick={() => handleHideEditStepInput()} customClass={'!h-[32px] !w-max'} buttonClass={'border-[#535353] px-4'} flexReverse={'flex-row-reverse'} height={'h-[32px]'} width={'w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })} color={'text-[#535353]'} backgroundColor={'bg-white'} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }

                            {showInput ?
                                <div>
                                    <input
                                        data-testid={`${testId}-add-step-input`}
                                        placeholder={intl.formatMessage({ id: 'add_a_step', defaultMessage: 'add_a_step' })}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${(errObj.stepName?.message || errObj.hasName?.message) ? 'border-[red]' : ''}`}
                                        value={stepData.name}
                                        onChange={(e) => {
                                            const regex = /^\S.*[a-zA-Z\s]*$/;
                                            if (e.target.value === "" || regex.test(e.target.value)) {
                                                handleStepName(e.target.value)
                                            }
                                        }}
                                        onKeyPress={(e) => handleKeyPressForAddStep(e)}
                                    />
                                    {(errObj.stepName?.message || errObj.hasName?.message) ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                                            <span>{errObj?.stepName?.message || errObj?.hasName?.message}</span>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className="flex mt-2">
                                        <StyledButton testId={`${testId}-add-steps-btn`} onClick={() => handleAddSteps()} customClass={'!h-[32px] !w-max mr-2'} buttonClass={'border-[#535353] px-4'} flexReverse={'flex-row-reverse'} height={'h-[32px]'} width={'w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'add', defaultMessage: 'add' })} color={'text-[#535353]'} backgroundColor={'bg-white'} />
                                        <StyledButton testId={`${testId}-hide-step-input-btn`} disabled={stepsList.length === 0} onClick={() => handleHideStepInput()} customClass={'!h-[32px] !w-max'} buttonClass={'border-[#535353] px-4'} flexReverse={'flex-row-reverse'} height={'h-[32px]'} width={'w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })} color={'text-[#535353]'} backgroundColor={'bg-white'} />
                                    </div>
                                </div>
                                :
                                ((role === "administrator") || (role === "editor")) ?
                                    <div className="mt-4">
                                        <StyledButton testId={`${testId}-showw-step-input-false`} onClick={() => handleShowStepInput()} customClass={'!h-[32px] !w-max'} buttonClass={'border-[#535353] px-4'} flexReverse={'flex-row-reverse'} height={'h-[32px]'} width={'w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'add_a_step', defaultMessage: 'add_a_step' })} color={'text-[#535353]'} iconType={'add'} backgroundColor={'bg-white'} />
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                        <div>
                            <div className="font-bold text-base mt-5 mb-2 font-family-arial">{intl.formatMessage({ id: 'impact_measure_dropdown_question', defaultMessage:'impact_measure_dropdown_question' })}</div>
                            <div className="flex mb-10">
                                <div data-testid={`${testId}-impact-xs`} onClick={() => handleImpactAns('1')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${impactAnswer === '1' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-5 sm:mr-2`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'XS', defaultMessage: 'XS' })}</div>
                                </div>
                                <div data-testid={`${testId}-impact-s`} onClick={() => handleImpactAns('2')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${impactAnswer === '2' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-5 sm:mr-2`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'S', defaultMessage: 'S' })}</div>
                                </div>
                                <div data-testid={`${testId}-impact-m`} onClick={() => handleImpactAns('3')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${impactAnswer === '3' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center mr-5 justify-center`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'M', defaultMessage: 'M' })}</div>
                                </div>
                                <div data-testid={`${testId}-impact-l`} onClick={() => handleImpactAns('4')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${impactAnswer === '4' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-5 sm:mr-2`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'L', defaultMessage: 'L' })}</div>
                                </div>
                                <div data-testid={`${testId}-impact-xl`} onClick={() => handleImpactAns('5')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${impactAnswer === '5' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'XL', defaultMessage: 'XL' })}</div>
                                </div>
                            </div>
                        </div>

                        {measureStatus !== "done" && stepsList?.length > 0 &&
                            <div>
                                <div className="font-bold text-base mt-5 mb-2 font-family-arial">{intl.formatMessage({ id: 'effort_measure_dropdown_question', defaultMessage:'effort_measure_dropdown_question' })}</div>

                                <div className="flex mb-10">
                                    <div data-testid={`${testId}-effort-xs`} onClick={() => handleEffortAns('1')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${effortAnswer === '1' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-5 sm:mr-2`}>
                                        <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'XS', defaultMessage: 'XS' })}</div>
                                    </div>
                                    <div data-testid={`${testId}-effort-s`} onClick={() => handleEffortAns('2')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${effortAnswer === '2' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-5 sm:mr-2`}>
                                        <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'S', defaultMessage: 'S' })}</div>
                                    </div>
                                    <div data-testid={`${testId}-effort-m`} onClick={() => handleEffortAns('3')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${effortAnswer === '3' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center mr-5 justify-center`}>
                                        <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'M', defaultMessage: 'M' })}</div>
                                    </div>
                                    <div data-testid={`${testId}-effort-l`} onClick={() => handleEffortAns('4')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${effortAnswer === '4' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-5 sm:mr-2`}>
                                        <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'L', defaultMessage: 'L' })}</div>
                                    </div>
                                    <div data-testid={`${testId}-effort-xl`} onClick={() => handleEffortAns('5')} className={`w-[35px] h-[35px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${effortAnswer === '5' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center`}>
                                        <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'XL', defaultMessage: 'XL' })}</div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                }
                {((answerType === 'Relevant' || answerType === 'Yes' || answerType === "") && measureStatus === 'done') &&
                    <div>
                        <div data-testid={`${testId}-evidence`} className="font-semibold text-xl mb-2">{intl.formatMessage({ id: 'evidence', defaultMessage: 'evidence' })}</div>
                        <div data-testid={`${testId}-evidence-description`} className="font-normal text-base text-[#535353] mb-10 font-family-arial">{intl.formatMessage({ id: 'evidence_content', defaultMessage: 'evidence_content' })}</div>
                    </div>
                }
                {((answerType === 'Relevant' || answerType === 'Yes' || answerType === "") && (measureStatus === 'inProgress' || measureStatus === 'todo')) &&
                    <>
                        <div>
                            <div className="font-bold text-base mt-5 mb-2 font-family-arial">{intl.formatMessage({ id: 'who_is_responsible_for_this?', defaultMessage: 'who_is_responsible_for_this?' })}</div>
                            <div className="flex">
                                <div className="w-full">
                                    <Dropdown
                                        testId={`${testId}-dropdown-measure-expand-section`}
                                        options={[...[initialOption], ...userDropdownList]}
                                        value={userData.userId}
                                        onChange={(value: string) =>
                                            handleInput(value)
                                        }
                                        error={errObj.dropDown?.message}
                                    />
                                </div>
                            </div>
                            {errObj.dropDown?.message ?
                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                    <span>{errObj.dropDown.message}</span>
                                </div>
                                :
                                null
                            }
                        </div>
                        {decodedUserInfo?.payload.planningMethod === 1 ?
                            <div>
                                <div className="font-bold text-base mt-10 mb-2 font-family-arial">{intl.formatMessage({ id: 'when_should_this_be_done?', defaultMessage: 'when_should_this_be_done?' })}</div>
                                <div>
                                    <DatePicker locale={decodedUserInfo?.payload.prefLang} disabled={!((role === "administrator") || (role === "editor"))} className='customDatePicker bg-zinc-50 px-2 border rounded-lg w-[180px] h-12 relative sm:min-w-full' calendarIcon={<img className={`${(role === "administrator") || (role === "editor") ? 'pl-[10px]' : 'pl-[0px]'}`} data-testid={`${testId}-calender-icon-inprogress`} src={calenderIcon} alt='' />} dayPlaceholder='dd' monthPlaceholder='mm' yearPlaceholder='yyyy' value={progressDoneDate} onChange={(value: any) => handleProgressDoneDate(value)} format={'dd-MM-yyyy'} />
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                }

                {((answerType === 'Relevant' || answerType === 'Yes' || answerType === '') && measureStatus === 'done') &&
                    <>
                        <div>
                            <div data-testid={`${testId}-q1`} className="font-bold text-base mb-2 font-family-arial">{intl.formatMessage({ id: 'when_do_you_want_to_do_this?', defaultMessage: 'when_do_you_want_to_do_this?' })}</div>
                            <div className="flex mb-10">
                                <div data-testid={`${testId}-now`} onClick={() => handleAnsSubType('Now')} className={`w-[110px] h-[45px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${answerSubType === 'Now' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-5 sm:mr-2`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'now', defaultMessage: 'now' })}</div>
                                </div>
                                <div data-testid={`${testId}-later`} onClick={() => handleAnsSubType('Later')} className={`w-[110px] h-[45px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${answerSubType === 'Later' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-5 sm:mr-2`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'later', defaultMessage: 'later' })}</div>
                                </div>
                                <div data-testid={`${testId}-never`} onClick={() => handleAnsSubType('Never')} className={`w-[110px] h-[45px] ${((role === "administrator") || (role === "editor")) ? "cursor-pointer" : ""} rounded-lg ${answerSubType === 'Never' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'never', defaultMessage: 'never' })}</div>
                                </div>
                            </div>
                        </div>

                        {answerSubType === 'Now' &&
                            <>
                                <div>
                                    <div className="font-bold text-base mb-2 font-family-arial">{intl.formatMessage({ id: 'describe_what_you_did_or_upload_file/photo?', defaultMessage: 'describe_what_you_did_or_upload_file/photo?' })}</div>
                                    <textarea data-testid={`${testId}-details-textarea`} disabled={!((role === "administrator") || (role === "editor"))} className={`custom-textarea p-3 border ${errObj.reminderDetails?.message ? 'border-[red]' : ''}`} placeholder={intl.formatMessage({ id: 'for_example_bought_this_installed_that', defaultMessage: 'for_example_bought_this_installed_that' })} value={details} onChange={(e) => handleDetails(e.target.value)} />
                                    {errObj.reminderDetails?.message ?
                                        <div className='text-red-600 font-normal text-sm mb-3 flex items-start justify-start'>
                                            <span>{errObj.reminderDetails.message}</span>
                                        </div>
                                        :
                                        null
                                    }
                                    {/* file upload*/}
                                    <div className="w-full flex mt-1 flex-col">
                                        {selectedFile.length > 0 && selectedFile?.map((item, index) => (
                                            <div data-testid={`${testId}-select-files-indexing-${index}`} key={`${testId}-select-files-indexing-${item?.fileId}`} className="flex items-center justyfy-between w-full">
                                                <div className="w-full">
                                                    <span data-testid={`${testId}-download-evidance-file`} className="ml-1 underline font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial" onClick={() => downloadEvidenceFile(item && item?.fileId)}>{item?.fileName}</span>
                                                </div>
                                                <div data-testid={`${testId}-delete-files`} onClick={() => handleDeleteFiles(index, item?.fileId)} className="py-1 justify-end">
                                                    <svg className='cursor-pointer' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                            <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="form-group w-full">
                                        {(progressValue as number > 0 && progressValue as number < 100) &&
                                            <>
                                                <progress className="w-full h-[8px]" max="100" color="success" value={Number(progressValue)} >
                                                    {Math.round(Number(progressValue))}%
                                                </progress>
                                                <div>{Math.round(Number(progressValue))} %</div>
                                            </>
                                        }
                                    </div>
                                    <div className="flex items-center justify-center mt-4 mb-2 border rounded-2xl border-[#535353] px-4 w-max h-[32px] cursor-pointer">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_507_1627)">
                                                <path d="M11.7105 5.8825L6.88399 0.4C6.65076 0.159317 6.33474 0.0163208 5.99999 0C5.66529 0.0165188 5.34934 0.159483 5.11599 0.4L0.289494 5.8825C0.226488 5.95186 0.177911 6.03307 0.14659 6.12139C0.115269 6.2097 0.101831 6.30337 0.107057 6.39693C0.112283 6.49049 0.136069 6.58208 0.177031 6.66636C0.217993 6.75064 0.275312 6.82593 0.34565 6.88784C0.415989 6.94976 0.497942 6.99706 0.586737 7.027C0.675533 7.05694 0.769397 7.06891 0.862866 7.06223C0.956334 7.05554 1.04754 7.03033 1.13117 6.98806C1.2148 6.94578 1.28918 6.8873 1.34999 6.816L5.07499 2.583C5.09183 2.56403 5.11403 2.55063 5.13866 2.54458C5.16329 2.53852 5.18917 2.5401 5.21288 2.54911C5.23659 2.55812 5.257 2.57412 5.2714 2.595C5.28579 2.61588 5.2935 2.64064 5.29349 2.666V11.2935C5.29349 11.4809 5.36793 11.6606 5.50042 11.7931C5.63292 11.9256 5.81262 12 5.99999 12C6.18737 12 6.36707 11.9256 6.49956 11.7931C6.63206 11.6606 6.70649 11.4809 6.70649 11.2935V2.666C6.70647 2.64057 6.71421 2.61574 6.72867 2.59482C6.74313 2.5739 6.76363 2.55789 6.78743 2.54892C6.81123 2.53995 6.83719 2.53846 6.86186 2.54464C6.88653 2.55082 6.90873 2.56438 6.92549 2.5835L10.65 6.816C10.7108 6.8873 10.7852 6.94578 10.8688 6.98806C10.9524 7.03033 11.0437 7.05554 11.1371 7.06223C11.2306 7.06891 11.3245 7.05694 11.4133 7.027C11.502 6.99706 11.584 6.94976 11.6543 6.88784C11.7247 6.82593 11.782 6.75064 11.823 6.66636C11.8639 6.58208 11.8877 6.49049 11.8929 6.39693C11.8982 6.30337 11.8847 6.2097 11.8534 6.12139C11.8221 6.03307 11.7735 5.95186 11.7105 5.8825Z" fill="#535353" />
                                            </g>
                                        </svg>
                                        <label htmlFor="uploadFiles" className="font-bold text-sm text-[#535353] mx-1 cursor-pointer font-family-arial">{intl.formatMessage({ id: 'upload_file(s)', defaultMessage: 'upload_file(s)' })}</label>
                                        {((role === "administrator") || (role === "editor")) ? <input data-testid={`${testId}-upload-files-input`} className="hidden" type="file" name="file" id="uploadFiles" onChange={(e) => onFileChangeHandler(e)} /> : <></>}
                                    </div>
                                    {largeFileMsg ?
                                        <div className='text-red-600 font-normal text-sm mb-3 flex items-start justify-start'>
                                            {intl.formatMessage({ id: 'file_larger_then_10mb_is_not_allow', defaultMessage: 'file_larger_then_10mb_is_not_allow' })}
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </>
                        }
                        {answerSubType === 'Later' &&
                            <>
                                <div className="mt-10">
                                    <div className="font-bold text-base mb-2 font-family-arial">{intl.formatMessage({ id: 'who_is_responsible_for_this?', defaultMessage: 'who_is_responsible_for_this?' })}</div>
                                    <div className="flex">
                                        <div className="w-full">
                                            <Dropdown
                                                testId={`${testId}-responsiblr-evidence-later-dropdown`}
                                                options={[...[initialOption], ...evidenceLaterDropdownList]}
                                                value={userData.userId}
                                                onChange={(value: string) =>
                                                    handleLaterDropDown(value)
                                                }
                                                error={errObj.dropDown?.message}
                                            />
                                        </div>
                                    </div>
                                    {errObj.dropDown?.message ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                                            <span>{errObj.dropDown.message}</span>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                {decodedUserInfo?.payload.planningMethod === 1 ?
                                    <div>
                                        <div className="font-bold text-base mt-10 mb-2 font-family-arial">{intl.formatMessage({ id: 'when_should_this_be_done?', defaultMessage: 'when_should_this_be_done?' })}</div>
                                        <div>
                                            <DatePicker locale={decodedUserInfo?.payload.prefLang} disabled={!((role === "administrator") || (role === "editor"))} className='customDatePicker bg-zinc-50 px-2 border rounded-lg w-[180px] h-12 relative sm:min-w-full' calendarIcon={<img className={`${(role === "administrator") || (role === "editor") ? 'pl-[10px]' : 'pl-[0px]'}`} data-testid={`${testId}-calender-img`} src={calenderIcon} alt='' />} monthPlaceholder='mm' dayPlaceholder='dd' yearPlaceholder='yyyy' value={evidenceDoneDate} onChange={(value: any) => handleEvidenceDoneDate(value)} format={'dd-MM-yyyy'} />
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </>
                        }
                    </>}
            </div>
        </>
    );
}