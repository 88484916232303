import {
    AxiosError,
} from 'axios';
import jwtDecode from 'jwt-decode';
import { put, call, takeLatest } from 'redux-saga/effects';
import { appContainerCreators, appContainerTypes } from './AppReducer';
import { languageProviderActions } from './containers/LanguageProvider/reducer';
import { userInfoCall } from './services/AppService';

type UserInfoType = {
    refreshToken: string;
}

const {
    GET_USER_INFO,
} = appContainerTypes;

type ResponseTypeUserInfo = {
    accessToken: string,
    expiresIn: number,
    refreshToken: string,
}

export function* getUserInfoData(data: UserInfoType) {
    try {
        const response: ResponseTypeUserInfo = yield call(userInfoCall, {
            refreshToken: data.refreshToken
        });
        const decodedInfo: DecodedUserInfo | null = jwtDecode(response?.accessToken)
        yield put(languageProviderActions.changeLocale(decodedInfo?.payload.prefLang));
        yield put(appContainerCreators.updateUserInfo(response))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(appContainerCreators.failureUserInfo(e.response?.data.errors[0].message));
        } else {
            yield put(appContainerCreators.failureUserInfo("Something went wrong, please try again later!"));
        }
    }
}


export default function* appContainerSaga() {
    // @ts-ignore
    yield takeLatest(GET_USER_INFO, getUserInfoData);
}
