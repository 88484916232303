// @ts-ignore
// import Slider from "rc-slider";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from "../../components/StyledButton";
import CloseIcon from "../../assets/icons/close-icon.svg";
import InfoQIcon from "../../assets/icons/info-q-icon.svg";
import { listOptionsContainerCreators } from "../OrganisationContainer/reducer";
import { selectDecodedUserInfo } from '../../AppSelector';
import InfoCard from "../../components/InfoCard";
import { biaContainerCreators } from "./biaReducer";
import { ContentType } from "@onesixeight-nl/classes";
import Container from "../../components/Container/Container";
import Slider, { Formatter } from "antd/es/slider";
import { BiaOverview } from "../SoftwareContainer/SoftwareFirstScreen";
import { redirectUrlCreators } from "../LoginContainer/reducer";
import Modal from "../../components/Modal/Modal";

const testId = `bia-edit`;

type InfoDetailsAttributes = {
    InfoText: string;
    SubText: string;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    locale: string;
    Video: InfoCardContent;
    Image: InfoCardContent;
}

type InfoCardContent = {
    data?: Data | null;
}
type Data = {
    id: number;
    attributes: Attributes;
}

type Attributes = {
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: Formats;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: null;
    provider: string;
    provider_metadata: null;
    createdAt: Date;
    updatedAt: Date;
}

type Formats = {
    small: Medium;
    medium: Medium;
    thumbnail: Medium;
}

type Medium = {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    path: null;
    size: number;
    width: number;
    height: number;
}


export type InfoDetails = {
    id: number;
    attributes: InfoDetailsAttributes;
}

export default function EditBiaContainer() {

    // hooks
    const intl = useIntl();
    const { id } = useParams();
    const location = useLocation();
    const [singleBiaObj, setSingleBiaObj] = useState<BiaOverview>()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const biaContentSelector = useSelector((state: RootState) => state.biaContainer);
    const biaSelector = useSelector((state: RootState) => state.listOptions);

    // states
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

    const [directSliderValue, setDirectSliderValue] = useState<number>(25);

    const [fourHoursSliderValue, setFourHoursSliderValue] = useState<number>(25);

    const [oneDaySliderValue, setOneDaySliderValue] = useState<number>(25);

    const [twoDaySliderValue, setTwoDaySliderValue] = useState<number>(25);

    const [oneWeekSliderValue, setOneWeekSliderValue] = useState<number>(25);

    const [oneMonthSliderValue, setOneMonthSliderValue] = useState<number>(25);

    const [lastValue, setLastValue] = useState<number | undefined>();

    const sliderMinValueLabel = intl.formatMessage({ id: 'none', defaultMessage: 'none' });

    const sliderMaxValueLabel = intl.formatMessage({ id: 'critical', defaultMessage: 'critical' });

    const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);
  /* istanbul ignore next */
    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        dispatch(redirectUrlCreators.clearRedirectUrl())
        dispatch(listOptionsContainerCreators.requestGetProcesses());
    }, [])
   /* istanbul ignore next */
    useEffect(() => {
        if (biaSelector && biaSelector.processes && biaSelector.processes.length && id) {
            const currentBiaObj = biaSelector.processes.find((ele: any) => ele?.processId === id)
            setSingleBiaObj(currentBiaObj);
            if (currentBiaObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [biaSelector.processes, id])
/* istanbul ignore next */
    useEffect(() => {
        if (singleBiaObj?.BIA0h === 0 || singleBiaObj?.BIA0h === null) {
            setLastValue(125);
            setDirectSliderValue(25);
            setFourHoursSliderValue(25);
            setOneDaySliderValue(25);
            setTwoDaySliderValue(25);
            setOneWeekSliderValue(25);
            setOneMonthSliderValue(25);
        } else {
            setLastValue(125);
            if (singleBiaObj) {
                setDirectSliderValue(singleBiaObj?.BIA0h * 25);
                setFourHoursSliderValue(singleBiaObj?.BIA4h * 25);
                setOneDaySliderValue(singleBiaObj?.BIA24h * 25);
                setTwoDaySliderValue(singleBiaObj?.BIA72h * 25);
                setOneWeekSliderValue(singleBiaObj?.BIA168h * 25);
                setOneMonthSliderValue(singleBiaObj?.BIA720h * 25);
            }
        }
    }, [singleBiaObj])

    useEffect(() => {
        if (showMoreInfo && decodedUserInfo) {
            dispatch(biaContainerCreators.requestGetBiaHelpText(ContentType.BIA, decodedUserInfo));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMoreInfo, decodedUserInfo])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (biaContentSelector && biaContentSelector.biaHelpText) {
            setInfoDetails(biaContentSelector.biaHelpText?.data);
        }
    }, [biaContentSelector])

    //@ts-ignore
    const formatter: null | Formatter | any = (value: number) => handleToolTip(value);

    function handleToolTip(value: any) {
        if (value === 25) {
            return intl.formatMessage({ id: 'none', defaultMessage: 'none' })
        }
        else if (value === 50) {
            return intl.formatMessage({ id: 'low', defaultMessage: 'low' })
        }
        else if (value === 75) {
            return intl.formatMessage({ id: 'bia_medium', defaultMessage: 'bia_medium' })
        }
        else if (value === 100) {
            return intl.formatMessage({ id: 'high', defaultMessage: 'high' })
        }
        else {
            return intl.formatMessage({ id: 'critical', defaultMessage: 'critical' })
        }
    }

    function handleShowMoreInfo() {
        setShowMoreInfo(!showMoreInfo);
    }

    // Direct Slider onChange
    /* istanbul ignore next */
    function handleDirectSlider(value: number) {
        if (directSliderValue < value) {
            if (value < Number(lastValue)) {
                setDirectSliderValue(value);
                if (fourHoursSliderValue < value) {
                    setFourHoursSliderValue(value);
                }
                if (oneDaySliderValue < value) {
                    setOneDaySliderValue(value);
                }
                if (twoDaySliderValue < value) {
                    setTwoDaySliderValue(value);
                }
                if (oneWeekSliderValue < value) {
                    setOneWeekSliderValue(value);
                }
                if (oneMonthSliderValue < value) {
                    setOneMonthSliderValue(value);
                }
            } else {
                setLastValue(value);
                setDirectSliderValue(value);
                setFourHoursSliderValue(value);
                setOneDaySliderValue(value);
                setTwoDaySliderValue(value);
                setOneWeekSliderValue(value);
                setOneMonthSliderValue(value);
            }
        } else {
            setDirectSliderValue(value);
        }
    }

    // Four Hours Slider onChange
    /* istanbul ignore next */
    function handleFourHoursSlider(value: number) {
        if (fourHoursSliderValue < value) {
            if (value < Number(lastValue)) {
                setFourHoursSliderValue(value);
                if (oneDaySliderValue < value) {
                    setOneDaySliderValue(value);
                }
                if (twoDaySliderValue < value) {
                    setTwoDaySliderValue(value);
                }
                if (oneWeekSliderValue < value) {
                    setOneWeekSliderValue(value);
                }
                if (oneMonthSliderValue < value) {
                    setOneMonthSliderValue(value);
                }
            } else {
                setLastValue(value);
                setFourHoursSliderValue(value);
                setOneDaySliderValue(value);
                setTwoDaySliderValue(value);
                setOneWeekSliderValue(value);
                setOneMonthSliderValue(value);
            }
        } else {
            if (directSliderValue < value) {
                setFourHoursSliderValue(value);
            } else {
                setFourHoursSliderValue(directSliderValue);
            }
        }
    }

    // 24 Hours Slider onChange
    /* istanbul ignore next */
    function handleOneDaySlider(value: number) {
        if (oneDaySliderValue < value) {
            if (value < Number(lastValue)) {
                setOneDaySliderValue(value);
                if (twoDaySliderValue < value) {
                    setTwoDaySliderValue(value);
                }
                if (oneWeekSliderValue < value) {
                    setOneWeekSliderValue(value);
                }
                if (oneMonthSliderValue < value) {
                    setOneMonthSliderValue(value);
                }
            } else {
                setLastValue(value);
                setOneDaySliderValue(value);
                setTwoDaySliderValue(value);
                setOneWeekSliderValue(value);
                setOneMonthSliderValue(value);
            }
        } else {
            if (fourHoursSliderValue < value) {
                setOneDaySliderValue(value);
            } else {
                setOneDaySliderValue(fourHoursSliderValue);
            }
        }
    }

    // 48 Hours Slider onChange
    /* istanbul ignore next */
    function handleTwoDaySlider(value: number) {
        if (twoDaySliderValue < value) {
            if (value < Number(lastValue)) {
                setTwoDaySliderValue(value);
                if (oneWeekSliderValue < value) {
                    setOneWeekSliderValue(value);
                }
                if (oneMonthSliderValue < value) {
                    setOneMonthSliderValue(value);
                }
            } else {
                setLastValue(value);
                setTwoDaySliderValue(value);
                setOneWeekSliderValue(value);
                setOneMonthSliderValue(value);
            }
        } else {
            if (oneDaySliderValue < value) {
                setTwoDaySliderValue(value);
            } else {
                setTwoDaySliderValue(oneDaySliderValue);
            }
        }
    }

    // 1 Week Slider onChange
    /* istanbul ignore next */
    function handleOneWeekSlider(value: number) {
        if (oneWeekSliderValue < value) {
            if (value < Number(lastValue)) {
                setOneWeekSliderValue(value);
                if (oneMonthSliderValue < value) {
                    setOneMonthSliderValue(value);
                }
            } else {
                setLastValue(value);
                setOneWeekSliderValue(value);
                setOneMonthSliderValue(value);
            }
        } else {
            if (twoDaySliderValue < value) {
                setOneWeekSliderValue(value);
            } else {
                setOneWeekSliderValue(twoDaySliderValue);
            }
        }
    }

    // 1 Month Slider onChange
    /* istanbul ignore next */
    function handleOneMonthSlider(value: number) {
        if (oneMonthSliderValue as number < value) {
            if (value as number < Number(lastValue)) {
                setOneMonthSliderValue(value);
            } else {
                setLastValue(value);
                setOneMonthSliderValue(value);
            }
        } else {
            if (oneWeekSliderValue as number < value) {
                setOneMonthSliderValue(value);
            } else {
                setOneMonthSliderValue(oneWeekSliderValue);
            }
        }
    }
/* istanbul ignore next */
    function handleBiaSliders() {
        const payload = {
            processListId: singleBiaObj?.processListId,
            nameWhenOther: singleBiaObj?.nameWhenOther,
            responsible: singleBiaObj?.responsible,
            BIA0h: directSliderValue / 25,
            BIA4h: fourHoursSliderValue / 25,
            BIA24h: oneDaySliderValue / 25,
            BIA72h: twoDaySliderValue / 25,
            BIA168h: oneWeekSliderValue / 25,
            BIA720h: oneMonthSliderValue / 25
        }
        dispatch(listOptionsContainerCreators.requestPutProcess(id, payload, navigate));
    }
   /* istanbul ignore next */
    const getColor = (sliderValue: number) => {
        if (sliderValue === 25) {
            return "direct-track-color"
        } else if (sliderValue === 50) {
            return "four-hours-track-color"
        } else if (sliderValue === 75) {
            return "one-day-track-color"
        } else if (sliderValue === 100) {
            return "one-week-track-color"
        } else if (sliderValue === 125) {
            return "one-month-track-color"
        } else {
            return ""
        }
    }

    return (
        <>
            <div className="flex flex-col">
                <Container>
                    <div className="m-auto w-full pb-7 align-middle items-center flex justify-between border-b border-b-[#C1C1C1] sm:m-0 sm:pb-6">
                        <div>
                            <div className="font-semibold text-xl text-[#535353] mb-1">{intl.formatMessage({ id: 'impact_analysis', defaultMessage: 'impact_analysis' })}</div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-10 mb-8">
                        <div>
                            <div className="text-[#121212] text-[32px] mb-[10] font-semibold">{intl.formatMessage({ id: 'bia_process_question', defaultMessage: 'bia_process_question' })} {singleBiaObj?.name || singleBiaObj?.nameWhenOther}</div>
                        </div>
                        <div className="relative">
                            <div data-testid={`${testId}-show-more-info-bia-edit`} onClick={() => handleShowMoreInfo()} className={` mb-[25px] ml-[31px] cursor-pointer flex items-center justify-center border rounded-full ${showMoreInfo ? 'border-[#535353]' : 'border-[#959595]'} py-1 px-3`}>
                                <div className={`mr-1 mb-[2px] font-normal text-xs ${showMoreInfo ? 'text-[#535353]' : 'text-[#959595]'}`}>{intl.formatMessage({ id: 'info', defaultMessage: 'info' })}</div>
                                {showMoreInfo ?
                                    <img data-testid={`${testId}-close-icon-bia-edit`} className="w-[12px] h-[12px]" src={CloseIcon} alt='' />
                                    :
                                    <img data-testid={`${testId}-info-icon-bia-edit`} className="w-[12px] h-[12px]" src={InfoQIcon} alt='' />
                                }
                            </div>
                            {showMoreInfo &&
                                <div className="absolute right-0 z-[1] flex flex-col min-h-max">
                                    {infoDetails.map((infoCard, index) => (
                                        <InfoCard testId={`${testId}-info-details-bia-edit-${index}`} key={`${testId}-info-details-bia-edit-${infoCard.id}`} infoCard={infoCard} />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="sm:w-full w-[464px] border border-[#EDEDED] rounded-lg bg-white p-9 sm:p-5">
                        <div className="mb-8">
                            <div className="font-bold text-base mb-2">{intl.formatMessage({ id: 'direct', defaultMessage: 'direct' })}</div>
                            {/* Slider */}
                            <Slider disabled={!((role === "administrator") || (role === "editor"))} className={`${getColor(directSliderValue)} `} defaultValue={25} value={directSliderValue} onChange={(val: number) => handleDirectSlider(val)} step={25} dots min={25} max={125}
                                marks={{
                                    25: { style: { marginLeft: 0, left: 0, marginTop: '5px', transform: 'translateX(0%)', }, label: <div style={{ textAlign: "start", color: "#666" }}>{sliderMinValueLabel}</div> },
                                    125: { style: { left: 'auto', marginLeft: 0, right: 0, transform: 'translateX(0%)', marginTop: '5px' }, label: <div style={{ textAlign: "end", color: "#666" }} >{sliderMaxValueLabel}</div> }
                                }}
                                tooltip={{ formatter }}

                            />
                        </div>

                        <div className="mb-8">
                            <div className="font-bold text-base mb-2">{intl.formatMessage({ id: '4_hours', defaultMessage: '4_hours' })}</div>
                            {/* Slider */}
                            <Slider disabled={!((role === "administrator") || (role === "editor"))} className={getColor(fourHoursSliderValue)} defaultValue={25} value={fourHoursSliderValue} onChange={(val: number) => handleFourHoursSlider(val)} step={25} dots min={25} max={125}
                                marks={{
                                    25: { style: { marginLeft: 0, left: 0, marginTop: '5px', transform: 'translateX(0%)', }, label: <div style={{ textAlign: "start", color: "#666" }}>{sliderMinValueLabel}</div> },
                                    125: { style: { marginLeft: 0, right: 0, transform: 'translateX(0%)', left: 'auto', marginTop: '5px' }, label: <div style={{ textAlign: "end", color: "#666" }} >{sliderMaxValueLabel}</div> }
                                }}
                                tooltip={{ formatter }}
                            />
                        </div>

                        <div className="mb-8">
                            <div className="font-bold text-base mb-2">{intl.formatMessage({ id: '24_hours', defaultMessage: '24_hours' })}</div>
                            {/* Slider */}
                            <Slider disabled={!((role === "administrator") || (role === "editor"))} className={getColor(oneDaySliderValue)} defaultValue={25} value={oneDaySliderValue} onChange={(val: number) => handleOneDaySlider(val)} step={25} dots min={25} max={125}
                                marks={{
                                    25: { style: { marginLeft: 0, left: 0, marginTop: '5px', transform: 'translateX(0%)', }, label: <div style={{ textAlign: "start", color: "#666" }}>{sliderMinValueLabel}</div> },
                                    125: { style: { marginLeft: 0, right: 0, transform: 'translateX(0%)', left: 'auto', marginTop: '5px' }, label: <div style={{ textAlign: "end", color: "#666" }} >{sliderMaxValueLabel}</div> }
                                }}
                                tooltip={{ formatter }}
                            />
                        </div>

                        <div className="mb-8">
                            <div className="font-bold text-base mb-2">{intl.formatMessage({ id: '48_hours', defaultMessage: '48_hours' })}</div>
                            {/* Slider */}
                            <Slider disabled={!((role === "administrator") || (role === "editor"))} className={getColor(twoDaySliderValue)} defaultValue={25} value={twoDaySliderValue} onChange={(val: number) => handleTwoDaySlider(val)} step={25} dots min={25} max={125}
                                marks={{
                                    25: { style: { marginLeft: 0, left: 0, marginTop: '5px', transform: 'translateX(0%)', }, label: <div style={{ textAlign: "start", color: "#666" }}>{sliderMinValueLabel}</div> },
                                    125: { style: { marginLeft: 0, right: 0, transform: 'translateX(0%)', left: 'auto', marginTop: '5px' }, label: <div style={{ textAlign: "end", color: "#666" }} >{sliderMaxValueLabel}</div> }
                                }}
                                tooltip={{ formatter }}
                            />
                        </div>

                        <div className="mb-8">
                            <div className="font-bold text-base mb-2">{intl.formatMessage({ id: '1_week', defaultMessage: '1_week' })}</div>
                            {/* Slider */}
                            <Slider disabled={!((role === "administrator") || (role === "editor"))} className={getColor(oneWeekSliderValue)} defaultValue={25} value={oneWeekSliderValue} onChange={(val: number) => handleOneWeekSlider(val)} step={25} dots min={25} max={125}
                                marks={{
                                    25: { style: { marginLeft: 0, left: 0, marginTop: '5px', transform: 'translateX(0%)', }, label: <div style={{ textAlign: "start", color: "#666" }}>{sliderMinValueLabel}</div> },
                                    125: { style: { marginLeft: 0, right: 0, transform: 'translateX(0%)', left: 'auto', marginTop: '5px' }, label: <div style={{ textAlign: "end", color: "#666" }} >{sliderMaxValueLabel}</div> }
                                }}
                                tooltip={{ formatter }}
                            />
                        </div>

                        <div>
                            <div className="font-bold text-base mb-2">{intl.formatMessage({ id: '1_month', defaultMessage: '1_month' })}</div>
                            {/* Slider */}
                            <Slider disabled={!((role === "administrator") || (role === "editor"))} className={getColor(oneMonthSliderValue)} defaultValue={25} value={oneMonthSliderValue} onChange={(val: number) => handleOneMonthSlider(val)} step={25} dots min={25} max={125}
                                marks={{
                                    25: { style: { marginLeft: 0, left: 0, marginTop: '5px', transform: 'translateX(0%)', }, label: <div style={{ textAlign: "start", color: "#666" }}>{sliderMinValueLabel}</div> },
                                    125: { style: { marginLeft: 0, right: 0, transform: 'translateX(0%)', left: 'auto', marginTop: '5px' }, label: <div style={{ textAlign: "end", color: "#666" }} >{sliderMaxValueLabel}</div> }
                                }}
                                tooltip={{ formatter }}
                            />
                        </div>
                    </div>
                    <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                        <StyledButton
                            testId={`${testId}-cancel-btn-bia-edit`}
                            width={'w-48 sm:w-full'}
                            align={'justify-start'}
                            text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}
                            color={'text-[#535353]'}
                            flexReverse={'flex-row-reverse'}
                            backgroundColor={'bg-[#EDEDED]'}
                            onClick={() => navigate(`/bia?isCancel=0`)}
                        />
                        {((role === "administrator") || (role === "editor")) ?

                            <StyledButton
                                testId={`${testId}-save-btn-bia-edit`}
                                onClick={() => handleBiaSliders()}
                                buttonClass={'PrimoryBtn'}
                                width={'w-48 sm:w-full'}
                                align={'justify-end'}
                                text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })}
                                color={'text-white'}
                                backgroundColor={'bg-black'}
                            /> : <></>}
                    </div>
                </Container>
                <Modal show={isWromgOrgModal} onClose={() => ""}>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                    </div>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                    </div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-goto-dashboard`}
                            onClick={() => handleWrongOrgModel()}
                            buttonClass={'PrimoryBtn'}
                            width={"w-[220px] sm:w-40"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                            color={"text-white mr-3 text-base"}
                            iconType={'home'}
                            backgroundColor={"bg-black"}
                        />
                    </div>
                </Modal>
            </div>
        </>
    )
}







