import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { PlanList } from '../../containers/PlansContainer';
import { subscriptionContainerCreators } from '../../containers/SubscriptionContainer/subscriptionReducer';
import Container from "../Container/Container";
import StyledButton from '../StyledButton';
import debounce from "lodash/debounce";
import CloseIcon from "../../assets/icons/close-icon.svg";
import { useNavigate } from "react-router-dom";
import { SubscriptionData } from '../../containers/SubscriptionContainer';

type Props = {
    isPlanStarted: boolean;
    planList: any;
    singleProgram: PlanList | undefined;
    handleCancelClick: () => void;
    testId?: string;
    upgradeSubscription?: boolean;
    manualRenewal?: boolean;
    subscriptionData?: SubscriptionData;
}

type PlansDropdownList = {
    label: string;
    value: string,
}

type ErrorObject = {
    discountCode: string | undefined;
}

type Accountant = {
    firstName: string;
    lastName: string;
}

type Insuranceadvisor = {
    firstName: string;
    lastName: string;
}

type DiscountObj = {
    code: string;
    discountId: string;
    initialPaymentOnly: boolean;
    percentage: number;
    plan?: planObject;
}

type planObject = {
    planId: string;
    name: number;
    price: number;
    created: Date | string;
    subscriptionState: number;
    yearlyFee: number;
    year1Fee: number;
    extraMonths: number;
    vat: number;
    firstPayment: number;
}

export default function SelectPlan(props: Props) {

    const { isPlanStarted, planList, singleProgram, handleCancelClick, testId = "select-plan", upgradeSubscription, manualRenewal, subscriptionData } = props;
    const subscriptionSelector = useSelector((state: RootState) => state.subscriptionContainer);

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [planData, setPlanData] = useState<PlanList>({
        planId: '',
        name: 1,
        price: 0,
        created: '',
        subscriptionState: 0,
        yearlyFee: 0,
        year1Fee: 0,
        extraMonths: 0,
        vat: 0,
        firstPayment: 0,
    });
    // eslint-disable-next-line
    const [planDropdownList, setPlanDropdownList] = useState<PlansDropdownList[]>([]);
    const [discountCode, setDiscountCode] = useState<string>();
    const [discountObj, setDiscountObj] = useState<DiscountObj | null>();
    // eslint-disable-next-line
    const [discountPercentage, setDiscountPercentage] = useState<number | null>(null);
    const [accountant, setAccountant] = useState<Accountant | null>();
    const [insuranceadvisor, setInsuranceadvisor] = useState<Insuranceadvisor | null>();
    const [errObj, setErrObj] = useState<ErrorObject>({
        discountCode: undefined,
    });
    const [isAccssessChecked, setIsAccssessChecked] = useState<boolean>(false);
    const [isShowVoucherBlock, setIsShowVoucherBlock] = useState<boolean>(false);
    const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState<boolean>(false);
    const [isVoucherCheckBoxChecked, setIsVoucherCheckBoxChecked] = useState<boolean>(false);
    const [subscriptionId, setSubscriptionId] = useState<string>("");
    /* istanbul ignore next */
    useEffect(() => {
        if (planList && planList.length > 0) {
            let planListArray = planList.map((plan: PlanList) => {
                return {
                    label: intl.formatMessage({ id: `plan_${plan.name}`, defaultMessage: `plan_${plan.name}` }),
                    value: plan.planId
                }
            });
            setPlanDropdownList(planListArray);
            if (singleProgram) {
                setPlanData({ ...singleProgram, planId: singleProgram?.planId });
            }

            if (subscriptionData && subscriptionData?.plan) {
                const currentPlan = planList.find((plan: PlanList) => plan.planId === subscriptionData?.plan.planId)
                setPlanData({ ...currentPlan })
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planList])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (upgradeSubscription) {
            dispatch(subscriptionContainerCreators.requestGetSubscription());
        }
    }, [upgradeSubscription])/* eslint-disable react-hooks/exhaustive-deps */
    /* istanbul ignore next */
    function discountErrMsg(error: string) {
        if (error === "Dicount not found/valid") {
            return intl.formatMessage({ id: 'voucher_code_not_found/valid', defaultMessage: 'voucher_code_not_found/valid' })
        } else {
            return error;
        }
    }

    useEffect(() => {
        if (subscriptionSelector && subscriptionSelector?.data && subscriptionSelector.discount === null) {
            setSubscriptionId(subscriptionSelector?.data?.subscriptionId);
            if (subscriptionSelector && subscriptionSelector?.data?.discount) {
                setDiscountObj(subscriptionSelector?.data?.discount);
                if (subscriptionSelector?.data?.discount?.percentage) {
                    setDiscountPercentage(subscriptionSelector?.data?.discount?.percentage);
                    setAccountant(subscriptionSelector?.data?.discount?.accountant)
                    setInsuranceadvisor(subscriptionSelector?.data?.discount?.insuranceadvisor)
                }
            }
        }
    }, [])/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore next */
    useEffect(() => {
        if (subscriptionSelector && subscriptionSelector.discount) {
            setDiscountObj(subscriptionSelector.discount);
            if (subscriptionSelector.discount.percentage !== 0) {
                setDiscountPercentage(subscriptionSelector?.discount?.percentage);
                setAccountant(subscriptionSelector?.discount?.accountant)
                setInsuranceadvisor(subscriptionSelector?.discount?.insuranceadvisor)
                setErrObj({
                    discountCode: undefined
                })
            } else if (subscriptionSelector?.discount?.percentage === 0) {
                setErrObj({
                    discountCode: intl.formatMessage({ id: "no_discount_availabel_please_check_your_voucher", defaultMessage: "no_discount_availabel_please_check_your_voucher" })
                })
            }
        } else {
            setAccountant(null);
            setInsuranceadvisor(null);
            setErrObj({
                discountCode: subscriptionSelector.error
            })
        }
    }, [subscriptionSelector, isPlanStarted])

    function handleIsAccssessChecked() {
        setIsAccssessChecked(!isAccssessChecked)
    }
    /* istanbul ignore next */
    function handleDiscountCode(value: string) {
        if (value === '') {
            setDiscountObj(subscriptionSelector?.data?.discount);
            setDiscountPercentage(subscriptionSelector?.data?.discount?.percentage);
            setAccountant(null);
            setInsuranceadvisor(null);
            setErrObj({
                discountCode: undefined
            })
        } else {
            dispatch(subscriptionContainerCreators.requestGetDiscountCode(value, (singleProgram?.planId || subscriptionData?.plan.planId)));
        }
    }

    const [debouncedCallApi] = useState(() => debounce((value) => handleDiscountCode(value), 1000));

    function delayedOnChange(value: string) {
        setDiscountCode(value);
        debouncedCallApi(value);
    }
    /* istanbul ignore next */
    function handleGetPlanClick() {
        if (!isTermsChecked) {
            setIsCheckBoxChecked(true)

        } else {
            setIsCheckBoxChecked(false)
            if (accountant || insuranceadvisor) {
                if (!isAccssessChecked) {

                    setIsVoucherCheckBoxChecked(true)
                } else {
                    setIsVoucherCheckBoxChecked(false)
                    const voucherCode = (discountCode === subscriptionSelector?.data?.discount?.code ? '' : discountCode)
                    const payload = {
                        planId: planData?.planId,
                        discountCode: discountCode !== '' ? voucherCode : '',
                        approval: (accountant || insuranceadvisor) ? true : false,
                        upgradedFrom: subscriptionId ? subscriptionId : ""
                    }
                    dispatch(subscriptionContainerCreators.requestPostSubscription(payload, handleCancelClick));
                }
            } else {
                setIsVoucherCheckBoxChecked(false)
                const voucherCode = (discountCode === subscriptionSelector?.data?.discount?.code ? '' : discountCode)
                const payload = {
                    planId: planData?.planId,
                    discountCode: discountCode !== '' ? voucherCode : '',
                    approval: (accountant || insuranceadvisor) ? true : false,
                    upgradedFrom: subscriptionId ? subscriptionId : ""
                }
                dispatch(subscriptionContainerCreators.requestPostSubscription(payload, handleCancelClick));
            }
        }
    }

    const handleCancelResetClick = () => {
        if (manualRenewal) {
            navigate("/subscription")
        } else {
            setDiscountObj(null);
            setDiscountPercentage(null);
            setAccountant(null);
            setInsuranceadvisor(null);
            setErrObj({
                discountCode: undefined
            })
            dispatch(subscriptionContainerCreators.requestGetSubscriptionPlans());
            handleCancelClick();
        }
    }

    const handleVoucherBlock = () => {
        setIsShowVoucherBlock(true);
    }

    const handleCloseVoucherBlock = () => {
        setDiscountCode('');
        setDiscountObj(subscriptionSelector?.data?.discount);
        setDiscountPercentage(subscriptionSelector?.data?.discount?.percentage);
        setAccountant(null);
        setInsuranceadvisor(null);
        setErrObj({
            discountCode: undefined
        })
        setIsShowVoucherBlock(false);
    }
    /* istanbul ignore next */
    function handleIsTermsChecked() {
        setIsTermsChecked(!isTermsChecked)
    }

    function subscriptionBtn() {
        if (upgradeSubscription) {
            return intl.formatMessage({ id: 'upgrade_subscription', defaultMessage: 'upgrade_subscription' })
        } else if (manualRenewal) {
            return intl.formatMessage({ id: 'renew_subscription', defaultMessage: 'renew_subscription' })
        } else {
            return intl.formatMessage({ id: 'get_plan', defaultMessage: 'get_plan' })
        }
    }

    function disObj() {
        return discountObj &&
            <>
                {(!discountObj.initialPaymentOnly) ?
                    <div className="flex text-base text-left w-full mt-6 mb-2 sm:text-xl">{intl.formatMessage({ id: `your_annual_fee_including_discount`, defaultMessage: 'your_annual_fee_including_discount' })} {`${discountObj.percentage}%`} {intl.formatMessage({ id: `discount2`, defaultMessage: 'discount2' })}: {`${'€ ' + (!discountObj?.plan ? planData?.yearlyFee : discountObj?.plan?.yearlyFee)}`}</div>
                    :
                    <div className="flex text-base text-left w-full mt-6 mb-2 sm:text-xl">{intl.formatMessage({ id: `your_first_year_fee_including_discount`, defaultMessage: 'your_first_year_fee_including_discount' })} {`${discountObj.percentage}%`} {intl.formatMessage({ id: `discount2`, defaultMessage: 'discount2' })}: {`${'€ ' + (!discountObj?.plan ? planData?.year1Fee : discountObj?.plan?.year1Fee)}`}. {intl.formatMessage({ id: `annual_fee_wef_year_two`, defaultMessage: 'annual_fee_wef_year_two' })}: {`${'€ ' + (!discountObj?.plan ? planData?.yearlyFee : discountObj?.plan?.yearlyFee)}`}</div>
                }
            </>
    }
    return (
        <>
            <div data-testid={testId}>
                <Container>
                    <div className='flex flex-col justify-center'>
                        <div className='max-w-[514px] w-full flex flex-col border bg-white rounded-lg mb-28 p-10'>

                            <div className="flex font-semibold text-2xl text-left w-full mb-3 sm:text-xl">{intl.formatMessage({ id: `plan_${planData?.name}`, defaultMessage: `plan_${planData?.name}` })}</div>
                            {manualRenewal ? <div className="flex text-base text-left w-full mb-6 sm:text-xl font-family-arial">{intl.formatMessage({ id: `manually_renew_first_text`, defaultMessage: `manually_renew_first_text${planData?.name}` })}</div>
                                :
                                <>
                                    {upgradeSubscription ?
                                        <div className="flex text-base text-left w-full mb-6 sm:text-xl font-family-arial">{intl.formatMessage({ id: `upgrade_plans_details_text_${planData?.name}`, defaultMessage: `upgrade_plans_details_text_${planData?.name}` })}</div>
                                        :
                                        <div className="flex text-base text-left w-full mb-6 sm:text-xl font-family-arial">{intl.formatMessage({ id: `plans_details_text_${planData?.name}`, defaultMessage: `plans_details_text_${planData?.name}` })}</div>}
                                </>
                            }
                            {isShowVoucherBlock ?
                                <div className='bg-white border rounded-lg p-5'>
                                    <div className='w-full flex items-center justify-end mb-2'>
                                        <img data-testid={`${testId}-select-plan-close-icon`} onClick={() => handleCloseVoucherBlock()} className="w-[20px] h-[20px] cursor-pointer" src={CloseIcon} alt='' />
                                    </div>
                                    <div className='font-semibold text-lg text-black mb-3'>
                                        {intl.formatMessage({ id: 'discount_code', defaultMessage: 'discount_code' })}
                                    </div>
                                    <div>
                                        <input data-testid={`${testId}-discount-code`} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.discountCode ? 'border-[red]' : ''}`} type='text' value={discountCode ?? ""} onChange={(e) => delayedOnChange(e.target.value)} />
                                        {errObj?.discountCode ?
                                            <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                                                <span>{discountErrMsg(errObj?.discountCode)}</span>
                                            </div>
                                            :
                                            <div className='mb-8'></div>
                                        }
                                    </div>

                                    {(accountant || insuranceadvisor) &&
                                        <>
                                            <div className='flex justify-center'>
                                                <input data-testid={`${testId}-isAccssessChecked-input`} id={'isAccssessChecked'} className={`!mt-[2px] max-w-[20px] h-[20px] border ${isVoucherCheckBoxChecked ? 'border-[red]' : 'border-[#EDEDED] hover:border-[#C1C1C1]'}`} type="checkbox" name="" checked={isAccssessChecked} onChange={() => handleIsAccssessChecked()} />
                                                <div className='w-full text-base text-left sm:text-xl font-family-arial ml-2'>{`${(accountant && (accountant.firstName + ' ' + accountant.lastName)) || (insuranceadvisor && (insuranceadvisor.firstName + ' ' + insuranceadvisor.lastName))} `}{intl.formatMessage({ id: 'read_access_to_my_buco_scan', defaultMessage: 'read_access_to_my_buco_scan' })}</div>
                                            </div>
                                            {(isVoucherCheckBoxChecked) ?
                                                <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>{intl.formatMessage({ id: 'please_check_read_access', defaultMessage: 'please_check_read_access' })}</div>
                                                :
                                                <></>
                                            }

                                        </>
                                    }
                                </div>
                                :
                                <div className='text-base font-family-arial'>{intl.formatMessage({ id: `plan_voucher_apply`, defaultMessage: `plan_voucher_apply` })} <span data-testid={`${testId}-voucher-block`} className='text-[#3BA8FC] cursor-pointer' onClick={() => handleVoucherBlock()}>{intl.formatMessage({ id: `discount_code_linktext`, defaultMessage: 'discount_code_linktext' })}</span>.</div>
                            }

                            {!discountObj?.percentage && <div className="flex text-base text-left w-full mt-6 mb-2 sm:text-xl font-family-arial">{intl.formatMessage({ id: `your_annual_fee`, defaultMessage: `your_annual_fee` })} {`${'€ ' + (!discountObj?.plan ? planData?.yearlyFee : discountObj?.plan?.yearlyFee)}`}</div>}
                            {disObj()}
                            <>
                                {/* <div className="flex text-base text-left w-full mt-6 mb-2 sm:text-xl font-family-arial">{intl.formatMessage({ id: `not_satisfied_cancel_your_subscription` })}</div> */}

                                {upgradeSubscription ?
                                    <div className="flex text-base text-left w-full mt-6 mb-2 sm:text-xl font-family-arial">{intl.formatMessage({ id: `upgrade_the_amount_you_will_be_charged`, defaultMessage: 'upgrade_the_amount_you_will_be_charged' })}: {`${'€ ' + (!discountObj?.plan ? planData?.firstPayment : discountObj?.plan?.firstPayment)}`}</div>
                                    :
                                    <div className="flex text-base text-left w-full mt-6 mb-2 sm:text-xl font-family-arial">{intl.formatMessage({ id: `the_amount_you_will_be_charged`, defaultMessage: 'the_amount_you_will_be_charged' })}: {`${'€ ' + (!discountObj?.plan ? planData?.firstPayment : discountObj?.plan?.firstPayment)}`}</div>
                                }

                                <div className="flex text-base text-left w-full mt-6 mb-2 sm:text-xl font-family-arial">{intl.formatMessage({ id: `automatic_annually_renewal`, defaultMessage: 'automatic_annually_renewal' })}</div>

                                <div className="flex text-base text-left w-full mt-6 mb-2 sm:text-xl">
                                    <div className='min-w-max'>
                                        <input data-testid={`${testId}-handle-term-checked`} id={'isTermsChecked'} className={`max-w-[20px] border ${isCheckBoxChecked ? 'border-[red]' : 'border-[#EDEDED] hover:border-[#C1C1C1]'}`} type="checkbox" checked={isTermsChecked} name="" onChange={() => handleIsTermsChecked()} />
                                    </div>
                                    <div className='mt-[-4px] ml-2 font-family-arial'>
                                        {intl.formatMessage({ id: 'i_authorize_BucoScan', defaultMessage: 'i_authorize_BucoScan' })} {" "}
                                        <a className='text-[#0052CC] font-family-arial underline' href="https://www.onesixeight.nl/voorwaarden" target="_blank" rel="noreferrer ugc nofollow">{intl.formatMessage({ id: 'active_subscription_paragraph_5', defaultMessage: 'active_subscription_paragraph_5' })}</a>
                                        {intl.formatMessage({ id: 'and2', defaultMessage: 'and2' })}
                                        <a className='text-[#0052CC] font-family-arial underline' href="https://www.onesixeight.nl/privacystatement" target="_blank" rel="noreferrer ugc nofollow">{intl.formatMessage({ id: 'privacy_statement', defaultMessage: 'privacy_statement' })}</a>.
                                    </div>
                                </div>
                                {(isCheckBoxChecked) ?
                                    <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>{intl.formatMessage({ id: 'please_check_term_conditions', defaultMessage: 'please_check_term_conditions' })}</div>
                                    :
                                    <></>
                                }

                            </>
                        </div>
                        <div className='w-full bottom-0 align-middle items-center flex justify-between'>
                            <StyledButton testId={`${testId}-cancel-button`} onClick={() => handleCancelResetClick()} buttonClass={'sm:px-2'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'back', defaultMessage: 'back' })} color={'text-[#535353]'} backgroundColor={'bg-[#EDEDED]'} />
                            <StyledButton testId={`${testId}-getPlan-button`} loading={subscriptionSelector?.loading} onClick={() => handleGetPlanClick()} buttonClass={'sm:px-2 PrimoryBtn'} width={'min-w-max w-56 sm:w-max'} align={'justify-end'} text={subscriptionBtn()} color={'text-white'} backgroundColor={'bg-black'} />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}