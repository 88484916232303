import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LanguageProvider from './containers/LanguageProvider';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const enTranslationMessages = require('./translations/en.json');
const nlTranslationMessages = require('./translations/nl.json');

root.render(
  <Provider store={store}>
    <LanguageProvider messages={{ en: enTranslationMessages, nl: nlTranslationMessages }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LanguageProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
