import { Client, ClientListOrg } from "../../containers/ProtectedContainer";

function clientOrg(selectedSingleOrg: ClientListOrg | undefined, clientOrgListItem: ClientListOrg) {
    return `${(selectedSingleOrg && clientOrgListItem.organizationId === selectedSingleOrg.organizationId) ? 'mb-6 sm:mb-3' : ''}`
}

function clientDetails(selectedSingleOrg: ClientListOrg | undefined, clientOrgListItem: ClientListOrg) {
    return `${(selectedSingleOrg && clientOrgListItem.organizationId === selectedSingleOrg.organizationId) ? 'border-[#535353]' : 'border-[#959595]'}`
}

function clientDetailsBtn(selectedSingleOrg: ClientListOrg | undefined, clientOrgListItem: ClientListOrg) {
    return `${(selectedSingleOrg && clientOrgListItem.organizationId === selectedSingleOrg.organizationId) ? 'text-[#535353]' : 'text-[#959595]'}`
}

function clientDataStyle(idx: number, clientData: Client[]) {
    return `${idx === 0 ? 'rounded-t-lg' : ''} ${idx + 1 === clientData.length ? 'rounded-b-lg' : ''}`
}

export { clientOrg, clientDetails, clientDetailsBtn, clientDataStyle }