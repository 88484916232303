import axios, { AxiosPromise } from 'axios';
import { getBaseURL } from '../utils';

let instance = axios.create({
    baseURL: getBaseURL(),
});
/* istanbul ignore next */
export const loginUserService = (data: object): AxiosPromise => instance.post(`/organizationservice/v1/auth/login`, data)
/* istanbul ignore next */
export const createUserService = (duplicateOrgIntentional:boolean, data: object): AxiosPromise => instance.post(`/organizationservice/v1/users?duplicateOrgIntentional=${duplicateOrgIntentional}`, data)
/* istanbul ignore next */
export const forgotPasswordService = (data: object): AxiosPromise => instance.post(`/organizationservice/v1/auth/resetpassword`, data)
/* istanbul ignore next */
export const verifyEmailService = (emailVerificationId: string): AxiosPromise => instance.delete(`/organizationservice/v1/emailverifications/${emailVerificationId}`)