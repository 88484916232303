import { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Container from "../../components/Container/Container";
import StyledButton from "../../components/StyledButton";
import schema from "../../validations/schema";
import { listOptionsContainerCreators } from '../../containers/OrganisationContainer/reducer';
import Dropdown from '../Dropdown';
import { PrefLanguageList } from '../../containers/LoginContainer';

type Users = {
    userId?: string,
    type?: string;
    firstName?: string,
    lastName?: string,
    email?: string,
    emailVerified?: boolean,
    mobilePhone?: string,
    prefferedLanguage?: string,
    created?: Date,
    modified?: Date
};

type Props = {
    editUserDetail: Users;
    onCancel: () => void;
    testId?: string
};

type ErrorMessage = {
    message: string
}

type ErrorObject = {
    firstName?: ErrorMessage,
    lastName?: ErrorMessage,
    email?: ErrorMessage,
    mobilePhone?: ErrorMessage,
    prefferedLanguage?: ErrorMessage,
};


export default function EditUsePage(props: Props) {

    // hooks
    const { editUserDetail, onCancel, testId = "edit-Use-Page" } = props;
    const dispatch = useDispatch();
    const intl = useIntl();

    const [userData, setUserData] = useState<Users>({
        userId: "",
        type: "",
        firstName: "",
        lastName: "",
        email: "",
        emailVerified: false,
        mobilePhone: "",
        prefferedLanguage: "",
    });

    const [errObj, setErrObj] = useState<ErrorObject>({
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        mobilePhone: undefined,
        prefferedLanguage: undefined,
    });

    const initialPrefLanguageList: PrefLanguageList[] = [
        { label: "Nederlands", value: "nl" },
        { label: "English", value: "en" },
    ]
    const prefLanguageList: PrefLanguageList[] = [...initialPrefLanguageList];

    useEffect(() => {
        if (editUserDetail) {
            setUserData(editUserDetail)
        }
    }, [editUserDetail])

    const handleUserInput = (key: string, value: string) => {
        setUserData({ ...userData, [key]: value });
    };

    function savedUser() {
        const firstNameErr = schema.firstName.validate(userData.firstName).error;
        const lastNameErr = schema.lastName.validate(userData.lastName).error;
        if (firstNameErr || lastNameErr) {
            setErrObj({
                firstName: firstNameErr?.message ? { message: intl.formatMessage({ id: firstNameErr?.message, defaultMessage: firstNameErr?.message }) } : undefined,
                lastName: lastNameErr?.message ? { message: intl.formatMessage({ id: lastNameErr?.message, defaultMessage: lastNameErr?.message }) } : undefined,
                email: undefined,
                mobilePhone: undefined,

            });
        } else {
            setErrObj({
                firstName: undefined,
                lastName: undefined,
                email: undefined,
                mobilePhone: undefined,
            });

            const payload = {
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                mobilePhone: userData.mobilePhone,
                password: "",
                prefferedLanguage: userData.prefferedLanguage,
            }
            dispatch(listOptionsContainerCreators.requestPutUsers(userData.userId, payload, dispatch, ''))
            onCancel();
        }
    };

    return (
        <div className="flex-grow-1 flex flex-col justify-between" data-testid={testId}>
            <Container>
                <div>
                    <div className="flex flex-col sm:flex-col">
                        <p className="my-8 max-w-xl text-2xl text-black font-semibold">
                            {intl.formatMessage({ id: "edit_user", defaultMessage: 'edit_user' })}
                        </p>
                        <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                            <div className="flex gap-8 sm:flex-col">
                                <div className='flex-1'>
                                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                        {intl.formatMessage({ id: "firstname", defaultMessage: 'firstname' })}
                                    </label>
                                    <input
                                        data-testid={`${testId}-first-name`}
                                        maxLength={100}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.firstName?.message ? 'border-[red]' : ''}`}
                                        value={userData?.firstName ?? ''}
                                        onChange={(event) =>
                                            handleUserInput("firstName", event.target.value)
                                        }
                                    />
                                    {errObj.firstName?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.firstName?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>

                                <div className="flex-1">
                                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                        {intl.formatMessage({ id: "last_name", defaultMessage: 'last_name' })}
                                    </label>
                                    <input
                                        data-testid={`${testId}-last-name`}
                                        maxLength={100}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.lastName?.message ? 'border-[red]' : ''}`}
                                        value={userData?.lastName ?? ""}
                                        onChange={(event) =>
                                            handleUserInput("lastName", event.target.value)
                                        }
                                    />
                                    {errObj.lastName?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.lastName?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>
                            </div>


                            <div className="flex gap-8">
                                <div className="flex-1">
                                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                        {intl.formatMessage({ id: "mobile_number", defaultMessage: 'mobile_number' })}
                                    </label>
                                    <input
                                        data-testid={`${testId}-mobile-number`}
                                        maxLength={12}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.mobilePhone?.message ? 'border-[red]' : ''}`}
                                        value={userData?.mobilePhone ?? ""}
                                        onChange={(event) =>
                                            handleUserInput("mobilePhone", event.target.value)
                                        }
                                    />
                                    {errObj.mobilePhone?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.mobilePhone?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>
                            </div>
                            <div className="flex gap-8">
                                <div className="flex flex-col max-w-[370px] w-full">
                                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                        {intl.formatMessage({ id: "preffered_language", defaultMessage: 'preffered_language' })}
                                    </label>
                                    <Dropdown
                                        isForceEnable={true}
                                        options={[...prefLanguageList]}
                                        value={userData.prefferedLanguage}
                                        onChange={(value) =>
                                            handleUserInput("prefferedLanguage", value)
                                        }
                                        error={errObj.prefferedLanguage?.message}
                                    />
                                    {errObj.prefferedLanguage?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.prefferedLanguage?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-cancel-btn`}
                        width={"w-48 sm:w-full"}
                        align={"justify-start"}
                        text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                        color={"text-[#535353]"}
                        flexReverse={"flex-row-reverse"}
                        backgroundColor={"bg-[#EDEDED]"}
                        onClick={() => onCancel()}
                    />
                    <StyledButton
                        testId={`${testId}-save-btn`}
                        buttonClass={'PrimoryBtn'}
                        width={"w-48 sm:w-full"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
                        color={"text-white"}
                        iconType={"arrow"}
                        backgroundColor={"bg-black"}
                        onClick={() => savedUser()}
                    />
                </div>
            </Container>
        </div>
    );
};