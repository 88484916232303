import SuccessIcon from '../../assets/images/success.svg';
import FailureIcon from '../../assets/images/failure.svg';
import { useIntl } from 'react-intl';

type Props = {
    text: string;
    iconType: string;
    color: string;
    showToast: boolean;
    onClick?: () => void;
    testId?: string;
}

export default function NotificationToast(props: Props) {

    const intl = useIntl();

    const {
        text,
        iconType,
        color,
        showToast,
        onClick,
        testId = 'notification-toast'
    } = props;

    const getIcon = () => {
        if (iconType === intl.formatMessage({ id: 'success', defaultMessage: 'success' })) {
            return SuccessIcon;
        }
        if (iconType === intl.formatMessage({ id: 'failure', defaultMessage: 'failure' })) {
            return FailureIcon;
        }
        return '';
    };

    const iconUrl = getIcon();

    const isShowToast = showToast ? showToast : false;

    return (
        <>
            {isShowToast ?
                <div data-testid={testId} id="toast-success" className="border top-32 right-5 fixed z-[9999999] duration-75 flex items-center p-4 mb-4 w-full max-w-max text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
                    <div className={`inline-flex flex-shrink-0 justify-center items-center w-8 h-8 ${color} rounded-lg`}>
                        {iconUrl ? <img className='w-5 h-5' src={iconUrl} alt='' /> : null}
                    </div>
                    <div className="ml-3 text-sm font-normal w-full max-w-[222px]">{text}</div>
                    <button onClick={() => onClick && onClick()} type="button" className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>
                :
                <></>
            }
        </>
    );
}
