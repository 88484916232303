import { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { selectLanguage } from '../LanguageProvider/selectors';
import { DEFAULT_LOCALE } from '../../i18n';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { selectUserInfo, selectDecodedUserInfo } from '../../AppSelector';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import routeConstants from '../../utils/routeConstants';
import {
  InitialStateType,
  loginContainerCreators,
  userContainerCreators
} from './reducer';
import schema from "../../validations/schema";
import ForgotPasswordLayout from '../../components/ForgotPasswordLayout/ForgotPasswordLayout';
import cookies from '../../utils/coockies';
import AuthHeader from "../../components/AuthHeader";
import AuthLayout from "../../components/AuthLayout";
import { languageProviderActions } from '../LanguageProvider/reducer';


const testId = `login-container`;

type ErrorMessage = {
  message: string
}

export type ErrorObject = {
  email?: ErrorMessage;
  password?: ErrorMessage;
  firstName?: ErrorMessage;
  lastName?: ErrorMessage;
  phoneNumber?: ErrorMessage;
  name?: ErrorMessage;
  kvKNumber?: ErrorMessage;
  street?: ErrorMessage;
  houseNumber?: ErrorMessage;
  houseNumberSuffix?: ErrorMessage;
  postalcode?: ErrorMessage;
  city?: ErrorMessage;
  country?: ErrorMessage;
  repeatPassword?: ErrorMessage;
};

export type PrefLanguageList = {
  label: string,
  value: string
};

export default function LoginContainer() {

  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const { inviteId, toastFromInvite, toastFromCreateAccount }: RootState = location?.state || {};
  const langSelector = useSelector(selectLanguage);

  const loginSelector: InitialStateType = useSelector((state: RootState) => state.loginContainer);

  const [activeTab, setActiveTab] = useState<number>(0);

  const navigate = useNavigate();

  //Login States
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errObj, setErrObj] = useState<ErrorObject>({
    email: undefined,
    password: undefined,
    firstName: undefined,
    lastName: undefined,
    phoneNumber: undefined,
    name: undefined,
    kvKNumber: undefined,
    street: undefined,
    houseNumber: undefined,
    houseNumberSuffix: undefined,
    postalcode: undefined,
    city: undefined,
    country: undefined,
    repeatPassword: undefined,
  });
  const [isShowPass, setIsShowPass] = useState<boolean>(true);
  //Create Account States
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [kvKNumber, setKvKNumber] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [houseNumber, setHouseNumber] = useState<string>('');
  const [houseNumberSuffix, setHouseNumberSuffix] = useState<string>("");
  const [postalcode, setPostalcode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [isShowRepeatPass, setIsShowRepeatPass] = useState<boolean>(true);
  const initialPrefLanguageList: PrefLanguageList[] = [
    { label: "Nederlands", value: "nl" },
    { label: "English", value: "en" },
  ]
  const [prefLanguageList, setPrefLanguageList] = useState<PrefLanguageList[]>([...initialPrefLanguageList]);
  const [isPasswordForgotten, setIsPasswordForgotten] = useState<boolean>(false);
  const [prefLanguage, setPrefLanguage] = useState<string>(DEFAULT_LOCALE);
  const [isOrgExist, setIsOrgExist] = useState<boolean>(false);

  const [showToast, setShowToast] = useState<boolean>(false);
  const [showLoginToast, setShowLoginToast] = useState<boolean>(false);
  const userInfo = useSelector(selectUserInfo);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const lang: string | null = searchParams.get('lang')
  //const decodedUserInfo = useSelector(selectDecodedUserInfo);

  useEffect(() => {
    setPrefLanguage(langSelector?.locale)
  }, [langSelector?.locale])
/* istanbul ignore next */
  useEffect(() => {
    if (lang === "en" || lang === "nl") {
      dispatch(languageProviderActions.changeLocale(lang));
    }/* eslint-disable react-hooks/exhaustive-deps */
  }, [lang])/* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (userInfo !== null) {
      setShowLoginToast(true);
      setTimeout(() => {
        //if (decodedUserInfo && decodedUserInfo?.payload && decodedUserInfo?.payload?.twoFa === 0) {
        //  navigate("/two-fa")
        //} else {
          //navigate(routeConstants.dashboard.route)
        //}
      }, 0);
    } else if (loginSelector.loginError) {
      setShowLoginToast(true);
      cookies.remove("email");
      cookies.remove("isRememberMe");
    }
  }, [userInfo, navigate, loginSelector]);
/* istanbul ignore next */
  useEffect(() => {
    if (toastFromInvite) {
      setShowToast(true);
      cookies.remove("email");
      cookies.remove("isRememberMe");
      setTimeout(() => {
        setShowToast(false);
        navigate(`/`, { state: { toastFromInvite: false } });
      }, 4000);
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore next */
  useEffect(() => {
    if (toastFromCreateAccount) {
      setShowToast(true);
      cookies.remove("email");
      cookies.remove("isRememberMe");
      setTimeout(() => {
        setShowToast(false);
        navigate(`/`, { state: { toastFromCreateAccount: false } });
      }, 4000);
    }
  }, [])/* eslint-disable react-hooks/exhaustive-deps */

  function onChangeCheckbox() {
    setIsRememberMe(!isRememberMe);
  };
/* istanbul ignore next */
  function handleLoginClick() {
    if (!email || !password || !isRememberMe) {
      cookies.remove("email");
      cookies.remove("isRememberMe");
    }
    else if (email && password && isRememberMe) {
      cookies.set("email", email);
      cookies.set("isRememberMe", isRememberMe);
    }

    const emailErr = schema.email.validate(email).error;
    const pwdErr = schema.logInPass.validate(password).error;
    if (emailErr || pwdErr) {
      setErrObj({
        email: emailErr?.message ? { message: intl.formatMessage({ id: emailErr?.message, defaultMessage: emailErr?.message }) } : undefined,
        password: pwdErr?.message ? { message: intl.formatMessage({ id: pwdErr?.message, defaultMessage: pwdErr?.message }) } : undefined
      });
    } else {
      setErrObj({ email: undefined, password: undefined });
      dispatch(loginContainerCreators.requestGetAuth({ email, password }, inviteId, intl));
    }
    setShowLoginToast(false);
  }

  function handleKeyPressForLogin(e: any) {
    if (e.key === 'Enter') {
      handleLoginClick();
    }
  }
/* istanbul ignore next */
  function handleCreateAccountClick() {
    const emailErr = schema.email.validate(email).error;
    const pwdErr = schema.password.validate(password).error;
    const firstNameErr = schema.firstName.validate(firstName).error;
    const lastNameErr = schema.lastName.validate(lastName).error;
    const phoneNumberErr = schema.phoneNumber.validate(phoneNumber).error;
    const nameErr = schema.name.validate(name).error;
    const kvKNumberErr = schema.kvKNumber.validate(kvKNumber).error;
    const streetErr = schema.street.validate(street).error;
    const houseNumberErr = schema.houseNumber.validate(houseNumber).error;
    const postalcodeErr = schema.postalcode.validate(postalcode).error;
    const cityErr = schema.city.validate(city).error;
    const repeatPasswordErr = schema.repeatPassword.validate(repeatPassword).error;
    if (emailErr || pwdErr || firstNameErr || lastNameErr || nameErr || phoneNumberErr || kvKNumberErr || streetErr || houseNumberErr || postalcodeErr || cityErr || repeatPasswordErr) {
      setErrObj({
        email: emailErr?.message ? { message: intl.formatMessage({ id: emailErr?.message, defaultMessage: emailErr?.message }) } : undefined,
        password: pwdErr?.message ? { message: intl.formatMessage({ id: pwdErr?.message, defaultMessage: pwdErr?.message }) } : undefined,
        firstName: firstNameErr?.message ? { message: intl.formatMessage({ id: firstNameErr?.message, defaultMessage: firstNameErr?.message }) } : undefined,
        lastName: lastNameErr?.message ? { message: intl.formatMessage({ id: lastNameErr?.message, defaultMessage: lastNameErr?.message }) } : undefined,
        name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined,
        kvKNumber: kvKNumberErr?.message ? { message: intl.formatMessage({ id: kvKNumberErr?.message, defaultMessage: kvKNumberErr?.message }) } : undefined,
        street: streetErr?.message ? { message: intl.formatMessage({ id: streetErr?.message, defaultMessage: streetErr?.message }) } : undefined,
        houseNumber: houseNumberErr?.message ? { message: intl.formatMessage({ id: houseNumberErr?.message, defaultMessage: houseNumberErr?.message }) } : undefined,
        houseNumberSuffix: undefined,
        postalcode: postalcodeErr?.message ? { message: intl.formatMessage({ id: postalcodeErr?.message, defaultMessage: postalcodeErr?.message }) } : undefined,
        city: cityErr?.message ? { message: intl.formatMessage({ id: cityErr?.message, defaultMessage: cityErr?.message }) } : undefined,
        country: undefined,
        repeatPassword: repeatPasswordErr?.message ? { message: intl.formatMessage({ id: repeatPasswordErr?.message, defaultMessage: repeatPasswordErr?.message }) } : undefined
      });
    } else {
      setErrObj({ email: undefined, password: undefined, firstName: undefined, lastName: undefined, name: undefined, phoneNumber: undefined, kvKNumber: undefined, street: undefined, houseNumber: undefined, houseNumberSuffix: undefined, postalcode: undefined, city: undefined, country: undefined, repeatPassword: undefined });
      dispatch(userContainerCreators.requestSetUser({ duplicateOrgIntentional: isOrgExist }, {
        user: { firstName, lastName, email, mobilePhone: phoneNumber, password, prefferedLanguage: prefLanguage },
        organization: { companyName: name, chamberofcommercenr: kvKNumber, street, houseNumber: Number(houseNumber), houseNumberSuffix, postalcode, city, country }
      }, setShowToast, setIsOrgExist, setIsFocus, setActiveTab));
    }
  }
/* istanbul ignore next */
  function handleKeyPressForCreateAccount(e: any) {
    if (e.key === 'Enter') {
      handleCreateAccountClick();
    }
  }

  function handleSendClick() {
    const emailErr = schema.email.validate(email).error;
    if (emailErr) {
      setErrObj({ email: emailErr?.message ? { message: intl.formatMessage({ id: emailErr?.message, defaultMessage: emailErr?.message }) } : undefined });
    } else {
      setErrObj({ email: undefined });
      dispatch(loginContainerCreators.requestPostEmail({ email }, setIsPasswordForgotten, setShowToast));
      dispatch(loginContainerCreators.setLoginErr())
      setShowLoginToast(false)
    }
  }
/* istanbul ignore next */
  if (isPasswordForgotten) {
    return <ForgotPasswordLayout email={email} setEmail={setEmail} setIsPasswordForgotten={setIsPasswordForgotten} errObj={errObj} handleSendClick={() => handleSendClick()} loading={loginSelector.loading} errorMessage={loginSelector.error} showToast={showToast} setShowToast={setShowToast} setShowLoginToast={setShowLoginToast} />
  }

  return (
    <>
      <div>
        <div>
          <AuthHeader activeTab={activeTab} setActiveTab={setActiveTab} testId={testId} />
        </div>
        <div className="flex items-center justify-center">
          <AuthLayout
            inviteId={inviteId}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            isPasswordForgotten={isPasswordForgotten}
            setIsPasswordForgotten={setIsPasswordForgotten}
            errObj={errObj}
            handleLoginClick={() => handleLoginClick()}
            isShowPass={isShowPass}
            setIsShowPass={setIsShowPass}
            loading={loginSelector.loading}
            errorMessage={loginSelector.loginError}
            createAccountErrorMessage={loginSelector.checkEmailError || loginSelector.createAccountErr}
            isOrgExist={isOrgExist}
            setIsOrgExist={setIsOrgExist}
            showToast={showToast}
            setShowToast={setShowToast}
            showLoginToast={showLoginToast}
            setShowLoginToast={setShowLoginToast}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            prefLanguage={prefLanguage}
            setPrefLanguage={setPrefLanguage}
            prefLanguageList={prefLanguageList}
            setPrefLanguageList={setPrefLanguageList}
            name={name}
            setName={setName}
            kvKNumber={kvKNumber}
            setKvKNumber={setKvKNumber}
            street={street}
            setStreet={setStreet}
            houseNumber={houseNumber}
            setHouseNumber={setHouseNumber}
            houseNumberSuffix={houseNumberSuffix}
            setHouseNumberSuffix={setHouseNumberSuffix}
            postalcode={postalcode}
            setPostalcode={setPostalcode}
            city={city}
            setCity={setCity}
            country={country}
            setCountry={setCountry}
            repeatPassword={repeatPassword}
            setRepeatPassword={setRepeatPassword}
            isShowRepeatPass={isShowRepeatPass}
            setIsShowRepeatPass={setIsShowRepeatPass}
            isFocus={isFocus}
            setIsFocus={setIsFocus}
            handleCreateAccountClick={() => handleCreateAccountClick()}
            onChangeCheckbox={() => onChangeCheckbox()}
            isRememberMe={isRememberMe}
            setIsRememberMe={setIsRememberMe}
            toastFromInvite={toastFromInvite}
            handleKeyPressForLogin={handleKeyPressForLogin}
            handleKeyPressForCreateAccount={handleKeyPressForCreateAccount}
            testId={testId}
          />
        </div>
      </div>
    </>
  );
}
