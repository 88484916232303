import { ReactNode } from "react";

const testId = `modal`;

type Props = {
    show: boolean;
    onClose: () => void;
    children?: ReactNode;
}

export default function Modal(props: Props) {

    const { show, onClose, children } = props;

    return (
        <>
            {show &&
                <div data-testid={`${testId}-popup-close`} onClick={() => onClose()} className="relative z-[1056]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <div data-testid={`${testId}-popup`} onClick={(e: any) => e.stopPropagation()} className="min-w-[464px] sm:p-4 sm:min-w-full relative bg-white p-10 rounded-lg text-left shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}