import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "../../assets/icons/edit-icon.svg";
import StyledButton from "../../components/StyledButton";
import Modal from '../../components/Modal/Modal';
import VideoJS from '../../components/VideoJS/video';
import videojs from 'video.js';
import { selectDecodedUserInfo } from '../../AppSelector';
import { clearLocationStatusCreators, locationContainerCreators } from '../LocationsContainer/locationReducer';
import Container from '../../components/Container/Container';
import GetIcons from '../../components/GetIcons/GetIcons';
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import LoadingIcon from '../../assets/icons/loading-icon';
import { Singlelocations } from '../HardwareContainer/Hardware';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";
import InfoIcon from "../../assets/icons/info-i-icon.svg";
import CloseIcon from "../../assets/icons/close-icon.svg";

const testId = `connectivity-container`;

export type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
}

type ConnectivityPopupVideoAttributes = {
    name: string;
    alternativeText: string;
    caption: string;
    width: null;
    height: null;
    formats: null;
    hash: string;
    ext: string;
    mime: string;
    size: string;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: null;
    createdAt: Date;
    updatedAt: Date;
}

type ConnectivityPopupVideo = {
    data: {
        id: number;
        attributes: ConnectivityPopupVideoAttributes;
    }
}

type ConnectivityPopupDetails = {
    Intro: string;
    Header: string;
    Body: string;
    Button: string;
    Button2: string;
    Link: string;
    Link2: string;
    Video: ConnectivityPopupVideo;
    createdAt: Date;
    updatedAt: Date;
    locale: string;
}

export type ConnectivitiesList = {
    connectivityId: string;
    connectivityListId: string;
    name: string;
    nameWhenOther: string;
    selected: boolean;
    created: Date | string;
    modified: Date | string;
    locationId: string;
    organizationId: string;
}

export default function ConnectivityContainer() {

    // hooks
    const intl = useIntl();
    const params = useParams();
    const { pathname, search, state } = useLocation();
    const { id } = params;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isConnectivity = pathname.includes(`/connectivity/${id}`);
    const isCancel = new URLSearchParams(search).get('isCancel');
    const { showPopup, from }: RootState = state || {}

    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;

    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const locationSelector = useSelector((state: RootState) => state.locationContainer);
    const connectivitySelector = useSelector((state: RootState) => state.locationContainer);

    // states
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>();

    const [singlelocations, setSinglelocations] = useState<Singlelocations>();
    const [connectivityCount, setConnectivityCount] = useState();
    const [locationsList, setLocationsList] = useState<Singlelocations[]>([]);

    const [connectivitiesList, setConnectivitiesList] = useState<ConnectivitiesList[]>([]);
    //const [connectivityQuestions, setConnectivityQuestions] = useState([]);
    const [isDeleteConnectivityModal, setIsDeleteConnectivityModal] = useState<boolean>(false);
    const [selectedConnectivity, setSelectedConnectivity] = useState<ConnectivitiesList>();

    const [isConnectivityModal, setIsConnectivityModal] = useState<boolean>(false);
    const [connectivityPopupDetails, setConnectivityPopupDetails] = useState<ConnectivityPopupDetails>();

    const playerRef = React.useRef(null);
    const isHttpsLink = connectivityPopupDetails?.Video?.data?.attributes?.url.startsWith('https://');
    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: isHttpsLink ? connectivityPopupDetails?.Video?.data?.attributes?.url : "https://" + connectivityPopupDetails?.Video?.data?.attributes?.url,
            type: 'video/mp4'
        }]
    };

    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        if (locationSelector?.status !== null && locationSelector?.status !== undefined && locationSelector?.method !== "delete" && locationSelector?.method !== null) {
            setIsRemoved(false);
            setIsSaved(true);
            setTimeCounter(1);
        } else if (locationSelector?.method !== null && locationSelector?.method !== undefined && locationSelector?.method === "delete" && (locationSelector?.method !== "post" || locationSelector?.method !== "put")) {
            setIsSaved(false);
            setIsRemoved(true);
            setTimeCounter(1);
            dispatch(clearLocationStatusCreators.clearLocationStatus());
        }
    }, [locationSelector?.status, locationSelector?.method])

    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    dispatch(clearLocationStatusCreators.clearLocationStatus());
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.riskCategory && riskAreaSelector.riskCategory.length && id) {
            const riskCategoryObj = riskAreaSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === id)
            setSingleRiskCategory(riskCategoryObj);
            if (riskCategoryObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [riskAreaSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (singleRiskCategory) {
            dispatch(locationContainerCreators.requestGetLocations(decodedUserInfo, dispatch, singleRiskCategory, isCancel, navigate, 'Connectivity', showPopup));
        }
    }, [singleRiskCategory]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.locations && locationSelector.locations.length > 0) {
            setLocationsList(locationSelector.locations)
            const availableConnectivityCount = locationSelector?.locations.find((ele: any) => ele.availableConnectivityCount > 0)
            setConnectivityCount(availableConnectivityCount)
        }

        if (locationSelector && locationSelector?.connectivityContent) {
            if (locationSelector?.connectivityContent?.data && locationSelector?.connectivityContent?.data[0] && isCancel !== '0') {
                setConnectivityPopupDetails(locationSelector?.connectivityContent?.data[0]?.attributes);
                setIsConnectivityModal(true);
            } else {
                setIsConnectivityModal(false);
                if (connectivityCount && isCancel !== '0') {
                    dispatch(locationContainerCreators.requestGetConnectivities(connectivityCount, singleRiskCategory, isCancel, navigate));
                }
            }
        } else {
            setIsConnectivityModal(false);
        }
    }, [locationSelector]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (singlelocations) {
            dispatch(locationContainerCreators.requestGetConnectivities(singlelocations, singleRiskCategory, isCancel, ''));
        }
    }, [singlelocations]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (connectivitySelector && connectivitySelector.connectivities && connectivitySelector.connectivities.length > 0) {
            const filteredConnectivities = connectivitySelector.connectivities.filter((ele: any) => ele.connectivityId)
            setConnectivitiesList(filteredConnectivities)
            //const filteredUndefinedHardwares = connectivitySelector.connectivities.filter((ele: any) => !ele.connectivityId)
            //setConnectivityQuestions(filteredUndefinedHardwares)
        } else {
            setConnectivitiesList([])
            //setConnectivityQuestions([])
        }
    }, [connectivitySelector])

    //functions

    function gotoEditConnectivity(connectivityObj: ConnectivitiesList) {
        navigate(`/connectivity/${id}/edit-connectivity/${singlelocations?.locationId}/${connectivityObj?.connectivityId}`, { state: { connectivityQuestions: connectivitySelector.connectivities, selectedLocations: singlelocations, editConnectivityObj: connectivityObj } });
    }

    function gotoAddConnectivity() {
        navigate(`/connectivity/${id}/add-connectivity/${singlelocations?.locationId}`, { state: { connectivityQuestions: connectivitySelector.connectivities, selectedLocations: singlelocations } });
    }

    function handleLocationShow(locationId: string) {
        setIsRemoved(false)
        if (locationId === singlelocations?.locationId) {
            setSinglelocations(undefined)
        } else {
            const locationNewList: Singlelocations[] = [...locationsList];
            const singleLocation = locationNewList.find((ele: Singlelocations) => ele.locationId === locationId)
            if (singleLocation) {
                setSinglelocations(singleLocation);
            }
        }
    }

    function handleConnectivityModalClose() {
        setIsConnectivityModal(false);
        if (connectivityCount && isCancel !== '0') {
            dispatch(locationContainerCreators.requestGetConnectivities(connectivityCount, singleRiskCategory, isCancel, navigate));
        }
    }

    function handleConnectivityAdd() {
        if (connectivityCount && isCancel !== '0') {
            dispatch(locationContainerCreators.requestGetConnectivities(connectivityCount, singleRiskCategory, isCancel, navigate));
        }
        setIsConnectivityModal(false);
    }
    /* istanbul ignore next */
    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });
        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    const goToPrevious = () => {
        navigate(`/hardware/${id}?isCancel=5`)
    }

    const goToNext = () => {
        navigate(`/software/${id}?isCancel=7`)
    }

    function getConnectivityStatus(item: ConnectivitiesList) {
        if (item.selected === true) {
            return intl.formatMessage({ id: 'on_this_location', defaultMessage: 'on_this_location' })
        } else {
            return intl.formatMessage({ id: 'not_on_this_location', defaultMessage: 'not_on_this_location' })
        }
    }

    function handleDeleteConnectivityModalOpen(item: ConnectivitiesList) {
        setSelectedConnectivity(item);
        setIsDeleteConnectivityModal(true);
    }

    function handleDeleteConnectivityModalClose() {
        setIsDeleteConnectivityModal(false);
    }

    const deleteConnectivityItem = () => {
        dispatch(locationContainerCreators.requestDeleteConnectivity(singlelocations?.locationId, selectedConnectivity?.connectivityId))
        setSinglelocations(undefined);
        setSelectedConnectivity(undefined);
        setIsDeleteConnectivityModal(false);
    }

    return (
        <>
            {locationSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div>
                    <Container>
                        <div className="flex-grow-1 flex flex-col justify-between">
                            <div>
                                <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0 sm:pb-6'>
                                    <div className="flex items-center font-semibold text-xl">
                                        <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                        <div className="ml-3 font-semibold text-xl text-[#535353]">
                                            {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                        </div>
                                    </div>
                                    {isSaved &&
                                        <div className="font-normal text-xs text-[#959595] text-end">
                                            <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                        </div>
                                    }
                                    {isRemoved &&
                                        <div className="font-normal text-xs text-[#959595] text-end">
                                            <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                                        </div>
                                    }
                                </div>

                                {/* Navbar */}
                                <div className="mb-10 w-full block" id="navbar-default">
                                    <ul className="flex sm:flex-wrap flex-row mt-[30px] text-xl font-semibold ">
                                        {singleRiskCategory?.riskListId === 'baa09e3c-e72e-4721-a3d0-77ac98e36400' &&
                                            <>
                                                <li data-testid={`${testId}-navigate-locations`} onClick={() => navigate(`/locations/${id}?isCancel=4`)}>
                                                    <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`}>{intl.formatMessage({ id: 'locations', defaultMessage: 'locations' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-hardware`} onClick={() => navigate(`/hardware/${id}?isCancel=5`)}>
                                                    <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`}>{intl.formatMessage({ id: 'hardware', defaultMessage: 'hardware' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-connectivity`} onClick={() => navigate(`/connectivity/${id}?isCancel=6`)}>
                                                    <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer ${isConnectivity && '!text-[#121212]'}`}>{intl.formatMessage({ id: 'connectivity', defaultMessage: 'connectivity' })}</span>
                                                </li>
                                                <li data-testid={`${testId}-navigate-software`} onClick={() => navigate(`/software/${id}?isCancel=7`)}>
                                                    <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`}>{intl.formatMessage({ id: 'software', defaultMessage: 'software' })}</span>
                                                </li>
                                            </>
                                        }

                                        <li data-testid={`${testId}-navigate-events`} onClick={() => navigate(`/events/${id}?isCancel=8`)}>
                                            <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`}>{intl.formatMessage({ id: 'events', defaultMessage: 'events' })}</span>
                                        </li>
                                        <li data-testid={`${testId}-navigate-mg`} onClick={() => navigate(`/risk-area/${id}?isCancel=9`)}>
                                            <span className={`min-w-max block mr-10 text-[#959595] hover:text-[#535353] cursor-pointer`} aria-current="page">{intl.formatMessage({ id: 'measure_groups', defaultMessage: 'measure_groups' })}</span>
                                        </li>

                                    </ul>
                                </div>

                                <div className="border w-full"></div>

                                <div className="my-10 sm:my-8 max-w-[952px]">
                                    <div className='mt-8'>
                                        <div className='mt-10 mb-[32px]'>
                                            <div className="font-semibold text-[32px] mb-[16px] sm:text-2xl">{intl.formatMessage({ id: 'connectivity', defaultMessage: 'connectivity' })}</div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {locationsList.length > 0 &&
                                        <div>
                                            {locationsList.map((location, idx) => (
                                                <div data-testid={`${testId}-mapping-location-list-${idx}`} key={`${testId}-mapping-location-list-${location.locationId}`} className='w-full bg-white border rounded-lg mb-4 p-6'>
                                                    <div className='flex items-center justify-between'>
                                                        <div data-testid={`${testId}-location-show`} className={`text-xl sm:text-sm font-semibold text-black cursor-pointer ${(singlelocations && location.locationId === singlelocations.locationId) ? 'mb-6 sm:mb-3' : ''}`} onClick={() => handleLocationShow(location.locationId)}>
                                                            {location?.name || location?.nameWhenOther || location.street || location.city || location.country}
                                                        </div>
                                                        <div data-testid={`${testId}-location-show-2`} onClick={() => handleLocationShow(location.locationId)} className={`flex items-center border rounded-full cursor-pointer ${(singlelocations && location.locationId === singlelocations.locationId) ? 'border-[#535353]' : 'border-[#959595]'} px-4 py-1 sm:px-2 sm:py-1`}>
                                                            <div className={`font-bold font-family-arial text-sm sm:text-[11px] ${(singlelocations && location.locationId === singlelocations.locationId) ? 'text-[#535353]' : 'text-[#959595]'} mr-2`}>{intl.formatMessage({ id: 'connectivity_detail', defaultMessage: 'connectivity_detail' })}</div>
                                                            {(singlelocations && location.locationId === singlelocations.locationId) ?
                                                                <img data-testid={`${testId}-close-icon`} src={CloseIcon} alt='' />
                                                                :
                                                                <img src={InfoIcon} alt='' />
                                                            }
                                                        </div>
                                                    </div>
                                                    {(singlelocations && location.locationId === singlelocations.locationId) &&
                                                        <>
                                                            <div className="mb-2">
                                                                {connectivitiesList.length > 0 ?
                                                                    connectivitiesList?.map((item, index) => (
                                                                        item.selected === true &&
                                                                        <div data-testid={`${testId}-connectivity-list-${index}`} key={`${testId}-connectivity-list-${item.connectivityId}`} className={`${index === 0 ? 'rounded-t-lg' : ''} ${index + 1 === connectivitiesList.length ? 'rounded-b-lg' : ''} flex item-center justify-between w-full px-4 py-[14px] bg-[#FAFAFA] border`}>
                                                                            <div className="font-semibold text-sm sm:text-xs">{item?.name || item?.nameWhenOther}</div>
                                                                            <div className="flex items-center">
                                                                                <div data-testid={`${testId}-connectivity-status`} className="font-normal text-xs mr-2 tracking-[0.25px]">{`${getConnectivityStatus(item)}`}</div>
                                                                                {((role === "administrator") || (role === "editor")) ?
                                                                                    <div className="flex items-center">
                                                                                        <img data-testid={`${testId}-edit-icon`} onClick={() => gotoEditConnectivity(item)} className="w-[12px] h-[12px] cursor-pointer" src={EditIcon} alt='' />
                                                                                        {item.connectivityListId === "00000000-0000-0000-0000-000000000000" &&
                                                                                            <div data-testid={`${testId}-delete-connectivity`} className="w-max flex ml-2 items-center justify-between cursor-pointer" onClick={() => handleDeleteConnectivityModalOpen(item)}>
                                                                                                <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                                                        <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    <img data-testid={`${testId}-info-icon`} onClick={() => gotoEditConnectivity(item)} className="w-[12px] cursor-pointer h-[12px]" src={InfoIcon} alt="" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <>
                                                                        {connectivitySelector.connectivityLoading &&
                                                                            <div className='w-full flex items-center justify-center'>
                                                                                <LoadingIcon />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            {((role === "administrator") || (role === "editor")) ?
                                                                <div>
                                                                    <StyledButton
                                                                        testId={`${testId}-add-connectivity-btn`}
                                                                        width={'w-60'}
                                                                        align={'justify-start'}
                                                                        text={intl.formatMessage({ id: "Add_Connectivity", defaultMessage: 'Add_Connectivity' })}
                                                                        color={'text-gray-700'}
                                                                        flexReverse={'flex-row-reverse'}
                                                                        iconType={'add'}
                                                                        backgroundColor={'transparent border-1'}
                                                                        onClick={() => gotoAddConnectivity()}
                                                                    />
                                                                </div>
                                                                :
                                                                <></>
                                                            }
                                                            <div className='mt-[15px]'>
                                                                {connectivitiesList?.map((item, index) => (
                                                                    item.selected === false &&
                                                                    <div data-testid={`${testId}-connectivity-${index}`} key={`${testId}-connectivity-${item.connectivityId}`} className={`${index === 0 ? 'rounded-t-lg' : ''} ${index + 1 === connectivitiesList.length ? 'rounded-b-lg' : ''} flex item-center justify-between w-full px-4 py-[14px] bg-[#FAFAFA] border`}>
                                                                        <div className="font-semibold text-sm sm:text-xs">{item?.name || item?.nameWhenOther}</div>
                                                                        <div className="flex items-center">
                                                                            <div data-testid={`${testId}-status`} className="font-normal text-xs mr-2 tracking-[0.25px]">{`${getConnectivityStatus(item)}`}</div>
                                                                            {((role === "administrator") || (role === "editor")) ?
                                                                                <div className="flex items-center">
                                                                                    <img data-testid={`${testId}-edit-icon-img`} onClick={() => gotoEditConnectivity(item)} className="w-[12px] h-[12px] cursor-pointer" src={EditIcon} alt='' />
                                                                                </div>
                                                                                :
                                                                                <img data-testid={`${testId}-info-icon-img`} onClick={() => gotoEditConnectivity(item)} className="w-[12px] cursor-pointer h-[12px]" src={InfoIcon} alt="" />}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                                <StyledButton
                                    testId={`${testId}-previous-btn`}
                                    width={'w-48 sm:w-full'}
                                    align={'justify-start'}
                                    text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                    color={'text-gray-700'}
                                    flexReverse={'flex-row-reverse'}
                                    iconType={'arrowleft'}
                                    backgroundColor={'bg-gray-300'}
                                    onClick={goToPrevious}
                                />
                                <StyledButton
                                    testId={`${testId}-next-btn`}
                                    buttonClass={'PrimoryBtn'}
                                    width={'w-48 sm:w-full'}
                                    align={'justify-end'}
                                    text={intl.formatMessage({ id: 'next', defaultMessage: 'next' })}
                                    color={'text-white'}
                                    iconType={'arrow'}
                                    backgroundColor={'bg-black'}
                                    onClick={goToNext}
                                />
                            </div>
                        </div>
                    </Container>
                </div >
            }

            <Modal data-testid={`${testId}-connectivity-modal`} show={isConnectivityModal} onClose={() => handleConnectivityModalClose()}>
                <div className={connectivityPopupDetails?.Video?.data ? "lg:max-w-[700px]" : "lg:max-w-[400px]"}>
                    <div data-testid={`${testId}-popup-intro`} className="font-bold text-[10px] text-[#959595] tracking-[1px] uppercase mb-2 cursor-pointer">
                        {connectivityPopupDetails?.Intro}
                    </div>
                    <div data-testid={`${testId}-popup-header`} className="font-semibold text-xl text-[#121212] mb-6">
                        {connectivityPopupDetails?.Header}
                    </div>
                    {connectivityPopupDetails?.Video?.data &&
                        <div className="mb-10">
                            <VideoJS options={options} onReady={handlePlayerReady} />
                        </div>
                    }
                    <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{String(connectivityPopupDetails?.Body)}</ReactMarkdown>
                    <div className="flex items-center justify-between">
                        {connectivityPopupDetails?.Button2 && <StyledButton testId={`${testId}-connectivity-popup-btn-2`} onClick={() => connectivityPopupDetails?.Link2 ? navigate(connectivityPopupDetails?.Link2) : handleConnectivityModalClose()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-start'} text={connectivityPopupDetails?.Button2} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                        {connectivityPopupDetails?.Button && <StyledButton testId={`${testId}-connectivity-popup-btn`} onClick={() => connectivityPopupDetails?.Link ? navigate(connectivityPopupDetails?.Link) : handleConnectivityAdd()} buttonClass={'px-3 py-5 PrimoryBtn'} width={'min-w-[140px] w-max'} align={'justify-end'} text={connectivityPopupDetails?.Button} color={'text-white mr-3'} iconType={''} backgroundColor={'bg-black'} />}
                    </div>
                </div>
            </Modal>

            <div>
                <Modal show={isDeleteConnectivityModal} onClose={() => handleDeleteConnectivityModalClose()}>
                    <div>{`${intl.formatMessage({ id: 'are_you_sure', defaultMessage: 'are_you_sure' })}`}</div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-cancel-delete-connectivity-item`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => handleDeleteConnectivityModalClose()}
                        />
                        <StyledButton
                            testId={`${testId}-delete-connectivity-item`}
                            buttonClass={'PrimoryBtn'}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "delete", defaultMessage: 'delete' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => deleteConnectivityItem()}
                        />
                    </div>
                </Modal>
                <Modal show={isWromgOrgModal} onClose={() => ""}>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                    </div>
                    <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                        <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                    </div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-goto-dashboard`}
                            onClick={() => handleWrongOrgModel()}
                            buttonClass={'PrimoryBtn'}
                            width={"w-[220px] sm:w-40"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                            color={"text-white mr-3 text-base"}
                            iconType={'home'}
                            backgroundColor={"bg-black"}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
}