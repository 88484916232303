declare type RouteConstants = {
  [key: string]: {
    route: string;
    isProtected: boolean;
  };
};

const routeConstants: RouteConstants = {
  home: {
    route: "/",
    isProtected: false,
  },
  createAccount: {
    route: "/create-account",
    isProtected: false,
  },
  plans: {
    route: "/plans",
    isProtected: true,
  },
  subscription: {
    route: "/subscription",
    isProtected: true,
  },
  addMeasureRiskAreas: {
    route: "/measures/:id/add-measures/:mgId",
    isProtected: true,
  },
  editMeasureRiskAreas: {
    route: "/measures/:id/edit-measures/:mgId/:singleMeasureId",
    isProtected: true,
  },
  assessment: {
    route: "/assessment/:id",
    isProtected: true,
  },
  riskAreas: {
    route: "/risk-areas",
    isProtected: true,
  },
  riskAreasEdit: {
    route: "/risk-areas/:id",
    isProtected: true,
  },
  manageRiskAreas: {
    route: "/manage-risk-areas",
    isProtected: true,
  },
  events: {
    route: "/events/:id",
    isProtected: true,
  },
  addEvents: {
    route: "/events/:id/addevents",
    isProtected: true,
  },
  editEvents: {
    route: "/events/:id/editevents/:singleEventId",
    isProtected: true,
  },
  bia: {
    route: "/bia",
    isProtected: true,
  },
  editBia: {
    route: "/bia/:id/edit",
    isProtected: true,
  },
  measureGroups: {
    route: "/risk-area/:id",
    isProtected: true,
  },
  addMeasureGroup: {
    route: "/risk-area/:id/add-measure-group",
    isProtected: true,
  },
  editMeasureGroup: {
    route: '/risk-area/:id/edit-measure-group/:singleMeasureGroupId',
    isProtected: true,
  },
  locations: {
    route: "/locations/:id",
    isProtected: true,
  },
  addLocation: {
    route: "/locations/:id/add-location",
    isProtected: true,
  },
  editLocation: {
    route: '/locations/:id/edit-location/:locationId',
    isProtected: true,
  },
  dashboard: {
    route: "/dashboard",
    isProtected: true,
  },
  tasks: {
    route: "/tasks",
    isProtected: true,
  },
  matrix: {
    route: "/matrix",
    isProtected: true,
  },
  organisation: {
    route: "/organisation",
    isProtected: true,
  },
  organisationStep2: {
    route: "/organisation/step2",
    isProtected: true,
  },
  addOrganisationStep2: {
    route: "/organisation/step2/add-location",
    isProtected: true,
  },
  editOrganisationStep2: {
    route: "/organisation/step2/edit-location/:id",
    isProtected: true,
  },
  organisationStep3: {
    route: "/organisation/step3",
    isProtected: true,
  },
  addOrganisationStep3: {
    route: "/organisation/step3/add-Product",
    isProtected: true,
  },
  editOrganisationStep3: {
    route: "/organisation/step3/edit-Product/:id",
    isProtected: true,
  },
  organisationStep4: {
    route: "/organisation/step4",
    isProtected: true,
  },
  addOrganisationStep4: {
    route: "/organisation/step4/add-Process",
    isProtected: true,
  },
  editOrganisationStep4: {
    route: "/organisation/step4/edit-Process/:id",
    isProtected: true,
  },
  hardware: {
    route: "/hardware/:id",
    isProtected: true,
  },
  addhardware: {
    route: "/hardware/:id/addhardware/:locationId",
    isProtected: true,
  },
  edithardware: {
    route: "/hardware/:id/edithardware/:locationId/:hardwareId",
    isProtected: true,
  },
  softwareOverview: {
    route: "/software/:id/softwareOverview",
    isProtected: true,
  },
  software: {
    route: "/software/:id",
    isProtected: true,
  },
  addSoftware: {
    route: "/software/:id/addSoftware/:processId",
    isProtected: true,
  },
  editSoftware: {
    route: "/software/:id/editSoftware/:processId/:softwareId",
    isProtected: true,
  },
  accountlayout: {
    route: "/account",
    isProtected: true,
  },
  users: {
    route: "/users",
    isProtected: true,
  },
  verifyEmail: {
    route: "/verify-email",
    isProtected: false,
  },
  organizationList: {
    route: "/organization-list",
    isProtected: true,
  },
  //notFound: {
  //  route: "*",
  //  isProtected: true,
  //},
  useInvite: {
    route: "/use-invite",
    isProtected: false,
  },
  currentOrganization: {
    route: "/current-organisation",
    isProtected: true,
  },
  activesubscription: {
    route: "/active-subscription",
    isProtected: true,
  },
  addClient: {
    route: "/add-client",
    isProtected: true,
  },
  editClient: {
    route: "/edit-client",
    isProtected: true,
  },
  connectivity: {
    route: "/connectivity/:id",
    isProtected: true,
  },
  addConnectivity: {
    route: "/connectivity/:id/add-connectivity/:locationId",
    isProtected: true,
  },
  editConnectivity: {
    route: "/connectivity/:id/edit-connectivity/:locationId/:connectivityId",
    isProtected: true,
  },
  changePassword: {
    route: "/change-password",
    isProtected: false,
  },
  newVersion: {
    route: "/new-version",
    isProtected: true,
  },
  twoFa: {
    route: "/two-fa",
    isProtected: true,
  },
  authDone: {
    route: "/auth-success",
    isProtected: true,
  },
  authentication: {
    route: "/auth-two-fa",
    isProtected: true,
  }
};

export default routeConstants;
