import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { riskAreaContainerCreators } from "../ManageRiskAreasContainer/reducer";
import { AssesedStatuses } from "@onesixeight-nl/classes/dist/classes/assesedStatusesEnum";
import { selectDecodedUserInfo } from "../../AppSelector";
import { ContentType } from "@onesixeight-nl/classes";
import { InfoDetails } from "../BIAContainer/BIAEdit";
import Slider, { Formatter } from 'antd/es/slider';
import InfoQIcon from "../../assets/icons/info-q-icon.svg";
import CloseIcon from "../../assets/icons/close-icon.svg";
import StyledButton from "../../components/StyledButton";
import InfoCard from "../../components/InfoCard";
import dayjs from "dayjs";
import GetIcons from "../../components/GetIcons/GetIcons";
import Container from "../../components/Container/Container";

const testId = `assessment-container`;

type AssessmentQuestions = {
    id: string;
    title: string;
}

type SingleRiskCategory = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
    task: {
        assignedTo: string,
        deadline: string,
    }
}

export default function AssessmentContainer() {

    // hooks
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation()
    const params = useParams();
    const { id } = params;
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);

    // states
    const [answerType, setAnswerType] = useState<string>('');
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
    const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);
    const { from }: RootState = state || {};

    const assessmentQuestions: AssessmentQuestions[] = [
        {
            id: '1',
            title: intl.formatMessage({ id: 'do_you_think_different_about_the_risk_factor?', defaultMessage: 'do_you_think_different_about_the_risk_factor?' }),
        },
        {
            id: '2',
            title: intl.formatMessage({ id: 'how_well_do_you_think_you_are_doing_it?', defaultMessage: 'how_well_do_you_think_you_are_doing_it?' }),
        },
    ]
    const [currentIndex, setCurrentIndex] = useState<number>(assessmentQuestions?.findIndex((ele) => ele));
    const [singleRiskCategory, setSingleRiskCategory] = useState<SingleRiskCategory>();
    const [disableSliderValue, setDisableSliderValue] = useState<number>(riskAreaSelector?.editData?.riskFactor);
    const [newRFactor, setNewRFactor] = useState<number>(riskAreaSelector?.editData?.riskFactor);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);

    useEffect(() => {
        if (riskAreaSelector?.status !== null && riskAreaSelector?.status !== undefined) {
            setIsSaved(true);
            setTimeCounter(1);
        }
    }, [riskAreaSelector.status])
    /* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved]);

    useEffect(() => {
        id && dispatch(riskAreaContainerCreators.requestEditValue(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (id && riskAreaSelector && riskAreaSelector.editData) {
            setSingleRiskCategory(riskAreaSelector.editData);
            setDisableSliderValue(riskAreaSelector?.editData?.riskFactor)
        }
    }, [id, riskAreaSelector])

    useEffect(() => {
        if (showMoreInfo && decodedUserInfo) {
            if (currentIndex === 0) {
                dispatch(riskAreaContainerCreators.requestGetAssessmentsHelpText(ContentType.ASSESMENT1, decodedUserInfo));
            } else if (currentIndex === 1) {
                dispatch(riskAreaContainerCreators.requestGetAssessmentsHelpText(ContentType.ASSESMENT2, decodedUserInfo));
            }
        }     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMoreInfo, decodedUserInfo, currentIndex])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.assessmentHelpText) {
            setInfoDetails(riskAreaSelector.assessmentHelpText?.data);
        }
    }, [riskAreaSelector])


    //functions
    function handleShowMoreInfo() {
        setShowMoreInfo(!showMoreInfo);
    }

    function handleAnswerType(answer: string) {
        if (currentIndex === 0) {
            if (answer === 'No') {
                setAnswerType('No');
            } else if (answer === 'Yes') {
                setAnswerType('Yes');
                setNewRFactor(riskAreaSelector?.editData?.riskFactor)
            }
        } else if (currentIndex === 1) {
            if (answer === 'Very well') {
                setAnswerType('Very well');
            } else if (answer === 'Well') {
                setAnswerType('Well');
            } else if (answer === 'Okay') {
                setAnswerType('Okay');
            } else if (answer === 'Can be better') {
                setAnswerType('Can be better');
            } else if (answer === 'Nothing arranged') {
                setAnswerType('Nothing arranged');
            }
        }
    }

    function getAssessedStatus() {
        if (answerType === 'Very well') {
            return AssesedStatuses.VERYWELL
        } else if (answerType === 'Well') {
            return AssesedStatuses.WELL
        } else if (answerType === 'Okay') {
            return AssesedStatuses.OKAY
        } else if (answerType === 'Can be better') {
            return AssesedStatuses.CANBEBETTER
        } else if (answerType === 'Nothing arranged') {
            return AssesedStatuses.NOTHINGARRANGED
        }
    }

    function handleClickNext() {

        if (currentIndex === 0) {

            if (answerType === 'Yes') {
                const assessment = true;
                const payload = {
                    riskListId: singleRiskCategory?.riskListId,
                    selected: singleRiskCategory?.selected,
                    riskFactor: newRFactor || riskAreaSelector?.editData?.riskFactor,
                    assesedStatus: 0,
                    nameWhenOther: singleRiskCategory?.riskListId === '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.nameWhenOther : '',
                    task: {
                        assignedTo: singleRiskCategory?.task?.assignedTo,
                        deadline: (singleRiskCategory?.task?.deadline !== '' && decodedUserInfo?.payload.planningMethod !== 2) ? singleRiskCategory?.task?.deadline : dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                    }
                }
                dispatch(riskAreaContainerCreators.requestPutRiskCategory(id, { ...payload, navigate }, assessment));
                if (currentIndex + 1 < assessmentQuestions.length) {
                    setCurrentIndex(currentIndex + 1);
                    setAnswerType('');
                } else {
                    setCurrentIndex(-1);
                    setAnswerType('');
                }
            } else {
                if (currentIndex + 1 < assessmentQuestions.length) {
                    setCurrentIndex(currentIndex + 1);
                    setAnswerType('');
                } else {
                    setCurrentIndex(-1);
                    setAnswerType('');
                }
            }
        } else if (currentIndex === 1) {
            if (answerType !== '' && answerType !== 'Yes' && answerType !== 'No') {
                const assessment = true;
                const payload = {
                    riskListId: singleRiskCategory?.riskListId,
                    selected: singleRiskCategory?.selected,
                    riskFactor: newRFactor || riskAreaSelector?.editData?.riskFactor,
                    assesedStatus: AssesedStatuses.UNDEFINED ? AssesedStatuses.UNDEFINED : getAssessedStatus(),
                    nameWhenOther: singleRiskCategory?.riskListId === '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.nameWhenOther : '',
                    task: {
                        assignedTo: singleRiskCategory?.task?.assignedTo,
                        deadline: singleRiskCategory?.task?.deadline || dayjs(new Date()).add(1, "month").format('YYYY-MM-DD').toString(),
                    }
                }
                dispatch(riskAreaContainerCreators.requestPutRiskCategory(id, { ...payload, navigate }, assessment));
                if (currentIndex + 1 < assessmentQuestions.length) {
                    setCurrentIndex(currentIndex + 1);
                    setAnswerType('');
                } else {
                    if (from === "matrix") {
                        navigate(`/matrix`)
                    } else if (from === "newVersion") {
                        navigate(`/new-version`)
                    } else {
                        setCurrentIndex(-1);
                        setAnswerType('');
                    }
                }
            }
        }
        setShowMoreInfo(false);
    }

    function gotoDashboard() {
        navigate("/dashboard?isCancel=0");
    }

    function handleNewAssessesStatusSlider(value: any) {
        setNewRFactor(value);
    }

    const formatter: null | Formatter | any = (value: number) => handleToolTip(value);

    function handleToolTip(value: any) {
        if (value === 1) {
            return intl.formatMessage({ id: 'low_risk', defaultMessage: 'low_risk' })
        }
        else if (value === 2) {
            return intl.formatMessage({ id: 'limited', defaultMessage: 'limited' })
        }
        else if (value === 3) {
            return intl.formatMessage({ id: 'medium', defaultMessage: 'medium' })
        }
        else if (value === 4) {
            return intl.formatMessage({ id: 'important', defaultMessage: 'important' })
        }
        else {
            return intl.formatMessage({ id: 'crucial', defaultMessage: 'crucial' })
        }
    }

    return (
        <div>
            <Container>
                <div className="flex-grow-1 flex flex-col justify-between">
                    <div>
                        <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0'>
                            <div className="flex items-center font-semibold text-xl">
                                <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                <div className="ml-3">
                                    <div className="font-semibold text-xl text-[#535353]">
                                        {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                    </div>
                                </div>
                            </div>
                            {isSaved &&
                                <div className="font-normal text-xs text-[#959595]">
                                    <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                </div>
                            }
                        </div>
                        <div className="border w-full"></div>
                        {currentIndex === -1 ?
                            <>
                                <div className="my-10">
                                    <div className="font-semibold text-5xl mb-4">{intl.formatMessage({ id: 'great_job!', defaultMessage: 'great_job!' })}</div>
                                    <div className="max-w-[586px] font-normal text-xl text-[#535353] font-family-arial">{intl.formatMessage({ id: 'assessment_last_step', defaultMessage: 'assessment_last_step' })}</div>
                                </div>
                                <div>
                                    <div className="flex items-center justify-center min-h-[30vh]">
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {currentIndex === 0 &&
                                    <div>
                                        <div className="flex items-center justify-between pt-7 mb-10">
                                            <div className="max-w-[586px] font-normal text-xl text-[#535353] font-family-arial">{intl.formatMessage({ id: 'assessment_title', defaultMessage: 'assessment_title' })}</div>
                                            <div className="relative">
                                                <div data-testid={`${testId}-show-more-info`} onClick={() => handleShowMoreInfo()} className={`cursor-pointer flex items-center justify-center border rounded-full ${showMoreInfo ? 'border-[#535353]' : 'border-[#959595]'} py-1 px-3`}>
                                                    <div className={`mr-1 mb-[2px] font-family-arial font-normal text-xs ${showMoreInfo ? 'text-[#535353]' : 'text-[#959595]'}`}>{intl.formatMessage({ id: 'info', defaultMessage: 'info' })}</div>
                                                    {showMoreInfo ?
                                                        <img className="w-[12px] h-[12px]" src={CloseIcon} alt='' />
                                                        :
                                                        <img data-testid={`${testId}-info-icon`} className="w-[12px] h-[12px]" src={InfoQIcon} alt='' />
                                                    }
                                                </div>
                                                {showMoreInfo &&
                                                    <div className="absolute right-0 z-[1] flex flex-col min-h-max">
                                                        {infoDetails.map((infoCard, index) => (
                                                            <InfoCard testId={`${testId}-info-detail-assesment-${index}`} key={infoCard?.id} infoCard={infoCard} />
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {/* Range */}
                                        <div className="w-full max-w-[414px]">
                                            <Slider className={'disable-track-color mb-[70px]'} tooltip={{ formatter: null }} disabled value={disableSliderValue} step={1} dots min={1} max={5} marks={{ 1: { style: { marginLeft: '0', marginTop: '5px', transform: 'translateX(0%)', left: 0, textAlign: "start" }, label: intl.formatMessage({ id: "low_risk", defaultMessage: 'low_risk' }) }, 5: { style: { marginLeft: '0', left: 'auto', right: 0, transform: 'translateX(0%)', color: '#666', textAlign: "end", marginTop: '5px' }, label: intl.formatMessage({ id: "crucial", defaultMessage: 'crucial' }) } }} />
                                        </div>
                                        <div className="mt-[130px]">
                                            <div className='m-auto w-full pb-7 align-middle items-center flex justify-between'>
                                                <div className="font-semibold text-[32px] sm:text-2xl">
                                                    <div>{assessmentQuestions[currentIndex].title}</div>
                                                </div>
                                            </div>
                                            <div className="flex sm:flex-col">
                                                <div className="mr-6 sm:w-full sm:mr-0">
                                                    <div data-testid={`${testId}-no`} onClick={() => handleAnswerType('No')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'No' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-yes`} onClick={() => handleAnswerType('Yes')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Yes' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                    </div>
                                                </div>
                                                {answerType === 'Yes' &&
                                                    <>
                                                        <div className="w-full max-w-[414px] border rounded-lg bg-white mb-10 p-10 sm:p-5">
                                                            <div className="font-bold text-base mb-2 font-family-arial font-family-arial">{intl.formatMessage({ id: 'what_is_the_risk_factor?', defaultMessage: 'what_is_the_risk_factor?' })}</div>
                                                            <Slider className={'reverse-range-slider'} defaultValue={newRFactor} value={newRFactor} onChange={(val: any) => handleNewAssessesStatusSlider(val)} step={1} dots min={1} max={5}
                                                                marks={{ 1: { style: { marginLeft: '0', marginTop: '5px', transform: 'translateX(0%)', left: 0, textAlign: "start", color: "#666" }, label: intl.formatMessage({ id: "low_risk", defaultMessage: 'low_risk' }) }, 5: { style: { marginLeft: '0', left: 'auto', right: 0, transform: 'translateX(0%)', color: '#666', textAlign: "end", marginTop: '5px' }, label: intl.formatMessage({ id: "crucial", defaultMessage: 'crucial' }) } }}
                                                                tooltip={{ formatter }}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {currentIndex === 1 &&
                                    <div>
                                        <div className="flex items-center justify-between pt-7 mb-10">
                                            <div className="font-semibold text-[32px] sm:text-2xl">
                                                <div>{assessmentQuestions[currentIndex].title}</div>
                                            </div>
                                            <div className="relative">
                                                <div data-testid={`${testId}-show-more-info`} onClick={() => handleShowMoreInfo()} className={`cursor-pointer flex items-center justify-center border rounded-full ${showMoreInfo ? 'border-[#535353]' : 'border-[#959595]'} py-1 px-3`}>
                                                    <div className={`mr-1 mb-[2px] font-family-arial font-normal text-xs ${showMoreInfo ? 'text-[#535353]' : 'text-[#959595]'}`}>{intl.formatMessage({ id: 'info', defaultMessage: 'info' })}</div>
                                                    {showMoreInfo ?
                                                        <img data-testid={`${testId}close-icon`} className="w-[12px] h-[12px]" src={CloseIcon} alt='' />
                                                        :
                                                        <img data-testid={`${testId}-info-icon-assesment`} className="w-[12px] h-[12px]" src={InfoQIcon} alt='' />
                                                    }
                                                </div>
                                                {showMoreInfo &&
                                                    <div className="absolute right-0 z-[1] flex flex-col min-h-max">
                                                        {infoDetails.map((infoCard, index) => (
                                                            <InfoCard testId={`${testId}-info-details-assesment-${index}`} key={infoCard?.id} infoCard={infoCard} />
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="mt-10">
                                            <div className="flex sm:flex-col">
                                                <div className="mr-6 sm:w-full sm:mr-0">
                                                    <div data-testid={`${testId}-very-well`} onClick={() => handleAnswerType('Very well')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Very well' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'very_well', defaultMessage: 'very_well' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-well`} onClick={() => handleAnswerType('Well')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Well' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'well', defaultMessage: 'well' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-okay`} onClick={() => handleAnswerType('Okay')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Okay' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'okay', defaultMessage: 'okay' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-can-be-better`} onClick={() => handleAnswerType('Can be better')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Can be better' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'can_be_better', defaultMessage: 'can_be_better' })}</div>
                                                    </div>
                                                    <div data-testid={`${testId}-nothing-arranged`} onClick={() => handleAnswerType('Nothing arranged')} className={`w-[416px] h-[56px] cursor-pointer rounded-lg sm:w-full ${answerType === 'Nothing arranged' ? 'bg-[#FCAF3B]' : 'bg-white border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mb-6`}>
                                                        <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'nothing_arranged', defaultMessage: 'nothing_arranged' })}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div className='w-full bottom-0 align-middle items-center flex justify-end'>
                        {currentIndex === -1 ?
                            <div className="flex justify-end">
                                <div>
                                    <StyledButton buttonClass={'PrimoryBtn'} testId={`${testId}-dashboard-button`} onClick={() => gotoDashboard()} flexReverse={'flex-row-reverse sm:flex-row'} width={'w-48 sm:w-40'} align={'justify-end'} text={intl.formatMessage({ id: 'dashboard', defaultMessage: 'dashboard' })} color={'text-white'} iconType={'home'} backgroundColor={'bg-black'} />
                                </div>
                            </div>
                            :
                            <StyledButton testId={`${testId}-next-button`} onClick={() => handleClickNext()} buttonClass={'PrimoryBtn sm:px-2'} disabled={answerType === '' ? true : false} width={'w-48 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'next', defaultMessage: 'next' })} color={answerType === '' ? 'text-[#535353]' : 'text-white'} iconType={answerType === '' ? 'blackarrow' : 'arrow'} backgroundColor={answerType === '' ? 'bg-[#EDEDED]' : 'bg-black'} />}
                    </div>
                </div>
            </Container>
        </div>
    );
}