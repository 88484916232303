import { Statuses } from '@onesixeight-nl/classes';
import axios, {
    AxiosError,
    AxiosPromise,
    AxiosResponse
} from 'axios';
import { IntlShape } from 'react-intl';
import { put, call, takeLatest } from 'redux-saga/effects';
import { DefaultActionCreators } from 'reduxsauce';
import { getLocationsService, getHardwaresService, addLocationsService, putLocationsService, deleteLocationsService, postHardwaresService, postSoftwareService, getSoftwareService, prodLocationContentService, prodSoftwareContentService, prodHardwareContentService, prodConnectivityContentService, putHardwaresService, getConnectivitiesService, postConnectivitiesService, putConnectivitiesService, deleteHardwaresService, deleteConnectivityService, deleteSoftwareService, putSoftwareService, getHardwareByIdService, getConnectivityByIdService, getSoftwareByIdService } from '../../services/LocationsService';
import { getProcessDataService } from '../../services/Organization';
import { getCmsURL } from '../../utils';
import { locationContainerTypes, locationContainerCreators, clearLocationStatusCreators } from './locationReducer';
import { redirectUrlCreators } from '../LoginContainer/reducer';

export const hardwaresHelpTextService = (contentType: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[Type][$eq]=${contentType}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const softwaresHelpTextService = (contentType: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[Type][$eq]=${contentType}&locale=${decodedUserInfo?.payload?.prefLang}`)

export const connectivitiesHelpTextService = (contentType: string, decodedUserInfo: DecodedUserInfo | null): AxiosPromise => axios.get(`${getCmsURL()}/info-blocks?populate[0]=Video&populate[1]=Image&filters[Type][$eq]=${contentType}&locale=${decodedUserInfo?.payload?.prefLang}`)

const {
    REQUEST_GET_LOCATIONS,
    REQUEST_GET_SOFTWARE_PROCESSES,
    REQUEST_POST_LOCATIONS,
    REQUEST_PUT_LOCATIONS,
    REQUEST_DELETE_LOCATIONS,
    REQUEST_GET_HARDWARES,
    REQUEST_GET_CONNECTIVITIES,
    REQUEST_POST_SOFTWARES,
    REQUEST_PUT_SOFTWARES,
    REQUEST_GET_SOFTWARES,
    REQUEST_DELETE_SOFTWARE,
    REQUEST_POST_HARDWARES,
    REQUEST_POST_CONNECTIVITIES,
    REQUEST_PUT_HARDWARES,
    REQUEST_DELETE_HARDWARE,
    REQUEST_PUT_CONNECTIVITIES,
    REQUEST_DELETE_CONNECTIVITY,
    REQUEST_GET_PROD_LOCATION_CONTENT,
    REQUEST_GET_PROD_SOFTWARE_CONTENT,
    REQUEST_GET_PROD_HARDWARE_CONTENT,
    REQUEST_GET_PROD_CONNECTIVITY_CONTENT,
    REQUEST_GET_HARDWARE_HELP_TEXT,
    REQUEST_GET_SOFTWARE_HELP_TEXT,
    REQUEST_GET_CONNECTIVITY_HELP_TEXT,
    REQUEST_GET_HARDWARE_BY_ID,
    REQUEST_GET_CONNECTIVITY_BY_ID,
    REQUEST_GET_SOFTWARE_BY_ID,
} = locationContainerTypes;

type GetProdLocationContentData = {
    decodedUserInfo: DecodedUserInfo | null;
    riskCategoryObj: any;
}

type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
    method: string | number;
}

type GetLocationsData = {
    decodedUserInfo?: DecodedUserInfo | null;
    dispatch?: (action: DefaultActionCreators) => void;
    singleRiskCategory?: AllRiskCategoryListData;
    isCancel?: string;
    navigate?: (path: string, state?: object) => void;
    isCallingFrom?: string;
    showPopup?: boolean;
};

type GetSoftwareProcessesData = {
    decodedUserInfo?: DecodedUserInfo | null;
    dispatch?: (action: DefaultActionCreators) => void;
    singleRiskCategory?: AllRiskCategoryListData;
    isCancel?: string;
    navigate?: (path: string, state?: object) => void;
    intl?: IntlShape;
    isCallingFrom?: string;
    showPopup?: boolean;
    isSkip?: any;
};

type Singlelocations = {
    nameWhenOther: string;
    city: string;
    country: null;
    created: string;
    houseNumber: number;
    houseNumberSuffix: string;
    locationId: string;
    modified: string;
    name: string;
    organizationId: string;
    postalcode: string;
    street: string;
    type: string;
}

type SingleProcess = {
    processId: string;
    processListId: string;
    organizationId: string;
    nameWhenOther: string;
    name: string;
    responsible: string;
    BIA0h: number;
    BIA4h: number;
    BIA24h: number;
    BIA72h: number;
    BIA168h: number;
    BIA720h: number;
    created: string;
    modified: string;
    availableSoftwareCount?: number;
}

type GetHardwaresData = {
    locationObj: Singlelocations;
    singleRiskCategory?: AllRiskCategoryListData;
    isCancel?: string;
    navigate?: (path: string, state?: object) => void;
}

type GetConnectivitiesData = {
    locationObj: Singlelocations;
    singleRiskCategory?: AllRiskCategoryListData;
    isCancel?: string;
    navigate?: (path: string, state?: object) => void;
}

type GetSoftwareData = {
    processObj: SingleProcess;
    singleRiskCategory?: AllRiskCategoryListData;
    isCancel?: string;
    navigate?: (path: string, state?: object) => void;
    intl?: IntlShape;
    isSkip?: string;
}

type GetHardwaresHelpTextData = {
    contentType: string;
    decodedUserInfo: DecodedUserInfo | null;
}

type GetSoftwaresHelpTextData = {
    contentType: string;
    decodedUserInfo: DecodedUserInfo | null;
}

type GetConnectivitiesHelpTextData = {
    contentType: string;
    decodedUserInfo: DecodedUserInfo | null;
}

type GetHardwareByIdData = {
    locationId: string;
    hardwareId: string;
}

type GetConnectivityByIdData = {
    locationId: string;
    connectivityId: string;
}

type GetSoftwareByIdData = {
    locationId: string;
    softwareId: string;
}

/* istanbul ignore next */
export function* getLocationsData(data: GetLocationsData) {
    const role = data.decodedUserInfo?.payload?.role
    try {
        const response: AxiosResponse = yield call(getLocationsService);
        if (response && response.data) {
            const singleLocationObjForHardware = response.data?.find((ele: any) => ele?.availableHardwareCount > 0);
            if (singleLocationObjForHardware && data.isCancel !== '0' && data.isCallingFrom === 'Hardware') {
                if ((role === "administrator") || (role === "editor")) {
                    if (data.showPopup === false) {
                        if (singleLocationObjForHardware && data.isCancel !== '0') {
                            data.dispatch && data.dispatch(locationContainerCreators.requestGetHardwares(singleLocationObjForHardware, data.singleRiskCategory, data.isCancel, data.navigate));
                        }
                    } else {
                        data.dispatch && data.dispatch(locationContainerCreators.requestGetProdHardwareContent(data.decodedUserInfo, data.singleRiskCategory));
                    }
                }
            }
            const singleLocationObjForConnectivity = response.data?.find((ele: any) => ele?.availableConnectivityCount > 0);
            if (singleLocationObjForConnectivity && data.isCancel !== '0' && data.isCallingFrom === 'Connectivity') {
                if ((role === "administrator") || (role === "editor")) {
                    if (data.showPopup === false) {
                        if (singleLocationObjForConnectivity && data.isCancel !== '0') {
                            data.dispatch && data.dispatch(locationContainerCreators.requestGetConnectivities(singleLocationObjForConnectivity, data.singleRiskCategory, data.isCancel, data.navigate));
                        }
                    } else {
                        data.dispatch && data.dispatch(locationContainerCreators.requestGetProdConnectivityContent(data.decodedUserInfo, data.singleRiskCategory));
                    }
                }
            }
        }
        yield put(clearLocationStatusCreators.clearLocationStatus());
        yield put(locationContainerCreators.successGetLocations(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetLocations(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetLocations("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getSoftwareProcessesData(data: GetSoftwareProcessesData) {
    const role = data.decodedUserInfo?.payload?.role
    try {
        const response: AxiosResponse = yield call(getProcessDataService);
        let processOverview: any = [];
        if (response && response.data) {
            const GenericProcess = response.data.find((ele: any) => ele.processListId === '00000000-0000-0000-0000-000000000001')
            const OtherProcesses = response.data.filter((ele: any) => ele.processListId !== '00000000-0000-0000-0000-000000000001')
            processOverview = [...[{ ...GenericProcess, name: "", nameWhenOther: data.intl && data.intl.formatMessage({ id: 'generic_process' }) }], ...OtherProcesses];
            const availableSoftwareCountArray = processOverview.filter((ele: any) => ele.softwareCount === 0)
            const isEveryOneEmpty = availableSoftwareCountArray.length === processOverview.length;

            const findIndexOfCurrent = processOverview.findIndex((ele: any) => ele.processId === data.isSkip);
            const nextIndex = processOverview.findIndex((ele: any, index: number) => index > findIndexOfCurrent && ele.softwareCount === 0);


            if ((role === "administrator") || (role === "editor")) {

                if (data.showPopup === false) {
                    if (data.isCancel !== '0') {
                        //don't change here double equale...
                        if (isEveryOneEmpty && data.isSkip === -1) {
                            data.dispatch && data.dispatch(locationContainerCreators.requestGetSoftwares(processOverview[0], data.singleRiskCategory, data.isCancel, data.navigate, data.intl, data.isSkip));
                        } else if (nextIndex !== -1 && findIndexOfCurrent !== -1) {
                            data.dispatch && data.dispatch(locationContainerCreators.requestGetSoftwares(processOverview[nextIndex], data.singleRiskCategory, data.isCancel, data.navigate, data.intl, data.isSkip));
                        }
                    }

                } else {
                    data.dispatch && data.dispatch(locationContainerCreators.requestGetProdSoftwareContent(data.decodedUserInfo, data.singleRiskCategory));
                }
            }
        }
        yield put(locationContainerCreators.successGetSoftwareProcesses(processOverview))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetSoftwareProcesses(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetSoftwareProcesses("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* postLocationsData(data: any) {
    try {
        const response: AxiosResponse = yield call(addLocationsService, data.data);
        yield put(locationContainerCreators.successPostLocations(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failurePostLocations(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failurePostLocations("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* putLocationsData(data: any) {
    try {
        const response: AxiosResponse = yield call(putLocationsService, data.id, data.data);
        yield put(locationContainerCreators.successPutLocations(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failurePutLocations(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failurePutLocations("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* deleteLocationsData(data: any) {
    try {
        const response: AxiosResponse = yield call(deleteLocationsService, data.data);
        yield put(locationContainerCreators.successDeleteLocations(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureDeleteLocations(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureDeleteLocations("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getHardwaresData(data: GetHardwaresData) {
    try {
        const response: AxiosResponse = yield call(getHardwaresService, data.locationObj.locationId);
        if (response && response.data) {
            const filteredUndefinedHardwares = response.data.filter((ele: any) => !ele.hardwareId)
            if (filteredUndefinedHardwares && filteredUndefinedHardwares.length > 0 && data.isCancel !== '0' && data.navigate && data.singleRiskCategory) {
                data.navigate(`/hardware/${data.singleRiskCategory?.riskCategoryId}/addhardware/${data?.locationObj?.locationId}`, { state: { hardwareQuestions: filteredUndefinedHardwares, selectedLocations: data.locationObj } });
            }
        }
        yield put(clearLocationStatusCreators.clearLocationStatus());
        yield put(locationContainerCreators.successGetHardwares(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetHardwares(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetHardwares("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getConnectivitiesData(data: GetConnectivitiesData) {
    try {
        const response: AxiosResponse = yield call(getConnectivitiesService, data.locationObj.locationId);
        if (response && response.data) {
            const filteredUndefinedConnectivity = response.data.filter((ele: any) => !ele.connectivityId)
            if (filteredUndefinedConnectivity && filteredUndefinedConnectivity.length > 0 && data.isCancel !== '0' && data.navigate && data.singleRiskCategory) {
                data.navigate(`/connectivity/${data.singleRiskCategory?.riskCategoryId}/add-connectivity/${data?.locationObj?.locationId}`, { state: { connectivityQuestions: filteredUndefinedConnectivity, selectedLocations: data.locationObj } });
            }
        }
        yield put(clearLocationStatusCreators.clearLocationStatus());
        yield put(locationContainerCreators.successGetConnectivities(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetConnectivities(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetConnectivities("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getSoftwareData(data: GetSoftwareData) {
    try {
        const response: AxiosResponse = yield call(getSoftwareService, data.processObj.processId);
        const otherProcessObj = data.processObj.processListId === '00000000-0000-0000-0000-000000000001' ? { ...data.processObj, name: "", nameWhenOther: data.intl && data.intl.formatMessage({ id: 'generic_process' }) } : { ...data.processObj, name: "" }
        const ProcessObj = data.processObj.name ? data.processObj : otherProcessObj
        if (response) {
            if (ProcessObj && data.isCancel !== '0' && data.navigate && data.singleRiskCategory) {
                data.navigate(`/software/${data.singleRiskCategory?.riskCategoryId}/addSoftware/${ProcessObj?.processId}${(data.isSkip !== '' && data.isSkip !== null && data.isSkip !== undefined) ? `?skip=${data.isSkip}` : ''}`, { state: { singleProcess: ProcessObj, sLastQuestion: true } });
            }
        }
        yield put(redirectUrlCreators.clearRedirectUrl())
        yield put(locationContainerCreators.successGetSoftwares(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetSoftwares(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetSoftwares("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* postSoftwareData(data: any) {
    try {
        const response: AxiosResponse = yield call(postSoftwareService, data.processId, data.data);
        yield put(locationContainerCreators.successPostSoftwares(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failurePostSoftwares(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failurePostSoftwares("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* putSoftwareData(data: any) {
    try {
        const response: AxiosResponse = yield call(putSoftwareService, data.processId, data.softwareId, data.data);
        yield put(locationContainerCreators.successPutSoftwares(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failurePutSoftwares(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failurePutSoftwares("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* deleteSoftwareData(data: any) {
    try {
        const response: AxiosResponse = yield call(deleteSoftwareService, data.processId, data.softwareId);
        yield put(locationContainerCreators.successDeleteSoftware(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureDeleteSoftware(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureDeleteSoftware("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* postHardwaresData(data: any) {
    try {
        const response: AxiosResponse = yield call(postHardwaresService, data.locationId, data.data);
        yield put(locationContainerCreators.successPostHardwares(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failurePostHardwares(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failurePostHardwares("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* postConnectivitiesData(data: any) {
    try {
        const response: AxiosResponse = yield call(postConnectivitiesService, data.locationId, data.data);
        yield put(locationContainerCreators.successPostConnectivities(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failurePostConnectivities(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failurePostConnectivities("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* putHardwaresData(data: any) {
    try {
        const response: AxiosResponse = yield call(putHardwaresService, data.locationId, data.hardwareId, data.data);
        yield put(locationContainerCreators.successPutHardwares(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failurePutHardwares(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failurePutHardwares("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* deleteHardwaresData(data: any) {
    try {
        const response: AxiosResponse = yield call(deleteHardwaresService, data.locationId, data.hardwareId);
        yield put(locationContainerCreators.successDeleteHardware(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureDeleteHardware(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureDeleteHardware("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* putConnectivitiesData(data: any) {
    try {
        const response: AxiosResponse = yield call(putConnectivitiesService, data.locationId, data.connectivityId, data.data);
        yield put(locationContainerCreators.successPutConnectivities(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failurePutConnectivities(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failurePutConnectivities("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* deleteConnectivityData(data: any) {
    try {
        const response: AxiosResponse = yield call(deleteConnectivityService, data.locationId, data.connectivityId);
        yield put(locationContainerCreators.successDeleteConnectivity(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureDeleteConnectivity(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureDeleteConnectivity("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getProdLocationContentData(data: GetProdLocationContentData) {
    const role = data.decodedUserInfo?.payload.role;
    const getRoles = () => {
        if (role === 'administrator') {
            return 'Administrator'
        } else if (role === 'editor') {
            return 'Editor'
        } else if (role === 'reader') {
            return 'Reader'
        } else if (role === 'accountant') {
            return 'Accountant'
        } else if (role === 'insureanceadvisor') {
            return 'InsureanceAdvisor'
        } else if (role === 'partneraccountant') {
            return 'PartnerAccountant'
        } else if (role === 'partnerinsureanceadvisor') {
            return 'PartnerInsureanceAdvisor'
        } else {
            return ''
        }
    }
    const currentStep: number = 0;
    const rcStepStatus = () => {
        if (currentStep < data?.riskCategoryObj?.step) {
            return 'DONE';
        } else if (currentStep > data?.riskCategoryObj?.step) {
            return 'TODO';
        } else {
            const currentStatus = Statuses[data?.riskCategoryObj?.stepStatus];
            return currentStatus;
        }
    }
    try {
        const response: AxiosResponse = yield call(prodLocationContentService, getRoles(), data.decodedUserInfo, rcStepStatus());
        yield put(clearLocationStatusCreators.clearLocationStatus());
        yield put(locationContainerCreators.successGetProdLocationContent(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetProdLocationContent(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetProdLocationContent("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getProdSoftwareContentData(data: GetProdLocationContentData) {
    const role = data.decodedUserInfo?.payload.role;
    const getRoles = () => {
        if (role === 'administrator') {
            return 'Administrator'
        } else if (role === 'editor') {
            return 'Editor'
        } else if (role === 'reader') {
            return 'Reader'
        } else if (role === 'accountant') {
            return 'Accountant'
        } else if (role === 'insureanceadvisor') {
            return 'InsureanceAdvisor'
        } else if (role === 'partneraccountant') {
            return 'PartnerAccountant'
        } else if (role === 'partnerinsureanceadvisor') {
            return 'PartnerInsureanceAdvisor'
        } else {
            return ''
        }
    }
    const currentStep: number = 3;
    const rcStepStatus = () => {
        if (currentStep < data?.riskCategoryObj?.step) {
            return 'DONE';
        } else if (currentStep > data?.riskCategoryObj?.step) {
            return 'TODO';
        } else {
            const currentStatus = Statuses[data?.riskCategoryObj?.stepStatus];
            return currentStatus;
        }
    }
    try {
        const response: AxiosResponse = yield call(prodSoftwareContentService, getRoles(), data.decodedUserInfo, rcStepStatus());
        yield put(clearLocationStatusCreators.clearLocationStatus());
        yield put(locationContainerCreators.successGetProdSoftwareContent(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetProdSoftwareContent(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetProdSoftwareContent("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getProdHardwareContentData(data: GetProdLocationContentData) {
    const role = data.decodedUserInfo?.payload.role;
    const getRoles = () => {
        if (role === 'administrator') {
            return 'Administrator'
        } else if (role === 'editor') {
            return 'Editor'
        } else if (role === 'reader') {
            return 'Reader'
        } else if (role === 'accountant') {
            return 'Accountant'
        } else if (role === 'insureanceadvisor') {
            return 'InsureanceAdvisor'
        } else if (role === 'partneraccountant') {
            return 'PartnerAccountant'
        } else if (role === 'partnerinsureanceadvisor') {
            return 'PartnerInsureanceAdvisor'
        } else {
            return ''
        }
    }
    const currentStep: number = 1;
    const rcStepStatus = () => {
        if (currentStep < data?.riskCategoryObj?.step) {
            return 'DONE';
        } else if (currentStep > data?.riskCategoryObj?.step) {
            return 'TODO';
        } else {
            const currentStatus = Statuses[data?.riskCategoryObj?.stepStatus];
            return currentStatus;
        }
    }
    try {
        const response: AxiosResponse = yield call(prodHardwareContentService, getRoles(), data.decodedUserInfo, rcStepStatus());
        yield put(clearLocationStatusCreators.clearLocationStatus());
        yield put(locationContainerCreators.successGetProdHardwareContent(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetProdHardwareContent(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetProdHardwareContent("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getProdConnectivityContentData(data: GetProdLocationContentData) {
    const role = data.decodedUserInfo?.payload.role;
    const getRoles = () => {
        if (role === 'administrator') {
            return 'Administrator'
        } else if (role === 'editor') {
            return 'Editor'
        } else if (role === 'reader') {
            return 'Reader'
        } else if (role === 'accountant') {
            return 'Accountant'
        } else if (role === 'insureanceadvisor') {
            return 'InsureanceAdvisor'
        } else if (role === 'partneraccountant') {
            return 'PartnerAccountant'
        } else if (role === 'partnerinsureanceadvisor') {
            return 'PartnerInsureanceAdvisor'
        } else {
            return ''
        }
    }
    const currentStep: number = 2;
    const rcStepStatus = () => {
        if (currentStep < data?.riskCategoryObj?.step) {
            return 'DONE';
        } else if (currentStep > data?.riskCategoryObj?.step) {
            return 'TODO';
        } else {
            const currentStatus = Statuses[data?.riskCategoryObj?.stepStatus];
            return currentStatus;
        }
    }
    try {
        const response: AxiosResponse = yield call(prodConnectivityContentService, getRoles(), data.decodedUserInfo, rcStepStatus());
        yield put(clearLocationStatusCreators.clearLocationStatus());
        yield put(locationContainerCreators.successGetProdConnectivityContent(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetProdConnectivityContent(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetProdConnectivityContent("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getHardwaresHelpTextData(data: GetHardwaresHelpTextData) {
    try {
        const response: AxiosResponse = yield call(hardwaresHelpTextService, data.contentType, data.decodedUserInfo);
        yield put(locationContainerCreators.successGetHardwareHelpText(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetHardwareHelpText(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetHardwareHelpText("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getSoftwaresHelpTextData(data: GetSoftwaresHelpTextData) {
    try {
        const response: AxiosResponse = yield call(softwaresHelpTextService, data.contentType, data.decodedUserInfo);
        yield put(locationContainerCreators.successGetSoftwareHelpText(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetSoftwareHelpText(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetSoftwareHelpText("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getConnectivitiesHelpTextData(data: GetConnectivitiesHelpTextData) {
    try {
        const response: AxiosResponse = yield call(connectivitiesHelpTextService, data.contentType, data.decodedUserInfo);
        yield put(locationContainerCreators.successGetConnectivityHelpText(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetConnectivityHelpText(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetConnectivityHelpText("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getHardwareByIdData(data: GetHardwareByIdData) {
    try {
        const response: AxiosResponse = yield call(getHardwareByIdService, data.locationId, data.hardwareId);
        yield put(locationContainerCreators.successGetHardwareById(response.data));
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetHardwareById(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetHardwareById("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getConnectivityByIdData(data: GetConnectivityByIdData) {
    try {
        const response: AxiosResponse = yield call(getConnectivityByIdService, data.locationId, data.connectivityId);
        yield put(locationContainerCreators.successGetConnectivityById(response.data));
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetConnectivityById(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetConnectivityById("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getSoftwareByIdData(data: GetSoftwareByIdData) {
    try {
        const response: AxiosResponse = yield call(getSoftwareByIdService, data.locationId, data.softwareId);
        yield put(locationContainerCreators.successGetSoftwareById(response.data));
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(locationContainerCreators.failureGetSoftwareById(e.response?.data.errors[0].message));
        } else {
            yield put(locationContainerCreators.failureGetSoftwareById("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export default function* locationContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_GET_LOCATIONS, getLocationsData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_SOFTWARE_PROCESSES, getSoftwareProcessesData);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_LOCATIONS, postLocationsData);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_LOCATIONS, putLocationsData);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_LOCATIONS, deleteLocationsData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_HARDWARES, getHardwaresData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_CONNECTIVITIES, getConnectivitiesData);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_SOFTWARES, postSoftwareData);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_SOFTWARES, putSoftwareData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_SOFTWARES, getSoftwareData);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_SOFTWARE, deleteSoftwareData);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_HARDWARES, postHardwaresData);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_CONNECTIVITIES, postConnectivitiesData);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_HARDWARES, putHardwaresData);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_HARDWARE, deleteHardwaresData);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_CONNECTIVITIES, putConnectivitiesData);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_CONNECTIVITY, deleteConnectivityData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_PROD_LOCATION_CONTENT, getProdLocationContentData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_PROD_SOFTWARE_CONTENT, getProdSoftwareContentData);
    //@ts-ignore
    yield takeLatest(REQUEST_GET_PROD_HARDWARE_CONTENT, getProdHardwareContentData);
    //@ts-ignore
    yield takeLatest(REQUEST_GET_PROD_CONNECTIVITY_CONTENT, getProdConnectivityContentData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_HARDWARE_HELP_TEXT, getHardwaresHelpTextData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_SOFTWARE_HELP_TEXT, getSoftwaresHelpTextData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_CONNECTIVITY_HELP_TEXT, getConnectivitiesHelpTextData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_HARDWARE_BY_ID, getHardwareByIdData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_CONNECTIVITY_BY_ID, getConnectivityByIdData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_SOFTWARE_BY_ID, getSoftwareByIdData);
}
