import CurrentOrganisationLayout from '../../components/CurrentOrganisationLayout/CurrentOrganisationLayout'

const testId = `current-organization-container`;

export default function CurrentOrganizationContainer() {
  return (
    <div data-testid={`${testId}-current-org-layout`}>
      <CurrentOrganisationLayout />
    </div>
  )
}
