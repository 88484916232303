import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectDecodedUserInfo } from '../../AppSelector';
import LoadingIcon from '../../assets/icons/loading-icon';
import { listOptionsContainerCreators } from '../../containers/OrganisationContainer/reducer';
import NotificationToast from '../ToastComponent';
import { ErrorObject } from '../../containers/LoginContainer';
import Modal from '../Modal/Modal';
import StyledButton from '../StyledButton';
import schema from '../../validations/schema';
import EditUsePage from '../UsersLayout/EditUsePage';
import { useLocation, useNavigate } from 'react-router-dom';
import { languageProviderActions } from '../../containers/LanguageProvider/reducer';
import Container from '../Container/Container';

export type User = {
    userId: string,
    type: string;
    firstName: string,
    lastName: string,
    email: string,
    emailVerified: boolean,
    mobilePhone: string,
    prefferedLanguage: string,
    created: Date,
    modified: Date,
    role: string
};

export type Organisation = {
    organizationId: string;
    companyName: string;
    chamberofcommercenr: string;
    street: string;
    houseNumber: string;
    houseNumberSuffix: string;
    postalcode: string;
    city: string;
    country: string;
    type: string;
    created: Date;
    modified: Date;
}

type Props = {
    testId?: string
}

export default function AccountLayout(props: Props) {
    const { testId = `account-layout` } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const decodedInfo: DecodedUserInfo | null = useSelector(selectDecodedUserInfo);
    const [isDeleteOrgModal, setIsDeleteOrgModal] = useState<boolean>(false)
    const [userOrgList, setUserOrgList] = useState<Organisation[]>([])
    const [userAccountInfo, setUserAccountInfo] = useState<User>();
    const [isEmailModal, setIsEmailModal] = useState<boolean>(false);
    const errorMessage: string = '';
    const [showToast, setShowToast] = useState<boolean>(false);
    const [showEditEmailToast, setShowEditEmailToast] = useState<boolean>(false);
    const [showDeleteBtnToast, setShowDeleteBtnToast] = useState<boolean>(false)
    const [userEmail, setUserEmail] = useState<string>("");
    const [isEditUser, setIsEditUser] = useState<boolean>(false);
    const [editUserDetail, setEditUserDetail] = useState<User | null>(null);
    const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
    const [organizationDetail, setOrganizationDetail] = useState<Organisation>()
    const location = useLocation();
    const { inviteId }: RootState = location?.state || {};
    const [errObj, setErrObj] = useState<ErrorObject>({
        email: undefined,
    });
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);
    const [notificationMessage, setNotificationMessage] = useState<boolean>(false);
    const [massage, setMessage] = useState<string>("");

    /* istanbul ignore next */
    useEffect(() => {
        if (listOptionsSelector?.status !== null && listOptionsSelector?.status !== undefined && listOptionsSelector?.method !== "delete" && listOptionsSelector?.method !== null) {
            setIsRemoved(false);
            setIsSaved(true);
            setTimeCounter(1);
        } else if (listOptionsSelector?.method !== null && listOptionsSelector?.method !== undefined && listOptionsSelector?.method === "delete" && (listOptionsSelector?.method !== "post" || listOptionsSelector?.method !== "put")) {
            setIsSaved(false);
            setIsRemoved(true);
            setTimeCounter(1);
        }
    }, [listOptionsSelector])
    /* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    useEffect(() => {
        if (decodedInfo && decodedInfo.payload) {
            dispatch(listOptionsContainerCreators.requestGetAccountInfo(decodedInfo.payload.userId))
            dispatch(listOptionsContainerCreators.requestGetUserOrgList(decodedInfo.payload.userId))
            dispatch(languageProviderActions.changeLocale(decodedInfo.payload.prefLang));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedInfo])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (listOptionsSelector.isReloadUserList) {
            dispatch(listOptionsContainerCreators.requestGetAccountInfo(decodedInfo?.payload.userId))
            decodedInfo && dispatch(listOptionsContainerCreators.requestGetUserOrgList(decodedInfo.payload.userId))
            decodedInfo && dispatch(languageProviderActions.changeLocale(decodedInfo.payload.prefLang));
        }
    }, [listOptionsSelector.isReloadUserList])

    useEffect(() => {
        if (listOptionsSelector.accountInfo) {
            setUserAccountInfo(listOptionsSelector.accountInfo)
            setUserEmail(listOptionsSelector.accountInfo.email)
        }
        if (listOptionsSelector.userOrgList) {
            setUserOrgList(listOptionsSelector.userOrgList)
        }
    }, [listOptionsSelector])

    useEffect(() => {
        if (inviteId) {
            dispatch(listOptionsContainerCreators.requestAddUserToOrgByInvite(decodedInfo?.payload.userId, inviteId, setShowToast))
        }
    }, [inviteId])

    function getLangusgeType(languageType: string) {
        if (languageType === "en") {
            return intl.formatMessage({ id: 'english', defaultMessage: 'english' })
        }
        else if (languageType === "nl") {
            return intl.formatMessage({ id: 'nederlands', defaultMessage: 'nederlands' })
        }
    }

    const editEmail = () => {
        setIsEmailModal(true);
    }

    function handleEmailModalClose() {
        setIsEmailModal(false);
        userAccountInfo && setUserEmail(userAccountInfo.email)
    }

    function handleDeleteUserOrg() {
        dispatch(listOptionsContainerCreators.requestDeleteOrgFromList(decodedInfo?.payload, organizationDetail?.organizationId, setShowDeleteBtnToast, dispatch, navigate))
        setIsDeleteOrgModal(false)
    }

    const getErrText = (error: string) => {
        if (error === "Confict, remove last admin not allowed") {
            return intl.formatMessage({ id: 'confict_remove_last_admin_not_allowed',  defaultMessage:'confict_remove_last_admin_not_allowed' });
        } else {
            return error;
        }
    }

    function onCancelUser() {
        setIsEditUser(false)
        setEditUserDetail(null)
    }

    function handleDeleteUserModalClose() {
        setIsDeleteOrgModal(false)
    }

    function editUserPage() {
        if (userAccountInfo) {
            setIsEditUser(true)
            setEditUserDetail(userAccountInfo);
        }
    }

    if (isEditUser && editUserDetail) {
        return <EditUsePage testId={`${testId}-edit-use-page`} editUserDetail={editUserDetail} onCancel={() => onCancelUser()} />
    }

    const deteleUserOrg = (org: Organisation) => {
        setIsDeleteOrgModal(true);
        setOrganizationDetail(org)
    }

    const handleEmailInput = (key: string, value: string) => {
        if (userAccountInfo) {
            setUserEmail(value);
        }
    };

    function handleEmailSave() {
        const emailErr = schema.email.validate(userEmail).error;
        if (emailErr) {
            setErrObj({
                email: { message: intl.formatMessage({ id: emailErr?.message, defaultMessage: emailErr?.message }) },
            });
        } else {
            setErrObj({
                email: undefined,
            });
            if (userAccountInfo) {
                const payload = {
                    firstName: userAccountInfo.firstName,
                    lastName: userAccountInfo.lastName,
                    email: userEmail,
                    mobilePhone: userAccountInfo.mobilePhone,
                    password: "",
                    prefferedLanguage: userAccountInfo.prefferedLanguage,

                }

                dispatch(listOptionsContainerCreators.requestPutUsers(userAccountInfo.userId, payload, '', setShowEditEmailToast))
                setIsEmailModal(false);
                setUserEmail(userAccountInfo.email)
            }
        }
    }

    // function handleCancalNotification() {
    //     setNotificationMessage(false);
    // }

    // function handleSaveMassage() {
    //     const subscriptionMessage = {
    //         message: massage
    //     }
    //     dispatch(listOptionsContainerCreators.requestPostNotificationMessage(subscriptionMessage))
    //     setNotificationMessage(false);
    // }

    return (
        <>
            {listOptionsSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div data-testid={testId}>
                    <Container>
                        {isSaved ?
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                            </div>
                            :
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div className="h-4">{"  "}</div>
                            </div>
                        }
                        {isRemoved ?
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div>{intl.formatMessage({ id: 'removed',defaultMessage:"removed" })}</div>
                            </div>
                            :
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div className="h-4">{"  "}</div>
                            </div>
                        }

                        <div className='my-10 font-semibold text-4xl flex items-start justify-start sm:mt-0 sm:text-3xl'>
                            {intl.formatMessage({ id: 'user_info', defaultMessage: 'user_info' })}
                        </div>
                        <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                            <div className="flex gap-8">
                                <div className='flex-1'>
                                    <div className='flex-col'>
                                        <label data-testid={`${testId}-name`} className="inline-block text-base font-bold mb-1 font-family-arial">
                                            {intl.formatMessage({ id: "name", defaultMessage: 'name' })}
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            {userAccountInfo && userAccountInfo.firstName} {userAccountInfo && userAccountInfo.lastName}
                                        </label>

                                    </div>
                                </div>
                            </div>


                            <div className="flex gap-8">
                                <div className="flex-1">
                                    <div className='flex-col'>
                                        <label data-testid={`${testId}-mobile_number`} className="inline-block text-base font-bold mb-1 font-family-arial">
                                            {intl.formatMessage({ id: "mobile_number", defaultMessage: 'mobile_number' })}
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            {userAccountInfo && userAccountInfo.mobilePhone ? userAccountInfo.mobilePhone : "-"}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-8">
                                <div className="flex flex-col max-w-[370px] w-full">
                                    <div className='flex-col'>
                                        <label data-testid={`${testId}-preffered_language`} className="inline-block text-base font-bold mb-1 font-family-arial">
                                            {intl.formatMessage({ id: "preffered_language", defaultMessage: 'preffered_language' })}
                                        </label>
                                    </div>

                                    <div>
                                        <label>
                                            {userAccountInfo && userAccountInfo.prefferedLanguage ? getLangusgeType(userAccountInfo.prefferedLanguage) : "-"}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                                <StyledButton
                                    testId={`${testId}-edit_user`}
                                    buttonClass={'px-4 py-2'}
                                    width={'min-w-36 max-w-max'}
                                    height={`h-10`}
                                    align={'justify-start'}
                                    text={`${intl.formatMessage({ id: 'edit_user', defaultMessage: 'edit_user' })}`}
                                    color={'text-gray-700'}
                                    iconType={""}
                                    backgroundColor={'transparent border-1'}
                                    onClick={() => editUserPage()}
                                />
                                {showToast && <NotificationToast text={listOptionsSelector.inviteError ? listOptionsSelector.inviteError : intl.formatMessage({ id: 'added_to_org' })} iconType={listOptionsSelector.inviteError ? intl.formatMessage({ id: 'failure' }) : intl.formatMessage({ id: 'success' })} color={listOptionsSelector.inviteError ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showToast} onClick={() => setShowToast(false)} />}
                            </div>
                        </div>

                        <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                            <div className="flex-1">
                                <div className='flex-col'>
                                    <label data-testid={`${testId}-email`} className="inline-block text-base font-bold mb-1 min-w-max">
                                        {intl.formatMessage({ id: "email", defaultMessage: "email" })}
                                    </label>
                                </div>
                                <div>
                                    {userAccountInfo && userAccountInfo.email ? userAccountInfo.email : "-"}
                                </div>
                            </div>
                            <div>
                                <StyledButton
                                    testId={`${testId}-edit_email`}
                                    buttonClass={'px-4 py-2'}
                                    width={'min-w-36 max-w-max'}
                                    height={`h-10`}
                                    align={'justify-start'}
                                    text={intl.formatMessage({ id: 'edit_email', defaultMessage: 'edit_email' })}
                                    color={'text-gray-700'}
                                    flexReverse={'flex-row-reverse'}
                                    iconType={""}
                                    backgroundColor={'transparent border-1'}
                                    onClick={() => editEmail()}
                                />
                                {showToast && <NotificationToast text={listOptionsSelector.inviteError ? listOptionsSelector.inviteError : intl.formatMessage({ id: 'added_to_org',defaultMessage:'added_to_org' })} iconType={listOptionsSelector.inviteError ? intl.formatMessage({ id: 'failure' }) : intl.formatMessage({ id: 'success' })} color={listOptionsSelector.inviteError ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showToast} onClick={() => setShowToast(false)} />}
                            </div>
                        </div>
                        <div>
                            <Modal show={isEmailModal} onClose={() => handleEmailModalClose()}>
                                <div className="lg:min-w-[400px] " >
                                    <div className="font-bold mb-2">
                                        {intl.formatMessage({ id: 'edit_email', defaultMessage: 'edit_email' })}
                                    </div>

                                    <input
                                        maxLength={254}
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.email?.message ? 'border-[red]' : ''}`}
                                        type="text"
                                        data-testid={`${testId}-input-email`}
                                        value={userEmail ?? ""}
                                        onChange={(event) =>
                                            handleEmailInput("email", event.target.value)
                                        }
                                    />
                                    {errObj.email?.message ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-4 flex items-start justify-start'>
                                            <span>{errObj.email?.message}</span>
                                        </div>
                                        :
                                        <div className={`${!errorMessage ? 'mb-8' : ''}`}></div>
                                    }
                                    <div className="font-semibold text-xl text-[#121212] mb-6">
                                    </div>
                                </div>
                                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                                    <StyledButton
                                        testId={`${testId}-cancel`}
                                        width={"w-48 sm:w-full"}
                                        align={"justify-start"}
                                        text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                                        color={"text-[#535353]"}
                                        flexReverse={"flex-row-reverse"}
                                        backgroundColor={"bg-[#EDEDED]"}
                                        onClick={() => handleEmailModalClose()}
                                    />
                                    <StyledButton
                                        testId={`${testId}-save`}
                                        width={"w-48 sm:w-full"}
                                        align={"justify-end"}
                                        buttonClass={'PrimoryBtn'}
                                        text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
                                        backgroundColor={'bg-black'}
                                        color={'text-white'}
                                        iconType={'arrow'}
                                        onClick={() => handleEmailSave()}
                                    />
                                </div>
                            </Modal>
                        </div>
                        {showEditEmailToast && <NotificationToast text={listOptionsSelector.error ? listOptionsSelector.error : intl.formatMessage({ id: 'edit_email_msg' })} iconType={listOptionsSelector.error ? intl.formatMessage({ id: 'failure' }) : intl.formatMessage({ id: 'success' })} color={listOptionsSelector.error ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showEditEmailToast} onClick={() => setShowEditEmailToast(false)} />}

                        <div className='my-10 font-semibold text-4xl flex items-start justify-start sm:mt-0 sm:text-3xl'>
                            {intl.formatMessage({ id: 'user_organization_list', defaultMessage: 'user_organization_list' })}
                        </div>
                        <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                            <div>
                                {userOrgList.length > 0 ?
                                    <div className='border rounded-t-md bg-zinc-50'>
                                        {userOrgList.map((user: Organisation, index: number) => (
                                            <div className={"flex items-center justify-between  px-4 py-3 font-semibold " + (index !== 0 ? " border-t " : "")} key={index}>
                                                <div className="">{user.companyName}</div>
                                                <>
                                                    <div className="w-max flex items-center justify-between">
                                                        <span data-testid={`${testId}-user-org-${index}`} className='cursor-pointer' onClick={() => deteleUserOrg(user)}>
                                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                    <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {showDeleteBtnToast && <NotificationToast text={listOptionsSelector.error ? getErrText(listOptionsSelector.error) : intl.formatMessage({ id: 'success' })} iconType={listOptionsSelector.error ? intl.formatMessage({ id: 'failure' }) : intl.formatMessage({ id: 'success' })} color={listOptionsSelector.error ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showDeleteBtnToast} onClick={() => setShowDeleteBtnToast(false)} />}
                        </div>
                    </Container>
                </div>
            }

            {(isDeleteOrgModal) &&
                <div>
                    <Modal data-testid={`${testId}-is-dlt-org-modal`} show={isDeleteOrgModal} onClose={() => handleDeleteUserModalClose()}>
                        <div>{`${intl.formatMessage({ id: 'are_you_sure', defaultMessage: 'are_you_sure' })}`}</div>
                        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                            <StyledButton
                                testId={`${testId}-delete-modal-cancel-button`}
                                width={"w-48 sm:w-full"}
                                align={"justify-start"}
                                text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                                color={"text-[#535353]"}
                                flexReverse={"flex-row-reverse"}
                                backgroundColor={"bg-[#EDEDED]"}
                                onClick={() => handleDeleteUserModalClose()}
                            />
                            <StyledButton
                                testId={`${testId}-delete-modal-delete-button`}
                                buttonClass={'PrimoryBtn'}
                                width={"w-48 sm:w-full"}
                                align={"justify-end"}
                                text={intl.formatMessage({ id: "delete", defaultMessage: 'delete' })}
                                backgroundColor={'bg-black'}
                                color={'text-white'}
                                onClick={() => handleDeleteUserOrg()}
                            />
                        </div>
                    </Modal>
                </div>
            }
            {/* <div>
                <Modal show={notificationMessage} onClose={() => handleCancalNotification()}>
                    <div className="lg:min-w-[400px] sm:w-full">
                        <div className="font-bold mb-2">
                            {intl.formatMessage({ id: 'test_notification', defaultMessage: 'test_notification' })}
                        </div>
                        <input className={`bg-zinc-50 border rounded-lg mb-2 p-3 py-2 w-full ${errObj.email?.message ? 'border-[red]' : ''}`} type="text" value={massage} onChange={(e) => setMessage(e.target.value)} />

                        <div className="font-semibold text-xl text-[#121212] mb-6">
                        </div>
                    </div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-test-notify-cancel-button`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => handleCancalNotification()}
                        />
                        <StyledButton
                            testId={`${testId}-test-notify-save-button`}
                            buttonClass={'PrimoryBtn'}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => handleSaveMassage()}
                        />
                    </div>
                </Modal>
            </div> */}
        </>
    )
}
