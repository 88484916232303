import { useEffect, useState, useRef } from "react";
import { selectDecodedUserInfo } from "../../AppSelector";
import { useSelector } from "react-redux";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg";
import { useIntl } from "react-intl";

type Option = {
    value: string | number;
    label: string;
    isDisable?: boolean;
}

type Props = {
    value?: string | number;
    options?: Option[];
    onChange?: (value: string | number | any) => void;
    testId?: string;
    isForceEnable?: boolean;
    error?: string;
}


const Dropdown = ({ value, options, onChange, testId = '', isForceEnable, error = '' }: Props) => {
    const intl = useIntl();
    const ref = useRef<HTMLHeadingElement>(null);
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload?.role;
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const selectedItem: Option | null = options?.find((item) => item.value === value) || null;

    const initialOption: Option = {
        label: intl.formatMessage({ id: 'select', defaultMessage: 'select' }),
        value: "",
    }

    const [selectedOption, setSelectedOption] = useState<Option>(selectedItem ?? initialOption);
    const handleChange = (option: Option) => {
        if (!option?.isDisable) {
            setSelectedOption({ ...option });
            setShowOptions(false);
            if (onChange) onChange(option.value);
        }
    }

    useEffect(() => {
        if (selectedItem && Object.keys(selectedItem).length) {
            setSelectedOption(selectedItem || initialOption)
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, selectedItem]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                setShowOptions(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    return (
        <div className="relative" ref={ref} data-testid={testId}>
            <div>
                <button disabled={(role === "administrator" || role === "editor") || isForceEnable ? false : true} type="button" className={`inline-flex justify-between font-family-arial w-full rounded-md border shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none ${error !== '' ? 'border-[red]' : 'border-gray-300'}`} aria-expanded="true" aria-haspopup="true" onClick={() => setShowOptions(!showOptions)}>
                    {selectedOption?.label}
                    <img src={ChevronDownIcon} alt="" />
                </button>
            </div>
            <div className={(showOptions ? "block " : "hidden ") + "origin-top-right absolute right-0 w-full z-50 rounded-md shadow-lg overflow-hidden bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"} role="menu" aria-orientation="vertical">
                <ul role="listbox" className="max-h-56 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                    {options?.map((option: Option, index: number) => {
                        return (

                            <li key={index} role="option" aria-selected data-testid={`${testId}-option-item-${index}`} className={`${selectedOption.value === option.value ? "bg-[#FCAF3B] " : ""} ${option.isDisable ? "opacity-50 " : ""} text-sm text-gray-900 cursor-pointer select-none hover:bg-[#FCAF3B] relative py-2 pl-4 pr-9`} onClick={() => handleChange(option)}>
                                <div className="flex items-center space-x-3">
                                    <span className="font-normal font-family-arial block truncate">
                                        {option.label} {option.isDisable ? intl.formatMessage({ id: 'disable_label', defaultMessage: 'disable_label' }) : ''}
                                    </span>
                                </div>
                            </li>
                        )
                    }
                    )}
                </ul>
            </div>
        </div>
    )
}

export default Dropdown
