import { ListOptionType } from '@onesixeight-nl/classes';
import axios, {
    AxiosError,
    AxiosPromise,
    AxiosResponse
} from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { DefaultActionCreators } from 'reduxsauce';
import { appContainerCreators } from '../../AppReducer';
import { USER_COOKIE } from '../../enum';
import { getListOptionsService } from '../../services/ListOptionsService';
import {
    addAddressDataService, deleteAddressDataService, getAddressesDataService, putAddressDataService, getOrganizationDataService, getRolesService, deleteUserService, putRoleService, putOrganizationDataService, addProductDataService, deleteProductDataService
    , getProductDataService, putProductDataService, addProcessDataService, getProcessDataService, putProcessDataService, deleteProcessDataService, putUserService, getInviteUserService, postInviteUserService, deleteInviteUserService, resendInviteUserService,
    getUserService, getCurrentOrgInfoService, putCurrentOrgInfoService, getOrgListService, deleteOrgService, addUserToOrgByInvite, deleteUserOrgService, getUsersLinkedToOrgService, createNewClientOrgService, getUsersRoleLimitsService, getClientOrgService, getAllClientOrgService, putUserLinkToClientOrgService, deleteClientService, getLinkedPartnerOrg, DeleteUserFromClientOrganizationService, AddLinkedPartnerOrgToUser, putOrgUserService, getOtpService, verifyAndEnableOtpService, validateOtpService, validateTwoFaTokenService, SubscribeForNotificationService, notificationsMessageService,
} from '../../services/Organization';
import { getOrganizationUsersService } from '../../services/OrganizationUser';
import { dashboardContainerCreators } from '../ProtectedContainer/dashboardReducer';
import { listOptionsContainerTypes, listOptionsContainerCreators, clearListOptionContainerCreators } from './reducer';
import { IntlShape } from 'react-intl';
import { getBaseURL } from '../../utils';
import cookies from '../../utils/coockies';
import { clearLocationStatusCreators } from '../LocationsContainer/locationReducer';
import { redirectUrlCreators } from '../LoginContainer/reducer';
/* istanbul ignore next */
export const getInviteUserByIdService = (inviteId: string): AxiosPromise => axios.get(`${getBaseURL()}/organizationservice/v1/invite/${inviteId}`)
/* istanbul ignore next */
export const createUserService = (duplicateOrgIntentional: boolean, inviteId: string, data: object): AxiosPromise => axios.post(`${getBaseURL()}/organizationservice/v1/users?duplicateOrgIntentional=${duplicateOrgIntentional}&inviteId=${inviteId}`, data)

type DeleteUserPayload = {
    organizationId: string;
    userId: string;
}

type PutOrgUserPayload = {
    organizationId: string;
    userId: string;
    data: object
}

type DeleteInviteUser = {
    inviteId: string
}

type GetInviteUserByID = {
    inviteId: string
    setIsCreateAccount: React.Dispatch<React.SetStateAction<boolean>>;
    navigate: (path: string, state?: object) => void
    intl: IntlShape
}

type ResendInviteUser = {
    inviteId: string;
    setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
}

type GetAccountInfo = {
    userId: string;
}

type GetCurrentOrgInfo = {
    organizationId: string;
    dispatch?: (action: DefaultActionCreators) => void;
    intl?: IntlShape;
}

type PutCurrentOrgInfo = {
    organizationId: string;
    data: object;
}

type GetUserOrgListData = {
    userId: string;
}

type DeleteUserOrgData = {
    organizationId: string;
    setShowDeleteCurrentOrgToast?: React.Dispatch<React.SetStateAction<boolean>>;
    navigate?: (path: string, state?: object) => void;
}

type DeleteUserOrgListData = {
    organizationId: string;
    payload: Payload;
    setShowDeleteBtnToast: React.Dispatch<React.SetStateAction<boolean>>;
    dispatch?: (action: DefaultActionCreators) => void;
    navigate?: (path: string, state?: object) => void
}

type PutRolePayload = {
    organizationId: string;
    userId: string;
    data: {
        roles: string
    }
}

type ListOptionsDataCallType = {
    id: string;
}

type PostInviteUser = {
    data: {
        email: string,
        initialRoles: {
            roles: [
                string
            ]
        }
    }
    resetData: () => void;
}

type AddUserToOrgByInviteType = {
    userId: string;
    inviteId: string;
    setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
}

type SetUserDataCallType = {
    isOrgExist: {
        duplicateOrgIntentional: boolean;
        inviteId: string;
    },
    data: {
        user: object,
        organization: object
    },
    setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
    navigate?: (path: string, state?: object) => void;
}

type GetUsersLinkedToOrganization = {
    organizationId: string;
}

type GetClientOrgListData = {
    organizationId: string;
}

type CreateNewClientOrganization = {
    organizationId: string;
    data: object;
    setShowAddClientToast: React.Dispatch<React.SetStateAction<boolean>>;
    onCancel: (toast: boolean) => void;
    navigate?: (path: string, state?: object) => void;
}

interface Payload {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    orgId: string;
    orgType: number;
    orgName: string;
    role: string;
    plan: number;
    prefLang: string;
    UserInfo: string[];
    partnerId?: string;
    partner?: string;
}

type PutUserData = {
    userId: string;
    data: object;
    dispatch?: (action: DefaultActionCreators) => void;
    setShowEditEmailToast?: React.Dispatch<React.SetStateAction<boolean>>;
    page?: string;
}

declare interface DecodedUserInfo {
    payload: Payload;
    iat: number;
    exp: number;
}

type GetAddressesDataCall = {
    dispatch?: (action: DefaultActionCreators) => void;
    decodedUserInfo?: DecodedUserInfo | null;
    intl?: IntlShape;
}

type GetUsersRoleLimits = {
    organizationId: string;
}

type GetLinkedPartnerOrg = {
    organizationId: string;
    userId: string;
}

type GetSoftwareStorageData = {
    typeId: string;
}

type GetSoftwareBackupData = {
    typeId: string;
}

type GetAllClientOrgData = {
    organizationId: string;
    clientOrganizationId: string;
}

type PutUserLinkToClientOrgData = {
    organizationId: string;
    clientOrganizationId: string;
    data: object;
    onCancel: (toast: boolean) => void;
    navigate?: (path: string, state?: object) => void;
}

type DeleteClientData = {
    organizationId: string;
    clientOrganizationId: string;
}

type DeleteUserFromClientData = {
    organizationId: string;
    userId: string;
    clientOrganizationId: string;
}

type PostUserFromClientData = {
    organizationId: string;
    userId: string;
    clientOrganizationId: string;
    data: object;
    setClientsListModal?: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
}

type VarifyOtp = {
    code: string;
    navigate?: (path: string, state?: object) => void;
}

type ValidateOtp = {
    code: string;
    navigate?: (path: string, state?: object) => void;
}

type ValidateToken = {
    code: string;
    navigate?: (path: string, state?: object) => void;
}

const {
    REQUEST_GET_LIST_OPTIONS_BUSINESS,
    REQUEST_GET_LIST_OPTIONS_EMPLOYEES,
    REQUEST_GET_LIST_OPTIONS_REVENUE,
    REQUEST_GET_LIST_OPTIONS_PROCESSES,
    REQUEST_GET_ORGANIZATION,
    REQUEST_GET_ROLES,
    REQUEST_DELETE_USER,
    REQUEST_PUT_ROLE,
    REQUEST_PUT_ORGANIZATION,
    REQUEST_GET_ADDRESSES,
    REQUEST_ADD_ADDRESS,
    REQUEST_DELETE_ADDRESS,
    REQUEST_PUT_ADDRESS,
    REQUEST_GET_PRODUCTS,
    REQUEST_ADD_PRODUCT,
    REQUEST_DELETE_PRODUCT,
    REQUEST_PUT_PRODUCT,
    REQUEST_GET_PROCESSES,
    REQUEST_ADD_PROCESS,
    REQUEST_DELETE_PROCESS,
    REQUEST_PUT_PROCESS,
    REQUEST_PUT_USERS,
    REQUEST_GET_ORGANIZATION_USERS,
    REQUEST_GET_INVITE_USER,
    REQUEST_POST_INVITE_USER,
    REQUEST_DELETE_INVITE_USER,
    REQUEST_RESEND_INVITE_USER,
    REQUEST_GET_ACCOUNT_INFO,
    REQUEST_GET_INVITE_USER_BY_ID,
    REQUEST_GET_CURRENT_ORG_INFO,
    REQUEST_PUT_CURRENT_ORG_INFO,
    REQUEST_GET_USER_ORG_LIST,
    REQUEST_DELETE_USER_ORG,
    REQUEST_ADD_USER_TO_ORG_BY_INVITE,
    REQUEST_DELETE_ORG_FROM_LIST,
    REQUEST_SET_INVITE_USER,
    REQUEST_GET_USER_LINK_TO_ORG,
    REQUEST_CREATE_NEW_CLIENT_ORG,
    REQUEST_GET_USERS_ROLE_LIMITS,
    REQUEST_GET_SOFTWARE_STORAGE,
    REQUEST_GET_SOFTWARE_BACKUP,
    REQUEST_GET_CLIENT_ORG_LIST,
    REQUEST_GET_ALL_CLIENT_ORG,
    REQUEST_PUT_CLIENT_ORG,
    REQUEST_DELETE_CLIENT,
    REQUEST_GET_LINKED_PARTNER_ORG,
    REQUEST_DELETE_USER_FROM_CLIENT_ORG,
    REQUEST_POST_LINKED_PARTNER_ORG_TO_USER,
    REQUEST_PUT_ORG_USER,
    REQUEST_GET_OTP,
    REQUEST_POST_VARIFY_OTP,
    REQUEST_POST_VALIDATE_OTP,
    REQUEST_POST_VALIDATE_TWOFA_TOKEN,
    REQUEST_POST_NOTIFICATION,
    REQUEST_POST_NOTIFICATION_MESSAGE
} = listOptionsContainerTypes;
/* istanbul ignore next */
export function* listOptionsBusinessDataCall() {
    try {
        const response: AxiosResponse = yield call(getListOptionsService, ListOptionType.BUSINESS_SECTOR);
        yield put(listOptionsContainerCreators.successGetListOptionsBusiness(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetListOptionsBusiness(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetListOptionsBusiness("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* listOptionsEmployeesDataCall() {
    try {
        const response: AxiosResponse = yield call(getListOptionsService, ListOptionType.NUMBER_OF_EMPLOYEES);
        yield put(listOptionsContainerCreators.successGetListOptionsEmployees(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetListOptionsEmployees(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetListOptionsEmployees("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* listOptionsRevenueDataCall() {
    try {
        const response: AxiosResponse = yield call(getListOptionsService, ListOptionType.ANNUAL_REVENUE);
        yield put(listOptionsContainerCreators.successGetListOptionsRevenue(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetListOptionsRevenue(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetListOptionsRevenue("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getOrganizationDataCall() {
    try {
        const response: AxiosResponse = yield call(getOrganizationDataService);
        yield put(listOptionsContainerCreators.successGetOrganization(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetOrganization(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetOrganization("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getRolesData({ organizationId, userId }: DeleteUserPayload) {
    try {
        const response: AxiosResponse = yield call(getRolesService, organizationId, userId);
        yield put(listOptionsContainerCreators.successGetRoles(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetRoles(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetRoles("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* deleteUserData({ organizationId, userId }: DeleteUserPayload) {
    try {
        const response: AxiosResponse = yield call(deleteUserService, organizationId, userId);
        yield put(clearListOptionContainerCreators.clearListOptionStatus())
        yield put(listOptionsContainerCreators.successDeleteUser(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(clearListOptionContainerCreators.clearListOptionStatus())
            yield put(listOptionsContainerCreators.failureDeleteUser(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureDeleteUser("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putUsersData(data: PutUserData) {
    try {
        const response: AxiosResponse = yield call(putUserService, data.userId, data.data);
        if (response) {
            data.setShowEditEmailToast && data.setShowEditEmailToast(true);
            setTimeout(() => {
                data.setShowEditEmailToast && data.setShowEditEmailToast(false);
            }, 5000)
        }
        yield put(listOptionsContainerCreators.successPutUsers(response.data, response.status, response.config.method))
        if (response && data.dispatch && data?.page !== 'twoFa') {
            const refreshToken = cookies.get(USER_COOKIE);
            data.dispatch(appContainerCreators.getUserInfo(refreshToken))
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e) {
                data.setShowEditEmailToast && data.setShowEditEmailToast(true);
                setTimeout(() => {
                    data.setShowEditEmailToast && data.setShowEditEmailToast(false);
                }, 5000)
            }
            yield put(listOptionsContainerCreators.failurePutUsers(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePutUsers("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putRoleDataCall({ organizationId, userId, data }: PutRolePayload) {
    try {
        const response: AxiosResponse = yield call(putRoleService, organizationId, userId, data);
        yield put(listOptionsContainerCreators.successPutRole(response.data, response.status))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePutRole(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePutRole("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putOrganizationDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(putOrganizationDataService, data.data);
        yield put(listOptionsContainerCreators.successPutOrganization(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePutOrganization(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePutOrganization("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getAddressesDataCall(data: GetAddressesDataCall) {
    try {
        const response: AxiosResponse = yield call(getAddressesDataService);
        const filteredAddresses = response.data.filter((ele: any) => ele.type !== 'hosting')
        if (filteredAddresses && filteredAddresses.length === 0 && data?.dispatch && data?.decodedUserInfo) {
            data?.dispatch(listOptionsContainerCreators.requestGetCurrentOrgInfo(data?.decodedUserInfo?.payload.orgId, data.dispatch, data?.intl));
        }
        yield put(clearLocationStatusCreators.clearLocationStatus());
        yield put(redirectUrlCreators.clearRedirectUrl())
        yield put(listOptionsContainerCreators.successGetAddresses(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetAddresses(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetAddresses("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* addAddressesDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(addAddressDataService, data.data);
        yield put(listOptionsContainerCreators.successAddAddress(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureAddAddress(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureAddAddress("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* deleteAddressesDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(deleteAddressDataService, data.data);
        yield put(listOptionsContainerCreators.successDeleteAddress(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureDeleteAddress(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureDeleteAddress("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putAddressDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(putAddressDataService, data.id, data.data);
        yield put(listOptionsContainerCreators.successPutAddress(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePutAddress(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePutAddress("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getProductsDataCall() {
    try {
        const response: AxiosResponse = yield call(getProductDataService);
        yield put(redirectUrlCreators.clearRedirectUrl())
        yield put(listOptionsContainerCreators.successGetProducts(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetProducts(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetProducts("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* addProductsDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(addProductDataService, data.data);
        yield put(listOptionsContainerCreators.successAddProduct(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureAddProduct(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureAddProduct("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* deleteProductsDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(deleteProductDataService, data.data);
        yield put(listOptionsContainerCreators.successDeleteProduct(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureDeleteProduct(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureDeleteProduct("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putProductDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(putProductDataService, data.id, data.data);
        yield put(listOptionsContainerCreators.successPutProduct(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePutProduct(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePutProduct("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* listOptionsProcessDataCall() {
    try {
        const response: AxiosResponse = yield call(getListOptionsService, ListOptionType.PROCESSES);
        yield put(listOptionsContainerCreators.successGetListOptionsProcesses(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetListOptionsProcesses(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetListOptionsProcesses("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getProcessesDataCall({ navigate, isCancel }: any) {
    try {
        const response: AxiosResponse = yield call(getProcessDataService);
        const listOfProcess = response.data.filter((ele: any) => ele.processListId !== '00000000-0000-0000-0000-000000000001')
        const filteredBiaOverview = listOfProcess.find((ele: any) => ele.BIA0h === null || ele.BIA0h === 0);
        yield put(listOptionsContainerCreators.successGetProcesses(listOfProcess))
        yield put(redirectUrlCreators.clearRedirectUrl())
        if (filteredBiaOverview && isCancel !== '0') {
            navigate(`/bia/${filteredBiaOverview.processId}/edit`, { state: { singleBiaObj: filteredBiaOverview } });
        } else {
            navigate("/bia?isCancel=0");
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetProcesses(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetProcesses("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* addProcessesDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(addProcessDataService, data.data);
        yield put(listOptionsContainerCreators.successAddProcess(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureAddProcess(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureAddProcess("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* deleteProcessesDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(deleteProcessDataService, data.data);
        yield put(listOptionsContainerCreators.successDeleteProcess(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureDeleteProcess(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureDeleteProcess("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putProcessDataCall(data: any) {
    try {
        const response: AxiosResponse = yield call(putProcessDataService, data.id, data.data);
        if (response) {
            yield put(listOptionsContainerCreators.requestGetProcesses(data.navigate, data.isCancel));
        }
        yield put(listOptionsContainerCreators.successPutProcess(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePutProcess(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePutProcess("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getUsersDataCall(data: ListOptionsDataCallType) {
    try {
        yield put(redirectUrlCreators.clearRedirectUrl())
        const response: AxiosResponse = yield call(
            getOrganizationUsersService,
            data.id
        );
        yield put(listOptionsContainerCreators.successGetOrganizationUsers(response.data));
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(
                listOptionsContainerCreators.failureGetOrganizationUsers(
                    e.response?.data.errors[0].message
                )
            );
        } else {
            yield put(
                listOptionsContainerCreators.failureGetOrganizationUsers(
                    "Something went wrong, please try again later!"
                )
            );
        }
    }
}
/* istanbul ignore next */
export function* getInviteUser() {
    try {
        const response: AxiosResponse = yield call(
            getInviteUserService
        );
        yield put(clearListOptionContainerCreators.clearListOptionStatus())
        yield put(listOptionsContainerCreators.successGetInviteUser(response.data));
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(
                listOptionsContainerCreators.failureGetInviteUser(
                    e.response?.data.errors[0].message
                )
            );
        } else {
            yield put(
                listOptionsContainerCreators.failureGetInviteUser(
                    "Something went wrong, please try again later!"
                )
            );
        }
    }
}
/* istanbul ignore next */
export function* postInviteUser(data: PostInviteUser) {
    try {
        const response: AxiosResponse = yield call(
            postInviteUserService,
            data.data
        );
        if (response) {
            data.resetData();
        }
        yield put(listOptionsContainerCreators.successPostInviteUser(response.data, response.status, response.config.method));
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(
                listOptionsContainerCreators.failurePostInviteUser(
                    e.response?.data.errors[0].message
                )
            );
        } else {
            yield put(
                listOptionsContainerCreators.failurePostInviteUser(
                    "Something went wrong, please try again later!"
                )
            );
        }
    }
}
/* istanbul ignore next */
export function* deleteInviteUser(data: DeleteInviteUser) {
    try {
        const response: AxiosResponse = yield call(
            deleteInviteUserService,
            data.inviteId
        );
        yield put(listOptionsContainerCreators.successDeleteInviteUser(response.data, response.config.method));
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(
                listOptionsContainerCreators.failureDeleteInviteUser(
                    e.response?.data.errors[0].message
                )
            );
        } else {
            yield put(
                listOptionsContainerCreators.failureDeleteInviteUser(
                    "Something went wrong, please try again later!"
                )
            );
        }
    }
}
/* istanbul ignore next */
export function* resendInviteUser(data: ResendInviteUser) {
    try {
        const response: AxiosResponse = yield call(
            resendInviteUserService,
            data.inviteId
        );
        if (response) {
            data.setShowToast(true);
            setTimeout(() => {
                data.setShowToast(false);
            }, 2000)
            data.setIsDisable(true)
            setTimeout(() => {
                data.setIsDisable(false);
            }, 30000)
        }
        yield put(listOptionsContainerCreators.successResendInviteUser(response.data, response.status, response.config.method));
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(
                listOptionsContainerCreators.failureResendInviteUser(
                    e.response?.data.errors[0].message
                )
            );
        } else {
            yield put(
                listOptionsContainerCreators.failureResendInviteUser(
                    "Something went wrong, please try again later!"
                )
            );
        }
    }
}

// get invite user By id
/* istanbul ignore next */
export function* getInviteUserByIdData(data: GetInviteUserByID) {
    try {
        const response: AxiosResponse = yield call(
            getInviteUserByIdService,
            data.inviteId
        );
        if (response) {
            if (response.data === true) {
                setTimeout(() => {
                    data.setIsCreateAccount(true);
                }, 0)
            } else {
                setTimeout(() => {
                    data.navigate("/", { state: { inviteId: data.inviteId } });
                }, 0)
            }
        }
        yield put(listOptionsContainerCreators.successGetInviteUserById(response.data));
    } catch (e) {
        if (e instanceof AxiosError && !e.response?.data) {
            yield put(
                listOptionsContainerCreators.failureGetInviteUserById(
                    e.response?.data.errors[0].message
                )
            );
        } else {
            yield put(
                listOptionsContainerCreators.failureGetInviteUserById(
                    data.intl.formatMessage({ id: 'use_invite_err_msg' })
                )
            );
        }
    }
}
/* istanbul ignore next */
export function* getAccountInfo(data: GetAccountInfo) {
    try {
        const response: AxiosResponse = yield call(getUserService, data.userId);
        yield put(clearListOptionContainerCreators.clearListOptionStatus())
        yield put(listOptionsContainerCreators.successGetAccountInfo(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetAccountInfo(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetAccountInfo("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getCurrentOrgInfo(data: GetCurrentOrgInfo) {
    try {
        const response: AxiosResponse = yield call(getCurrentOrgInfoService, data.organizationId);
        if (response && response.data && data.dispatch) {
            const payload = {
                name: data.intl && data.intl.formatMessage({ id: "main_location" }),
                type: String(response.data.type),
                street: response.data.street,
                houseNumber: response.data.houseNumber,
                houseNumberSuffix: response.data.houseNumberSuffix,
                postalcode: response.data.postalcode,
                city: response.data.city,
                country: response.data.country
            }
            data.dispatch(listOptionsContainerCreators.requestAddAddress(payload));
        }
        yield put(clearListOptionContainerCreators.clearListOptionStatus())
        yield put(listOptionsContainerCreators.successGetCurrentOrgInfo(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetCurrentOrgInfo(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetCurrentOrgInfo("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putCurrentOrgInfo(data: PutCurrentOrgInfo) {
    try {
        const response: AxiosResponse = yield call(putCurrentOrgInfoService, data.organizationId, data.data);
        yield put(listOptionsContainerCreators.successPutCurrentOrgInfo(response.data, response.status, response.config.method))
        yield put(redirectUrlCreators.clearRedirectUrl())
        const refreshToken = cookies.get(USER_COOKIE);
        yield put(appContainerCreators.getUserInfo(refreshToken))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePutCurrentOrgInfo(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePutCurrentOrgInfo("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getUserOrgList(data: GetUserOrgListData) {
    try {
        const response: AxiosResponse = yield call(getOrgListService, data.userId);
        yield put(listOptionsContainerCreators.successGetUserOrgList(response.data))
        yield put(redirectUrlCreators.clearRedirectUrl())
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetUserOrgList(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetUserOrgList("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* deleteUserOrg(data: DeleteUserOrgData) {
    try {
        const response: AxiosResponse = yield call(deleteOrgService, data.organizationId);
        yield put(listOptionsContainerCreators.successDeleteUserOrg(response.data, response.config.method))
        yield put(dashboardContainerCreators.requestPostSwitchOrg({
            organizationId: '',
        }));
        if (response && data.navigate) {
            data?.navigate('/dashboard');
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            if (data.setShowDeleteCurrentOrgToast) {
                data.setShowDeleteCurrentOrgToast(true);
                setTimeout(() => {
                    data.setShowDeleteCurrentOrgToast && data?.setShowDeleteCurrentOrgToast(false);
                }, 5000)
            }
            yield put(listOptionsContainerCreators.failureDeleteUserOrg(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureDeleteUserOrg("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* addUserToOrganizationByInvite({ userId, inviteId, setShowToast }: AddUserToOrgByInviteType) {
    try {
        const response: AxiosResponse = yield call(
            addUserToOrgByInvite, userId, inviteId
        );
        if (response) {
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 2000)
        }
        yield put(listOptionsContainerCreators.successAddUserToOrgByInvite(response.data));
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e) {
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 2000)
            }
            yield put(
                listOptionsContainerCreators.failureAddUserToOrgByInvite(
                    e.response?.data.errors[0].message
                )
            );
        } else {
            yield put(
                listOptionsContainerCreators.failureAddUserToOrgByInvite(
                    "Something went wrong, please try again later!"
                )
            );
        }
    }
}
/* istanbul ignore next */
export function* deleteUserOrgList(data: DeleteUserOrgListData) {
    try {
        const response: AxiosResponse = yield call(deleteUserOrgService, data.payload.userId, data.organizationId);
        if (response) {
            data.setShowDeleteBtnToast(true);
            setTimeout(() => {
                data.setShowDeleteBtnToast(false);
                if (data.payload.orgId === data.organizationId) {
                    const refreshToken = cookies.get(USER_COOKIE);
                    data?.dispatch && data?.dispatch(appContainerCreators.getUserInfo(refreshToken))
                    data?.navigate && data?.navigate("/dashboard");
                }
            }, 5000)
        }
        yield put(listOptionsContainerCreators.successDeleteOrgFromList(response.data, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e) {
                data.setShowDeleteBtnToast(true);
                setTimeout(() => {
                    data.setShowDeleteBtnToast(false);
                }, 5000)
            }
            yield put(listOptionsContainerCreators.failureDeleteOrgFromList(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureDeleteOrgFromList("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* createInviteUserAsync(data: SetUserDataCallType) {
    try {
        const response: AxiosResponse = yield call(createUserService, data.isOrgExist.duplicateOrgIntentional, data.isOrgExist.inviteId, data.data);
        if (response) {
            data?.navigate && data?.navigate(`/`, { state: { toastFromInvite: true } })
        }
        yield put(listOptionsContainerCreators.successSetInviteUser(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e) {
                data.setShowToast(true);
                setTimeout(() => {
                    data.setShowToast(false);
                }, 5000)
            }
            yield put(listOptionsContainerCreators.failureSetInviteUser(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureSetInviteUser("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getUsersLinkedToOrganization(data: GetUsersLinkedToOrganization) {
    try {
        const response: AxiosResponse = yield call(getUsersLinkedToOrgService, data.organizationId);
        yield put(clearListOptionContainerCreators.clearListOptionStatus())
        yield put(listOptionsContainerCreators.successGetUserLinkToOrg(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetUserLinkToOrg(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetUserLinkToOrg("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getClientOrgListData(data: GetClientOrgListData) {
    try {
        const response: AxiosResponse = yield call(getClientOrgService, data.organizationId);
        yield put(clearListOptionContainerCreators.clearListOptionStatus())
        yield put(listOptionsContainerCreators.successGetClientOrgList(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetClientOrgList(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetClientOrgList("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* createNewClientOrganization(data: CreateNewClientOrganization) {
    try {
        const response: AxiosResponse = yield call(createNewClientOrgService, data.organizationId, data.data);
        if (response) {
            if (data.onCancel) {
                data.onCancel(true);
            } else {
                data.navigate && data.navigate("/dashboard", { state: { toastFromAddClient: true } })
            }
        }
        yield put(listOptionsContainerCreators.successCreateNewClientOrg(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            if (e) {
                data.setShowAddClientToast(true);
                setTimeout(() => {
                    data.setShowAddClientToast(false);
                }, 4000)
            }
            yield put(listOptionsContainerCreators.failureCreateNewClientOrg(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureCreateNewClientOrg("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getUsersRoleLimits(data: GetUsersRoleLimits) {
    try {
        const response: AxiosResponse = yield call(getUsersRoleLimitsService, data.organizationId);
        yield put(clearListOptionContainerCreators.clearListOptionStatus())
        yield put(listOptionsContainerCreators.successGetUsersRoleLimits(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetUsersRoleLimits(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetUsersRoleLimits("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getSoftwareStorageData(data: GetSoftwareStorageData) {
    try {
        const response: AxiosResponse = yield call(getListOptionsService, data.typeId);
        yield put(listOptionsContainerCreators.successGetSoftwareStorage(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetSoftwareStorage(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetSoftwareStorage("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getSoftwareBackupData(data: GetSoftwareBackupData) {
    try {
        const response: AxiosResponse = yield call(getListOptionsService, data.typeId);
        yield put(listOptionsContainerCreators.successGetSoftwareBackup(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetSoftwareBackup(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetSoftwareBackup("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getAllClientOrgData(data: GetAllClientOrgData) {
    try {
        const response: AxiosResponse = yield call(getAllClientOrgService, data.organizationId, data.clientOrganizationId);
        yield put(listOptionsContainerCreators.successGetAllClientOrg(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetAllClientOrg(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetAllClientOrg("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putUserLinkToClientOrgData(data: PutUserLinkToClientOrgData) {
    try {
        const response: AxiosResponse = yield call(putUserLinkToClientOrgService, data.organizationId, data.clientOrganizationId, data.data);
        if (response) {
            if (data.onCancel) {
                data.onCancel(true);
            } else {
                data.navigate && data.navigate("/dashboard", { state: { toastFromAddClient: true } })
            }
        }
        yield put(listOptionsContainerCreators.successPutClientOrg(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePutClientOrg(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePutClientOrg("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* deleteClientData(data: DeleteClientData) {
    try {
        const response: AxiosResponse = yield call(deleteClientService, data.organizationId, data.clientOrganizationId);
        yield put(listOptionsContainerCreators.successDeleteClient(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureDeleteClient(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureDeleteClient("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* getlinkPartnerOrganisation(data: GetLinkedPartnerOrg) {
    try {
        const response: AxiosResponse = yield call(getLinkedPartnerOrg, data.organizationId, data.userId);
        yield put(listOptionsContainerCreators.successGetLinkedPartnerOrg(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetLinkedPartnerOrg(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetLinkedPartnerOrg("Something went wrong, please try again later!"));
        }
    }
}

//delete user from client org
/* istanbul ignore next */
export function* deleteUserFromClientOrg(data: DeleteUserFromClientData) {
    try {
        const response: AxiosResponse = yield call(DeleteUserFromClientOrganizationService, data.organizationId, data.userId, data.clientOrganizationId);
        yield put(listOptionsContainerCreators.successDeleteUserFromClientOrg(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureDeleteUserFromClientOrg(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureDeleteUserFromClientOrg("Something went wrong, please try again later!"));
        }
    }
}

//post linked partner org to user
/* istanbul ignore next */
export function* postLinkedPartnerOrgToUser(data: PostUserFromClientData) {
    try {
        const response: AxiosResponse = yield call(AddLinkedPartnerOrgToUser, data.organizationId, data.userId, data.data,);
        if (response) {
            data.setErrorMessage && data.setErrorMessage('');
        }
        yield put(listOptionsContainerCreators.successPostLinkedPartnerOrgToUser(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePostLinkedPartnerOrgToUser(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePostLinkedPartnerOrgToUser("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* putOrgUserData({ organizationId, userId, data }: PutOrgUserPayload) {
    try {
        const response: AxiosResponse = yield call(putOrgUserService, organizationId, userId, data);
        yield put(listOptionsContainerCreators.successPutOrgUser(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePutOrgUser(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePutOrgUser("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* getOtp() {
    try {
        const response: AxiosResponse = yield call(getOtpService);
        yield put(listOptionsContainerCreators.successGetOtp(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failureGetOtp(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failureGetOtp("Something went wrong, please try again later!"));
        }
    }
}

/* istanbul ignore next */
export function* postverifyAndEnableOtp(data: VarifyOtp) {
    try {
        const response: AxiosResponse = yield call(verifyAndEnableOtpService, data.code);
        yield put(appContainerCreators.updateUserInfo(response.data))
        yield put(listOptionsContainerCreators.successPostVarifyOtp(response.data))
        if (response && data.navigate) {
            data?.navigate('/auth-success');
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePostVarifyOtp(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePostVarifyOtp("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* postValidateCode(data: ValidateOtp) {
    try {
        const response: AxiosResponse = yield call(validateOtpService, data.code);
        yield put(appContainerCreators.updateUserInfo(response.data))
        yield put(listOptionsContainerCreators.successPostValidateOtp(response.data))
        if (response && data.navigate) {
            data?.navigate('/dashboard');
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePostValidateOtp(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePostValidateOtp("Something went wrong, please try again later!"));
        }
    }
}

//validate token
/* istanbul ignore next */
export function* validatetwoFaToken(data: ValidateToken) {
    try {
        const response: AxiosResponse = yield call(validateTwoFaTokenService, data.code);
        yield put(appContainerCreators.updateUserInfo(response.data))
        yield put(listOptionsContainerCreators.successPostValidateTwofaToken(response.data))
        if (response && data.navigate) {
            data?.navigate('/dashboard');
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePostValidateTwofaToken(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePostValidateTwofaToken("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* SubscribeForNotification(data: any) {
    try {
        const response: AxiosResponse = yield call(SubscribeForNotificationService, data.data);
        yield put(listOptionsContainerCreators.successPostNotification(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePostNotification(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePostNotification("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export function* SubscribeForNotificationMessage(data: any) {
    try {
        const response: AxiosResponse = yield call(notificationsMessageService, data.data);
        yield put(listOptionsContainerCreators.successPostNotificationMessage(response.data, response.status, response.config.method))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(listOptionsContainerCreators.failurePostNotificationMessage(e.response?.data.errors[0].message));
        } else {
            yield put(listOptionsContainerCreators.failurePostNotificationMessage("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export default function* listOptionsContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_GET_LIST_OPTIONS_BUSINESS, listOptionsBusinessDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_LIST_OPTIONS_EMPLOYEES, listOptionsEmployeesDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_LIST_OPTIONS_REVENUE, listOptionsRevenueDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_LIST_OPTIONS_PROCESSES, listOptionsProcessDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ORGANIZATION, getOrganizationDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ROLES, getRolesData);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_USER, deleteUserData);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_ORGANIZATION, putOrganizationDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ADDRESSES, getAddressesDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_ADD_ADDRESS, addAddressesDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_ADDRESS, deleteAddressesDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_ADDRESS, putAddressDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_PRODUCTS, getProductsDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_ADD_PRODUCT, addProductsDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_PRODUCT, deleteProductsDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_PRODUCT, putProductDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_PROCESSES, getProcessesDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_ADD_PROCESS, addProcessesDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_PROCESS, deleteProcessesDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_PROCESS, putProcessDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_USERS, putUsersData);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_ROLE, putRoleDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ORGANIZATION_USERS, getUsersDataCall);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_INVITE_USER, getInviteUser);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_INVITE_USER, postInviteUser);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_INVITE_USER, deleteInviteUser);
    // @ts-ignore
    yield takeLatest(REQUEST_RESEND_INVITE_USER, resendInviteUser);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ACCOUNT_INFO, getAccountInfo);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_CURRENT_ORG_INFO, getCurrentOrgInfo);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_CURRENT_ORG_INFO, putCurrentOrgInfo);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_INVITE_USER_BY_ID, getInviteUserByIdData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_USER_ORG_LIST, getUserOrgList);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_USER_ORG, deleteUserOrg);
    // @ts-ignore
    yield takeLatest(REQUEST_ADD_USER_TO_ORG_BY_INVITE, addUserToOrganizationByInvite);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_ORG_FROM_LIST, deleteUserOrgList);
    // @ts-ignore
    yield takeLatest(REQUEST_SET_INVITE_USER, createInviteUserAsync);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_USER_LINK_TO_ORG, getUsersLinkedToOrganization);
    // @ts-ignore
    yield takeLatest(REQUEST_CREATE_NEW_CLIENT_ORG, createNewClientOrganization);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_USERS_ROLE_LIMITS, getUsersRoleLimits);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_SOFTWARE_STORAGE, getSoftwareStorageData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_SOFTWARE_BACKUP, getSoftwareBackupData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_CLIENT_ORG_LIST, getClientOrgListData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_ALL_CLIENT_ORG, getAllClientOrgData);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_CLIENT_ORG, putUserLinkToClientOrgData);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_CLIENT, deleteClientData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_LINKED_PARTNER_ORG, getlinkPartnerOrganisation);
    // @ts-ignore
    yield takeLatest(REQUEST_DELETE_USER_FROM_CLIENT_ORG, deleteUserFromClientOrg);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_LINKED_PARTNER_ORG_TO_USER, postLinkedPartnerOrgToUser);
    // @ts-ignore
    yield takeLatest(REQUEST_PUT_ORG_USER, putOrgUserData);
    // @ts-ignore
    yield takeLatest(REQUEST_GET_OTP, getOtp);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_VARIFY_OTP, postverifyAndEnableOtp);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_VALIDATE_OTP, postValidateCode);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_VALIDATE_TWOFA_TOKEN, validatetwoFaToken);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_NOTIFICATION, SubscribeForNotification);
    // @ts-ignore
    yield takeLatest(REQUEST_POST_NOTIFICATION_MESSAGE, SubscribeForNotificationMessage);
}
