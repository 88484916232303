import { useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import StyledButton from '../../components/StyledButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectDecodedUserInfo } from '../../AppSelector';
import { listOptionsContainerCreators } from "../../containers/OrganisationContainer/reducer";
import Modal from "../Modal/Modal";
import Dropdown from "../Dropdown";
import schema from "../../validations/schema";
import NotificationToast from '../ToastComponent';
import InviteUserItem from "./InviteUser";
import LoadingIcon from "../../assets/icons/loading-icon";
import Container from "../Container/Container";

export type singleorganization = {
    bucoOwnerId?: string;
    description?: string;
    modified?: string
    numberEmployeesListId?: string;
    organizationId?: string
    responsibleEmailWhenOther?: string;
    responsibleId?: string;
    responsibleNameWhenOther?: string;
    revenueListId?: string;
    sectorListId?: string;
}
export type User = {
    userId: string,
    type?: string;
    firstName?: string,
    lastName?: string,
    email?: string,
    emailVerified?: boolean,
    mobilePhone?: string,
    prefferedLanguage?: string,
    created?: Date,
    modified?: Date,
    role: string,
};

type Option = {
    value: string;
    label: string;
}

export type UserInfo = {
    [key: string]: any;
    lastname: string;
    houseNumberSuffix: string;
    mobilenumber: string;
    firstname: string;
    prefferedLanguage?: string;
    riskListId: string | null;
}

export type UserDropdownList = {
    label: string,
    value: string
};

type ErrorMessage = {
    message: string
}

type ErrorObject = {
    email?: ErrorMessage,
    inviteUserRole?: ErrorMessage
};

export type InviteUser = {
    inviteId: string;
    organizationId: string;
    email: string;
    code: null;
    used: null;
    created: Date;
    modified: Date;
    initialRoles: string[];
}

export type RoleLimitsData = {
    available: number | null;
    limit: number | null;
    role: string | null;
    used: number | null;
}

type Props = {
    testId?: string
}

type ClientsList = {
    chamberofcommercenr: string;
    city: string;
    companyName: string;
    country: string;
    created: Date | string;
    houseNumber: number
    houseNumberSuffix: string;
    modified: Date | string;
    organizationId: string;
    partnerId: string;
    postalcode: string;
    street: string;
    type: number;
}

type ClientListOrg = {
    chamberofcommercenr: string;
    city: string;
    companyName: string;
    country: string;
    created: Date | string;
    houseNumber: number;
    houseNumberSuffix: string;
    modified: Date | string;
    organizationId: string;
    partnerId: string;
    postalcode: string;
    street: string;
    type: number;
    users?: Client[];
}

type Client = {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: boolean;
    mobilePhone: string;
    prefferedLanguage: string;
    created: Date;
    modified: Date;
}

export default function UsersLayout(props: Props) {

    const { testId = `users-layout` } = props;

    // hooks
    const dispatch = useDispatch();
    const {
        roles,
        isReloadUserList,
        inviteUsers,
        isReloadInviteUserList,
        error,
        roleLimits,
        isReloadRoleLimit,
    } = useSelector((state: RootState) => state.listOptions);
    const {
        organizationUsers,
    } = useSelector((state: RootState) => state.riskAreContainer);
    const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
    const decodedInfo: DecodedUserInfo | null = useSelector(selectDecodedUserInfo);
    const userRole: string | undefined = decodedInfo?.payload.role;

    // states
    const [users, setUsers] = useState<User[]>([]);
    const [inviteUsersList, setInviteUsersList] = useState<InviteUser[]>([]);
    const [singleUser, setSingleUser] = useState<User | null>(null);
    const [userEmail, setUserEmail] = useState<string>("");
    const [isUserModal, setIsUserModal] = useState<boolean>(false);
    const [isInviteUserModal, setisInviteUserModal] = useState<boolean>(false);
    const [isDeleteUserModal, setIsDeleteUserModal] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>("");
    const [selectedInviteUser, setSelectedInviteUser] = useState<InviteUser>();
    const [clientsListModal, setClientsListModal] = useState<boolean>(false);
    const [clientsList, setClientsList] = useState<ClientsList[]>([]);
    const [clientOrgList, setClientOrgList] = useState<Option[]>([]);
    const [getUserId, setGetUserId] = useState<string>("");
    const [selectedClient, setSelectedClient] = useState<ClientListOrg>({
        chamberofcommercenr: "",
        city: "",
        companyName: "",
        country: "",
        created: new Date(),
        houseNumber: 0,
        houseNumberSuffix: "",
        modified: new Date(),
        organizationId: "",
        partnerId: "",
        postalcode: "",
        street: "",
        type: 0,
    })
    const [userData, setUserData] = useState<User>({
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        emailVerified: false,
        mobilePhone: "",
        prefferedLanguage: "",
        created: new Date(),
        modified: new Date(),
        role: ((decodedInfo?.payload.role === "partneraccountant" || decodedInfo?.payload.role === "partnerinsuranceadvisor") && decodedInfo?.payload.plan === 0) ? "administrator" : "",
    });
    const [errObj, setErrObj] = useState<ErrorObject>({
        email: undefined,
        inviteUserRole: undefined,
    });
    const intl = useIntl();
    const initialOption: Option = {
        label: intl.formatMessage({ id: 'select', defaultMessage: 'select' }),
        value: "",
    }

    const [selectedUserData, setSelectedUserData] = useState<User>({
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        emailVerified: false,
        mobilePhone: "",
        prefferedLanguage: "",
        created: new Date(),
        modified: new Date(),
        role: "",
    });

    const [errorMessage, setErrorMessage] = useState<string>('');

    const [showToast, setShowToast] = useState<boolean>(false);
    // eslint-disable-next-line
    const [isDisable, setIsDisable] = useState<boolean>(false);
    const [availabelRoles, setAvailabelRoles] = useState<UserDropdownList[]>([]);

    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);

    useEffect(() => {
        if (listOptionsSelector?.status !== 500 && listOptionsSelector?.status !== null && listOptionsSelector?.status !== undefined && listOptionsSelector?.method !== "delete" && listOptionsSelector?.method !== null) {
            setIsRemoved(false);
            setIsSaved(true);
            setTimeCounter(1);
        } else if (listOptionsSelector?.method !== null && listOptionsSelector?.method !== undefined && listOptionsSelector?.method === "delete" && (listOptionsSelector?.method !== "post" || listOptionsSelector?.method !== "put")) {
            setIsSaved(false);
            setIsRemoved(true);
            setTimeCounter(1);
        }
    }, [listOptionsSelector])

 /* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    useEffect(() => {
        if (decodedInfo?.payload.orgId) {
            dispatch(listOptionsContainerCreators.requestGetOrganizationUsers(decodedInfo?.payload.orgId))
            dispatch(listOptionsContainerCreators.requestGetUsersRoleLimits(decodedInfo?.payload.orgId));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decodedInfo?.payload.orgId])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (isReloadUserList) {
            dispatch(listOptionsContainerCreators.requestGetOrganizationUsers(decodedInfo?.payload.orgId))
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReloadUserList])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (isReloadRoleLimit) {
            dispatch(listOptionsContainerCreators.requestGetUsersRoleLimits(decodedInfo?.payload.orgId));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReloadRoleLimit])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        dispatch(listOptionsContainerCreators.requestGetInviteUser());
    }, []);

    useEffect(() => {
        if (isReloadInviteUserList) {
            dispatch(listOptionsContainerCreators.requestGetInviteUser())
        }
    }, [isReloadInviteUserList])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (organizationUsers && organizationUsers.length) {
            setUsers(organizationUsers)
        }
    }, [organizationUsers])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (inviteUsers) {
            setInviteUsersList(inviteUsers)
        }
    }, [inviteUsers])

    useEffect(() => {
        if (roles && roles.role) {
            setSelectedUserData({
                ...selectedUserData,
                role: roles.role,
            })
        }
    }, [roles])

    useEffect(() => {
        if (roleLimits && roleLimits.length >= 0) {
            const temp: any = roleLimits.map((ele: any) => {
                return {
                    label: intl.formatMessage({ id: `${ele.role}`, defaultMessage: `${ele.role}` }),
                    value: ele.role,
                    isDisable: ele.available === 0

                }
            });
            setAvailabelRoles([...temp])
        }
    }, [roleLimits])/* eslint-disable react-hooks/exhaustive-deps */

    // functions
    function handleChangeRole(value: string) {
        if (singleUser) {
            const selectedUser = {
                ...singleUser,
                //roles: [value],
                role: value,
            }
            setSingleUser(selectedUser);
        }
    }

    function handleUserRole(value: string) {
        setUserData({
            ...userData,
            role: value,
        })
    }

    const removeUser = (userId: string) => {
        setIsDeleteUserModal(true);
        setIsUserModal(false);
        setUserId(userId);
    }

    const editUser = (user: User) => {
        setIsUserModal(true);
        setIsDeleteUserModal(false);
        setSingleUser(user);
    }

    const gotoInviteUsers = () => {
        setisInviteUserModal(true);
    }

    function handleUserModalClose() {
        setIsUserModal(false);
    }

    function handleCancel() {
        setIsUserModal(false);
    }

    function handleSave() {
        setIsUserModal(false);
        if (singleUser) {
            const data = {
                //roles: singleUser.roles
                role: singleUser.role
            }
            dispatch(listOptionsContainerCreators.requestPutOrgUser(decodedInfo?.payload.orgId, singleUser?.userId, data));
            //dispatch(listOptionsContainerCreators.requestPutRole(decodedInfo?.payload.orgId, singleUser?.userId, data));
        }
    }
 /* istanbul ignore next */
    function handleInviteCancel() {
        setisInviteUserModal(false);
        setErrObj({
            email: undefined,
            inviteUserRole: undefined
        });
        setErrorMessage('');
        setUserEmail("");
        setUserData({
            ...userData,
            role: ((decodedInfo?.payload.role === "partneraccountant" || decodedInfo?.payload.role === "partnerinsuranceadvisor") && decodedInfo?.payload.plan === 0) ? "administrator" : "",
        })
    }

    useEffect(() => {
        if (error) {
            setErrorMessage(error);
        }
    }, [error])

    const resetData = () => {
        setisInviteUserModal(false);
        setErrorMessage('');
        setUserEmail("");
        setUserData({
            ...userData,
            role: ((decodedInfo?.payload.role === "partneraccountant" || decodedInfo?.payload.role === "partnerinsuranceadvisor") && decodedInfo?.payload.plan === 0) ? "administrator" : "",
        })
    }
 /* istanbul ignore next */
    function handleInviteUserSave() {
        const emailErr = schema.email.validate(userEmail).error;
        const inviteRollErr = schema.addInviteRole.validate(userData.role).error;
        if (emailErr || inviteRollErr) {
            setErrObj({
                email: emailErr?.message ? { message: intl.formatMessage({ id: emailErr?.message, defaultMessage: emailErr?.message }) } : undefined,
                inviteUserRole: inviteRollErr?.message ? { message: intl.formatMessage({ id: inviteRollErr?.message, defaultMessage: inviteRollErr?.message }) } : undefined
            });
        } else {
            setErrObj({
                email: undefined,
                inviteUserRole: undefined
            });
            const data = {
                email: userEmail,
                //initialRoles: [
                //    userData.role
                //]
                role: userData.role
            }
            dispatch(listOptionsContainerCreators.requestPostInviteUser(data, resetData));
        }
    }
    /* istanbul ignore next */
    const getUserRow = (user: User) => {
        return `${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''} ${user.email ? `(${user.email})` : ''}`
    }

    function handleDeleteUserModalClose() {
        setIsDeleteUserModal(false)
    }

    function handleDeleteUser() {
        dispatch(listOptionsContainerCreators.requestDeleteUser(decodedInfo?.payload.orgId, userId))
        setIsDeleteUserModal(false);
    }

    function handleDeleteInviteUser(inviteId: any) {
        dispatch(listOptionsContainerCreators.requestDeleteInviteUser(inviteId))
    }
 /* istanbul ignore next */
    function handleResendInviteUser(user: InviteUser, inviteId: any) {

        if (inviteId === selectedInviteUser?.inviteId) {
            setSelectedInviteUser(undefined);
        } else {
            const newinviteUsersList: InviteUser[] = [...inviteUsersList];
            const singleInviteUser: any = newinviteUsersList.find((ele: InviteUser) => ele.inviteId === inviteId)
            if (singleInviteUser) {
                setSelectedInviteUser(singleInviteUser);
            }
        }
    }

    useEffect(() => {
        if (selectedInviteUser) {
            dispatch(listOptionsContainerCreators.requestResendInviteUser(selectedInviteUser.inviteId, setShowToast, setIsDisable))
        }
    }, [selectedInviteUser])/* eslint-disable react-hooks/exhaustive-deps */

    function isBtnDisable() {
        if (decodedInfo?.payload.orgType === 1 || decodedInfo?.payload.orgType === 2) {
            return false;
        } else {
            if (selectedUserData.role === "partneraccountant" || selectedUserData.role === "partnerinsuranceadvisor") {
                return true;
            } else {
                return false;
            }
        }
    }

    const filterData = roleLimits && roleLimits?.length > 0 && roleLimits?.filter((ele: any) => ele.available === 0);
    const tempData = filterData?.length === roleLimits?.length;

    function getRolesInRow(role: string) {
        if (role === 'administrator') {
            return intl.formatMessage({ id: 'administrator', defaultMessage: 'administrator' })
        } else if (role === 'editor') {
            return intl.formatMessage({ id: 'editor', defaultMessage: 'editor' })
        } else if (role === 'reader') {
            return intl.formatMessage({ id: 'reader', defaultMessage: 'reader' })
        } else if (role === 'accountant') {
            return intl.formatMessage({ id: 'accountant', defaultMessage: 'accountant' })
        } else if (role === 'insuranceadvisor') {
            return intl.formatMessage({ id: 'insuranceadvisor', defaultMessage: 'insuranceadvisor' })
        } else if (role === 'partneraccountant') {
            return intl.formatMessage({ id: 'partneraccountant', defaultMessage: 'partneraccountant' })
        } else if (role === 'partnerinsuranceadvisor') {
            return intl.formatMessage({ id: 'partnerinsuranceadvisor', defaultMessage: 'partnerinsuranceadvisor' })
        } else {
            return ''
        }
    }

    const clientListModalOpen = (user: User) => {
        setClientsListModal(true);
        dispatch(listOptionsContainerCreators.requestGetLinkedPartnerOrg(decodedInfo?.payload.orgId, user.userId))
        dispatch(listOptionsContainerCreators.requestGetClientOrgList(decodedInfo?.payload.orgId));
        setGetUserId(user.userId);
    }

    useEffect(() => {
        if (listOptionsSelector.isReloadClientsList && getUserId) {
            dispatch(listOptionsContainerCreators.requestGetLinkedPartnerOrg(decodedInfo?.payload.orgId, getUserId))
        }
    }, [listOptionsSelector.isReloadClientsList])

    /* istanbul ignore next */
    useEffect(() => {
        let sortedArray: ClientsList[] = [];
        if (listOptionsSelector?.linkPartnerOrgList) {
            const companyNameArray = [...listOptionsSelector.linkPartnerOrgList];
            const type2Array = companyNameArray.filter((ele) => ele.type === 2).sort((a, b) => a.companyName.localeCompare(b.companyName));
            const type1Array = companyNameArray.filter((ele) => ele.type === 1).sort((a, b) => a.companyName.localeCompare(b.companyName));
            const type0Array = companyNameArray.filter((ele) => ele.type === 0).sort((a, b) => a.companyName.localeCompare(b.companyName));
            sortedArray = [...type2Array, ...type1Array, ...type0Array]
            setClientsList([...sortedArray])
        }
        if (listOptionsSelector && listOptionsSelector?.clientOrgList && listOptionsSelector?.clientOrgList?.length) {
            const clientOrgListArray = [...listOptionsSelector.clientOrgList]
            const type2ClientArray = clientOrgListArray.filter((ele) => ele.type === 2).sort((a, b) => a.companyName.localeCompare(b.companyName));
            const type1ClientArray = clientOrgListArray.filter((ele) => ele.type === 1).sort((a, b) => a.companyName.localeCompare(b.companyName));
            const type0ClientArray = clientOrgListArray.filter((ele) => ele.type === 0).sort((a, b) => a.companyName.localeCompare(b.companyName));
            let allArray = ([...type2ClientArray, ...type1ClientArray, ...type0ClientArray])


            getDifference(sortedArray, allArray)
            const difference = [
                ...getDifference(sortedArray, allArray),
                ...getDifference(allArray, sortedArray)
            ];

            let newUsers = difference.map((user: ClientListOrg) => {
                return {
                    label: `${user?.companyName}`,
                    value: user?.organizationId
                }
            })
            setClientOrgList([...newUsers])
        }
    }, [listOptionsSelector])

    function getDifference(array1: ClientsList[], array2: ClientListOrg[]) {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1.organizationId === object2.organizationId;
            });
        });
    }

    function clientListModalClose() {
        setSelectedClient({ ...selectedClient, organizationId: '' });
        setErrorMessage('');
        setClientsListModal(false)
    }

    function removeUserList(clientsListItem: ClientsList) {
        dispatch(listOptionsContainerCreators.requestDeleteUserFromClientOrg(decodedInfo?.payload?.orgId, getUserId, clientsListItem?.organizationId))
    }

    function handleClientOrg(value: string) {
        setSelectedClient({ ...selectedClient, organizationId: value });
    }

    function handleAddClientOrg() {
        const payload = {
            organizationId: selectedClient.organizationId,
        }
        dispatch(listOptionsContainerCreators.requestPostLinkedPartnerOrgToUser(decodedInfo?.payload?.orgId, getUserId, payload, setClientsListModal, setErrorMessage))
        setSelectedClient({ ...selectedClient, organizationId: "" });
    }

    return (
        <>
            <div data-testid={testId} className='m-auto sm:w-full'>
                <Container>
                    {isSaved ?
                        <div className="font-normal text-xs text-[#959595] text-end">
                            <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                        </div>
                        :
                        <div className="font-normal text-xs text-[#959595] text-end">
                            <div className="h-4">{"  "}</div>
                        </div>
                    }
                    {isRemoved ?
                        <div className="font-normal text-xs text-[#959595] text-end">
                            <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                        </div>
                        :
                        <div className="font-normal text-xs text-[#959595] text-end">
                            <div className="h-4">{"  "}</div>
                        </div>
                    }
                    <NavigationMenu />
                    <div className='my-10 mt-10 font-semibold text-4xl flex items-start justify-start sm:text-3xl'>
                        {intl.formatMessage({ id: 'users', defaultMessage: 'users' })}
                    </div>
                    <div className='mt-8 p-6 bg-white border rounded'>
                        <p className='text-lg font-semibold text-[#121212] mb-6'>{intl.formatMessage({ id: 'users', defaultMessage: 'users' })}</p>
                        <div>
                            {users.length > 0 ?
                                <div className='border rounded-t-md bg-zinc-50'>
                                    {users.map((user: User, index: number) => (
                                        <div data-testid={`${testId}-users-${index}`} className={"grid grid-cols-3 sm:grid-cols-1 sm:flex-wrap items-center justify-between px-4 py-3 font-semibold " + (index !== 0 ? " border-t " : "")} key={user?.userId}>
                                            <div className="user-details-elip col-span-2 sm:min-w-[100%] max-w-[80%]">{getUserRow(user)}</div>
                                            <div className="grid grid-cols-3 items-center justify-between sm:w-full">
                                                <div data-testid={`${testId}-get-roles-in-row`} className="user-role-elip  flex justify-start min-w-[135px] sm:mr-0">{getRolesInRow(user.role)}</div>
                                                {(decodedInfo?.payload.orgType === 1 && decodedInfo?.payload?.role === "administrator") ?
                                                    <div data-testid={`${testId}-client-list-modal`} className="w-full cursor-pointer text-[#3BA8FC] justify-center text-center" onClick={() => clientListModalOpen(user)}>{intl.formatMessage({ id: 'clients', defaultMessage: 'clients' })}</div>
                                                    :
                                                    <div className="w-full"></div>
                                                }
                                                <div className="flex items-center justify-start min-w-[122px] sm:pr-4 sm:min-w-max">
                                                    {(decodedInfo?.payload.role !== "partneraccountant" && decodedInfo?.payload.role !== "partnerinsuranceadvisor") &&
                                                        <>
                                                            {(user?.userId !== decodedInfo?.payload.userId) ?
                                                                <>
                                                                    {(decodedInfo?.payload.role === 'administrator') && (user.role !== 'partneraccountant' && user?.role !== 'partnerinsuranceadvisor') &&
                                                                        <>
                                                                            <div data-testid={`${testId}-edit-role`} className="cursor-pointer text-[#3BA8FC]" onClick={() => editUser(user)}>{intl.formatMessage({ id: 'edit_role', defaultMessage: 'edit_role' })}</div>
                                                                            <div data-testid={`${testId}-remove-user`} className='cursor-pointer ml-2' onClick={() => removeUser(user.userId ?? "")}>
                                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                                        <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                                    </g>
                                                                                </svg>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                                :
                                                                <></>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className='mt-8 p-6 bg-white border rounded'>
                        <p className='text-lg font-semibold text-[#121212] mb-6'>{intl.formatMessage({ id: 'invite_users', defaultMessage: 'invite_users' })}</p>
                        <div>
                            {inviteUsersList.length > 0 ?
                                <div className='border rounded-t-md bg-zinc-50 sm:overflow-x-auto'>
                                    {inviteUsersList.map((user: InviteUser, index: number) => (
                                        <InviteUserItem testId={`${testId}-invite-user-item-${index}`} key={index} index={index} user={user} handleDeleteInviteUser={handleDeleteInviteUser} handleResendInviteUser={handleResendInviteUser} />
                                    ))}
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className="mt-5">
                            {(userRole === "partneraccountant" || userRole === "partnerinsuranceadvisor") ?
                                <>
                                    {decodedInfo?.payload.plan === 0 ?
                                        <StyledButton
                                            testId={`${testId}-invite-user`}
                                            width={'w-60'}
                                            align={'justify-start'}
                                            text={`${intl.formatMessage({ id: 'invite_user', defaultMessage: 'invite_user' })}`}
                                            color={'text-gray-700'}
                                            flexReverse={'flex-row-reverse'}
                                            iconType={'add'}
                                            backgroundColor={'transparent border-1'}
                                            onClick={() => gotoInviteUsers()}
                                        />
                                        :
                                        <></>
                                    }
                                </>
                                :
                                <StyledButton
                                    testId={`${testId}-invite-users`}
                                    width={'w-60'}
                                    align={'justify-start'}
                                    text={`${intl.formatMessage({ id: 'invite_user', defaultMessage: 'invite_user' })}`}
                                    color={'text-gray-700'}
                                    flexReverse={'flex-row-reverse'}
                                    iconType={'add'}
                                    backgroundColor={'transparent border-1'}
                                    onClick={() => gotoInviteUsers()}
                                />
                            }
                        </div>
                    </div>
                </Container>
            </div>

            {(!listOptionsSelector.loading && isUserModal && !isDeleteUserModal && singleUser) &&
                <div>
                    <Modal show={isUserModal} onClose={() => handleUserModalClose()}>
                        <div className="lg:min-w-[400px] sm:w-full">
                            <div className="font-bold mb-2">
                                {intl.formatMessage({ id: 'change_user_role', defaultMessage: 'change_user_role' })}
                            </div>
                            {
                                <Dropdown
                                    testId={`${testId}-user-role-dropdown`}
                                    options={[...[initialOption], ...availabelRoles]}
                                    value={singleUser.role}
                                    onChange={(value: string) =>
                                        handleChangeRole(value)
                                    }
                                />
                            }

                            <div className="font-semibold text-xl text-[#121212] mb-6">
                            </div>
                        </div>
                        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                            <StyledButton
                                testId={`${testId}-cancel-button`}
                                width={"w-48 sm:w-full"}
                                align={"justify-start"}
                                text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                                color={"text-[#535353]"}
                                flexReverse={"flex-row-reverse"}
                                backgroundColor={"bg-[#EDEDED]"}
                                onClick={() => handleCancel()}
                            />
                            {/* <div> */}
                            <StyledButton
                                testId={`${testId}-save-button`}
                                buttonClass={'PrimoryBtn'}
                                width={"w-48 sm:w-full"}
                                align={"justify-end"}
                                disabled={isBtnDisable()}
                                text={intl.formatMessage({ id: "save", defaultMessage: 'save' })}
                                backgroundColor={'bg-black'}
                                color={'text-white'}
                                iconType={'arrow'}
                                onClick={() => handleSave()}
                            />
                            {/* </div> */}
                        </div>
                    </Modal>
                </div>
            }

            {/* delete user modal */}
            {(!listOptionsSelector.loading && !isUserModal && isDeleteUserModal) &&
                <div>
                    <Modal show={isDeleteUserModal} onClose={() => handleDeleteUserModalClose()}>
                        <div>{`${intl.formatMessage({ id: 'are_you_sure', defaultMessage: 'are_you_sure' })}`}</div>
                        <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                            <StyledButton
                                testId={`${testId}-cancel-delete-user-modal-close`}
                                width={"w-48 sm:w-full"}
                                align={"justify-start"}
                                text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                                color={"text-[#535353]"}
                                flexReverse={"flex-row-reverse"}
                                backgroundColor={"bg-[#EDEDED]"}
                                onClick={() => handleDeleteUserModalClose()}
                            />
                            <StyledButton
                                testId={`${testId}-delete-btn`}
                                width={"w-48 sm:w-full"}
                                align={"justify-end"}
                                buttonClass={'PrimoryBtn'}
                                text={intl.formatMessage({ id: "delete", defaultMessage: 'delete' })}
                                backgroundColor={'bg-black'}
                                color={'text-white'}
                                onClick={() => handleDeleteUser()}
                            />
                        </div>
                    </Modal>
                </div>
            }

            {/* add invite user modal */}
            <div>
                <Modal show={isInviteUserModal} onClose={() => handleInviteCancel()}>
                    {
                        !tempData ?
                            <>
                                <div className="lg:min-w-[400px] h-full">
                                    <label className='inline-block text-base font-bold mb-2 sm:text-base sm:font-bold font-family-arial'>{intl.formatMessage({ id: 'email_address', defaultMessage: 'email_address' })}</label>
                                    <div>
                                        <input
                                            data-testid={`${testId}-email-input`}
                                            maxLength={254}
                                            type="text"
                                            className={`bg-zinc-50 border rounded-lg mb-2 p-3 py-2 w-full ${errObj.email?.message ? 'border-[red]' : ''}`}
                                            value={userEmail}
                                            onChange={(event) =>
                                                setUserEmail(event.target.value)
                                            }
                                        />
                                        {errObj.email?.message ? (
                                            <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                                <span>{errObj.email?.message}</span>
                                            </div>
                                        ) : (
                                            <div className="mb-5"></div>
                                        )}
                                    </div>
                                    <div className="font-bold mb-2 font-family-arial">
                                        {intl.formatMessage({ id: 'select_user_role', defaultMessage: 'select_user_role' })}
                                    </div>
                                    <Dropdown
                                        testId={`${testId}-invite-user-dropdown`}
                                        options={[...[initialOption], ...availabelRoles]}
                                        value={userData.role}
                                        onChange={(value: string) =>
                                            handleUserRole(value)
                                        }
                                        error={errObj.inviteUserRole?.message}
                                    />
                                    {errObj.inviteUserRole?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.inviteUserRole?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-5"></div>
                                    )}
                                    <div className="font-semibold text-xl text-[#121212] mb-6">
                                    </div>
                                </div>
                                {errorMessage &&
                                    <div className="text-red-600 font-normal text-base h-3 mb-5 flex items-start justify-start">{errorMessage}</div>
                                }
                                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                                    <StyledButton
                                        testId={`${testId}-invite-cancel-button`}
                                        width={"w-48 sm:w-full"}
                                        align={"justify-start"}
                                        text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                                        color={"text-[#535353]"}
                                        flexReverse={"flex-row-reverse"}
                                        backgroundColor={"bg-[#EDEDED]"}
                                        onClick={() => handleInviteCancel()}
                                    />
                                    <StyledButton
                                        testId={`${testId}-add-button`}
                                        width={"w-48 sm:w-full"}
                                        align={"justify-end"}
                                        buttonClass={'PrimoryBtn'}
                                        text={intl.formatMessage({ id: "add", defaultMessage: 'add' })}
                                        backgroundColor={'bg-black'}
                                        color={'text-white'}
                                        onClick={() => handleInviteUserSave()}
                                    />
                                </div>
                            </> :
                            <>
                                <div className="w-[414px] sm:w-[100%]">
                                    <div className='text-red-600 font-normal text-sm h-max m-0 flex items-start justify-start sm:h-max sm:m-0'>{intl.formatMessage({ id: 'invite_user_unfortunately', defaultMessage: 'invite_user_unfortunately' })}</div>
                                </div>
                            </>
                    }
                </Modal>
            </div>

            {/* clients list modal */}
            <div>
                <Modal data-testid={`${testId}-client-list-modal`} show={clientsListModal} onClose={() => clientListModalClose()}>
                    <div className="w-full flex flex-col gap-4">
                        <div>
                            <div className="inline-block text-base font-bold mb-2 sm:text-base sm:font-bold font-family-arial" >{`${intl.formatMessage({ id: 'clients', defaultMessage: 'clients' })}`}</div>
                            {clientsList.length > 0 ?
                                <div className="flex flex-col">
                                    {clientsList.map((clientsListItem, index) => (
                                        <div data-testid={`${testId}-client-list-${index}`} key={clientsListItem?.organizationId} className="flex items-center justify-between">
                                            <div className="lg:max-w-[400px] lg:min-w-[400px] mb-1">{clientsListItem.companyName}</div>
                                            <div data-testid={`${testId}-remove-user-list`} className='cursor-pointer ml-2 flex flex-col' onClick={() => removeUserList(clientsListItem)}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                        <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <>
                                    {listOptionsSelector?.loading &&
                                        <div className='w-full flex items-center justify-center'>
                                            <LoadingIcon />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        <div>
                            <div className="inline-block text-base font-bold mb-2 sm:text-base sm:font-bold font-family-arial">
                                {intl.formatMessage({ id: 'organisation', defaultMessage: 'organisation' })}
                            </div>
                            <Dropdown
                                testId={`${testId}-invite-user-dropdown`}
                                options={[...[initialOption], ...clientOrgList]}
                                value={selectedClient?.organizationId}
                                onChange={(value: string) =>
                                    handleClientOrg(value)
                                }
                            // error={errObj.inviteUserRole?.message}
                            />
                        </div>
                    </div>
                    {(errorMessage && selectedClient?.organizationId !== "") &&
                        <div className="text-red-600 font-normal text-base h-3 mb-5 flex items-start justify-start">{errorMessage}</div>
                    }
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-client-list-modal-close`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "cancel", defaultMessage: 'cancel' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => clientListModalClose()}
                        />
                        {/* <div> */}
                        <StyledButton
                            testId={`${testId}-add-btn-client-list-modal`}
                            buttonClass={'PrimoryBtn'}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            disabled={isBtnDisable()}
                            text={intl.formatMessage({ id: "add", defaultMessage: 'add' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => handleAddClientOrg()}
                        />
                        {/* </div> */}
                    </div>
                </Modal>
            </div>
            <NotificationToast testId={`${testId}-notification-toast`} text={errorMessage ? errorMessage : intl.formatMessage({ id: `done_message_send`, defaultMessage: 'done_message_send' })} iconType={errorMessage ? intl.formatMessage({ id: 'failure', defaultMessage: 'failure' }) : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} color={errorMessage ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showToast} onClick={() => setShowToast(false)} />
        </>
    )
}