import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: createAccountContainerTypes, Creators: createAccountContainerCreators } = createActions({

  // reset-password
  setCreateAccountData: ['data'],
  resetCreateAccountData: []
});

type CreateAccountData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  name: string;
  kvKNumber: string;
  street: string;
  houseNumber:string;
  houseNumberSuffix:string;
  postalcode: string;
  city: string;
  country: string;
  repeatPassword: string;
  errObj: ErrorObject;
}

export type InitialStateType = {
  data: CreateAccountData
}

type ErrorMessage = {
  message: string
}

export type ErrorObject = {
  email?: ErrorMessage;
  password?: ErrorMessage;
  firstName?: ErrorMessage;
  lastName?: ErrorMessage;
  phoneNumber?: ErrorMessage;
  name?: ErrorMessage;
  kvKNumber?: ErrorMessage;
  street?: ErrorMessage;
  houseNumber?: ErrorMessage;
  houseNumberSuffix?: ErrorMessage;
  postalcode?: ErrorMessage;
  city?: ErrorMessage;
  country?: ErrorMessage;
  repeatPassword?: ErrorMessage;
};

const initialErr = {
  email: undefined,
        password: undefined,
        firstName: undefined,
        lastName: undefined,
        phoneNumber: undefined,
        name: undefined,
        kvKNumber: undefined,
        street: undefined,
        houseNumber: undefined,
        houseNumberSuffix: undefined,
        postalcode: undefined,
        city: undefined,
        country: undefined,
        repeatPassword: undefined,
}

const initialData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phoneNumber: '',
  name: '',
  kvKNumber: '',
  street: '',
  houseNumber: '',
  houseNumberSuffix: '',
  postalcode: '',
  city: '',
  country: '',
  repeatPassword: '',
  errObj: initialErr,
}

export const initialState: InitialStateType = { data: initialData };

export const createAccountReducer = (state = initialState, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {

      case createAccountContainerTypes.SET_CREATE_ACCOUNT_DATA:
        draft.data = action.data;
        break;
      case createAccountContainerTypes.RESET_CREATE_ACCOUNT_DATA:
        draft.data = initialData;
        break;

      default:
        return state;
    }
  })
export default createAccountReducer;