import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectDecodedUserInfo } from "../../AppSelector";
import LoadingIcon from "../../assets/icons/loading-icon";
import Container from "../../components/Container/Container";
import Dropdown from "../../components/Dropdown";
import StyledButton from "../../components/StyledButton";
import { listOptionsContainerCreators } from "./reducer";

const testId = `org-container`;

type Organization = {
  [key: string]: any;
  bucoOwnerId: string;
  responsibleEmailWhenOther: string;
  responsibleId: string;
  responsibleNameWhenOther: string;
  sectorListId: string;
  description: string;
  numberEmployeesListId: string;
  revenueListId: string;
};

type Option = {
  value: string;
  label: string;
}

export function getDropdownValues(this: any) {
  return this.map((data: any) => {
    return {
      label: data.name,
      value: data.listOptionId,
    };
  });
}

export default function OrganisationContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const decodedUserInfo = useSelector(selectDecodedUserInfo);
  const role: string | undefined = decodedUserInfo?.payload?.role;
  const {
    listOptionsBusiness,
    listOptionsEmployees,
    listOptionsRevenue,
    organization,
    loading
  } = useSelector((state: RootState) => state.listOptions);
  const [organizationData, setOrganizationData] = useState<Organization>({
    bucoOwnerId: "",
    responsibleEmailWhenOther: "",
    responsibleId: "",
    responsibleNameWhenOther: "",
    sectorListId: "",
    description: "",
    numberEmployeesListId: "",
    revenueListId: "",
  });

  const intl = useIntl();

  const handleInput = (key: string, value: any) => {
    organizationData[key] = value;
    setOrganizationData({ ...organizationData });
  };

  const businessDropdownOptions = getDropdownValues.call(listOptionsBusiness);

  const employeesDropdownOptions: any[] =
    getDropdownValues.call(listOptionsEmployees);

  const revenuewDropdownOptions: any[] =
    getDropdownValues.call(listOptionsRevenue);


  const goToNext = () => {
    if ((role === "administrator") || (role === "editor")) {
      dispatch(
        listOptionsContainerCreators.requestPutOrganization(organizationData)
      );
    }
    navigate("/organisation/step2");
  };

  const initialOption: Option = {
    label: intl.formatMessage({ id: 'select', defaultMessage: 'select' }),
    value: "",
  }

  useEffect(() => {
    dispatch(listOptionsContainerCreators.requestGetListOptionsBusiness());
    dispatch(listOptionsContainerCreators.requestGetListOptionsEmployees());
    dispatch(listOptionsContainerCreators.requestGetListOptionsRevenue());
    dispatch(listOptionsContainerCreators.requestGetOrganization());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);/* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (organization && listOptionsBusiness.length > 0) {
      setOrganizationData({
        bucoOwnerId: organization.bucoOwnerId ?? "",
        responsibleEmailWhenOther: organization.responsibleEmailWhenOther ?? "",
        responsibleId: organization.responsibleId ?? "",
        responsibleNameWhenOther: organization.responsibleNameWhenOther ?? "",
        sectorListId: organization.sectorListId ?? "",
        description: organization.description ?? "",
        numberEmployeesListId: organization.numberEmployeesListId ?? "",
        revenueListId: organization.revenueListId ?? "",
      });
    }
  }, [organization, listOptionsBusiness]);/* eslint-disable react-hooks/exhaustive-deps */

  function isDisableBtn() {
    if ((role === "administrator") || (role === "editor")) {
      if (!organizationData.revenueListId || !organizationData.sectorListId || !organizationData.numberEmployeesListId || !organizationData.description) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      {
        loading ?
          <div className='app-loader'>
            <LoadingIcon />
          </div>
          :
          <div>
            <Container>
              <div>
                <div className="m-auto w-full pb-7 align-middle items-center flex justify-between border-b sm:m-0 sm:pb-6">
                  <div className="font-semibold text-xl">
                    <div>{intl.formatMessage({ id: "organisation", defaultMessage: 'organisation' })}</div>
                  </div>
                </div>
                <div className="my-10 sm:my-8">
                  <h4 className="text-xl lg:text-3xl xl:text-3xl font-semibold">
                    {intl.formatMessage({ id: "organization.title", defaultMessage: 'organization.title' })}
                  </h4>
                  <Container>
                    <div className="mt-8 bg-white rounded p-6">
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 mb-5">
                        <div>
                          <label className="text-base font-semibold font-family-arial">
                            {intl.formatMessage({
                              id: "organization.form_labels.business", defaultMessage: 'organization.form_labels.business'
                            })}
                          </label>
                        </div>
                        <div>
                          <Dropdown
                            options={[...[initialOption], ...businessDropdownOptions]}
                            value={organizationData.sectorListId}
                            onChange={(value: string) =>
                              handleInput("sectorListId", value)
                            }
                          />
                        </div>
                      </div>
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 mb-5">
                        <label className="text-base font-semibold font-family-arial">
                          {intl.formatMessage({
                            id: "organization.form_labels.description", defaultMessage: 'organization.form_labels.description'
                          })}
                        </label>
                        <textarea
                          data-testid={`${testId}-description-textarea`}
                          className={`bg-gray-100 border rounded-lg p-3 mb-1 w-full`}
                          rows={4}
                          disabled={((role === "administrator") || (role === "editor")) ? false : true}
                          onChange={(event: any) =>
                            handleInput("description", event.target.value)
                          }
                          value={organizationData.description}
                        ></textarea>
                      </div>
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 mb-5">
                        <label className="text-base font-semibold font-family-arial">
                          {intl.formatMessage({
                            id: "organization.form_labels.employees", defaultMessage: 'organization.form_labels.employees'
                          })}
                        </label>
                        <Dropdown
                          options={[...[initialOption], ...employeesDropdownOptions]}
                          value={organizationData.numberEmployeesListId}
                          onChange={(value: string) =>
                            handleInput("numberEmployeesListId", value)
                          }
                        />
                      </div>
                      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 mb-5">
                        <label className="text-base font-semibold font-family-arial">
                          {intl.formatMessage({
                            id: "organization.form_labels.annual_revenu", defaultMessage: 'organization.form_labels.annual_revenu'
                          })}
                        </label>
                        <Dropdown
                          options={[...[initialOption], ...revenuewDropdownOptions]}
                          value={organizationData.revenueListId}
                          onChange={(value: string) =>
                            handleInput("revenueListId", value)
                          }
                        />
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
              <div className="w-full align-middle items-center flex justify-end mt-8">
                <div>
                  <StyledButton
                    testId={`${testId}-org-next-btn`}
                    disabled={isDisableBtn()
                    }
                    customClass={'my-10'}
                    buttonClass={'PrimoryBtn'}
                    width={"w-48 sm:w-full"}
                    align={"justify-end"}
                    text={intl.formatMessage({ id: "next", defaultMessage: 'next' })}
                    color={"text-white"}
                    iconType={"arrow"}
                    backgroundColor={isDisableBtn()
                      ? "bg-zinc-400"
                      : "bg-black"
                    }
                    onClick={goToNext}
                  />
                </div>
              </div>
            </Container>
          </div>
      }
    </>
  );
}
