import { produce } from "immer";
import { createActions } from "reduxsauce";
import { RiskCategory } from "../RiskAreasContainer";

export const {
  Types: riskAreaContainerTypes,
  Creators: riskAreaContainerCreators,
} = createActions({
  //Get
  requestGetRiskCategories: ['navigate', 'isCancel'],
  successGetRiskCategories: ["data", "method"],
  failureGetRiskCategories: ["error"],

  // Add
  requestAddRiskCategory: ["data", "navigate"],
  successAddRiskCategory: ["postRiskCategory", "status", "method"],
  failureAddRiskCategory: ["error"],

  //Delete
  requestDeleteRiskCategory: ["riskCategoryId"],
  successDeleteRiskCategory: ["data", "method"],
  failureDeleteRiskCategory: ["error"],

  //Put
  requestPutRiskCategory: ["id", "data", "assessment"],
  successPutRiskCategory: ["data", "status", "method"],
  failurePutRiskCategory: ["error"],

  //GetListOptions
  requestGetListOptions: [""],
  successGetListOptions: ["data"],
  failureGetListOptions: ["error"],

  // get value
  requestEditValue: ["id"],
  successEditValue: ["editData"],
  failureEditValue: ["error"],

  //Get
  requestGetOrganizationUsers: ["id"],
  successGetOrganizationUsers: ["data"],
  failureGetOrganizationUsers: ["error"],

  //Get Risk Category Content
  requestGetRiskCategoryContent: ["contentType"],
  successGetRiskCategoryContent: ["riskCategoryContent"],
  failureGetRiskCategoryContent: ["error"],

  //Get PROD Risk Category Content
  requestGetProdRiskCategoryContent: ["contentType", "manageRiskCategoryStatus", "decodedUserInfo"],
  successGetProdRiskCategoryContent: ["riskCategoryContent"],
  failureGetProdRiskCategoryContent: ["error"],

  // Manage Risk Category Help Text
  requestGetManageRiskHelpText: ['contentType', 'decodedUserInfo'],
  successGetManageRiskHelpText: ['manageRiskHelpText'],
  failureGetManageRiskHelpText: ['error'],

  // Add/Edit Risk Category Help Text
  requestGetRiskCategoryHelpText: ['riskListId', 'decodedUserInfo'],
  successGetRiskCategoryHelpText: ['riskCategoryHelpText'],
  failureGetRiskCategoryHelpText: ['error'],

  // ASSESSMENTS Help Text
  requestGetAssessmentsHelpText: ['contentType', 'decodedUserInfo'],
  successGetAssessmentsHelpText: ['assessmentHelpText'],
  failureGetAssessmentsHelpText: ['error'],
});

export type InitialStateType = {
  data: RiskCategory | null;
  shouldRealoadRiskCategory?: boolean;
  error: string | null;
  loading: boolean | null;
  listOptions: object[] | null;
  riskCategory: object[] | null;
  editData: object | null;
  organizationUsers: object[] | null;
  riskCategoryContent: object | null;
  manageRiskHelpText: object | null;
  riskCategoryHelpText: object | null;
  infoCardLoading: boolean | null;
  assessmentHelpText: object | null;
  status?: number | null;
  method?: string | null;
  loadingAfterPost?: boolean;
  postRiskCategory?: object | null;
};

export const initialState: InitialStateType = {
  data: null,
  shouldRealoadRiskCategory: false,
  error: null,
  editData: null,
  loading: null,
  listOptions: [],
  riskCategory: [],
  organizationUsers: [],
  riskCategoryContent: null,
  manageRiskHelpText: null,
  riskCategoryHelpText: null,
  infoCardLoading: false,
  assessmentHelpText: null,
  status: null,
  method: null,
  loadingAfterPost: false,
  postRiskCategory: null
};

export const riskAreContainerReducer = (state = initialState, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case riskAreaContainerTypes.REQUEST_GET_LIST_OPTIONS:
        draft.loading = true;
        draft.error = null;
        draft.shouldRealoadRiskCategory = false;
        draft.data = null;
        break;
      case riskAreaContainerTypes.SUCCESS_GET_LIST_OPTIONS:
        draft.listOptions = action.data;
        draft.loading = false;
        draft.error = null;
        draft.shouldRealoadRiskCategory = false;
        break;
      case riskAreaContainerTypes.FAILURE_GET_LIST_OPTIONS:
        draft.loading = false;
        draft.error = action.error;
        draft.shouldRealoadRiskCategory = false;
        draft.data = null;
        break;

      // post risk category
      case riskAreaContainerTypes.REQUEST_ADD_RISK_CATEGORY:
        draft.loadingAfterPost = true;
        draft.loading = true;
        draft.error = null;
        draft.riskCategory = null;
        draft.postRiskCategory = null;
        break;
      case riskAreaContainerTypes.SUCCESS_ADD_RISK_CATEGORY:
        draft.method = action.method;
        draft.loading = false;
        draft.error = null;
        draft.riskCategory = null;
        draft.postRiskCategory = action.postRiskCategory;
        draft.status = action.status;
        break;
      case riskAreaContainerTypes.FAILURE_ADD_RISK_CATEGORY:
        draft.loading = false;
        draft.error = action.error;
        draft.riskCategory = null;
        draft.postRiskCategory = null;
        break;

      // get risk category
      case riskAreaContainerTypes.REQUEST_GET_RISK_CATEGORIES:
        draft.loadingAfterPost = false;
        draft.method = action.method;
        draft.status = null;
        draft.riskCategory = null;
        draft.riskCategoryContent = null;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.SUCCESS_GET_RISK_CATEGORIES:
        draft.method = action.method;
        draft.status = null;
        draft.method = null;
        draft.status = null;
        draft.riskCategory = action.data;
        draft.riskCategoryContent = null;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.FAILURE_GET_RISK_CATEGORIES:
        draft.status = null;
        draft.riskCategory = null;
        draft.riskCategoryContent = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // put risk category
      case riskAreaContainerTypes.REQUEST_PUT_RISK_CATEGORY:
        draft.riskCategory = null;
        draft.loading = true;
        draft.error = null;
        draft.shouldRealoadRiskCategory = false;
        draft.editData = null;
        break;
      case riskAreaContainerTypes.SUCCESS_PUT_RISK_CATEGORY:
        draft.method = action.method;
        draft.riskCategory = action.data;
        draft.loading = false;
        draft.error = null;
        draft.shouldRealoadRiskCategory = true;
        draft.status = action.status;
        draft.editData = null;
        break;
      case riskAreaContainerTypes.FAILURE_PUT_RISK_CATEGORY:
        draft.riskCategory = null;
        draft.loading = false;
        draft.error = action.error;
        draft.shouldRealoadRiskCategory = false;
        draft.editData = null;
        break;

      // delete risk category
      case riskAreaContainerTypes.REQUEST_DELETE_RISK_CATEGORY:
        draft.riskCategory = null;
        draft.loading = true;
        draft.error = null;
        draft.shouldRealoadRiskCategory = false;
        break;
      case riskAreaContainerTypes.SUCCESS_DELETE_RISK_CATEGORY:
        draft.method = action.method;
        draft.riskCategory = action.data;
        draft.loading = false;
        draft.error = null;
        draft.shouldRealoadRiskCategory = true;
        break;
      case riskAreaContainerTypes.FAILURE_DELETE_RISK_CATEGORY:
        draft.riskCategory = null;
        draft.loading = false;
        draft.error = action.error;
        draft.shouldRealoadRiskCategory = false;
        break;

      case riskAreaContainerTypes.REQUEST_EDIT_VALUE:
        draft.loadingAfterPost = true;
        draft.status = null;
        draft.editData = null;
        draft.loading = true;
        draft.error = null;
        break;
      case riskAreaContainerTypes.SUCCESS_EDIT_VALUE:
        draft.status = null;
        draft.editData = action.editData;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.FAILURE_EDIT_VALUE:
        draft.status = null;
        draft.editData = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      //OrganizationUser
      case riskAreaContainerTypes.REQUEST_GET_ORGANIZATION_USERS:
        draft.organizationUsers = null;
        draft.loading = true;
        draft.error = null;
        break;
      case riskAreaContainerTypes.SUCCESS_GET_ORGANIZATION_USERS:
        draft.organizationUsers = action.data;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.FAILURE_GET_ORGANIZATION_USERS:
        draft.organizationUsers = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // get Risk Category content
      case riskAreaContainerTypes.REQUEST_GET_RISK_CATEGORY_CONTENT:
        draft.data = null;
        draft.riskCategory = null;
        draft.riskCategoryContent = null;
        draft.loading = true;
        draft.error = null;
        break;
      case riskAreaContainerTypes.SUCCESS_GET_RISK_CATEGORY_CONTENT:
        draft.data = null;
        draft.riskCategory = null;
        draft.riskCategoryContent = action.riskCategoryContent;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.FAILURE_GET_RISK_CATEGORY_CONTENT:
        draft.data = null;
        draft.riskCategory = null;
        draft.riskCategoryContent = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // get PROD Risk Category content
      case riskAreaContainerTypes.REQUEST_GET_PROD_RISK_CATEGORY_CONTENT:
        draft.data = null;
        draft.riskCategory = null;
        draft.riskCategoryContent = null;
        draft.loading = true;
        draft.error = null;
        break;
      case riskAreaContainerTypes.SUCCESS_GET_PROD_RISK_CATEGORY_CONTENT:
        draft.data = null;
        draft.riskCategory = null;
        draft.riskCategoryContent = action.riskCategoryContent;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.FAILURE_GET_PROD_RISK_CATEGORY_CONTENT:
        draft.data = null;
        draft.riskCategory = null;
        draft.riskCategoryContent = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // get Manage Risk help text
      case riskAreaContainerTypes.REQUEST_GET_MANAGE_RISK_HELP_TEXT:
        draft.manageRiskHelpText = null;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.SUCCESS_GET_MANAGE_RISK_HELP_TEXT:
        draft.manageRiskHelpText = action.manageRiskHelpText;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.FAILURE_GET_MANAGE_RISK_HELP_TEXT:
        draft.manageRiskHelpText = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // get add/edit Risk Category help text
      case riskAreaContainerTypes.REQUEST_GET_RISK_CATEGORY_HELP_TEXT:
        draft.riskCategoryHelpText = null;
        draft.loading = false;
        draft.infoCardLoading = true;
        draft.error = null;
        draft.status = null;
        draft.editData = null;
        break;
      case riskAreaContainerTypes.SUCCESS_GET_RISK_CATEGORY_HELP_TEXT:
        draft.riskCategoryHelpText = action.riskCategoryHelpText;
        draft.loading = false;
        draft.infoCardLoading = false;
        draft.error = null;
        draft.status = null;
        draft.editData = null;
        break;
      case riskAreaContainerTypes.FAILURE_GET_RISK_CATEGORY_HELP_TEXT:
        draft.riskCategoryHelpText = null;
        draft.loading = false;
        draft.infoCardLoading = false;
        draft.error = action.error;
        draft.status = null;
        draft.editData = null;
        break;

      // get ASSESSMENTS help text
      case riskAreaContainerTypes.REQUEST_GET_ASSESSMENTS_HELP_TEXT:
        draft.assessmentHelpText = null;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.SUCCESS_GET_ASSESSMENTS_HELP_TEXT:
        draft.assessmentHelpText = action.assessmentHelpText;
        draft.loading = false;
        draft.error = null;
        break;
      case riskAreaContainerTypes.FAILURE_GET_ASSESSMENTS_HELP_TEXT:
        draft.assessmentHelpText = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      default:
        return state;
    }
  });
export default riskAreContainerReducer;
