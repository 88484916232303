import { AxiosPromise } from "axios";
import axios from "./axios";

export const getMeasureGroupsService = (id: string): AxiosPromise =>
    axios.get(`/bucoscanservice/v1/riskcategories/${id}/measuregroups`);

export const fileUploadCall = (data: any) => {
    return axios.post(`/bucoscanservice/v1/files`, data.data, {
        onUploadProgress: ProgressEvent => {
            if (ProgressEvent?.total && ProgressEvent.loaded) {
                data.setProgressValue(
                    Number(ProgressEvent.loaded / ProgressEvent.total * 100),
                )
            }
        },
    })
}

export const getMeasuresService = (id: string, mgId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/riskcategories/${id}/measuregroups/${mgId}/measures`);

export const postAddMeasureGroupService = (id: string, data: object): AxiosPromise => axios.post(`/bucoscanservice/v1/riskcategories/${id}/measuregroups`, data)

export const putMeasureGroupsService = (id: string, data: object, mgId: string): AxiosPromise => axios.put(`/bucoscanservice/v1/riskcategories/${id}/measuregroups/${mgId}`, data)

export const getEventsService = (id: string): AxiosPromise => axios.get(`/bucoscanservice/v1/riskcategories/${id}/events`);

export const postEventsService = (id: string, data: object): AxiosPromise => axios.post(`/bucoscanservice/v1/riskcategories/${id}/events`, data)

export const deleteEventsService = (riskCategoryId: string, eventId: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/riskcategories/${riskCategoryId}/events/${eventId}`);

export const deleteMeasureService = (riskCategoryId: string, measureGroupId: string, measureId: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/riskcategories/${riskCategoryId}/measuregroups/${measureGroupId}/measures/${measureId}`);

export const putEventsService = (id: string, data: object, eId: string): AxiosPromise => axios.put(`/bucoscanservice/v1/riskcategories/${id}/events/${eId}`, data)

export const postAddMeasureService = (id: string, data: object, mgId: string): AxiosPromise => axios.post(`/bucoscanservice/v1/riskcategories/${id}/measuregroups/${mgId}/measures`, data)

export const putAddMeasureService = (id: string, data: object, mgId: string, mId: string): AxiosPromise => axios.put(`/bucoscanservice/v1/riskcategories/${id}/measuregroups/${mgId}/measures/${mId}`, data)

export const getMeasureByIdService = (id: string, mgId: string, mId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/riskcategories/${id}/measuregroups/${mgId}/measures/${mId}`)

export const postFileService = (data: object): AxiosPromise => axios.post(`/bucoscanservice/v1/files`, data)

export const deleteFileService = (fileId: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/files/${fileId}`)

export const eventContentService = (contentType: string, filterId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/content/${contentType}?filterId=${filterId}`)

export const measureGroupContentService = (contentType: string, filterId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/content/${contentType}?filterId=${filterId}`)

export const deleteMeasureGroupService = (riskCategoryId: string, measureGroupId: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/riskcategories/${riskCategoryId}/measuregroups/${measureGroupId}`)

export const getEventByIdService = (id: string, eventId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/riskcategories/${id}/events/${eventId}`)

export const getMeasureGroupByIdService = (id: string, mgId: string): AxiosPromise => axios.get(`/bucoscanservice/v1/riskcategories/${id}/measuregroups/${mgId}`)
