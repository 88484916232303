import { produce } from "immer";
import { createActions } from "reduxsauce";

export const { Types: measureGroupsContainerTypes, Creators: measureGroupsContainerCreators } = createActions({
  // get measure groups
  requestGetMeasureGroups: ["id", "navigate", "isCancel", "dispatch", "events", "decodedUserInfo", 'riskCategoryObj'],
  successGetMeasureGroups: ["data"],
  failureGetMeasureGroups: ["error"],

  // get measures
  requestGetMeasures: ["id", "mgId", "singleMeasureGroup", "navigate", "isCancel", "decodedUserInfo", "dispatch", "riskCategoryObj"],
  successGetMeasures: ["measures"],
  failureGetMeasures: ["error"],

  // get events
  requestGetEvents: ["id", "navigate", "isCancel", "dispatch", "decodedUserInfo", "riskCategoryObj"],
  successGetEvents: ["events"],
  failureGetEvents: ["error"],

  // Post events
  requestPostEvents: ["id", "data", "navigate"],
  successPostEvents: ["postEventData", "status", "method"],
  failurePostEvents: ["error"],

  // Put events
  requestPutEvents: ["id", "data", "navigate", "eId"],
  successPutEvents: ["putEventData", "status", "method"],
  failurePutEvents: ["error"],

  // post measures
  requestPostMeasures: ["id", "data", "mgId", "navigate", "mgFromCancel"],
  successPostMeasures: ["measureid", 'status', 'method', "postMeasureData"],
  failurePostMeasures: ["error"],

  // put measures
  requestPutMeasures: ["id", "data", "navigate", "mgId", "mId", "from", "mgFromCancel"],
  successPutMeasures: ["measures", 'status', 'method'],
  failurePutMeasures: ["error"],

  // delete measures
  requestDeleteMeasures: ["riskCategoryId", "measureGroupId", "measureId"],
  successDeleteMeasures: ["measures", 'method'],
  failureDeleteMeasures: ["error"],

  // get measures by id
  requestGetMeasureById: ["id", "mgId", "mId"],
  successGetMeasureById: ["singleMeasure"],
  failureGetMeasureById: ["error"],

  // Post files
  requestPostFiles: ["data", "setProgressValue"],
  successPostFiles: ["files"],
  failurePostFiles: ["error"],

  // Delete files
  requestDeleteFiles: ["fileId"],
  successDeleteFiles: ["files"],
  failureDeleteFiles: ["error"],

  //content event api
  requestGetEventContent: ['contentType', 'filterId'],
  successGetEventContent: ['eventContent'],
  failureGetEventContent: ['error'],

  //event list Delete
  requestDeleteEvents: ["riskCategoryId", "eventId"],
  successDeleteEvents: ["events", "method"],
  failureDeleteEvents: ["error"],

  //measure Group Content api
  requestGetMeasureGroupContent: ['contentType', 'filterId'],
  successGetMeasureGroupContent: ['measureGroupContent'],
  failureGetMeasureGroupContent: ['error'],

  //event content api for PROD
  requestGetProdEventContent: ['contentType', 'singleRiskCategory', 'decodedUserInfo'],
  successGetProdEventContent: ['eventContent'],
  failureGetProdEventContent: ['error'],

  //measure Group Content api for PROD
  requestGetProdMeasureGroupContent: ['contentType', 'singleRiskCategory', 'decodedUserInfo'],
  successGetProdMeasureGroupContent: ['measureGroupContent'],
  failureGetProdMeasureGroupContent: ['error'],

  //measures Content api for PROD
  requestGetProdMeasuresContent: ['contentType', 'status', 'selectedSingleMeasure', 'decodedUserInfo'],
  successGetProdMeasuresContent: ['measuresContent'],
  failureGetProdMeasuresContent: ['error'],

  //event help text
  requestGetEventsHelpText: ['eventListId', 'decodedUserInfo'],
  successGetEventsHelpText: ['eventsHelpText'],
  failureGetEventsHelpText: ['error'],

  //measure group help text
  requestGetMeasureGroupsHelpText: ['measuregroupListId', 'decodedUserInfo'],
  successGetMeasureGroupsHelpText: ['measureGroupsHelpText'],
  failureGetMeasureGroupsHelpText: ['error'],

  //measures help text
  requestGetMeasuresHelpText: ['measureListId', 'decodedUserInfo'],
  successGetMeasuresHelpText: ['measuresHelpText'],
  failureGetMeasuresHelpText: ['error'],

  // Delete MeasureGroup
  requestDeleteMeasureGroup: ['riskCategoryId', 'measuregroupId'],
  successDeleteMeasureGroup: ['data', 'method'],
  failureDeleteMeasureGroup: ['error'],

  //get event by id
  requestGetEventById: ["id", "eventId"],
  successGetEventById: ["singleEvent"],
  failureGetEventById: ["error"],

  //get Measure Group by id
  requestGetMeasureGroupById: ["id", "mgId"],
  successGetMeasureGroupById: ["singleMeasureGroup"],
  failureGetMeasureGroupById: ["error"],

});

export const { Types: clearMeasureStatusTypes, Creators: clearMeasureStatusCreators } = createActions({
  //clear status
  clearMeasureStatus: []
});

export type InitialStateType = {
  data: object[] | null;
  error: string | null;
  loading: boolean | null;
  events: object[] | null;
  measures: object[] | null;
  measureid?: string | null;
  measureLoading: boolean | null;
  singleMeasure: object | null;
  // fillUpMeasure?: object | null;
  files: object[] | null;
  eventContent: object | null;
  measureGroupContent: object | null;
  measuresContent: object | null;
  eventsHelpText: object | null;
  measureGroupsHelpText: object | null;
  measuresHelpText: object | null;
  shouldRealoadEvents: boolean;
  shouldReloadMeasureGroup: boolean;
  status?: number | null;
  singleEvent?: object | null;
  singleMeasureGroup?: object | null;
  method?: string | null;
  loadingAfterPost?: boolean;
  postEventData?: object | null;
  postMeasureData?: object | null;
  postMeasureGroupData?: object | null;
};

export const initialState: InitialStateType = {
  data: null,
  error: null,
  loading: null,
  events: null,
  measures: null,
  measureid: null,
  measureLoading: null,
  singleMeasure: null,
  // fillUpMeasure:null,
  files: null,
  eventContent: null,
  measureGroupContent: null,
  measuresContent: null,
  eventsHelpText: null,
  measureGroupsHelpText: null,
  measuresHelpText: null,
  shouldRealoadEvents: false,
  shouldReloadMeasureGroup: false,
  status: null,
  singleEvent: null,
  singleMeasureGroup: null,
  method: null,
  loadingAfterPost: false,
  postEventData: null,
  postMeasureData: null,
  postMeasureGroupData: null
};

export const measureGroupsContainerReducer = (
  state = initialState,
  action: any
) =>
  produce(state, (draft) => {
    switch (action.type) {
      // get measure groups
      case measureGroupsContainerTypes.REQUEST_GET_MEASURE_GROUPS:
        draft.loadingAfterPost = false;
        draft.method = null;
        draft.status = null;
        draft.data = null;
        draft.postMeasureGroupData = null;
        draft.singleMeasureGroup = null;
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.measuresContent = null;
        draft.events = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_MEASURE_GROUPS:
        draft.method = null;
        draft.status = null;
        draft.data = action.data;
        draft.postMeasureGroupData = null;
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.measuresContent = null;
        draft.events = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_MEASURE_GROUPS:
        draft.method = null;
        draft.status = null;
        draft.data = null;
        draft.postMeasureGroupData = null;
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.measuresContent = null;
        draft.events = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // get measures
      case measureGroupsContainerTypes.REQUEST_GET_MEASURES:
        draft.loadingAfterPost = false;
        draft.singleMeasure = null;
        draft.measures = null;
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.files = null;
        draft.loading = false;
        draft.measureLoading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_MEASURES:
        draft.measures = action.measures;
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.files = null;
        draft.loading = false;
        draft.measureLoading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_MEASURES:
        draft.measures = null;
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.files = null;
        draft.loading = false;
        draft.measureLoading = false;
        draft.error = action.error;
        break;

      // get events
      case measureGroupsContainerTypes.REQUEST_GET_EVENTS:
        draft.loadingAfterPost = false;
        draft.method = null;
        draft.status = null;
        draft.events = null;
        draft.eventContent = null;
        draft.singleEvent = null;
        draft.measureGroupContent = null;
        draft.measuresContent = null;
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_EVENTS:
        draft.method = null;
        draft.status = null;
        draft.events = action.events;
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.measuresContent = null;
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_EVENTS:
        draft.status = null;
        draft.events = null;
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.measuresContent = null;
        draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // Post events
      case measureGroupsContainerTypes.REQUEST_POST_EVENTS:
        draft.loadingAfterPost = true;
        draft.status = null;
        draft.data = null;
        draft.postEventData = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_POST_EVENTS:
        draft.method = action.method;
        draft.status = action.status;
        draft.data = null;
        draft.postEventData = action.postEventData;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_POST_EVENTS:
        draft.status = null;
        draft.data = null;
        draft.postEventData = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // Put events
      case measureGroupsContainerTypes.REQUEST_PUT_EVENTS:
        draft.status = null;
        draft.data = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_PUT_EVENTS:
        draft.method = action.method;
        draft.status = action.status;
        draft.data = action.data;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_PUT_EVENTS:
        draft.status = null;
        draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // delete risk category
      case measureGroupsContainerTypes.REQUEST_DELETE_EVENTS:
        draft.method = null;
        draft.events = null;
        draft.loading = true;
        draft.error = null;
        draft.shouldRealoadEvents = false;
        break;
      case measureGroupsContainerTypes.SUCCESS_DELETE_EVENTS:
        draft.method = action.method;
        draft.events = action.events;
        draft.loading = false;
        draft.error = null;
        draft.shouldRealoadEvents = true;
        break;
      case measureGroupsContainerTypes.FAILURE_DELETE_EVENTS:
        draft.method = null;
        draft.events = null;
        draft.loading = false;
        draft.error = action.error;
        draft.shouldRealoadEvents = false;
        break;

      // post measures
      case measureGroupsContainerTypes.REQUEST_POST_MEASURES:
        draft.loadingAfterPost = true;
        draft.method = null;
        draft.measureid = null;
        draft.files = null;
        draft.loading = true;
        draft.error = null;
        draft.status = null;
        draft.postMeasureData = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_POST_MEASURES:
        draft.method = action.method;
        draft.measureid = action.measureid;
        draft.files = null;
        draft.loading = false;
        draft.error = null;
        draft.status = action.status;
        draft.postMeasureData = action.postMeasureData;
        break;
      case measureGroupsContainerTypes.FAILURE_POST_MEASURES:
        draft.method = null;
        draft.measureid = action.measureid;
        draft.files = null;
        draft.loading = false;
        draft.error = action.error;
        draft.status = null;
        draft.postMeasureData = null;
        break;

      // delete measures
      case measureGroupsContainerTypes.REQUEST_DELETE_MEASURES:
        draft.method = null;
        draft.measures = null;
        draft.loading = true;
        draft.error = null;
        draft.shouldReloadMeasureGroup = false;
        break;
      case measureGroupsContainerTypes.SUCCESS_DELETE_MEASURES:
        draft.method = action.method;
        draft.measures = action.measures;
        draft.loading = false;
        draft.error = null;
        draft.shouldReloadMeasureGroup = true;
        break;
      case measureGroupsContainerTypes.FAILURE_DELETE_MEASURES:
        draft.method = null;
        draft.measures = null;
        draft.loading = false;
        draft.error = action.error;
        draft.shouldReloadMeasureGroup = false;
        break;

      // post measures
      case measureGroupsContainerTypes.REQUEST_PUT_MEASURES:
        draft.method = null;
        draft.measures = null;
        draft.loading = true;
        draft.error = null;
        draft.status = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_PUT_MEASURES:
        draft.method = action.method;
        draft.measures = action.measures;
        draft.loading = false;
        draft.error = null;
        draft.status = action.status;
        break;
      case measureGroupsContainerTypes.FAILURE_PUT_MEASURES:
        draft.method = null;
        draft.measures = null;
        draft.loading = false;
        draft.error = action.error;
        draft.status = null;
        break;

      // get measure by id
      case measureGroupsContainerTypes.REQUEST_GET_MEASURE_BY_ID:
        draft.loadingAfterPost = false;
        draft.singleMeasure = null;
        // draft.fillUpMeasure = null;
        draft.loading = true;
        draft.measureLoading = true;
        draft.error = null;
        draft.postMeasureData = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_MEASURE_BY_ID:
        draft.singleMeasure = action.singleMeasure;
        // draft.fillUpMeasure = action.fillUpMeasure;
        draft.loading = false;
        draft.measureLoading = false;
        draft.error = null;
        draft.postMeasureData = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_MEASURE_BY_ID:
        draft.singleMeasure = null;
        // draft.fillUpMeasure = null;
        draft.loading = false;
        draft.measureLoading = false;
        draft.error = action.error;
        draft.postMeasureData = null;
        break;

      // post files
      case measureGroupsContainerTypes.REQUEST_POST_FILES:
        draft.files = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_POST_FILES:
        draft.files = action.files;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_POST_FILES:
        draft.files = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // delete files
      case measureGroupsContainerTypes.REQUEST_DELETE_FILES:
        draft.files = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_DELETE_FILES:
        draft.files = action.files;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_DELETE_FILES:
        draft.files = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // Event content api
      case measureGroupsContainerTypes.REQUEST_GET_EVENT_CONTENT:
        draft.eventContent = null;
        draft.measureGroupContent = null;
        // draft.events = null;
        draft.data = null;
        draft.measures = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_EVENT_CONTENT:
        draft.eventContent = action.eventContent;
        draft.measureGroupContent = null;
        // draft.events = null;
        draft.measures = null;
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_EVENT_CONTENT:
        draft.eventContent = null;
        draft.measureGroupContent = null;
        // draft.events = null;
        draft.measures = null;
        draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // Measure Group content api
      case measureGroupsContainerTypes.REQUEST_GET_MEASURE_GROUP_CONTENT:
        draft.measureGroupContent = null;
        draft.eventContent = null;
        draft.events = null;
        //draft.data = null;
        draft.measures = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_MEASURE_GROUP_CONTENT:
        draft.measureGroupContent = action.measureGroupContent;
        draft.eventContent = null;
        draft.events = null;
        draft.measures = null;
        //draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_MEASURE_GROUP_CONTENT:
        draft.measureGroupContent = null;
        draft.eventContent = null;
        draft.events = null;
        draft.measures = null;
        //draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // PROD Event content api
      case measureGroupsContainerTypes.REQUEST_GET_PROD_EVENT_CONTENT:
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.measuresContent = null;
        // draft.events = null;
        draft.data = null;
        draft.measures = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_PROD_EVENT_CONTENT:
        draft.eventContent = action.eventContent;
        draft.measureGroupContent = null;
        draft.measuresContent = null;
        // draft.events = null;
        draft.measures = null;
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_PROD_EVENT_CONTENT:
        draft.eventContent = null;
        draft.measureGroupContent = null;
        draft.measuresContent = null;
        // draft.events = null;
        draft.measures = null;
        draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // PROD Measure Group content api
      case measureGroupsContainerTypes.REQUEST_GET_PROD_MEASURE_GROUP_CONTENT:
        draft.measuresContent = null;
        draft.measureGroupContent = null;
        draft.eventContent = null;
        draft.events = null;
        //draft.data = null;
        draft.measures = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_PROD_MEASURE_GROUP_CONTENT:
        draft.measuresContent = null;
        draft.measureGroupContent = action.measureGroupContent;
        draft.eventContent = null;
        draft.events = null;
        draft.measures = null;
        //draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_PROD_MEASURE_GROUP_CONTENT:
        draft.measuresContent = null;
        draft.measureGroupContent = null;
        draft.eventContent = null;
        draft.events = null;
        draft.measures = null;
        //draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // PROD Measures content api
      case measureGroupsContainerTypes.REQUEST_GET_PROD_MEASURES_CONTENT:
        draft.measuresContent = null;
        draft.measureGroupContent = null;
        draft.eventContent = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_PROD_MEASURES_CONTENT:
        draft.measuresContent = action.measuresContent;
        draft.measureGroupContent = null;
        draft.eventContent = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_PROD_MEASURES_CONTENT:
        draft.measuresContent = null;
        draft.measureGroupContent = null;
        draft.eventContent = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // get events help text
      case measureGroupsContainerTypes.REQUEST_GET_EVENTS_HELP_TEXT:
        draft.singleEvent = null;
        draft.eventsHelpText = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_EVENTS_HELP_TEXT:
        draft.singleEvent = null;
        draft.eventsHelpText = action.eventsHelpText;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_EVENTS_HELP_TEXT:
        draft.singleEvent = null;
        draft.eventsHelpText = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // get measure group help text
      case measureGroupsContainerTypes.REQUEST_GET_MEASURE_GROUPS_HELP_TEXT:
        draft.singleMeasureGroup = null;
        draft.measureGroupsHelpText = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_MEASURE_GROUPS_HELP_TEXT:
        draft.singleMeasureGroup = null;
        draft.measureGroupsHelpText = action.measureGroupsHelpText;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_MEASURE_GROUPS_HELP_TEXT:
        draft.singleMeasureGroup = null;
        draft.measureGroupsHelpText = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // get measures help text
      case measureGroupsContainerTypes.REQUEST_GET_MEASURES_HELP_TEXT:
        draft.singleMeasure = null;
        draft.measuresHelpText = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_MEASURES_HELP_TEXT:
        draft.singleMeasure = null;
        draft.measuresHelpText = action.measuresHelpText;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_MEASURES_HELP_TEXT:
        draft.singleMeasure = null;
        draft.measuresHelpText = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      //  delete measure group
      case measureGroupsContainerTypes.REQUEST_DELETE_MEASURE_GROUP:
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        draft.shouldReloadMeasureGroup = false;
        break;
      case measureGroupsContainerTypes.SUCCESS_DELETE_MEASURE_GROUP:
        draft.method = action.method;
        draft.data = action.data;
        draft.loading = false;
        draft.error = null;
        draft.shouldReloadMeasureGroup = true;
        break;
      case measureGroupsContainerTypes.FAILURE_DELETE_MEASURE_GROUP:
        draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        draft.shouldReloadMeasureGroup = false;
        break;

      // get event by id
      case measureGroupsContainerTypes.REQUEST_GET_EVENT_BY_ID:
        draft.loadingAfterPost = true;
        draft.singleEvent = null;
        draft.postEventData = null;
        draft.loading = true;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_EVENT_BY_ID:
        draft.singleEvent = action.singleEvent;
        draft.postEventData = null;
        draft.loading = false;
        draft.error = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_EVENT_BY_ID:
        draft.singleEvent = null;
        draft.postEventData = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // get measure group by id
      case measureGroupsContainerTypes.REQUEST_GET_MEASURE_GROUP_BY_ID:
        draft.loadingAfterPost = true;
        draft.singleMeasureGroup = null;
        draft.loading = true;
        draft.error = null;
        draft.postMeasureGroupData = null;
        break;
      case measureGroupsContainerTypes.SUCCESS_GET_MEASURE_GROUP_BY_ID:
        draft.singleMeasureGroup = action.singleMeasureGroup;
        draft.loading = false;
        draft.error = null;
        draft.postMeasureGroupData = null;
        break;
      case measureGroupsContainerTypes.FAILURE_GET_MEASURE_GROUP_BY_ID:
        draft.singleMeasureGroup = null;
        draft.loading = false;
        draft.error = action.error;
        draft.postMeasureGroupData = null;
        break;

      case clearMeasureStatusTypes.CLEAR_MEASURE_STATUS:
        draft.status = null;
        draft.method = null;
        break;

      default:
        return state;
    }
  });
export default measureGroupsContainerReducer;
