import dayjs from 'dayjs';
import { produce } from 'immer';
import { createActions } from 'reduxsauce';
import { setUserCookies } from '../../utils/coockies';
import jwtDecode from 'jwt-decode';

export const { Types: loginContainerTypes, Creators: loginContainerCreators } = createActions({
  requestGetAuth: ['data', 'inviteId', 'intl'],
  successGetAuth: ['data'],
  failureGetAuth: ['loginError'],

  // forgot-password
  requestPostEmail: ['data', 'setIsPasswordForgotten', 'setShowToast'],
  successPostEmail: ['data'],
  failurePostEmail: ['error'],

  // verify-email
  requestGetVerifyEmail: ['emailVerificationId'],
  successGetVerifyEmail: ['data'],
  failureGetVerifyEmail: ['checkEmailError'],

  //login error null
  setLoginErr: []
});

// redirect Url
export const { Types: redirectUrlTypes, Creators: redirectUrlCreators } = createActions({
  redirectUrl: ['url'],
  clearRedirectUrl: [],
});

export const { Types: userContainerTypes, Creators: userContainerCreators } = createActions({
  requestSetUser: ['isOrgExist', 'data', 'setShowToast', 'setIsOrgExist', 'setIsFocus', 'setActiveTab', 'navigate'],
  successSetUser: ['data'],
  failureSetUser: ['createAccountErr'],
});

export type InitialStateType = {
  data: object | null;
  error: string | null;
  loginError: string | null;
  createAccountErr: string | null;
  checkEmailError: string | null;
  loading: boolean | null;
  isUserLoggedIn: boolean | null;
  redirectUrl?: string | null;
}

export const initialState: InitialStateType = { data: null, error: null, loginError: null, loading: null, isUserLoggedIn: null, checkEmailError: null, createAccountErr: null, redirectUrl: null };

export const loginContainerReducer = (state = initialState, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {
      case loginContainerTypes.REQUEST_GET_AUTH:
        draft.data = null;
        draft.loading = true;
        draft.loginError = null;
        draft.isUserLoggedIn = null;
        break;
      /* istanbul ignore next */
      case loginContainerTypes.SUCCESS_GET_AUTH:
        const expiryIn = dayjs().add(action.data.expiresIn, 'second');
        setUserCookies(action.data.refreshToken, expiryIn.toDate());
        draft.data = action.data;
        draft.loading = false;
        draft.loginError = null;
        draft.isUserLoggedIn = true;
        break;
      /* istanbul ignore next */
      case loginContainerTypes.FAILURE_GET_AUTH:
        draft.data = null;
        draft.loading = false;
        draft.loginError = action.loginError;
        draft.isUserLoggedIn = false;
        break;

      // forgot-password
      case loginContainerTypes.REQUEST_POST_EMAIL:
        draft.data = null;
        draft.loading = true;
        draft.error = null;
        break;
      case loginContainerTypes.SUCCESS_POST_EMAIL:
        draft.data = null;
        draft.loading = false;
        draft.error = null;
        break;
      case loginContainerTypes.FAILURE_POST_EMAIL:
        draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;

      // verify-email
      case loginContainerTypes.REQUEST_GET_VERIFY_EMAIL:
        draft.data = null;
        draft.loading = true;
        draft.checkEmailError = null;
        draft.error = null;
        break;
      case loginContainerTypes.SUCCESS_GET_VERIFY_EMAIL:
        draft.data = null;
        draft.loading = false;
        draft.checkEmailError = null;
        draft.error = null;
        break;
      case loginContainerTypes.FAILURE_GET_VERIFY_EMAIL:
        draft.data = null;
        draft.loading = false;
        draft.checkEmailError = action.checkEmailError;
        draft.error = null;
        break;

      //create account
      case userContainerTypes.REQUEST_SET_USER:
        draft.data = null;
        draft.loading = true;
        draft.createAccountErr = null;
        draft.checkEmailError = null;
        break;
      case userContainerTypes.SUCCESS_SET_USER:
        draft.data = action.data;
        draft.loading = false;
        draft.createAccountErr = null;
        draft.checkEmailError = null;
        break;
      case userContainerTypes.FAILURE_SET_USER:
        draft.data = null;
        draft.loading = false;
        draft.createAccountErr = action.createAccountErr;
        draft.checkEmailError = null;
        break;
      /* istanbul ignore next */
      case loginContainerTypes.SET_LOGIN_ERR:
        draft.loading = false;
        draft.loginError = null;
        break;

      case redirectUrlTypes.REDIRECT_URL:
        draft.redirectUrl = action.url;
        break;

      case redirectUrlTypes.CLEAR_REDIRECT_URL:
        draft.redirectUrl = null;
        break;

      default:
        return state;
    }
  })
export default loginContainerReducer;
