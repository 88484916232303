import {
    AxiosError,
    AxiosResponse
} from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { getPlansService } from '../../services/plansService';
import { plansContainerTypes, plansContainerCreators } from './reducer';

type PlansDataCallType = {
    data: object;
}

const {
    REQUEST_GET_PLANS,
} = plansContainerTypes;
/* istanbul ignore next */
export function* plansDataCall(data: PlansDataCallType) {
    try {
        const response: AxiosResponse = yield call(getPlansService);
        yield put(plansContainerCreators.successGetPlans(response.data))
    } catch (e) {
        if (e instanceof AxiosError) {
            yield put(plansContainerCreators.failureGetPlans(e.response?.data.errors[0].message));
        } else {
            yield put(plansContainerCreators.failureGetPlans("Something went wrong, please try again later!"));
        }
    }
}
/* istanbul ignore next */
export default function* plansContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_GET_PLANS, plansDataCall);
}
