import { AxiosPromise } from 'axios';
import { appContainerCreators } from '../AppReducer';
import { EXPIRY_IN, USER_COOKIE } from '../enum';
import { store } from '../store';
import { getBaseURL } from '../utils';
import cookies from '../utils/coockies';
import axios from './axios';

export const userInfoService = (data: object): AxiosPromise => axios.post(`/organizationservice/v1/auth/refresh-token`, data)

const logout = () => {
    cookies.remove(USER_COOKIE);
    cookies.remove(EXPIRY_IN);
    store.dispatch(appContainerCreators.clearUserInfo());
    window.location.href = "/";
}

export const userInfoCall = async (data: any) => {

    const refreshToken = data.refreshToken !== 'null' ? data.refreshToken : cookies.get(USER_COOKIE);

    const rawResponse = await fetch(`${getBaseURL()}/organizationservice/v1/auth/refresh-token`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refreshToken })
    })

    if (rawResponse.ok) {
        return rawResponse.json()
    } else {
        logout();
    }
}