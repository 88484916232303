import { AxiosPromise } from "axios";
import axios from "axios";
import { getBaseURL } from "../utils";

let instance = axios.create({
  baseURL: getBaseURL(),
});
export const resetPasswordService = (data: object, token: string): AxiosPromise =>
instance.post(`/organizationservice/v1/auth/changepassword`, data, {
    headers:{
        resetPasswordToken: token,
    }
  });