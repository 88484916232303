import { AxiosError, AxiosResponse } from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { resetPasswordService } from '../../services/resetPassService';
import { resetPasswordContainerCreators, resetPasswordContainerTypes } from './reducer';

const { REQUEST_POST_RESET_PASSWORD } = resetPasswordContainerTypes;

type ChangePasswordAPIType = {
    data: {
        newPassword: string;
        currentPassword: string;
    },
    token: string;
}

    /* istanbul ignore next */
export function* changePasswordAPI(data: ChangePasswordAPIType) {
    try {
      const response: AxiosResponse = yield call(resetPasswordService, data.data, data.token);
      yield put(resetPasswordContainerCreators.successPostResetPassword(response.data))
    } catch (e) {
      if (e instanceof AxiosError) {
        yield put(resetPasswordContainerCreators.failurePostResetPassword(e.response?.data.errors[0].message));
      } else {
        yield put(resetPasswordContainerCreators.failurePostResetPassword("Something went wrong, please try again later!"));
      }
    }
  }

  /* istanbul ignore next */
export default function* resetPassContainerSaga() {
    // @ts-ignore
    yield takeLatest(REQUEST_POST_RESET_PASSWORD, changePasswordAPI);

  }