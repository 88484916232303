import {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { selectUserInfo } from './AppSelector';
import { routeConfig } from './routeConfig';
import { appContainerCreators } from './AppReducer';
import { setAccessToken } from './services/axios';
import { USER_COOKIE } from './enum';
import { languageProviderActions } from './containers/LanguageProvider/reducer';
import { selectLanguage } from './containers/LanguageProvider/selectors';
import cookies from './utils/coockies';
import MenuIcon from './assets/icons/menu-icon.svg';
import LoadingIcon from './assets/icons/loading-icon';
import SideBar from './components/SideBar';
import './App.css';
import jwtDecode from 'jwt-decode';
import { redirectUrlCreators } from './containers/LoginContainer/reducer';

function App() {

  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const refreshToken = cookies.get(USER_COOKIE);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(window.innerWidth > 576 ? true : false);
  const langSelector = useSelector(selectLanguage);
  const twoFapath: any = (location.pathname === "/two-fa" || location.pathname === "/auth-two-fa" || location.pathname === "/auth-success")
  const navigate = useNavigate();
  const isUserLoggedIn = userInfo !== null;
  const [urlStore, setUrlStore] = useState<any>("");
  const twoFactorURL = ['/', '/two-fa', '/auth-success', '/auth-two-fa'];
  const decodeJWTUserInfo: any = userInfo && userInfo?.accessToken && jwtDecode(userInfo?.accessToken || "")

  const renderRoutes = useCallback((routeKey: string, index: number) => {
    const routeConfigs = routeConfig[routeKey];
    const Component = routeConfigs.component;
    const isProtected = routeConfigs.isProtected;
    if (isProtected && !isUserLoggedIn) {
      return <Route key={index} path={routeConfigs.route} element={<Navigate to='/' replace />} />;
    }
    else if (!isUserLoggedIn) {
      return <Route key={index} path={routeConfigs.route} element={<Component />} />;
    }
    else if (isProtected && isUserLoggedIn && refreshToken !== 'null') {
      return <Route key={index} path={routeConfigs.route} element={<Component />} />;
    }
    // if (!isProtected && isUserLoggedIn && routeConfigs.route === '/use-invite') {
    //   return <Route key={index} path={routeConfigs.route} element={<Navigate to='/dashboard' replace />} />;
    // }

    // return (
    //   <Route
    //     key={index}
    //     path={routeConfigs.route}
    //     element={<Component />}
    //   />
    // );
  }, [userInfo]);

  const renderAllRoutes = useMemo(() => {
    setUrlStore(location.pathname)
    return Object.keys(routeConfig).map(renderRoutes)
  }, [renderRoutes])

  useEffect(() => {
    setTimeout(() => {
      if (!twoFactorURL.includes(urlStore)) {
        dispatch(redirectUrlCreators.redirectUrl(urlStore))
      }
    }, 500);
  }, [urlStore]);

  const fetchUserInfo = () => {
    if (refreshToken && refreshToken !== 'null') {
      dispatch(appContainerCreators.getUserInfo(refreshToken));
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!userInfo) {
      fetchUserInfo();
    }
    else {
      setLoading(false);
      setAccessToken(userInfo.accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])/* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    dispatch(languageProviderActions.changeLocale(langSelector?.locale));
  }, [langSelector?.locale])/* eslint-disable react-hooks/exhaustive-deps */

  function handleShowSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  useEffect(() => {
    if (userInfo && userInfo?.accessToken) {
      if (!twoFactorURL.includes(location.pathname) && (decodeJWTUserInfo && decodeJWTUserInfo?.payload && !decodeJWTUserInfo?.payload?.twoFaOk)) {
        navigate(-1);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo?.accessToken) {
    if (decodeJWTUserInfo && decodeJWTUserInfo?.payload && decodeJWTUserInfo?.payload?.twoFa === 0) {
      navigate('/two-fa');
    }
    else if (decodeJWTUserInfo && decodeJWTUserInfo?.payload && decodeJWTUserInfo?.payload?.twoFaOk === false && (decodeJWTUserInfo?.payload?.twoFa === 1 || decodeJWTUserInfo?.payload?.twoFa === 2)) {
      navigate('/auth-two-fa')
    }
  }
  }, [userInfo])

  return (
    <div className='App'>
      {userInfo !== null && !twoFapath &&
        <div onClick={() => handleShowSidebar()} className='hidden sm:flex sm:items-center sm:absolute sm:left-6 sm:top-7'>
          <img className='w-4 h-4' src={MenuIcon} alt='' />
          <div className='font-semibold text-base text-[#959595] mx-2'>{intl.formatMessage({ id: 'menu' })}</div>
        </div>
      }
      {(userInfo !== null && !twoFapath && decodeJWTUserInfo?.payload?.twoFaOk) ?
        <SideBar isSidebarOpen={isSidebarOpen} handleShowSidebar={handleShowSidebar} />
        :
        <></>
      }
      <div className={'App-Bg min-h-screen' + (userInfo !== null ? ` sm:ml-0 sm:w-full ${(twoFapath || location.pathname === "/dashboard") && !decodeJWTUserInfo?.payload?.twoFaOk ? "ml-[-60] px-0 py-0" : "ml-60 px-32 py-7 sm:px-5 sm:py-[72px]"} ` : "")}>
        {
          loading ?
            <div className='app-loader'>
              <LoadingIcon />
            </div>
            :
            <Routes>
              {renderAllRoutes}
              {
                !isUserLoggedIn &&
                <Route path='*' element={<Navigate to='/' replace />} />
              }
              {
                isUserLoggedIn &&
                <Route path='*' element={<Navigate to='/dashboard' replace />} />
              }
            </Routes>
        }
      </div>
    </div>
  );
}

export default App;
