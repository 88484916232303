import { AxiosPromise } from 'axios';
import axios from './axios';

export const taskService = (offset: number, pageSize: number): AxiosPromise => axios.get(`/bucoscanservice/v1/tasks?offset=${offset}&pageSize=${pageSize}`)

export const dashBoardTaskListOneService = (offset: number, pageSize: number, dashboardList: number): AxiosPromise => axios.get(`/bucoscanservice/v1/tasks?offset=${offset}&pageSize=${pageSize}&dashboardList=${dashboardList}`)

export const dashBoardTaskListTwoService = (offset: number, pageSize: number, dashboardList: number): AxiosPromise => axios.get(`/bucoscanservice/v1/tasks?offset=${offset}&pageSize=${pageSize}&dashboardList=${dashboardList}`)

export const taskListUpdateService = (taskId: string,data : object): AxiosPromise => axios.put(`/bucoscanservice/v1/tasks/${taskId}`,data)

export const todoTaskListService = (offset: number, pageSize: number, status: number): AxiosPromise => axios.get(`/bucoscanservice/v1/tasks?offset=${offset}&pageSize=${pageSize}&status=${status}`)

export const doingTaskListService = (offset: number, pageSize: number, status: number): AxiosPromise => axios.get(`/bucoscanservice/v1/tasks?offset=${offset}&pageSize=${pageSize}&status=${status}`)