import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: subscriptionContainerTypes, Creators: subscriptionContainerCreators } = createActions({
    // get plans
    requestGetSubscriptionPlans: [],
    successGetSubscriptionPlans: ['plans'],
    failureGetSubscriptionPlans: ['error'],

    // get Subscription
    requestGetSubscription: [],
    successGetSubscription: ['data'],
    failureGetSubscription: ['error'],

    // post Subscription
    requestPostSubscription: ['data', 'navigate'],
    successPostSubscription: ['data'],
    failurePostSubscription: ['error'],

    // get DiscountCode
    requestGetDiscountCode: ['discountCode', 'planId'],
    successGetDiscountCode: ['discount'],
    failureGetDiscountCode: ['error'],

    // delete my renewal
    requestDeleteMyRenewal: ['subscriptionId', 'setShowDeleteBtnToast'],
    successDeleteMyRenewal: ['data', 'method'],
    failureDeleteMyRenewal: ['error'],

    // get rolelimits
    requestGetRoleLimits: ['organizationId'],
    successGetRoleLimits: ['roleLimits'],
    failureGetRoleLimits: ['error'],

    // PLANS Help Text
    requestGetPlansHelpText: ['contentType', 'decodedUserInfo'],
    successGetPlansHelpText: ['planHelpText'],
    failureGetPlansHelpText: ['error'],

    // delete my subscription
    requestDeleteMySubscription: ['subscriptionId', 'setShowDeleteSubscriptionToast'],
    successDeleteMySubscription: ['deleteSubData', 'method'],
    failureDeleteMySubscription: ['error'],
});

export const { Types: invoicesContainerTypes, Creators: invoicesContainerCreators } = createActions({
    requestGetInvoices: [],
    successGetInvoices: ['invoices'],
    failureGetInvoices: ['error'],

    // get Get Invoice Pdf
    requestGetInvoicesPdf: ['invoiceId'],
    successGetInvoicesPdf: ['invoicesPdf'],
    failureGetInvoicesPdf: ['error'],
});

export type InitialStateType = {
    plans: object | null;
    data: object | null;
    error: string | null;
    loading: boolean | null;
    invoices: object[] | null;
    discount: object | null;
    isReloadSubscription: boolean,
    roleLimits: object[] | null;
    invoicesPdf: object[] | null;
    planHelpText: object | null;
    deleteSubData: object | null;
    status?: number | null;
    method?: string | null;
}

export const initialState: InitialStateType = { plans: null, data: null, deleteSubData: null, error: null, loading: null, invoices: null, discount: null, isReloadSubscription: false, roleLimits: null, invoicesPdf: null, planHelpText: null, status: null, method: null };

export const subscriptionContainerReducer = (state = initialState, action: any) =>
    produce(state, (draft) => {
        switch (action.type) {
            // get Plans
            case subscriptionContainerTypes.REQUEST_GET_SUBSCRIPTION_PLANS:
                draft.plans = null;
                draft.discount = null;
                draft.loading = true;
                draft.error = null;
                break;
            case subscriptionContainerTypes.SUCCESS_GET_SUBSCRIPTION_PLANS:
                draft.plans = action.plans;
                draft.discount = null;
                draft.loading = false;
                draft.error = null;
                break;
            case subscriptionContainerTypes.FAILURE_GET_SUBSCRIPTION_PLANS:
                draft.plans = null;
                draft.discount = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get Subscription
            case subscriptionContainerTypes.REQUEST_GET_SUBSCRIPTION:
                draft.method = null;
                draft.status = null;
                draft.data = null;
                draft.loading = true;
                draft.error = null;
                draft.invoices = null;
                draft.isReloadSubscription = false;
                break;
            case subscriptionContainerTypes.SUCCESS_GET_SUBSCRIPTION:
                draft.method = null;
                draft.status = null;
                draft.data = action.data;
                draft.loading = false;
                draft.error = null;
                draft.invoices = null;
                draft.isReloadSubscription = false;
                break;
            case subscriptionContainerTypes.FAILURE_GET_SUBSCRIPTION:
                draft.status = null;
                draft.data = null;
                draft.loading = false;
                draft.error = action.error;
                draft.invoices = null;
                draft.isReloadSubscription = false;
                break;

            // post Subscription
            case subscriptionContainerTypes.REQUEST_POST_SUBSCRIPTION:
                draft.data = null;
                draft.loading = true;
                draft.error = null;
                break;
            case subscriptionContainerTypes.SUCCESS_POST_SUBSCRIPTION:
                draft.data = action.data;
                draft.loading = false;
                draft.error = null;
                break;
            case subscriptionContainerTypes.FAILURE_POST_SUBSCRIPTION:
                draft.data = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get Discount code
            case subscriptionContainerTypes.REQUEST_GET_DISCOUNT_CODE:
                draft.discount = null;
                draft.loading = true;
                draft.error = null;
                break;
            case subscriptionContainerTypes.SUCCESS_GET_DISCOUNT_CODE:
                draft.discount = action.discount;
                draft.loading = false;
                draft.error = null;
                break;
            case subscriptionContainerTypes.FAILURE_GET_DISCOUNT_CODE:
                draft.discount = null;
                draft.loading = false;
                draft.error = action.error;
                break;


            case invoicesContainerTypes.REQUEST_GET_INVOICES:
                draft.invoices = null;
                draft.loading = true;
                draft.error = null;
                break;
            case invoicesContainerTypes.SUCCESS_GET_INVOICES:
                draft.invoices = action.invoices;
                draft.loading = false;
                draft.error = null;
                break;
            case invoicesContainerTypes.FAILURE_GET_INVOICES:
                draft.invoices = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // delete my renewal
            case subscriptionContainerTypes.REQUEST_DELETE_MY_RENEWAL:
                draft.method = null;
                draft.data = null;
                draft.loading = true;
                draft.error = null;
                draft.isReloadSubscription = false;
                break;
            case subscriptionContainerTypes.SUCCESS_DELETE_MY_RENEWAL:
                draft.method = action.method;
                draft.data = action.data;
                draft.loading = false;
                draft.error = null;
                draft.isReloadSubscription = true;
                break;
            case subscriptionContainerTypes.FAILURE_DELETE_MY_RENEWAL:
                draft.data = null;
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadSubscription = false;
                break;

            // get rolelimits
            case subscriptionContainerTypes.REQUEST_GET_ROLE_LIMITS:
                draft.roleLimits = null;
                draft.loading = true;
                draft.error = null;
                break;
            case subscriptionContainerTypes.SUCCESS_GET_ROLE_LIMITS:
                draft.method = null;
                draft.status = null;
                draft.roleLimits = action.roleLimits;
                draft.loading = false;
                draft.error = null;
                break;
            case subscriptionContainerTypes.FAILURE_GET_ROLE_LIMITS:
                draft.roleLimits = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get invoice pdf
            case invoicesContainerTypes.REQUEST_GET_INVOICES_PDF:
                draft.invoicesPdf = null;
                draft.loading = true;
                draft.error = null;
                break;
            case invoicesContainerTypes.SUCCESS_GET_INVOICES_PDF:
                draft.invoicesPdf = action.invoicesPdf;
                draft.loading = false;
                draft.error = null;
                break;
            case invoicesContainerTypes.FAILURE_GET_INVOICES_PDF:
                draft.invoicesPdf = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get PLANS help text
            case subscriptionContainerTypes.REQUEST_GET_PLANS_HELP_TEXT:
                draft.planHelpText = null;
                draft.loading = true;
                draft.error = null;
                break;
            case subscriptionContainerTypes.SUCCESS_GET_PLANS_HELP_TEXT:
                draft.planHelpText = action.planHelpText;
                draft.loading = false;
                draft.error = null;
                break;
            case subscriptionContainerTypes.FAILURE_GET_PLANS_HELP_TEXT:
                draft.planHelpText = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // delete my subscription
            case subscriptionContainerTypes.REQUEST_DELETE_MY_SUBSCRIPTION:
                // draft.method=null;
                draft.deleteSubData = null;
                draft.loading = true;
                draft.error = null;
                draft.isReloadSubscription = false;
                break;
            case subscriptionContainerTypes.SUCCESS_DELETE_MY_SUBSCRIPTION:
                draft.method = action.method;
                draft.deleteSubData = action.deleteSubData;
                draft.loading = false;
                draft.error = null;
                draft.isReloadSubscription = true;
                break;
            case subscriptionContainerTypes.FAILURE_DELETE_MY_SUBSCRIPTION:
                draft.deleteSubData = null;
                draft.loading = false;
                draft.error = action.error;
                draft.isReloadSubscription = false;
                break;

            default:
                return state;
        }
    })
export default subscriptionContainerReducer;
