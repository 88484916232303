import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import LoadingIcon from '../../assets/icons/loading-icon';
import CustomMatrixChart from '../../components/CustomMatrixChart/CustomMatrixChart';
import Container from '../../components/Container/Container';
import { matrixContainerCreators } from './matrixReducer';
import { selectDecodedUserInfo } from '../../AppSelector';
import StyledButton from '../../components/StyledButton';
import EditIcon from '../../assets/icons/edit-icon.svg';
import CloseIcon from "../../assets/icons/close-icon.svg";

const testId = `matrix-container`;

export type MatrixRiskCategoryType = {
    assesedStatus: number;
    name: string;
    nameWhenOther: string;
    riskCategoryId: string;
    riskFactor: number;
    riskListId: string;
    selected: number;
    status: number;
    task: {
        assignedTo: string;
        deadline: string;
    }
}

export default function MatrixContainer() {

    // hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload?.role;
    const riskCategoryMatrixSelector = useSelector((state: RootState) => state.riskAreContainer);
    const matrixSelector = useSelector((state: RootState) => state.matrixContainer);
    // states
    const isMatrixFilter = false;
    const [matrixRiskCategory, setMatrixRiskCategory] = useState<MatrixRiskCategoryType[]>([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [accountantComment, setAccountantComment] = useState<string>("");
    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);

    useEffect(() => {
        if (riskAreaSelector?.status !== null && riskAreaSelector?.status !== undefined) {
            setIsSaved(true);
            setTimeCounter(1);
        }
    }, [riskAreaSelector.status])

    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved]);

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        dispatch(matrixContainerCreators.requestGetAccountantComments());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskCategoryMatrixSelector.shouldRealoadRiskCategory) {
            dispatch(riskAreaContainerCreators.requestGetRiskCategories());
        }
    }, [riskCategoryMatrixSelector.shouldRealoadRiskCategory])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskCategoryMatrixSelector && riskCategoryMatrixSelector.riskCategory && riskCategoryMatrixSelector.riskCategory.length > 0) {
            const filteredRiskCategory = riskCategoryMatrixSelector.riskCategory.filter((ele: MatrixRiskCategoryType) => ele.riskFactor !== undefined && ele.riskFactor !== 0 && ele.assesedStatus !== undefined && ele.assesedStatus !== 0);
            setMatrixRiskCategory(filteredRiskCategory);
        }
    }, [riskCategoryMatrixSelector])

    useEffect(() => {
        if (matrixSelector.shouldReloadAcctComments) {
            dispatch(matrixContainerCreators.requestGetAccountantComments());
        }
        if (matrixSelector && matrixSelector?.acctComments) {
            if (((role === "accountant") || (role === "partneraccountant")) && matrixSelector?.acctComments?.text === "") {
                setIsEditMode(true);
            } else {
                setIsEditMode(false);
            }
            setAccountantComment(matrixSelector?.acctComments?.text);
        }
    }, [matrixSelector])/* eslint-disable react-hooks/exhaustive-deps */

    function handleEditComment() {
        setIsEditMode(true);
    }

    function handleEditCommentClose() {
        setIsEditMode(false);
    }

    function handleSaveClick() {
        const payload = {
            text: accountantComment,
        }
        dispatch(matrixContainerCreators.requestPutAccountantComments(payload));
        setIsEditMode(false);
    }

    return (
        <>
            {riskCategoryMatrixSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div>
                    <Container>
                        {isSaved ?
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                            </div>
                            :
                            <div className="font-normal text-xs text-[#959595] text-end">
                                <div className="h-4">{"  "}</div>
                            </div>
                        }
                        <div className="flex">
                            <div className={`flex flex-col my-10 sm:w-full sm:my-6 ${isMatrixFilter ? 'sm:hidden' : 'sm:block'}`}>
                                <div className='flex items-center justify-between'>
                                    <div className='font-semibold text-5xl mb-4 sm:text-2xl'>
                                        {intl.formatMessage({ id: 'matrix', defaultMessage: 'matrix' })}
                                    </div>
                                    {/* Matrix Mobile Filter */}
                                    {/* <div onClick={() => handleShowMatrixFilter()} className='hidden sm:flex sm:items-center '>
                                    <div className='font-bold text-base text-[#3BA8FC] mr-2'>{intl.formatMessage({ id: 'filter' })}</div>
                                    <img src={FilterIcon} alt='' />
                                </div> */}
                                </div>
                                <div>
                                    <div className='font-normal text-lg mb-10 w-[526px] sm:w-full sm:text-sm sm:mb-2 font-family-arial'>
                                        {matrixRiskCategory.length > 0 ?
                                            intl.formatMessage({ id: 'matrix_title', defaultMessage: 'matrix_title' })
                                            :
                                            <>
                                                <div>{intl.formatMessage({ id: 'matrix_title', defaultMessage: 'matrix_title' })}</div>
                                                <br />
                                                <div>{intl.formatMessage({ id: 'no_matrix_risk_category_found', defaultMessage: 'no_matrix_risk_category_found' })}</div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className='matrix-chart sm:overflow-x-auto'>
                                    <CustomMatrixChart matrixRiskCategory={matrixRiskCategory} testId={testId} from={"matrix"} />
                                </div>
                            </div>

                            <div className={`flex flex-col m-12 min-w-[220px] w-full sm:m-6 sm:pl-0 ${isMatrixFilter ? 'sm:block' : 'sm:hidden'}`}>
                            </div>
                        </div>

                        {/* Accountant comments section */}
                        {decodedUserInfo?.payload?.plan === 3 &&
                            <div className='max-w-[586px] mx-10 my-0 sm:mx-6 sm:my-6'>
                                {((accountantComment !== "") || ((role === "accountant") || (role === "partneraccountant"))) &&
                                    <div className='flex items-center justify-between'>
                                        <div className="font-semibold text-xl mb-2">{intl.formatMessage({ id: 'accountant_comments', defaultMessage: 'accountant_comments' })}</div>
                                        {isEditMode ?
                                            <img data-testid={`${testId}-edit-comment-close-btn`} onClick={() => handleEditCommentClose()} className="w-[20px] h-[20px] cursor-pointer" src={CloseIcon} alt='' />
                                            :
                                            ((role === "accountant") || (role === "partneraccountant")) && <img data-testid={`${testId}-edit-icon-accountant`} onClick={() => handleEditComment()} className='w-[16px] h-[16px] cursor-pointer' src={EditIcon} alt='' />
                                        }
                                    </div>
                                }
                                <div>
                                    {!isEditMode ?
                                        <p className='font-family-arial text-[#535353] text-base max-w-[586px] break-all mb-2'>{accountantComment}</p>
                                        :
                                        <div>
                                            <textarea data-testid={`${testId}-commment-textarea`} className='custom-textarea p-3' placeholder={intl.formatMessage({ id: 'please_enter_your_comments', defaultMessage: 'please_enter_your_comments' })} value={accountantComment} onChange={(e) => setAccountantComment(e.target.value)} />
                                            <div>
                                                <StyledButton testId={`${testId}-save-btn`} onClick={() => handleSaveClick()} loading={matrixSelector?.loading} buttonClass={'sm:px-2 PrimoryBtn'} width={'w-40 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })} color={'text-white'} iconType={''} backgroundColor={'bg-black'} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </Container>
                </div>
            }
        </>
    )
}
