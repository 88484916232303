import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: dashboardContainerTypes, Creators: dashboardContainerCreators } = createActions({
    requestGetStatus: [],
    successGetStatus: ['data'],
    failureGetStatus: ['error'],

    // get Org List
    requestGetOrgList: ['userId'],
    successGetOrgList: ['orgList'],
    failureGetOrgList: ['error'],

    // post switch org
    requestPostSwitchOrg: ['data', 'inviteToast'],
    successPostSwitchOrg: ['userInfo', 'inviteToast', 'status'],
    failurePostSwitchOrg: ['error'],
    inviteToastMsg: ['inviteToast'],
    resetInviteToastMsg: [],

    // post add org
    requestPostAddOrg: ['duplicateOrgIntentional', 'data', 'onCancel', 'decodedUserInfo'],
    successPostAddOrg: ['userInfo', 'status'],
    failurePostAddOrg: ['error'],

    // delete org
    requestDeleteOrg: ['organizationId'],
    successDeleteOrg: ['orgList'],
    failureDeleteOrg: ['error'],

    // Get Partner Logo
    requestGetPartnerLogo: ['partnerId'],
    successGetPartnerLogo: ['partnerInfo'],
    failureGetPartnerLogo: ['error'],

    //get new version
    requestGetNewVersion: [],
    successGetNewVersion: ['getVersion'],
    failureGetNewVersion: ['getVersionError'],

    //post new version
    requestPostNewVersion: ['setShowPostVersionToast'],
    successPostNewVersion: ['postVersion', 'status'],
    failurePostNewVersion: ['postVersionError'],
});

export const { Types: welcomeContainerTypes, Creators: welcomeContainerCreators } = createActions({
    requestGetWelcome: [],
    successGetWelcome: ['contentType'],
    failureGetWelcome: ['error'],

    requestGetNoOrgWelcome: ['decodedUserInfo'],
    successGetNoOrgWelcome: ['contentType'],
    failureGetNoOrgWelcome: ['error'],

    requestGetOrgWelcomeText: ['orgWelcomeTextObj'],
    successGetOrgWelcomeText: ['contentType'],
    failureGetOrgWelcomeText: ['error'],

    requestGetPartnerOrgWelcomeText: ['orgWelcomeTextObj'],
    successGetPartnerOrgWelcomeText: ['contentType'],
    failureGetPartnerOrgWelcomeText: ['error'],
});

export const { Types: clearDashboardContainerTypes, Creators: clearDashboardContainerCreators } = createActions({
    //clear status
    clearDashboardStatus: []
});

export type InitialStateType = {
    data: object[] | null;
    error: string | null;
    postVersionError: string | null;
    getVersionError?: string | null;
    loading: boolean | null;
    contentType: object | null;
    orgList: object[] | null;
    isReloadOrgList: boolean;
    partnerInfo: object | null;
    inviteToastMsg?: boolean | string | null;
    getVersion?: object[] | null;
    postVersion?: object[] | null;
    status?: number | null;
}

export const initialState: InitialStateType = { data: null, error: null, getVersionError: null, postVersionError: null, loading: null, contentType: null, orgList: null, isReloadOrgList: false, partnerInfo: null, inviteToastMsg: null, getVersion: null, postVersion: null };

export const dashboardContainerReducer = (state = initialState, action: any) =>
    produce(state, (draft) => {
        switch (action.type) {
            case dashboardContainerTypes.REQUEST_GET_STATUS:
                draft.data = null;
                draft.loading = true;
                draft.error = null;
                break;
            case dashboardContainerTypes.SUCCESS_GET_STATUS:
                draft.data = action.data;
                draft.loading = false;
                draft.error = null;
                break;
            case dashboardContainerTypes.FAILURE_GET_STATUS:
                draft.data = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get Org List
            case dashboardContainerTypes.REQUEST_GET_ORG_LIST:
                draft.status = null;
                draft.orgList = null;
                draft.loading = true;
                draft.isReloadOrgList = false;
                draft.error = null;
                break;
            case dashboardContainerTypes.SUCCESS_GET_ORG_LIST:
                draft.status = null;
                draft.orgList = action.orgList;
                draft.loading = false;
                draft.isReloadOrgList = false;
                draft.error = null;
                break;
            case dashboardContainerTypes.FAILURE_GET_ORG_LIST:
                draft.status = null;
                draft.orgList = null;
                draft.loading = false;
                draft.isReloadOrgList = false;
                draft.error = action.error;
                break;

            // post switch Org
            case dashboardContainerTypes.REQUEST_POST_SWITCH_ORG:
                draft.status = null;
                draft.orgList = null;
                draft.loading = true;
                draft.error = null;
                break;
            case dashboardContainerTypes.SUCCESS_POST_SWITCH_ORG:
                draft.status = action.status;
                draft.orgList = action.orgList;
                draft.loading = false;
                draft.error = null;
                draft.inviteToastMsg = action.inviteToast;
                break;
            case dashboardContainerTypes.FAILURE_POST_SWITCH_ORG:
                draft.status = null;
                draft.orgList = null;
                draft.loading = false;
                draft.error = action.error;
                break;
            /* istanbul ignore next */
            case dashboardContainerTypes.RESET_INVITE_TOAST_MSG:
                draft.inviteToastMsg = null;
                break;
            case dashboardContainerTypes.INVITE_TOAST_MSG:
                draft.inviteToastMsg = action.inviteToast;
                break;
            // post add Org
            case dashboardContainerTypes.REQUEST_POST_ADD_ORG:
                draft.status = null;
                draft.orgList = null;
                draft.loading = true;
                draft.isReloadOrgList = false;
                draft.error = null;
                break;
            case dashboardContainerTypes.SUCCESS_POST_ADD_ORG:
                draft.orgList = action.orgList;
                draft.loading = false;
                draft.isReloadOrgList = true;
                draft.error = null;
                draft.status = action.status;
                break;
            case dashboardContainerTypes.FAILURE_POST_ADD_ORG:
                draft.status = null;
                draft.orgList = null;
                draft.loading = false;
                draft.isReloadOrgList = false;
                draft.error = action.error;
                break;

            // delete Org
            case dashboardContainerTypes.REQUEST_DELETE_ORG:
                draft.orgList = null;
                draft.loading = true;
                draft.isReloadOrgList = false;
                draft.error = null;
                break;
            case dashboardContainerTypes.SUCCESS_DELETE_ORG:
                draft.orgList = action.orgList;
                draft.loading = false;
                draft.isReloadOrgList = true;
                draft.error = null;
                break;
            case dashboardContainerTypes.FAILURE_DELETE_ORG:
                draft.orgList = null;
                draft.loading = false;
                draft.isReloadOrgList = false;
                draft.error = action.error;
                break;

            // get welcome text
            case welcomeContainerTypes.REQUEST_GET_WELCOME:
                draft.contentType = null;
                draft.loading = true;
                draft.error = null;
                break;
            case welcomeContainerTypes.SUCCESS_GET_WELCOME:
                draft.contentType = action.contentType;
                draft.loading = false;
                draft.error = null;
                break;
            case welcomeContainerTypes.FAILURE_GET_WELCOME:
                draft.contentType = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get welcome text when NO ORG
            case welcomeContainerTypes.REQUEST_GET_NO_ORG_WELCOME:
                draft.contentType = null;
                draft.loading = true;
                draft.error = null;
                break;
            case welcomeContainerTypes.SUCCESS_GET_NO_ORG_WELCOME:
                draft.contentType = action.contentType;
                draft.loading = false;
                draft.error = null;
                break;
            case welcomeContainerTypes.FAILURE_GET_NO_ORG_WELCOME:
                draft.contentType = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get Selected ORG welcome text
            case welcomeContainerTypes.REQUEST_GET_ORG_WELCOME_TEXT:
                draft.contentType = null;
                draft.loading = true;
                draft.error = null;
                break;
            case welcomeContainerTypes.SUCCESS_GET_ORG_WELCOME_TEXT:
                draft.contentType = action.contentType;
                draft.loading = false;
                draft.error = null;
                break;
            case welcomeContainerTypes.FAILURE_GET_ORG_WELCOME_TEXT:
                draft.contentType = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            //GET PARTNER LOGO
            case dashboardContainerTypes.REQUEST_GET_PARTNER_LOGO:
                draft.partnerInfo = null;
                draft.loading = true;
                draft.error = null;
                break;
            case dashboardContainerTypes.SUCCESS_GET_PARTNER_LOGO:
                draft.partnerInfo = action.partnerInfo;
                draft.loading = false;
                draft.error = null;
                break;
            case dashboardContainerTypes.FAILURE_GET_PARTNER_LOGO:
                draft.partnerInfo = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // get Selected ORG welcome text
            case welcomeContainerTypes.REQUEST_GET_PARTNER_ORG_WELCOME_TEXT:
                draft.contentType = null;
                draft.loading = true;
                draft.error = null;
                break;
            case welcomeContainerTypes.SUCCESS_GET_PARTNER_ORG_WELCOME_TEXT:
                draft.contentType = action.contentType;
                draft.loading = false;
                draft.error = null;
                break;
            case welcomeContainerTypes.FAILURE_GET_PARTNER_ORG_WELCOME_TEXT:
                draft.contentType = null;
                draft.loading = false;
                draft.error = action.error;
                break;
            /* istanbul ignore next */
            //get new version
            case dashboardContainerTypes.REQUEST_GET_NEW_VERSION:
                draft.loading = true;
                draft.getVersion = null;
                draft.postVersion = null;
                draft.getVersionError = null;
                break;
            /* istanbul ignore next */
            case dashboardContainerTypes.SUCCESS_GET_NEW_VERSION:
                draft.loading = false;
                draft.getVersion = action.getVersion;
                draft.postVersion = null;
                draft.getVersionError = null;
                break;
            /* istanbul ignore next */
            case dashboardContainerTypes.FAILURE_GET_NEW_VERSION:
                draft.loading = false;
                draft.getVersion = null;
                draft.postVersion = null;
                draft.getVersionError = action.getVersionError;
                break;
            //post new version
            case dashboardContainerTypes.REQUEST_POST_NEW_VERSION:
                draft.status = null;
                draft.loading = true;
                draft.postVersion = null;
                draft.postVersionError = null;
                break;
            case dashboardContainerTypes.SUCCESS_POST_NEW_VERSION:
                draft.status = action.status;
                draft.loading = false;
                draft.postVersion = action.postVersion;
                draft.postVersionError = null;
                break;
            case dashboardContainerTypes.FAILURE_POST_NEW_VERSION:
                draft.status = null;
                draft.loading = false;
                draft.postVersion = null;
                draft.postVersionError = action.postVersionError;
                break;

            case clearDashboardContainerTypes.CLEAR_DASHBOARD_STATUS:
                draft.status = null;
                break;

            default:
                return state;
        }
    })
export default dashboardContainerReducer;
