import { InfoDetails } from "../../containers/BIAContainer/BIAEdit";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";
import LoadingIcon from "../../assets/icons/loading-icon";
import { useIntl } from "react-intl";
import { useEffect, useRef } from "react";

type Props = {
    // infoCard?: InfoDetails;
    loading?: boolean;
    testId?: string;
    drawerInfo?: InfoDetails[];
    SideDrawerClose?: () => void;
}

export default function SideDrawer(props: Props) {

    const { loading, testId = "infocard", drawerInfo, SideDrawerClose } = props;
    const intl = useIntl();
    const ref = useRef<HTMLHeadingElement>(null);
/* istanbul ignore next */
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                SideDrawerClose && SideDrawerClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);/* eslint-disable react-hooks/exhaustive-deps */
    }, [ref]);/* eslint-disable react-hooks/exhaustive-deps */

/* istanbul ignore next */
    const handleInfoContent = (drawerInfoItem: InfoDetails) => {

        const richText = drawerInfoItem?.attributes?.InfoText;
        const isHttpsLink = drawerInfoItem?.attributes?.Video?.data?.attributes?.url.startsWith('https://');
        const videoSource = isHttpsLink ? drawerInfoItem?.attributes?.Video?.data?.attributes?.url : "https://" + drawerInfoItem?.attributes?.Video?.data?.attributes?.url

        if (drawerInfoItem?.attributes?.InfoText && !drawerInfoItem?.attributes?.Video.data && !drawerInfoItem?.attributes?.Image.data) {
            return (
                <div>
                    <div data-testid={`${testId}-infotext`} className="w-full flex flex-wrap font-normal text-base mb-2">
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{richText}</ReactMarkdown>
                    </div>
                    <div className="w-full flex flex-wrap font-normal text-sm tracking-[0.25px] text-[#959595]">
                        <ReactMarkdown remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{drawerInfoItem?.attributes?.SubText}</ReactMarkdown>
                    </div>
                </div>
            )
        } else if (!drawerInfoItem?.attributes?.InfoText && drawerInfoItem?.attributes?.Video && drawerInfoItem?.attributes?.Video?.data) {
            return (
                <video data-testid={`${testId}-video`} controls src={videoSource} />
            )
        } else if (!drawerInfoItem?.attributes?.InfoText && drawerInfoItem?.attributes?.Image && drawerInfoItem?.attributes?.Image?.data) {
            return (
                <img data-testid={`${testId}-image`} src={drawerInfoItem?.attributes?.Image?.data?.attributes?.url} alt='' />
            )
        } else if (drawerInfoItem?.attributes?.InfoText && drawerInfoItem?.attributes?.Video && drawerInfoItem?.attributes?.Video?.data) {
            return (
                <div>
                    <video controls src={videoSource} />
                    <div className="w-full flex flex-wrap font-normal text-sm tracking-[0.25px] text-[#959595] mt-2">
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{richText}</ReactMarkdown>
                    </div>
                </div>
            )
        } else if (drawerInfoItem?.attributes?.InfoText && drawerInfoItem?.attributes?.Image && drawerInfoItem?.attributes?.Image?.data) {
            return (
                <div>
                    <img src={drawerInfoItem?.attributes?.Image?.data?.attributes?.url} alt='' />
                    <div className="w-full flex flex-wrap font-normal text-sm tracking-[0.25px] text-[#959595] mt-2">
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{richText}</ReactMarkdown>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <div data-testid={`${testId}`} className="overflow-y-auto bg-white h-screen px-6 py-4 w-full shadow-[0_16px_48px_-15px_rgba(0,0,0,0.3)]">
                <div className="flex items-center justify-end">
                    <svg onClick={() => SideDrawerClose && SideDrawerClose()} className="w-5 h-5 cursor-pointer" fill="#959595" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </div>
                <div className="w-full font-bold text-xs mb-5 text-[#959595] tracking-[1px] font-family-arial uppercase">{intl.formatMessage({ id: 'learn_more', defaultMessage: 'learn_more' })}</div>
                {drawerInfo && drawerInfo.map((drawerInfoItem, index) => (
                    <div data-testid={`${testId}-drawer-info-${index}`} key={drawerInfoItem?.id} className="flex flex-col mb-2 w-full min-h-max rounded-lg sm:w-full">
                        <div className="w-full flex flex-wrap font-normal text-base">
                            {loading ?
                                <div className='m-auto'>
                                    <LoadingIcon />
                                </div>
                                :
                                handleInfoContent(drawerInfoItem)
                            }
                        </div>
                        <div className="my-5 border w-full"></div>
                    </div>
                ))}
            </div>
        </>
    );
}