import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: resetPasswordContainerTypes, Creators: resetPasswordContainerCreators } = createActions({

  // reset-password
  requestPostResetPassword: ['data', 'token'],
  successPostResetPassword: ['data'],
  failurePostResetPassword: ['error'],
});



export type InitialStateType = {
  data: string | null;
  error: string | null;
  loading: boolean | null;
}

export const initialState: InitialStateType = { data: null, error: null, loading: null };

export const resetPasswordReducer = (state = initialState, action: any) =>
  produce(state, (draft) => {
    switch (action.type) {

      // forgot-password

      case resetPasswordContainerTypes.REQUEST_POST_RESET_PASSWORD:
        draft.data = null;
        draft.loading = true;
        draft.error = null;
        break;
      case resetPasswordContainerTypes.SUCCESS_POST_RESET_PASSWORD:
        draft.data = 'success';
        draft.loading = false;
        draft.error = null;
        break;
      case resetPasswordContainerTypes.FAILURE_POST_RESET_PASSWORD:
        draft.data = null;
        draft.loading = false;
        draft.error = action.error;
        break;


      default:
        return state;
    }
  })
export default resetPasswordReducer;
