import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectDecodedUserInfo, selectUserInfo } from "../../AppSelector";
import LoadingIcon from "../../assets/icons/loading-icon";
import { SubscriptionData, InvoicesData, RoleLimitsData } from "../../containers/SubscriptionContainer";
import { subscriptionContainerCreators } from "../../containers/SubscriptionContainer/subscriptionReducer";
import { getBaseURL } from "../../utils";
import Modal from "../Modal/Modal";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import StyledButton from "../StyledButton";
import NotificationToast from "../ToastComponent";
import { saveAs } from 'file-saver';

type Props = {
    subscriptionData: SubscriptionData | undefined;
    invoicesData: InvoicesData[] | undefined;
    roleLimits: RoleLimitsData[] | undefined;
    testId?: string
}

export default function SubscriptionLayout(props: Props) {

    const { subscriptionData, invoicesData, roleLimits, testId = `subscription-layout` } = props;
    const decodedInfo = useSelector(selectDecodedUserInfo);
    const role = decodedInfo?.payload?.role;
    const navigate = useNavigate();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [isCancelMyRenewalModal, setIsCancelMyRenewalModal] = useState<boolean>(false)
    const [showDeleteBtnToast, setShowDeleteBtnToast] = useState<boolean>(false)
    const subscriptionSelector = useSelector((state: RootState) => state.subscriptionContainer);
    const [isCancelMySubscriptionModal, setIsCancelMySubscriptionModal] = useState<boolean>(false)
    const [showDeleteSubscriptionToast, setShowDeleteSubscriptionToast] = useState<boolean>(false)


    const date1 = dayjs(subscriptionData?.startdate)
    const date2 = dayjs(new Date())
    const dateDiff = date1.diff(date2, "d")
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);

    useEffect(() => {
        if (subscriptionSelector?.status !== 500 && subscriptionSelector?.status !== null && subscriptionSelector?.status !== undefined && subscriptionSelector?.method !== "delete" && subscriptionSelector?.method !== null) {
            setIsSaved(true);
            setTimeCounter(1);
        } else if (subscriptionSelector?.method !== null && subscriptionSelector?.method !== undefined && subscriptionSelector?.method === "delete" && (subscriptionSelector?.method !== "post" || subscriptionSelector?.method !== "put")) {
            setIsRemoved(true);
            setTimeCounter(1);
        }
    }, [subscriptionSelector])
/* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);
/* istanbul ignore next */
    function gotoActiveSubscription() {
        navigate("/active-subscription", { state: { from: "subscription" } });
    }

    const handleDeleteCancelMyRenewalModalOpen = () => {
        setIsCancelMyRenewalModal(true);
    }

    function handleDeleteCancelMyRenewal() {
        dispatch(subscriptionContainerCreators.requestDeleteMyRenewal(subscriptionData?.subscriptionId, setShowDeleteBtnToast))
        setIsCancelMyRenewalModal(false)
    }

    function handleDeleteCancelMyRenewalModalClose() {
        setIsCancelMyRenewalModal(false)
    }

    function gotoPlans() {
        navigate("/plans");
    }

    function gotoUpgradeSubscription() {
        navigate("/plans", { state: { upgradeSubscription: true } });
    }

    function gotoManualRenewal() {
        navigate("/plans", { state: { manualRenewal: true, subscriptionData } });
    }

    const handleCancelMySubscriptionModalOpen = () => {
        setIsCancelMySubscriptionModal(true);
    }

    const handleCancelMySubscriptionModalClose = () => {
        setIsCancelMySubscriptionModal(false);
    }

    const handleCancelMySubscription = () => {
        dispatch(subscriptionContainerCreators.requestDeleteMySubscription(subscriptionData?.subscriptionId, setShowDeleteSubscriptionToast))
        setIsCancelMySubscriptionModal(false);
    }

    const userInfo = useSelector(selectUserInfo);
    const accessToken: string = userInfo?.accessToken || '';
    /* istanbul ignore next */
    async function downloadFile(invoiceId: any) {
        try {
            const response: any = await fetch(`${getBaseURL()}/subscriptionservice/v1/subscriptions/invoices/${invoiceId}`, {
                headers: {
                    authorization: accessToken
                }
            });

            const filename = response.headers.get('content-disposition')
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .replaceAll('"', '')
                .trim()
                ;
            const blob = await response.blob();

            const temp = filename.substring(0, filename.lastIndexOf('.'))

            // Download the file
            saveAs(blob, temp);

        } catch (error) {
            console.error('Failed to download file', error);
            throw new Error('Failed to download file');
        }
    }
    /* istanbul ignore next */
    async function downloadCreditInvoiceFile(invoiceId: any) {
        try {
            const response: any = await fetch(`${getBaseURL()}/subscriptionservice/v1/subscriptions/invoices/${invoiceId}/credit`, {
                headers: {
                    authorization: accessToken
                }
            });

            const filename = response.headers.get('content-disposition')
                .split(';')
                .find((n: any) => n.includes('filename='))
                .replace('filename=', '')
                .replaceAll('"', '')
                .trim()
                ;
            const blob = await response.blob();

            const temp = filename.substring(0, filename.lastIndexOf('.'))

            // Download the file
            saveAs(blob, temp);

        } catch (error) {
            console.error('Failed to download file', error);
            throw new Error('Failed to download file');
        }
    }

    const reFormatedDate = (date: any) => {
        return dayjs(date).format("DD-MM-YYYY")
    }

    const getRoles = (role: string) => {
        if (role === 'administrator') {
            return intl.formatMessage({ id: 'administrator', defaultMessage: 'administrator' })
        } else if (role === 'editor') {
            return intl.formatMessage({ id: 'editor', defaultMessage: "editor" })
        } else if (role === 'reader') {
            return intl.formatMessage({ id: 'reader', defaultMessage: 'reader' })
        } else if (role === 'accountant') {
            return intl.formatMessage({ id: 'accountant', defaultMessage: 'accountant' })
        } else if (role === 'insureanceadvisor') {
            return intl.formatMessage({ id: 'insureanceadvisor', defaultMessage: 'insureanceadvisor' })
        } else if (role === 'partneraccountant') {
            return intl.formatMessage({ id: 'partneraccountant', defaultMessage: 'partneraccountant' })
        } else if (role === 'partnerinsureanceadvisor') {
            return intl.formatMessage({ id: 'partnerinsureanceadvisor', defaultMessage: 'partnerinsureanceadvisor' })
        } else {
            return ''
        }
    }
    // 1 month from now
    /* istanbul ignore next */
    const now = dayjs()
    const addMonth = now.add(1, "month").format("YYYY-MM-DD")
    const manuallyRenewBtn = (subscriptionData?.enddate && subscriptionData?.enddate < addMonth)

    return (
        <>
            {subscriptionSelector.loading ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :
                <div data-testid={testId} className='m-auto sm:w-full'>
                    {isSaved ?
                        <div className="font-normal text-xs text-[#959595] text-end">
                            <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                        </div>
                        :
                        <div className="font-normal text-xs text-[#959595] text-end">
                            <div className="h-4">{"  "}</div>
                        </div>
                    }
                    {isRemoved ?
                        <div className="font-normal text-xs text-[#959595] text-end">
                            <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                        </div>
                        :
                        <div className="font-normal text-xs text-[#959595] text-end">
                            <div className="h-4">{"  "}</div>
                        </div>
                    }
                    <NavigationMenu />
                    <div className='my-10 mt-10 font-semibold text-4xl flex items-start justify-start sm:text-3xl'>
                        {intl.formatMessage({ id: 'subscription', defaultMessage: 'subscription' })}
                    </div>
                    <div className="grid sm:grid-cols-1 grid-cols-2 gap-x-14 gap-y-3">
                        <div className="sm:min-w-full min-w-[422px] min-h-[400px] p-10 border rounded-lg bg-white mb-6 sm:w-full">
                            <div className="font-semibold text-xl text-black text-left w-full mb-6">{intl.formatMessage({ id: 'my_subscription', defaultMessage: 'my_subscription' })}</div>
                            <table className="table-auto w-full">
                                <tbody className="bg-[#FAFAFA]">
                                    <tr className={`p-4 border h-12 flex items-center justify-between rounded-t-lg ${decodedInfo?.payload.plan === 0 ? 'rounded-b-lg' : ''}`}>
                                        <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'plans', defaultMessage: 'plans' })}</td>
                                        <td className="font-normal text-sm text-[#535353] font-family-arial">
                                            {!subscriptionData ?
                                                intl.formatMessage({ id: 'none', defaultMessage: 'none' })
                                                :
                                                subscriptionData?.plan?.name === 0 ?
                                                    intl.formatMessage({ id: 'none', defaultMessage: 'none' })
                                                    :
                                                    (
                                                        subscriptionData?.plan?.name === 1 ?
                                                            intl.formatMessage({ id: 'plan_1', defaultMessage: 'plan_1' })
                                                            :
                                                            (
                                                                subscriptionData?.plan?.name === 2 ?
                                                                    intl.formatMessage({ id: 'plan_2', defaultMessage: 'plan_2' })
                                                                    :
                                                                    intl.formatMessage({ id: 'plan_3', defaultMessage: 'plan_3' })
                                                            )
                                                    )
                                            }
                                        </td>
                                    </tr>
                                    {decodedInfo?.payload?.plan !== 0 &&
                                        <>
                                            {((decodedInfo?.payload.role === 'partneraccountant') || (decodedInfo?.payload.role === 'partnerinsureanceadvisor') || (subscriptionData && subscriptionData.partnerId)) ?
                                                <tr className={"p-4 border h-12 flex items-center justify-between rounded-b-lg"}>
                                                    <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'start_date', defaultMessage: 'start_date' })}</td>
                                                    <td className="font-normal text-sm text-[#535353]">{!subscriptionData ? '' : reFormatedDate(subscriptionData?.startdate)}</td>
                                                </tr>
                                                :
                                                subscriptionData?.autorenewal === 1 ?
                                                    <>
                                                        <tr className="p-4 border h-12 flex items-center justify-between">
                                                            <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'start_date', defaultMessage: 'start_date' })}</td>
                                                            <td className="font-normal text-sm text-[#535353] font-family-arial">{!subscriptionData ? '' : reFormatedDate(subscriptionData?.startdate)}</td>
                                                        </tr>
                                                        {subscriptionData?.discount &&
                                                            <tr className="p-4 border h-12 flex items-center justify-between">
                                                                <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'discount', defaultMessage: 'discount' })}</td>
                                                                <td className="font-normal text-sm text-[#535353]">{subscriptionData?.discount?.percentage + "%"}</td>
                                                            </tr>
                                                        }
                                                        <tr className="p-4 border h-12 flex items-center justify-between">
                                                            <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'annual_fee', defaultMessage: 'annual_fee' })}</td>
                                                            <td className="font-normal text-sm text-[#535353] font-family-arial">
                                                                {!subscriptionData ? `€ 0` :
                                                                    <>
                                                                        {`€ ${subscriptionData?.plan?.discountPercentage ? subscriptionData?.plan?.yearlyFee : subscriptionData?.plan?.price}`}
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr className="p-4 border h-12 flex items-center justify-between">
                                                            <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'auto_renewal', defaultMessage: 'auto_renewal' })}</td>
                                                            <td className="font-normal text-sm text-[#535353] font-family-arial">
                                                                {subscriptionData && (subscriptionData?.autorenewal === 1 ? intl.formatMessage({ id: 'active', defaultMessage: 'active' }) : intl.formatMessage({ id: 'in_active', defaultMessage: 'in_active' }))}
                                                            </td>
                                                        </tr>
                                                        <tr className="p-4 border h-12 flex items-center justify-between rounded-b-lg">
                                                            <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'renewal_date', defaultMessage: 'renewal_date' })}</td>
                                                            <td className="font-normal text-sm text-[#535353] font-family-arial">{!subscriptionData ? '' : reFormatedDate(subscriptionData?.enddate)}</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    subscriptionData?.autorenewal === 0 ?
                                                        <>
                                                            <tr className="p-4 border h-12 flex items-center justify-between">
                                                                <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'start_date', defaultMessage: 'start_date' })}</td>
                                                                <td className="font-normal text-sm text-[#535353]">{!subscriptionData ? '' : reFormatedDate(subscriptionData?.startdate)}</td>
                                                            </tr>
                                                            <tr className="p-4 border h-12 flex items-center justify-between">
                                                                <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'annual_fee', defaultMessage: 'annual_fee' })}</td>
                                                                <td className="font-normal text-sm text-[#535353]">
                                                                    {!subscriptionData ? `€ 0` :
                                                                        <>
                                                                            {`€ ${subscriptionData?.plan?.discountPercentage ? subscriptionData?.plan?.yearlyFee : subscriptionData?.plan?.price}`}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr className="p-4 border h-12 flex items-center justify-between">
                                                                <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'auto_renewal', defaultMessage: 'auto_renewal' })}</td>
                                                                <td className="font-normal text-sm text-[#535353]">
                                                                    {subscriptionData && (subscriptionData?.autorenewal === 0 ? intl.formatMessage({ id: 'in_active', defaultMessage: 'in_active' }) : intl.formatMessage({ id: 'active', defaultMessage: 'active' }))}
                                                                </td>
                                                            </tr>
                                                            <tr className="p-4 border h-12 flex items-center justify-between rounded-b-lg">
                                                                <td className="font-semibold text-sm text-black">{intl.formatMessage({ id: 'end_date', defaultMessage: 'end_date' })}</td>
                                                                <td className="font-normal text-sm text-[#535353]">{!subscriptionData ? '' : reFormatedDate(subscriptionData?.enddate)}</td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <></>
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>

                        {decodedInfo?.payload?.plan !== 0 ?

                            <div className="sm:min-w-full min-w-[422px] min-h-[400px] p-10 border rounded-lg bg-white mb-6 sm:w-full">
                                <div className="font-semibold text-xl text-black text-left w-full mb-6">{intl.formatMessage({ id: 'users_available_in_subscription', defaultMessage: 'users_available_in_subscription' })}</div>
                                <table className="table-auto w-full">
                                    <thead className="bg-white">
                                        <tr className="p-4 h-10 flex items-center justify-between">
                                            <th className="min-w-[33.33%]"></th>
                                            <th className="text-right ">{intl.formatMessage({ id: 'in_use', defaultMessage: 'in_use' })}</th>
                                            <th className="text-right">{intl.formatMessage({ id: 'still_available', defaultMessage: 'still_available' })}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-[#FAFAFA] w-full">
                                        {roleLimits?.map((roleLimitsItem, idx) => (
                                            <tr data-testid={`${testId}-role-limits-${idx}`} key={roleLimitsItem?.available} className={`${idx === 0 ? 'rounded-t-lg' : ''} ${idx + 1 === roleLimits.length ? 'rounded-b-lg' : ''} p-4 border h-12 flex items-center justify-between`}>
                                                <td className="font-semibold text-sm text-black role-limit-elip">{roleLimitsItem.role && getRoles(roleLimitsItem.role)}</td>
                                                <td className="text-right font-normal text-sm text-[#535353] font-family-arial">{roleLimitsItem.used}</td>
                                                <td className="font-normal text-sm text-[#535353] font-family-arial">{roleLimitsItem.available}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <></>
                        }


                        {(role !== 'partneraccountant' && role !== 'partnerinsureanceadvisor') && (decodedInfo?.payload.partnerId && decodedInfo?.payload?.plan === 0 && role === 'administrator') || (!decodedInfo?.payload.partnerId && decodedInfo?.payload.plan === 0) || (decodedInfo?.payload.plan === 1 || decodedInfo?.payload?.plan === 2) || (decodedInfo?.payload.plan !== 0 && dateDiff >= -14 && dateDiff <= 0) || (decodedInfo?.payload.plan !== 0 && subscriptionData?.autorenewal === 0 && manuallyRenewBtn) || (decodedInfo?.payload.plan !== 0 && subscriptionData?.autorenewal === 1) ?
                        // {/* {(role !== 'partneraccountant' && role !== 'partnerinsureanceadvisor') && !decodedInfo?.payload?.partnerId &&  */}
                            <div className="sm:min-w-full min-w-[422px] min-h-[400px] p-10 border rounded-lg bg-white mb-6 sm:w-full">
                                <div className="font-semibold text-xl text-black text-left w-full mb-6">{intl.formatMessage({ id: 'i_want_to...', defaultMessage: 'i_want_to...' })}</div>
                                <div className="rounded-lg border overflow-hidden">
                                    <table className="table-auto w-full">
                                        <tbody className="bg-[#FAFAFA]">
                                            {(decodedInfo?.payload.partnerId && decodedInfo?.payload?.plan === 0 && role === 'administrator') &&
                                                <tr className="p-4 border border-t-0 border-r-0 border-l-0  h-12 flex items-center justify-between rounded-lg" onClick={() => gotoActiveSubscription()}>
                                                    <td className="font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial">{intl.formatMessage({ id: 'activate_my_subscription', defaultMessage: 'activate_my_subscription' })}</td>
                                                </tr>      
                                            }

                                            {(!decodedInfo?.payload.partnerId && decodedInfo?.payload.plan === 0) &&
                                                <tr data-testid={`${testId}-goto-plans`} className="p-4 border border-r-0 border-l-0 h-12 flex items-center justify-between rounded-lg">
                                                    <td className="font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial" onClick={() => gotoPlans()}>{intl.formatMessage({ id: 'get_subscription', defaultMessage: 'get_subscription' })}</td>
                                                </tr>
                                            }

                                            {(decodedInfo?.payload.plan === 1 || decodedInfo?.payload?.plan === 2) &&
                                                <tr data-testid={`${testId}-goto-upgrade-subscription`} className="p-4 border border-r-0 border-l-0 h-12 flex items-center justify-between">
                                                    <td className="font-bold text-sm text-[#3BA8FC]  cursor-pointer font-family-arial" onClick={() => gotoUpgradeSubscription()}>{intl.formatMessage({ id: 'upgrade_my_subscription', defaultMessage: 'upgrade_my_subscription' })}</td>
                                                </tr>
                                            }

                                            {(decodedInfo && decodedInfo?.payload?.plan > 0) && dateDiff >= -14 && dateDiff <= 0 &&
                                                <tr data-testid={`${testId}-cancel-my-subscription`} className="p-4 border border-r-0 border-l-0 h-12 flex items-center justify-between" onClick={() => handleCancelMySubscriptionModalOpen()}>
                                                    <td className="font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial">{intl.formatMessage({ id: 'cancel_my_subscription', defaultMessage: 'cancel_my_subscription' })}</td>
                                                </tr>
                                            }

                                            {((decodedInfo && decodedInfo?.payload?.plan > 0) && subscriptionData?.autorenewal === 0 && manuallyRenewBtn) &&
                                                <tr className="p-4 border border-r-0 border-l-0 h-12 flex items-center justify-between">
                                                    <td className="font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial" onClick={() => gotoManualRenewal()}>{intl.formatMessage({ id: 'manually_renew_my_subscription', defaultMessage: 'manually_renew_my_subscription' })}</td>
                                                </tr>
                                            }

                                            {((decodedInfo && decodedInfo?.payload?.plan > 0) && subscriptionData?.autorenewal === 1) &&
                                                <tr data-testid={`${testId}-cancel-my-renewal`} className="p-4 border border-r-0 border-l-0 h-12 flex items-center justify-between" onClick={() => handleDeleteCancelMyRenewalModalOpen()}>
                                                    <td className="font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial">{intl.formatMessage({ id: 'cancel_my_renewal', defaultMessage: 'cancel_my_renewal' })}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <></>
                        }


                        {invoicesData && invoicesData?.length > 0 &&
                            <div className="sm:min-w-full min-w-[422px] min-h-[400px] p-10 border rounded-lg bg-white mb-6 sm:w-full">
                                <div className="font-semibold text-xl text-black text-left w-full mb-6">{intl.formatMessage({ id: 'payments_and_invoices', defaultMessage: 'payments_and_invoices' })}</div>
                                <table className="table-auto w-full">
                                    <thead className="bg-white w-full">
                                        <tr className="p-4 h-10 flex items-center justify-between">
                                            <th>{intl.formatMessage({ id: 'invoice_date', defaultMessage: 'invoice_date' })}</th>
                                            <th>{intl.formatMessage({ id: 'status', defaultMessage: 'status' })}</th>
                                            <th className="min-w-[33.33%]"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-[#FAFAFA] w-full">
                                        {invoicesData?.map((invoice, idx) => (
                                            <tr data-testid={`${testId}-invoice-data-${idx}`} key={invoice.invoiceId} className={`${idx === 0 ? 'rounded-t-lg' : ''} ${idx + 1 === invoicesData.length ? 'rounded-b-lg' : ''} p-4 border h-12 flex items-center justify-between`}>
                                                <td className="font-normal text-sm text-[#535353] font-family-arial">{(invoice?.created || invoice?.startDate) && reFormatedDate(invoice?.created || invoice?.startDate)}</td>
                                                <td className="flex justify-center font-normal text-sm text-[#535353] font-family-arial">{invoice?.paymentStatus}</td>

                                                <td className="flex flex-col min-w-[90px]">
                                                    {(invoice?.invoiceNr && invoice?.invoiceNr > 0) ?
                                                        <span data-testid={`${testId}-download-file`} className="underline font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial" onClick={() => downloadFile(invoice && invoice?.invoiceId)}>{intl.formatMessage({ id: 'invoice', defaultMessage: 'invoice' })}</span>
                                                        :
                                                        <span className="min-w-[80px] underline font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial"></span>
                                                    }
                                                    {(invoice?.invoiceCreditNr && invoice?.invoiceCreditNr > 0) ?
                                                        <span data-testid={`${testId}-invoice-credit`} className="underline font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial" onClick={() => downloadCreditInvoiceFile(invoice && invoice?.invoiceId)}>{intl.formatMessage({ id: 'credit_invoice', defaultMessage: 'credit_invoice' })}</span>
                                                        :
                                                        <span className="min-w-[80px] underline font-bold text-sm text-[#3BA8FC] cursor-pointer font-family-arial"></span>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            }
            <div>
                <Modal show={isCancelMyRenewalModal} onClose={() => handleDeleteCancelMyRenewalModalClose()}>
                    <div className="lg:max-w-[400px]">{`${intl.formatMessage({ id: 'are_you_sure_cancel_my_renewal', defaultMessage: 'are_you_sure_cancel_my_renewal' })}`}</div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-delete-modal-cancel-button`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "no", defaultMessage: 'no' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => handleDeleteCancelMyRenewalModalClose()}
                        />
                        <StyledButton
                            testId={`${testId}-delete-modal-delete-button`}
                            buttonClass={'PrimoryBtn'}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            text={intl.formatMessage({ id: "yes", defaultMessage: 'yes' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => handleDeleteCancelMyRenewal()}
                        />
                    </div>
                </Modal>
            </div>
            <div>
                <Modal show={isCancelMySubscriptionModal} onClose={() => handleCancelMySubscriptionModalClose()}>
                    <div className="lg:max-w-[400px]">{`${intl.formatMessage({ id: 'cancel_sub_modal_para_text', defaultMessage: 'cancel_sub_modal_para_text' })}`}</div>
                    <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                        <StyledButton
                            testId={`${testId}-cancel-delete-user-modal-close`}
                            width={"w-48 sm:w-full"}
                            align={"justify-start"}
                            text={intl.formatMessage({ id: "no", defaultMessage: 'no' })}
                            color={"text-[#535353]"}
                            flexReverse={"flex-row-reverse"}
                            backgroundColor={"bg-[#EDEDED]"}
                            onClick={() => handleCancelMySubscriptionModalClose()}
                        />
                        <StyledButton
                            testId={`${testId}-delete-btn`}
                            width={"w-48 sm:w-full"}
                            align={"justify-end"}
                            buttonClass={'PrimoryBtn'}
                            text={intl.formatMessage({ id: "yes", defaultMessage: 'yes' })}
                            backgroundColor={'bg-black'}
                            color={'text-white'}
                            onClick={() => handleCancelMySubscription()}
                        />
                    </div>
                </Modal>
            </div>
            {showDeleteBtnToast && <NotificationToast testId={`${testId}-delete-btn-toast`} text={subscriptionSelector.error ? subscriptionSelector.error : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} iconType={subscriptionSelector.error ? intl.formatMessage({ id: 'failure', defaultMessage: 'failure' }) : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} color={subscriptionSelector.error ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showDeleteBtnToast} onClick={() => setShowDeleteBtnToast(false)} />}
            {showDeleteSubscriptionToast && <NotificationToast text={subscriptionSelector.error ? subscriptionSelector.error : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} iconType={subscriptionSelector.error ? intl.formatMessage({ id: 'failure', defaultMessage: 'failure' }) : intl.formatMessage({ id: 'success', defaultMessage: 'success' })} color={subscriptionSelector.error ? 'bg-red-300 dark:bg-red-800' : 'bg-green-300 dark:bg-green-800'} showToast={showDeleteSubscriptionToast} onClick={() => setShowDeleteSubscriptionToast(false)} />}
        </>
    );
}
