import { InfoDetails } from "../../containers/BIAContainer/BIAEdit";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkNormalizeHeadings from "remark-normalize-headings";
import LoadingIcon from "../../assets/icons/loading-icon";

type Props = {
    infoCard: InfoDetails;
    loading?: boolean;
    testId?: string
}

export default function InfoCard(props: Props) {

    const { infoCard, loading, testId = "infocard" } = props;
    // const infoText = DOMPurify.sanitize((infoCard?.attributes?.InfoText));
    // const richText = infoText.replace(/\n/gi, '\n <br>');
    const richText = infoCard?.attributes?.InfoText;
    const isHttpsLink = infoCard?.attributes?.Video?.data?.attributes?.url.startsWith('https://');
    const videoSource = isHttpsLink ? infoCard?.attributes?.Video?.data?.attributes?.url : "https://" + infoCard?.attributes?.Video?.data?.attributes?.url
    /* istanbul ignore next */
    const handleInfoContent = () => {
        if (infoCard?.attributes?.InfoText && !infoCard?.attributes?.Video.data && !infoCard?.attributes?.Image.data) {
            return (
                <div>
                    <div className="w-full flex flex-wrap font-normal text-base mb-2">
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{richText}</ReactMarkdown>
                    </div>
                    <div data-testid={`${testId}-infocard-subtext`} className="w-full flex flex-wrap font-normal text-sm tracking-[0.25px] text-[#959595]">
                        <ReactMarkdown remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{infoCard?.attributes?.SubText}</ReactMarkdown>
                    </div>
                </div>
            )
        } else if (!infoCard?.attributes?.InfoText && infoCard?.attributes?.Video && infoCard?.attributes?.Video?.data) {
            return (
                <video data-testid={`${testId}-video`} controls src={videoSource} />
            )
        } else if (!infoCard?.attributes?.InfoText && infoCard?.attributes?.Image && infoCard?.attributes?.Image?.data) {
            return (
                <img data-testid={`${testId}-image`} src={infoCard?.attributes?.Image?.data?.attributes?.url} alt='' />
            )
        } else if (infoCard?.attributes?.InfoText && infoCard?.attributes?.Video && infoCard?.attributes?.Video?.data) {
            return (
                <div>
                    <video controls src={videoSource} />
                    <div className="w-full flex flex-wrap font-normal text-sm tracking-[0.25px] text-[#959595] mt-2">
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{richText}</ReactMarkdown>
                    </div>
                </div>
            )
        } else if (infoCard?.attributes?.InfoText && infoCard?.attributes?.Image && infoCard?.attributes?.Image?.data) {
            return (
                <div>
                    <img src={infoCard?.attributes?.Image?.data?.attributes?.url} alt='' />
                    <div className="w-full flex flex-wrap font-normal text-sm tracking-[0.25px] text-[#959595] mt-2">
                        <ReactMarkdown className="prose welcome-text" remarkPlugins={[remarkGfm, remarkNormalizeHeadings]} rehypePlugins={[rehypeRaw]}>{richText}</ReactMarkdown>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <div data-testid={`${testId}`} className="flex flex-col p-6 mb-2 w-[342px] min-h-max bg-white shadow-[0_16px_48px_-15px_rgba(0,0,0,0.3)] rounded-lg sm:w-[280px]">
                <div className="w-full flex flex-wrap font-normal text-base mb-2">
                    {loading ?
                        <div className='m-auto'>
                            <LoadingIcon />
                        </div>
                        :
                        handleInfoContent()
                    }
                </div>
            </div>
        </>
    );
}