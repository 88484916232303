import dayjs from 'dayjs';
import { produce } from 'immer';
import { createActions } from 'reduxsauce';
import { setUserCookies } from './utils/coockies';

export const { Types: appContainerTypes, Creators: appContainerCreators } = createActions({
  updateUserInfo: ['userInfo'],
  clearUserInfo: [],
  getUserInfo: ['refreshToken'],
  failureUserInfo: ['error'],
});

export type AppReducerInitialType = {
  userInfo: {
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
  } | null;
  refreshToken: string | null;
  error: string | null;
}

export const initialState: AppReducerInitialType = { userInfo: null, refreshToken: null, error: null };

export const appContainerReducer = (state = initialState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case appContainerTypes.UPDATE_USER_INFO:
        const expiryIn = dayjs().add(action.userInfo.expiresIn, 'second');
        setUserCookies(action.userInfo.refreshToken, expiryIn.toDate());
        draft.userInfo = action.userInfo;
        break;
      case appContainerTypes.CLEAR_USER_INFO:
        setUserCookies(null, new Date('1970-01-01'));
        draft.userInfo = null;
        break;
      case appContainerTypes.GET_USER_INFO:
        draft.refreshToken = action.data;
        draft.error = null;
        break;
      case appContainerTypes.FAILURE_USER_INFO:
        setUserCookies(null, new Date('1970-01-01'));
        draft.refreshToken = null;
        draft.userInfo = null;
        draft.error = action.error;
        break;
      default:
        return state;
    }
  })
