import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../components/StyledButton';
import { useDispatch, useSelector } from 'react-redux';
import { listOptionsContainerCreators } from './reducer';
import EditIcon from '../../assets/icons/edit-icon.svg';
import { selectDecodedUserInfo } from '../../AppSelector';
import Container from '../../components/Container/Container';

const testId = `step-3`;

export type Product = {
    [key: string]: any;
    productId?: string;
    name: string;
}

export default function OrganisationStep3Container() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const { products, shouldRealoadProducts } = useSelector((state: RootState) => state.listOptions);
    const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
    const intl = useIntl();
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1);

    useEffect(() => {
        if (listOptionsSelector?.status !== null && listOptionsSelector?.status !== undefined && listOptionsSelector?.method !== "delete" && listOptionsSelector?.method !== null) {
            setIsRemoved(false)
            setIsSaved(true);
            setTimeCounter(1);
        } else if (listOptionsSelector?.method !== null && listOptionsSelector?.method !== undefined && listOptionsSelector?.method === "delete" && (listOptionsSelector?.method !== "post" || listOptionsSelector?.method !== "put")) {
            setIsSaved(false)
            setIsRemoved(true);
            setTimeCounter(1);
        }
    }, [listOptionsSelector])
/* istanbul ignore next */
    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        } else if (isRemoved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsRemoved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved, isRemoved]);

    const goToNext = () => {
        navigate("/organisation/step4");
    }

    const removeProduct = (id: string) => {
        dispatch(listOptionsContainerCreators.requestDeleteProduct(id));
    }
    const editProduct = (productDetail: Product | null) => {
        navigate(`/organisation/step3/edit-Product/${productDetail?.productId}`);  
    }

    const addProduct = (productDetail: Product | null) => {
        navigate("/organisation/step3/add-Product");  
    }

    useEffect(() => {
        dispatch(listOptionsContainerCreators.requestGetProducts());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (shouldRealoadProducts) {
            dispatch(listOptionsContainerCreators.requestGetProducts());
        }
    }, [shouldRealoadProducts]);/* eslint-disable react-hooks/exhaustive-deps */

    function isDisableBtn() {
        if ((role === "administrator") || (role === "editor")) {
            if (!products.length) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <div>
            <Container>
                <div className="flex-grow-1 flex flex-col justify-between">
                    <div>
                        <div className='m-auto w-full pb-[28px] align-middle items-center flex justify-between border-b sm:m-0 sm:pb-6'>
                            <div className="font-semibold text-xl">
                                <div>{intl.formatMessage({ id: 'organisation', defaultMessage: 'organisation' })}</div>
                            </div>
                            {isSaved &&
                                <div className="font-normal text-xs text-[#959595]">
                                    <div>{intl.formatMessage({ id: 'saved', defaultMessage:'saved' })}</div>
                                </div>
                            }
                            {isRemoved &&
                                <div className="font-normal text-xs text-[#959595]">
                                    <div>{intl.formatMessage({ id: 'removed', defaultMessage: 'removed' })}</div>
                                </div>
                            }
                        </div>
                        <div className="my-[40px] sm:my-8 max-w-[952px]">
                            <div className='mt-8'>
                                <div className='mt-10'>
                                    <h3 className='text-3xl font-semibold'>{intl.formatMessage({ id: 'organization_step3_text_2', defaultMessage: 'organization_step3_text_2' })}</h3>
                                    <p className='max-w-xl text-xl text-[#535353] mt-4 font-family-arial' >
                                        {intl.formatMessage({ id: 'organization_step3_text_3', defaultMessage: 'organization_step3_text_3' })}
                                    </p>
                                </div>
                                <div className='mt-8 p-6 bg-white border rounded'>
                                    <p className='text-lg font-semibold text-[#121212] mb-6'>{intl.formatMessage({ id: 'products', defaultMessage: 'products' })}</p>
                                    {products.length > 0
                                        ? <div className='mb-4 border rounded-md bg-zinc-50'>
                                            {products.map((product: Product, index: number) => (
                                                <div data-testid={`${testId}-products-0`} className={'relative px-4 py-3 font-semibold' + (index !== 0 ? " border-t " : "")} key={index}>
                                                    {product.name}
                                                    {((role === "administrator") || (role === "editor")) &&
                                                        <>
                                                            <span data-testid={`${testId}-remove-products`} className='absolute top-4 right-4 cursor-pointer' onClick={() => removeProduct(product.productId ?? "")}>
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_3_2750)" fill="#959595">
                                                                        <path d="M13 5H3a.333.333 0 0 0-.333.333v9.334A1.333 1.333 0 0 0 4 16h8a1.333 1.333 0 0 0 1.334-1.333V5.333A.333.333 0 0 0 13 5Zm-6.166 8.667a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm3.333 0a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 1 0v6Zm4.5-11H11.5a.166.166 0 0 1-.166-.167v-.833A1.667 1.667 0 0 0 9.667 0H6.334a1.667 1.667 0 0 0-1.667 1.667V2.5a.167.167 0 0 1-.167.167H1.334a.667.667 0 1 0 0 1.333h13.333a.667.667 0 1 0 0-1.333ZM6 2.5v-.833a.333.333 0 0 1 .334-.334h3.333a.333.333 0 0 1 .333.334V2.5a.167.167 0 0 1-.166.167H6.167A.167.167 0 0 1 6 2.5Z" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span data-testid={`${testId}-edit-product`} onClick={() => editProduct(product)} className='absolute cursor-pointer top-5 right-11' >
                                                                <img data-testid={`${testId}-edit-product-img`} src={EditIcon} alt="" />
                                                            </span>
                                                        </>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                        : null
                                    }
                                    {((role === "administrator") || (role === "editor")) &&
                                        <StyledButton
                                            testId={`${testId}-org-step3-text-btn`}
                                            width={'w-60'}
                                            align={'justify-start'}
                                            text={`${intl.formatMessage({ id: 'organization_step3_text_button', defaultMessage: 'organization_step3_text_button' })}`}
                                            color={'text-gray-700'}
                                            flexReverse={'flex-row-reverse'}
                                            iconType={'add'}
                                            backgroundColor={'transparent border-1'}
                                            onClick={() => addProduct(null)}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                        <StyledButton
                            testId={`${testId}-previous-btn-step3`}
                            align={'justify-start'}
                            width={'w-48 sm:w-full'}
                            color={'text-gray-700'}
                            text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                            flexReverse={'flex-row-reverse'}
                            iconType={'arrowleft'}
                            onClick={() => navigate("/organisation/step2")}
                            backgroundColor={'bg-gray-300'}
                        />
                        <StyledButton
                            testId={`${testId}-next-btn-step3`}
                            disabled={isDisableBtn()}
                            buttonClass={'PrimoryBtn'}
                            align={'justify-end'}
                            width={'w-48 sm:w-full'}
                            color={'text-white'}
                            text={intl.formatMessage({ id: 'next', defaultMessage: 'next' })}
                            iconType={'arrow'}
                            onClick={goToNext}
                            backgroundColor={isDisableBtn() ? 'bg-zinc-400' : 'bg-black'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    )
}
