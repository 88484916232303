import { useState, useEffect } from 'react'
import Container from "../../components/Container/Container";
import { useIntl } from "react-intl";
import StyledButton from "../../components/StyledButton";
import schema from "../../validations/schema";
import { useDispatch } from "react-redux";
import { listOptionsContainerCreators } from '../../containers/OrganisationContainer/reducer';

export type Organisation = {
    organizationId?: string;
    companyName?: string;
    chamberofcommercenr?: string;
    street?: string;
    houseNumber?: string;
    houseNumberSuffix?: string;
    postalcode?: string;
    city?: string;
    country?: string;
    type?: string;
    created?: Date;
    modified?: Date;
}

type Props = {
    editOrganisation: Organisation;
    onCancel: () => void
    testId?: string
};

type ErrorMessage = {
    message: string
}

type ErrorObject = {
    companyName?: ErrorMessage,
    chamberofcommercenr?: ErrorMessage,
    street?: ErrorMessage,
    houseNumber?: ErrorMessage,
    houseNumberSuffix?: ErrorMessage,
    postalcode?: ErrorMessage,
    city?: ErrorMessage
};

export default function EditCurrentOrganisationPage(props: Props) {

    const dispatch = useDispatch();
    const { editOrganisation, onCancel ,testId =`edit-current-organization` } = props;
    const intl = useIntl();

    const [orgData, setOrgData] = useState<Organisation>({
        organizationId: "",
        companyName: "",
        chamberofcommercenr: "",
        street: "",
        houseNumber: undefined,
        houseNumberSuffix: "",
        postalcode: "",
        city: "",
        country: "",
        type: "",
    });

    const [errObj, setErrObj] = useState<ErrorObject>({
        companyName: undefined,
        chamberofcommercenr: undefined,
        street: undefined,
        houseNumber: undefined,
        houseNumberSuffix: undefined,
        postalcode: undefined,
        city: undefined,
    });

    useEffect(() => {
        if (editOrganisation) {
            setOrgData(editOrganisation)
        }
    }, [editOrganisation])

    const handleUserInput = (key: string, value: string) => {
        setOrgData({ ...orgData, [key]: value });
    };

    function savedUser() {
        const companyNameErr = schema.companyName.validate(orgData.companyName).error;
        const chamberofcommercenrErr = schema.chamberofcommercenr.validate(orgData.chamberofcommercenr).error;
        const streetErr = schema.street.validate(orgData.street).error;
        const houseNumberErr = schema.houseNumber.validate(orgData.houseNumber).error;
        const postalcodeErr = schema.postalcode.validate(orgData.postalcode).error;
        const cityErr = schema.city.validate(orgData.city).error;

        if (companyNameErr || chamberofcommercenrErr || streetErr || houseNumberErr || postalcodeErr || cityErr) {
            setErrObj({
                companyName: companyNameErr?.message ? { message: intl.formatMessage({ id: companyNameErr?.message, defaultMessage:companyNameErr?.message }) } : undefined,
                chamberofcommercenr: chamberofcommercenrErr?.message ? { message: intl.formatMessage({ id: chamberofcommercenrErr?.message, defaultMessage:chamberofcommercenrErr?.message }) } : undefined,
                street: streetErr?.message ? { message: intl.formatMessage({ id: streetErr?.message, defaultMessage:streetErr?.message }) } : undefined,
                houseNumber: houseNumberErr?.message ? { message: intl.formatMessage({ id: houseNumberErr?.message, defaultMessage:houseNumberErr?.message }) } : undefined,
                houseNumberSuffix: undefined,
                postalcode: postalcodeErr?.message ? { message: intl.formatMessage({ id: postalcodeErr?.message, defaultMessage:  postalcodeErr?.message}) } : undefined,
                city: cityErr?.message ? { message: intl.formatMessage({ id: cityErr?.message, defaultMessage:cityErr?.message }) } : undefined
            });
        } else {
            const payload = {
                companyName: orgData.companyName,
                chamberofcommercenr: orgData.chamberofcommercenr,
                street: orgData.street,
                houseNumber: Number(orgData.houseNumber),
                houseNumberSuffix: orgData.houseNumberSuffix,
                postalcode: orgData.postalcode,
                city: orgData.city,
                country: orgData.country
            }
            dispatch(listOptionsContainerCreators.requestPutCurrentOrgInfo(orgData.organizationId, payload))
            setErrObj({
                companyName: undefined,
                chamberofcommercenr: undefined,
                street: streetErr,
                houseNumber: undefined,
                houseNumberSuffix: undefined,
                postalcode: undefined,
                city: undefined
            });
            onCancel();
        }
    };

    return (
        <div className="flex-grow-1 flex flex-col justify-between">
            <Container>
                <div data-testid={testId}>
                    <div className="flex flex-col sm:flex-col">
                        <p className="my-8 max-w-xl text-2xl text-black font-semibold">
                            {intl.formatMessage({ id: "edit_organisation", defaultMessage:'edit_organisation' })}
                        </p>
                        <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                            <div className="flex gap-8 sm:flex-col">
                                <div className='flex-1'>
                                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                        {intl.formatMessage({ id: 'name', defaultMessage:'name' })}
                                    </label>
                                    <input
                                        data-testid={`${testId}-name`}
                                        maxLength={100}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.companyName?.message ? 'border-[red]' : ''}`}
                                        value={orgData?.companyName ?? ""}
                                        onChange={(event) =>
                                            handleUserInput("companyName", event.target.value)
                                        }
                                    />
                                    {errObj.companyName?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.companyName?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>

                                <div className="flex-1">
                                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                        {intl.formatMessage({ id: 'kvK_number', defaultMessage:'kvK_number' })}
                                    </label>
                                    <input
                                        data-testid={`${testId}-kvknumber`}
                                        maxLength={45}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.chamberofcommercenr?.message ? 'border-[red]' : ''}`}
                                        value={orgData?.chamberofcommercenr ?? ""}
                                        onChange={(event) =>
                                            handleUserInput("chamberofcommercenr", event.target.value)
                                        }
                                    />
                                    {errObj.chamberofcommercenr?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.chamberofcommercenr?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>
                            </div>
                            <div className="flex gap-8 sm:flex-col">
                                <div className="flex-1">
                                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                        {intl.formatMessage({ id: 'street_address', defaultMessage:'street_address' })}
                                    </label>
                                    <input
                                        data-testid={`${testId}-street-address`}
                                        maxLength={100}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.street?.message ? 'border-[red]' : ''}`}
                                        value={orgData?.street ?? ""}
                                        onChange={(event) =>
                                            handleUserInput("street", event.target.value)
                                        }
                                    />
                                    {errObj.street?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.street?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>
                            </div>
                            <div className="flex gap-8 sm:flex-col">
                                <div className="flex-1">
                                    <label className="inline-block text-base font-bold mb-1 min-w-max font-family-arial">
                                        {intl.formatMessage({ id: 'number', defaultMessage:'number' })}
                                    </label>
                                    <input data-testid={`${testId}-housenumber`} maxLength={15} className={`bg-zinc-50 border rounded-lg px-3 mb-1 w-full h-11 ${errObj.houseNumber?.message ? 'border-[red]' : ''}`} type='text' value={orgData?.houseNumber ?? ""} onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        // if value is not blank, then test the regex
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            handleUserInput("houseNumber", e.target.value)
                                        }
                                    }} />
                                    {errObj.houseNumber?.message ?
                                        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                            <span>{errObj.houseNumber?.message}</span>
                                        </div>
                                        :
                                        <div className='mb-8'></div>
                                    }
                                </div>
                                <div className="flex-1">
                                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                        {intl.formatMessage({ id: 'affix_(optional)', defaultMessage:'affix_(optional)' })}
                                    </label>
                                    <input
                                        data-testid={`${testId}-house-number-suffix`}
                                        maxLength={10}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.houseNumberSuffix?.message ? 'border-[red]' : ''}`}
                                        value={orgData?.houseNumberSuffix ?? ""}
                                        onChange={(event) =>
                                            handleUserInput("houseNumberSuffix", event.target.value)
                                        }
                                    />
                                    {errObj.houseNumberSuffix?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.houseNumberSuffix?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>
                            </div>
                            <div className="flex sm:flex-wrap gap-8 sm:gap-0">
                                <div className="flex flex-col lg:max-w-[220px] w-full">
                                    <label className="inline-block text-base font-bold mb-1 min-w-max font-family-arial">
                                        {intl.formatMessage({ id: 'zipcode' , defaultMessage:'zipcode'})}
                                    </label>
                                    <input
                                        data-testid={`${testId}-zipcode`}
                                        maxLength={7}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.postalcode?.message ? 'border-[red]' : ''}`}
                                        value={orgData?.postalcode ?? ""}
                                        onChange={(event) =>
                                            handleUserInput(
                                                "postalcode",
                                                event.target.value
                                            )
                                        }
                                    />
                                    {errObj.postalcode?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.postalcode?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>
                                <div className="flex flex-col w-full">
                                    <label className="inline-block text-base font-bold mb-1 font-family-arial">
                                        {intl.formatMessage({ id: "city", defaultMessage:'city' })}
                                    </label>
                                    <input
                                        data-testid={`${testId}-city`}
                                        maxLength={100}
                                        type="text"
                                        className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.city?.message ? 'border-[red]' : ''}`}
                                        value={orgData?.city ?? ""}
                                        onChange={(event) =>
                                            handleUserInput("city", event.target.value)
                                        }
                                    />
                                    {errObj.city?.message ? (
                                        <div className="text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start">
                                            <span>{errObj.city?.message}</span>
                                        </div>
                                    ) : (
                                        <div className="mb-8"></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-cancel-button`}
                        width={"w-48 sm:w-full"}
                        align={"justify-start"}
                        text={intl.formatMessage({ id: "cancel", defaultMessage:'cancel' })}
                        color={"text-[#535353]"}
                        flexReverse={"flex-row-reverse"}
                        backgroundColor={"bg-[#EDEDED]"}
                        onClick={() => onCancel()}
                    />
                    <StyledButton
                        testId={`${testId}-save-button`}
                        buttonClass={'PrimoryBtn'}
                        width={"w-48 sm:w-full"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "save" , defaultMessage:'save'})}
                        color={"text-white"}
                        iconType={""}
                        backgroundColor={"bg-black"}
                        onClick={() => savedUser()}
                    />
                </div>
            </Container>
        </div>
    )
}
