import { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StyledButton from '../../components/StyledButton';
import { locationContainerCreators } from '../LocationsContainer/locationReducer';
import InfoQIcon from "../../assets/icons/info-q-icon.svg";
import CloseIcon from "../../assets/icons/close-icon.svg";
import schema from '../../validations/schema';
import { HardwaresList, Singlelocations } from './Hardware';
import Container from '../../components/Container/Container';
import GetIcons from '../../components/GetIcons/GetIcons';
import LoadingIcon from '../../assets/icons/loading-icon';
import { riskAreaContainerCreators } from '../ManageRiskAreasContainer/reducer';
import { selectDecodedUserInfo } from '../../AppSelector';
import InfoCard from '../../components/InfoCard';
import { InfoDetails } from '../BIAContainer/BIAEdit';
import { ContentType } from '@onesixeight-nl/classes';
import { answerTypeDontKnowBtn, answerTypeNotApplicableBtn, getReplacementValue, getShowMoreInfo, hardwareNameWhenOther, infoBtnStyle, nameErrObj, noBtn, notOnThisLocationBtn, onThisLocationBtn, queFiveAnsTypeDontKnowBtn, queFiveAnsTypeNoBtn, queFiveAnsTypeNotApplicableBtn, queFiveAnsTypeYesBtn, queFourAnsTypeDontKnowBtn, queFourAnsTypeNoBtn, queFourAnsTypeNotApplicableBtn, queFourAnsTypeYesBtn, queOneNoBtn, queOneYesBtn, queThreeAnsTypeDontKnowBtn, queThreeAnsTypeNoBtn, queThreeAnsTypeNotApplicableBtn, queThreeAnsTypeYesBtn, queTwoAnsTypeDontKnowBtn, queTwoAnsTypeNoBtn, queTwoAnsTypeNotApplicableBtn, queTwoAnsTypeYesBtn, yesBtn } from '../../utils/HardwareUtilsFolder/AddHardwareUtils';
import Modal from '../../components/Modal/Modal';

const testId = `add-hardware`;

export type Address = {
    [key: string]: any;
    type?: string;
    locationId?: string;
    street: string;
    houseNumber: number | null;
    houseNumberSuffix: string;
    postalcode: string;
    city: string;
    name?: string;
}

export type AllRiskCategoryListData = {
    assesedStatus: number | null;
    name: string | null;
    nameWhenOther: string | null;
    riskCategoryId: string;
    riskFactor: number | null;
    riskListId: string | null;
    selected: number | null;
    status: number;
}

export type ErrorMessage = {
    message: string;
}

export type ErrorObject = {
    name?: ErrorMessage;
}

function otherErr(errMsg: string | undefined) {
    return errMsg ?
        <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
            <span>{errMsg}</span>
        </div>
        :
        null
}

function styleBtn(isDisableBtn: boolean, value1: string, value2: string) {
    return isDisableBtn ? value1 : value2
}

function editHardwareBlock(editHardwareObj: any, intl: IntlShape) {
    return <div className="font-semibold text-[32px] sm:text-2xl">
        <div>{editHardwareObj?.hardwareListId === "00000000-0000-0000-0000-000000000000"
            ? intl.formatMessage({ id: "Edit_Hardware", defaultMessage: 'Edit_Hardware' })
            : editHardwareObj?.name}</div>
    </div>
}

function addHardwareBlock(lastQuestion: boolean, intl: IntlShape) {
    return lastQuestion ?
        <div className="font-semibold text-[32px] sm:text-2xl">
            <div>{intl.formatMessage({ id: "can_you_name_another_hardware?", defaultMessage: 'can_you_name_another_hardware?' })}</div>
        </div>
        :
        <div className="font-semibold text-[32px] sm:text-2xl">
            <div>{intl.formatMessage({ id: 'Add_Hardware', defaultMessage: 'Add_Hardware' })}</div>
        </div>
}

function showInfoIcon(showMoreInfo: boolean) {
    return showMoreInfo ?
        <img data-testid={`${testId}-close-icon-hardware`} className="w-[12px] h-[12px]" src={CloseIcon} alt='' />
        :
        <img data-testid={`${testId}-info-icon-hardware`} className="w-[12px] h-[12px]" src={InfoQIcon} alt='' />
}

function editHardwareObjHandler(lastQuestion: boolean, intl: IntlShape, editHardwareObj: any, hardwareQuestions: any, currentIndex: number) {
    return editHardwareObj ?
        <div className="font-semibold text-[32px] sm:text-2xl">
            <div> {editHardwareBlock(editHardwareObj, intl)}</div>
        </div>
        :
        <>
            {hardwareQuestions[currentIndex]?.hardwareListId ?
                <div className="font-semibold text-[32px] sm:text-2xl">
                    <div>{hardwareQuestions[currentIndex]?.name}</div>
                </div>
                :
                <>
                    {addHardwareBlock(lastQuestion, intl)}
                </>
            }
        </>
}

function editLastQueAnsType(handleType: (ans: string) => void, editHardwareObj: any, intl: IntlShape, nameWhenOther: string, setNameWhenOther: React.Dispatch<React.SetStateAction<string>>, errObj: ErrorObject, answerType3: string) {
    return editHardwareObj.hardwareListId === "00000000-0000-0000-0000-000000000000" ?
        <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mt-8 mb-10 mr-10 p-10 sm:p-5">
            <label className="inline-block text-base font-bold mb-1">
                {intl.formatMessage({ id: "enter_manual_hardware", defaultMessage: 'enter_manual_hardware' })}
            </label>
            <input
                data-testid={`${testId}-eneter-hardware-name`}
                type="text"
                maxLength={100}
                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2  ${nameErrObj(errObj)}`}
                value={nameWhenOther}
                onChange={(e) => setNameWhenOther(e.target.value)}
            />
            {otherErr(errObj.name?.message)}
        </div>
        :
        <div className='mt-8'>
            <div data-testid={`${testId}-on-this-location-btn`} onClick={() => handleType('OnThisLocation')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${onThisLocationBtn(answerType3)} flex items-center justify-center mb-6`}>
                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'on_this_location', defaultMessage: 'on_this_location' })}</div>
            </div>
            <div data-testid={`${testId}-not-on-this-location-btn`} onClick={() => handleType('NotOnThisLocation')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${notOnThisLocationBtn(answerType3)} flex items-center justify-center mb-6`}>
                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'not_on_this_location', defaultMessage: 'not_on_this_location' })}</div>
            </div>
        </div>
}

function lastQueAnsType(handleType: (ans: string) => void, lastQuestion: boolean, intl: IntlShape, nameWhenOther: string, setNameWhenOther: React.Dispatch<React.SetStateAction<string>>, answerType3: string, errObj: ErrorObject) {
    return lastQuestion ?
        <div className='mt-8'>
            <div data-testid={`${testId}-last-que-type-no`} onClick={() => handleType('No')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${noBtn(answerType3)} flex items-center justify-center mb-6`}>
                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
            </div>
            <div onClick={() => handleType('Yes')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${yesBtn(answerType3)} flex items-center justify-center mb-6`}>
                <div className="font-semibold text-2xl sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
            </div>
        </div>
        :
        <div className="w-full max-w-[414px] h-[160px] border rounded-lg bg-white mt-8 mb-10 mr-10 p-10 sm:p-5">
            <label className="inline-block text-base font-bold mb-1">
                {intl.formatMessage({ id: "enter_manual_hardware", defaultMessage: 'enter_manual_hardware' })}
            </label>
            <input
                type="text"
                maxLength={100}
                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${nameErrObj(errObj)}`}
                value={nameWhenOther}
                onChange={(e) => setNameWhenOther(e.target.value)}
            />
            {otherErr(errObj.name?.message)}
        </div>

}

function Add_Hardware() {
    // hooks
    const intl = useIntl();
    const params = useParams();
    const { state, search } = useLocation();
    const { id, locationId, hardwareId } = params;
    const isCancel = new URLSearchParams(search).get('isCancel');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [answerType, setAnswerType] = useState<string>('');
    const [answerType2, setAnswerType2] = useState<string>('');
    const [answerType3, setAnswerType3] = useState<string>('');
    const [answerType4, setAnswerType4] = useState<string>('');
    const [answerType5, setAnswerType5] = useState<string>('');
    const [answerType6, setAnswerType6] = useState<string>('');
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
    const riskAreaSelector = useSelector((state: RootState) => state.riskAreContainer);
    const locationSelector = useSelector((state: RootState) => state.locationContainer);

    // states
    const { shouldRealoadAddresses } = useSelector((state: RootState) => state.locationContainer);
    const [singlelocations, setSinglelocations] = useState<Singlelocations>();
    const [singleRiskCategory, setSingleRiskCategory] = useState<AllRiskCategoryListData | undefined>();
    const [nameWhenOther, setNameWhenOther] = useState<string>("")
    const [editHardwareObj, setEditHardwareObj] = useState<HardwaresList>()
    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;
    const [currentIndex, setCurrentIndex] = useState<number>(locationSelector?.hardwares && locationSelector?.hardwares?.length && locationSelector?.hardwares?.findIndex((ele: HardwaresList) => !ele.hardwareId));
    const userRoles = ((role === "administrator") || (role === "editor"))
    const [errObj, setErrObj] = useState<ErrorObject>({
        name: undefined
    });

    const [lastQuestion, setLastQuestion] = useState<boolean>(false);

    const [infoDetails, setInfoDetails] = useState<InfoDetails[]>([]);

    const [hardwareQuestions, setHardwareQuestions] = useState<HardwaresList[]>([]);
    const [loading, setLoading] = useState(false);
    const intialHardware: HardwaresList = {
        hardwareId: '',
        hardwareListId: '',
        name: '',
        nameWhenOther: '',
        selected: false,
        maintainHardware: 0,
        maintainOS: 0,
        inSupport: 0,
        virusScanner: 0,
        replacements: 0,
        created: '',
        modified: '',
        locationId: '',
        organizationId: ''
    };

    const [singleHardware, setSingleHardware] = useState<HardwaresList>();
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [timeCounter, setTimeCounter] = useState<number>(1)
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.error !== null) {
            setIsWromgOrgModal(true)
        }
    }, [riskAreaSelector.error])

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        const postHardwareData = locationSelector.postHardwareData
        const postHardwareIndex = hardwareQuestions.findIndex((ele: HardwaresList) => ele?.hardwareListId === locationSelector?.postHardwareData?.hardwareListId)
        if (postHardwareIndex > -1 && postHardwareData !== null) {
            hardwareQuestions[postHardwareIndex] = locationSelector.postHardwareData;
            setHardwareQuestions(hardwareQuestions);
        }
    }, [locationSelector.postHardwareData])

    useEffect(() => {
        if (locationSelector.hardwares && locationSelector.hardwares.length) {
            const defaultHardwares = locationSelector.hardwares?.filter((ele: HardwaresList) => ele.hardwareListId !== "00000000-0000-0000-0000-000000000000");
            setHardwareQuestions(defaultHardwares);
        }
    }, [locationSelector.hardwares])

    useEffect(() => {
        if (locationSelector?.status !== null && locationSelector?.status !== undefined) {
            setIsSaved(true);
            setTimeCounter(1);
        }
    }, [locationSelector])

    useEffect(() => {
        if (isSaved) {
            let intervalTimeCounter = setInterval(() => {
                setTimeCounter(timeCounter + 1);
                if (timeCounter === 3) {
                    setIsSaved(false)
                    clearInterval(intervalTimeCounter);
                    setTimeCounter(1);
                }
            }, 1000);
            return () => {
                clearInterval(intervalTimeCounter);
            };
        }
    }, [timeCounter, isSaved]);

    useEffect(() => {
        dispatch(riskAreaContainerCreators.requestGetRiskCategories());
    }, [id])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (riskAreaSelector && riskAreaSelector.riskCategory && riskAreaSelector.riskCategory.length && id) {
            const riskCategoryObj = riskAreaSelector.riskCategory.find((ele: any) => ele?.riskCategoryId === id)
            setSingleRiskCategory(riskCategoryObj);
            if (riskCategoryObj === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [riskAreaSelector])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        dispatch(locationContainerCreators.requestGetLocations());
    }, []);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (shouldRealoadAddresses) {
            dispatch(locationContainerCreators.requestGetLocations());
        }
    }, [shouldRealoadAddresses]);/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.locations && locationSelector.locations.length > 0 && locationId) {
            const availableHardwareCount = locationSelector?.locations?.find((ele: any) => ele?.locationId === locationId)
            setSinglelocations(availableHardwareCount)
        }
    }, [locationSelector.locations, locationId])

    useEffect(() => {
        if (singlelocations) {
            dispatch(locationContainerCreators.requestGetHardwares(singlelocations, singleRiskCategory, isCancel, ''));
        }
    }, [singlelocations])

    useEffect(() => {
        if (locationSelector && locationSelector.hardwares && locationSelector.hardwares.length > 0 && hardwareId) {
            const currentHardware = locationSelector?.hardwares?.find((ele: HardwaresList) => ele?.hardwareId === hardwareId)
            setSingleHardware(currentHardware)
            setEditHardwareObj(currentHardware)
        }
    }, [locationSelector.hardwares, locationId])

    useEffect(() => {
        if (locationSelector.hardwares && locationSelector.hardwares.length >= 0 && !editHardwareObj) {
            if (currentIndex !== null && currentIndex > -1) {
                setCurrentIndex(currentIndex)
                setSingleHardware(locationSelector.hardwares[currentIndex])
            }
            else if (currentIndex === null) {
                const index = locationSelector.hardwares?.findIndex((ele: HardwaresList) => !ele?.hardwareId)
                setCurrentIndex(index)
                setSingleHardware(locationSelector.hardwares[index])
            }
            else if (currentIndex === -1) {
                setSingleHardware({
                    ...intialHardware,
                    hardwareListId: '00000000-0000-0000-0000-000000000000'
                });
            }
        }
    }, [locationSelector.hardwares, currentIndex])

    /* istanbul ignore next */
    useEffect(() => {
        if (editHardwareObj) {
            setCurrentIndex(-1);
            setSingleHardware(editHardwareObj);
            if (editHardwareObj.selected) {
                if (editHardwareObj?.hardwareListId !== '00000000-0000-0000-0000-000000000000') {
                    setAnswerType3('OnThisLocation');
                    setAnswerType(getEditValues(editHardwareObj?.maintainHardware));
                    setAnswerType2(getEditValues(editHardwareObj?.maintainOS));
                    setAnswerType4(getEditValues(editHardwareObj?.inSupport));
                    setAnswerType5(getEditValues(editHardwareObj?.virusScanner));
                    setAnswerType6(getEditValues(editHardwareObj?.replacements));
                } else {
                    setAnswerType3('');
                    setNameWhenOther(editHardwareObj?.nameWhenOther);
                    setAnswerType(getEditValues(editHardwareObj?.maintainHardware));
                    setAnswerType2(getEditValues(editHardwareObj?.maintainOS));
                    setAnswerType4(getEditValues(editHardwareObj?.inSupport));
                    setAnswerType5(getEditValues(editHardwareObj?.virusScanner));
                    setAnswerType6(getEditValues(editHardwareObj?.replacements));
                }
            } else {
                if (editHardwareObj?.hardwareListId !== '00000000-0000-0000-0000-000000000000') {
                    setAnswerType3('NotOnThisLocation');
                } else {
                    setAnswerType3('');
                }
            }
        }
    }, [editHardwareObj])

    useEffect(() => {
        if (locationSelector.singleHardware && !editHardwareObj) {
            setSingleHardware(locationSelector.singleHardware);
            if (locationSelector.singleHardware.selected) {
                if (locationSelector.singleHardware?.hardwareListId !== '00000000-0000-0000-0000-000000000000') {
                    setAnswerType3('OnThisLocation');
                    setAnswerType(getEditValues(locationSelector.singleHardware?.maintainHardware));
                    setAnswerType2(getEditValues(locationSelector.singleHardware?.maintainOS));
                    setAnswerType4(getEditValues(locationSelector.singleHardware?.inSupport));
                    setAnswerType5(getEditValues(locationSelector.singleHardware?.virusScanner));
                    setAnswerType6(getEditValues(locationSelector.singleHardware?.replacements));
                } else {
                    setAnswerType3('');
                    setNameWhenOther(locationSelector.singleHardware?.nameWhenOther);
                    setAnswerType(getEditValues(locationSelector.singleHardware?.maintainHardware));
                    setAnswerType2(getEditValues(locationSelector.singleHardware?.maintainOS));
                    setAnswerType4(getEditValues(locationSelector.singleHardware?.inSupport));
                    setAnswerType5(getEditValues(locationSelector.singleHardware?.virusScanner));
                    setAnswerType6(getEditValues(locationSelector.singleHardware?.replacements));
                }
            } else {
                if (locationSelector.singleHardware?.hardwareListId !== '00000000-0000-0000-0000-000000000000') {
                    setAnswerType3('NotOnThisLocation');
                } else {
                    setAnswerType3('');
                }
            }
        }
    }, [locationSelector.singleHardware])

    useEffect(() => {
        if (showMoreInfo && decodedUserInfo) {
            dispatch(locationContainerCreators.requestGetHardwareHelpText(ContentType.HARDWARE, decodedUserInfo));
        }
    }, [showMoreInfo, decodedUserInfo])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (locationSelector && locationSelector.hardwareHelpText) {
            setInfoDetails(locationSelector.hardwareHelpText?.data);
        }
    }, [locationSelector])

 
    useEffect(() => {
        if (locationSelector?.loadingAfterPost) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [locationSelector?.loadingAfterPost])

    // functions
    /* istanbul ignore next */
    function getEditValues(value: number) {
        if (value === 1) {
            return "Yes";
        } else if (value === 2) {
            return "No";
        } else if (value === 3) {
            return "DontKnow";
        } else if (value === 4) {
            return "NotApplicable";
        } else {
            return "";
        }
    }
    /* istanbul ignore next */
    function getSelectedValue() {
        if (answerType3 === "OnThisLocation") {
            return true;
        } else if (answerType3 === "NotOnThisLocation") {
            return false;
        } else if (answerType3 === 'Yes') {
            return true;
        } else if (answerType3 === 'No') {
            return false;
        } else if (answerType3 === '') {
            return true;
        }
    }
    /* istanbul ignore next */
    function getMaintainHardwareValue() {
        if (answerType === "Yes") {
            return 1;
        } else if (answerType === "No") {
            return 2;
        } else if (answerType === "DontKnow") {
            return 3;
        } else if (answerType === "NotApplicable") {
            return 4;
        } else {
            return 0;
        }
    }
    /* istanbul ignore next */
    function getMaintainOsValue() {
        if (answerType2 === "Yes") {
            return 1;
        } else if (answerType2 === "No") {
            return 2;
        } else if (answerType2 === "DontKnow") {
            return 3;
        } else if (answerType2 === "NotApplicable") {
            return 4;
        } else {
            return 0;
        }
    }
    /* istanbul ignore next */
    function getSupportValue() {
        if (answerType4 === "Yes") {
            return 1;
        } else if (answerType4 === "No") {
            return 2;
        } else if (answerType4 === "DontKnow") {
            return 3;
        } else if (answerType4 === "NotApplicable") {
            return 4;
        } else {
            return 0;
        }
    }
    /* istanbul ignore next */
    function getVirusScannerValue() {
        if (answerType5 === "Yes") {
            return 1;
        } else if (answerType5 === "No") {
            return 2;
        } else if (answerType5 === "DontKnow") {
            return 3;
        } else if (answerType5 === "NotApplicable") {
            return 4;
        } else {
            return 0;
        }
    }


    function navigatetoHardwareCancel() {
        navigate(`/hardware/${id}?isCancel=0`, { state: { from: 'cancal' } });
    }

    async function handlePreviousHardware() {
        if (currentIndex === -1) {
            if (singlelocations?.locationId && hardwareQuestions[hardwareQuestions?.length - 1] && hardwareQuestions[hardwareQuestions?.length - 1]?.hardwareId) {
                dispatch(locationContainerCreators.requestGetHardwareById(singlelocations?.locationId, hardwareQuestions[hardwareQuestions?.length - 1]?.hardwareId))
            }
            setCurrentIndex(hardwareQuestions.length - 1);
        } else if (currentIndex > -1) {
            setCurrentIndex(currentIndex - 1);
            if (singlelocations?.locationId && hardwareQuestions[currentIndex - 1] && hardwareQuestions[currentIndex - 1]?.hardwareId) {
                dispatch(locationContainerCreators.requestGetHardwareById(singlelocations?.locationId, hardwareQuestions[currentIndex - 1]?.hardwareId))
            }
        }
        //setTimeout(() => {
        //    dispatch(locationContainerCreators.requestGetHardwares(singlelocations, singleRiskCategory, isCancel, ''));
        //}, 1500)
    }

    /* istanbul ignore next */
    function handleType(answer: string) {
        if (((role === "administrator") || (role === "editor"))) {
            if (answer === 'OnThisLocation') {
                setAnswerType3('OnThisLocation');
            } else if (answer === 'NotOnThisLocation') {
                setAnswerType3('NotOnThisLocation');
            } else if (answer === 'Yes') {
                setAnswerType3('Yes');
                setSingleHardware({
                    ...intialHardware,
                    hardwareListId: '00000000-0000-0000-0000-000000000000'
                });
            } else if (answer === 'No') {
                setAnswerType3('No');
                setSingleHardware({
                    ...intialHardware,
                    hardwareListId: '00000000-0000-0000-0000-000000000000'
                });
            }
        }
    }
    /* istanbul ignore next */
    function handleAnswerType(answer: string) {
        if (((role === "administrator") || (role === "editor"))) {
            if (answer === 'Yes') {
                setAnswerType('Yes');
            } else if (answer === 'No') {
                setAnswerType('No');
            } else if (answer === 'DontKnow') {
                setAnswerType('DontKnow');
            } else if (answer === 'NotApplicable') {
                setAnswerType('NotApplicable');
            }
        }
    }
    /* istanbul ignore next */
    function handleAnswerType2(answer: string) {
        if (((role === "administrator") || (role === "editor"))) {
            if (answer === 'Yes') {
                setAnswerType2('Yes');
            } else if (answer === 'No') {
                setAnswerType2('No');
            } else if (answer === 'DontKnow') {
                setAnswerType2('DontKnow');
            } else if (answer === 'NotApplicable') {
                setAnswerType2('NotApplicable');
            }
        }
    }
    /* istanbul ignore next */
    function handleAnswerType4(answer: string) {
        if (((role === "administrator") || (role === "editor"))) {
            if (answer === 'Yes') {
                setAnswerType4('Yes');
            } else if (answer === 'No') {
                setAnswerType4('No');
            } else if (answer === 'DontKnow') {
                setAnswerType4('DontKnow');
            } else if (answer === 'NotApplicable') {
                setAnswerType4('NotApplicable');
            }
        }
    }
    /* istanbul ignore next */
    function handleAnswerType5(answer: string) {
        if (((role === "administrator") || (role === "editor"))) {
            if (answer === 'Yes') {
                setAnswerType5('Yes');
            } else if (answer === 'No') {
                setAnswerType5('No');
            } else if (answer === 'DontKnow') {
                setAnswerType5('DontKnow');
            } else if (answer === 'NotApplicable') {
                setAnswerType5('NotApplicable');
            }
        }
    }
    /* istanbul ignore next */
    function handleAnswerType6(answer: string) {
        if (((role === "administrator") || (role === "editor"))) {
            if (answer === 'Yes') {
                setAnswerType6('Yes');
            } else if (answer === 'No') {
                setAnswerType6('No');
            } else if (answer === 'DontKnow') {
                setAnswerType6('DontKnow');
            } else if (answer === 'NotApplicable') {
                setAnswerType6('NotApplicable');
            }
        }
    }

    const temp = (answerType === '' || answerType2 === '' || answerType4 === '' || answerType5 === '' || answerType6 === '')

    function disableOnYes() {
        if (temp) {
            return true;
        } else {
            return false;
        }
    }

    function disableOnEmptyAnswer(): boolean {
        if (answerType3 === '') {
            return true;
        } else if (answerType3 === 'No' || answerType3 === "NotOnThisLocation") {
            return false;
        } else if (answerType3 === 'Yes' || answerType3 === "OnThisLocation") {
            return disableOnYes();
        }
        return false;
    }

    function isDisableBtn(): boolean {
        if (singleHardware?.hardwareListId !== '00000000-0000-0000-0000-000000000000') {
            return disableOnEmptyAnswer();
        } else if (singleHardware?.hardwareListId === '00000000-0000-0000-0000-000000000000' && lastQuestion) {
            return disableOnEmptyAnswer();
        } else if (singleHardware?.hardwareListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion) {
            return disableOnYes();
        }
        return false;
    }

    function handleEditHardwareInAddMode(isAddMode: boolean) {
        const selectedAnsType = (answerType3 === 'OnThisLocation' || answerType3 === 'NotOnThisLocation')
        const nameErr = schema.HardwareNameWhenOther.validate(nameWhenOther).error;
        if (answerType3 === "") {
            if (nameErr) {
                setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message }) } : undefined });
            } else {
                const payload = {
                    hardwareListId: editHardwareObj?.hardwareListId,
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: getSelectedValue(),
                    maintainHardware: getMaintainHardwareValue(),
                    maintainOS: getMaintainOsValue(),
                    inSupport: getSupportValue(),
                    virusScanner: getVirusScannerValue(),
                    replacements: getReplacementValue(answerType6)
                }
                dispatch(locationContainerCreators.requestPutHardwares(singlelocations?.locationId, hardwareQuestions[currentIndex]?.hardwareId, payload));
                setErrObj({ name: undefined });
                setAnswerType('');
                setNameWhenOther('');
                setAnswerType2('')
                setAnswerType3('');
                setAnswerType4('');
                setAnswerType5('');
                setAnswerType6('');
                if (isAddMode) {
                    if (currentIndex + 1 < hardwareQuestions.length) {
                        setCurrentIndex(currentIndex + 1);
                      } else {
                        setCurrentIndex(-1);
                      }
                } else {
                    navigate(`/hardware/${id}?isCancel=0`);
                }
            }
        } else {
            if (selectedAnsType) {
                const payload = {
                    hardwareListId: editHardwareObj?.hardwareListId,
                    nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                    selected: getSelectedValue(),
                    maintainHardware: getMaintainHardwareValue(),
                    maintainOS: getMaintainOsValue(),
                    inSupport: getSupportValue(),
                    virusScanner: getVirusScannerValue(),
                    replacements: getReplacementValue(answerType6)
                }
                dispatch(locationContainerCreators.requestPutHardwares(singlelocations?.locationId, hardwareQuestions[currentIndex]?.hardwareId, payload));
                setErrObj({ name: undefined });
                setAnswerType('');
                setNameWhenOther('');
                setAnswerType2('')
                setAnswerType3('');
                setAnswerType4('');
                setAnswerType5('');
                setAnswerType6('');
                if (isAddMode) {
                    if (currentIndex + 1 < hardwareQuestions.length) {
                        setCurrentIndex(currentIndex + 1);
                      } else {
                        setCurrentIndex(-1);
                      }
                } else {
                    navigate(`/hardware/${id}?isCancel=0`);
                }
            }
            else {
                if (answerType3 === "No") {
                    const payload = {
                        hardwareListId: editHardwareObj?.hardwareListId,
                        nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                        selected: getSelectedValue(),
                        maintainHardware: getMaintainHardwareValue(),
                        maintainOS: getMaintainOsValue(),
                        inSupport: getSupportValue(),
                        virusScanner: getVirusScannerValue(),
                        replacements: getReplacementValue(answerType6)
                    }
                    dispatch(locationContainerCreators.requestPutHardwares(singlelocations?.locationId, hardwareQuestions[currentIndex]?.hardwareId, payload));
                    setErrObj({ name: undefined });
                    setAnswerType('');
                    setNameWhenOther('');
                    setAnswerType2('')
                    setAnswerType3('');
                    setAnswerType4('');
                    setAnswerType5('');
                    setAnswerType6('');
                    if (isAddMode) {
                        if (currentIndex + 1 < hardwareQuestions.length) {
                            setCurrentIndex(currentIndex + 1);
                          } else {
                            setCurrentIndex(-1);
                          }
                    } else {
                        navigate(`/hardware/${id}?isCancel=0`);
                    }
                }
            }
        }
    }

    /* istanbul ignore next */
    function navigatetoHardware() {//NOSONAR
        const selectedAnsType = (answerType3 === 'OnThisLocation' || answerType3 === 'NotOnThisLocation')
        if (hardwareQuestions[currentIndex]?.hardwareId) {
            // EDIT HARDWARE IN ADD MODE
            if (singlelocations?.locationId && hardwareQuestions[currentIndex + 1]?.hardwareId) {
                dispatch(locationContainerCreators.requestGetHardwareById(singlelocations?.locationId, hardwareQuestions[currentIndex + 1]?.hardwareId))
            }
            handleEditHardwareInAddMode(true);
        }
        else if (editHardwareObj) {
            const nameErr = schema.HardwareNameWhenOther.validate(nameWhenOther).error;
            if (answerType3 === "") {
                if (nameErr) {
                    setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message }) } : undefined });
                } else {
                    const payload = {
                        hardwareListId: editHardwareObj?.hardwareListId,
                        nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                        selected: getSelectedValue(),
                        maintainHardware: getMaintainHardwareValue(),
                        maintainOS: getMaintainOsValue(),
                        inSupport: getSupportValue(),
                        virusScanner: getVirusScannerValue(),
                        replacements: getReplacementValue(answerType6)
                    }
                    dispatch(locationContainerCreators.requestPutHardwares(editHardwareObj?.locationId, editHardwareObj?.hardwareId, payload));
                    setErrObj({ name: undefined });
                    setAnswerType('');
                    setNameWhenOther('');
                    setAnswerType2('')
                    setAnswerType3('');
                    setAnswerType4('');
                    setAnswerType5('');
                    setAnswerType6('');
                    navigate(`/hardware/${id}?isCancel=0`);
                }
            } else {
                if (selectedAnsType) {
                    const payload = {
                        hardwareListId: editHardwareObj?.hardwareListId,
                        nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                        selected: getSelectedValue(),
                        maintainHardware: getMaintainHardwareValue(),
                        maintainOS: getMaintainOsValue(),
                        inSupport: getSupportValue(),
                        virusScanner: getVirusScannerValue(),
                        replacements: getReplacementValue(answerType6)
                    }
                    dispatch(locationContainerCreators.requestPutHardwares(editHardwareObj?.locationId, editHardwareObj?.hardwareId, payload));
                    setErrObj({ name: undefined });
                    setAnswerType('');
                    setNameWhenOther('');
                    setAnswerType2('')
                    setAnswerType3('');
                    setAnswerType4('');
                    setAnswerType5('');
                    setAnswerType6('');
                    navigate(`/hardware/${id}?isCancel=0`);
                }
                else {
                    if (answerType3 === "No") {
                        const payload = {
                            hardwareListId: editHardwareObj?.hardwareListId,
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: getSelectedValue(),
                            maintainHardware: getMaintainHardwareValue(),
                            maintainOS: getMaintainOsValue(),
                            inSupport: getSupportValue(),
                            virusScanner: getVirusScannerValue(),
                            replacements: getReplacementValue(answerType6)
                        }
                        dispatch(locationContainerCreators.requestPutHardwares(editHardwareObj?.locationId, editHardwareObj?.hardwareId, payload));
                        setErrObj({ name: undefined });
                        setAnswerType('');
                        setNameWhenOther('');
                        setAnswerType2('')
                        setAnswerType3('');
                        setAnswerType4('');
                        setAnswerType5('');
                        setAnswerType6('');
                        navigate(`/hardware/${id}?isCancel=0`);
                    }
                }
            }
        } else {
            // ADD Hardware
            if (singleHardware?.hardwareListId === '00000000-0000-0000-0000-000000000000' && lastQuestion) {
                if (answerType3 === 'Yes') {
                    const nameErr = schema.HardwareNameWhenOther.validate(nameWhenOther).error
                    if (nameErr) {
                        setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
                    } else {
                        const payload = {
                            hardwareListId: "00000000-0000-0000-0000-000000000000",
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: getSelectedValue(),
                            maintainHardware: getMaintainHardwareValue(),
                            maintainOS: getMaintainOsValue(),
                            inSupport: getSupportValue(),
                            virusScanner: getVirusScannerValue(),
                            replacements: getReplacementValue(answerType6)
                        }
                        dispatch(locationContainerCreators.requestPostHardwares(singlelocations && singlelocations?.locationId, payload));
                        setErrObj({ name: undefined });
                        setAnswerType('');
                        setNameWhenOther('');
                        setAnswerType2('')
                        setAnswerType3('');
                        setAnswerType4('');
                        setAnswerType5('');
                        setAnswerType6('');
                    }
                } else {
                    navigate(`/hardware/${id}`, { state: { showPopup: false } });
                }
            } else if (singleHardware?.hardwareListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion) {
                if (answerType3 === '') {
                    const nameErr = schema.HardwareNameWhenOther.validate(nameWhenOther).error
                    if (nameErr) {
                        setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
                    } else {
                        const payload = {
                            hardwareListId: "00000000-0000-0000-0000-000000000000",
                            nameWhenOther: nameWhenOther !== "" ? nameWhenOther : "",
                            selected: getSelectedValue(),
                            maintainHardware: getMaintainHardwareValue(),
                            maintainOS: getMaintainOsValue(),
                            inSupport: getSupportValue(),
                            virusScanner: getVirusScannerValue(),
                            replacements: getReplacementValue(answerType6)
                        }
                        dispatch(locationContainerCreators.requestPostHardwares(singlelocations && singlelocations?.locationId, payload));
                        setErrObj({ name: undefined });
                        setAnswerType('');
                        setNameWhenOther('');
                        setAnswerType2('')
                        setAnswerType3('');
                        setAnswerType4('');
                        setAnswerType5('');
                        setAnswerType6('');
                        if (currentIndex + 1 < hardwareQuestions.length) {
                            if (currentIndex + 1 < hardwareQuestions.length) {
                                setCurrentIndex(currentIndex + 1);
                              } else {
                                setCurrentIndex(-1);
                              }
                            setAnswerType('');
                        } else {
                            setCurrentIndex(-1);
                            setAnswerType('');
                        }
                        navigate(`/hardware/${id}?isCancel=0`);
                    }
                } else {
                    navigate(`/hardware/${id}?isCancel=0`);
                }
            } else {
                if (selectedAnsType) {
                    const payload = {
                        hardwareListId: hardwareQuestions[currentIndex]?.hardwareListId,
                        nameWhenOther: '',
                        selected: getSelectedValue(),
                        maintainHardware: getMaintainHardwareValue(),
                        maintainOS: getMaintainOsValue(),
                        inSupport: getSupportValue(),
                        virusScanner: getVirusScannerValue(),
                        replacements: getReplacementValue(answerType6)
                    }
                    dispatch(locationContainerCreators.requestPostHardwares(singlelocations && singlelocations?.locationId, payload));
                    setErrObj({ name: undefined });
                    setAnswerType('');
                    setNameWhenOther('');
                    setAnswerType2('')
                    setAnswerType3('');
                    setAnswerType4('');
                    setAnswerType5('');
                    setAnswerType6('');
                    setLastQuestion(true);
                    if (currentIndex + 1 < hardwareQuestions.length) {
                        if (currentIndex + 1 < hardwareQuestions.length) {
                            setCurrentIndex(currentIndex + 1);
                          } else {
                            setCurrentIndex(-1);
                          }
                        setAnswerType('');
                    } else {
                        setCurrentIndex(-1);
                        setAnswerType('');
                    }
                }
            }
        }
        setShowMoreInfo(false);
        //if (!editHardwareObj && answerType3 !== "No") {
        //    setTimeout(() => {
        //        dispatch(locationContainerCreators.requestGetHardwares(singlelocations, singleRiskCategory, isCancel, ''));
        //    }, 1000)
        //}
    }

    function handleBtnText() {
        if (editHardwareObj) {
            return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
        } else {
            if (hardwareQuestions[currentIndex]?.hardwareListId) {
                return intl.formatMessage({ id: 'next', defaultMessage: 'next' })
            } else {
                if (lastQuestion) {
                    return intl.formatMessage({ id: 'next', defaultMessage: 'next' })
                } else {
                    return intl.formatMessage({ id: 'save', defaultMessage: 'save' })
                }
            }
        }
    }

    function handleShowMoreInfo() {
        setShowMoreInfo(!showMoreInfo);
    }
    return (
        <>
            {/*{(locationSelector.loading || loading) ?
                <div className='app-loader'>
                    <LoadingIcon />
                </div>
                :*/}
                <div>
                    <Container>
                        <div className='m-auto w-full pb-7 align-middle items-center flex justify-between sm:m-0 sm:pb-6'>
                            <div className="flex items-center font-semibold text-xl">
                                <GetIcons item={singleRiskCategory} height={"40"} width={"40"} />
                                <div data-testid={`${testId}-hardware-que-name`} className="ml-3 font-semibold text-xl text-[#535353]">
                                    {singleRiskCategory?.riskListId !== '00000000-0000-0000-0000-000000000000' ? singleRiskCategory?.name : singleRiskCategory?.nameWhenOther}
                                </div>
                            </div>
                            {isSaved &&
                                <div className="font-normal text-xs text-[#959595]">
                                    <div>{intl.formatMessage({ id: "saved", defaultMessage:"saved" })}</div>
                                </div>
                            }
                        </div>
                        <div className="border w-full"></div>
                        <div>
                            <div className="flex-grow-1 flex flex-col justify-between">
                                <div>
                                    <div className='w-full align-middle items-center flex justify-between mt-5'>
                                        <div className='flex-col mt-5'>
                                            <div className="font-bold text-xs text-[#959595] sm:text-2xl uppercase font-family-arial">
                                                {(singlelocations?.name || singlelocations?.nameWhenOther)}
                                            </div>
                                            {editHardwareObjHandler(lastQuestion, intl, editHardwareObj, hardwareQuestions, currentIndex)}
                                        </div>
                                        <div className="relative">
                                            <div data-testid={`${testId}-show-more-info`} onClick={() => handleShowMoreInfo()} className={`mb-2 cursor-pointer flex items-center justify-center border rounded-full ${getShowMoreInfo(showMoreInfo)} py-1 px-3`}>
                                                <div className={`mr-1 mb-[2px] font-bold text-xs ${infoBtnStyle(showMoreInfo)}`}>{intl.formatMessage({ id: 'info', defaultMessage: 'info' })}</div>
                                                {showInfoIcon(showMoreInfo)}
                                            </div>
                                            {showMoreInfo &&
                                                <div className="absolute right-0 z-[1] flex flex-col min-h-max">
                                                    {infoDetails.map((infoCard, index) => (
                                                        <InfoCard key={infoCard?.id} infoCard={infoCard} />
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='flex gap-[24px] sm:flex-col'>
                                    <div className="flex sm:flex-col sm:w-full">
                                        {editHardwareObj ?
                                            <>
                                                {editLastQueAnsType(handleType, editHardwareObj, intl, nameWhenOther, setNameWhenOther, errObj, answerType3)}
                                            </>
                                            :
                                            <>
                                                {hardwareQuestions[currentIndex]?.hardwareListId ?
                                                    <div className='mt-8'>
                                                        <div data-testid={`${testId}-current-on-this-loc-btn`} onClick={() => handleType('OnThisLocation')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${onThisLocationBtn(answerType3)} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-center text-2xl sm:text-xl">{intl.formatMessage({ id: 'on_this_location', defaultMessage: 'on_this_location' })}</div>
                                                        </div>
                                                        <div data-testid={`${testId}-current-not-on-this-loc-btn`} onClick={() => handleType('NotOnThisLocation')} className={`w-[464px] h-[56px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${notOnThisLocationBtn(answerType3)} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-center text-2xl sm:text-xl">{intl.formatMessage({ id: 'not_on_this_location', defaultMessage: 'not_on_this_location' })}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        {lastQueAnsType(handleType, lastQuestion, intl, nameWhenOther, setNameWhenOther, answerType3, errObj)}
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>

                                    {hardwareNameWhenOther(answerType3, editHardwareObj, singleHardware, lastQuestion) &&
                                        <div className='sm:mt-0 mt-8 p-6 bg-white border rounded max-w-[414px]'>
                                            <div className="flex-1">
                                                {answerType3 === 'Yes' &&
                                                    <div className='mb-10'>
                                                        <p className='text-base font-bold text-[#121212] mb-2 sm:text-base sm:font-bold'>{intl.formatMessage({ id: 'enter_manual_hardware', defaultMessage: 'enter_manual_hardware' })}</p>
                                                        <input
                                                            type="text"
                                                            maxLength={100}
                                                            value={nameWhenOther}
                                                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-3 ${nameErrObj(errObj)}`}
                                                            onChange={(e) => setNameWhenOther(e.target.value)}
                                                        />
                                                        {otherErr(errObj.name?.message)}
                                                    </div>
                                                }
                                                <div>
                                                    <div className='font-bold text-base sm:text-xl font-family-arial'>{intl.formatMessage({ id: 'Hardware_question1', defaultMessage: 'Hardware_question1' })}</div>
                                                    <div className='flex gap-[24px]'>
                                                        <div data-testid={`${testId}-type-yes-one`} onClick={() => handleAnswerType('Yes')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full ${queOneYesBtn(answerType)} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                        </div>
                                                        <div data-testid={`${testId}-type-no-one`} onClick={() => handleAnswerType('No')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full sm:taxt-base sm:font-semibold ${queOneNoBtn(answerType)} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                        </div>
                                                    </div>

                                                    <div className='flex gap-[24px]'>
                                                        <div data-testid={`${testId}-dont-know-one`} onClick={() => handleAnswerType('DontKnow')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${answerTypeDontKnowBtn(answerType)} flex items-center justify-center mb-6`}>
                                                            <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'don’t_know', defaultMessage: 'don’t_know' })}</div>
                                                        </div>
                                                        <div data-testid={`${testId}-not-applicable-one`} onClick={() => handleAnswerType('NotApplicable')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${answerTypeNotApplicableBtn(answerType)} flex items-center justify-center mb-[40px]`}>
                                                            <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'not_applicable', defaultMessage: 'not_applicable' })}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='font-bold text-base sm:text-xl font-family-arial'>{intl.formatMessage({ id: 'Hardware_question2', defaultMessage: 'Hardware_question2' })}</div>
                                                        <div className='flex gap-[24px]'>
                                                            <div data-testid={`${testId}-type-yes-two`} onClick={() => handleAnswerType2('Yes')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full ${queTwoAnsTypeYesBtn(answerType2)} flex items-center justify-center mb-6`}>
                                                                <div className="font-bold text-base sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                            </div>
                                                            <div data-testid={`${testId}-type-no-two`} onClick={() => handleAnswerType2('No')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full ${queTwoAnsTypeNoBtn(answerType2)} flex items-center justify-center mb-6`}>
                                                                <div className="font-bold text-base sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                            </div>
                                                        </div>

                                                        <div className='flex gap-[24px]'>
                                                            <div data-testid={`${testId}-dont-know-two`} onClick={() => handleAnswerType2('DontKnow')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${queTwoAnsTypeDontKnowBtn(answerType2)} flex items-center justify-center mb-6`}>
                                                                <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'don’t_know', defaultMessage: 'don’t_know' })}</div>
                                                            </div>
                                                            <div data-testid={`${testId}-not-applicable-two`} onClick={() => handleAnswerType2('NotApplicable')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${queTwoAnsTypeNotApplicableBtn(answerType2)} flex items-center justify-center mb-[40px]`}>
                                                                <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'not_applicable', defaultMessage: 'not_applicable' })}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='font-bold text-base sm:text-xl font-family-arial'>{intl.formatMessage({ id: 'Hardware_question3', defaultMessage: 'Hardware_question3' })}</div>
                                                        <div className='flex gap-[24px]'>
                                                            <div data-testid={`${testId}-type-yes-three`} onClick={() => handleAnswerType4('Yes')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full ${queThreeAnsTypeYesBtn(answerType4)} flex items-center justify-center mb-6`}>
                                                                <div className="font-bold text-base sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                            </div>
                                                            <div data-testid={`${testId}-type-no-three`} onClick={() => handleAnswerType4('No')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full ${queThreeAnsTypeNoBtn(answerType4)} flex items-center justify-center mb-6`}>
                                                                <div className="font-bold text-base sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                            </div>
                                                        </div>

                                                        <div className='flex gap-[24px]'>
                                                            <div data-testid={`${testId}-dont-know-three`} onClick={() => handleAnswerType4('DontKnow')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${queThreeAnsTypeDontKnowBtn(answerType4)} flex items-center justify-center mb-6`}>
                                                                <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'don’t_know', defaultMessage: 'don’t_know' })}</div>
                                                            </div>
                                                            <div data-testid={`${testId}-not-applicable-three`} onClick={() => handleAnswerType4('NotApplicable')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${queThreeAnsTypeNotApplicableBtn(answerType4)} flex items-center justify-center mb-[40px]`}>
                                                                <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'not_applicable', defaultMessage: 'not_applicable' })}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='font-bold text-base sm:text-xl font-family-arial'>{intl.formatMessage({ id: 'Hardware_question4', defaultMessage: 'Hardware_question4' })}</div>
                                                        <div className='flex gap-[24px]'>
                                                            <div data-testid={`${testId}-type-yes-four`} onClick={() => handleAnswerType5('Yes')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full ${queFourAnsTypeYesBtn(answerType5)} flex items-center justify-center mb-6`}>
                                                                <div className="font-bold text-base sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                            </div>
                                                            <div data-testid={`${testId}-type-no-four`} onClick={() => handleAnswerType5('No')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full ${queFourAnsTypeNoBtn(answerType5)} flex items-center justify-center mb-6`}>
                                                                <div className="font-bold text-base sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                            </div>
                                                        </div>

                                                        <div className='flex gap-[24px]'>
                                                            <div data-testid={`${testId}-dont-know-four`} onClick={() => handleAnswerType5('DontKnow')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${queFourAnsTypeDontKnowBtn(answerType5)} flex items-center justify-center mb-6`}>
                                                                <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'don’t_know', defaultMessage: 'don’t_know' })}</div>
                                                            </div>
                                                            <div data-testid={`${testId}-not-applicable-four`} onClick={() => handleAnswerType5('NotApplicable')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${queFourAnsTypeNotApplicableBtn(answerType5)} flex items-center justify-center mb-[40px]`}>
                                                                <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'not_applicable', defaultMessage: 'not_applicable' })}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='font-bold text-base sm:text-xl font-family-arial'>{intl.formatMessage({ id: 'Hardware_question5', defaultMessage: 'Hardware_question5' })}</div>
                                                        <div className='flex gap-[24px]'>
                                                            <div data-testid={`${testId}-type-yes-five`} onClick={() => handleAnswerType6('Yes')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full ${queFiveAnsTypeYesBtn(answerType6)} flex items-center justify-center mb-6`}>
                                                                <div className="font-bold text-base sm:text-xl">{intl.formatMessage({ id: 'yes', defaultMessage: 'yes' })}</div>
                                                            </div>
                                                            <div data-testid={`${testId}-type-no-five`} onClick={() => handleAnswerType6('No')} className={`w-[180px] h-[48px] gap-[8px] mt-2 cursor-pointer rounded-lg sm:w-full ${queFiveAnsTypeNoBtn(answerType6)} flex items-center justify-center mb-6`}>
                                                                <div className="font-bold text-base sm:text-xl">{intl.formatMessage({ id: 'no', defaultMessage: 'no' })}</div>
                                                            </div>
                                                        </div>

                                                        <div className='flex gap-[24px]'>
                                                            <div data-testid={`${testId}-dont-know-five`} onClick={() => handleAnswerType6('DontKnow')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${queFiveAnsTypeDontKnowBtn(answerType6)} flex items-center justify-center mb-6`}>
                                                                <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'don’t_know', defaultMessage: 'don’t_know' })}</div>
                                                            </div>
                                                            <div data-testid={`${testId}-not-applicable-five`} onClick={() => handleAnswerType6('NotApplicable')} className={`w-[180px] h-[48px] gap-[8px] cursor-pointer rounded-lg sm:w-full ${queFiveAnsTypeNotApplicableBtn(answerType6)} flex items-center justify-center mb-[40px]`}>
                                                                <div className="font-semibold text-base sm:taxt-base sm:font-semibold">{intl.formatMessage({ id: 'not_applicable', defaultMessage: 'not_applicable' })}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='flex gap-[24px]'></div>
                            </div>
                            {(hardwareQuestions[currentIndex]?.hardwareListId || (lastQuestion)) &&
                                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                                    <div>
                                        <StyledButton
                                            testId={`${testId}-previous-btn`}
                                            onClick={() => handlePreviousHardware()}
                                            disabled={(currentIndex === 0 || editHardwareObj) ? true : false}
                                            buttonClass={'sm:px-2'}
                                            flexReverse={'flex-row-reverse'}
                                            width={'w-48 sm:w-max'}
                                            align={'justify-start'}
                                            text={intl.formatMessage({ id: 'previous', defaultMessage: 'previous' })}
                                            color={'text-[#535353]'}
                                            iconType={'arrowleft'}
                                            backgroundColor={'bg-[#EDEDED]'}
                                        />
                                    </div>
                                    {userRoles &&
                                        <StyledButton
                                            testId={`${testId}-next-btn`}
                                            buttonClass={'PrimoryBtn'}
                                            width={"w-48 sm:w-full"}
                                            align={"justify-end"}
                                            text={handleBtnText()}
                                            disabled={isDisableBtn()}
                                            backgroundColor={styleBtn(isDisableBtn(), 'bg-[#EDEDED]', 'bg-black')}
                                            color={styleBtn(isDisableBtn(), 'text-[#535353]', 'text-white')}
                                            iconType={styleBtn(isDisableBtn(), 'blackarrow', 'arrow')}
                                            onClick={() => navigatetoHardware()}
                                        />
                                    }
                                </div>
                            }
                            <div className="w-full flex items-center justify-between mt-4">
                                {(editHardwareObj || (singleHardware?.hardwareListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion)) ?
                                    <StyledButton testId={`${testId}-cancel-btn`} onClick={() => navigatetoHardwareCancel()} buttonClass={'sm:px-2'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-start'} text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })} color={'text-[#535353]'} backgroundColor={'bg-[#EDEDED]'} />
                                    :
                                    <div className="w-48 h-[52px] flex items-center justify-center">
                                        <button onClick={() => navigatetoHardwareCancel()} className="flex items-center justify-center">
                                            <span className="text-[#535353] font-family-arial font-semibold">{intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}</span>
                                        </button>
                                    </div>
                                }
                                {(editHardwareObj || (singleHardware?.hardwareListId === '00000000-0000-0000-0000-000000000000' && !lastQuestion)) && <StyledButton testId={`${testId}-handle-next-btn`} onClick={() => navigatetoHardware()} buttonClass={'sm:px-2 PrimoryBtn'} flexReverse={'flex-row-reverse'} width={'w-48 sm:w-max'} align={'justify-end'} text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })} disabled={isDisableBtn()} color={isDisableBtn() ? 'text-[#535353]' : 'text-white'} backgroundColor={isDisableBtn() ? 'bg-[#EDEDED]' : 'bg-black'} />}
                            </div>
                        </div>
                    </Container>
                </div>
            {/*}*/}
            <Modal show={isWromgOrgModal} onClose={() => ""}>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                </div>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-goto-dashboard`}
                        onClick={() => handleWrongOrgModel()}
                        buttonClass={'PrimoryBtn'}
                        width={"w-[220px] sm:w-40"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                        color={"text-white mr-3 text-base"}
                        iconType={'home'}
                        backgroundColor={"bg-black"}
                    />
                </div>
            </Modal>
        </>
    )
}

export default Add_Hardware;