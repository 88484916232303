import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Container from "../../../components/Container/Container";
import StyledButton from "../../../components/StyledButton";
import { Product } from "../Step3";
import { useDispatch, useSelector } from "react-redux";
import { listOptionsContainerCreators } from "../reducer";
import schema from "../../../validations/schema";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../../components/Modal/Modal";

const testId = `add-product`;

const initialProduct: Product = {
    name: "",
}

type ErrorMessage = {
    message: string;
}

type ErrorObject = {
    name?: ErrorMessage;
}

const AddProduct = () => {
    const params = useParams();
    const { id } = params;
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const intl = useIntl();
    const listOptionsSelector = useSelector((state: RootState) => state.listOptions);
    const [product, setProduct] = useState<Product>({ ...initialProduct });
    const [errObj, setErrObj] = useState<ErrorObject>({
        name: undefined
    });
    const [isWromgOrgModal, setIsWromgOrgModal] = useState<boolean>(false);

    function handleWrongOrgModel() {
        navigate("/dashboard")
    }

    useEffect(() => {
        dispatch(listOptionsContainerCreators.requestGetProducts());
    }, []);

    useEffect(() => {
        if (listOptionsSelector && listOptionsSelector.products && listOptionsSelector.products.length > 0 && id) {
            const currentProduct = listOptionsSelector.products?.find((ele: Product) => ele?.productId === id)
            setProduct({ ...currentProduct })
            if (currentProduct === undefined) {
                setIsWromgOrgModal(true)
            }
        }
    }, [listOptionsSelector.products, id])

    const handleProductInput = (key: string, value: string) => {
        product[key] = value;
        setProduct({ ...product });
    }

    const addProduct = () => {
        const nameErr = schema.productName.validate(product.name).error;
        if (nameErr) {
            setErrObj({ name: nameErr?.message ? { message: intl.formatMessage({ id: nameErr?.message, defaultMessage: nameErr?.message }) } : undefined });
        } else {
            setErrObj({ name: undefined });
            if (id) {
                let productData: Product = { ...product };
                delete productData.productId;
                delete productData.created;
                delete productData.modified;
                delete productData.organizationId;
                dispatch(listOptionsContainerCreators.requestPutProduct(product.productId, productData));
            }
            else {
                dispatch(listOptionsContainerCreators.requestAddProduct(product));
            }
            navigate("/organisation/step3")
        }
    }

    function onCancel() {
        navigate(`/organisation/step3`)
    }

    return (
        <div className="flex-grow-1 flex flex-col justify-between">
            <Container>
                <div>
                    <div className='m-auto w-full pb-7 align-middle items-center flex justify-between border-b sm:m-0 sm:pb-6'>
                        <div className="font-semibold text-xl">
                            <div>{intl.formatMessage({ id: 'organisation', defaultMessage: 'organisation' })}</div>
                        </div>
                    </div>
                    <div className="my-10 sm:my-8">
                        <div className='mt-8'>
                            <p className='max-w-xl text-2xl text-black font-semibold'>
                                {intl.formatMessage({ id: id ? 'organization_step3_text_Edit' : 'organization_step3_text_Add', defaultMessage: id ? 'organization_step3_text_Edit' : 'organization_step3_text_Add' })}
                            </p>
                            <div className="mt-7">
                                <div className="rounded-lg bg-white border flex flex-col gap-6 p-10 mb-4">
                                    <div>
                                        <label className='inline-block text-base font-bold mb-1 font-family-arial'>
                                            {intl.formatMessage({ id: 'product_name', defaultMessage: 'product_name' })}
                                        </label>
                                        <input
                                            data-testid={`${testId}-name-add-product`}
                                            type="text"
                                            maxLength={100}
                                            className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full ${errObj.name?.message ? 'border-[red]' : ''}`}
                                            value={product?.name ?? ""}
                                            onChange={event => handleProductInput("name", event.target.value)}
                                        />
                                        {errObj.name?.message ?
                                            <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                                <span>{errObj.name.message}</span>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full align-middle items-center flex justify-between mt-8 gap-4'>
                    <StyledButton
                        testId={`${testId}-cancel-btn-add-product`}
                        width={'w-48 sm:w-40'}
                        align={'justify-start'}
                        text={intl.formatMessage({ id: 'cancel', defaultMessage: 'cancel' })}
                        color={'text-gray-700'}
                        flexReverse={'flex-row-reverse'}
                        backgroundColor={'bg-gray-300'}
                        onClick={() => onCancel()}
                    />
                    <div>
                        <StyledButton
                            testId={`${testId}-save-btn-add-product`}
                            buttonClass={'PrimoryBtn'}
                            width={'w-48 sm:w-40'}
                            align={'justify-end'}
                            text={intl.formatMessage({ id: 'save', defaultMessage: 'save' })}
                            color={'text-white'}
                            iconType={'arrow'}
                            backgroundColor={'bg-black'}
                            onClick={() => addProduct()}
                        />
                    </div>
                </div>
            </Container>
            <Modal show={isWromgOrgModal} onClose={() => ""}>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "oops!", defaultMessage: "oops!" })}</div>
                </div>
                <div className="lg:min-w-[400px] text-center font-semibold text-[22px]" >
                    <div className='max-w-[414px]'>{intl.formatMessage({ id: "somthing_went_wrong", defaultMessage: "somthing_went_wrong" })}</div>
                </div>
                <div className="w-full align-middle items-center flex justify-between mt-8 gap-4">
                    <StyledButton
                        testId={`${testId}-goto-dashboard-bia`}
                        onClick={() => handleWrongOrgModel()}
                        buttonClass={'PrimoryBtn'}
                        width={"w-[220px] sm:w-40"}
                        align={"justify-end"}
                        text={intl.formatMessage({ id: "dashboard", defaultMessage: 'dashboard' })}
                        color={"text-white mr-3 text-base"}
                        iconType={'home'}
                        backgroundColor={"bg-black"}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default AddProduct
