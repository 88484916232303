import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: addMeasureGroupContainerTypes, Creators: addMeasureGroupContainerCreators } = createActions({
    requestPostAddMeasureGroup: ['id', 'data', 'navigate'],
    successPostAddMeasureGroup: ['postMeasureGroupData', 'status', 'method'],
    failurePostAddMeasureGroup: ['error'],
});

export const { Types: editMeasureGroupContainerTypes, Creators: editMeasureGroupContainerCreators } = createActions({
    //Put
    requestPutMeasureGroups: ['id', 'data', 'navigate', 'mgId'],
    successPutMeasureGroups: ['data', 'status', 'method'],
    failurePutMeasureGroups: ['error'],
});

export const { Types: clearMeasureGroupStatusTypes, Creators: clearMeasureGroupStatusCreators } = createActions({
    //clear status
    clearMeasureGroupStatus: []
});

export const { Types: disableMeasureGroupLoadingTypes, Creators: disableMeasureGroupLoadingCreators } = createActions({
    //clear status
    disableMeasureGroupLoading: []
});

export type InitialStateType = {
    data: object[] | null;
    error: string | null;
    loading: boolean | null;
    status?: number | null;
    method?: string | null;
    loadingAfterPost?: boolean;
    postMeasureGroupData?: object | null;
}

export const initialState: InitialStateType = { data: null, error: null, loading: null, status: null, method: null, loadingAfterPost: false, postMeasureGroupData: null };

export const addMeasureGroupContainerReducer = (state = initialState, action: any) =>
    produce(state, (draft) => {
        switch (action.type) {
            case addMeasureGroupContainerTypes.REQUEST_POST_ADD_MEASURE_GROUP:
                draft.loadingAfterPost = true;
                draft.method = null;
                draft.data = null;
                draft.loading = true;
                draft.error = null;
                draft.status = null;
                draft.postMeasureGroupData = null;
                break;
            case addMeasureGroupContainerTypes.SUCCESS_POST_ADD_MEASURE_GROUP:
                draft.method = action.method;
                draft.status = action.status;
                draft.data = null;
                draft.loading = false;
                draft.error = null;
                draft.postMeasureGroupData = action.postMeasureGroupData;
                break;
            case addMeasureGroupContainerTypes.FAILURE_POST_ADD_MEASURE_GROUP:
                draft.method = null;
                draft.data = null;
                draft.loading = false;
                draft.error = action.error;
                draft.status = null;
                draft.postMeasureGroupData = null;
                break;

            //Put
            case editMeasureGroupContainerTypes.REQUEST_PUT_MEASURE_GROUPS:
                draft.method = null;
                draft.data = null;
                draft.loading = true;
                draft.error = null;
                draft.status = null;
                break;
            case editMeasureGroupContainerTypes.SUCCESS_PUT_MEASURE_GROUPS:
                draft.method = action.method;
                draft.status = action.status;
                draft.data = action.data;
                draft.loading = false;
                draft.error = null;
                break;
            case editMeasureGroupContainerTypes.FAILURE_PUT_MEASURE_GROUPS:
                draft.method = null;
                draft.data = null;
                draft.loading = false;
                draft.error = action.error;
                draft.status = null;
                break;

            case clearMeasureGroupStatusTypes.CLEAR_MEASURE_GROUP_STATUS:
                draft.status = null;
                draft.method = null;
                break;

            case disableMeasureGroupLoadingTypes.DISABLE_MEASURE_GROUP_LOADING:
                draft.loadingAfterPost = false;
                break;

            default:
                return state;
        }
    })
export default addMeasureGroupContainerReducer;
