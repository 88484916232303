import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../components/Container/Container";
import SubscriptionLayout from "../../components/SubscriptionLayout";
import { invoicesContainerCreators, subscriptionContainerCreators } from "./subscriptionReducer";

const testId = `subscription-container`;

type SubscriptionPlan = {
    planId: string | null,
    name: number | null,
    price: number | null,
    created?: string | null,
    discountPercentage?: number,
    extraMonths?: number,
    firstPayment?: number,
    vat?: number,
    year1Fee?: number,
    yearlyFee?: number,
}

type DiscountObj = {
    accountantId: string | null,
    code: string | null,
    created: string | null,
    discountId: string | null,
    initialPaymentOnly: boolean,
    insuranceadvisorId: string | null,
    oneTimeUse: boolean,
    oneTimeUsed: boolean,
    percentage: number,
    validTill: string | null,
}

export type SubscriptionData = {
    autorenewal: number | null;
    created: Date | string | null;
    enddate: Date | string | null;
    modified: Date | string | null;
    organizationId: string | null;
    plan: SubscriptionPlan;
    partnerId: string;
    previousSubscription: null
    providerSubscriptionId: null
    startdate: Date | string
    status: number | null;
    subscriptionId: string | null;
    discount: DiscountObj;
}

export type InvoicesData = {
    invoiceId?: string;
    subscriptionId?: string;
    amount?: number;
    discount?: number;
    refund?: number;
    vat?: number;
    startDate?: Date;
    endDate?: Date;
    providerPaymentId?: string;
    providerCustomerId?: string;
    paymentAmount?: number;
    paymentStatus?: string;
    paymentCompleted?: Date;
    invoiceNr?: number;
    invoiceCreditNr?: number;
    created?: Date;
    modified?: Date;
}

export type RoleLimitsData = {
    available: number | null;
    limit: number | null;
    role: string | null;
    used: number | null;
}

export default function SubscriptionContainer() {

    const dispatch = useDispatch();

    const subscriptionSelector = useSelector((state: RootState) => state.subscriptionContainer);

    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData | undefined>();
    const [invoicesData, setInvoicesData] = useState<InvoicesData[] | undefined>();
    const [roleLimits, setRoleLimits] = useState<RoleLimitsData[] | undefined>();

    useEffect(() => {
        dispatch(subscriptionContainerCreators.requestGetSubscription());
        dispatch(invoicesContainerCreators.requestGetInvoices());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])/* eslint-disable react-hooks/exhaustive-deps */


    useEffect(() => {
        if (subscriptionSelector.isReloadSubscription) {
            dispatch(subscriptionContainerCreators.requestGetSubscription());
            dispatch(invoicesContainerCreators.requestGetInvoices());
        }
    }, [subscriptionSelector.isReloadSubscription])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (subscriptionSelector && subscriptionSelector.data) {
            setSubscriptionData(subscriptionSelector.data);
        } else {
            setSubscriptionData(undefined);
        }
        if (subscriptionSelector && subscriptionSelector.invoices && subscriptionSelector.invoices.length >= 0) {
            setInvoicesData(subscriptionSelector.invoices);
        }
    }, [subscriptionSelector])

    useEffect(() => {
        if (subscriptionData) {
            dispatch(subscriptionContainerCreators.requestGetRoleLimits(subscriptionData?.organizationId));
        }
    }, [subscriptionData])/* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (subscriptionSelector && subscriptionSelector.roleLimits && subscriptionSelector.roleLimits.length >= 0) {
            setRoleLimits(subscriptionSelector.roleLimits)
        }
    }, [subscriptionSelector])

    return (
        <Container>
            <SubscriptionLayout testId={testId} subscriptionData={subscriptionData} invoicesData={invoicesData} roleLimits={roleLimits} />
        </Container>
    );
}