import { AxiosPromise } from 'axios';
import axios from './axios';

export const getOrganizationDataService = (): AxiosPromise => axios.get(`/bucoscanservice/v1/organization`)

export const getCurrentOrgInfoService = (organizationId: string): AxiosPromise => axios.get(`/organizationservice/v1/organizations/${organizationId}`)

export const putCurrentOrgInfoService = (organizationId: string, data: object): AxiosPromise => axios.put(`/organizationservice/v1/organizations/${organizationId}`, data)

export const getRolesService = (organizationId: string, userId: string): AxiosPromise => axios.get(`/organizationservice/v1/organizations/${organizationId}/users/${userId}/roles`)

export const deleteUserService = (organizationId: string, userId: string): AxiosPromise => axios.delete(`/organizationservice/v1/organizations/${organizationId}/users/${userId}`)

export const putRoleService = (organizationId: string, userId: string, data: any): AxiosPromise => axios.put(`/organizationservice/v1/organizations/${organizationId}/users/${userId}/roles`, data)

export const putOrganizationDataService = (data: any): AxiosPromise => axios.put(`/bucoscanservice/v1/organization`, data)

export const getAddressesDataService = (): AxiosPromise => axios.get(`/bucoscanservice/v1/locations`)

export const addAddressDataService = (data: any): AxiosPromise => axios.post(`/bucoscanservice/v1/locations`, data)

export const deleteAddressDataService = (id: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/locations/${id}`)

export const putAddressDataService = (id: string, data: any): AxiosPromise => axios.put(`/bucoscanservice/v1/locations/${id}`, data)

export const getProductDataService = (): AxiosPromise => axios.get(`/bucoscanservice/v1/products`)

export const addProductDataService = (data: any): AxiosPromise => axios.post(`/bucoscanservice/v1/products`, data)

export const deleteProductDataService = (id: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/products/${id}`)

export const putProductDataService = (id: string, data: any): AxiosPromise => axios.put(`/bucoscanservice/v1/products/${id}`, data)

export const getProcessDataService = (): AxiosPromise => axios.get(`/bucoscanservice/v1/processes`)

export const addProcessDataService = (data: any): AxiosPromise => axios.post(`/bucoscanservice/v1/processes`, data)

export const deleteProcessDataService = (id: string): AxiosPromise => axios.delete(`/bucoscanservice/v1/processes/${id}`)

export const putProcessDataService = (id: string, data: any): AxiosPromise => axios.put(`/bucoscanservice/v1/processes/${id}`, data)

export const putUserService = (userId: string, data: object): AxiosPromise => axios.put(`/organizationservice/v1/users/${userId}`, data)

export const getInviteUserService = (): AxiosPromise => axios.get(`/organizationservice/v1/invites`)

export const getInviteUserByIdService = (inviteId: string): AxiosPromise => axios.get(`/organizationservice/v1/invite/${inviteId}`)

export const postInviteUserService = (data: object): AxiosPromise => axios.post(`/organizationservice/v1/invites`, data)

export const deleteInviteUserService = (inviteId: string): AxiosPromise => axios.delete(`/organizationservice/v1/invites/${inviteId}`)

export const resendInviteUserService = (inviteId: string): AxiosPromise => axios.post(`/organizationservice/v1/invites/${inviteId}`)

export const getUserService = (userId: string): AxiosPromise => axios.get(`/organizationservice/v1/users/${userId}`)

export const getOrgListService = (userId: string): AxiosPromise => axios.get(`/organizationservice/v1/users/${userId}/organizations`)

export const switchOrgService = (data: object): AxiosPromise => axios.post(`/organizationservice/v1/auth/switch-organization`, data)

export const createNewOrgService = (duplicateOrgIntentional: boolean, data: object): AxiosPromise => axios.post(`/organizationservice/v1/organizations?duplicateOrgIntentional=${duplicateOrgIntentional}`, data)

export const deleteOrgService = (organizationId: string): AxiosPromise => axios.delete(`/organizationservice/v1/organizations/${organizationId}`)

export const addUserToOrgByInvite = (userId: string, inviteId: string): AxiosPromise => axios.post(`/organizationservice/v1/users/${userId}/organizations?inviteId=${inviteId}&code=${''}`)

export const deleteUserOrgService = (userId: string, organizationId: string): AxiosPromise => axios.delete(`/organizationservice/v1/users/${userId}/organizations/${organizationId}`)

export const getUsersLinkedToOrgService = (organizationId: string): AxiosPromise => axios.get(`/organizationservice/v1/organizations/${organizationId}/users`)

export const getClientOrgService = (organizationId: string): AxiosPromise => axios.get(`/organizationservice/v1/organizations/${organizationId}/organizations`)

export const createNewClientOrgService = (organizationId: string, data: object): AxiosPromise => axios.post(`/organizationservice/v1/organizations/${organizationId}/organizations`, data)

export const getUsersRoleLimitsService = (organizationId: string): AxiosPromise => axios.get(`/organizationservice/v1/organizations/${organizationId}/rolelimits`)

export const getAllClientOrgService = (organizationId: string, clientOrganizationId: string): AxiosPromise => axios.get(`/organizationservice/v1/organizations/${organizationId}/organizations/${clientOrganizationId}`)

export const putUserLinkToClientOrgService = (organizationId: string, clientOrganizationId: string, data: object): AxiosPromise => axios.put(`/organizationservice/v1/organizations/${organizationId}/organizations/${clientOrganizationId}`, data)

export const deleteClientService = (organizationId: string, clientOrganizationId: string): AxiosPromise => axios.delete(`/organizationservice/v1/organizations/${organizationId}/organizations/${clientOrganizationId}`)

export const getLinkedPartnerOrg = (organizationId: string, userId: string): AxiosPromise => axios.get(`/organizationservice/v1/organizations/${organizationId}/users/${userId}/organizations`)

export const DeleteUserFromClientOrganizationService = (organizationId: string, userId: string, clientOrganizationId: string): AxiosPromise => axios.delete(`/organizationservice/v1/organizations/${organizationId}/users/${userId}/organizations/${clientOrganizationId}`)

export const AddLinkedPartnerOrgToUser = (organizationId: string, userId: string, data: object): AxiosPromise => axios.post(`/organizationservice/v1/organizations/${organizationId}/users/${userId}/organizations`, data)

export const putOrgUserService = (organizationId: string, userId: string, data: object): AxiosPromise => axios.put(`/organizationservice/v1/organizations/${organizationId}/users/${userId}`, data)

export const getOtpService = (): AxiosPromise => axios.get(`/organizationservice/v1/auth/otp`)

export const verifyAndEnableOtpService = (data: any): AxiosPromise => axios.post(`/organizationservice/v1/auth/otp/verify`, data)

export const validateOtpService = (data: any): AxiosPromise => axios.post(`/organizationservice/v1/auth/otp/validate`, data)

export const validateTwoFaTokenService = (data: any): AxiosPromise => axios.post(`/organizationservice/v1/auth/validate-token`, data)

export const SubscribeForNotificationService = (data: object): AxiosPromise => axios.post(`organizationservice/v1/notifications/subscriptions`,data)

export const notificationsMessageService = (data: object): AxiosPromise => axios.post(`organizationservice/v1/notifications/messages`,data)