
function hardwareBtn(isHardware: boolean) {
    return `${isHardware && '!text-[#121212]'}`
}

function locationDetails(singlelocationsId: boolean | undefined) {
    return `${singlelocationsId ? 'mb-6 sm:mb-3' : ''}`
}

function hardwareDetailsBtnWrap(singlelocationsId: boolean | undefined) {
    return `${singlelocationsId ? 'border-[#535353]' : 'border-[#959595]'}`
}

function hardwareDetailsBtn(singlelocationsId: boolean | undefined) {
    return `${singlelocationsId ? 'text-[#535353]' : 'text-[#959595]'}`
}

export { hardwareBtn, locationDetails, hardwareDetailsBtnWrap, hardwareDetailsBtn }