import React from "react";
import { useIntl } from "react-intl";
import calenderIcon from "../../assets/icons/calender-icon.svg";
import ProfilePicture from "../../assets/images/profile-picture.svg";
import EditIcon from '../../assets/icons/edit-icon.svg';
import StyledButton from '../StyledButton';
import DatePicker from 'react-date-picker';
import Dropdown from "../Dropdown";
import { Users } from "../../containers/ManageRiskAreasContainer";
import dayjs from "dayjs";
import { UserDropdownList } from "../../containers/RiskAreasContainer";
import { useSelector } from "react-redux";
import { selectDecodedUserInfo } from "../../AppSelector";
import Slider from 'antd/es/slider';

type Props = {
    setriskAreaCategory: any
    errRiskNameOther: any,
    intialRiskCategory: any,
    filterd: string,
    answerSubType: string;
    setAnswerSubType: React.Dispatch<React.SetStateAction<string>>;
    profileArr: string[];
    nameOfRiskArea: boolean;
    measuresDefinedDate: Date;
    setMeasuresDefinedDate: React.Dispatch<React.SetStateAction<Date>>;
    riskFactorValue: number;
    setRiskFactorValue: React.Dispatch<React.SetStateAction<number>>;
    answerType: string;
    isEditMode: boolean;
    riskListId: string;
    formatedDate: string;
    setFormatedDate: React.Dispatch<React.SetStateAction<string>>;
    userData: Users;
    setUserData: React.Dispatch<React.SetStateAction<Users>>;
    userDropdownList: UserDropdownList[];
    setUserDropdownList: React.Dispatch<React.SetStateAction<UserDropdownList[]>>;
    lastQuetion: boolean;
    testId?: string
}


export default function QuestionsExpand(props: Props) {
    const { answerSubType, setAnswerSubType, nameOfRiskArea, riskFactorValue, setRiskFactorValue, measuresDefinedDate, setMeasuresDefinedDate, intialRiskCategory, errRiskNameOther, setriskAreaCategory, answerType, riskListId,
        setFormatedDate, userData, setUserData, userDropdownList, lastQuetion, testId = `question-expand` } = props;

    const decodedUserInfo = useSelector(selectDecodedUserInfo);
    const role: string | undefined = decodedUserInfo?.payload.role;

    const handleAddressInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setriskAreaCategory({
            ...intialRiskCategory,
            nameWhenOther: event.target.value
        });
    };

    const intl = useIntl();

    const handleInput = (value: any) => {
        userData['userId'] = value;
        setUserData({ ...userData });
    };

    type Option = {
        value: string;
        label: string;
    }
    const initialOption: Option = {
        label: intl.formatMessage({ id: 'select', defaultMessage: 'select' }),
        value: "",
    }

    function handleMeasuresDefinedDate(date: Date) {
        const newDateFormate = dayjs(date).format('YYYY-MM-DD');
        setFormatedDate(newDateFormate.toString());
        setMeasuresDefinedDate(date);
    }

    return (
        <>
            <div data-testid={testId} className="w-full max-w-[414px] border rounded-lg bg-white mb-10 p-10 sm:p-5">
                {nameOfRiskArea &&
                    <div className={`w-full border rounded-lg bg-[#FAFAFA] p-5 mb-4`}>
                        <img data-testid={`${testId}-edit-icon`} className='absolute -mt-2 ml-[284px] cursor-pointer sm:ml-[190px]' src={EditIcon} alt='' />
                        <div className="font-bold text-[10px] text-[#959595] uppercase mb-1">{'Medium risk'}</div>
                        <div className="flex items-center justify-between">
                            <div className="font-semibold text-base">{'Infections'}</div>
                            <div>
                                <img data-testid={`${testId}-profile-picture`} src={ProfilePicture} alt='' />
                            </div>
                        </div>
                    </div>
                }
                <div className={`${nameOfRiskArea ? 'w-full border rounded-lg bg-[#EDEDED] p-5 sm:p-4' : 'bg-white'}`}>
                    {riskListId === '00000000-0000-0000-0000-000000000000' && lastQuetion ?
                        <div>
                            <label className="inline-block text-base font-bold mb-1">
                                {intl.formatMessage({ id: "name_of_the_risk_area", defaultMessage: 'name_of_the_risk_area' })}
                            </label>
                            <input
                                data-testid={`${testId}-name-of-risk-area`}
                                maxLength={100}
                                type="text"
                                className={`bg-zinc-50 border rounded-lg p-3 py-2 w-full mb-2 ${errRiskNameOther.nameWhenOther?.message ? 'border-[red]' : ''}`}
                                value={intialRiskCategory?.nameWhenOther}
                                onChange={handleAddressInput}
                            />
                            {errRiskNameOther.nameWhenOther?.message ?
                                <div className='text-red-600 font-normal text-sm h-3 mb-5 flex items-start justify-start'>
                                    <span>{errRiskNameOther.nameWhenOther.message}</span>
                                </div>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                    {answerType === 'Yes' &&
                        <div>
                            <div className="font-bold text-base mb-2 font-family-arial">{intl.formatMessage({ id: 'when_do_you_want_to_do_this?', defaultMessage: 'when_do_you_want_to_do_this?' })}</div>
                            <div className="flex">
                                <div data-testid={`${testId}-now-question-expand`} onClick={() => setAnswerSubType('Now')} className={`w-[110px] h-[45px] cursor-pointer rounded-lg ${answerSubType === 'Now' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-6 sm:mr-2`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'now', defaultMessage: 'now' })}</div>
                                </div>
                                <div data-testid={`${testId}-later-question-expand`} onClick={() => setAnswerSubType('Later')} className={`w-[110px] h-[45px] cursor-pointer rounded-lg ${answerSubType === 'Later' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'later', defaultMessage: 'later' })}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {intialRiskCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && answerType === '' &&
                        <div>
                            <div className="font-bold text-base mb-2 font-family-arial">{intl.formatMessage({ id: 'when_do_you_want_to_do_this?', defaultMessage: 'when_do_you_want_to_do_this?' })}</div>
                            <div className="flex">
                                <div data-testid={`${testId}-now-click`} onClick={() => setAnswerSubType('Now')} className={`w-[110px] h-[45px] cursor-pointer rounded-lg ${answerSubType === 'Now' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center mr-6 sm:mr-2`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'now', defaultMessage: 'now' })}</div>
                                </div>
                                <div data-testid={`${testId}-later-click`} onClick={() => setAnswerSubType('Later')} className={`w-[110px] h-[45px] cursor-pointer rounded-lg ${answerSubType === 'Later' ? 'bg-[#FCAF3B]' : 'bg-[#FAFAFA] border hover:bg-[#FFF9F0] hover:border-[#FCAF3B]'} flex items-center justify-center`}>
                                    <div className="font-semibold text-base sm:text-sm">{intl.formatMessage({ id: 'later', defaultMessage: 'later' })}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {answerSubType !== '' && answerType === 'Yes' &&
                        <div>
                            <div className="font-bold text-base mt-10 mb-2 font-family-arial">{intl.formatMessage({ id: 'what_is_the_risk_factor?', defaultMessage: 'what_is_the_risk_factor?' })}</div>
                            {/* Slider */}
                            <Slider value={riskFactorValue} onChange={(val: number) => setRiskFactorValue(val as number)} step={25} dots min={25} max={125}
                                marks={{
                                    25: { style: { marginLeft: '0', marginTop: '5px', left: 0, transform: 'translateX(0%)', textAlign: "start" }, label: intl.formatMessage({ id: "low_risk", defaultMessage: 'low_risk' }) },
                                    125: { style: { marginLeft: '0', left: 'auto', right: 0, transform: 'translateX(0%)', color: '#666', textAlign: "end", marginTop: '5px' }, label: intl.formatMessage({ id: "crucial", defaultMessage: 'crucial' }) }
                                }}
                                tooltip={{ formatter: null }}
                            />
                        </div>
                    }
                    {intialRiskCategory?.riskListId === '00000000-0000-0000-0000-000000000000' && answerSubType !== '' && answerType === '' &&
                        <div>
                            <div className="font-bold text-base mt-10 mb-2 font-family-arial">{intl.formatMessage({ id: 'what_is_the_risk_factor?', defaultMessage: 'what_is_the_risk_factor?' })}</div>
                            {/* Slider */}
                            <Slider value={riskFactorValue} onChange={(val: number) => setRiskFactorValue(val as number)} step={25} dots min={25} max={125}
                                marks={{
                                    25: { style: { marginLeft: '0', marginTop: '5px', transform: 'translateX(0%)', left: 0, textAlign: "start" }, label: intl.formatMessage({ id: "low_risk", defaultMessage: 'low_risk' }) },
                                    125: { style: { marginLeft: '0', left: 'auto', right: 0, transform: 'translateX(0%)', color: '#666', textAlign: "end", marginTop: '5px' }, label: intl.formatMessage({ id: "crucial", defaultMessage: 'crucial' }) }
                                }}
                                tooltip={{ formatter: null }}
                            />
                        </div>
                    }
                    {answerSubType === 'Now' &&
                        <>
                            <div>
                                <div className="font-bold text-base mt-10 mb-2 font-family-arial">{intl.formatMessage({ id: 'who_is_responsible_for_this?', defaultMessage: 'who_is_responsible_for_this?' })}</div>
                                <div className="flex h-[35px]">
                                    <div className="w-full">
                                        <Dropdown
                                            testId={`${testId}-dropdown-question-expand`}
                                            options={[...[initialOption], ...userDropdownList]}
                                            value={userData.userId}
                                            onChange={(value: string) =>
                                                handleInput(value)
                                            }
                                            error={errRiskNameOther.responsible?.message}
                                        />
                                    </div>
                                </div>
                                {errRiskNameOther.responsible?.message ?
                                    <div className='text-red-600 font-normal text-sm h-3 mt-2 mb-5 flex items-start justify-start'>
                                        <span>{errRiskNameOther.responsible.message}</span>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {decodedUserInfo?.payload.planningMethod === 1 ?
                                <div>
                                    <div className="font-bold text-base mt-10 mb-2 font-family-arial">{intl.formatMessage({ id: 'when_should_all_measures_be_defined?', defaultMessage: 'when_should_all_measures_be_defined?' })}</div>
                                    <div>
                                        <DatePicker locale={decodedUserInfo?.payload.prefLang} disabled={!((role === "administrator") || (role === "editor"))} className='customDatePicker bg-zinc-50 px-2 border rounded-lg w-[180px] h-12 relative sm:min-w-full' calendarIcon={<img className={`${(role === "administrator") || (role === "editor") ? 'pl-[10px]' : 'pl-[0px]'}`} data-testid={`${testId}-calnder-icon`} src={calenderIcon} alt='' />} dayPlaceholder='dd' monthPlaceholder='mm' yearPlaceholder='yyyy' value={measuresDefinedDate} onChange={(value: any) => handleMeasuresDefinedDate(value)} format={'dd-MM-yyyy'} />
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </>
                    }
                </div>
                {nameOfRiskArea &&
                    <div className="mt-4">
                        <StyledButton testId={`${testId}-add-another-risk-area`} flexReverse={'flex-row-reverse'} width={'w-[246px] sm:w-full'} align={'justify-start'} text={intl.formatMessage({ id: 'add_another_risk_area', defaultMessage: 'add_another_risk_area' })} color={'text-[#535353]'} iconType={'add'} backgroundColor={'bg-white'} />
                    </div>
                }
            </div>
        </>
    );
}