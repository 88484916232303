import { useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../../components/StyledButton';
import { subscriptionContainerCreators } from '../SubscriptionContainer/subscriptionReducer';
import { selectDecodedUserInfo } from '../../AppSelector';
import Container from '../../components/Container/Container';

const testId = `activate-subscription`;

export default function ActiveSubscriptionPage() {

    const intl = useIntl();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const decodedInfo: DecodedUserInfo | null = useSelector(selectDecodedUserInfo);
    const { from } = location.state || {};

    // state
    const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);

    // function
    function handleIsTermsChecked() {
        setIsTermsChecked(!isTermsChecked)
    }

    function handleActiveSubscriptionClose() {
        if (from === "subscription") {
            navigate("/subscription");
        } else {
            navigate("/dashboard");
        }
    }

    /* istanbul ignore next */
    function handleActivateSubscription() {
        const payload = {
            planId: "e177e02b-a1a5-4ec5-b159-55909c9c950f",
            discountCode: '',
            approval: false,
            upgradedFrom: ""
        }
        dispatch(subscriptionContainerCreators.requestPostSubscription(payload, navigate));
    }

    return (
        <div>
            <Container>
                <div className='flex items-center justify-center'>
                    <div className="w-full sm:w-full sm:p-4">
                        <div className={"min-h-[70vh]"}>
                            <div className='font-semibold text-[32px] sm:text-2xl'>{intl.formatMessage({ id: 'activate_subscription' , defaultMessage:'activate_subscription'})}</div>
                            <div className='flex justify-left'>
                                <div className='max-w-[992px] flex flex-col align-middle justify-self-center  w-full min-h-[76px] my-10 p-10 border rounded-lg bg-white'>
                                    <div className="font-normal text-base text-[#535353] tracking-[0.25px] pb-5 w-full">
                                        {intl.formatMessage({ id: 'active_subscription_paragraph_1' , defaultMessage:'active_subscription_paragraph_1'})}
                                    </div>
                                    <div data-testid={`${testId}=para-2-and-3`} className="font-normal text-base text-[#535353] tracking-[0.25px] pb-5 w-full">
                                        {intl.formatMessage({ id: 'active_subscription_paragraph_2', defaultMessage:'active_subscription_paragraph_2' }) + " "}
                                        {decodedInfo?.payload.partner + "."}
                                        {intl.formatMessage({ id: 'active_subscription_paragraph_3', defaultMessage:'active_subscription_paragraph_3' })}
                                    </div>
                                    <div className="font-normal text-base text-[#535353] tracking-[0.25px] w-full">

                                        <div className='flex items-center mb-4'>
                                            <input data-testid={`${testId}-trem-checked`} id={'isTermsChecked'} className='max-w-[20px] mr-2 h-[20px] border hover:border-[#C1C1C1]' type="checkbox" checked={isTermsChecked} name="" onChange={() => handleIsTermsChecked()} />
                                            <div className='ml-2'>
                                                {intl.formatMessage({ id: 'i_agree_with_the', defaultMessage:'i_agree_with_the' })} {" "}
                                                <a className='text-[#0052CC] underline' href="https://www.onesixeight.nl/voorwaarden" target="_blank" rel="noreferrer ugc nofollow">{intl.formatMessage({ id: 'active_subscription_paragraph_5' , defaultMessage:'active_subscription_paragraph_5'})}</a>
                                                {intl.formatMessage({ id: 'and2', defaultMessage:'and2' })}
                                                <a className='text-[#0052CC] underline' href="https://www.onesixeight.nl/privacystatement" target="_blank" rel="noreferrer ugc nofollow">{intl.formatMessage({ id: 'privacy_statement' , defaultMessage:'privacy_statement'})}</a>.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full align-middle items-center flex justify-between mt-8">
                            <StyledButton
                                testId={`${testId}-cancel-btn`}
                                onClick={() => handleActiveSubscriptionClose()}
                                buttonClass={'px-3 py-5'}
                                width={"w-48 sm:w-40"}
                                align={"justify-start"}
                                text={intl.formatMessage({ id: 'cancel', defaultMessage:'cancel' })}
                                color={"text-[#535353]"}
                                iconType={''}
                                backgroundColor={"bg-[#EDEDED]"}
                            />

                            <StyledButton
                                testId={`${testId}-activate-subscription`}
                                disabled={!isTermsChecked}
                                buttonClass={'px-3 py-5 PrimoryBtn'}
                                width={'min-w-[114px] w-max'}
                                align={'justify-end'}
                                text={intl.formatMessage({ id: 'activate_subscription', defaultMessage:'activate_subscription' })}
                                color={'text-white mr-3'}
                                iconType={''}
                                backgroundColor={
                                    !isTermsChecked
                                        ? "bg-zinc-400"
                                        : "bg-black"
                                }
                                onClick={() => handleActivateSubscription()}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}




