import { produce } from 'immer';
import { createActions } from 'reduxsauce';

export const { Types: taskContainerTypes, Creators: taskContainerCreators } = createActions({
    // get tasks
    requestGetTask: ['offset', 'pageSize'],
    successGetTask: ['data'],
    failureGetTask: ['error'],

    //get tasklistone
    requestGetTaskOneList: ['offset', 'pageSize', 'dashboardList'],
    successGetTaskOneList: ['dashboardListOne'],
    failureGetTaskOneList: ['error'],

    //get tasklisttwo
    requestGetTaskTwoList: ['offset', 'pageSize', 'dashboardList'],
    successGetTaskTwoList: ['dashboardListTwo'],
    failureGetTaskTwoList: ['error'],

    // put tasklist 
    requestPutTaskList: ['taskId', 'data'],
    successPutTaskList: ['taskId'],
    failurePutTaskList: ['error'],

    //get todotasklist
    requestGetTodoTaskList: ['offset', 'pageSize', 'status'],
    successGetTodoTaskList: ['todoTaskList'],
    failureGetTodoTaskList: ['error'],

    //get todoDoinglist
    requestGetDoingTaskList: ['offset', 'pageSize', 'status'],
    successGetDoingTaskList: ['doingTaskList'],
    failureGetDoingTaskList: ['error'],
});

export type InitialStateType = {
    data: object | null;
    error: string | null;
    loading: boolean | null;
    status?: number | null;
    dashboardListOne?: object | null;
    dashboardListTwo?: object | null;
    taskId?: object | null;
    todoTaskList?: object | null;
    doingTaskList?: object | null;
}

export const initialState: InitialStateType = { data: null, error: null, loading: null, status: null, dashboardListOne: null, dashboardListTwo: null, taskId: null, todoTaskList: null, doingTaskList: null };

export const taskContainerReducer = (state = initialState, action: any) =>
    produce(state, (draft) => {
        switch (action.type) {
            // get tasks
            case taskContainerTypes.REQUEST_GET_TASK:
                draft.status = null;
                draft.data = null;
                draft.error = null;
                break;
            case taskContainerTypes.SUCCESS_GET_TASK:
                draft.status = null;
                draft.data = action.data;
                draft.loading = false;
                draft.error = null;
                break;
            case taskContainerTypes.FAILURE_GET_TASK:
                draft.status = null;
                draft.data = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            //get tasklistone
            case taskContainerTypes.REQUEST_GET_TASK_ONE_LIST:
                draft.status = null;
                draft.dashboardListOne = null;
                draft.error = null;
                break;
            case taskContainerTypes.SUCCESS_GET_TASK_ONE_LIST:
                draft.status = null;
                draft.dashboardListOne = action.dashboardListOne;
                draft.loading = false;
                draft.error = null;
                break;
            case taskContainerTypes.FAILURE_GET_TASK_ONE_LIST:
                draft.status = null;
                draft.dashboardListOne = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            //get tasklisttwo
            case taskContainerTypes.REQUEST_GET_TASK_TWO_LIST:
                draft.status = null;
                draft.dashboardListTwo = null;
                draft.error = null;
                break;
            case taskContainerTypes.SUCCESS_GET_TASK_TWO_LIST:
                draft.status = null;
                draft.dashboardListTwo = action.dashboardListTwo;
                draft.loading = false;
                draft.error = null;
                break;
            case taskContainerTypes.FAILURE_GET_TASK_TWO_LIST:
                draft.status = null;
                draft.dashboardListTwo = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            // put tasklists
            case taskContainerTypes.REQUEST_PUT_TASK_LIST:
                draft.status = null;
                draft.taskId = null;
                draft.data = null;
                draft.error = null;
                break;
            case taskContainerTypes.SUCCESS_PUT_TASK_LIST:
                draft.status = null;
                draft.taskId = action.taskId;
                draft.loading = false;
                draft.error = null;
                break;
            case taskContainerTypes.FAILURE_PUT_TASK_LIST:
                draft.status = null;
                draft.taskId = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            //get todo tasklist
            case taskContainerTypes.REQUEST_GET_TODO_TASK_LIST:
                draft.loading = true;
                draft.todoTaskList = null;
                draft.error = null;
                break;
            case taskContainerTypes.SUCCESS_GET_TODO_TASK_LIST:
                draft.todoTaskList = action.todoTaskList;
                draft.loading = false;
                draft.error = null;
                break;
            case taskContainerTypes.FAILURE_GET_TODO_TASK_LIST:
                draft.todoTaskList = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            //get doing tasklist
            case taskContainerTypes.REQUEST_GET_DOING_TASK_LIST:
                draft.loading = true;
                draft.doingTaskList = null;
                draft.error = null;
                break;
            case taskContainerTypes.SUCCESS_GET_DOING_TASK_LIST:
                draft.doingTaskList = action.doingTaskList;
                draft.loading = false;
                draft.error = null;
                break;
            case taskContainerTypes.FAILURE_GET_DOING_TASK_LIST:
                draft.doingTaskList = null;
                draft.loading = false;
                draft.error = action.error;
                break;

            default:
                return state;
        }
    })
export default taskContainerReducer;
